import { LOWER_AGE_LIMIT, MAXIMUM_CURVE } from "@/agecurve"
import { formatDollars, getDistinctAgeCurveLabel } from "@/utils/formatting"
import { Collapse, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useFormikContext } from "formik"
import { useEffect } from "react"
import { CREATE_NEW_CLASS_INITIAL_VALUES } from "../Steps/Setup/PlanStructure/CustomClasses"
import { PLAN_STRUCTURE_INITIAL_VALUES } from "../Steps/Setup/PlanStructure/PlanStructure"
import { useCalculateReimbursementRate } from "../Steps/Setup/PlanStructure/planStructureService"
import { CalculateReimbursementRateResponse } from "../Steps/Setup/PlanStructure/planStructureTypes"

type PlanStructureInitialValues = typeof PLAN_STRUCTURE_INITIAL_VALUES
type CustomClassIntialValues = typeof CREATE_NEW_CLASS_INITIAL_VALUES
type FormValues = PlanStructureInitialValues | CustomClassIntialValues

export interface AmountAgesTableProps {
  agesTableOpen: boolean
  isAgeAndFamily: boolean
}

export const AmountAgesTable = ({ agesTableOpen, isAgeAndFamily }: AmountAgesTableProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const columnsSingle = ["Age", "Employee"] as const

  const columnsAgeAndFamily = [
    ...columnsSingle,
    "Employee + Spouse",
    "Employee + Children",
    "Employee + Spouse + Children",
  ] as const

  const columns = isAgeAndFamily ? columnsAgeAndFamily : columnsSingle

  const { data: ratesWithAgeCurve } = useCalculateReimbursementRate({
    states: values.geographyDescription ? values.geographyDescription.split(",") : [],
    employeeAmount: values.employeeAmount,
    employeeAndSpouseAmount: values.employeeAndSpouseAmount,
    employeeAndChildrenAmount: values.employeeAndChildrenAmount,
    employeeAndSpouseAndChildrenAmount: values.employeeAndSpouseAndChildrenAmount,
  }) as { data: CalculateReimbursementRateResponse }

  const { ageCurveId } = ratesWithAgeCurve || {}

  useEffect(() => {
    setFieldValue("ageCurveId", ageCurveId)
  }, [ageCurveId, setFieldValue])

  return (
    <Grid item xs={12}>
      <Collapse in={agesTableOpen} timeout="auto" unmountOnExit>
        <TableContainer component={Paper} sx={{ maxHeight: "21.875rem" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column} align="left">
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {ratesWithAgeCurve?.reimbursementRates
                .filter(row => row.age <= MAXIMUM_CURVE.age)
                .map(row => {
                  const ageLabel = getDistinctAgeCurveLabel(row.age)

                  return (
                    <TableRow
                      key={ageLabel}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      data-qa={`amount-ages-row-${ageLabel}`}
                    >
                      <TableCell component="th" scope="row">
                        {ageLabel}
                      </TableCell>
                      <TableCell align="left" sx={{ minWidth: "12.5rem" }} data-qa={`amount-ages-single-${ageLabel}`}>
                        {formatDollars(row.employeeAmount)}
                      </TableCell>
                      {isAgeAndFamily && (
                        <>
                          <TableCell
                            align="left"
                            sx={{ minWidth: "12.5rem" }}
                            data-qa={`amount-ages-spouse-${ageLabel}`}
                          >
                            {row.age >= LOWER_AGE_LIMIT ? formatDollars(row.employeeAndSpouseAmount) : "N/A"}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ minWidth: "12.5rem" }}
                            data-qa={`amount-ages-children-${ageLabel}`}
                          >
                            {row.age >= LOWER_AGE_LIMIT ? formatDollars(row.employeeAndChildrenAmount) : "N/A"}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ minWidth: "12.5rem" }}
                            data-qa={`amount-ages-spouse-and-children-${ageLabel}`}
                          >
                            {row.age >= LOWER_AGE_LIMIT ? formatDollars(row.employeeAndSpouseAndChildrenAmount) : "N/A"}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Grid>
  )
}
