import { CARRIER_QUESTION_FORM_KEY_DELIMITER, GROUP, INDIVIDUAL } from "@/features/TCHub/tcHubConstants"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { Uuid } from "@/utils/types"
import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import { InvalidateQueryFilters, useQueryClient } from "@tanstack/react-query"
import { Formik } from "formik"
import { isEqual, isNil, noop } from "lodash"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { MAILING_ADDRESS } from "../../benefitsElectionConstants"
import {
  useCreateCarrierEnrollmentQuestionAnswers,
  useDeleteCarrierEnrollmentQuestionAnswers,
  useGetCarrierEnrollmentQuestionAnswers,
  useShoppingUrl,
  useUpdateCarrierEnrollmentQuestionAnswers,
} from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import {
  CarrierEnrollmentQuestion,
  CarrierEnrollmentQuestionAnswer,
  EligibleApplicant,
} from "../../benefitsElectionTypes"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"
import { CarrierQuestionsContainer } from "../../components/CarrierQuestionsContainer"
import { CarrierQuestionsErrorContainer } from "../../components/CarrierQuestionsErrorContainer"
import { useGetCarrierQuestionsByStates } from "../../healthPlansService"
import { buildEnrollmentQuestionFormKey } from "../../utils/carrierQuestions"

interface CarrierLogoProps {
  selectedPlan: { logoUrl: string; carrierName: string }
  baseDataQa: string
}

const CarrierLogo = ({ selectedPlan, baseDataQa }: CarrierLogoProps) => (
  <Grid item xs={12}>
    <Box
      sx={{
        minHeight: "3.5rem",
        maxHeight: "3.5rem",
      }}
    >
      <img
        src={selectedPlan.logoUrl}
        alt={`${selectedPlan.carrierName} logo`}
        style={{ objectFit: "contain" }}
        data-qa={createDataQa(baseDataQa, "carrier-logo")}
      />
    </Box>
  </Grid>
)

export const baseDataQa = "carrier-questions"

export const CarrierQuestions = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { notify } = useNotifications("carrier-questions")
  // FUTURE: Improve this to pull the correct election ID from the store when we support multiple elections
  const currentShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const currentBenefitElection = currentShoppingSession.healthBenefitElections?.[0]
  const electionId = currentBenefitElection?.id
  const shoppingPersons = useBenefitsElectionStore(state => state.getEligibleApplicants())
  const carrierId = useBenefitsElectionStore(state => state.selectedPlan?.carrier?.id)
  const selectedPlan = useBenefitsElectionStore(state => state.selectedPlan)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const planView = useBenefitsElectionStore(state => state.planView)
  const employee = useBenefitsElectionStore(state => state.employee)
  const state = currentBenefitElection?.state
  const shoppingUrl = useShoppingUrl()
  // FUTURE: Update the type of the validation schema to be more specific than any
  // because then we can at least standardize the type of the schema
  const [validationSchema, setValidationSchema] = useState<Yup.ObjectSchema<any>>(Yup.object())

  const [initialAnswersPerIndividual, setInitialAnswersPerIndividual] = useState<
    CarrierEnrollmentQuestionAnswer[] | undefined
  >(undefined)

  const [initialAnswersForGroup, setInitialAnswersForGroup] = useState<CarrierEnrollmentQuestionAnswer[] | undefined>(
    undefined
  )

  const [formInitialValues, setFormInitialValues] = useState<{ [key: string]: string }>({})

  const [individualEnrollmentQuestions, setIndividualEnrollmentQuestions] = useState<CarrierEnrollmentQuestion[]>(
    {} as CarrierEnrollmentQuestion[]
  )

  const [groupEnrollmentQuestions, setGroupEnrollmentQuestions] = useState<CarrierEnrollmentQuestion[]>(
    {} as CarrierEnrollmentQuestion[]
  )

  const previous = shoppingUrl + planView
  const next = shoppingUrl + MAILING_ADDRESS
  const { mutateAsync: createEnrollmentQuestionAnswers } = useCreateCarrierEnrollmentQuestionAnswers(electionId ?? "")
  const { mutateAsync: updateCarrierEnrollmentQuestions } = useUpdateCarrierEnrollmentQuestionAnswers(electionId ?? "")
  const { mutateAsync: deleteCarrierEnrollmentQuestions } = useDeleteCarrierEnrollmentQuestionAnswers(electionId ?? "")

  const {
    data: carrierEnrollmentQuestions,
    isLoading: isLoadingQuestions,
    error: loadQuestionsError,
  } = useGetCarrierQuestionsByStates(carrierId ?? "", state)

  const { data: carrierEnrollmentQuestionAnswers, isLoading: isLoadingAnswers } =
    useGetCarrierEnrollmentQuestionAnswers(electionId ?? "")

  const haveQuestionsForEnrollment = carrierEnrollmentQuestions && carrierEnrollmentQuestions.length > 0

  const extractAnswersForSaveOperation = (formValues: Record<string, string>, isUpdateOperation: boolean) => {
    const answerRequests = Object.keys(formValues).map((key: string) => {
      // key format: questionId-scope-personId
      // questionId and personId are UUIDs, so they will also contain "-"
      // so we cannot split by "-" directly
      const [questionId, scope, personId] = key.split(CARRIER_QUESTION_FORM_KEY_DELIMITER)
      const userAnswer = formValues[key]

      const existingAnswer = carrierEnrollmentQuestionAnswers?.find(
        (answer: CarrierEnrollmentQuestionAnswer) =>
          answer.questionId === questionId && (scope === INDIVIDUAL ? answer.shoppingPersonId === personId : true)
      )

      if (
        (isUpdateOperation && !existingAnswer) ||
        (!isUpdateOperation && existingAnswer) ||
        (existingAnswer && existingAnswer.answer === userAnswer)
      ) {
        return null
      }

      const enrollmentQuestion = carrierEnrollmentQuestions?.find(
        (question: CarrierEnrollmentQuestion) => question.id === questionId
      )
      let questionText: string = enrollmentQuestion?.question ?? ""
      let webmergeKey: string = enrollmentQuestion?.webmergeKey ?? ""

      if (!questionText) {
        // Look through again, but this time at the child questions
        const findChildQuestion = (childQuestion: CarrierEnrollmentQuestion) => childQuestion.id === questionId

        const matchingParentQuestion = carrierEnrollmentQuestions?.find((question: CarrierEnrollmentQuestion) =>
          question.childQuestions?.find(findChildQuestion)
        )

        if (!matchingParentQuestion) {
          return null
        }

        const matchingChildQuestion = matchingParentQuestion.childQuestions?.find(findChildQuestion)

        if (!matchingChildQuestion) {
          return null
        }
        questionText = matchingChildQuestion.question ?? ""
        webmergeKey = matchingChildQuestion.webmergeKey ?? ""
      }

      const newAnswer: CarrierEnrollmentQuestionAnswer = {
        questionId: questionId as Uuid,
        questionText,
        answer: userAnswer,
        scope,
        shoppingPersonId: scope === INDIVIDUAL ? (personId as Uuid) : employee.shoppingPersonId,
        webmergeKey,
      }

      return isUpdateOperation ? { ...existingAnswer, ...newAnswer } : newAnswer
    })

    return answerRequests.filter(
      (answer: CarrierEnrollmentQuestionAnswer | null): answer is CarrierEnrollmentQuestionAnswer => answer !== null
    )
  }

  /**
   * Validate that the selected plan and carrier ID are present before proceeding
   */
  useEffect(
    () => {
      if (!selectedPlan || !carrierId) {
        notify(`Please choose a plan to fill carrier questions`, "error")
        navigate(previous)
      }
    },
    // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPlan]
  )

  /**
   * Split the carrier enrollment questions based on the scope, which is needed
   * to handle the form values and validation schema.
   */
  useEffect(() => {
    const isLoadingData = (isLoadingQuestions || isLoadingAnswers) ?? false
    const hasQuestions = carrierEnrollmentQuestions && carrierEnrollmentQuestions.length > 0

    if (!isLoadingData && hasQuestions) {
      const questionsPerIndividual = carrierEnrollmentQuestions.filter(
        (question: CarrierEnrollmentQuestion) => question.scope === INDIVIDUAL
      )

      const questionsForGroup = carrierEnrollmentQuestions.filter(
        (question: CarrierEnrollmentQuestion) => question.scope === GROUP
      )

      setIndividualEnrollmentQuestions(questionsPerIndividual)
      setGroupEnrollmentQuestions(questionsForGroup)
    }
  }, [carrierEnrollmentQuestions, isLoadingQuestions, isLoadingAnswers])

  /**
   * Initialize the answers for the form whenever the questions, answers,
   * or shopping persons change
   */
  useEffect(() => {
    // 1 - For each applicant, load their answers to individual scope questions
    if (individualEnrollmentQuestions && individualEnrollmentQuestions.length > 0) {
      const newInitialAnswersPerIndividual: CarrierEnrollmentQuestionAnswer[] = []

      shoppingPersons?.forEach(applicant => {
        individualEnrollmentQuestions.forEach((question: CarrierEnrollmentQuestion) => {
          // 1a - Load the answer to the parent question.
          const parentQuestionAnswer = carrierEnrollmentQuestionAnswers?.find(
            (answer: CarrierEnrollmentQuestionAnswer) =>
              answer.questionId === question.id && answer.shoppingPersonId === applicant.shoppingPersonId
          )

          newInitialAnswersPerIndividual.push({
            questionId: question.id,
            questionText: question.question,
            scope: question.scope,
            answer: parentQuestionAnswer?.answer ?? "",
            shoppingPersonId: applicant.shoppingPersonId,
          })

          // 1b - Load any answers to child questions based on the parent answer.
          question.childQuestions?.forEach((childQuestion: CarrierEnrollmentQuestion) => {
            if (childQuestion.parentAnswerForVisibility?.toLowerCase() === parentQuestionAnswer?.answer.toLowerCase()) {
              const childQuestionAnswer = carrierEnrollmentQuestionAnswers?.find(
                (answer: CarrierEnrollmentQuestionAnswer) =>
                  answer.questionId === childQuestion.id && answer.shoppingPersonId === applicant.shoppingPersonId
              )

              newInitialAnswersPerIndividual.push({
                questionId: childQuestion.id,
                questionText: childQuestion.question,
                scope: childQuestion.scope,
                answer: childQuestionAnswer?.answer ?? "",
                shoppingPersonId: applicant.shoppingPersonId,
                webmergeKey: childQuestion.webmergeKey,
              })
            }
          })
        })
      })

      if (JSON.stringify(newInitialAnswersPerIndividual) !== JSON.stringify(initialAnswersPerIndividual)) {
        setInitialAnswersPerIndividual(newInitialAnswersPerIndividual)
      }
    }

    // 2 - Load answers for group questions
    if (groupEnrollmentQuestions && groupEnrollmentQuestions.length > 0) {
      const newInitialAnswersForGroup: CarrierEnrollmentQuestionAnswer[] = []

      groupEnrollmentQuestions.forEach((question: CarrierEnrollmentQuestion) => {
        // 1 - Push initial answers to parent questions
        const parentQuestionAnswer = carrierEnrollmentQuestionAnswers?.find(
          (answer: CarrierEnrollmentQuestionAnswer) => answer.questionId === question.id
        )

        newInitialAnswersForGroup.push({
          questionId: question.id,
          questionText: question.question,
          scope: question.scope,
          answer: parentQuestionAnswer?.answer ?? "",
          shoppingPersonId: undefined,
          webmergeKey: question.webmergeKey,
        })

        // 2 - Push initial answers to child questions
        question.childQuestions?.forEach((childQuestion: CarrierEnrollmentQuestion) => {
          if (parentQuestionAnswer?.answer.toLowerCase() === childQuestion.parentAnswerForVisibility?.toLowerCase()) {
            const childQuestionAnswer = carrierEnrollmentQuestionAnswers?.find(
              (answer: CarrierEnrollmentQuestionAnswer) => answer.questionId === childQuestion.id
            )

            newInitialAnswersForGroup.push({
              questionId: childQuestion.id,
              questionText: childQuestion.question,
              scope: childQuestion.scope,
              answer: childQuestionAnswer?.answer ?? "",
              shoppingPersonId: undefined,
              webmergeKey: childQuestion.webmergeKey,
            })
          }
        })
      })
      if (!isEqual(newInitialAnswersForGroup, initialAnswersForGroup)) {
        setInitialAnswersForGroup(newInitialAnswersForGroup)
      }
    }
  }, [
    individualEnrollmentQuestions,
    groupEnrollmentQuestions,
    initialAnswersPerIndividual,
    initialAnswersForGroup,
    carrierEnrollmentQuestionAnswers,
    shoppingPersons,
  ])

  /**
   * Set form's initial values based on answers when initial answers, questions, or shopping persons change.
   * Prevents unnecessary re-renders by comparing new and current values before updating.
   */
  useEffect(() => {
    if (initialAnswersPerIndividual || initialAnswersForGroup) {
      const newFormInitialValues: Record<string, string> = {}

      // Helper function to find question answer
      const findQuestionAnswer = (
        question: CarrierEnrollmentQuestion,
        shoppingPersonId?: string,
        answers?: CarrierEnrollmentQuestionAnswer[]
      ) =>
        answers?.find(answer => {
          const matchingQuestionId = answer.questionId === question.id
          const matchingShoppingPersonId = shoppingPersonId ? answer.shoppingPersonId === shoppingPersonId : true
          const answeredAfterLastUpdate = answer.lastUpdated ? answer.lastUpdated > question.lastUpdated : true

          return matchingQuestionId && matchingShoppingPersonId && answeredAfterLastUpdate
        })

      // Helper function to set initial values
      const setInitialValues = (
        questions: CarrierEnrollmentQuestion[],
        answers?: CarrierEnrollmentQuestionAnswer[],
        shoppingPersonId?: string
      ) => {
        questions?.forEach((parentQuestion: CarrierEnrollmentQuestion) => {
          const parentQuestionAnswer = findQuestionAnswer(parentQuestion, shoppingPersonId, answers)

          newFormInitialValues[buildEnrollmentQuestionFormKey(parentQuestion, shoppingPersonId)] =
            parentQuestionAnswer?.answer ?? ""

          parentQuestion.childQuestions?.forEach((childQuestion: CarrierEnrollmentQuestion) => {
            if (parentQuestionAnswer?.answer.toLowerCase() === childQuestion.parentAnswerForVisibility?.toLowerCase()) {
              const existingChildAnswer = findQuestionAnswer(childQuestion, shoppingPersonId, answers)

              newFormInitialValues[buildEnrollmentQuestionFormKey(childQuestion, shoppingPersonId)] =
                existingChildAnswer?.answer ?? ""
            }
          })
        })
      }

      // Generate initial values for individual questions
      shoppingPersons?.forEach(applicant => {
        setInitialValues(individualEnrollmentQuestions, initialAnswersPerIndividual, applicant.shoppingPersonId)
      })

      // Generate initial values for group questions
      setInitialValues(groupEnrollmentQuestions, initialAnswersForGroup)

      // Update form initial values only if they have changed
      if (JSON.stringify(newFormInitialValues) !== JSON.stringify(formInitialValues)) {
        setFormInitialValues(newFormInitialValues)
      }
    }
  }, [
    initialAnswersPerIndividual,
    initialAnswersForGroup,
    individualEnrollmentQuestions,
    groupEnrollmentQuestions,
    formInitialValues,
    shoppingPersons,
  ])

  const convertEligibleApplicantToShoppingPersonPayload = (applicant: EligibleApplicant) => ({
    id: applicant.shoppingPersonId,
    firstName: applicant.personalInformation?.firstName ?? "",
    lastName: applicant.personalInformation?.lastName ?? "",
    preferredName: applicant.personalInformation?.preferredName ?? "",
    dateOfBirth: applicant.personalInformation?.dateOfBirth.toString() ?? "",
    zipCode: applicant.personalInformation?.zipCode?.zipCode ?? "",
    county: applicant.personalInformation?.zipCode?.countyName ?? "",
    state: applicant.personalInformation?.zipCode?.state ?? "",
    fipsCode: applicant.personalInformation?.zipCode?.fipsCode ?? "",
    gender: applicant.personalInformation?.gender ?? "",
    isTobaccoUser: applicant.personalInformation?.isTobaccoUser,
    isMedicareEligible: applicant.personalInformation?.isMedicareEligible,
    isEnrolledInMedicaid: applicant.personalInformation?.isEnrolledInMedicaid,
    planMember: applicant.planMember,
    relationship: applicant.personalInformation?.relationship,
    annualIncomeCents: applicant.annualIncome ? applicant.annualIncome * 100 : undefined,
    providerPreferences: [...applicant.doctors, ...applicant.hospitals],
    drugPreferences: applicant.prescriptions,
    healthBenefitElections: [currentBenefitElection?.id],
  })

  return (
    <>
      {selectedPlan?.logoUrl && <CarrierLogo selectedPlan={selectedPlan} baseDataQa={baseDataQa} />}
      {isLoadingQuestions && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width="100%"
          height="100%"
        >
          <CircularProgress sx={{ mb: 2 }} />
          <Typography>Loading questions...</Typography>
        </Box>
      )}

      {!isLoadingQuestions && loadQuestionsError && <CarrierQuestionsErrorContainer error={loadQuestionsError} />}

      {!isLoadingQuestions && !haveQuestionsForEnrollment && !loadQuestionsError && (
        <BenefitsElectionStep
          title="Carrier Questions"
          description="No questions available for this carrier. Please continue to the next step."
          handleContinue={() => setCurrentStep(MAILING_ADDRESS)}
          previous={previous}
          next={next}
          required
          advanceOnSuccess
        />
      )}
      {!isLoadingQuestions &&
        haveQuestionsForEnrollment &&
        formInitialValues &&
        Object.keys(formInitialValues).length > 0 && (
          <Formik initialValues={formInitialValues} onSubmit={noop} validationSchema={validationSchema}>
            {({ values, isValid }) => (
              <BenefitsElectionStep
                title="Carrier Questions"
                description="Please complete these questions required by the carrier to submit your healthcare plan application."
                required
                handleContinue={async () => {
                  const answersToCreate = extractAnswersForSaveOperation(values, false)
                  const answersToUpdate = extractAnswersForSaveOperation(values, true)

                  const answersToDelete = carrierEnrollmentQuestionAnswers?.filter(
                    (answer: CarrierEnrollmentQuestionAnswer) => {
                      const formQuestionKeys = Object.keys(values)

                      const matchingQuestionKeyForAnswer = formQuestionKeys.find((key: string) => {
                        // NOTE: Since the format of the key is "questionId-scope-personId", we need to check
                        // for both "INDIVIDUAL" and "GROUP" in the key. In the case that the question is for
                        // a group, the key will not contain a personId, so we need to check for that as well.
                        if (key.includes(GROUP)) {
                          return key.startsWith(answer.questionId)
                        } else {
                          if (!answer.shoppingPersonId) {
                            return false
                          }

                          return key.startsWith(answer.questionId) && key.endsWith(answer.shoppingPersonId)
                        }
                      })

                      // NOTE: If we cannot find a matching question key for the answer, it means that the question
                      // was removed from the form, so we should delete the answer.
                      if (!matchingQuestionKeyForAnswer) {
                        return true
                      }

                      let questionForAnswer = carrierEnrollmentQuestions?.find(
                        (question: CarrierEnrollmentQuestion) => question.id === answer.questionId
                      )

                      if (!questionForAnswer) {
                        // Check if the question is a child question
                        for (const question of carrierEnrollmentQuestions) {
                          const matchingChildQuestion = question.childQuestions?.find(
                            (childQuestion: CarrierEnrollmentQuestion) => childQuestion.id === answer.questionId
                          )

                          if (matchingChildQuestion) {
                            questionForAnswer = matchingChildQuestion
                            break
                          }
                        }

                        // NOTE: If we cannot find the question, it means that the question was removed from the backend.
                        if (!questionForAnswer) {
                          return true
                        }
                      }

                      if (!isNil(questionForAnswer.parentQuestionId)) {
                        const parentQuestionAnswer =
                          values[buildEnrollmentQuestionFormKey(questionForAnswer, answer.shoppingPersonId, true)]

                        if (
                          !isNil(parentQuestionAnswer) &&
                          parentQuestionAnswer.toLowerCase() !==
                            questionForAnswer.parentAnswerForVisibility?.toLowerCase()
                        ) {
                          return true
                        }
                      }

                      return !values[matchingQuestionKeyForAnswer]
                    }
                  )

                  let errorSavingAnswers = false

                  try {
                    if (answersToCreate && answersToCreate.length > 0) {
                      await createEnrollmentQuestionAnswers(answersToCreate)
                    }
                  } catch (error) {
                    errorSavingAnswers = true
                    console.error("Error creating carrier enrollment question answers", error)
                  }

                  try {
                    if (answersToUpdate && answersToUpdate.length > 0) {
                      await updateCarrierEnrollmentQuestions(answersToUpdate)
                    }
                  } catch (error) {
                    errorSavingAnswers = true
                    console.error("Error updating carrier enrollment question answers", error)
                  }

                  try {
                    if (answersToDelete && answersToDelete.length > 0) {
                      await deleteCarrierEnrollmentQuestions(answersToDelete)
                    }
                  } catch (error) {
                    errorSavingAnswers = true
                    console.error("Error deleting carrier enrollment question answers", error)
                  }

                  if (errorSavingAnswers) {
                    throw new Error("Error while saving carrier questions.")
                  } else {
                    // NOTE: We invalidate the answers query to ensure that the new answers are fetched
                    // from the backend when the user navigates back to this page.
                    queryClient.invalidateQueries(["election", electionId, "answers"] as InvalidateQueryFilters)
                    setCurrentStep(MAILING_ADDRESS)
                  }
                }}
                previous={previous}
                next={next}
                successMessage="Successfully saved Carrier Questions"
                disabled={!isValid}
                advanceOnSuccess
                errorMessage="Error saving carrier questions, please try again later."
              >
                <CarrierQuestionsContainer
                  shoppingPersons={shoppingPersons.map((applicant: EligibleApplicant) =>
                    convertEligibleApplicantToShoppingPersonPayload(applicant)
                  )}
                  enrollmentQuestions={carrierEnrollmentQuestions}
                  validationSchema={validationSchema}
                  setValidationSchema={setValidationSchema}
                />
              </BenefitsElectionStep>
            )}
          </Formik>
        )}
    </>
  )
}
