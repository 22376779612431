import styled from "@emotion/styled"
import { SvgIconComponent } from "@mui/icons-material"
import { ListItemButton, ListItemText, Typography } from "@mui/material"
import { forwardRef, MouseEventHandler } from "react"
import { NavLink, NavLinkProps } from "react-router-dom"
const CustomRouterLink = forwardRef<any, NavLinkProps>((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
))

interface ItemType {
  activeclassname?: string
  to?: string
  component?: typeof NavLink
  depth: number
}

const Item = styled(ListItemButton)<ItemType>`
  padding-left: ${props => props.theme.spacing(props.depth && props.depth > 0 ? 8 * props.depth + 4 : 4)};
  padding-right: ${props => props.theme.spacing(4)};
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  min-height: ${({ theme }) => theme.sidebar.minHeight};
  border-radius: 0;

  svg {
    font-size: ${({ theme }) => theme.sidebar.svg.fontSize};
    width: ${({ theme }) => theme.sidebar.svg.width};
    height: ${({ theme }) => theme.sidebar.svg.height};
  }

  &:hover {
    background: ${({ theme }) => theme.sidebar.hover};
    color: ${({ theme }) => theme.sidebar.hoverText};
  }

  &.${props => props.activeclassname} {
    color: ${({ theme }) => theme.palette.colors.white};
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

interface TitleType {
  depth: number
}

const Title = styled(ListItemText)<TitleType>`
  margin: 0;

  span {
    font-size: ${({ theme }) => theme.typography.body1.fontSize}px;
    padding: 0 ${({ theme }) => theme.spacing(4)};
  }
`

export interface SidebarNavListItemProps {
  depth?: number
  href?: string
  title?: string
  noActiveClass?: boolean
  onClick?: MouseEventHandler
  icon: SvgIconComponent
  endIcon?: SvgIconComponent
}

export const SidebarHeader = ({
  href,
  depth = 0,
  title,
  noActiveClass,
  onClick,
  icon,
  endIcon,
}: SidebarNavListItemProps) => {
  const StartIcon = icon
  const EndIcon = endIcon ?? null

  return (
    <Item
      depth={depth}
      component={CustomRouterLink}
      to={href}
      activeclassname={!noActiveClass ? "active" : undefined}
      data-qa="sidebar-footer"
      sx={{ borderRight: "1px solid", borderColor: "colors.borderGray" }}
      onClick={event => {
        if (onClick) {
          event.preventDefault()
          onClick(event)
        }
      }}
    >
      <StartIcon />
      <Title depth={depth} secondary={<Typography variant="caption">Admin Center</Typography>}>
        {title}
      </Title>
      <br />
      {EndIcon && <EndIcon />}
    </Item>
  )
}
