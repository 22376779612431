import { LoadingContentArea } from "@/components/LoadingContentArea"
import { StyledCard } from "@/components/StyledCard"
import { BaseTable } from "@/components/Table/Table"
import { toTitleCase } from "@/utils/formatting"
import { KeyboardArrowLeft } from "@mui/icons-material"
import { Button, CircularProgress, Grid, TableCell, TextField, Typography } from "@mui/material"
import { constant, noop } from "lodash"
import { useMemo } from "react"
import { Helmet } from "react-helmet-async"
import { Link, useParams } from "react-router-dom"
import { DISBURSEMENT_ACCOUNT, PAYMENT_ACCOUNT, SUB_ACCOUNT } from "../../tcHubConstants"
import { useAutoPayCompanies, useFundingEntityDetails } from "../../tcHubService"
import { AccountTokenTableHeader, FundingEntityDetailModel } from "../../tcHubTypes"
import { CompanySet } from "./TcHubAccountsPage"

interface AccountBusinessInformationProps {
  fundingEntityDetails: FundingEntityDetailModel
  companySet: Map<string, string>
  isCompaniesLoading: boolean
}

const AccountBusinessInformation = ({
  fundingEntityDetails,
  companySet,
  isCompaniesLoading,
}: AccountBusinessInformationProps) => (
  <StyledCard>
    <Grid container spacing={6} data-qa="account-details-business-information">
      <Grid item xs={12}>
        <Typography variant="h5">Business Information</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="name"
          label="Name"
          value={fundingEntityDetails?.name ?? ""}
          InputProps={{
            readOnly: true,
          }}
          data-qa="account-detail-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="entityType"
          label="Entity Type"
          value={toTitleCase(fundingEntityDetails?.entityType ?? "")}
          InputProps={{
            readOnly: true,
          }}
          data-qa="account-detail-entity-type"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="companyName"
          label="Company Name"
          value={fundingEntityDetails?.companyId ? companySet.get(fundingEntityDetails.companyId) : ""}
          InputProps={{
            readOnly: true,
            endAdornment: isCompaniesLoading && <CircularProgress size={20} />,
          }}
          data-qa="account-detail-company-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="companyId"
          label="Company ID"
          value={fundingEntityDetails?.companyId ?? ""}
          InputProps={{
            readOnly: true,
          }}
          data-qa="account-detail-company-id"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="vendorCustomerId"
          label="Vendor Customer ID"
          value={fundingEntityDetails?.vendorCustomerId ?? ""}
          InputProps={{
            readOnly: true,
          }}
          data-qa="account-detail-vendor-customer-id"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="vendorFundingSourceId"
          label="Vendor Funding Source ID"
          value={fundingEntityDetails?.vendorFundingSourceId ?? ""}
          InputProps={{
            readOnly: true,
          }}
          data-qa="account-detail-vendor-funding-source-id"
        />
      </Grid>
    </Grid>
  </StyledCard>
)

interface AccountBankingInformationProps {
  fundingEntityDetails: FundingEntityDetailModel
  entityType: string | undefined
}

const AccountBankingInformation = ({ fundingEntityDetails, entityType }: AccountBankingInformationProps) => {
  const showBankName = entityType !== DISBURSEMENT_ACCOUNT && entityType !== SUB_ACCOUNT
  const showStatusModifiedReason = entityType === SUB_ACCOUNT

  return (
    <StyledCard>
      <Grid container spacing={6} data-qa="account-details-banking-information">
        <Grid item xs={12}>
          <Typography variant="h5">Banking Information</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="routingNumber"
            label="Routing Number"
            value={fundingEntityDetails?.routingNumber ?? ""}
            InputProps={{
              readOnly: true,
            }}
            data-qa="account-detail-routing-number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="accountNumber"
            label="Account Number"
            value={fundingEntityDetails?.accountNumber ?? ""}
            InputProps={{
              readOnly: true,
            }}
            data-qa="account-detail-account-number"
          />
        </Grid>
        {showBankName && entityType !== DISBURSEMENT_ACCOUNT && (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="bankName"
              label="Bank Name"
              value={fundingEntityDetails?.bankName ?? ""}
              InputProps={{
                readOnly: true,
              }}
              data-qa="account-detail-bank-name"
            />
          </Grid>
        )}
        {showStatusModifiedReason && (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="statusModifiedReason"
              label="Status Modified Reason"
              value={fundingEntityDetails?.statusModifiedReason ?? ""}
              InputProps={{
                readOnly: true,
              }}
              data-qa="account-detail-status-modified-reason"
            />
          </Grid>
        )}
      </Grid>
    </StyledCard>
  )
}

const headers: AccountTokenTableHeader[] = [
  { id: "status", label: "Status", field: "status", alignment: "left" },
  {
    id: "type",
    label: "Type",
    field: "type",
    alignment: "left",
  },
  {
    id: "vendorToken",
    label: "Vendor Token",
    field: "vendorToken",
    alignment: "left",
  },
]

interface AccountTokenTableInformationProps {
  fundingEntityDetails: FundingEntityDetailModel
}

const AccountTokenTableInformation = ({ fundingEntityDetails }: AccountTokenTableInformationProps) => (
  <StyledCard>
    <Grid container data-qa="account-token-table-information">
      <Grid item xs={12}>
        <Typography variant="h5">Token Information</Typography>
      </Grid>
      <Grid item xs={12}>
        <BaseTable
          rows={fundingEntityDetails?.fundingTokens ?? []}
          selected={[]}
          searchCriteria=""
          onToggleSelect={noop}
          onToggleOrderBy={noop}
          onToggleSelectAll={noop}
          onPageChange={noop}
          onRowsPerPageChange={noop}
          uniqueIdSelector={constant("")}
          headCells={headers ?? []}
          rowsPerPage={4}
          page={0}
          fullWidth
          orderBy={[{ headCellId: "", direction: "asc" }]}
          hidePagination
        >
          {({ row }) => (
            <>
              <TableCell>
                <Typography data-qa="funding-entity-name">{toTitleCase(row.status)}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{toTitleCase(row.type, /(?=[A-Z])/)}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{row.vendorToken}</Typography>
              </TableCell>
            </>
          )}
        </BaseTable>
      </Grid>
    </Grid>
  </StyledCard>
)

interface Params extends Record<string, string | undefined> {
  fundingEntityId: string
}

export const TcHubAccountDetailPage = () => {
  const { fundingEntityId } = useParams<Params>()

  const { data: fundingEntityDetails, isLoading: isFundingEntitiesDetailsLoading } = useFundingEntityDetails(
    fundingEntityId ?? ""
  )

  const { data: companies, isLoading: isCompaniesLoading } = useAutoPayCompanies()
  const companySet = useMemo(() => CompanySet(companies ?? []), [companies])
  const entityType = fundingEntityDetails?.entityType
  const isPageLoading = isFundingEntitiesDetailsLoading || !fundingEntityDetails

  return (
    <Grid container data-qa="tc-hub-account-details" spacing={6}>
      <Grid item xs={12}>
        <Helmet title="TC Hub Account Detail" />
        <Button color="primary" component={Link} to="/admin/autopay/accounts" startIcon={<KeyboardArrowLeft />}>
          Back
        </Button>
        <Grid item xs={12} mt={3}>
          <Typography variant="h1" gutterBottom display="inline" data-qa="account-details-title">
            Account Detail
          </Typography>
        </Grid>
        {isPageLoading ? (
          <LoadingContentArea data-qa="loading-account-details" />
        ) : (
          <>
            <AccountBusinessInformation
              fundingEntityDetails={fundingEntityDetails}
              companySet={companySet}
              isCompaniesLoading={isCompaniesLoading}
            />

            <AccountBankingInformation fundingEntityDetails={fundingEntityDetails} entityType={entityType} />

            {entityType === PAYMENT_ACCOUNT && (
              <AccountTokenTableInformation fundingEntityDetails={fundingEntityDetails} />
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}
