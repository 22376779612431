import { addResponse } from "@/features/HRAPlanDesign/HRAPlanDesign.data"
import { usePrescreenResult } from "@/features/HRAPlanDesign/HRAPlanDesign.hooks"
import {
  isIchraDiy,
  isIchraNonDiy,
  isQsehra,
  PLAN_RESULT,
  PRE_SCREEN_QUESTION,
  StateHraValidation,
} from "@/features/HRAPlanDesign/HRAPlanDesign.utils"
import { createDataQa } from "@/utils/dataQa"
import { STATE_OPTIONS } from "@/utils/States"
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { Formik } from "formik"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { NavigationContainer } from "./NavigationContainer"
import { QuestionBody } from "./QuestionBody"
import { QuestionContainer } from "./QuestionContainer"
import { QuestionTitle } from "./QuestionTitle"
import { QuizBackButton } from "./QuizBackButton"
import { QuizLoader } from "./QuizLoader"
import { QuizNextQuestionButton } from "./QuizNextQuestionButton"
import { QuizProgressBar } from "./QuizProgressBar"

export const LocationForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const dataQa = createDataQa("location-form")
  const numFtEeResult = usePrescreenResult("NUM_FT_EE")
  const numFtEeResultPlan = numFtEeResult?.result
  const haveGroupPlanResult = usePrescreenResult("HAVE_GROUP_PLAN")
  const haveGroupPlanResponse = haveGroupPlanResult?.response
  const keepGroupPlanResult = usePrescreenResult("KEEP_GROUP_PLAN")
  const keepGroupPlanResponse = keepGroupPlanResult?.response
  const monthlyAllowanceResult = usePrescreenResult("MONTHLY_ALLOWANCE")
  const monthlyAllowanceResponse = monthlyAllowanceResult?.response
  const nonIndividualReimbursementResult = usePrescreenResult("NON_INDIVIDUAL_PLAN_REIMBURSEMENT")
  const nonIndividualReimbursementResponse = nonIndividualReimbursementResult?.response
  const locationResult = usePrescreenResult("LOCATION")
  const locationResultResponse = locationResult?.response

  const initialValues = {
    state: locationResultResponse || "",
  }

  const handleBack = async () => {
    if (numFtEeResultPlan && isQsehra(numFtEeResultPlan)) {
      if (haveGroupPlanResponse === "Yes" && keepGroupPlanResponse === "Yes") {
        navigate("/hra-determination/keep_group_plan")
      } else if (monthlyAllowanceResponse === "Yes") {
        navigate("/hra-determination/monthly_allowance")
      } else if (monthlyAllowanceResponse === "No" && nonIndividualReimbursementResponse === "No") {
        navigate("/hra-determination/non_individual_plan_reimbursement")
      } else {
        throw Error("Invalid Previous Response")
      }
    } else if (numFtEeResultPlan && (isIchraDiy(numFtEeResultPlan) || isIchraNonDiy(numFtEeResultPlan))) {
      if (haveGroupPlanResponse === "No") {
        navigate("/hra-determination/have_group_plan")
      } else if (haveGroupPlanResponse === "Yes" && keepGroupPlanResponse) {
        navigate("/hra-determination/keep_group_plan")
      } else {
        throw Error("Invalid Previous Response")
      }
    } else {
      throw Error("Invalid Previous Response")
    }
  }

  const handleFormSubmit = async (values: any, { resetForm, setErrors, setStatus, setSubmitting }: any) => {
    try {
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)

      if (numFtEeResultPlan && isIchraNonDiy(numFtEeResultPlan)) {
        await dispatch(
          addResponse({
            question: PRE_SCREEN_QUESTION.LOCATION,
            response: values.state,
            result: PLAN_RESULT.ICHRA_NON_DIY,
          })
        )
      } else if (numFtEeResultPlan && (isIchraDiy(numFtEeResultPlan) || isQsehra(numFtEeResultPlan))) {
        await dispatch(
          addResponse({
            question: PRE_SCREEN_QUESTION.LOCATION,
            response: values.state,
            result: PLAN_RESULT.ICHRA_DIY,
          })
        )
      } else {
        throw Error("Invalid Previous Response")
      }

      navigate("/hra-determination/varied_allowances")
    } catch (error: any) {
      setStatus({ sent: false })
      setErrors({ submit: error.message })
      setSubmitting(false)
    }
  }

  return (
    <QuestionContainer data-qa={createDataQa(dataQa, "wrapper")}>
      <Formik initialValues={initialValues} validationSchema={StateHraValidation} onSubmit={handleFormSubmit}>
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, isValid, values }) => (
          <>
            <QuizProgressBar value={60} />
            <QuestionTitle>Where are you geographically located?</QuestionTitle>
            <QuestionBody>
              Individual premiums vary from state to state. You could save money by offering similar buying power to
              your geographically located employees by setting up classes per state.
            </QuestionBody>
            {isSubmitting ? (
              <QuizLoader />
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" alignItems="center">
                  <FormControl>
                    <InputLabel id="stateLabel">Select State</InputLabel>
                    <Select
                      id="state"
                      name="state"
                      labelId="stateLabel"
                      label="Select State"
                      data-qa={createDataQa(dataQa, "option")}
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoWidth
                      sx={{
                        align: "center",
                        width: "13.75rem",
                      }}
                    >
                      {STATE_OPTIONS.map(state => (
                        <MenuItem key={state.value} id="state" value={state.value}>
                          {state.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <NavigationContainer>
                  <QuizBackButton handleBack={handleBack} />
                  <QuizNextQuestionButton
                    onClick={handleSubmit}
                    disabled={!(isValid && values.state && !isSubmitting && errors)}
                  />
                </NavigationContainer>
              </form>
            )}
          </>
        )}
      </Formik>
    </QuestionContainer>
  )
}
