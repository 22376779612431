import { FilterDropdownField } from "@/components/FilterDropdownField"
import {
  getCarriersOptions,
  getEnrollmentStatusOptions,
  getYearOptions,
  useCarriers,
} from "@/features/TCHub/Enrollment/tcHubEnrollmentService"
import { STATE_OPTIONS } from "@/utils/States"

import { PLAN_MARKET_OPTIONS } from "@/features/BenefitsElection/benefitsElectionConstants"
import { useEnrollmentSearch } from "@/features/TCHub/Enrollment/EnrollmentSearch"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import { Button, Drawer, Grid, Typography } from "@mui/material"

export const EnrollmentFilterDrawer = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
  const { filters, setFilter } = useEnrollmentSearch()
  const { data: carriers } = useCarriers()
  const carrierOptions = getCarriersOptions(carriers, filters?.state ?? "")

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="right">
      <Grid
        sx={{
          p: 4,
          width: 300,
        }}
      >
        <Button variant="text" onClick={() => setOpen(false)}>
          <KeyboardArrowLeft />
          Back to Enrollments
        </Button>
        <Grid item container xs={12} justifyContent="space-between" alignItems="center" mt={6}>
          <Typography variant="h1" gutterBottom>
            Filters
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              setFilter("state", "")
              setFilter("carrierId", "")
              setFilter("enrollmentStatus", "")
              setFilter("planYear", "")
            }}
          >
            Clear all
          </Button>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-start" spacing={3} mt={2} px={2}>
          <Grid item xs={12}>
            <FilterDropdownField
              options={STATE_OPTIONS}
              value={filters?.state ?? ""}
              onChange={state => setFilter("state", state)}
              label="Filter by State"
            />
          </Grid>
          <Grid item xs={12}>
            <FilterDropdownField
              options={carrierOptions}
              value={filters?.carrierId ?? ""}
              onChange={carrierId => setFilter("carrierId", carrierId)}
              label="Filter by Carrier"
            />
          </Grid>
          <Grid item xs={12}>
            <FilterDropdownField
              options={getEnrollmentStatusOptions()}
              value={filters?.enrollmentStatus ?? ""}
              onChange={status => setFilter("enrollmentStatus", status)}
              label="Filter by Status"
              clearable={false}
            />
          </Grid>
          <Grid item xs={12}>
            <FilterDropdownField
              options={getYearOptions()}
              value={filters?.planYear ?? ""}
              onChange={planYear => setFilter("planYear", planYear)}
              label="Filter by Year"
            />
          </Grid>

          <Grid item xs={12}>
            <FilterDropdownField
              options={PLAN_MARKET_OPTIONS}
              value={filters?.planMarket ?? ""}
              onChange={planMarket => setFilter("planMarket", planMarket)}
              label="Filter by Plan Market"
            />
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
}
