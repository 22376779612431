import { ConfirmationModal, ModalConfig } from "@/components/ConfirmationModal"
import { DatePickerField } from "@/components/DatePickerField"
import {
  ClampedTextField,
  EmailTextField,
  FirstNameTextField,
  LastNameTextField,
  MiddleNameTextField,
  PreferredNameTextField,
} from "@/components/TextFields"
import { MAX_CHAR_COUNT } from "@/constants"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { ZipCodeField } from "@/features/People/PeopleSettings/ZipCodeField"
import { ROLES } from "@/roles_permissions"
import { usePermissions } from "@/services/permissionsService"
import { getOnlyDate } from "@/utils/dates"
import { STATE_OPTIONS } from "@/utils/States"
import {
  dateOfBirthValidation,
  hireDateValidation,
  validateCity,
  validateCounty,
  validateEmail,
  validateFirstName,
  validateLastName,
  validateMiddleName,
  validateMobilePhoneNumber,
  validatePhoneNumber,
  validatePreferredName,
  validateState,
  validateStreet1,
  validateStreet2,
  validateZip,
} from "@/utils/validations"
import styled from "@emotion/styled"
import {
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Card as MuiCard,
  Select,
  Typography,
} from "@mui/material"
import FormHelperText from "@mui/material/FormHelperText"
import { spacing, SpacingProps } from "@mui/system"
import { useMutation } from "@tanstack/react-query"
import { Formik } from "formik"
import { get } from "lodash"
import { DateTime } from "luxon"
import { useState } from "react"
import * as Yup from "yup"
import { updatePerson } from "../peopleManagementEndpoints"
import { PersonModel } from "../peopleTypes"
import { PhoneNumberField } from "./PhoneNumberField"

interface ButtonProps extends SpacingProps {
  component?: string
}

const Card = styled(MuiCard)(spacing)
const Button = styled(MuiButton)<ButtonProps>(spacing)

interface AboutFormProps {
  person: PersonModel
}

export const AboutFormSection = ({ person }: AboutFormProps) => {
  const { user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId || ""
  const canEditRole = usePermissions("accounts_assign_roles")
  // FUTURE: Use or remove this unused state value and remove this eslint-disable directive
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dateOfBirth, setDateOfBirth] = useState(person.dateOfBirth)
  const [modal, setModal] = useState<ModalConfig>(null)

  // FUTURE: Move into service file
  const updatePersonMutation = useMutation({
    mutationFn: (payload: PersonModel) => updatePerson(companyId, payload),
  })

  const validationSchema = Yup.object().shape({
    firstName: validateFirstName,
    lastName: validateLastName,
    middleName: validateMiddleName,
    preferredName: validatePreferredName,
    email: validateEmail,
    phone: validatePhoneNumber,
    dateOfBirth: dateOfBirthValidation,
    employments: Yup.array().of(
      Yup.object().shape({
        activeFrom: hireDateValidation(),
      })
    ),
    mobilePhoneNumber: validateMobilePhoneNumber,
    addresses: Yup.array().of(
      Yup.object().shape({
        street1: validateStreet1,
        street2: validateStreet2,
        zip: validateZip,
        city: validateCity,
        county: validateCounty,
        state: validateState,
      })
    ),
  })

  const handleDateOfBirthChange = (event: any) => {
    const selectedDate = new Date(event.target.value)

    if (!isNaN(selectedDate.getTime())) {
      setDateOfBirth(event.target.value)
    }
  }

  return (
    <>
      <Formik
        initialValues={person}
        validationSchema={validationSchema}
        onSubmit={values => {
          setModal({
            title: "Update Person",
            message: "Are you sure you want to update this person?",
            isError: false,
            onConfirm: async () => {
              try {
                await updatePersonMutation.mutateAsync(values)
                setModal({
                  isCancelable: false,
                  isError: false,
                  title: "Updated Person",
                  message: "Person has been updated successfully.",
                  actionLabel: "Ok",
                  onConfirm: () => setModal(null),
                })
              } catch (e: any) {
                setModal({
                  isCancelable: false,
                  isError: true,
                  title: "Error",
                  message: e.developerMessage,
                  actionLabel: "Ok",
                  onConfirm: () => setModal(null),
                })
              }
            },
          })
        }}
      >
        {({ errors, handleChange, submitCount, handleSubmit, handleBlur, touched, values }) => (
          <Card>
            <CardContent data-qa="about-container">
              <Typography variant="h6" color="primary" mb={6} gutterBottom>
                About
              </Typography>
              <form onSubmit={handleSubmit} data-qa="user-settings-about">
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FirstNameTextField />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MiddleNameTextField />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LastNameTextField />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PreferredNameTextField />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <EmailTextField />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PhoneNumberField
                      name="phone"
                      label="Phone number"
                      value={values.phoneNumber}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      fullWidth
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: MAX_CHAR_COUNT }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePickerField
                      name="dateOfBirth"
                      label="Date of Birth"
                      value={getOnlyDate(values.dateOfBirth ?? null)}
                      maxDate={DateTime.now().minus({ years: 18 }).toJSDate()}
                      InputLabelProps={{ shrink: true }}
                      onBlur={handleBlur}
                      error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                      helperText={touched.dateOfBirth ? `${errors.dateOfBirth ?? ""}` : ""}
                      fullWidth
                      required
                      variant="outlined"
                      onChange={e => {
                        handleDateOfBirthChange(e)
                        handleChange(e)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <InputLabel>Role*</InputLabel>
                      <Select
                        data-qa="role"
                        name="companyAdministrator.roleId"
                        label="Role"
                        placeholder="Select role"
                        fullWidth
                        value={values.roles}
                        disabled={!canEditRole}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {ROLES.map(row => (
                          <MenuItem key={row.id} id="row" value={row.id}>
                            {row.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ClampedTextField
                      data-qa="job-title"
                      name="jobTitle"
                      label="Job Title"
                      variant="outlined"
                      fullWidth
                      value={(values.jobTitle ?? null) as never}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: MAX_CHAR_COUNT }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePickerField
                      name="hireDate"
                      label="Hire Date"
                      value={getOnlyDate(values.employments[0].activeFrom ?? null)}
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(touched.hireDate && errors.hireDate)}
                      helperText={touched.hireDate ? `${errors.hireDate ?? ""}` : ""}
                      fullWidth
                      required
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                {values.addresses?.map((address, index) => {
                  if (!address.type || !address.type.includes("HOME")) {
                    return null
                  }

                  return (
                    <Grid container mt={2} spacing={6} key={`address-${index}`}>
                      <Grid item xs={12} md={6}>
                        <ClampedTextField
                          data-qa="street1"
                          name={`addresses.${index}.street1`}
                          label="Address"
                          value={values.addresses[index].street1 ?? ""}
                          inputProps={{ maxLength: MAX_CHAR_COUNT }}
                          error={Boolean(
                            (submitCount !== 0 && get(errors, `addresses.${index}.street1`)) ||
                              (touched.addresses?.[index]?.street1 && get(errors, `addresses.${index}.street1`))
                          )}
                          helperText={
                            (submitCount !== 0 && get(errors, `addresses.${index}.street1`)) ||
                            (touched.addresses?.[index]?.street1 && get(errors, `addresses.${index}.street1`))
                          }
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <ClampedTextField
                          data-qa="address2"
                          name={`addresses.${index}.street2`}
                          label="Apt, suite, etc. (optional)"
                          value={values.addresses[index].street2 ?? ""}
                          inputProps={{ maxLength: MAX_CHAR_COUNT }}
                          error={Boolean(
                            (submitCount !== 0 && get(errors, `addresses.${index}.street2`)) ||
                              (touched.addresses?.[index]?.street2 && get(errors, `addresses.${index}.street2`))
                          )}
                          helperText={
                            (submitCount !== 0 && get(errors, `addresses.${index}.street2`)) ||
                            (touched.addresses?.[index]?.street2 && get(errors, `addresses.${index}.street2`))
                          }
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <ClampedTextField
                          data-qa="city"
                          name={`addresses.${index}.city`}
                          label="City"
                          value={values.addresses[index].city ?? ""}
                          inputProps={{ maxLength: MAX_CHAR_COUNT }}
                          error={Boolean(
                            (submitCount !== 0 && get(errors, `addresses.${index}.city`)) ||
                              (touched.addresses?.[index]?.city && get(errors, `addresses.${index}.city`))
                          )}
                          helperText={
                            (submitCount !== 0 && get(errors, `addresses.${index}.city`)) ||
                            (touched.addresses?.[index]?.city && get(errors, `addresses.${index}.city`))
                          }
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl
                          variant="outlined"
                          sx={{ width: "100%" }}
                          error={Boolean(
                            (submitCount !== 0 && get(errors, `addresses.${index}.state`)) ||
                              (touched.addresses?.[index]?.state && get(errors, `addresses.${index}.state`))
                          )}
                          required
                        >
                          <InputLabel>State</InputLabel>
                          <Select
                            name={`addresses.${index}.state`}
                            label="State"
                            placeholder="Select State"
                            data-qa="state"
                            fullWidth
                            value={values.addresses[index].state ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            {STATE_OPTIONS.map(state => (
                              <MenuItem key={state.value} id="state" value={state.value}>
                                {state.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {(submitCount !== 0 && get(errors, `addresses.${index}.state`)) ||
                            touched.addresses?.[index]?.state
                              ? `${get(errors, `addresses.${index}.state`) ?? ""}`
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ZipCodeField
                          data-qa="zip"
                          name={`addresses.${index}.zip`}
                          label="ZIP Code"
                          value={values.addresses[index].zip ?? ""}
                          error={Boolean(
                            (submitCount !== 0 && get(errors, `addresses.${index}.zip`)) ||
                              (touched.addresses?.[index]?.zip && get(errors, `addresses.${index}.zip`))
                          )}
                          helperText={
                            (submitCount !== 0 && get(errors, `addresses.${index}.zip`)) ||
                            (touched.addresses?.[index]?.zip && get(errors, `addresses.${index}.zip`))
                          }
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ClampedTextField
                          data-qa="county"
                          name={`addresses.${index}.county`}
                          label="County"
                          value={values.addresses[index].county ?? ""}
                          inputProps={{ maxLength: MAX_CHAR_COUNT }}
                          error={Boolean(
                            (submitCount !== 0 && get(errors, `addresses.${index}.county`)) ||
                              (touched.addresses?.[index]?.county && get(errors, `addresses.${index}.county`))
                          )}
                          helperText={
                            (submitCount !== 0 && get(errors, `addresses.${index}.county`)) ||
                            (touched.addresses?.[index]?.county && get(errors, `addresses.${index}.county`))
                          }
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </Grid>
                    </Grid>
                  )
                })}
                <Button data-qa="submit-about-button" type="submit" variant="contained" color="primary" mt={3}>
                  Save changes
                </Button>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
      {modal && <ConfirmationModal {...modal} isOpen onClose={() => setModal(null)} />}
    </>
  )
}
