import { ROLES } from "@/roles_permissions"
import { Permission, Role } from "@/utils/types"

export type RoleId = Role["id"]
export type RoleName = Role["name"]
export type PermissionId = Permission["id"]

// SAFETY: We can assert this is non-null because the type of RoleId is derived from the valid values
export const getRoleById = (roleId: RoleId): Role => ROLES.find(role => role.id === roleId)!

export const roleHasPermissions = (role: Role, permissionIds: PermissionId[], matchType: "some" | "every") => {
  if (!permissionIds?.length) return false
  if (!role) return false

  return matchType === "some"
    ? permissionIds.some((permissionId: PermissionId) => role.permissions.includes(permissionId as never))
    : permissionIds.every((permissionId: PermissionId) => role.permissions.includes(permissionId as never))
}
