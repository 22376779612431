import { addResponse } from "@/features/HRAPlanDesign/HRAPlanDesign.data"
import { usePrescreenResult } from "@/features/HRAPlanDesign/HRAPlanDesign.hooks"
import { PLAN_RESULT, PRE_SCREEN_QUESTION, radioGroupHraValidation } from "@/features/HRAPlanDesign/HRAPlanDesign.utils"
import { createDataQa } from "@/utils/dataQa"
import { Formik } from "formik"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { NavigationContainer } from "./NavigationContainer"
import { QuestionBody } from "./QuestionBody"
import { QuestionContainer } from "./QuestionContainer"
import { QuestionTitle } from "./QuestionTitle"
import { QuizBackButton } from "./QuizBackButton"
import { QuizLoader } from "./QuizLoader"
import { QuizNextQuestionButton } from "./QuizNextQuestionButton"
import { QuizProgressBar } from "./QuizProgressBar"
import { QuizRadioGroupQuestion } from "./QuizRadioGroupQuestion"

const formOptions = ["Yes", "No"]

export const NonIndividualPlanReimbursement = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const dataQa = createDataQa("non-individual-plan-reimbursement")
  const nonInvidivualPlanReimbursementResult = usePrescreenResult("NON_INDIVIDUAL_PLAN_REIMBURSEMENT")
  const nonInvidivualPlanReimbursementResponse = nonInvidivualPlanReimbursementResult?.response

  const initialValues = {
    radioGroup: nonInvidivualPlanReimbursementResponse || "",
  }

  const handleBack = async () => {
    navigate("/hra-determination/monthly_allowance")
  }

  const handleFormSubmit = async (values: any, { resetForm, setErrors, setStatus, setSubmitting }: any) => {
    try {
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
      if (formOptions.includes(values.radioGroup)) {
        switch (values.radioGroup) {
          case "Yes":
            await dispatch(
              addResponse({
                question: PRE_SCREEN_QUESTION.NON_INDIVIDUAL_PLAN_REIMBURSEMENT,
                response: values.radioGroup,
                result: PLAN_RESULT.QSEHRA,
              })
            )
            navigate("/hra-determination-results/qsehra")
            break
          case "No":
            await dispatch(
              addResponse({
                question: PRE_SCREEN_QUESTION.NON_INDIVIDUAL_PLAN_REIMBURSEMENT,
                response: values.radioGroup,
                result: PLAN_RESULT.ICHRA_DIY,
              })
            )
            navigate("/hra-determination/location")
            break
          default:
            throw Error("Invalid Option Selected")
        }
      }
    } catch (error: any) {
      setStatus({ sent: false })
      setErrors({ submit: error.message })
      setSubmitting(false)
    }
  }

  return (
    <QuestionContainer data-qa={createDataQa(dataQa, "wrapper")}>
      <Formik initialValues={initialValues} validationSchema={radioGroupHraValidation} onSubmit={handleFormSubmit}>
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, isValid, values }) => (
          <>
            <QuizProgressBar value={80} />

            <QuestionTitle>Do you want to offer reimbursements for employees for non-individual plans?</QuestionTitle>
            <QuestionBody>
              An employee can recieve their monthly reimbursement while being part of a spouse's group plan.
            </QuestionBody>
            {isSubmitting ? (
              <QuizLoader />
            ) : (
              <form onSubmit={handleSubmit}>
                <QuizRadioGroupQuestion
                  options={formOptions}
                  value={values.radioGroup}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="radioGroup"
                  data-qa={createDataQa("option")}
                />

                <NavigationContainer>
                  <QuizBackButton handleBack={handleBack} />
                  <QuizNextQuestionButton
                    onClick={handleSubmit}
                    disabled={!(isValid && values.radioGroup && !isSubmitting && errors)}
                  />
                </NavigationContainer>
              </form>
            )}
          </>
        )}
      </Formik>
    </QuestionContainer>
  )
}
