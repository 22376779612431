import { SimpleNotification } from "@/components/Notifications/SimpleNotification"
import { SnackbarProvider } from "notistack"
import { ReactNode } from "react"

declare module "notistack" {
  interface VariantOverrides {
    simpleNotification: {
      selection: "success" | "warning" | "info" | "error"
      dataQa: string
    }
  }
}

export const NotificationProvider = ({ children }: { children: ReactNode }) => (
  <SnackbarProvider
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    autoHideDuration={8000}
    preventDuplicate
    TransitionProps={{ direction: "left" }}
    Components={{ simpleNotification: SimpleNotification }}
  >
    {children}
  </SnackbarProvider>
)
