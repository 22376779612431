import { createDataQa } from "@/utils/dataQa"
import { Grid, Typography } from "@mui/material"
import { WidgetData } from "../../dashboardTypes"
import { WidgetLayout } from "../WidgetLayout"

interface EmployerContributionWidgetProps {
  isLoading: boolean
  data: WidgetData
}

//FUTURE: implement functionality in future ticket
export const EmployerContributionWidget = ({ isLoading, data }: EmployerContributionWidgetProps) => (
  <WidgetLayout isLoading={isLoading} data-qa={createDataQa("employer", "contribution", "widget")}>
    <Grid container>
      <Grid item>
        <Typography variant="h2small">Employer Contribution</Typography>
      </Grid>
    </Grid>
  </WidgetLayout>
)
