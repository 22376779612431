import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { EmployerOnboardingStatus } from "./employerOnboardingTypes"

const { baseApiUrl } = appConfig

// FUTURE: Consider consolidating with similar definition in createCompanyEndpoints
export const getEmployerOnboardingStatuses = async (companyId: string) =>
  (await axios.get<EmployerOnboardingStatus[]>(`${baseApiUrl}/benefits/v1/companies/${companyId}/onboarding-statuses`))
    .data

export const updateEmployerOnboardingStatus = async (companyId: string, onboardingStatus: EmployerOnboardingStatus) => {
  const updateEmployerOnboardingStatusResponse = (
    await axios.put<EmployerOnboardingStatus>(
      `${baseApiUrl}/benefits/v1/companies/${companyId}/onboarding-statuses`,
      onboardingStatus
    )
  ).data

  return updateEmployerOnboardingStatusResponse
}
