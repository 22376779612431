import { Footer } from "@/components/Footer"
import { EXTERNAL_LINKS } from "@/constants"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Box, Button, Divider, Grid, Link, Stack, Typography } from "@mui/material"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { HraQuizNavbar } from "../components/HraQuizNavbar"

export const WelcomeScreen = () => {
  const navigate = useNavigate()

  return (
    <>
      <Grid container>
        <Helmet title="Welcome Page" />
        <HraQuizNavbar />
        <Grid container direction="column" alignItems="center" sx={{ p: 20, backgroundColor: "colors.white" }}>
          <Grid item sx={{ textAlign: "center", pb: 10 }}>
            <Typography variant="h2tiempos">Find Out Which HRA is Right for You</Typography>
          </Grid>
          <Grid item sx={{ pb: 10 }}>
            <Typography variant="body1" sx={{ maxWidth: "45.125rem" }}>
              Answer a few questions and find out the right health reimbursement arrangement (HRA) structure for your
              organization. <br />
              <br />
              You'll recieve quiz results that break down why an HRA may be a good option. The pros and cons of them.
              And now to get started with setting one up.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              data-qa="find-hra-type-nav-button"
              sx={{ mt: 7, mb: 2 }}
              onClick={() => {
                navigate("/hra-determination/num_ft_ee")
              }}
            >
              Let's find your HRA type
            </Button>
          </Grid>
          <Grid item>
            <Link
              data-qa="welcome-screen-home-back-button"
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              justifyContent="center"
              href={EXTERNAL_LINKS.TCH_HOME_PAGE}
              sx={{ my: 4 }}
            >
              <ArrowBackIcon sx={{ color: "colors.darkBody" }} />
              <Typography variant="body1" color="colors.darkBody">
                Back to home page
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ backgroundColor: "colors.gumDropGreen" }}>
        <Grid item>
          <Box
            sx={{
              borderBottom: "0.438rem solid rgba(232, 210, 184, 1)",
              height: "62px",
              mb: "50px",
            }}
          >
            <Typography variant="h1" sx={{ my: 7, pt: "1.75rem" }}>
              How it works
            </Typography>
          </Box>
        </Grid>
        <Grid container justifyContent="center">
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 6, sm: 8, md: 15 }}
            divider={<Divider orientation="vertical" flexItem />}
            sx={{ pb: 18, pt: 5 }}
          >
            <Grid container direction="column" sx={{ p: 4 }}>
              <Grid item sx={{ pb: 8 }}>
                <Typography variant="h2tiempos">Do the quiz</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Tell us how many employees your organization has, the types of employees, reimbursement structure you
                  want, and a few more items. It should take around 5-10 minutes to complete. Once you complete the
                  quiz, you'll get a results summary breaking down the ideal HRA structure for your organization based
                  on your responses.
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" sx={{ p: 4 }}>
              <Grid item sx={{ pb: 8 }}>
                <Typography variant="h2tiempos">HRA plan design</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  After completing the short quiz, you will get results that include an HRA plan suggestion. Take
                  Command Health will provide a list of the pros and cons. Also included will be our QSEHRA and ICHRA
                  plan design guides walking you through the basics of each HRA type. You will be able to download and
                  save your results.
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" sx={{ p: 4 }}>
              <Grid item sx={{ pb: 8 }}>
                <Typography variant="h2tiempos">Create your account</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  After viewing your HRA type, you will have the option to start building your HRA plan. You can set
                  designations, allowance, criteria, and make your payment to have the HRA go live. Your organization
                  will be all set to start using an HRA.
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}
