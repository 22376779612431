import { takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { formatCents } from "@/utils/formatting"
import { Alert, Box, Button, Grid, Typography } from "@mui/material"
import { format, parseISO } from "date-fns"
import { useNavigate } from "react-router-dom"
import { ERROR_STATUS } from "../../dashboardConstants"
import { WidgetData, WidgetDataRetrievalStatus } from "../../dashboardTypes"
import { WidgetLayout } from "../WidgetLayout"

interface AutopayUpcomingFundingWidgetProps {
  isLoading: boolean
  data: WidgetData
  status: WidgetDataRetrievalStatus
}

const getNextFundingEventAmountCents = (data: WidgetData) =>
  data && "nextFundingEventAmountCents" in data ? data.nextFundingEventAmountCents : 0

const getNextFundingEventDate = (data: WidgetData) =>
  data && "nextFundingEventDate" in data ? format(parseISO(data.nextFundingEventDate!), "MM/dd/yyyy") : null

export const AutopayUpcomingFundingWidget = ({ isLoading, data, status }: AutopayUpcomingFundingWidgetProps) => {
  const navigate = useNavigate()
  const nextFundingEventAmountCents = getNextFundingEventAmountCents(data)
  const nextFundingEventDate = getNextFundingEventDate(data)
  const isError = !data || status === ERROR_STATUS
  const handleViewDetailsClick = () => navigate("/autopay?tab=funding-reports")

  return (
    <WidgetLayout isLoading={isLoading} data-qa={createDataQa("autopay", "widget")}>
      <Box>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography display="block" variant="h2small" mb={{ xs: 4, md: 0 }} flexBasis="50%">
              AutoPay upcoming funding
            </Typography>
          </Grid>
          {!isError && (
            <Grid item>
              <Button
                variant="contained"
                data-qa="view-details"
                onClick={handleViewDetailsClick}
                sx={{
                  mb: { xs: 2, md: 0 },
                }}
              >
                <Typography variant="body2">View details</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
        {isError ? (
          <Box mt={3}>
            <Alert severity="error">Error loading data, please refresh page.</Alert>
          </Box>
        ) : (
          <Box mt={4}>
            {nextFundingEventDate && nextFundingEventAmountCents >= 0 ? (
              <Typography variant="body1">
                <Typography variant="h2small" color={takeCommandPrimary.main}>
                  {`${formatCents(nextFundingEventAmountCents)}`}
                </Typography>{" "}
                on <strong>{`${nextFundingEventDate}`}</strong>
              </Typography>
            ) : (
              <Typography variant="body1">No upcoming funding event</Typography>
            )}
          </Box>
        )}
      </Box>
    </WidgetLayout>
  )
}
