import { useNotifications } from "@/services/notificationService"
import { colors } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { Uuid } from "@/utils/types"
import { useTheme } from "@emotion/react"
import { GetAppOutlined } from "@mui/icons-material"
import { Alert, Button, CircularProgress, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { ChartsItemContentProps, useDrawingArea } from "@mui/x-charts"
import { PieChart } from "@mui/x-charts/PieChart"
import { EMPTY_ENROLLMENT_STATUS_WIDGET_DATA, ERROR_STATUS } from "../../dashboardConstants"
import { useGetEnrollmentReportCsv } from "../../dashboardService"
import { WidgetData, WidgetDataRetrievalStatus } from "../../dashboardTypes"
import { WidgetLayout } from "../WidgetLayout"

interface PieCenterLabelProps {
  percentage: number
  label: string
}
const PieCenterLabel = ({ percentage, label }: PieCenterLabelProps) => {
  const theme = useTheme()
  const { width, height, left, top } = useDrawingArea()
  const centerX = left + width / 2
  const centerY = top + height / 2

  return (
    <>
      <text
        x={centerX}
        y={centerY - 10}
        textAnchor="middle"
        dominantBaseline="central"
        fill={theme.palette.text.primary}
      >
        {percentage}%
      </text>

      <text
        x={centerX}
        y={centerY + 10}
        textAnchor="middle"
        dominantBaseline="central"
        fill={theme.palette.text.primary}
      >
        {label}
      </text>
    </>
  )
}

const getCompletedData = (data: WidgetData) =>
  data && "completed" in data ? data.completed : EMPTY_ENROLLMENT_STATUS_WIDGET_DATA.completed

const getInProgressdData = (data: WidgetData) =>
  data && "inProgress" in data ? data.inProgress : EMPTY_ENROLLMENT_STATUS_WIDGET_DATA.inProgress

const getNotStartedData = (data: WidgetData) =>
  data && "notStarted" in data ? data.notStarted : EMPTY_ENROLLMENT_STATUS_WIDGET_DATA.notStarted

interface DownloadButtonAdornmentProps {
  isLoading?: boolean
  isError?: boolean
}

const DownloadButtonAdornment = ({ isLoading, isError }: DownloadButtonAdornmentProps) => {
  if (isError) {
    return null
  }

  if (isLoading) {
    return <CircularProgress size={24} color="inherit" />
  }

  return <GetAppOutlined />
}

interface EmployeeEnrollmentStatusWidgetProps {
  companyId: Uuid
  isLoading: boolean
  data: WidgetData
  status: WidgetDataRetrievalStatus
}

export const EmployeeEnrollmentStatusWidget = ({
  companyId,
  isLoading,
  data: widgetData,
  status,
}: EmployeeEnrollmentStatusWidgetProps) => {
  const { notify } = useNotifications("downloading-enrollment-report")
  const isError = !widgetData || status === ERROR_STATUS

  const {
    isFetching: isEnrollmentReportCsvLoading,
    isError: isEnrollmentReportCsvError,
    refetch: refetchEnrollmentReportCsv,
  } = useGetEnrollmentReportCsv(companyId)

  const completedData = getCompletedData(widgetData)
  const inProgressData = getInProgressdData(widgetData)
  const notStartedData = getNotStartedData(widgetData)
  const totalCompleted = completedData.active + completedData.optedOut + completedData.waived
  const totalNotStarted = notStartedData.invited + notStartedData.pendingInvite
  const totalInProgress = inProgressData.onBoarding
  const total = totalCompleted + totalNotStarted + totalInProgress
  const percentageCompleted = total === 0 ? 0 : Math.round((totalCompleted / total) * 100)
  const inProgressId = "inProgress"
  const completeId = "complete"
  const notStartedId = "notStarted"
  const hiddeAngle = percentageCompleted === 100 || percentageCompleted === 0

  const handleDownloadEnrollmentReportCsv = async () => {
    try {
      const { data } = await refetchEnrollmentReportCsv()

      if (data) {
        const blob = new Blob([data], { type: "text/csv" })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        const timestampString = new Date().toISOString().split("T")[0]
        a.download = `Employee Enrollment Report for ${timestampString}.csv`
        a.click()
        window.URL.revokeObjectURL(url)
      } else {
        notify("Failed to download the enrollment report. Please try again.", "error")
      }
    } catch (error) {
      console.error("Error fetching enrollment report CSV:", error)
      notify("An error occurred while downloading the report.", "error")
    }
  }

  const data = [
    { id: notStartedId, value: totalNotStarted, label: "Not Started", color: colors.bluePie },
    { id: inProgressId, value: totalInProgress, label: "In Progress", color: colors.orangePie },
    { id: completeId, value: totalCompleted, label: "Complete", color: colors.greenPie },
  ]

  const tooltipStyle = {
    backgroundColor: colors.greyToolTipPie,
    color: "white",
    padding: "0.5rem",
    borderRadius: "0.25rem",
    boxShadow: "0 0.125rem 0,625rem rgba(0, 0, 0, 0.2)",
    maxWidth: "9.375rem",
    display: "flex",
    flexDirection: "column",
    fontSize: "0.875rem",
  }

  const renderContent = (dataItem?: ChartsItemContentProps) => {
    if (dataItem?.itemData.dataIndex === 0) {
      return (
        <Tooltip key={dataItem?.itemData.seriesId} title={notStartedId} arrow>
          <Box sx={tooltipStyle}>
            <Typography>Not started ({totalNotStarted})</Typography>
            <Typography>Pending Invite ({notStartedData.pendingInvite})</Typography>
            <Typography>Invited ({notStartedData.invited})</Typography>
          </Box>
        </Tooltip>
      )
    } else if (dataItem?.itemData.dataIndex === 1) {
      return (
        <Tooltip key={dataItem?.itemData.seriesId} title={inProgressId} arrow>
          <Box sx={tooltipStyle}>
            <Box>In progress ({totalInProgress})</Box>
            <Box>Onboarding ({inProgressData.onBoarding})</Box>
          </Box>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip key={dataItem?.itemData.seriesId} title={completeId} arrow>
          <Box sx={tooltipStyle}>
            <Box>Complete ({totalCompleted})</Box>
            <Box>Waived ({completedData.waived})</Box>
            <Box>Opted-out ({completedData.optedOut})</Box>
            <Box>Active ({completedData.active})</Box>
          </Box>
        </Tooltip>
      )
    }
  }

  return (
    <WidgetLayout isLoading={isLoading} data-qa={createDataQa("employee", "enrollment", "widget")}>
      {isError ? (
        <Box>
          <Typography variant="h2small" display="block" mb={3}>
            Employee enrollment status
          </Typography>
          <Alert severity="error">Error loading data, please refresh page.</Alert>
        </Box>
      ) : (
        <>
          <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between" alignItems="center" gap={4}>
            <Typography display="block" variant="h2small" mb={{ xs: 4, md: 0 }} flexBasis="50%">
              Employee enrollment status
            </Typography>
            <Button
              variant="contained"
              endIcon={
                <DownloadButtonAdornment
                  isLoading={isEnrollmentReportCsvLoading}
                  isError={isEnrollmentReportCsvError}
                />
              }
              onClick={handleDownloadEnrollmentReportCsv}
              sx={{ flexBasis: "40%" }}
            >
              <Typography variant="body2">Download Report</Typography>
            </Button>
          </Box>
          <Box position="relative" display="flex" justifyContent="center" alignItems="center" mt={2}>
            <PieChart
              series={[
                {
                  data: data.filter(item => item.value > 0).map(item => ({ ...item })),
                  innerRadius: 80,
                  outerRadius: 100,
                  paddingAngle: hiddeAngle ? 0 : 1,
                },
              ]}
              width={400}
              height={300}
              tooltip={{ trigger: "item", itemContent: renderContent }}
              slotProps={{
                legend: { hidden: true },
              }}
            >
              {total !== 0 && <PieCenterLabel percentage={percentageCompleted} label="Complete" />}
            </PieChart>
          </Box>
          <Box>
            {data.map((item, index) => (
              <Box
                key={item.label}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom={index < data.length - 1 ? `0.0625rem solid ${colors.lightGreyBorder}` : "none"}
                pb={1}
                mb={4}
              >
                <Box display="flex" alignItems="center">
                  <Box sx={{ width: 12, height: 12, backgroundColor: item.color, borderRadius: "50%", mr: 1 }} />
                  <Typography variant="body2">{item.label}</Typography>
                </Box>
                <Box display="flex" alignItems="center" flex="0 0 57%">
                  <Typography variant="body2">{item.value}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
    </WidgetLayout>
  )
}
