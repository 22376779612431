import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { CompanyAutopay } from "@/features/Settings/components/Tabs/CompanyAutopay"
import { useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import { OnboardingStep } from "../components/OnboardingStep"
import { AUTOPAY_COMPLETED } from "../employerOnboardingConstants"

export const AutoPay = () => {
  const { user } = useAuth()
  const company = getActiveCompany(user)
  const companyId = company?.companyId
  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const handleContinue = () => {
    queryClient.invalidateQueries({ queryKey: ["onboarding-statuses", companyId] })

    navigate("/employer-onboarding")
  }

  return (
    <OnboardingStep title="AutoPay" stepName={AUTOPAY_COMPLETED} handleContinue={handleContinue}>
      <CompanyAutopay />
    </OnboardingStep>
  )
}
