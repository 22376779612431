import { SubSectionDivider } from "@/components/ComponentLibrary"
import { LoadingContentArea } from "@/components/LoadingContentArea"
import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { StyledCard } from "@/components/StyledCard"
import { BaseTable, OrderByItemType } from "@/components/Table/Table"
import { TABLE_CELL_PADDING } from "@/constants"
import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import { convertHraClassModelToCustomClassDetails } from "@/features/Benefits/benefitsUtils"
import { useGetHraPlan } from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupService"
import { HraPlanModel } from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupTypes"
import { MultipleClassesGrid } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/CustomClasses"
import { useGetCurrentClasses } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureService"
import { CustomClassDetails } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureTypes"
import { useGetCompany } from "@/features/CreateCompany/components/Steps/Setup/setupService"
import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import {
  useGetCompanyOnboardingStatus,
  useGetEmployerOnboardingStatuses,
} from "@/features/EmployerOnboarding/employerOnboardingService"
import { adminPeopleFilter, useGetPeople } from "@/features/People/peopleService"
import { useGetPaymentDetails } from "@/features/Settings/hooks/useGetPaymentDetails"
import { StripeId } from "@/features/Settings/types/paymentTypes"
import { formatDateToMmDdYyyy } from "@/utils/dates"
import { DATE_FORMAT_MONTH_DAY_YEAR, formatCents, toTitleCase, transformDate } from "@/utils/formatting"
import { IsoDateString, Uuid } from "@/utils/types"
import { EditOutlined } from "@mui/icons-material"
import { Button, Divider, Grid, TableCell, Typography } from "@mui/material"
import { constant, noop } from "lodash"
import { useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router-dom"
import { AdminPersonModel, AdminUsersTableHeader } from "../../tcHubTypes"
import { getStatusValue } from "../TcHubCompaniesTable"

const adminRoleSelector = (person: AdminPersonModel) => person.roles.map(role => role.name).join(", ")

const headers: AdminUsersTableHeader[] = [
  { id: "firstName", label: "First", sortable: true, field: "firstName", alignment: "left" },
  { id: "lastName", label: "Last", sortable: true, field: "lastName", alignment: "left" },
  {
    id: "email",
    label: "Email",
    field: row => row.email,
    sortable: true,
    alignment: "left",
  },
  {
    id: "phone",
    label: "Phone #",
    sortable: true,
    field: row => row.phoneNumber!,
    alignment: "left",
  },
  {
    id: "role",
    label: "Role",
    sortable: true,
    field: (row: AdminPersonModel) => row.roles.map(role => role.name).join(", "),
    alignment: "left",
  },
  {
    id: "serviceContact",
    label: "Service Contact",
    sortable: true,
    field: row => row.serviceContact!,
    alignment: "left",
  },
  {
    id: "billingContact",
    label: "Billing Contact",
    sortable: true,
    field: row => row.billingContact!,
    alignment: "left",
  },
]

const matchUserLastName = (user: AdminPersonModel, searchQueryLowerCase: string) =>
  (user.lastName ?? "").toLowerCase().includes(searchQueryLowerCase)

const matchUserEmail = (user: AdminPersonModel, searchInputValue: string) => !!user.email?.includes(searchInputValue)

interface CompanySectionProps {
  company: CompanyModel | null
  stripeId: StripeId | null
}

const CompanySection = ({ company, stripeId }: CompanySectionProps) => {
  const { companyId } = useParams<{ companyId: string }>()
  const { data } = useGetEmployerOnboardingStatuses(companyId!)
  const todayDate = new Date()
  const formattedDate = formatDateToMmDdYyyy(todayDate)
  const inviteDateEmailObject = data?.find(item => item.step === "INVITE_DATE_SET")

  const inviteDateStatus = inviteDateEmailObject?.statusValue
    ? formatDateToMmDdYyyy(inviteDateEmailObject.statusValue)
    : formattedDate

  return (
    <StyledCard>
      <Typography variant="h5">Company Details</Typography>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={12} data-qa="tc-hub-company-profile-details">
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-name">
            Company Name:
          </Typography>
          <Typography variant="body1">{company?.companyInfo.companyName ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-business-structure">
            Business Structure:
          </Typography>
          <Typography variant="body1">{company?.companyInfo.companyType ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-ein">
            EIN:
          </Typography>
          <Typography variant="body1">{company?.companyInfo.ein ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-industry">
            Industry:
          </Typography>
          <Typography variant="body1">{company?.companyInfo.industry ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-industry">
            Invitation email:
          </Typography>
          <Typography variant="body1">{inviteDateStatus ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-stripe-id">
            Stripe ID:
          </Typography>
          <Typography variant="body1">{stripeId ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-address">
            Company Address:
          </Typography>
          <Typography variant="body1">{company?.companyAddress.streetAddress ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-suite">
            Suite/Apt:
          </Typography>
          <Typography variant="body1">{company?.companyAddress.suiteApt ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-city">
            City:
          </Typography>
          <Typography variant="body1">{company?.companyAddress.city ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-zip">
            ZIP code:
          </Typography>
          <Typography variant="body1">{company?.companyAddress.zip ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-state">
            State:
          </Typography>
          <Typography variant="body1">{company?.companyAddress.state ?? ""}</Typography>
        </Grid>
      </Grid>
    </StyledCard>
  )
}

interface HraPlanSectionProps {
  hraPlan: HraPlanModel | null
  company: CompanyModel | null
  monthlyAmountCents: string
  billingDate: IsoDateString
}

const HraPlanSection = ({ hraPlan, company, monthlyAmountCents, billingDate }: HraPlanSectionProps) => (
  <StyledCard>
    <Typography variant="h5">HRA Plan Details</Typography>
    <Divider sx={{ my: 3 }} />
    <Grid container spacing={12} data-qa="tc-hub-hra-plan-details">
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="hra-plan-detail-package-name">
          Package Name:
        </Typography>
        <Typography variant="body1">{hraPlan?.packageName ?? ""}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-business-structure">
          Hra Start Date:
        </Typography>
        <Typography variant="body1">
          {hraPlan?.hraStartDate ? transformDate(hraPlan.hraStartDate, DATE_FORMAT_MONTH_DAY_YEAR) : ""}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-ein">
          Billing Date:
        </Typography>
        <Typography variant="body1">
          {billingDate ? transformDate(billingDate, DATE_FORMAT_MONTH_DAY_YEAR) : ""}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-industry">
          Report Date:
        </Typography>
        <Typography variant="body1">{hraPlan?.dayOfMonth ?? ""}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-stripe-id">
          Report Frequency:
        </Typography>
        <Typography variant="body1">{hraPlan?.statementFrequency ?? ""}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-address">
          Monthly Amount:
        </Typography>
        <Typography variant="body1">{monthlyAmountCents ?? ""}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-suite">
          Annual or Quarterly Compliance:
        </Typography>
        <Typography variant="body1">{hraPlan?.complianceFrequency ?? ""}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-suite">
          Show AutoPay Setup:
        </Typography>
        <Typography variant="body1">{company?.companyInfo.autoPayAvailableOption ? "Yes" : "No"}</Typography>
      </Grid>
    </Grid>
  </StyledCard>
)

const defaultOrder = { headCellId: "name", direction: "asc" } as const

interface AdminTableProps {
  searchInputValue: string
  users: AdminPersonModel[]
  isLoading: boolean
}

export const AdminTable = ({ searchInputValue, users, isLoading }: AdminTableProps) => {
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(25)
  const [orderBy, setOrderBy] = useState<OrderByItemType[]>([defaultOrder])

  const filteredUsers = (users ?? []).filter(user => {
    const searchQueryLowerCase = searchInputValue.toLowerCase()

    return matchUserLastName(user, searchQueryLowerCase) || matchUserEmail(user, searchInputValue)
  })

  return isLoading ? (
    <SkeletonTableLoader
      data-qa="skeleton-table-loader-admins"
      headerTitles={headers.map(cell => `${cell.label}`)}
      bodyRowsCount={3}
    />
  ) : (
    <Grid hidden={users.length === 0}>
      <SubSectionDivider>
        <Typography variant="h5" textAlign="left">
          Admin Details
        </Typography>
      </SubSectionDivider>
      {filteredUsers.length > 0 ? (
        <BaseTable
          rows={filteredUsers}
          selected={[]}
          searchCriteria=""
          onToggleSelect={noop}
          onToggleSelectAll={noop}
          onPageChange={changedPage => setPage(changedPage)}
          onRowsPerPageChange={rows => setRowsPerPage(rows)}
          uniqueIdSelector={constant("")}
          headCells={headers ?? []}
          rowsPerPage={rowsPerPage}
          page={page}
          fullWidth
          orderBy={orderBy}
          onToggleOrderBy={headId =>
            setOrderBy(state => {
              const result = [] as OrderByItemType[]

              if (state && state.length > 0 && state[0].headCellId === headId) {
                result.push({
                  headCellId: headId,
                  direction: state[0].direction === "asc" ? "desc" : "asc",
                })
              } else {
                result.push({ headCellId: headId, direction: "asc" })
              }
              if (defaultOrder.headCellId !== headId) {
                result.push(defaultOrder)
              }

              return result
            })
          }
        >
          {({ row }) => (
            <>
              <TableCell>
                <Typography variant="body1" data-qa="users-first-name" sx={TABLE_CELL_PADDING}>
                  {row.firstName}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                  {row.lastName}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                  {row.email}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                  {toTitleCase(row.phoneNumber ?? "")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                  {adminRoleSelector(row)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                  {row.serviceContact}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                  {row.billingContact}
                </Typography>
              </TableCell>
            </>
          )}
        </BaseTable>
      ) : null}
    </Grid>
  )
}

interface ClassSectionProps {
  classes: CustomClassDetails[]
}

const ClassSection = ({ classes }: ClassSectionProps) => (
  <StyledCard>
    <Typography variant="h5">Classes</Typography>
    <MultipleClassesGrid
      classes={classes}
      toggleSelection={noop}
      isSelected={constant(false)}
      createClass={noop}
      canAddNewClass={false}
    />
  </StyledCard>
)

interface Params extends Record<string, string | undefined> {
  companyId: Uuid
}

export const TcHubCompanyProfilePage = () => {
  const { companyId } = useParams<Params>()
  const { data: company, isLoading } = useGetCompany(companyId!)
  const { data: onboardingStatuses } = useGetCompanyOnboardingStatus(companyId!)
  const { data: hraPlans } = useGetHraPlan(companyId)
  const getPeopleResults = useGetPeople(companyId!, adminPeopleFilter)
  const adminPeople = getPeopleResults.data ?? []
  const navigate = useNavigate()

  const currentHraPlan = useMemo(
    () => ({
      ...(hraPlans ? hraPlans[0] : ({ id: "" } as unknown as HraPlanModel)),
      packageName: getStatusValue(onboardingStatuses, "packageName"),
    }),
    [hraPlans, onboardingStatuses]
  )

  const { data: classes } = useGetCurrentClasses(companyId!, currentHraPlan.id)

  const customClassDetails = useMemo(
    () => (classes ? convertHraClassModelToCustomClassDetails(classes) : []),
    [classes]
  )

  const { paymentDetails } = useGetPaymentDetails({ companyId })

  return isLoading ? (
    <LoadingContentArea data-qa="tc-hub-company-profile-page-loading" />
  ) : (
    <TcHubGuard requiredPermissions={["tc_hub_companies"]}>
      <Grid container data-qa="tc-hub-company-profile-page" direction="column">
        <Helmet title="TC Hub Company Profile" />
        <Grid container justifyContent="space-between">
          <Typography variant="h1" gutterBottom display="inline">
            Company Information
          </Typography>
          <Grid item>
            <Button
              data-qa="edit-company-button"
              variant="contained"
              onClick={() => navigate(`edit`)}
              startIcon={<EditOutlined />}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <CompanySection company={company!} stripeId={paymentDetails.billingProviderId!} />
        <HraPlanSection
          hraPlan={currentHraPlan}
          company={company!}
          monthlyAmountCents={formatCents(paymentDetails.amountDueCents!)}
          billingDate={paymentDetails.billingDate!}
        />
        <AdminTable searchInputValue="" users={adminPeople} isLoading={getPeopleResults.isLoading} />
        <ClassSection classes={customClassDetails} />
      </Grid>
    </TcHubGuard>
  )
}
