import { toTitleCase } from "@/utils/formatting"
import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { defaultMemoize as memoize } from "reselect"
import { getAllFundingEntities } from "../../tcHubEndpoints"
import { FundingEntityModel } from "../../tcHubTypes"

export const useFundingEntities = () =>
  useQuery({ queryKey: ["getAllFundingEntities"], queryFn: () => getAllFundingEntities() })

export const useAccountsPageParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  return useMemo(() => {
    const currentParams = Object.fromEntries(searchParams.entries())

    return {
      fundingEventStatus: searchParams.get("status") ?? "",
      setFundingEventStatus: (status: string) => setSearchParams({ ...currentParams, status }),
      fundingEntityType: searchParams.get("accountType") ?? "",
      setFundingEntityType: (entityType: string) => setSearchParams({ ...currentParams, accountType: entityType }),
    }
  }, [searchParams, setSearchParams])
}

export const getFundingEntityStatus = memoize((fundingEntities: FundingEntityModel[] | undefined) => {
  const fundingEntityStatusOptions = new Set<string>()

  fundingEntities?.forEach(fundingEntity => {
    fundingEntityStatusOptions.add(fundingEntity.status)
  })

  return Array.from(fundingEntityStatusOptions).map(status => ({ value: status, label: toTitleCase(status) }))
})

export const getFundingEntityType = memoize((fundingEntities: FundingEntityModel[] | undefined) => {
  const fundingEntityTypeOptions = new Set<string>()

  fundingEntities?.forEach(fundingEntity => {
    fundingEntityTypeOptions.add(fundingEntity.entityType)
  })

  return Array.from(fundingEntityTypeOptions).map(entityType => ({ value: entityType, label: toTitleCase(entityType) }))
})
