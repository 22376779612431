import { DatePickerField } from "@/components/DatePickerField"
import { FileUploader } from "@/components/FileUploader"
import { FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { Page404 } from "@/features/Auth/pages/Page404"
import { getShoppingSessions } from "@/features/BenefitsElection/benefitsElectionEndpoints"
import { useBenefitsElectionStore } from "@/features/BenefitsElection/benefitsElectionStore"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { getOnlyDate } from "@/utils/dates"
import { transformDate } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import { getFileBase64, removeBase64Metadata } from "@/utils/util"
import { Grid, Typography } from "@mui/material"
import { useFeatureFlag } from "configcat-react"
import { endOfDay, startOfDay, subDays } from "date-fns"
import { Formik } from "formik"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useAuth } from "../../Auth/useAuth"
import { QUALIFYING_EVENT_SUPPORT_DOCUMENT } from "../../Documents/documentsConstants"
import { useCreateDocument } from "../../Documents/documentsService"
import { DashboardLayout } from "../components/DashboardLayout"
import { MAX_DAYS_FOR_QLE, MAX_QLE_FILES, QLE_OPTIONS, QLE_VALUES } from "../dashboardConstants"
import { useCreateQle } from "../dashboardService"
import { QlePayload, QleReason } from "../dashboardTypes"

const dataQa = "qle"

interface QleValues {
  qleDate: Date
  qleReason: QleReason
}

const QLE_INITIAL_VALUES = {
  qleDate: "",
  qleReason: "",
}

const getMinDate = () => startOfDay(subDays(new Date(), MAX_DAYS_FOR_QLE))
const getMaxDate = () => endOfDay(new Date())

export const qleValidationSchema = Yup.object().shape({
  qleDate: Yup.lazy(() =>
    Yup.date()
      .nullable()
      .required("Event date is required")
      .typeError("Use MM/DD/YYYY format")
      .min(getMinDate(), `The event date must be within the last ${MAX_DAYS_FOR_QLE} days`)
      .max(getMaxDate(), "The event date cannot be in the future")
  ),
  qleReason: Yup.string().oneOf(QLE_VALUES, "Please select a valid option").required("Please select an event"),
})

export const QlePage = () => {
  const { value: qleEnabledValue } = useFeatureFlag("qleEnabled", false)
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
  const navigate = useNavigate()
  const { refresh, user } = useAuth()
  const { notify } = useNotifications("qle")
  const companyId = user?.company?.companyId as Uuid
  const employeeId = user?.company?.employeeId as Uuid
  const { mutateAsync: createDocument, isPending: isDocumentPending } = useCreateDocument(companyId)
  // FUTURE: Use this variable to update QLE data and remove this eslint-disable directive
  const { mutateAsync: createQle, isPending: isQlePending } = useCreateQle()
  const setShoppingSession = useBenefitsElectionStore(state => state.setCurrentShoppingSession)

  const navigateToDashboard = () => {
    navigate("/")
  }

  const handleContinue = async (values: QleValues) => {
    try {
      const file = uploadedFiles[0]
      const base64 = await getFileBase64(file)
      const document = removeBase64Metadata(base64)
      const documentName = `qle-proof-${file.name}`

      const { id: qleDocumentId } = await createDocument({
        document,
        documentName,
        documentType: QUALIFYING_EVENT_SUPPORT_DOCUMENT,
        employeeId,
      })

      const payload: QlePayload = {
        qleReason: values.qleReason,
        qleDate: transformDate(values.qleDate),
        qleDocumentId,
        companyId,
        employmentId: employeeId,
      }

      const qleResponse = await createQle(payload)

      //update user context -- user.shoppingSession
      await refresh()

      const shoppingSessionDetails = await getShoppingSessions(
        employeeId,
        qleResponse.targetEnrollmentTimePeriodId!,
        true
      )
      const shoppingSessionId = shoppingSessionDetails?.[0].id
      setShoppingSession(shoppingSessionDetails?.[0])

      navigate(`/benefits-election/${shoppingSessionId}/welcome`)

      notify("Succesfully saved your qualifying life event data", "success")
    } catch (error) {
      console.error(error)
      notify("Error uploading qualifying life event data. Please try again later.", "error")
    }
  }

  if (!qleEnabledValue) {
    return <Page404 />
  } else {
    return (
      <DashboardLayout>
        <Typography variant="h1" mb={2}>
          Has your situation recently changed?
        </Typography>
        <Typography mb={8}>
          If you have recently had a change in your situation — like getting married, having a baby, or losing health
          coverage — that can make you eligible for a Special Enrollment Period, allowing you to enroll in health
          insurance outside the yearly Open Enrollment Period.
        </Typography>

        <Formik
          initialValues={QLE_INITIAL_VALUES}
          onSubmit={async values => {}}
          validationSchema={qleValidationSchema}
          validateOnMount
          validateOnChange
        >
          {({ values, touched, errors, isValid, handleChange, handleBlur, setFieldTouched, setFieldValue }) => {
            const isEventDataValid = isValid && uploadedFiles.length > 0
            const disableContinue = !isEventDataValid

            return (
              <form data-qa="qle-form">
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="h6">When did the qualifying event take place?</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} mb={4}>
                    <DatePickerField
                      name="qleDate"
                      value={getOnlyDate(values.qleDate ?? null)}
                      onBlur={handleBlur}
                      error={Boolean(touched.qleDate && errors.qleDate)}
                      helperText={touched.qleDate ? `${errors.qleDate ?? ""}` : ""}
                      fullWidth
                      required
                      variant="outlined"
                      onChange={handleChange}
                      data-qa={createDataQa(dataQa, "date")}
                      maxDate={new Date()}
                      minDate={subDays(new Date(), MAX_DAYS_FOR_QLE)}
                    />
                  </Grid>
                  <Grid item xs={12} mb={4}>
                    <RadioGroupCard
                      name="qleReason"
                      formName="qle-form"
                      elements={QLE_OPTIONS}
                      value={values.qleReason}
                      handleChange={setFieldValue}
                      required
                      label="What was the event that qualifies you for a special enrollment period? "
                      labelVariant="h6"
                      maxCardsPerRow={2}
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Typography variant="h5">Awesome! Now, upload proof of qualifying life event. </Typography>
                    <Typography mt={2}>
                      We’ll need documentation to verify the date of your qualifying life event. Once that's confirmed,
                      you'll be ready to start shopping for your health plan!
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FileUploader
                      maxFiles={MAX_QLE_FILES}
                      fileFormatDescription="JPG, JPEG, PNG or PDF"
                      accept={{
                        "image/jpeg": [".jpeg", ".jpg"],
                        "image/png": [".png"],
                        "application/pdf": [".pdf"],
                      }}
                      maxSize={3000000}
                      uploadedFiles={uploadedFiles}
                      setUploadedFiles={setUploadedFiles}
                    />
                  </Grid>

                  <Grid item xs={12} my={8}>
                    <FlowNavigationButtons
                      handleContinue={() => handleContinue(values as unknown as QleValues)}
                      handleBack={navigateToDashboard}
                      disabled={disableContinue}
                      isSubmitting={isQlePending || isDocumentPending}
                    />
                  </Grid>
                </Grid>
              </form>
            )
          }}
        </Formik>
      </DashboardLayout>
    )
  }
}
