import { PURCHASE_HRA_STEPS } from "../CreateCompany/createCompanyConstants"

export const EMPLOYER_ONBOARDING = "employer-onboarding"
export const HRA_DOCUMENTS = "hra-documents"
export const EMPLOYEES = "employees"
export const AUTOPAY = "autopay"
export const INVITATIONS = "invitations"
export const SUBSCRIPTION_BILLING = "subscription-billing"
export const SUCCESS = "success"

export const EMPLOYER_ONBOARDING_PATHS = [HRA_DOCUMENTS, EMPLOYEES, AUTOPAY, INVITATIONS, SUBSCRIPTION_BILLING] as const

export const BILLING_SETUP = "BILLING_SETUP"
export const DOCUMENTS_VIEWED = "DOCUMENTS_VIEWED"
export const ADMIN_CLASS_ASSIGNMENT = "ADMIN_CLASS_ASSIGNMENT"
export const AUTOPAY_COMPLETED = "AUTOPAY_COMPLETED"
export const QUARTERLY_COMPLIANCE = "QUARTERLY_COMPLIANCE"
export const INVITE_DATE_SET = "INVITE_DATE_SET"
export const ONBOARDING_COMPLETE = "ONBOARDING_COMPLETE"

export const EMPLOYER_ONBOARDING_STEPS = [
  ...PURCHASE_HRA_STEPS,
  BILLING_SETUP,
  DOCUMENTS_VIEWED,
  ADMIN_CLASS_ASSIGNMENT,
  AUTOPAY_COMPLETED,
  QUARTERLY_COMPLIANCE,
  INVITE_DATE_SET,
  ONBOARDING_COMPLETE,
] as const
