import { takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined"
import { Checkbox, FormControl, ListItemText, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material"
import { PEOPLE_ACTIVE_STATUS, PEOPLE_STATUS_TO_VARIANT } from "../peopleConstants"

interface PeopleTableFilterDropdownProps {
  filterStatusValues: string[]
  handleStatusValuesChange: (statusValues: string[]) => void
}

export const PeopleTableFilterDropdown = ({
  filterStatusValues,
  handleStatusValuesChange,
}: PeopleTableFilterDropdownProps) => {
  const handleStatusFilterChange = (event: SelectChangeEvent<typeof filterStatusValues>) => {
    const {
      target: { value },
    } = event

    handleStatusValuesChange(Array.from(value))
  }

  return (
    <FormControl>
      <Select
        data-qa="people-table-filter-dropdown"
        id="demo-multiple-checkbox"
        multiple
        displayEmpty
        value={filterStatusValues}
        onChange={handleStatusFilterChange}
        size="small"
        renderValue={() => (
          <Stack direction="row">
            <FilterAltOutlinedIcon sx={{ mr: 2 }} />
            Filter
          </Stack>
        )}
        sx={{
          color: takeCommandPrimary.main,
          backgroundColor: "white",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: takeCommandPrimary.main,
            borderRadius: "0.5rem",
          },
        }}
      >
        {Object.entries(PEOPLE_ACTIVE_STATUS).map(([statusName, statusLabel]) => (
          <MenuItem key={statusName} value={statusName} data-qa={createDataQa("filter-item", statusName)}>
            <Checkbox checked={filterStatusValues.includes(statusName)} />
            <ListItemText primary={PEOPLE_STATUS_TO_VARIANT[statusLabel].label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
