import { ZipCode } from "@/features/BenefitsElection/benefitsElectionTypes"
import { getCountiesFromZip } from "@/features/CreateCompany/createCompanyEndpoints"
import { CountiesModel } from "@/features/CreateCompany/createCompanyTypes"
import { Autocomplete, CircularProgress, SxProps, TextField, Typography } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { debounce, isNil } from "lodash"
import { useCallback, useState } from "react"

const ZIP_CODE_MIN_LENGTH = 4
const ZIP_CODE_MAX_LENGTH = 5

interface ZipCodeCountyAutoCompleteProps {
  error?: string
  name: string
  handleBlur: any
  sx?: SxProps
  touched: boolean
  value: ZipCode
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  handleChange?: (value: CountiesModel | null) => void
}

/**
 * FUTURE: create a FormSearchField component based on this one
 * (allows value, validations, handleBlur, setField, ...)
 */
export const ZipCodeCountyAutoComplete = ({
  error,
  name,
  handleBlur,
  sx,
  touched,
  value: propsValue,
  setFieldValue,
  handleChange,
}: ZipCodeCountyAutoCompleteProps) => {
  // searchTerm and inputValue are kept independent to avoid triggering search on option select
  const [searchTerm, setSearchTerm] = useState("")
  const [inputValue, setInputValue] = useState("")
  const searchTermHasMinimumLength = searchTerm?.length >= ZIP_CODE_MIN_LENGTH

  const {
    data: options,
    isLoading: loading,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: ["searchZipCode", searchTerm],
    queryFn: () => getCountiesFromZip(searchTerm),
    enabled: searchTermHasMinimumLength,
  })

  const finishedFetching = isSuccess || isError

  // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
  // More info: https://react.dev/reference/rules/rules-of-hooks
  // This has since been promoted to a hard error
  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputChange = useCallback(
    debounce(newValue => setSearchTerm(newValue), 300),
    []
  )

  return (
    <Autocomplete
      autoComplete
      forcePopupIcon={false}
      data-qa="zip-code-autocomplete"
      filterOptions={x => x}
      getOptionLabel={option => `${option.zipCode} - ${option.countyName}`}
      isOptionEqualToValue={(option, newValue) =>
        option.zipCode === newValue.zipCode && option.countyName === newValue.countyName
      }
      loading={loading}
      noOptionsText={searchTermHasMinimumLength && finishedFetching ? "No counties found" : "Type to search"}
      onInputChange={(_, value, reason) => {
        setInputValue(value)
        if (reason === "input") {
          handleInputChange(value)
        } else if (reason === "reset" || reason === "clear") {
          setSearchTerm("")
        }
      }}
      options={options ?? []}
      sx={sx}
      value={propsValue ?? null}
      inputValue={inputValue}
      onChange={(e, newValue) => {
        setFieldValue(name, newValue)
        handleChange?.(newValue)
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.zipCode + option.countyName}>
          <Typography variant="body1">
            {option.zipCode} - {option.countyName}, {option.state}
          </Typography>
        </li>
      )}
      renderInput={({ InputProps, ...params }) => (
        <TextField
          {...params}
          error={!isNil(error) && touched}
          helperText={touched && error && "Please select a valid zip code"}
          label="ZIP Code and County"
          name={name}
          onBlur={handleBlur}
          placeholder="Search a ZIP Code and County"
          required
          InputProps={{
            ...InputProps,
            endAdornment: loading ? (
              <CircularProgress color="inherit" size={20} sx={{ mx: 3 }} />
            ) : (
              InputProps.endAdornment
            ),
          }}
          inputProps={{ ...params.inputProps, maxLength: ZIP_CODE_MAX_LENGTH }}
        />
      )}
    />
  )
}
