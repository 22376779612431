import { createDataQa } from "@/utils/dataQa"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Box, Button, Card, Grid, IconButton, SxProps, Typography } from "@mui/material"
import { times } from "lodash"
import { useNavigate } from "react-router-dom"
import { COMPARE_PLANS } from "../../benefitsElectionConstants"
import { useShoppingUrl } from "../../benefitsElectionService"
import { Plan } from "../../benefitsElectionTypes"
import { CarrierLogo } from "../../components/PlanComponents"

const dataQa = "comparison-footer"

const compareCardStyle: SxProps = {
  bgcolor: "inherit",
  px: 3,
  pt: 2,
  pb: 3,
  flex: "1 1 0",
  minWidth: { xs: "16rem", md: "" },
  maxWidth: "40ch",
  border: "1px solid",
  boxShadow: "none",
  display: "flex",
  justifyContent: "space-between",
}

export interface ComparePlanCardProps {
  plan: Plan
  handleDelete: () => void
}

const ComparePlanCard = ({ plan: { logoUrl, carrierName, displayName }, handleDelete }: ComparePlanCardProps) => (
  <Card sx={compareCardStyle} data-qa={createDataQa(dataQa, "plan-card")}>
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        minWidth: 0,
      }}
    >
      <CarrierLogo
        carrierName={carrierName}
        logoUrl={logoUrl}
        sx={{ height: "1.125rem" }}
        data-qa={createDataQa(dataQa, "plan-card", "logo")}
      />

      <Typography
        variant="body2"
        mt={2}
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        data-qa={createDataQa(dataQa, "plan-card", "title")}
      >
        {displayName}
      </Typography>
    </Box>
    <Box>
      <IconButton onClick={handleDelete} sx={{ p: 0 }} data-qa={createDataQa(dataQa, "plan-card", "delete-button")}>
        <CloseOutlinedIcon />
      </IconButton>
    </Box>
  </Card>
)

const numberStrings = ["First", "Second", "Third", "Fourth"]

const emptyCardStyle = {
  ...compareCardStyle,
  bgcolor: "colors.lightBlack",
  border: "1px dashed",
  borderColor: "colors.lightGray",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

export interface EmptyCardProps {
  index: number
}

const EmptyCard = ({ index }: EmptyCardProps) => (
  <Card sx={emptyCardStyle} data-qa={createDataQa(dataQa, "plan-card", "empty")}>
    {numberStrings[index]} Plan
  </Card>
)

const footerStyle: SxProps = {
  bgcolor: "colors.regionGray",
  position: "fixed",
  bottom: "0",
  left: "0",
  right: "0",
  zIndex: 10,
  py: { xs: 4, md: 6 },
  px: { xs: 6, md: 8 },
}

export interface ComparePlansFooterProps {
  plansToCompare: Plan[]
  handleDeletePlan: (plan: Plan) => void
  handleCancelComparison: () => void
  persistPlansState: () => void
}

export const ComparePlansFooter = ({
  plansToCompare,
  handleDeletePlan,
  handleCancelComparison,
  persistPlansState,
}: ComparePlansFooterProps) => {
  const isOpen = plansToCompare.length > 0
  const navigate = useNavigate()
  const shoppingUrl = useShoppingUrl()

  return (
    <Box
      data-qa={createDataQa(dataQa)}
      sx={{
        ...footerStyle,
        visibility: !isOpen ? "hidden" : "visible",
        transition: "all .2s",
        opacity: !isOpen ? "0" : "1",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={10} xl={11}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 4,
              overflowX: { xs: "scroll", md: "hidden" },
              py: { xs: 0, md: 4 },
            }}
          >
            {times(4, index => {
              if (index < plansToCompare.length) {
                const currentPlan = plansToCompare[index]

                return (
                  <ComparePlanCard
                    key={`card-${currentPlan.id}`}
                    plan={currentPlan}
                    handleDelete={() => handleDeletePlan(currentPlan)}
                  />
                )
              } else {
                return <EmptyCard key={`card-${numberStrings[index]}`} index={index} />
              }
            })}
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          xl={1}
          gap={2}
          sx={{
            display: "flex",
            flexDirection: { xs: "row-reverse", md: "column" },
            gap: 2,
          }}
        >
          <Button
            data-qa={createDataQa(dataQa, "compare-button")}
            variant="contained"
            fullWidth
            disabled={plansToCompare.length < 2}
            onClick={() => {
              persistPlansState()
              navigate(shoppingUrl + COMPARE_PLANS)
            }}
          >
            Compare
          </Button>
          <Button
            color="inherit"
            onClick={handleCancelComparison}
            sx={{ minWidth: "6.5rem" }}
            data-qa={createDataQa(dataQa, "cancel-button")}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
