import { CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, SxProps } from "@mui/material"
import { FieldHookConfig, useField } from "formik"
import { at } from "lodash"

export interface SelectFieldProps {
  label?: string
  data: ReadonlyArray<{ value: string; label: string }>
  name: string
  value?: any
  placeholder?: string
  required?: boolean
  dataQa?: string
  sx?: SxProps
  loading?: boolean
  handleChange?: (e: any) => void
}

export const SelectField = ({
  name,
  label,
  value,
  data,
  placeholder,
  required,
  disabled,
  loading,
  sx = {},
  dataQa,
  handleChange,
}: SelectFieldProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(name)
  const selectedValue = value ?? field.value
  const [touched, error] = at(meta, "touched", "error")
  const isError = touched && error

  return (
    <FormControl fullWidth error={!!isError}>
      <InputLabel shrink required={required} id={`test-select-${label}`}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        {...field}
        onChange={e => {
          handleChange?.(e)
          field.onChange(e)
        }}
        label={label}
        value={selectedValue}
        notched
        required={required}
        labelId={`test-select-${label}`}
        MenuProps={{
          sx: {
            maxHeight: 350,
          },
        }}
        sx={{
          ...{
            "& .MuiSelect-select .notranslate::after": placeholder
              ? {
                  content: `"${placeholder}"`,
                  opacity: 0.42,
                }
              : {},
          },
          ...{ textAlign: "start" },
          ...sx,
        }}
        data-qa={dataQa}
        inputProps={{ "data-qa": `${dataQa}-select-input` }}
      >
        {loading && (
          <MenuItem disabled>
            <CircularProgress size={24} />
          </MenuItem>
        )}
        {data.map(item => (
          <MenuItem key={item.value} value={item.value} data-qa={`${dataQa}-option-${item.value}`}>
            {item.label}
          </MenuItem>
        ))}
      </Select>

      {isError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
