import MoreVertIcon from "@mui/icons-material/MoreVert"
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import { SyntheticEvent, useState } from "react"
import { useNavigate } from "react-router-dom"

interface TcHubCompaniesDropdownProps {
  companyId: string
}

export const TcHubCompaniesDropdown = ({ companyId }: TcHubCompaniesDropdownProps) => {
  const [showCompanieOptions, setShowCompaniesOptions] = useState<any>(null)
  const navigate = useNavigate()

  const showMenu = (event: SyntheticEvent) => {
    setShowCompaniesOptions(event.currentTarget)
  }

  const closeMenu = () => {
    setShowCompaniesOptions(null)
  }

  return (
    <div data-qa={`icon-more-actions-${companyId}`}>
      <Tooltip title="Options">
        <IconButton
          data-qa={`dropdown-button-${companyId}`}
          aria-owns={showCompanieOptions ? "menu-companies-options" : undefined}
          aria-haspopup="true"
          size="large"
          onClick={showMenu}
          sx={{ width: "2.5rem", height: "2.5rem", borderRadius: "50%" }}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        data-qa="menu-companies-options"
        id="menu-companies-options"
        anchorEl={showCompanieOptions}
        open={Boolean(showCompanieOptions)}
        onClose={closeMenu}
      >
        <MenuItem
          className="tch-companies-menu-item"
          data-qa="manage-business-units"
          onClick={() => {
            closeMenu()
            navigate(`${companyId}/business-units`)
          }}
        >
          Manage Business Units
        </MenuItem>
      </Menu>
    </div>
  )
}
