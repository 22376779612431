import logoHeaderUrlWhite from "@/assets/svg/tch-logo-header-white.svg"
import logoHeaderUrl from "@/assets/svg/tch-logo-header.svg"
import logoTCHubUrl from "@/assets/svg/tch-logo-tchub.svg"
import { useTheme } from "@/theme/useTheme"
import { Link } from "@mui/material"

const logoStyle = {
  height: "2rem",
  width: "auto",
  color: "secondary",
}

interface TakeCommandLogoHeaderProps {
  href?: string
  isViewingTcHub?: boolean
}

export const TakeCommandLogoHeader = ({ href = "/", isViewingTcHub }: TakeCommandLogoHeaderProps) => {
  const { isDarkMode } = useTheme()

  return isDarkMode && isViewingTcHub ? (
    <Link data-qa="take-command-logo-header-white" href={href}>
      <img src={logoHeaderUrlWhite} alt="take command health header" style={logoStyle} />
    </Link>
  ) : (
    <Link data-qa="take-command-logo-header" href={href}>
      <img src={isViewingTcHub ? logoTCHubUrl : logoHeaderUrl} alt="take command health header" style={logoStyle} />
    </Link>
  )
}
