import styled from "@emotion/styled"
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNoneOutlined"
import { Badge, Box, Button, IconButton, List, Popover as MuiPopover, Tooltip, Typography } from "@mui/material"
import { ReactNode, useRef, useState } from "react"
import { Link } from "react-router-dom"

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 18.75rem;
    ${props => props.theme.shadows[1]};
    border: 1px solid ${props => props.theme.palette.divider};
  }
`

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.palette.divider};
`

export const NavbarNotificationsDropdown = ({
  children,
  notificationCount = 0,
}: {
  children?: ReactNode
  notificationCount?: number
}) => {
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          data-qa="header-notifications"
          ref={ref}
          onClick={handleOpen}
          size="medium"
          sx={{ width: "2.5rem", height: "2.5rem", borderRadius: "50%" }}
        >
          <Badge
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={notificationCount ? notificationCount : undefined}
          >
            <NotificationsNoneIcon sx={{ color: "black", fontSize: "1.5rem" }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        {notificationCount > 0 && (
          <NotificationHeader p={2}>
            <Typography variant="subtitle1" color="textPrimary">
              {notificationCount} New Notifications
            </Typography>
          </NotificationHeader>
        )}
        <>
          <List disablePadding>{children}</List>
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" component={Link} to="#">
              Show all notifications
            </Button>
          </Box>
        </>
      </Popover>
    </>
  )
}
