import { createDataQa } from "@/utils/dataQa"
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material"
import { useRef, useState } from "react"

interface ClearableSearchInputProps {
  handleClearClick?: () => void
  placeholder?: string
}

export const ClearableSearchInput = ({ handleClearClick, ...props }: TextFieldProps & ClearableSearchInputProps) => {
  const [showClearButton, setShowClearButton] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    setShowClearButton(false)
    if (inputRef?.current) {
      inputRef.current.value = ""
    }
    if (handleClearClick) {
      handleClearClick()
    }
  }

  return (
    <TextField
      data-qa={createDataQa("search-bar-input")}
      data-testid="clear-search-input"
      name="search-bar"
      autoFocus
      inputRef={inputRef}
      autoComplete="off"
      placeholder={props.placeholder ?? "Search"}
      variant="outlined"
      size="small"
      inputProps={{
        "aria-label": "search input",
        maxLength: 255,
        "data-qa": createDataQa("search-input"),
        ...props.inputProps,
      }}
      sx={{ display: "inline-flex", backgroundColor: "white" }}
      onChange={props.onChange}
      onInput={event => {
        const { value } = event.target as HTMLInputElement

        setShowClearButton(!!value && "" !== value)
        props.onInput?.(event)
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        ),
        endAdornment: showClearButton ? (
          <InputAdornment position="end">
            <IconButton
              size="small"
              data-testid="clear-text-button"
              data-qa={createDataQa("clear-search-button")}
              aria-label="clear search"
              onClick={handleClick}
            >
              <ClearOutlinedIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      {...props}
    />
  )
}
