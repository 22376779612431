import { ConfirmationModal, ModalConfig } from "@/components/ConfirmationModal"
import { ADD_PERSON_INITIAL_VALUES } from "@/features/People/AddPersonModal/AddPersonModal"
import { useAddPerson } from "@/features/People/AddPersonModal/addPersonService"
import { convertToTouched } from "@/features/People/AddPersonModal/addPersonUtils"
import { addPersonValidate, AddPersonValues } from "@/features/People/AddPersonModal/addPersonValidations"
import { AboutProfileFormStep } from "@/features/People/AddPersonModal/steps/AboutProfileFormStep"
import { AddressProfileFormStep } from "@/features/People/AddPersonModal/steps/AddressProfileFormStep"
import { BenefitsProfileFormStep } from "@/features/People/AddPersonModal/steps/BenefitsProfileFormStep"
import { ConfirmBenefitsStep } from "@/features/People/AddPersonModal/steps/ConfirmBenefitsStep"
import { BorderLinearProgress } from "@/features/People/AddPersonModal/steps/StyledComponents"
import { WellDoneMessageStep } from "@/features/People/AddPersonModal/steps/WellDoneMessageStep"
import { Step, STEPS } from "@/features/People/AddPersonModal/types"
import { useNotifications } from "@/services/notificationService"
import { Uuid } from "@/utils/types"
import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import { Formik } from "formik"
import { useState } from "react"
import { PartialDeep } from "type-fest"
import { TcHubConfirmActionStep } from "./steps/TcHubConfirmActionStep"
import { TcHubConfirmCompanyStep } from "./steps/TcHubConfirmCompanyStep"

const TCHUB_ADD_PERSON_INITIAL_VALUES: PartialDeep<AddPersonValues> = {
  ...ADD_PERSON_INITIAL_VALUES,
  isAdmin: false,
  isTcHub: true,
}

const STEP_COMPONENTS = {
  [STEPS.CONFIRM_COMPANY.id]: TcHubConfirmCompanyStep,
  [STEPS.CONFIRM_ACTION.id]: TcHubConfirmActionStep,
  [STEPS.CONFIRM_BENEFITS.id]: ConfirmBenefitsStep,
  [STEPS.TC_HUB_ABOUT_PERSON_FORM.id]: AboutProfileFormStep,
  [STEPS.TC_HUB_ADDRESS_PERSON_FORM.id]: AddressProfileFormStep,
  [STEPS.TC_HUB_EMPLOYMENT_PERSON_FORM.id]: BenefitsProfileFormStep,
  [STEPS.WELL_DONE_MESSAGE.id]: WellDoneMessageStep,
  [STEPS.ABOUT_PERSON_FORM.id]: AboutProfileFormStep,
}

interface FormStepperProps {
  onClose: () => void
}

const FormStepper = ({ onClose }: FormStepperProps) => {
  const [activeStep, setActiveStep] = useState<Step>(STEPS.CONFIRM_ACTION)
  const [lastSteps, setLastSteps] = useState<Step[] | null>(null)
  const [tcHubCompanyId, setTcHubCompanyId] = useState<Uuid>()
  const [currentPlanId, setCurrentPlanId] = useState<Uuid>()
  const [modal, setModal] = useState<ModalConfig>(null)
  const { notify } = useNotifications("add-person-modal")

  const { mutateAsync: addPerson, isPending: isAddingPerson } = useAddPerson(tcHubCompanyId, currentPlanId)
  const StepComponent = STEP_COMPONENTS[activeStep.id]

  return (
    <>
      <Formik
        initialValues={TCHUB_ADD_PERSON_INITIAL_VALUES as AddPersonValues}
        validate={values => addPersonValidate(values, activeStep)}
        onSubmit={async values => {
          setModal({
            title: "Adding a person",
            message: "Are you sure you want to add this person?",
            isError: false,
            onConfirm: async () => {
              try {
                await addPerson(values)
                setLastSteps([STEPS.CONFIRM_ACTION])
                notify(`${values.firstName} ${values.lastName} has been successfully added.`, "success")
                onClose()
              } catch (e: any) {
                console.error(e)
                setModal({
                  title: "Error adding person",
                  message: "An error occurred while adding this person. Please try again later.",
                  isError: true,
                  isCancelable: false,
                  actionLabel: "Close",
                  onConfirm: () => setModal(null),
                })
              }
            },
          })
        }}
      >
        {formProps => {
          const showProgress = activeStep.progress && formProps.values.benefitEligible

          setTcHubCompanyId(formProps.values.company?.id)
          setCurrentPlanId(formProps.values.planId)
          const handleNext = async (next: Step) => {
            if (activeStep.shouldValidate) {
              const errors = await formProps.validateForm()

              if (errors && Object.keys(errors).length > 0) {
                formProps.setTouched(convertToTouched(formProps.touched, errors))

                return
              }
            }
            setLastSteps(prev => [...(prev || []), activeStep])
            setActiveStep(next)
          }

          const handleBack = () => {
            if (lastSteps?.length) {
              setActiveStep(lastSteps[lastSteps.length - 1])
              setLastSteps(lastSteps.slice(0, lastSteps.length - 1))

              return
            }
            onClose()
          }

          return (
            <form onSubmit={formProps.handleSubmit} data-qa="add-person-form" noValidate>
              <Grid
                container
                sx={{
                  gap: 2,
                }}
              >
                {showProgress && (
                  <BorderLinearProgress
                    variant="determinate"
                    sx={{ mt: 2, mx: 4, width: "100%" }}
                    value={activeStep.progress}
                  />
                )}
                <Grid
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <StepComponent
                    onNextStep={handleNext}
                    onClose={onClose}
                    onBack={handleBack}
                    planId={formProps.values.planId!}
                    companyId={formProps.values.company?.id!}
                    isAdmin={formProps.values.isAdmin}
                    hraStartDate={formProps.values.hraStartDate!}
                    {...formProps}
                  />
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
      {modal && <ConfirmationModal {...modal} isOpen onClose={() => setModal(null)} isSubmitting={isAddingPerson} />}
    </>
  )
}

interface AddPersonModalProps {
  onClose: () => void
}

export const TcHubAddPersonModal = ({ onClose }: AddPersonModalProps) => (
  <Dialog
    open
    onClose={(evt, reason) => {
      if (reason === "backdropClick") {
        return
      }
      onClose()
    }}
    scroll="body"
    data-qa="add-person-modal"
    aria-labelledby="add-person-title"
    PaperProps={{
      sx: { minWidth: "min(100vw - 80px, 750px)", p: 4 },
    }}
  >
    <DialogTitle>
      <Grid
        container
        sx={{ justifyContent: "space-between", py: 3, alignItems: "center" }}
        className="dialog-headline-container"
      >
        <Typography className="dialog-headline" variant="h3tiempos">
          Add new
        </Typography>
        <CloseIcon
          data-qa="activity-download-dialog-close-icon-button"
          className="dialog-close-icon"
          onClick={onClose}
        />
      </Grid>
    </DialogTitle>

    <DialogContent sx={{ px: 4, pt: 1, pb: 4 }}>
      <FormStepper onClose={onClose} />
    </DialogContent>
  </Dialog>
)
