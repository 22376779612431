import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { default as axiosLib } from "axios"
import { FundingAccount } from "../TCHub/tcHubTypes"

const fundingBaseUrl = `${appConfig.baseApiUrl}/funding`
const unitBaseUrl = `${appConfig.unitBaseUrl}`

export const getAuthenticationToken = async (companyId: string) => {
  try {
    const response = await axios.get(`${fundingBaseUrl}/v1/companies/${companyId}/auth-tokens`)

    return response.data as { token: string; expiredAt: string }
  } catch (error) {
    console.error("Error fetching authentication token:", error)
    throw error
  }
}

export const getFundingAccounts = async (companyId: string) => {
  try {
    const response = await axios.get(`${fundingBaseUrl}/v1/companies/${companyId}/funding-entities`)

    return response.data as FundingAccount[]
  } catch (error) {
    console.error("Error fetching funding account(s):", error)
    throw error
  }
}

export const getActivityStatements = async (companyId: string, fundingEntityId: string) => {
  try {
    const response = await axios.get(
      `${fundingBaseUrl}/v1/companies/${companyId}/funding-entities/${fundingEntityId}/statements`
    )

    // format for period yyyy-mm
    return response.data as { fundingEntityId: string; statementId: string; period: string }[]
  } catch (error) {
    console.error("Error fetching activity statement(s):", error)
    throw error
  }
}

export const downloadStatementPdf = async (otpToken: string, statementId: string) => {
  try {
    const response = await axiosLib.get(`${unitBaseUrl}/statements/${statementId}/pdf`, {
      headers: { Authorization: `Bearer ${otpToken}` },
      responseType: "blob",
    })

    const href = URL.createObjectURL(response.data)
    const link = document.createElement("a")

    link.href = href
    link.setAttribute("download", `statement_${statementId}.pdf`)
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  } catch (error) {
    console.error("Error fetching PDF:", error)
    throw error
  }
}
