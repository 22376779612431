import { DateTime } from "luxon"

export const formatDateRange = (startDateISO: string) => {
  const startDate = DateTime.fromISO(startDateISO)
  const endDate = startDate.endOf("month")

  const formattedStartDate = startDate.toLocaleString({
    month: "short",
    day: "numeric",
  })

  const formattedEndDate = endDate.toLocaleString({
    month: "short",
    day: "numeric",
    year: "numeric",
  })

  return `${formattedStartDate} - ${formattedEndDate}`
}
