import { TextField } from "@mui/material"
import { ComponentProps, forwardRef } from "react"
import { IMaskInput } from "react-imask"

const VerificationMaskInput = forwardRef<
  HTMLElement,
  {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
  }
>(({ onChange, ...props }, ref) => (
  <IMaskInput
    {...props}
    mask="000000"
    inputRef={ref as never}
    onAccept={value =>
      onChange({
        target: {
          name: props.name,
          value,
        },
      })
    }
    overwrite
  />
))

type VerificationCodeFieldProps = ComponentProps<typeof TextField>
export const VerificationCodeField = ({ onChange, value, label, ...textFieldProps }: VerificationCodeFieldProps) => (
  <TextField
    label={label}
    value={value as string}
    onChange={onChange}
    {...textFieldProps}
    InputProps={{
      inputComponent: VerificationMaskInput as never,
    }}
  />
)
