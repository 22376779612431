import { ClearableSearchInput } from "@/components/ClearableSearchInput/ClearableSearchInput"
import { FilterDropdownField } from "@/components/FilterDropdownField"
import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { BaseTable } from "@/components/Table/Table"
import { TABLE_CELL_PADDING } from "@/constants"
import { useAutoPayCompanies } from "@/features/TCHub/tcHubService"
import { AutoPayAccountTableHeader, FundingEntityModel } from "@/features/TCHub/tcHubTypes"
import { toTitleCase } from "@/utils/formatting"
import { Grid, TableCell, Typography } from "@mui/material"
import { constant } from "lodash"
import { useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { TcHubGuard } from "../../../Auth/guards/TcHubGuard"
import { CompanyModel } from "../../../CreateCompany/createCompanyEndpoints"
import {
  getFundingEntityStatus,
  getFundingEntityType,
  useAccountsPageParams,
  useFundingEntities,
} from "./tcHubAccountsService"

const headers: AutoPayAccountTableHeader[] = [
  { id: "name", label: "Name", sortable: true, field: "name", alignment: "left" },
  { id: "status", label: "Status", sortable: true, field: "status", alignment: "left" },
  {
    id: "entityType",
    label: "Account Type",
    sortable: true,
    field: "entityType",
    alignment: "left",
  },
  {
    id: "companyName",
    label: "Company Name",
    sortable: true,
    field: "companyName",
    alignment: "left",
  },
  {
    id: "accountLast4Digits",
    label: "Account Number",
    sortable: true,
    field: "accountLast4Digits",
    alignment: "left",
  },
]

export const CompanySet = (companies: CompanyModel[]) => {
  const companySet = new Map<string, string>()

  companies.forEach(company => {
    companySet.set(company.id, company.companyInfo.companyName)
  })

  return companySet
}

const matchAccountName = (entity: FundingEntityModel, searchQueryLowerCase: string) =>
  (entity.name ?? "").toLowerCase().includes(searchQueryLowerCase)

const matchCompanyName = (
  entity: FundingEntityModel,
  companyId: string,
  companySet: Map<string, string>,
  searchQueryLowerCase: string
) => companySet.get(companyId)?.toLowerCase().includes(searchQueryLowerCase)

const matchAccountNumber = (entity: FundingEntityModel, searchInputValue: string) =>
  (entity.accountLast4Digits ?? "").includes(searchInputValue)

export const TcHubAccountsPage = () => {
  const navigate = useNavigate()
  const [searchInputValue, setSearchInputValue] = useState<string>("")
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(25)

  const { fundingEventStatus, setFundingEventStatus, fundingEntityType, setFundingEntityType } = useAccountsPageParams()
  const { data: fundingEntities, isLoading: isFundingEntitiesLoading } = useFundingEntities()
  const { data: companies, isLoading: isCompaniesLoading } = useAutoPayCompanies()

  const fundingEntityStatusOptions = getFundingEntityStatus(fundingEntities)
  const fundingEntityTypeOptions = getFundingEntityType(fundingEntities)

  const companySet = useMemo(() => CompanySet(companies ?? []), [companies])

  const filteredFundingEntities = useMemo(
    () =>
      (fundingEntities ?? [])
        .filter(entity => {
          const searchQueryLowerCase = searchInputValue.toLowerCase()

          return (
            matchAccountName(entity, searchQueryLowerCase) ||
            matchCompanyName(entity, entity.companyId, companySet, searchQueryLowerCase) ||
            matchAccountNumber(entity, searchInputValue)
          )
        })
        .filter(
          entity =>
            (entity.status === fundingEventStatus || !fundingEventStatus) &&
            (entity.entityType === fundingEntityType || !fundingEntityType)
        )
        .map(entity => ({
          ...entity,
          companyName: companySet.get(entity.companyId),
        })),
    [fundingEntities, searchInputValue, companySet, fundingEventStatus, fundingEntityType]
  )

  return (
    <TcHubGuard requiredPermissions={["tc_hub_autopay"]}>
      <Grid container data-qa="tc-hub-accounts-page">
        <Helmet title="TC Hub Accounts" />
        <Grid item xs={12}>
          <Typography variant="h1" data-qa="accounts" gutterBottom>
            Accounts
          </Typography>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-start" spacing={3}>
          <Grid item>
            <ClearableSearchInput
              onChange={event => setSearchInputValue(event.target.value)}
              handleClearClick={() => setSearchInputValue("")}
              data-qa="account-search-input"
            />
          </Grid>
          <Grid item>
            <FilterDropdownField
              options={fundingEntityStatusOptions}
              value={toTitleCase(fundingEventStatus)}
              onChange={setFundingEventStatus}
              label="Account Status"
            />
          </Grid>
          <Grid item>
            <FilterDropdownField
              options={fundingEntityTypeOptions}
              value={toTitleCase(fundingEntityType)}
              onChange={setFundingEntityType}
              label="Account Type"
            />
          </Grid>
        </Grid>
        {!isCompaniesLoading && !isFundingEntitiesLoading ? (
          <BaseTable
            rows={filteredFundingEntities}
            selected={[]}
            searchCriteria={searchInputValue}
            onToggleSelect={() => []}
            onToggleOrderBy={() => []}
            onToggleSelectAll={() => []}
            onPageChange={changedPage => setPage(changedPage)}
            onRowsPerPageChange={rows => setRowsPerPage(rows)}
            uniqueIdSelector={constant("")}
            headCells={headers ?? []}
            rowsPerPage={rowsPerPage}
            page={page}
            fullWidth
            orderBy={[{ headCellId: "", direction: "asc" }]}
            onRowClick={row => navigate(`${row.fundingEntityId}`)}
            exportCsv
            csvTitle="Accounts"
          >
            {({ row }) => (
              <>
                <TableCell>
                  <Typography variant="body1" data-qa="funding-entity-name" sx={TABLE_CELL_PADDING}>
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                    {toTitleCase(row.status)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                    {toTitleCase(row.entityType)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                    {row.companyName}
                  </Typography>
                </TableCell>
                <TableCell>
                  {row.accountLast4Digits ? (
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      ****{row.accountLast4Digits}
                    </Typography>
                  ) : null}
                </TableCell>
              </>
            )}
          </BaseTable>
        ) : (
          <SkeletonTableLoader
            data-qa="skeleton-table-loader-accounts"
            headerTitles={headers.map(cell => `${cell.label}`)}
            bodyRowsCount={10}
          />
        )}
      </Grid>
    </TcHubGuard>
  )
}
