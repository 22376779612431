import { FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { Stepper } from "@/features/CreateCompany/components/Stepper"
import { PurchaseHraStepProps } from "@/features/CreateCompany/pages/CreateCompany"
import { DownloadForOfflineOutlined } from "@mui/icons-material"
import { Button, Grid, Typography } from "@mui/material"
import { Formik } from "formik"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { FileUploader } from "../../../../../components/FileUploader"

const EMPLOYEE_ROSTER_INITIAL_VALUES = {}
const employeeRosterValidationSchema = Yup.object({})

export const EmployeeRoster = ({ stepData }: PurchaseHraStepProps) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])

  const hasErrors = (touched: { [field: string]: boolean }, errors: { [field: string]: string }) => {
    let errorExists = false

    Object.keys(touched)?.forEach(field => {
      if (errors[field]) {
        errorExists = true
      }
    })

    return errorExists
  }

  return (
    <Formik
      initialValues={EMPLOYEE_ROSTER_INITIAL_VALUES}
      validationSchema={employeeRosterValidationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setIsLoading(true)
          navigate("/create-company/plan-summary")
        } catch (error: any) {
          const message = error.message || "Something went wrong"

          // FUTURE: Remove this use of alert and eslint-disable directive, consider using notifications service instead
          // eslint-disable-next-line no-alert
          alert("Error adding employees")
          setStatus({ success: false })
          setErrors({ submit: message })
          setSubmitting(false)
        } finally {
          setIsLoading(false)
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, isValid, dirty }) => (
        <>
          <Stepper steps={stepData} activeStepIndex={4} isError={hasErrors(touched, errors)} />
          <form noValidate onSubmit={handleSubmit} data-qa="plan-setup-form">
            <Grid container spacing={4} mt={5}>
              <Grid item xs={12}>
                <Typography variant="h1" data-qa="employee-roster-title">
                  Employee Roster
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Add your employees</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Please include all employees eligible for reimbursement. This is important for year-end reporting. You
                  may make adjustments or add employees later in your member portal.
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4} mt={5}>
              <Grid item xs={12}>
                <Typography variant="h5">1. Download census file and instructions</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  To add multiple employees at once, download the census template below and fill in the required fields.{" "}
                  <strong>Employees under the age of 18 are not eligible to participate on our platform</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  data-qa="download-template-button"
                  variant="contained"
                  color="primary"
                  sx={{ mr: 3 }}
                  startIcon={<DownloadForOfflineOutlined />}
                >
                  Download Template
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={4} mt={5}>
              <Grid item xs={12}>
                <Typography variant="h5" data-qa="plan-setup-title">
                  2. Upload census file
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FileUploader
                  fileName="completed census"
                  fileFormatDescription="CSV"
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                />
              </Grid>
            </Grid>
            <FlowNavigationButtons
              handleBack={() => {
                navigate("/create-company/add-on-products")
              }}
              type="submit"
              continueLabel="Skip"
              isSubmitting={isLoading}
            />
          </form>
        </>
      )}
    </Formik>
  )
}
