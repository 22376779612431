import { ConfirmationModal, ModalConfig } from "@/components/ConfirmationModal"
import { DatePickerField } from "@/components/DatePickerField"
import { LoadingContentArea } from "@/components/LoadingContentArea"
import { SelectField } from "@/components/SelectField"
import { StyledCard } from "@/components/StyledCard"
import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import {
  convertToFormFields,
  fundingEventDetailsInitialValues,
  fundingEventStatusEnum,
  fundingEventStatusOptions,
  fundingEventTimePeriodEnum,
  fundingEventTimePeriodOptions,
  useCompanySet,
  useFundingEventDelete,
  useFundingEventProcess,
  useFundingEventUpdate,
} from "@/features/TCHub/AutoPay/FundingEvents/fundingEventDetailsService"
import { CompanySearchAutoComplete } from "@/features/TCHub/components/CompanySearchAutoComplete"
import { useFundingEventById } from "@/features/TCHub/tcHubService"
import { useNotifications } from "@/services/notificationService"
import { getOnlyDate } from "@/utils/dates"
import { formatCents } from "@/utils/formatting"
import { fundingEventDetailsSchema } from "@/utils/validations"
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import { Formik, FormikProps } from "formik"
import { isNil } from "lodash"
import { DateTime } from "luxon"
import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { NumericFormat } from "react-number-format"
import { useNavigate, useParams } from "react-router-dom"

interface FundingEventDetailsFormProps extends FormikProps<typeof fundingEventDetailsInitialValues> {
  isReadOnly: boolean
  isActionLoading: boolean
  isNew: boolean
  canRetry: boolean
  onBack: () => void
  onDelete: () => void
  onRetry: () => void
}

const FundingEventDetailsForm = ({
  handleSubmit,
  handleBlur,
  handleChange,
  setFieldValue,
  errors,
  values,
  touched,
  isReadOnly,
  isNew,
  canRetry,
  isActionLoading,
  onBack,
  onDelete,
  onRetry,
}: FundingEventDetailsFormProps) => (
  <StyledCard>
    <form noValidate onSubmit={handleSubmit} data-qa="funding-event-details-page">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <CompanySearchAutoComplete
            name="company"
            label="Company"
            touched={Boolean(errors.company)}
            error={errors.company?.toString()}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            value={values.company}
            disabled={!isNew}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            dataQa="time-period-select"
            data={fundingEventTimePeriodOptions}
            type="text"
            name="timePeriod"
            label="Time Period"
            required
            value={values.timePeriod ?? ""}
            placeholder="Select time period"
            disabled={isReadOnly}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            name="timePeriodLabel"
            label="Time period label"
            value={values.timePeriodLabel}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.timePeriodLabel && errors.timePeriodLabel)}
            helperText={touched.timePeriodLabel && errors.timePeriodLabel}
            data-qa="time-period-label"
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            name="periodStartAt"
            label="Period Start Date"
            value={getOnlyDate(values.periodStartAt)}
            InputLabelProps={{ shrink: true, disabled: isReadOnly }}
            onBlur={handleBlur}
            error={Boolean(touched.periodStartAt && errors.periodStartAt)}
            helperText={touched.periodStartAt ? `${errors.periodStartAt ?? ""}` : ""}
            fullWidth
            required
            variant="outlined"
            onChange={e => {
              handleChange(e)
            }}
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            name="periodEndAt"
            label="Period End Date"
            value={getOnlyDate(values.periodEndAt)}
            InputLabelProps={{
              shrink: true,
            }}
            onBlur={handleBlur}
            error={Boolean(touched.periodEndAt && errors.periodEndAt)}
            helperText={touched.periodEndAt ? `${errors.periodEndAt ?? ""}` : ""}
            fullWidth
            required
            variant="outlined"
            onChange={e => {
              handleChange(e)
            }}
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth required>
            <InputLabel
              htmlFor="number-of-employees-input"
              error={touched.numberOfEmployees && Boolean(errors.numberOfEmployees)}
            >
              Number of Employees
            </InputLabel>
            <NumericFormat
              id="number-of-employees-input"
              customInput={OutlinedInput}
              name="numberOfEmployees"
              label="Number of Employees"
              value={values.numberOfEmployees ?? ""}
              onBlur={handleBlur}
              error={Boolean(touched.numberOfEmployees && errors.numberOfEmployees)}
              decimalScale={0}
              prefix=""
              data-qa="number-of-employees-input"
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth required>
            <InputLabel
              htmlFor="total-amount-label"
              error={touched.totalAmountCents && Boolean(errors.totalAmountCents)}
            >
              Total Amount
            </InputLabel>
            <NumericFormat
              id="total-amount-label"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              customInput={OutlinedInput}
              name="totalAmountCents"
              label="Total Amount"
              onBlur={handleBlur}
              onChange={e => {
                const value = e.target.value.replace(/[^0-9.]/g, "")
                const valueInCents = parseFloat(value) * 100

                setFieldValue("totalAmountCents", valueInCents)
              }}
              error={Boolean(touched.totalAmountCents && errors.totalAmountCents)}
              value={isNil(values?.totalAmountCents) ? "" : formatCents(values.totalAmountCents)}
              thousandSeparator
              decimalSeparator="."
              decimalScale={2}
              prefix=""
              data-qa="total-amount-input"
              disabled
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth required>
            <InputLabel
              htmlFor="total-amount-label"
              error={touched.premiumsAmountCents && Boolean(errors.premiumsAmountCents)}
            >
              Premiums' Amount
            </InputLabel>
            <NumericFormat
              id="premiums-amount-label"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              customInput={OutlinedInput}
              name="premiumsAmountCents"
              label="Premiums Amount"
              onBlur={handleBlur}
              error={Boolean(touched.premiumsAmountCents && errors.premiumsAmountCents)}
              value={isNil(values?.premiumsAmountCents) ? "" : formatCents(values.premiumsAmountCents)}
              onChange={e => {
                const value = e.target.value.replace(/[^0-9.]/g, "")
                const valueInCents = parseFloat(value) * 100

                setFieldValue("premiumsAmountCents", valueInCents)
              }}
              thousandSeparator
              decimalSeparator="."
              decimalScale={2}
              prefix=""
              data-qa="premiums-amount-input"
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth required>
            <InputLabel
              htmlFor="total-amount-label"
              error={touched.reserveAmountCents && Boolean(errors.reserveAmountCents)}
            >
              Reserve's Amount
            </InputLabel>
            <NumericFormat
              id="reserve-amount-label"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              customInput={OutlinedInput}
              name="reserveAmountCents"
              label="Total Amount"
              onBlur={handleBlur}
              error={Boolean(touched.reserveAmountCents && errors.reserveAmountCents)}
              value={isNil(values?.reserveAmountCents) ? "" : formatCents(values.reserveAmountCents)}
              onChange={e => {
                const value = e.target.value.replace(/[^0-9.]/g, "")
                const valueInCents = parseFloat(value) * 100

                setFieldValue("reserveAmountCents", valueInCents)
              }}
              thousandSeparator
              decimalSeparator="."
              decimalScale={2}
              prefix=""
              data-qa="premiums-amount-input"
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            dataQa="status-select"
            data={fundingEventStatusOptions}
            type="text"
            name="status"
            label="Status"
            required
            value={values.status ?? ""}
            placeholder="Select status"
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            label="Funding timestamp"
            renderInput={({ error, ...params }) => <TextField fullWidth {...params} />}
            value={values.fundingTimestamp}
            onChange={date => setFieldValue("fundingTimestamp", date)}
            disabled={isReadOnly}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center" mt={5}>
        <Grid item>
          <Grid container justifyContent="flex-end" spacing={4}>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                fullWidth
                disabled={isReadOnly || isActionLoading}
                data-qa="delete-button"
                onClick={onDelete}
              >
                Delete
              </Button>
            </Grid>
            <Grid item>
              {canRetry && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isActionLoading}
                  data-qa="retry-button"
                  onClick={onRetry}
                >
                  Retry
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="flex-end" spacing={4}>
            <Grid item>
              <Button variant="outlined" color="primary" fullWidth onClick={onBack} data-qa="cancel-button">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isReadOnly || isActionLoading}
                data-qa="submit-button"
              >
                Save changes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  </StyledCard>
)

interface Params extends Record<string, string | undefined> {
  fundingEventId: string
  companyId: string
}

export const TcHubFundingEventDetailsPage = () => {
  const navigate = useNavigate()
  const companySet = useCompanySet()
  const { notify } = useNotifications("edit-funding-event")

  const { fundingEventId = null, companyId = null } = useParams<Params>() ?? {}

  const { data: fundingEvent } = useFundingEventById(companyId, fundingEventId)

  const { mutateAsync: updateFundingEvent, isPending: isUpdateFundingEventPending } = useFundingEventUpdate(
    companyId,
    fundingEventId
  )

  const { mutateAsync: deleteFundingEvent, isPending: isDeleteFundingEventPending } = useFundingEventDelete(
    companyId,
    fundingEventId
  )

  const { mutateAsync: fundingEventProcess } = useFundingEventProcess(companyId)
  const [confirmationModal, setConfirmationModal] = useState<ModalConfig>(null)

  const setConfirmationSubmitting = (isSubmitting: boolean) => {
    setConfirmationModal(currentConfig => currentConfig && { ...currentConfig, isSubmitting })
  }

  const clearConfirmationModal = () => setConfirmationModal(null)

  if (!fundingEvent) {
    return <LoadingContentArea data-qa="loading-funding-event-details" />
  }
  const canRetry =
    fundingEvent.status === fundingEventStatusEnum.FAILED &&
    fundingEvent.timePeriod !== fundingEventTimePeriodEnum.OFF_CYCLE

  const isReadOnly = fundingEvent.status !== fundingEventStatusEnum.SCHEDULED
  const isNew = false
  const isActionLoading = isUpdateFundingEventPending || isDeleteFundingEventPending

  return (
    <TcHubGuard requiredPermissions={["tc_hub_autopay"]}>
      <Helmet title="Edit company auto funding event" />
      <Grid item xs={12}>
        <Typography variant="h1" display="inline" data-qa="funding-event">
          Edit company funding event
        </Typography>
      </Grid>
      <Formik
        validationSchema={fundingEventDetailsSchema}
        enableReinitialize
        initialValues={fundingEvent ? convertToFormFields(companySet, fundingEvent) : fundingEventDetailsInitialValues}
        onSubmit={async (values, handlers) => {
          setConfirmationModal({
            title: "Save changes",
            message: "Are you sure you want to save changes?",
            actionLabel: "Save",
            onConfirm: async () => {
              setConfirmationSubmitting(true)
              await updateFundingEvent({
                // FUTURE: Remove these unsafe non-null assertions
                timePeriod: values.timePeriod!,
                timePeriodLabel: values.timePeriodLabel!,
                periodStartAt: DateTime.fromJSDate(values.periodStartAt!).toISODate()!,
                periodEndAt: DateTime.fromJSDate(values.periodEndAt!).toISODate()!,
                status: values.status!,
                expectedFundingAt: DateTime.fromJSDate(values.fundingTimestamp!).toISO()!,
              })
              notify(`Funding event updated successfully`, "success")
              clearConfirmationModal()
            },
          })
        }}
      >
        {formikProps => (
          <FundingEventDetailsForm
            isReadOnly={isReadOnly}
            isNew={isNew}
            {...formikProps}
            onBack={() => {
              navigate(-1)
            }}
            canRetry={canRetry}
            onRetry={() => {
              setConfirmationModal({
                title: "Retry funding event",
                message: "Are you sure you want to retry this funding event?",
                actionLabel: "Retry",
                onConfirm: async () => {
                  if (!canRetry) {
                    clearConfirmationModal()

                    return
                  }
                  setConfirmationSubmitting(true)
                  await fundingEventProcess({
                    periodStartAt: fundingEvent.periodStartAt,
                    periodEndAt: fundingEvent.periodEndAt,
                  })
                  clearConfirmationModal()
                },
              })
            }}
            isActionLoading={isActionLoading}
            onDelete={async () => {
              setConfirmationModal({
                title: "Delete funding event",
                message: "Are you sure you want to delete this funding event?",
                actionLabel: "Delete",
                onConfirm: async () => {
                  setConfirmationSubmitting(true)
                  await deleteFundingEvent()
                  clearConfirmationModal()
                  navigate(-1)
                },
              })
            }}
          />
        )}
      </Formik>
      {confirmationModal && (
        <ConfirmationModal {...confirmationModal} isOpen onClose={() => setConfirmationModal(null)} />
      )}
    </TcHubGuard>
  )
}
