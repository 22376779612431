import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { Uuid } from "@/utils/types"

const baseUrl = appConfig.baseApiUrl

export const messagingServiceBaseUrl = `${baseUrl}/messaging`

export interface HubspotRequest {
  companyId: Uuid
  benefitElectionId: Uuid
}

export const createHubSpotDeal = async (hubspotRequest: HubspotRequest) =>
  (await axios.post(`${messagingServiceBaseUrl}/v1/hubspot-create-deal`, hubspotRequest)).data
