import { Redirect } from "@/components/Navigation"
import { useOnboardingStatuses } from "@/features/EmployerOnboarding/employerOnboardingService"
import { WrapperProps } from "@/utils/types"

// For routes that redirct to dashboard if billing setup is completed
export const EmployerOnboardingGuard = ({ children }: WrapperProps) => {
  const { isBillingSetupComplete } = useOnboardingStatuses()

  if (isBillingSetupComplete) {
    return <Redirect />
  }

  return children
}
