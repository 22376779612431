import { SidebarItem } from "@/components/Sidebar/types"
import { createDataQa } from "@/utils/dataQa"
import { List } from "@mui/material"
import { SidebarNavSection } from "./SidebarNavSection"

export interface SidebarNavProps {
  items: SidebarItem[]
  showSectionDivider?: boolean
}

const dataQa = createDataQa("sidebar-nav-item")

export const SidebarNav = ({ items, showSectionDivider }: SidebarNavProps) => (
  <List disablePadding sx={{ flexGrow: 1, borderRight: "1px solid", borderColor: "colors.borderGray" }}>
    <SidebarNavSection title="" pages={items} data-qa={createDataQa(dataQa)} showDivider={showSectionDivider} />
  </List>
)
