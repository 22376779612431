import { useNotifications } from "@/services/notificationService"
import { takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { formatBytes } from "@/utils/formatting"
import { CheckCircle, Close, UploadFile } from "@mui/icons-material"
import { Avatar, Box, Grid, IconButton, Link, Typography } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { DropzoneOptions, useDropzone } from "react-dropzone"

const dataQa = "file-upload"
const DEFAULT_MAX_SIZE_BYTES = 100000000
const uploadedFileDataQa = createDataQa(dataQa, "uploaded-file")
const pluralize = (word: string, amount: number) => `${amount} ${word}${amount > 1 ? "s" : ""}`

interface UploadedFileProps {
  name: string
  size: number
  url?: string
  removeFile?: () => void
}

export const UploadedFile = ({ name, size, url, removeFile }: UploadedFileProps) => (
  <Box sx={{ display: "flex", gap: 4, mt: 4, alignItems: "center", width: "100%" }} data-qa={uploadedFileDataQa}>
    <Avatar sx={{ bgcolor: "colors.lightTealShade" }} data-qa={createDataQa(uploadedFileDataQa, "avatar")}>
      <UploadFile color="primary" sx={{ width: "1.5rem", height: "1.5rem" }} />
    </Avatar>
    <Box sx={{ width: { xs: "100%", sm: "20rem" }, display: "flex", flexDirection: "column" }}>
      <Typography variant="body2" data-qa={createDataQa(uploadedFileDataQa, "name")}>
        {name}
      </Typography>
      <Typography variant="caption" data-qa={createDataQa(uploadedFileDataQa, "size")}>
        {formatBytes(size)} • Complete
      </Typography>
      {url && (
        <Typography variant="caption">
          <Link href={url}>Download file</Link>
        </Typography>
      )}
    </Box>
    <Box sx={{ width: "1.5rem", height: "1.5rem" }}>
      <CheckCircle
        sx={{ width: "1.5rem", height: "1.5rem", color: takeCommandPrimary.main }}
        data-qa={createDataQa(uploadedFileDataQa, "check")}
      />
    </Box>
    <Box>
      {removeFile && (
        <IconButton
          aria-label="Remove file"
          onClick={removeFile}
          data-qa={createDataQa(uploadedFileDataQa, "remove-button")}
        >
          <Close />
        </IconButton>
      )}
    </Box>
  </Box>
)

interface FileUploaderProps extends DropzoneOptions {
  fileName?: string
  fileFormatDescription?: string
  uploadedFiles: File[]
  setUploadedFiles: Dispatch<SetStateAction<File[]>>
}

export const FileUploader = ({
  fileName,
  fileFormatDescription,
  uploadedFiles,
  setUploadedFiles,
  ...dropzoneOptions
}: FileUploaderProps) => {
  const { notify } = useNotifications("file-upload")
  const { maxFiles, maxSize = DEFAULT_MAX_SIZE_BYTES } = dropzoneOptions

  const { getRootProps, getInputProps } = useDropzone({
    ...dropzoneOptions,
    maxSize,
    onDropRejected(fileRejections, event) {
      if (maxFiles && fileRejections.length > maxFiles) {
        notify(
          maxFiles === 1 ? "You can upload a maximum of one file." : `You can upload a maximum of ${maxFiles} files`,
          "error"
        )
      } else {
        notify(
          `Please upload files only in ${fileFormatDescription} format and with a maximum size of ${formatBytes(
            maxSize
          )}.`,
          "error"
        )
      }
    },
    onDropAccepted(files: File[], event) {
      const selectedFilesAmount = files.length
      const newAmountOfFiles = uploadedFiles.length + selectedFilesAmount

      if (!maxFiles || newAmountOfFiles <= maxFiles) {
        let amountOfFilesUploaded = 0

        setUploadedFiles(previousFiles => {
          // Avoid adding repeated files
          const existingFileNames = previousFiles.map(f => f.name)
          const uniqueFiles = files.filter(newFile => !existingFileNames.includes(newFile.name))

          amountOfFilesUploaded = uniqueFiles.length

          return [...previousFiles, ...uniqueFiles]
        })

        if (amountOfFilesUploaded > 0) {
          notify(`${pluralize("file", amountOfFilesUploaded)} successfully uploaded.`, "success")
        }

        if (amountOfFilesUploaded < selectedFilesAmount) {
          const repeatedFilesAmount = selectedFilesAmount - amountOfFilesUploaded

          notify(`${pluralize("file", repeatedFilesAmount)} have already been uploaded`, "error")
        }
      } else {
        notify(`You can upload a maximum of ${maxFiles} files`, "error")
      }
    },
  })

  const clearFile = (fileNameToDelete: string) => {
    setUploadedFiles(previousFiles => previousFiles.filter(file => file.name !== fileNameToDelete))
  }

  const showDropdownZone = !maxFiles || uploadedFiles.length < maxFiles

  return (
    <>
      {showDropdownZone && (
        <Box {...getRootProps({ className: "dropzone" })} sx={{ width: "100%" }} data-qa={createDataQa(dataQa)}>
          <Grid
            container
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems="center"
            sx={{
              border: "2px dashed",
              borderColor: "colors.borderGray",
              borderRadius: "4px",
              backgroundColor: "colors.regionGray",
              my: 4,
              mx: 0,
              py: 8,
              px: 4,
              cursor: "pointer",
              gap: 2,
              ":hover": {
                bgcolor: "colors.lightBlueShade",
              },
            }}
          >
            <input {...getInputProps()} />

            <Avatar sx={{ bgcolor: "colors.lightTealShade" }} data-qa={createDataQa(dataQa, "avatar")}>
              <UploadFile color="primary" sx={{ width: "1.5rem", height: "1.5rem" }} />
            </Avatar>

            <Typography variant="body1" data-qa={createDataQa(dataQa, "title")}>
              Click to upload or drag and drop {fileName && `your ${fileName} file here.`}
            </Typography>

            <Typography variant="body2" data-qa={createDataQa(dataQa, "description")} color="colors.lightGrayText">
              {fileFormatDescription} (Max size: {formatBytes(maxSize)}
              {!!maxFiles && ` - Max ${pluralize("file", maxFiles)}`})
            </Typography>
          </Grid>
        </Box>
      )}

      {uploadedFiles?.length > 0 && (
        <Box mt={8} width="100%">
          <Typography
            variant="body1bold"
            gutterBottom
            data-qa={createDataQa(dataQa, "uploaded-files")}
          >{`Uploaded file${maxFiles && maxFiles > 1 ? "s" : " "}`}</Typography>
          {uploadedFiles.map(file => (
            <UploadedFile key={file.name} name={file.name} size={file.size} removeFile={() => clearFile(file.name)} />
          ))}
        </Box>
      )}
    </>
  )
}
