import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import { useState } from "react"
import {
  EMPLOYEE_PURCHASED,
  FINAL_CHECK,
  OTHER_PREMIUMS,
  RECURRING_REIMBURSEMENT,
} from "../../benefitsElectionConstants"
import { useShoppingSession, useShoppingUrl, useUpdateHealthBenefitsElection } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { HealthBenefitsElection } from "../../benefitsElectionTypes"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"

export const FinalCheck = () => {
  const shoppingUrl = useShoppingUrl()
  const previous = shoppingUrl + RECURRING_REIMBURSEMENT
  const next = shoppingUrl + OTHER_PREMIUMS
  const shoppingSessionId = useShoppingSession()
  const currentShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const currentBenefitElection = currentShoppingSession.healthBenefitElections[0]
  const [removedTaxCredits, setRemovedTaxCredits] = useState(false)
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const { mutateAsync: updateHealthBenefitsElection } = useUpdateHealthBenefitsElection(shoppingSessionId)
  const setHealthBenefitElection = useBenefitsElectionStore(state => state.setHealthBenefitElection)

  return (
    <BenefitsElectionStep
      title="You will not be able to make changes to your plan after this point"
      description="By continuing to the next page, you will lock your choices of health plan and coverages. Please make sure all your information is accurate before continuing."
      required
      previous={previous}
      next={next}
      advanceOnSuccess
      disabled={!removedTaxCredits}
      handleContinue={async () => {
        const updatedBenefitsElection: HealthBenefitsElection = {
          id: currentBenefitElection.id,
          employmentId: currentBenefitElection.employmentId,
          enrollmentStatus: EMPLOYEE_PURCHASED,
        }

        const updatedBenefitElection = await updateHealthBenefitsElection(updatedBenefitsElection)

        setHealthBenefitElection(updatedBenefitElection)

        if (currentStep === FINAL_CHECK) {
          setCurrentStep(OTHER_PREMIUMS)
        }
      }}
    >
      <FormGroup sx={{ mt: 10 }}>
        <FormControlLabel
          label="I acknowledge my plan will be locked after this point and I will not be able to go back to change it."
          control={
            <Checkbox
              checked={removedTaxCredits}
              onClick={() => setRemovedTaxCredits(!removedTaxCredits)}
              data-qa="tax-credits-checkbox"
            />
          }
        />
      </FormGroup>
    </BenefitsElectionStep>
  )
}
