import { ICHRA_HRA_TYPE } from "@/features/Documents/documentsConstants"
import { Uuid } from "@/utils/types"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { isNumber } from "lodash"
import moment, { Moment } from "moment"
import { createHraPlan, getHraPlan, updateHraPlan } from "./planSetupEndpoints"
import {
  ComplianceFrequency,
  CreateHraPlanValues,
  CreateUnassistedHraPlanValues,
  HraPlanDiyFormValues,
  HraPlanFormValues,
  HraPlanModel,
} from "./planSetupTypes"

const buildStatementCreationDate = (
  hraStartString: HraPlanModel["hraStartDate"],
  statementCreationDate: HraPlanModel["statementCreationDate"],
  dayOfMonth: number | string
) => {
  const emptyDates = { hraStartDateFormatted: "", statementCreationDateFormatted: "" }

  try {
    if (!hraStartString) {
      return emptyDates
    }
    const hraStartDate = moment(hraStartString, "YYYY-MM-DD") //Using moment to avoid Date inconsistencies
    const statementCreationDateFormatted =
      statementCreationDate ??
      hraStartDate
        .clone()
        .set("date", dayOfMonth as number)
        .toDate()

    return {
      hraStartDateFormatted: hraStartDate.format("YYYY-MM-DD"),
      statementCreationDateFormatted,
    }
  } catch (error) {
    console.error("Error setting HRA dates")

    return emptyDates
  }
}

export const convertHraPlanToFormValues = (existingData: HraPlanModel): HraPlanFormValues => {
  const {
    hraStartDate = "",
    statementCreationDate,
    statementFrequency = "",
    dayOfMonth,
    complianceFrequency,
    eligibleEmployees = "",
    participatingEmployees = "",
    isAutoPay = false,
    packageName,
    hraType = ICHRA_HRA_TYPE,
  } = existingData || {}

  const { hraStartDateFormatted, statementCreationDateFormatted } = buildStatementCreationDate(
    hraStartDate,
    statementCreationDate,
    dayOfMonth
  )

  return {
    hraStartDate: hraStartDateFormatted,
    statementFrequency,
    statementCreationDate: statementCreationDateFormatted,
    complianceFrequency,
    hraType,
    eligibleEmployees,
    participatingEmployees,
    isAutoPay,
    packageName,
    submit: false,
  }
}

export const convertUnassistedHraPlanToFormValues = (existingData: HraPlanModel): HraPlanDiyFormValues => {
  const {
    hraStartDate = "",
    statementCreationDate,
    dayOfMonth,
    willOfferHRA: willOfferHra = false,
    eligibleEmployees = "",
    participatingEmployees = "",
    isAutoPay = false,
    packageName = "Starter",
  } = existingData || {}

  const { hraStartDateFormatted } = buildStatementCreationDate(hraStartDate, statementCreationDate, dayOfMonth)

  return {
    ichra: willOfferHra ? "offeredIchraYes" : "offeredIchraNo",
    statementCreationDate,
    hraStartDate: hraStartDateFormatted,
    eligibleEmployees,
    participatingEmployees,
    isAutoPay,
    submit: "false",
    packageName,
  }
}

const transformDate = (date: Date | Moment | string) => moment(date).format("YYYY-MM-DD")

export const hraPlanTypeFormToPayload = (formValues: CreateHraPlanValues, companyId: Uuid): Partial<HraPlanModel> => {
  const dayOfMonth = isNumber(formValues.statementCreationDate)
    ? formValues.statementCreationDate
    : (formValues.statementCreationDate as Date).getDate()
  const payload: Partial<HraPlanModel> = {
    companyId,
    complianceFrequency: formValues.complianceFrequency as ComplianceFrequency,
    dayOfMonth,
    hraEndDate: formValues.hraEndDate,
    hraStartDate: transformDate(formValues.hraStartDate),
    hraType: formValues.hraType,
    partTimeHourQualification: 1,
    seasonalMonthQualification: 1,
    eligibleEmployees: formValues.eligibleEmployees ? parseInt(formValues.eligibleEmployees.toString()) : undefined,
    participatingEmployees: formValues.participatingEmployees
      ? parseInt(formValues.participatingEmployees.toString())
      : undefined,
    isAutoPay: formValues.isAutoPay,
    packageName: formValues.packageName,
    statementFrequency: formValues.statementFrequency,
  }

  return payload
}

export const hraPlanDiyTypeFormToPayload = (
  formValues: CreateUnassistedHraPlanValues,
  companyId: Uuid
): Partial<HraPlanModel> => {
  const payload: Partial<HraPlanModel> = {
    companyId,
    dayOfMonth: formValues.statementCreationDate,
    hraEndDate: formValues.hraEndDate,
    hraStartDate: transformDate(formValues.hraStartDate),
    hraType: ICHRA_HRA_TYPE,
    partTimeHourQualification: 1,
    seasonalMonthQualification: 1,
    eligibleEmployees: parseInt(formValues.eligibleEmployees.toString()),
    participatingEmployees: parseInt(formValues.participatingEmployees.toString()),
    isAutoPay: formValues.isAutoPay,
    packageName: formValues.packageName,
  }

  return payload
}

export const useGetHraPlan = (companyId: Uuid | undefined) =>
  useQuery({
    queryKey: ["hraPlan", companyId],
    queryFn: () => getHraPlan(companyId!), // SAFETY: Enabled enforces that this value is non-null
    enabled: !!companyId,
    staleTime: 2000,
  })

export const useCreateHraPlan = (companyId: Uuid) =>
  useMutation({
    mutationFn: (values: CreateHraPlanValues) => createHraPlan(hraPlanTypeFormToPayload(values, companyId)),
  })

export const useUpdateHraPlan = (companyId: Uuid, hraPlanId: Uuid | undefined) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (values: CreateHraPlanValues) => {
      if (!hraPlanId) {
        throw new Error("HRA Plan ID not found")
      }

      return updateHraPlan(hraPlanTypeFormToPayload(values, companyId), hraPlanId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["hraPlan", companyId] })
      queryClient.invalidateQueries({ queryKey: ["onboarding-statuses", companyId] })
    },
  })
}

export const useCreateUnassistedHraPlan = (companyId: Uuid) =>
  useMutation({
    mutationFn: (values: CreateUnassistedHraPlanValues) =>
      createHraPlan(hraPlanDiyTypeFormToPayload(values, companyId)),
  })

export const useUpdateUnassistedHraPlan = (companyId: Uuid, hraPlanId: Uuid | undefined) =>
  useMutation({
    mutationFn: async (values: CreateUnassistedHraPlanValues) => {
      if (!hraPlanId) {
        throw new Error("HRA Plan ID not found")
      }

      return updateHraPlan(hraPlanDiyTypeFormToPayload(values, companyId), hraPlanId)
    },
  })
