import { PAYROLL_REPORT, RECONCILIATION_PAYROLL_REPORT } from "@/features/Documents/documentsConstants"
import { useCompanyDocuments } from "@/features/Documents/documentsService"
import { ServiceDocument } from "@/features/Documents/documentsTypes"
import { formatDateToHumanReadable, getClosestMonthStartDate, getLastDayOfMonth } from "@/utils/dates"
import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { PayrollReportHistoryTable } from "./PayrollReportHistoryTable"

const formatStartDate = (startDate: string | Date | null) => {
  if (!startDate) return ""
  return DateTime.fromISO(startDate.toString(), { zone: "utc" }).toLocaleString()
}

interface PayrollReportModalProps {
  onClose: () => void
  open: boolean
  companyId: string
  currentCompanyHraPlanStartDate: string
  reportType: string
}

export const PayrollReportModal = ({
  onClose,
  open,
  companyId,
  currentCompanyHraPlanStartDate,
  reportType,
}: PayrollReportModalProps) => {
  const [_startDate, setStartDate] = useState<string | Date>(formatStartDate(currentCompanyHraPlanStartDate))
  const {
    data: payrollReports,
    isLoading: isPayrollReportsLoading,
    isError: isPayrollReportsError,
    refetch: refetchPayrollReports,
  } = useCompanyDocuments(companyId, true, reportType)
  payrollReports?.sort((a, b) => b.createdAt.localeCompare(a.createdAt))

  const calculateStartDate = (reports: ServiceDocument[]) => {
    const endDates = reports.map(r =>
      r?.documentType === PAYROLL_REPORT && r?.applicableTimeframeEnd ? new Date(r.applicableTimeframeEnd).getTime() : 0
    )
    const latestEndDate = Math.max(...endDates)
    return latestEndDate ? new Date(latestEndDate) : null
  }
  const closestMonthStartDate = getClosestMonthStartDate(currentCompanyHraPlanStartDate)
  const expectedFirstReportStartDate =
    reportType === RECONCILIATION_PAYROLL_REPORT ? getLastDayOfMonth(closestMonthStartDate) : closestMonthStartDate

  useEffect(() => {
    if (open) {
      refetchPayrollReports()
    }
  }, [open, refetchPayrollReports])

  useEffect(() => {
    if (!isPayrollReportsLoading && !isPayrollReportsError && payrollReports?.length) {
      const latestStartDate = calculateStartDate(payrollReports)
      if (latestStartDate) {
        setStartDate(latestStartDate)
      }
    }
  }, [payrollReports, isPayrollReportsError, isPayrollReportsLoading])

  const hasPayrollReports = payrollReports && payrollReports.length > 0

  return (
    <Dialog open={open} scroll="body" data-qa="run-payroll-report-modal" aria-labelledby="run-payroll-report-modal">
      <DialogTitle>
        <Grid
          container
          sx={{ justifyContent: "space-between", alignItems: "center" }}
          className="run-payroll-report-dialog-headline-container"
        >
          <Typography className="run-payroll-report-dialog-headline" variant="h3tiempos">
            Report History
            {/*
              FUTURE: Reintroduce the dynamic modal title when reports can be generated properly.
              {(isStartDateInFuture || !hasPayrollReports) ? "Report History" : "Run Payroll Report"}
            */}
          </Typography>
          <CloseIcon
            data-qa="run-payroll-report-dialog-close-icon-button"
            className="dialog-close-icon"
            onClick={onClose}
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        {/*
          FUTURE: Reintroduce the dynamic modal title when reports can be generated properly.
          {(!isStartDateInFuture && hasPayrollReports) && (
            <>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1">This will be the date range of the payroll report.</Typography>
                </Grid>
                <Grid item container justifyContent="center" py={6} spacing={3} xs={12}>
                  <Grid item xs={12} md={6}>
                    <DatePickerField
                      data-qa="payroll-report-year-selector"
                      name="startDate"
                      label="Start Date"
                      fullWidth
                      value={getOnlyDate(values.startDate ?? null)}
                      readOnly
                      disabled={isLoading}
                      variant="outlined"
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePickerField
                      data-qa="payroll-report-month-selector"
                      name="endDate"
                      label="End Date"
                      fullWidth
                      readOnly
                      disabled={isLoading}
                      value={getOnlyDate(values.endDate ?? null)}
                      variant="outlined"
                      type="date"
                    />
                  </Grid>
                </Grid>
              </Grid>
              
              <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button variant="text" onClick={onClose} color="inherit">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  data-qa="generate-report-button"
                  onClick={handleGenerateReport}
                  disabled={isLoading}
                  startIcon={isGeneratingReportLoading ? <CircularProgress size={18} color="inherit" /> : null}
                >
                  {isGeneratingReportLoading ? "Generating..." : "Generate Report"}
                </Button>
              </DialogActions>
            </>
          )}
        */}

        {!isPayrollReportsLoading && !hasPayrollReports && (
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1" align="center" mt={4}>
                No payroll reports found. Your first report will be available after{" "}
                {formatDateToHumanReadable(expectedFirstReportStartDate)}.
              </Typography>
            </Grid>
          </Grid>
        )}

        {isPayrollReportsLoading && (
          <Grid container justifyContent="center" alignItems="center" sx={{ my: 2 }}>
            <Typography variant="body2" color="text.secondary">
              Loading payroll reports...
            </Typography>
          </Grid>
        )}

        {!isPayrollReportsLoading && payrollReports && payrollReports.length > 0 && (
          /* FUTURE: Update `showTitle` logic once backend API has been updated to accept a date range. */
          <PayrollReportHistoryTable showTitle={false} payrollReports={payrollReports} companyId={companyId} />
        )}
      </DialogContent>
    </Dialog>
  )
}
