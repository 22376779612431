import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import {
  CalculateReimbursementRatePayload,
  CalculateReimbursementRateResponse,
  CustomClassDataWithoutDeleted,
  HraClassModel,
  HraClassModelResponse,
  PlanStructureModel,
  PlanStructurePatchRequest,
  PlanStructureRequest,
} from "./planStructureTypes"
import {
  createClass,
  createHraClassPayload,
  mapFormValuesToCreateClassesPayload,
  removeCommasFromPayload,
} from "./planStructureUtils"

const planStructureBaseUrl = `${appConfig.baseApiUrl}/benefits/v1/companies`

export const getCurrentClasses = async (companyId: string, planId: string) =>
  (await axios.get(`${planStructureBaseUrl}/${companyId}/plans/${planId}/classes`)).data as HraClassModelResponse[]

export const getClassDetails = async (companyId: string, planId: string, classId: string) =>
  createClass(
    (await axios.get(`${planStructureBaseUrl}/${companyId}/plans/${planId}/classes/${classId}`)).data as HraClassModel
  )

export const createPlanStructure = async (companyId: string, planId: string, payload: PlanStructureRequest) => {
  if (payload.customclassValues) {
    const customclassValues = payload.customclassValues

    const newClasses = (
      await axios.post(`${planStructureBaseUrl}/${companyId}/plans/${planId}/classes`, {
        partTimeHourQualification: customclassValues.partTimeHourQualification,
        seasonalMonthQualification: customclassValues.seasonalMonthQualification,
        hraClasses: Object.values(customclassValues.customClassData as CustomClassDataWithoutDeleted).map(
          createHraClassPayload
        ),
      })
    ).data as PlanStructureModel

    return newClasses
  } else if (payload.values) {
    const newClasses = (
      await axios.post(
        `${planStructureBaseUrl}/${companyId}/plans/${planId}/classes`,
        mapFormValuesToCreateClassesPayload(payload.values)
      )
    ).data as PlanStructureModel

    return newClasses
  } else {
    throw Error()
  }
}

export const updatePlanStructure = async (companyId: string, planId: string, payload: PlanStructurePatchRequest) => {
  const updatedClasses = (await axios.patch(`${planStructureBaseUrl}/${companyId}/plans/${planId}/classes`, payload))
    .data as PlanStructureModel

  return updatedClasses
}

export const calculateReimbursementRate = async (payload: CalculateReimbursementRatePayload) => {
  const cleanedPayload = {
    ...payload,
    employeeAmount: payload.employeeAmount.replace(/[$,]/g, ""),
    employeeAndSpouseAmount: payload.employeeAndSpouseAmount.replace(/[$,]/g, ""),
    employeeAndChildrenAmount: payload.employeeAndChildrenAmount.replace(/[$,]/g, ""),
    employeeAndSpouseAndChildrenAmount: payload.employeeAndSpouseAndChildrenAmount.replace(/[$,]/g, ""),
  }

  const response = await axios.post(
    `${appConfig.baseApiUrl}/benefits/v1/reimbursement-rates/calculate`,
    removeCommasFromPayload(cleanedPayload)
  )

  return response.data as CalculateReimbursementRateResponse
}
