import { Grid } from "@mui/material"
import { ReactNode } from "react"

interface NavigationContainerProps {
  children: ReactNode
}

export const NavigationContainer = ({ children }: NavigationContainerProps) => (
  <Grid container justifyContent="space-between" sx={{ mt: 12, px: "12%" }}>
    {children}
  </Grid>
)
