import { StyledCard } from "@/components/StyledCard"
import { useGetEnrollmentSearch } from "@/features/TCHub/Enrollment/tcHubEnrollmentService"
import { colors } from "@/theme/palette"
import SearchIcon from "@mui/icons-material/Search"
import { Button, Grid, TextField } from "@mui/material"
import { isNumber } from "lodash"
import { useMemo } from "react"
import { create } from "zustand"

const useEnrollmentFilterState = create<{
  filters: {
    employeeName?: string
    employeeEmail?: string
    companyName?: string
    state?: string
    carrierId?: string
    enrollmentStatus?: string
    planYear?: string
    planMarket?: string
  }
  setFilter: (field: string, value: string) => void
}>(set => ({
  filters: {},
  setFilter: (field, value) =>
    set(state => ({
      filters: { ...state.filters, [field]: value },
    })),
}))

export const useEnrollmentSearch = () => {
  const { filters, setFilter } = useEnrollmentFilterState()
  const { data, isLoading, refetch, isRefetching } = useGetEnrollmentSearch({
    employeeName: filters?.employeeName || undefined,
    employeeEmail: filters?.employeeEmail || undefined,
    companyName: filters?.companyName || undefined,
    carrierId: filters?.carrierId || undefined,
    state: filters?.state || undefined,
    planYear: isNumber(filters?.planYear) ? parseInt(filters?.planYear) : undefined,
  })

  const handleSearch = () => refetch()

  const enrollmentsData = useMemo(() => {
    if (data?.healthBenefitElections) {
      return data.healthBenefitElections
    }
    return []
  }, [data?.healthBenefitElections])

  return {
    enrollmentsData,
    setFilter,
    handleSearch,
    filters,
    isLoading: isLoading || isRefetching,
  }
}

export const EnrollmentSearch = () => {
  const { filters, setFilter, handleSearch } = useEnrollmentSearch()

  return (
    <StyledCard>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={10} container spacing={4} alignItems="center">
          <Grid item xs={6}>
            <TextField
              fullWidth
              placeholder="Search by Name"
              value={filters?.employeeName ?? ""}
              onChange={(event: { target: { value: string } }) => {
                setFilter("employeeName", event.target.value)
              }}
              onKeyUp={event => {
                if (event.key === "Enter") {
                  handleSearch()
                }
              }}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: <SearchIcon sx={{ mr: 2 }} />,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              placeholder="Search by Email"
              value={filters?.employeeEmail ?? ""}
              onChange={(event: { target: { value: string } }) => {
                setFilter("employeeEmail", event.target.value)
              }}
              onKeyUp={event => {
                if (event.key === "Enter") {
                  handleSearch()
                }
              }}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: <SearchIcon sx={{ mr: 2 }} />,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              placeholder="Search by Company"
              value={filters?.companyName ?? ""}
              onChange={(event: { target: { value: string } }) => {
                setFilter("companyName", event.target.value)
              }}
              onKeyUp={event => {
                if (event.key === "Enter") {
                  handleSearch()
                }
              }}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: <SearchIcon sx={{ mr: 2 }} />,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={2}>
          <Button
            disableElevation
            data-qa="search-button"
            variant="contained"
            onClick={handleSearch}
            fullWidth
            sx={{
              backgroundColor: colors.boneChilling,
              "&:hover": { backgroundColor: "colors.mintGreen" },
              color: theme => theme.palette.primary.main,
            }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </StyledCard>
  )
}
