import { addResponse } from "@/features/HRAPlanDesign/HRAPlanDesign.data"
import { usePrescreenResult } from "@/features/HRAPlanDesign/HRAPlanDesign.hooks"
import {
  isIchraDiy,
  isIchraNonDiy,
  isQsehra,
  PLAN_RESULT,
  PRE_SCREEN_QUESTION,
  radioGroupHraValidation,
} from "@/features/HRAPlanDesign/HRAPlanDesign.utils"
import { createDataQa } from "@/utils/dataQa"
import { Formik } from "formik"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { NavigationContainer } from "./NavigationContainer"
import { QuestionBody } from "./QuestionBody"
import { QuestionContainer } from "./QuestionContainer"
import { QuestionTitle } from "./QuestionTitle"
import { QuizBackButton } from "./QuizBackButton"
import { QuizLoader } from "./QuizLoader"
import { QuizNextQuestionButton } from "./QuizNextQuestionButton"
import { QuizProgressBar } from "./QuizProgressBar"
import { QuizRadioGroupQuestion } from "./QuizRadioGroupQuestion"

const formOptions = ["Yes", "No"]

export const HaveGroupPlan = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const numFtEeResult = usePrescreenResult("NUM_FT_EE")
  const numFtEeResultPlan = numFtEeResult?.result
  const haveGroupPlanResult = usePrescreenResult("HAVE_GROUP_PLAN")
  const haveGroupPlanResponse = haveGroupPlanResult?.response
  const dataQa = createDataQa("have-group-plan")

  const initialValues = {
    radioGroup: haveGroupPlanResponse || "",
  }

  const handleBack = async () => {
    navigate("/hra-determination/num_ft_ee")
  }

  const handleFormSubmit = async (values: any, { resetForm, setErrors, setStatus, setSubmitting }: any) => {
    try {
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)

      if (formOptions.includes(values.radioGroup)) {
        if (numFtEeResultPlan && isQsehra(numFtEeResultPlan)) {
          switch (values.radioGroup) {
            case "Yes":
              await dispatch(
                addResponse({
                  question: PRE_SCREEN_QUESTION.HAVE_GROUP_PLAN,
                  response: values.radioGroup,
                  result: PLAN_RESULT.QSEHRA,
                })
              )
              navigate("/hra-determination/keep_group_plan")
              break
            case "No":
              await dispatch(
                addResponse({
                  question: PRE_SCREEN_QUESTION.HAVE_GROUP_PLAN,
                  response: values.radioGroup,
                  result: PLAN_RESULT.QSEHRA,
                })
              )
              navigate("/hra-determination/monthly_allowance")
              break
            default:
              throw Error("Invalid Option Selected")
          }
        } else if (numFtEeResultPlan && (isIchraDiy(numFtEeResultPlan) || isIchraNonDiy(numFtEeResultPlan))) {
          switch (values.radioGroup) {
            case "Yes":
              await dispatch(
                addResponse({
                  question: PRE_SCREEN_QUESTION.HAVE_GROUP_PLAN,
                  response: values.radioGroup,
                  result: numFtEeResultPlan,
                })
              )
              navigate("/hra-determination/keep_group_plan")
              break
            case "No":
              await dispatch(
                addResponse({
                  question: PRE_SCREEN_QUESTION.HAVE_GROUP_PLAN,
                  response: values.radioGroup,
                  result: numFtEeResultPlan,
                })
              )
              navigate("/hra-determination/location")
              break
            default:
              throw Error("Invalid Option Selected")
          }
        }
      }
    } catch (error: any) {
      setStatus({ sent: false })
      setErrors({ submit: error.message })
      setSubmitting(false)
    }
  }

  return (
    <QuestionContainer data-qa={createDataQa(dataQa, "question-wrapper")}>
      <Formik initialValues={initialValues} validationSchema={radioGroupHraValidation} onSubmit={handleFormSubmit}>
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, isValid, values }) => (
          <>
            <QuizProgressBar value={40} />
            <QuestionTitle>Do you currently have a group health plan for your organization?</QuestionTitle>
            <QuestionBody>Some HRA Plan designs are incompatible with group health plans.</QuestionBody>
            {isSubmitting ? (
              <QuizLoader />
            ) : (
              <form onSubmit={handleSubmit}>
                <QuizRadioGroupQuestion
                  options={formOptions}
                  value={values.radioGroup}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="radioGroup"
                  data-qa={createDataQa(dataQa, "options")}
                />
                <NavigationContainer>
                  <QuizBackButton handleBack={handleBack} />
                  <QuizNextQuestionButton
                    onClick={handleSubmit}
                    disabled={!(isValid && values.radioGroup && !isSubmitting && errors)}
                  />
                </NavigationContainer>
              </form>
            )}
          </>
        )}
      </Formik>
    </QuestionContainer>
  )
}
