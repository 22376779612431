import { Grid, Skeleton, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { times } from "lodash"

interface SkeletonTableDesignProps {
  headerTitles: string[]
  bodyRowsCount: number
  rowStyle?: SxProps
  "data-qa": string
}

// Generates skeleton columns for table header
const SkeletonHeaderColumns = ({ columnsTitles }: { columnsTitles: string[] }) => (
  <TableRow>
    {times(columnsTitles.length, index => (
      <TableCell key={`header-${index}`} size="small" padding="normal">
        <Typography
          variant="body1"
          sx={{
            margin: 0,
            fontSize: "0.875rem",
            fontWeight: 500,
            lineHeight: "1.45781rem",
            fontFamily: "Mark Pro Medium",
            textTransform: "capitalize",
          }}
        >
          {columnsTitles[index]}
        </Typography>
      </TableCell>
    ))}
  </TableRow>
)

// Generates skeleton rows for table body
const SkeletonBodyRows = ({ rows, columns, rowStyle }: { rows: number; columns: number; rowStyle: SxProps }) => {
  const skeletonRows = times(rows, rowIndex => (
    <TableRow key={`row-${rowIndex}`} sx={rowStyle}>
      {times(columns, columnIndex => (
        <TableCell key={`cell-${rowIndex}-${columnIndex}`}>
          <Skeleton variant="text" />
        </TableCell>
      ))}
    </TableRow>
  ))

  return skeletonRows
}

// Combine header columns and body rows
export const SkeletonTableLoader = ({
  headerTitles,
  bodyRowsCount,
  "data-qa": dataQa,
  rowStyle,
}: SkeletonTableDesignProps) => (
  <Grid container>
    <Table data-qa={dataQa} sx={{ marginTop: 0, marginLeft: 4 }}>
      <TableHead>
        <SkeletonHeaderColumns columnsTitles={headerTitles} />
      </TableHead>
      <TableBody>
        <SkeletonBodyRows rows={bodyRowsCount} columns={headerTitles.length} rowStyle={rowStyle ?? {}} />
      </TableBody>
    </Table>
  </Grid>
)
