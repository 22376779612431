import { Grid, Typography } from "@mui/material"
import { ReactNode } from "react"

interface QuestionBodyProps {
  children: ReactNode
}

export const QuestionBody = ({ children }: QuestionBodyProps) => (
  <Grid item>
    <Typography variant="body1" align="center" sx={{ mb: 10 }}>
      {children}
    </Typography>
  </Grid>
)
