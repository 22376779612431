import { SelectField, SelectFieldProps } from "@/components/SelectField"
import { CityTextField, Street1TextField, Street2TextField } from "@/components/TextFields"
import { ZipCodeCountyAutoComplete } from "@/components/ZipCodeCountyAutoComplete"
import { CountiesModel } from "@/features/CreateCompany/createCompanyTypes"
import { useNotifications } from "@/services/notificationService"
import { DataQa } from "@/utils/dataQa"
import { STATE_OPTIONS, UsaState } from "@/utils/States"
import { PickOptional } from "@/utils/types"
import { validateMailingAddress } from "@/utils/validations"
import { Grid, Typography } from "@mui/material"
import { Formik, useFormikContext } from "formik"
import { isEqual } from "lodash"
import { useEffect, useState } from "react"
import * as Yup from "yup"
import { CARRIER_QUESTIONS, MAILING_ADDRESS, SSN } from "../../benefitsElectionConstants"
import { useShoppingSession, useShoppingUrl, useUpdateHealthBenefitsElection } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { HealthBenefitElectionsMailingAddress } from "../../benefitsElectionTypes"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"

interface MailingAddressFormValues {
  street1: string
  street2: string
  city: string
  state: UsaState | ""
  zipCode: null | CountiesModel
}
const initialValues: MailingAddressFormValues = {
  street1: "",
  street2: "",
  city: "",
  state: "",
  zipCode: null,
}

// Regular expression to match "PO Box", "P.O. Box", "P O Box", "Post Office Box", etc.
const poBoxRegex = /\b(?:P\.?O\.?|Post(?:\s+Office)?)\s?Box\b/i
const poBoxErrorMessage = "Address invalid. Enter a valid address, PO Box is not allowed."

// FUTURE: replace the poBox regex validation with an endpoint call
const validationSchema = validateMailingAddress
  .test("street1", "Does not contain PO box address", obj => {
    if (!obj.street1 || (!poBoxRegex.test(obj.street1) && !obj.street1.toLowerCase().includes("locker"))) {
      return true
    }

    return new Yup.ValidationError(poBoxErrorMessage, null, "street1")
  })
  .test("street2", "Does not contain PO box address", obj => {
    if (!obj.street2 || (!poBoxRegex.test(obj.street2) && !obj.street2.toLowerCase().includes("locker"))) {
      return true
    }

    return new Yup.ValidationError(poBoxErrorMessage, null, "street2")
  })

const convertFormValuesToMailingAddress = (
  values: MailingAddressFormValues
): Partial<HealthBenefitElectionsMailingAddress> => {
  const { street1, street2, city, state, zipCode } = values
  const stateWithCast = state as UsaState

  return {
    street1,
    street2,
    city,
    state: stateWithCast,
    zipCode: zipCode?.zipCode,
    county: zipCode?.countyName,
    fipsCode: zipCode?.fipsCode,
  }
}

export const baseDataQa = "mailing-address" as DataQa

// FUTURE: Find a better place for this component to live
interface StateValues {
  state: UsaState
}

type StateSelectFieldProps = PickOptional<SelectFieldProps, "data" | "name">

export const StateSelectField = (props: StateSelectFieldProps) => {
  const { values, handleChange, handleBlur } = useFormikContext<StateValues>()

  return (
    <SelectField
      data={STATE_OPTIONS}
      type="text"
      name="state"
      label="State"
      required
      value={values.state}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder="Select State"
      dataQa="state-select"
      {...props}
    />
  )
}

export const MailingAddress = () => {
  const shoppingSessionId = useShoppingSession()
  const shoppingUrl = useShoppingUrl()
  const { notify } = useNotifications(baseDataQa)
  const { mutateAsync: updateHealthBenefitsElection, isPending } = useUpdateHealthBenefitsElection(shoppingSessionId)
  const currentShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const setHealthBenefitElection = useBenefitsElectionStore(state => state.setHealthBenefitElection)
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const currentHealthBenefitElection = currentShoppingSession.healthBenefitElections?.[0]
  const currentMailingAddress = currentHealthBenefitElection?.mailingAddress
  const [formInitialValues, setFormInitialValues] = useState(initialValues)

  useEffect(() => {
    if (currentMailingAddress) {
      const { street1, street2 = "", city, state, zipCode, county = "" } = currentMailingAddress
      const zipCodeObject = { zipCode, countyName: county, state, fipsCode: "" }

      setFormInitialValues({
        street1,
        street2,
        city,
        state,
        zipCode: zipCodeObject,
      })
    }
  }, [currentMailingAddress])

  const previous = shoppingUrl + CARRIER_QUESTIONS
  const next = shoppingUrl + SSN

  const setNextStep = () => {
    if (currentStep === MAILING_ADDRESS) {
      setCurrentStep(SSN)
    }
  }

  const showErrorSnackbar = () => {
    notify("Error saving mailing address, please try again later.", "error")
  }

  return (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async values => {}}
      validateOnMount
    >
      {({ errors, handleBlur, handleSubmit, touched, values, isValid, setFieldValue }) => (
        <BenefitsElectionStep
          title="Home Address"
          description="Update or provide the home address you wish to include in your plan."
          disabled={!isValid}
          previous={previous}
          next={next}
          advanceOnSuccess
          isSubmitting={isPending}
          data-qa={baseDataQa}
          required
          handleContinue={async () => {
            const isFilledAddressEqual = isEqual(formInitialValues, values)

            if (isFilledAddressEqual) {
              setNextStep()
            } else {
              const formMailingAddress = convertFormValuesToMailingAddress(values)

              if (currentMailingAddress) {
                formMailingAddress.id = currentMailingAddress.id
                formMailingAddress.country = currentMailingAddress.country
              }

              if (currentHealthBenefitElection) {
                const benefitElectionPayload = {
                  id: currentHealthBenefitElection.id,
                  employmentId: currentHealthBenefitElection.employmentId,
                  mailingAddress: formMailingAddress as HealthBenefitElectionsMailingAddress,
                } as const

                try {
                  const updatedBenefitElection = await updateHealthBenefitsElection(benefitElectionPayload)

                  setHealthBenefitElection(updatedBenefitElection)
                  notify("Succesfully saved mailing address", "success")
                  setNextStep()
                } catch (error: any) {
                  showErrorSnackbar()
                }
              } else {
                showErrorSnackbar()
              }
            }
          }}
        >
          <form noValidate onSubmit={handleSubmit} data-qa={`${baseDataQa}-form`}>
            <Grid container spacing={4}>
              <Grid item xs={12} mt={1}>
                <Typography variant="h5" data-qa={`${baseDataQa}-information-label`}>
                  Address information
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Street1TextField />
              </Grid>
              <Grid item xs={12} md={6}>
                <Street2TextField />
              </Grid>
              <Grid item xs={12} md={4}>
                <CityTextField sx={{ my: 0 }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <StateSelectField sx={{ mt: 0 }} dataQa={`${baseDataQa}-state-select`} />
              </Grid>
              <Grid item xs={12} md={4} mb={8}>
                <ZipCodeCountyAutoComplete
                  name="zipCode"
                  touched={Boolean(touched.zipCode)}
                  error={errors.zipCode}
                  setFieldValue={setFieldValue}
                  value={values.zipCode}
                  handleBlur={handleBlur}
                />
              </Grid>
            </Grid>
          </form>
        </BenefitsElectionStep>
      )}
    </Formik>
  )
}
