import { ClearableSearchInput } from "@/components/ClearableSearchInput/ClearableSearchInput"
import { SelectField } from "@/components/SelectField"
import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { StyledCard } from "@/components/StyledCard"
import { BaseTable } from "@/components/Table/Table"
import { TABLE_CELL_PADDING } from "@/constants"
import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import { useNotifications } from "@/services/notificationService"
import { autoPayYearOptions } from "@/utils/dates"
import { formatCents, toTitleCase } from "@/utils/formatting"
import { validateTransferDetails } from "@/utils/validations"
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TableCell,
  TextField,
  Typography,
} from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { Formik, FormikProps } from "formik"
import { get, sumBy } from "lodash"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { NumericFormat } from "react-number-format"
import * as Yup from "yup"
import { CompanyAutopaySearchAutoComplete } from "../../components/CompanyAutopaySearchAutoComplete"
import { DISBURSEMENT_ACCOUNT, FIVE_PERCENT, FUNDING_TYPES, PAYMENT_ACCOUNT } from "../../tcHubConstants"
import {
  useBookTransfer,
  useFundingAccounts,
  useFundingTransfer,
  useRecurringPremiums,
  useReserveBalance,
} from "../../tcHubService"
import {
  FundingAccount,
  FundingEventPremium,
  FundingEventPremiumTableHeader,
  FundsTransferRequest,
} from "../../tcHubTypes"

const headers: FundingEventPremiumTableHeader[] = [
  { id: "select", label: "", sortable: true, alignment: "left" },
  { id: "name", label: "Name", sortable: true, field: "name", alignment: "left" },
  {
    id: "premiumAmountCents",
    label: "Premium Amount",
    sortable: true,
    field: "premiumAmountCents",
    alignment: "left",
  },
]

const matchAccountName = (fundingEventPremiums: FundingEventPremium, searchQueryLowerCase: string) =>
  (fundingEventPremiums.fundingEntityName ?? "").toLowerCase().includes(searchQueryLowerCase)

interface FundingEventTableProps {
  searchInputValue: string
  fundingEventPremiums: FundingEventPremium[]
  selectedRows: FundingEventPremium[]
  setSelectedRows: (rows: FundingEventPremium[]) => void
}

// Premiums table
const FundingEventPremiumsTable = ({
  searchInputValue,
  fundingEventPremiums,
  setSelectedRows,
  selectedRows,
}: FundingEventTableProps) => {
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)

  const handleToggleSelectAll = () => {
    setSelectedRows(selectedRows.length === 0 ? (fundingEventPremiums ?? []) : [])
  }

  const handleToggleSelect = (row: FundingEventPremium) => {
    const selectedIndex = selectedRows.findIndex(selectedRow => selectedRow.id === row.id)
    let newSelectedRows: FundingEventPremium[] = []

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, row)
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1))
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      )
    }

    setSelectedRows(newSelectedRows)
  }

  const filteredFundingEventPremiums = fundingEventPremiums?.filter(fundingPremium =>
    matchAccountName(fundingPremium, searchInputValue.toLowerCase())
  )

  if (!filteredFundingEventPremiums?.length) return null

  return (
    <BaseTable
      rows={filteredFundingEventPremiums}
      selected={selectedRows}
      searchCriteria=""
      onToggleSelect={handleToggleSelect}
      onToggleOrderBy={() => []}
      onToggleSelectAll={handleToggleSelectAll}
      onPageChange={changedPage => setPage(changedPage)}
      onRowsPerPageChange={rows => setRowsPerPage(rows)}
      uniqueIdSelector={row => row.id}
      headCells={headers ?? []}
      rowsPerPage={rowsPerPage}
      page={page}
      fullWidth
      orderBy={[{ headCellId: "", direction: "asc" }]}
    >
      {({ row, isSelected, toggleSelect }) => (
        <>
          <TableCell padding="checkbox">
            <Checkbox checked={isSelected} onChange={toggleSelect} inputProps={{ "aria-label": "select all" }} />
          </TableCell>
          <TableCell>
            <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="funding-entity-name">
              {toTitleCase(row.fundingEntityName)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="registration-status">
              {formatCents(row.premiumAmountCents)}
            </Typography>
          </TableCell>
        </>
      )}
    </BaseTable>
  )
}

type TransferSchema = Yup.InferType<typeof validateTransferDetails>

// FUTURE: Remove these unsafe casts
const TRANSFER_FORM_INITIAL_VALUES: TransferSchema = {
  company: { id: null as any },
  fromAccountId: "",
  toAccountId: "",
  amount: undefined as any,
  description: "",
  fundingType: "",
}

// Premium or Reserve transfer 'to' and 'from' account fields
interface AccountSelectionFieldsProps {
  fundingAccounts: FundingAccount[] | undefined
  handleBlur: FormikProps<TransferSchema>["handleBlur"]
  isLoadingFundingAccounts: boolean
  values: FormikProps<TransferSchema>["values"]
}

const getFromAccounts = (fundingAccounts: FundingAccount[] | undefined, fundingType: string) => {
  if (!fundingAccounts?.length) {
    return []
  }

  if (
    fundingType === FUNDING_TYPES.PREMIUMS_PAYMENT_TO_DISBURSMENT ||
    fundingType === FUNDING_TYPES.RESERVE_PAYMENT_TO_DISBURSEMENT
  ) {
    return fundingAccounts.filter(account => account.entityType === PAYMENT_ACCOUNT)
  }

  return fundingAccounts
}

const getToAccounts = (fundingAccounts: FundingAccount[] | undefined, fundingType: string) => {
  if (!fundingAccounts?.length) {
    return []
  }

  if (
    fundingType === FUNDING_TYPES.PREMIUMS_PAYMENT_TO_DISBURSMENT ||
    fundingType === FUNDING_TYPES.RESERVE_PAYMENT_TO_DISBURSEMENT
  ) {
    return fundingAccounts.filter(account => account.entityType === DISBURSEMENT_ACCOUNT)
  }

  return fundingAccounts
}

const AccountSelectionFields = ({
  fundingAccounts,
  handleBlur,
  isLoadingFundingAccounts,
  values,
}: AccountSelectionFieldsProps) => (
  <>
    <Grid item xs={12} sm={6}>
      <SelectField
        required
        name="fromAccountId"
        label="From Account"
        placeholder="Please Select"
        data={
          getFromAccounts(fundingAccounts, values.fundingType)?.map(account => ({
            value: account.fundingEntityId,
            label: `${account.name} - ${account.accountLast4Digits}`,
          })) ?? []
        }
        value={values.fromAccountId}
        onBlur={handleBlur}
        dataQa="from-account-dropdown"
        loading={isLoadingFundingAccounts}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <SelectField
        required
        name="toAccountId"
        label="To Account"
        placeholder="Please Select"
        data={
          getToAccounts(fundingAccounts, values.fundingType)?.map(account => ({
            value: account.fundingEntityId,
            label: `${account.name} - ${account.accountLast4Digits}`,
          })) ?? []
        }
        value={values.toAccountId}
        onBlur={handleBlur}
        dataQa="to-account-dropdown"
        loading={isLoadingFundingAccounts}
      />
    </Grid>
  </>
)

// Premium or Reserve transfer description field
interface DescriptionFieldProps {
  handleBlur: FormikProps<TransferSchema>["handleBlur"]
  values: FormikProps<TransferSchema>["values"]
  touched: any
  errors: any
  handleChange: any
}

const DescriptionField = ({ values, handleChange, handleBlur, touched, errors }: DescriptionFieldProps) => (
  <Grid item xs={12}>
    <TextField
      required
      fullWidth
      name="description"
      label="Description"
      value={values.description}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(touched.description && errors.description)}
      helperText={touched.description && errors.description}
      data-qa="transfer-description-input"
    />
  </Grid>
)

// Reserve transfer amount field
interface ReserveAmountToMoveFieldProps {
  handleBlur: FormikProps<TransferSchema>["handleBlur"]
  values: FormikProps<TransferSchema>["values"]
  touched: any
  errors: any
  reserveAmountToMove: number
  isLoadingReserveBalance: boolean
  isLoadingRecurringPremiums: boolean
  setFieldValue: FormikProps<TransferSchema>["setFieldValue"]
}

const ReserveAmountToMoveField = ({
  isLoadingRecurringPremiums,
  reserveAmountToMove,
  errors,
  touched,
  values,
  handleBlur,
  isLoadingReserveBalance,
  setFieldValue,
}: ReserveAmountToMoveFieldProps) => (
  <Grid item xs={12} sm={6} mt={2}>
    <FormControl fullWidth required>
      <InputLabel htmlFor="transfer-amount-label" error={touched.amount && Boolean(errors.amount)}>
        Amount to Move
      </InputLabel>
      <NumericFormat
        id="transfer-amount-input"
        customInput={OutlinedInput}
        startAdornment={
          isLoadingRecurringPremiums || isLoadingReserveBalance ? (
            <CircularProgress size={24} />
          ) : (
            <InputAdornment position="start">$</InputAdornment>
          )
        }
        name="amount"
        label="Amount to Move"
        value={reserveAmountToMove ? formatCents(reserveAmountToMove) : ""}
        onValueChange={({ floatValue }) => {
          if (floatValue !== undefined) {
            const roundedValue = Math.round(floatValue * 100) / 100

            setFieldValue("amount", roundedValue)
          }
        }}
        onBlur={handleBlur}
        error={Boolean(touched.amount && errors.amount)}
        placeholder={isLoadingReserveBalance ? "" : "0.00"}
        thousandSeparator
        decimalSeparator="."
        decimalScale={2}
        prefix=""
        data-qa="transfer-amount-input"
      />
      {touched.amount && errors.amount && <FormHelperText error>{errors.amount}</FormHelperText>}
    </FormControl>
  </Grid>
)

// Premium transfer amount field
interface PremiumAmountToMoveFieldProps {
  handleBlur: FormikProps<TransferSchema>["handleBlur"]
  touched: any
  errors: any
  isLoadingRecurringPremiums: boolean
  selectedRows: FundingEventPremium[]
}

const PremiumAmountToMoveField = ({
  isLoadingRecurringPremiums,
  errors,
  touched,
  handleBlur,
  selectedRows,
}: PremiumAmountToMoveFieldProps) => (
  <Grid item xs={6}>
    <FormControl fullWidth required>
      <InputLabel htmlFor="transfer-amount-label" error={touched.amount && Boolean(errors.amount)}>
        Total Premiums Moved
      </InputLabel>
      <NumericFormat
        id="transfer-amount-input"
        customInput={OutlinedInput}
        startAdornment={
          isLoadingRecurringPremiums ? (
            <CircularProgress size={24} />
          ) : (
            <InputAdornment position="start">$</InputAdornment>
          )
        }
        name="amount"
        label="Total Premiums Moved"
        value={formatCents(sumBy(selectedRows, "premiumAmountCents"))}
        onBlur={handleBlur}
        error={Boolean(touched.amount && errors.amount)}
        placeholder={isLoadingRecurringPremiums ? "" : "0.00"}
        thousandSeparator
        decimalSeparator="."
        decimalScale={2}
        prefix=""
        data-qa="transfer-amount-input"
      />
      {touched.amount && errors.amount && <FormHelperText error>{errors.amount}</FormHelperText>}
    </FormControl>
  </Grid>
)

// Generic transfer form fields
interface GenericFormFieldsProps {
  handleBlur: FormikProps<TransferSchema>["handleBlur"]
  touched: any
  errors: any
  isLoadingRecurringPremiums: boolean
  fundingAccounts: FundingAccount[] | undefined
  isLoadingFundingAccounts: boolean
  values: FormikProps<TransferSchema>["values"]
  isLoadingReserveBalance: boolean
  reserveAmountToMove: number
  setFieldValue: FormikProps<TransferSchema>["setFieldValue"]
  handleChange: FormikProps<TransferSchema>["handleChange"]
}

const GenericFormFields = ({
  handleChange,
  setFieldValue,
  fundingAccounts,
  handleBlur,
  isLoadingFundingAccounts,
  values,
  isLoadingReserveBalance,
  isLoadingRecurringPremiums,
  touched,
  errors,
  reserveAmountToMove,
}: GenericFormFieldsProps) => (
  <StyledCard>
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} mb={2}>
        <Typography variant="h6">Transfer details</Typography>
      </Grid>
      <AccountSelectionFields
        fundingAccounts={fundingAccounts}
        handleBlur={handleBlur}
        isLoadingFundingAccounts={isLoadingFundingAccounts}
        values={values}
      />
      <ReserveAmountToMoveField
        reserveAmountToMove={reserveAmountToMove}
        isLoadingReserveBalance={isLoadingReserveBalance}
        isLoadingRecurringPremiums={isLoadingRecurringPremiums}
        setFieldValue={setFieldValue}
        values={values}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
      />
      <DescriptionField
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      />
    </Grid>
  </StyledCard>
)

// Reserve transfer form fields
interface ReserveFormFieldsProps {
  handleBlur: FormikProps<TransferSchema>["handleBlur"]
  touched: any
  errors: any
  isLoadingRecurringPremiums: boolean
  fundingAccounts: FundingAccount[] | undefined
  isLoadingFundingAccounts: boolean
  values: FormikProps<TransferSchema>["values"]
  isLoadingReserveBalance: boolean
  reserveBalance: any
  minimumReserveRequired: number
  reserveAmountToMove: number
  setFieldValue: FormikProps<TransferSchema>["setFieldValue"]
  handleChange: FormikProps<TransferSchema>["handleChange"]
}

const ReserveFormFields = ({
  handleChange,
  setFieldValue,
  fundingAccounts,
  handleBlur,
  isLoadingFundingAccounts,
  values,
  isLoadingReserveBalance,
  isLoadingRecurringPremiums,
  touched,
  errors,
  reserveBalance,
  minimumReserveRequired,
  reserveAmountToMove,
}: ReserveFormFieldsProps) => (
  <StyledCard>
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} mb={2}>
        <Typography variant="h6">Reserve transfer details</Typography>
      </Grid>
      <AccountSelectionFields
        fundingAccounts={fundingAccounts}
        handleBlur={handleBlur}
        isLoadingFundingAccounts={isLoadingFundingAccounts}
        values={values}
      />
      <Grid item xs={6}>
        <FormControl fullWidth required>
          <InputLabel htmlFor="current-reserve-label">Current Reserve Amount</InputLabel>
          <NumericFormat
            id="current-reserve-input"
            customInput={OutlinedInput}
            startAdornment={
              isLoadingReserveBalance ? (
                <CircularProgress size={24} />
              ) : (
                <InputAdornment position="start">$</InputAdornment>
              )
            }
            name="reserveAmount"
            label="Current Reserve Amount"
            value={reserveBalance?.balanceCents ? formatCents(reserveBalance.balanceCents) : ""}
            readOnly
            disabled
            onBlur={handleBlur}
            thousandSeparator
            decimalSeparator="."
            decimalScale={2}
            prefix=""
            data-qa="current-reserve-input"
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth required>
          <InputLabel htmlFor="minimum-reserve-label">Minimum Reserve Required</InputLabel>
          <NumericFormat
            id="minimum-reserve-input"
            customInput={OutlinedInput}
            startAdornment={
              isLoadingRecurringPremiums ? (
                <CircularProgress size={24} />
              ) : (
                <InputAdornment position="start">$</InputAdornment>
              )
            }
            name="minimumReserveBalance"
            label="Minimum Reserve Amount"
            value={minimumReserveRequired ? formatCents(minimumReserveRequired) : ""}
            readOnly
            disabled
            onBlur={handleBlur}
            thousandSeparator
            decimalSeparator="."
            decimalScale={2}
            prefix=""
            data-qa="minimum-reserve-amount-input"
          />
        </FormControl>
      </Grid>
      <ReserveAmountToMoveField
        reserveAmountToMove={reserveAmountToMove}
        isLoadingReserveBalance={isLoadingReserveBalance}
        isLoadingRecurringPremiums={isLoadingRecurringPremiums}
        setFieldValue={setFieldValue}
        values={values}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
      />
      <DescriptionField
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      />
    </Grid>
  </StyledCard>
)

// Premium transfer form fields
interface PremiumFormFieldsProps {
  handleBlur: FormikProps<TransferSchema>["handleBlur"]
  touched: any
  errors: any
  isLoadingRecurringPremiums: boolean
  fundingAccounts: FundingAccount[] | undefined
  isLoadingFundingAccounts: boolean
  values: FormikProps<TransferSchema>["values"]
  handleChange: FormikProps<TransferSchema>["handleChange"]
  setSearchInputValue: (value: string) => void
  selectedRows: FundingEventPremium[]
  setSelectedRows: (rows: FundingEventPremium[]) => void
  searchInputValue: string
  recurringPremiumCollection: any
}

const PremiumFormFields = ({
  handleChange,
  fundingAccounts,
  handleBlur,
  isLoadingFundingAccounts,
  values,
  isLoadingRecurringPremiums,
  touched,
  errors,
  setSearchInputValue,
  selectedRows,
  setSelectedRows,
  searchInputValue,
  recurringPremiumCollection,
}: PremiumFormFieldsProps) => (
  <>
    <StyledCard>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} mb={2}>
          <Typography variant="h6">Premium transfer details</Typography>
        </Grid>
        <AccountSelectionFields
          fundingAccounts={fundingAccounts}
          handleBlur={handleBlur}
          isLoadingFundingAccounts={isLoadingFundingAccounts}
          values={values}
        />
        <Grid item xs={6} mb={6}>
          <SelectField
            required
            name="premiumYear"
            label="Premium Year"
            placeholder="Please Select"
            data={autoPayYearOptions}
            value={values.premiumYear}
            onBlur={handleBlur}
            dataQa="premium-year-dropdown"
          />
        </Grid>
        <Grid item xs={6} mb={6}>
          <SelectField
            required
            name="premiumMonth"
            label="Premium Month"
            placeholder="Please Select"
            data={[
              { value: "1", label: "January" },
              { value: "2", label: "February" },
              { value: "3", label: "March" },
              { value: "4", label: "April" },
              { value: "5", label: "May" },
              { value: "6", label: "June" },
              { value: "7", label: "July" },
              { value: "8", label: "August" },
              { value: "9", label: "September" },
              { value: "10", label: "October" },
              { value: "11", label: "November" },
              { value: "12", label: "December" },
            ]}
            value={values.premiumMonth}
            onBlur={handleBlur}
            dataQa="premium-month-dropdown"
          />
        </Grid>
      </Grid>
    </StyledCard>

    <StyledCard>
      <Grid item xs={12} mb={2}>
        <Typography variant="h6">Search for employee premium</Typography>
      </Grid>
      <Grid item xs={12}>
        <ClearableSearchInput
          onChange={event => setSearchInputValue(event.target.value)}
          handleClearClick={() => setSearchInputValue("")}
          data-qa="funding-event-premiums-search-input"
          placeholder="Search employee name"
          sx={{ minWidth: "18.75rem" }}
        />
      </Grid>
      <Grid item xs={12} mb={4}>
        {!isLoadingRecurringPremiums ? (
          <FundingEventPremiumsTable
            searchInputValue={searchInputValue}
            fundingEventPremiums={recurringPremiumCollection?.recurringPremiums ?? []}
            data-qa="funding-event-premiums-table"
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        ) : (
          <SkeletonTableLoader
            data-qa="skeleton-table-loader-accounts"
            headerTitles={headers.map(column => column.label)}
            bodyRowsCount={8}
          />
        )}
      </Grid>
    </StyledCard>

    <StyledCard>
      <Grid container spacing={4}>
        <PremiumAmountToMoveField
          isLoadingRecurringPremiums={isLoadingRecurringPremiums}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          selectedRows={selectedRows}
        />
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            name="employees-to-fund"
            label="Employees to fund"
            value={selectedRows.length}
            onChange={handleChange}
            onBlur={handleBlur}
            data-qa="employees-to-fund"
            disabled
          />
        </Grid>
        <DescriptionField
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
      </Grid>
    </StyledCard>
  </>
)

interface TransferFormProps extends FormikProps<TransferSchema> {
  selectedRows: FundingEventPremium[]
  setSelectedRows: (rows: FundingEventPremium[]) => void
}

const TransferForm = ({
  handleBlur,
  errors,
  touched,
  values,
  handleChange,
  setFieldValue,
  setTouched,
  handleSubmit,
  isValid,
  setSelectedRows,
  selectedRows,
  isValidating,
  isSubmitting,
}: TransferFormProps) => {
  const DEFAULT_YEAR = new Date().getFullYear()
  const DEFAULT_MONTH = new Date().getMonth() + 1

  values.premiumYear = values.premiumYear ?? DEFAULT_YEAR
  values.premiumMonth = values.premiumMonth ?? DEFAULT_MONTH

  // FUTURE: Remove unsafe non-null assertion
  const { isLoading: isLoadingFundingAccounts, data: fundingAccounts } = useFundingAccounts(values.company?.id ?? "")
  const { isLoading: isLoadingReserveBalance, data: reserveBalance } = useReserveBalance(values.company?.id ?? "")

  const {
    isLoading: isLoadingRecurringPremiums,
    data: recurringPremiumCollection,
    refetch,
  } = useRecurringPremiums(values.company?.id ?? "", {
    month: values.premiumMonth,
    year: values.premiumYear,
    limit: 1000,
    offset: 0,
  })

  useEffect(
    () => {
      refetch()
    },
    // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.premiumMonth, values.premiumYear]
  )

  const [searchInputValue, setSearchInputValue] = useState<string>("")

  // Calculate minimum reserve amount based on 5% of the total premiums for a time period
  const calculateMinimumReserveAmount = (recurringPremiums: any[] | undefined) => {
    if (!Array.isArray(recurringPremiums) || recurringPremiums.length === 0) {
      return 0
    }

    const totalPremiumsCents = recurringPremiums
      .map(premium => premium.premiumAmountCents)
      .reduce((total: number, amount: number) => total + amount, 0)

    const minimumReserveRequired = totalPremiumsCents * FIVE_PERCENT

    return minimumReserveRequired
  }

  // Minimum reserve required based on 5% of the total premiums
  const minimumReserveRequired = calculateMinimumReserveAmount(recurringPremiumCollection?.recurringPremiums)

  // Calculate the reserve amount to move
  const reserveAmountToMove = Math.max(
    0,
    minimumReserveRequired - (reserveBalance?.balanceCents ? reserveBalance.balanceCents : 0)
  )

  useEffect(() => {
    if (reserveAmountToMove > 0) {
      const reserveAsDecimal = Math.round((reserveAmountToMove / 100) * 100) / 100

      setFieldValue("amount", reserveAsDecimal, true)
      setTouched({ amount: true })
    }
  }, [reserveAmountToMove, setFieldValue, setTouched])

  return (
    <FormControl fullWidth sx={{ mt: 6 }}>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <StyledCard>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} mb={2}>
                <Typography variant="h6">Company and account type information</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CompanyAutopaySearchAutoComplete
                  name="company"
                  touched={Boolean(errors.company)}
                  error={errors.company?.toString()}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.company as CompanyModel}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField
                  required
                  name="fundingType"
                  label="Funding Type"
                  placeholder="Please Select"
                  data={[
                    {
                      value: FUNDING_TYPES.PREMIUMS_PAYMENT_TO_DISBURSMENT,
                      label: "Premiums - Payment  --> Disbursement",
                    },
                    {
                      value: FUNDING_TYPES.RESERVE_PAYMENT_TO_DISBURSEMENT,
                      label: "Reserve - Payment  --> Disbursement",
                    },
                    { value: "GENERIC", label: "Generic" },
                  ]}
                  value={values.fundingType}
                  onBlur={handleBlur}
                  dataQa="funding-type-dropdown"
                />
              </Grid>
            </Grid>
          </StyledCard>

          {values.fundingType === FUNDING_TYPES.RESERVE_PAYMENT_TO_DISBURSEMENT && (
            <ReserveFormFields
              fundingAccounts={fundingAccounts}
              handleBlur={handleBlur}
              isLoadingFundingAccounts={isLoadingFundingAccounts}
              values={values}
              isLoadingReserveBalance={isLoadingReserveBalance}
              isLoadingRecurringPremiums={isLoadingRecurringPremiums}
              touched={touched}
              errors={errors}
              reserveAmountToMove={reserveAmountToMove}
              reserveBalance={reserveBalance}
              minimumReserveRequired={minimumReserveRequired}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          )}
          {values.fundingType === FUNDING_TYPES.PREMIUMS_PAYMENT_TO_DISBURSMENT && (
            <PremiumFormFields
              fundingAccounts={fundingAccounts}
              handleBlur={handleBlur}
              isLoadingFundingAccounts={isLoadingFundingAccounts}
              values={values}
              isLoadingRecurringPremiums={isLoadingRecurringPremiums}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              setSearchInputValue={setSearchInputValue}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              searchInputValue={searchInputValue}
              recurringPremiumCollection={recurringPremiumCollection}
            />
          )}
          {values.fundingType === "GENERIC" && (
            <GenericFormFields
              fundingAccounts={fundingAccounts}
              handleBlur={handleBlur}
              isLoadingFundingAccounts={isLoadingFundingAccounts}
              values={values}
              isLoadingReserveBalance={isLoadingReserveBalance}
              isLoadingRecurringPremiums={isLoadingRecurringPremiums}
              touched={touched}
              errors={errors}
              reserveAmountToMove={reserveAmountToMove}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          )}
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button
            disabled={!Object.keys(touched).length || !isValid || isValidating || isSubmitting}
            type="submit"
            variant="contained"
            sx={{ mt: 5 }}
            data-qa="transfer-button"
          >
            Transfer
          </Button>
        </Grid>
      </form>
    </FormControl>
  )
}

export const TcHubTransferPage = () => {
  const queryClient = useQueryClient()
  const fundingTransfer = useFundingTransfer()
  const bookTransfer = useBookTransfer()
  const [selectedRows, setSelectedRows] = useState<FundingEventPremium[]>([])
  const { notify } = useNotifications("transfer-completed-success")

  return (
    <TcHubGuard requiredPermissions={["tc_hub_autopay"]}>
      <Grid container data-qa="tc-hub-transfer-page" direction="column">
        <Helmet title="TC Hub Transfer" />
        <Typography variant="h1" gutterBottom display="inline" data-qa="autopay">
          Transfer
        </Typography>
        <Typography variant="body1" gutterBottom>
          Type the name of the company you are looking for
        </Typography>
        <Formik
          data-qa="transfer-form"
          initialValues={TRANSFER_FORM_INITIAL_VALUES}
          validationSchema={validateTransferDetails}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              const fundingAccounts = queryClient.getQueryData(["fundingAccounts", values.company?.id]) as {
                fundingEntityId: string
                entityType: string
              }[]

              const isToPaymentAccount = fundingAccounts?.some(
                account => account.fundingEntityId === values.toAccountId && account.entityType === "PAYMENT_ACCOUNT"
              )

              const isFromPaymentAccount = fundingAccounts?.some(
                account => account.fundingEntityId === values.fromAccountId && account.entityType === "PAYMENT_ACCOUNT"
              )

              const isFundingOperation = isToPaymentAccount || isFromPaymentAccount

              const mutationPayload = {
                companyId: values.company?.id as string,
                data: {
                  fromEntityId: values.fromAccountId,
                  toEntityId: values.toAccountId,
                  description: values.description,
                  premiumMonth: values.premiumMonth as number,
                  premiumYear: values.premiumYear as number,
                } as FundsTransferRequest,
              }

              if (values.fundingType === FUNDING_TYPES.PREMIUMS_PAYMENT_TO_DISBURSMENT) {
                mutationPayload.data.fundingEventPremiumAmounts = selectedRows.map(row => ({
                  amountCents: row.premiumAmountCents,
                  recurringPremiumId: row.id,
                  premiumMonth: row.premiumMonth,
                  premiumYear: row.premiumYear,
                }))
              } else {
                mutationPayload.data.amountCents = Math.round(values.amount! * 100)
              }
              const mutationTransfer = isFundingOperation ? fundingTransfer : bookTransfer

              await mutationTransfer.mutateAsync(mutationPayload)
              notify(`Transfer completed successfully`, "success")
              setSubmitting(false)
              resetForm()
            } catch (error) {
              const errorMessage = get(error, "developerMessage") ?? get(error, "message") ?? "An error occurred"
              notify(`Transfer failed: ${errorMessage}`, "error")
              setSubmitting(false)
            }
          }}
        >
          {formikProps => (
            <TransferForm {...formikProps} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
          )}
        </Formik>
      </Grid>
    </TcHubGuard>
  )
}
