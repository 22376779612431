import { FAMILY, SELF } from "@/features/BenefitsElection/benefitsElectionConstants"
import { useManageShoppingPersons } from "@/features/BenefitsElection/benefitsElectionService"
import { useBenefitsElectionStore } from "@/features/BenefitsElection/benefitsElectionStore"
import { ManageShoppingPersonPayload, ShoppingPerson } from "@/features/BenefitsElection/benefitsElectionTypes"
import {
  createDeleteShoppingPersonPayload,
  createNewShoppingPersonPayload,
  createUpdatedShoppingPersonPayload,
  hasApplicantDataChanged,
} from "@/features/BenefitsElection/benefitsElectionUtils"
import { useNotifications } from "@/services/notificationService"
import { useMemo, useState } from "react"
import { useResetPlanSelection } from "../../../hooks/useResetPlanSelection"

export const useFamilyState = (advance: () => void, shoppingSessionId: string) => {
  const { notify } = useNotifications("family")
  const [familyMemberEditorVisible, setFamilyMemberEditorVisible] = useState(false)
  const [currentFamilyMember, setCurrentFamilyMember] = useState<ShoppingPerson | undefined>(undefined)
  const { mutateAsync: manageShoppingPersons, isPending } = useManageShoppingPersons(shoppingSessionId)
  // Zustand selectors
  const storedEmployee = useBenefitsElectionStore(state => state.employee)
  const setEmployee = useBenefitsElectionStore(state => state.setEmployee)
  const familyMembers = useBenefitsElectionStore(state => state.familyMembers)
  const setFamilyMembers = useBenefitsElectionStore(state => state.setFamilyMembers)
  const selectedPlan = useBenefitsElectionStore(state => state.selectedPlan)
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const zipCode = storedEmployee.personalInformation?.zipCode

  const medicareEligibleMembers = useMemo(
    () => familyMembers.filter(member => member.personalInformation?.isMedicareEligible),
    [familyMembers]
  )

  const medicaidEligibleMembers = useMemo(
    () => familyMembers.filter(member => !!member.personalInformation?.isEnrolledInMedicaid),
    [familyMembers]
  )

  const differentAddressMembers = useMemo(
    () => familyMembers.filter(member => member.personalInformation?.zipCode?.fipsCode !== zipCode?.fipsCode),
    [familyMembers, zipCode]
  )

  const { unselectPlan, unselectComparePlans } = useResetPlanSelection()

  const showFamilyMemberForm = () => {
    setFamilyMemberEditorVisible(true)
  }

  const handleMemberCreate = () => {
    setCurrentFamilyMember(undefined)
    showFamilyMemberForm()
  }

  const handleMemberEdit = (familyMember: ShoppingPerson) => {
    setCurrentFamilyMember(familyMember)
    showFamilyMemberForm()
  }

  const hideFamilyMemberForm = () => {
    setFamilyMemberEditorVisible(false)
  }

  const resetPlanSelection = async () => {
    unselectComparePlans()
    if (selectedPlan) {
      await unselectPlan()
      setCurrentStep(FAMILY)
    }
  }

  const resetPlanSelectionOnMemberEdit = async (
    editedMember: ShoppingPerson,
    originalMember: ShoppingPerson | undefined,
    isFamilyMember: boolean
  ) => {
    if (
      originalMember &&
      hasApplicantDataChanged(editedMember.personalInformation!, originalMember.personalInformation!, isFamilyMember) &&
      currentStep !== FAMILY
    ) {
      unselectComparePlans()
      if (selectedPlan) {
        await unselectPlan()
      }
      setCurrentStep(FAMILY)
    }
  }

  const createFamilyMember = async (newMember: ShoppingPerson) => {
    try {
      const postMemberPayload = createNewShoppingPersonPayload(newMember)
      const createResult = await manageShoppingPersons([postMemberPayload])
      const newMemberId = createResult.shoppingPersonOperations[0] as ManageShoppingPersonPayload

      newMember.shoppingPersonId = newMemberId.id!

      setFamilyMembers([...familyMembers, newMember])
      resetPlanSelection()
    } catch (error) {
      notify(`Error creating new member. Please try again later.`, "error")
      console.error(error)
    }
  }

  const editFamilyMember = async (editedMember: ShoppingPerson) => {
    const isEmployee = editedMember.personalInformation?.relationship === SELF
    const patchEmployeePayload = createUpdatedShoppingPersonPayload(editedMember)

    await manageShoppingPersons([patchEmployeePayload])

    if (isEmployee) {
      resetPlanSelectionOnMemberEdit(editedMember, storedEmployee, false)
      setEmployee(editedMember)
    } else {
      const updatedFamilyMembers = familyMembers.map(member => {
        if (member.shoppingPersonId === editedMember.shoppingPersonId) {
          resetPlanSelectionOnMemberEdit(editedMember, member, true)

          return editedMember
        } else {
          return member
        }
      })

      setFamilyMembers(updatedFamilyMembers)
    }
    setCurrentFamilyMember(undefined)
  }

  const deleteFamilyMember = async (deleteId: ShoppingPerson["shoppingPersonId"]) => {
    const deleteMemberPayload = createDeleteShoppingPersonPayload(deleteId!)

    await manageShoppingPersons([deleteMemberPayload])

    const filteredMembers = familyMembers.filter(member => member.shoppingPersonId !== deleteId)

    setFamilyMembers(filteredMembers)
    resetPlanSelection()
    notify(`You have successfully deleted your family member`, "success")
  }

  return {
    currentFamilyMember,
    familyMemberEditorVisible,
    familyMembers,
    medicaidEligibleMembers,
    medicareEligibleMembers,
    differentAddressMembers,
    storedEmployee,
    zipCode,
    isPending,
    createFamilyMember,
    deleteFamilyMember,
    editFamilyMember,
    handleMemberCreate,
    handleMemberEdit,
    hideFamilyMemberForm,
  }
}
