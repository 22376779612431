import { createNavigationStep, FlowNavigationStepProps } from "@/components/FlowNavigationStep"
import { useOnboardingStatuses, useUpdateOnboardingStatus } from "../employerOnboardingService"
import { EmployerOnboardingStep } from "../employerOnboardingTypes"

const Step = createNavigationStep("employer-onboarding")

type SharedProps = "handleBack"

export type OnboardingStepProps = Omit<FlowNavigationStepProps, SharedProps> & {
  stepName: EmployerOnboardingStep
  statusValue?: string
}

export const OnboardingStep = ({ stepName, statusValue, ...props }: OnboardingStepProps) => {
  const { statuses } = useOnboardingStatuses()
  const stepCompleted = statuses[stepName]?.isComplete
  const stepUpdated = statuses[stepName]?.statusValue !== statusValue
  const { updateStepOnboardingStatus } = useUpdateOnboardingStatus()

  return (
    <Step
      previous="/employer-onboarding"
      next="/employer-onboarding"
      handleContinue={async () => {
        if (!stepCompleted || stepUpdated) {
          updateStepOnboardingStatus(stepName, true, statusValue)
        }
      }}
      required
      advanceOnSuccess
      {...props}
    />
  )
}
