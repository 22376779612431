import { ConfirmationModal } from "@/components/ConfirmationModal"
import { getAdminDocDownloadUrl } from "@/features/Documents/documentsEndpoints"
import { usePollingTaskStatus } from "@/features/Tasks/taskManagementService"
import { TASK_STATUS_COMPLETED, TASK_STATUS_FAILED } from "@/features/TCHub/tcHubConstants"
import { createApplicationCsvDownloadRequest } from "@/features/TCHub/tcHubEndpoints"
import { useNotifications } from "@/services/notificationService"
import { sleep } from "@/utils/useAsyncRetry"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { Alert, Button, Grid, Menu, MenuItem, PopoverProps, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"

const MAX_EXPORT_WAIT_DURATION_IN_SECONDS = 60

export interface ExportEnrollmentToCsvButtonProps {
  ids: string[]
  disabled: boolean
}

const pluralize = (word: string, amount: number) => `${amount} ${word}${amount > 1 ? "s" : ""}`

const TimerUntil = ({
  startedAt,
  finishedAt,
  timeoutMessage,
  waitMessage,
  onTimeout,
}: {
  startedAt: string
  finishedAt: string
  timeoutMessage: string | React.ReactNode
  waitMessage: string | React.ReactNode
  onTimeout?: () => void
}) => {
  const [seconds, setSeconds] = useState(DateTime.fromISO(finishedAt).diff(DateTime.fromISO(startedAt)).as("seconds"))
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(draft => {
        if (draft <= 1) {
          clearInterval(interval)
          onTimeout?.()
          return 0
        }
        return draft - 1
      })
    }, 1000)
    return () => clearInterval(interval)
  })
  const minutes = Math.floor(seconds / 60)
  if (seconds <= 0) {
    return timeoutMessage
  }
  if (seconds > 60) {
    return (
      <>
        {waitMessage} {pluralize("minute", minutes)} left
      </>
    )
  }
  return (
    <>
      {waitMessage} {pluralize("second", seconds)} left
    </>
  )
}

interface ExportRequest {
  minimalFieldsOnly: boolean
  extendedFields: boolean
  escapeQuotes: boolean
}

const WaitingModalContent = ({
  numSubmitted,
  startedExportAt,
  onTimeout,
}: {
  numSubmitted: number
  startedExportAt: string | null
  onTimeout: () => void
}) => (
  <Grid>
    <Typography variant="body1">
      Exporting {pluralize("record", numSubmitted)}.
      {startedExportAt && (
        <TimerUntil
          startedAt={startedExportAt}
          finishedAt={DateTime.fromISO(startedExportAt).plus({ seconds: MAX_EXPORT_WAIT_DURATION_IN_SECONDS }).toISO()!}
          timeoutMessage=""
          waitMessage=" "
          onTimeout={onTimeout}
        />
      )}
      .
    </Typography>
  </Grid>
)

export const ExportEnrollmentToCsvButton = ({ ids, disabled }: ExportEnrollmentToCsvButtonProps) => {
  const [popoverRef, setPopoverRef] = useState<PopoverProps["anchorEl"]>(null)
  const [downloadLink, setDownloadLink] = useState("")
  const [numSubmitted, setNumSubmitted] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [startedExportAt, setStartedExportAt] = useState<string | null>(null)
  const [didExportTimedOut, setDidExportTimedOut] = useState(false)
  const [lastExport, setLastExport] = useState<ExportRequest | null>(null)
  const [taskId, setTaskId] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { notify } = useNotifications("csv-application-download")

  const { task, error, isPolling, startPolling, stopPolling } = usePollingTaskStatus(taskId, 3000)

  useEffect(() => {
    if (taskId) {
      startPolling().catch(console.error)
    }
  }, [taskId, startPolling, stopPolling])

  useEffect(() => {
    if (!isModalOpen && isPolling) {
      stopPolling()
      setTaskId(null)
    }
  }, [isModalOpen, isPolling, stopPolling])

  const startExport =
    ({ minimalFieldsOnly, extendedFields, escapeQuotes }: ExportRequest) =>
    async () => {
      const execution = () =>
        createApplicationCsvDownloadRequest({
          healthBenefitElectionIds: ids,
          minimalFieldsOnly,
          extendedFields,
          escapeQuotes,
        })
      setPopoverRef(null)
      setTaskId(null)
      setIsExporting(true)
      setStartedExportAt(DateTime.now().toISO())
      setDidExportTimedOut(false)
      setLastExport({ minimalFieldsOnly, extendedFields, escapeQuotes })
      try {
        setIsModalOpen(true)
        setNumSubmitted(ids.length)
        let response = null
        const maxRetries = 3
        let retryCount = 0
        let responsError = null
        while (retryCount < maxRetries) {
          try {
            response = await execution()
            break
          } catch (err: any) {
            responsError = err
            await sleep(3000 + Math.random() * 2000)
          }
          retryCount += 1
        }
        if (!response || !response.taskId || retryCount >= maxRetries) {
          throw responsError || new Error("Failed to start export. Please try again later.")
        }
        setTaskId(response.taskId)
      } catch (err: any) {
        setErrorMessage("Failed to start export. Please try again later.")
      } finally {
        setIsExporting(false)
      }
    }

  const handleCloseDownloadModal = () => {
    setIsModalOpen(false)
    setDownloadLink("")
    setNumSubmitted(0)
    setTaskId(null)
    setErrorMessage(null)
  }

  useEffect(() => {
    const getDownloadLink = async (resourceId: string) => {
      try {
        const response = await getAdminDocDownloadUrl(resourceId)
        setDownloadLink(response.url)
      } catch (err: any) {
        setErrorMessage("Failed to get download link. Please try again later.")
      }
    }

    const handleError = (message: string) => {
      setIsModalOpen(false)
      notify(message, "error")
      setTaskId(null)
    }

    if (errorMessage) {
      handleError(errorMessage)
      setErrorMessage(null)
    } else if (task?.status === TASK_STATUS_COMPLETED) {
      getDownloadLink(task.resourceId)
    } else if (task?.status === TASK_STATUS_FAILED || error) {
      handleError(error || "Failed to start export. Please try again later.")
    }
  }, [task, error, notify, errorMessage])
  const isProcessing = isExporting || isPolling
  const isCompleted = !isProcessing && task?.status === TASK_STATUS_COMPLETED
  return (
    <>
      <Button
        variant="outlined"
        endIcon={<ArrowDropDownIcon />}
        onClick={evt => {
          setPopoverRef(evt.currentTarget)
        }}
        disabled={disabled || isPolling}
      >
        Export CSV
      </Button>
      <Menu anchorEl={popoverRef} open={Boolean(popoverRef)} onClose={() => setPopoverRef(null)}>
        <MenuItem
          className="tch-menu-item"
          onClick={startExport({
            minimalFieldsOnly: false,
            extendedFields: true,
            escapeQuotes: true,
          })}
        >
          Download csv for excel with questions
        </MenuItem>
        <MenuItem
          className="tch-menu-item"
          onClick={startExport({
            minimalFieldsOnly: false,
            extendedFields: false,
            escapeQuotes: true,
          })}
        >
          Download csv for excel without questions
        </MenuItem>
        <MenuItem
          className="tch-menu-item"
          onClick={startExport({
            minimalFieldsOnly: false,
            extendedFields: true,
            escapeQuotes: false,
          })}
        >
          Download csv for automation with questions
        </MenuItem>
        <MenuItem
          className="tch-menu-item"
          onClick={startExport({
            minimalFieldsOnly: true,
            extendedFields: false,
            escapeQuotes: false,
          })}
        >
          Download csv for automation without questions
        </MenuItem>
      </Menu>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="We're getting your file ready"
        actionLabel={didExportTimedOut && !isCompleted ? "Retry Export" : "Download"}
        isSubmitting={!didExportTimedOut && (isProcessing || !downloadLink)}
        onConfirm={() => {
          if (task?.status === TASK_STATUS_COMPLETED && downloadLink) {
            const link = document.createElement("a")
            link.target = "_blank"
            link.href = downloadLink
            link.download = `application_${Date.now().toLocaleString}.csv`
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            handleCloseDownloadModal()
          } else {
            if (didExportTimedOut && lastExport) {
              startExport(lastExport)()
            }
          }
        }}
      >
        {Boolean(didExportTimedOut && task?.status !== TASK_STATUS_COMPLETED) && (
          <Alert severity="error">
            <Typography variant="body1">
              Export of {pluralize("record", numSubmitted)} failed. Click Retry Export to try again.
            </Typography>
          </Alert>
        )}
        {Boolean(!isProcessing && task?.status === TASK_STATUS_FAILED) && (
          <Alert severity="error">
            <Typography variant="body1">
              Export of {pluralize("record", numSubmitted)} failed. Please try again.
            </Typography>
          </Alert>
        )}
        {Boolean(!isProcessing && task?.status === TASK_STATUS_COMPLETED) && (
          <Typography variant="body1">{pluralize("record", numSubmitted)} exported.</Typography>
        )}
        {Boolean(!didExportTimedOut && isProcessing && task?.status !== TASK_STATUS_COMPLETED) && (
          <WaitingModalContent
            numSubmitted={numSubmitted}
            startedExportAt={startedExportAt}
            onTimeout={() => {
              stopPolling()
              setTaskId(null)
              setDidExportTimedOut(true)
            }}
          />
        )}
      </ConfirmationModal>
    </>
  )
}
