import { ErrorPage } from "@/components/ErrorBoundary/ErrorPage"
import { LoadingPage } from "@/components/LoadingPage/LoadingPage"
import { TOKEN_REFRESH_THRESHOLD_MS } from "@/constants"
import { INITIALIZE, SIGN_OUT } from "@/features/Auth/authConstants"
import { AuthState, CognitoActions, CognitoContextType } from "@/features/Auth/authTypes"
import {
  changeUserPassword,
  completeNewPasswordChallenge,
  completePasswordReset,
  getActiveCompany,
  getSession,
  isUserSignedUp,
  refreshUserData,
  sendPasswordResetEmail,
  signInActionCreator,
  signInWithMfaActionCreator,
  signOutActionCreator,
  signUp,
} from "@/features/Auth/services/authService"
import { getUserDisplayName } from "@/utils/util"
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useReducer, useState } from "react"

interface UserDisplayInfo {
  displayName?: string
  displayInitial?: string
}

const initialState: AuthState & UserDisplayInfo = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: "",
}

export const reducer = (state: AuthState & UserDisplayInfo, action: CognitoActions) => {
  switch (action.type) {
    case INITIALIZE: {
      const user = action.payload.user
      const { userName, initials } = getUserDisplayName(user)
      const keepEndpointData = user?.keepEndpointData

      if (keepEndpointData) {
        const propertiesToCopy = [
          "profileData",
          "shoppingSession",
          "companyHRAPlan",
          "companyInfo",
          "onboardingStatuses",
          "hraClasses",
        ]

        propertiesToCopy.forEach(property => {
          if (state.user?.[property]) {
            user[property] = state.user[property]
          }
        })
      }

      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user,
        displayName: userName,
        displayInitial: initials,
      }
    }
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      }
    default:
      return state
  }
}

const refreshSessionWrapper =
  (
    dispatch: Dispatch<CognitoActions>,
    setError: Dispatch<SetStateAction<Error | undefined>>,
    isRefreshingToken: boolean
  ) =>
  () => {
    refreshUserData(dispatch, isRefreshingToken).catch(setError)
  }

export const AuthContext = createContext<(CognitoContextType & UserDisplayInfo) | null>(null)

interface AuthErrorWrapperProps {
  error: Error | undefined
  loading: boolean
  children?: ReactNode
}

const AuthErrorWrapper = ({ error, loading, children }: AuthErrorWrapperProps) => {
  if (error) return <ErrorPage error={error} />

  if (loading) return <LoadingPage />

  return children
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [error, setError] = useState<Error>()

  const refresh = useCallback(async () => {
    await refreshUserData(dispatch, false)
  }, [dispatch])

  useEffect(() => {
    const interval = setInterval(refreshSessionWrapper(dispatch, setError, true), TOKEN_REFRESH_THRESHOLD_MS)

    refresh().catch(setError)

    return () => clearInterval(interval)
  }, [refresh])

  const signIn = signInActionCreator(dispatch)
  const signOut = signOutActionCreator(dispatch)
  const signInWithMfa = signInWithMfaActionCreator(dispatch)
  const companyId = getActiveCompany(state.user)?.companyId || ""

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "cognito",
        user: {
          displayName: state?.user?.name || "-",
          role: "user",
          ...state.user,
        },
        error: state.error,
        completeNewPasswordChallenge,
        signIn,
        signInWithMfa,
        signUp,
        signOut,
        refresh,
        forgotPassword: sendPasswordResetEmail,
        resetPassword: completePasswordReset,
        changeUserPassword,
        isUserSignedUp,
        getSession,
        companyId,
      }}
    >
      <AuthErrorWrapper error={error} loading={!state.isInitialized}>
        {children}
      </AuthErrorWrapper>
    </AuthContext.Provider>
  )
}
