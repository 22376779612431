import { createDataQa } from "@/utils/dataQa"
import { WrapperProps } from "@/utils/types"
import { Grid, Paper } from "@mui/material"
import { Outlet } from "react-router-dom"

export const AuthLayout = ({ children }: WrapperProps) => (
  <Grid
    container
    sx={{
      margin: "0 auto",
      maxWidth: "32.5rem",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: "fit-content",
      marginY: "auto",
      padding: 8,
    }}
  >
    {children}
    <Outlet />
  </Grid>
)

export type AuthWrapperProps = WrapperProps

export const AuthWrapper = ({
  "data-qa": dataQa = createDataQa("auth-wrapper"),
  sx = {},
  ...props
}: AuthWrapperProps) => <Paper data-qa={dataQa} sx={{ mt: "1.25rem", p: "2rem", ...sx }} {...props} />
