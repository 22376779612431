import { useGetCarrierQuestions } from "@/features/BenefitsElection/healthPlansService"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import SearchIcon from "@mui/icons-material/Search"
import { Autocomplete, Button, CircularProgress, Grid, InputAdornment, TextField, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useGetCarriersIntegration } from "../tcHubService"
import { emptyCarrierQuestion, emptyCarrierToFilter } from "../tcHubTypes"
import { CarrierQuestionsDrawer } from "./CarrierQuestionsDrawer"
import { CarrierQuestionTable } from "./CarrierQuestionsTable"
import { buildCarrierOptions, buildCarriersForFiltering } from "./shared/CarrierService"

export const TcHubCarrierQuestionsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { isLoading: isLoadingCarrierIntegration, data: carriersIntegration } = useGetCarriersIntegration()
  const carriers = buildCarriersForFiltering(undefined, carriersIntegration)
  const carrierOptions = useMemo(() => buildCarrierOptions(carriers), [carriers])
  const [selectedCarrier, setSelectedCarrier] = useState(emptyCarrierToFilter)

  const { data: carrierQuestions, isLoading: isCarrierQuestionsLoading } = useGetCarrierQuestions(
    selectedCarrier.id,
    carriersIntegration
  )

  useEffect(() => {
    if (carriersIntegration?.length! > 0) {
      setSelectedCarrier(carriersIntegration![0])
    }
  }, [carriersIntegration])

  //FUTURE: functionality implemented in different user story
  const handleNewQuestion = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleCarrierChange = (carrierName: string | null) => {
    const carrier = carriers.find(c => c.name === carrierName)

    if (carrier && carrierName) {
      setSelectedCarrier(carrier)
    }
  }

  return (
    <Grid container data-qa="tc-hub-carrier-questions">
      <Helmet title="TC Hub Carrier Questions" />
      <Grid container item>
        <Grid item xs={12}>
          <Typography variant="h1" gutterBottom data-qa="title">
            Carrier Questions
          </Typography>
        </Grid>
        <Grid item xs={12} />
      </Grid>
      <Grid item xs={12} mt={10} mb={5} justifyContent="space-between" alignItems="center">
        <Grid container justifyContent="space-between">
          <Grid item my={4} xs={6}>
            <Autocomplete
              autoComplete
              data-qa="carrier-options"
              options={carrierOptions}
              value={selectedCarrier.name}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Please select a carrier"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: isLoadingCarrierIntegration ? (
                      <CircularProgress color="inherit" size={20} sx={{ mx: 3 }} />
                    ) : null,
                  }}
                />
              )}
              onChange={(event, newValue) => {
                handleCarrierChange(newValue)
              }}
              filterOptions={(options, { inputValue }) =>
                options.filter(option => option.toLowerCase().includes(inputValue.toLowerCase()))
              }
            />
          </Grid>
          <Grid item my={6} xs={6}>
            <Button
              variant="contained"
              onClick={handleNewQuestion}
              sx={{ float: "right" }}
              startIcon={<AddOutlinedIcon />}
            >
              Add new question
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={0}>
        <CarrierQuestionTable
          carrierQuestions={carrierQuestions ?? []}
          carrier={selectedCarrier}
          isLoading={isCarrierQuestionsLoading}
        />
      </Grid>
      <CarrierQuestionsDrawer
        onClose={closeModal}
        visible={isModalOpen}
        carrierQuestion={emptyCarrierQuestion}
        currentCarrierId={selectedCarrier.id}
        action="add"
        title="Add new carrier question"
        submitButtonLabel="Add New"
      />
    </Grid>
  )
}
