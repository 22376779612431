import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import { useNotifications } from "@/services/notificationService"
import { Uuid } from "@/utils/types"
import { validateReverseJournalEntry } from "@/utils/validations"
import { Button, FormControl, Grid, TextField, Typography } from "@mui/material"
import { Formik, FormikProps } from "formik"
import * as Yup from "yup"
import { useReverseJournalEntry } from "../../tcHubService"

type ReverseJournalEntrySchema = Yup.InferType<typeof validateReverseJournalEntry>

interface ReverseJournalEntryFormProps extends FormikProps<ReverseJournalEntrySchema> {
  handleBlur: (e: React.FocusEvent<any>) => void
}

const ReverseJournalEntryForm = ({
  handleBlur,
  errors,
  touched,
  values,
  handleChange,
  setFieldValue,
  setTouched,
  handleSubmit,
  isValid,
  isValidating,
  isSubmitting,
}: ReverseJournalEntryFormProps) => (
  <FormControl>
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Reverse Journal Entry
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={values.journalEntryId}
            id="journalEntryId"
            label="Journal Entry ID"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.journalEntryId && Boolean(errors.journalEntryId)}
            helperText={touched.journalEntryId && errors.journalEntryId}
          />
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button
            disabled={!Object.keys(touched).length || !isValid || isValidating || isSubmitting}
            type="submit"
            variant="contained"
            sx={{ mt: 5 }}
            data-qa="submit-button"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  </FormControl>
)

const REVERSE_JOURNAL_ENTRY_FORM_INITIAL_VALUES: ReverseJournalEntrySchema = {
  journalEntryId: "" as Uuid,
}

// create a simple react component with a single TextField form and submit button
export const TcHubReverseJournalEntryPage = () => {
  const { notify } = useNotifications("journal-entry-created-success")
  const reverseJournalEntry = useReverseJournalEntry()

  return (
    <TcHubGuard requiredPermissions={["tc_hub_journal_entry_management"]}>
      <Formik
        initialValues={REVERSE_JOURNAL_ENTRY_FORM_INITIAL_VALUES}
        validationSchema={validateReverseJournalEntry}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            setSubmitting(true)
            await reverseJournalEntry.mutateAsync(values)
            notify("Journal Entry Reversed", "success")
            resetForm()
          } catch (error) {
            console.error(error)
            notify("Failed to reverse journal entry", "error")
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {formikProps => <ReverseJournalEntryForm {...formikProps} />}
      </Formik>
    </TcHubGuard>
  )
}
