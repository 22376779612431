import { CARRIER_QUESTION_FORM_KEY_DELIMITER, GROUP, INDIVIDUAL } from "@/features/TCHub/tcHubConstants"
import { CarrierEnrollmentQuestion } from "../benefitsElectionTypes"

export const buildEnrollmentQuestionFormKey = (
  question: CarrierEnrollmentQuestion,
  personId?: string,
  isChildQuestion?: boolean
) => {
  if (question.scope === INDIVIDUAL && !personId) {
    throw new Error("Person ID is required when scope is individual")
  }

  const formKeySuffix =
    question.scope === INDIVIDUAL ? `${INDIVIDUAL}${CARRIER_QUESTION_FORM_KEY_DELIMITER}${personId}` : `${GROUP}`

  const questionId = isChildQuestion ? question.parentQuestionId : question.id

  return `${questionId}${CARRIER_QUESTION_FORM_KEY_DELIMITER}${formKeySuffix}`
}
