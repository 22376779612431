import { EXTERNAL_LINKS } from "@/constants"
import {
  Box,
  Grid,
  List,
  ListItemButtonProps,
  ListItemTextProps,
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText,
} from "@mui/material"

const ListItemButton = ({ sx = {}, ...props }: Omit<ListItemButtonProps<"a">, "component">) => (
  <MuiListItemButton
    component="a"
    sx={{
      display: "inline-block",
      width: "auto",
      px: theme => theme.spacing(2),
      "&,&": "hover",
      ...sx,
    }}
    {...props}
  />
)

const ListItemText = ({ sx = {}, ...props }: ListItemTextProps) => (
  <MuiListItemText
    sx={{
      fontSize: "0.812rem",
      color: theme => theme.footer.color,
      ...sx,
    }}
    {...props}
  />
)

export const Footer = () => (
  <Box
    sx={{
      p: theme => `${theme.spacing(0.25)} ${theme.spacing(4)}`,
      background: theme => theme.footer.background,
    }}
  >
    <Grid container spacing={0} data-qa="footer-take-command">
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid",
          borderColor: "colors.borderGray",
        }}
      >
        <List>
          <ListItemButton data-qa="footer-support-link" href={EXTERNAL_LINKS.SUPPORT}>
            <ListItemText primary="Support" />
          </ListItemButton>
          <ListItemButton data-qa="footer-help-center-link" href={EXTERNAL_LINKS.HELP_CENTER}>
            <ListItemText primary="Help Center" />
          </ListItemButton>
          <ListItemButton data-qa="footer-privacy-link" href={EXTERNAL_LINKS.PRIVACY}>
            <ListItemText primary="Privacy" />
          </ListItemButton>
          <ListItemButton data-qa="footer-terms-of-service-link" href={EXTERNAL_LINKS.TERMS_OF_SERVICE}>
            <ListItemText primary="Terms of Service" />
          </ListItemButton>
        </List>
        <List>
          <ListItemText
            primary={`© ${new Date().getFullYear()} Take Command Health. All rights reserved.`}
            data-qa="footer-copyright"
          />
        </List>
      </Box>
    </Grid>
  </Box>
)
