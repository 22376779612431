import { TakeCommandLogoInline } from "@/components/Branding"
import { EXTERNAL_LINKS, MAX_CHAR_COUNT } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { PhoneNumberField } from "@/features/People/PeopleSettings/PhoneNumberField"
import { validateMobilePhoneNumber } from "@/utils/validations"
import { Button, Chip, CircularProgress, Grid, Link, Typography } from "@mui/material"

import { Formik } from "formik"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { mfaSmsCodeSend, useCognitoUser } from "./mfaService"

export const SetupSmsMfa = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { email: stateEmail, password, isLoggedIn } = location.state ?? {}
  const email = stateEmail ?? user?.email ?? ""
  const { data: cognitoUser } = useCognitoUser(email, password, isLoggedIn)

  const validationSchema = Yup.object().shape({
    mobilePhoneNumber: validateMobilePhoneNumber,
  })

  const initialValues = {
    mobilePhoneNumber: "",
  }

  useEffect(() => {
    if (!email) {
      navigate("/")
    }
  }, [email, navigate])

  const handleAuthAppSelection = () => {
    navigate("/mfa/setup", {
      state: {
        ...(location.state ?? {}),
        email,
      },
    })
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ maxWidth: "36.667rem" }}>
      <TakeCommandLogoInline />
      <Chip
        label={email}
        color="default"
        sx={{
          mt: 8,
          border: "1pt solid black",
        }}
      />
      <Typography variant="h1" mt={6}>
        Enter your number
      </Typography>
      <Typography variant="body1" mt={8}>
        A 6-digit code will be sent to the phone number you've provided. To verify it's you, enter the code on the next
        screen.
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus }) => {
          try {
            await mfaSmsCodeSend(cognitoUser!, values.mobilePhoneNumber)
            navigate("/mfa/verify/sms", {
              state: {
                ...(location.state ?? {}),
                email,
                phoneNumber: values.mobilePhoneNumber,
              },
            })
          } catch (error: any) {
            console.error(error)
            const message = error.message || "Something went wrong"

            setStatus({ success: false })
            setErrors({ mobilePhoneNumber: message })
          }
        }}
      >
        {({ errors, handleSubmit, handleChange, handleBlur, isSubmitting, touched, values }) => (
          <form style={{ width: "100%", marginTop: "2rem" }} onSubmit={handleSubmit}>
            <PhoneNumberField
              required
              sx={{ marginBottom: "1rem" }}
              data-qa="phone"
              name="mobilePhoneNumber"
              label="Phone Number"
              value={values.mobilePhoneNumber.replace(/[^+\d]/g, "")}
              fullWidth
              error={Boolean(touched.mobilePhoneNumber && errors.mobilePhoneNumber)}
              helperText={touched.mobilePhoneNumber && errors.mobilePhoneNumber}
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{ maxLength: MAX_CHAR_COUNT }}
            />
            <Typography mt={3} variant="caption">
              By providing your phone number, you agree that Take Command Health will only send you text messages as
              part of the sign in process (1 per login). Message and data rates may apply. See our Terms & Conditions
              and Privacy Policy for mobile details.
            </Typography>
            <Button
              sx={{ mt: 12 }}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting}
              startIcon={isSubmitting && <CircularProgress size={20} />}
            >
              Send Code
            </Button>
          </form>
        )}
      </Formik>
      <Typography mt={3} variant="body1">
        <Button variant="text" onClick={handleAuthAppSelection}>
          Try with the app authentication
        </Button>
      </Typography>
      <Grid sx={{ mt: 6 }}>
        <Typography variant="caption">
          <Link href={EXTERNAL_LINKS.PRIVACY}>Privacy Policy</Link> |{" "}
          <Link href={EXTERNAL_LINKS.TERMS_OF_SERVICE}>Terms of Service</Link>
        </Typography>
      </Grid>
    </Grid>
  )
}
