import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { FIVE, TEN, TWENTY_FIVE } from "@/components/Table/tableConstants"
import DragHandleIcon from "@mui/icons-material/DragHandle"
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"
import {
  Badge,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material"
import { noop } from "lodash"
import moment from "moment"
import { useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { CarrierQuestion, CarrierQuestionsTableHeader, CarrierToFilter } from "../tcHubTypes"
import { CarrierQuestionsActionDropdownMenu } from "./CarrierQuestionsActionDropdownMenu"

const headers: CarrierQuestionsTableHeader[] = [
  { id: "questionTitle", label: "Question Title", alignment: "left" },
  {
    id: "dateModified",
    label: "Date Modified",
    alignment: "center",
  },
  {
    id: "childQuestions",
    label: "Child Questions",
    alignment: "center",
  },
  {
    id: "actions",
    label: "Actions",
    alignment: "center",
  },
]

export interface CarrierQuestionTableProps {
  carrier: CarrierToFilter
  carrierQuestions: CarrierQuestion[]
  isLoading: boolean
}

export const CarrierQuestionTable = ({ carrierQuestions, carrier, isLoading }: CarrierQuestionTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const slicedRows = carrierQuestions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  //FUTURE: full drag and drop functionality implementation in SEG-3158
  const handleDragEnd = noop

  if (isLoading) {
    return (
      <SkeletonTableLoader
        headerTitles={headers.map(cell => `${cell.label}`)}
        bodyRowsCount={8}
        data-qa="skeleton-carrier-question-table"
      />
    )
  }

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer sx={{ p: 4 }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable-carrier-questions">
            {(provided, snapshot) => (
              <>
                <Grid item xs={12} mb={5}>
                  <Typography variant="h5" m={2}>
                    {carrier.name}
                  </Typography>
                </Grid>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headers.map(headCell => (
                        <TableCell key={headCell.id} align={headCell.alignment}>
                          <Typography variant="subtitle1">{headCell.label}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                    {slicedRows.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ height: "18rem" }}>
                          There are no questions available for the selected carrier.
                        </TableCell>
                      </TableRow>
                    )}
                    {slicedRows?.map((question, index) => (
                      <Draggable
                        draggableId={question.id ?? question.question}
                        index={index}
                        key={JSON.stringify(question)}
                      >
                        {(providedDraggable, snapshotDragable) => (
                          <TableRow
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                          >
                            <TableCell>
                              <Stack alignItems="center" direction="row">
                                <DragHandleIcon sx={{ marginRight: "1.125rem" }} />
                                <Typography variant="body1">{question.question}</Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="body1">{moment(question.lastUpdated).format("MM/DD/YY")}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Badge badgeContent={question?.childQuestions?.length ?? 0} color="primary">
                                <QuestionAnswerIcon />
                              </Badge>
                            </TableCell>
                            <TableCell align="center">
                              <CarrierQuestionsActionDropdownMenu carrierId={carrier.id} carrierQuestion={question} />
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                  </TableBody>
                </Table>
                {provided.placeholder}
              </>
            )}
          </Droppable>
        </DragDropContext>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[FIVE, TEN, TWENTY_FIVE]}
        component="div"
        count={carrierQuestions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, changedPage) => handlePageChange(changedPage)}
        onRowsPerPageChange={event => handleRowsPerPageChange(Number(event.target.value))}
        data-qa="carrier-questions-table-pagination"
      />
    </Paper>
  )
}
