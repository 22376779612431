import logoHeaderUrl from "@/assets/svg/tch-logo-header.svg"

const logoStyle = {
  height: "2rem",
  width: "auto",
  color: "secondary",
}

export const TakeCommandLogoInline = ({ href = "/" }: { href?: string }) => (
  <img src={logoHeaderUrl} alt="take command health" style={logoStyle} />
)
