import { Property } from "@/utils/types"

export const HRIS_STATUS_NOT_STARTED = "NOT_STARTED"
export const HRIS_STATUS_PROCESSING = "PROCESSING"
export const HRIS_STATUS_CONNECTED = "CONNECTED"
export const HRIS_STATUS_ERROR = "ERROR"

export const HRIS_STATUS = [
  HRIS_STATUS_NOT_STARTED,
  HRIS_STATUS_PROCESSING,
  HRIS_STATUS_CONNECTED,
  HRIS_STATUS_ERROR,
] as const

export type HrisStatus = Property<typeof HRIS_STATUS>
