import surferImage from "@/assets/svg/surfer-riding-wave.svg"
import { createDataQa } from "@/utils/dataQa"
import { Box, Grid, Typography, useMediaQuery } from "@mui/material"
import { useResetStore } from "../../hooks/useResetStore"
import { useShoppingCompleteStore } from "../../shoppingCompleteStore"

export const WaiveCoverageSuccess = () => {
  const waivedPlanYear = useShoppingCompleteStore(state => state.waivedPlanYear)
  useResetStore(true)
  const isMediumUp = useMediaQuery(theme => theme.breakpoints.up("md"))

  return (
    <Box
      sx={{
        minHeight: "90vh",
        maxWidth: "100vw",
        justifyContent: "center",
        backgroundColor: "colors.white",
        alignItems: "center",
      }}
    >
      <Grid container item xs={12}>
        <Grid item xs={12} md={4}>
          <Box sx={{ backgroundColor: "colors.regionGray", height: isMediumUp ? "90vh" : "30vh" }}>
            <Box
              component="img"
              alt="surfer riding wave"
              data-qa={createDataQa("come-back-later-img")}
              src={surferImage}
              sx={theme => ({
                [theme.breakpoints.up("md")]: {
                  position: "fixed",
                  left: "10%",
                },
                maxWidth: {
                  xs: "18rem",
                  md: "29rem",
                },
              })}
            />
          </Box>
        </Grid>
        <Grid
          item
          container
          direction="row"
          xs={12}
          md={8}
          sx={{
            padding: "auto",
            pt: "6rem",
            margin: "auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            md={7}
            sx={{ maxWidth: isMediumUp ? "50%" : "100%", p: "1.5rem" }}
            data-qa="waive-coverage-success-title"
          >
            <Typography variant="h1">Success! Your coverage for {waivedPlanYear} has been waived.</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{ maxWidth: isMediumUp ? "50%" : "100%", px: "1.5rem" }}
            data-qa="waive-coverage-success-subheading"
          >
            <Typography variant="h4"> You're all done and can close this window now.</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
