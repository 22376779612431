import { useAuth } from "@/features/Auth/useAuth"
import { createDateFromText, createDateFromTextOrElse, formatDate, transformDate } from "@/utils/formatting"
import { TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { DateValidationError } from "@mui/x-date-pickers/internals/hooks/validation/useDateValidation"
import { addDays, format, subDays } from "date-fns"
import { isDate } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { OnboardingStep } from "../components/OnboardingStep"
import { INVITE_DATE_SET } from "../employerOnboardingConstants"
import { useOnboardingStatuses } from "../employerOnboardingService"

export const Invitations = () => {
  const { user } = useAuth()
  const hraStartDateRaw = user?.companyHRAPlan?.[0]?.hraStartDate
  const hraStartDate = isDate(hraStartDateRaw) ? hraStartDateRaw : createDateFromTextOrElse(hraStartDateRaw, new Date())
  const startDate = addDays(hraStartDate, 1)
  const minDate = subDays(startDate, 61)
  const displayMonth = startDate ? format(startDate, "MMMM") : "the month"
  const displayDate = startDate ? formatDate(startDate) : ""
  const { statuses } = useOnboardingStatuses()

  const inviteDateStatus = statuses.INVITE_DATE_SET

  const [date, setDate] = useState<Date | null>(null)
  const [dateError, setDateError] = useState<DateValidationError>(null)

  useEffect(() => {
    if (inviteDateStatus?.statusValue && createDateFromText(inviteDateStatus.statusValue) > new Date()) {
      setDate(createDateFromText(inviteDateStatus.statusValue))
    }
  }, [inviteDateStatus])

  const errorMessage = useMemo(() => {
    switch (dateError) {
      case "invalidDate":
        return "Invitation date must be in MM/DD/YYYY format."

      case "minDate":
        return `Invitations need to be sent at most 60 days prior to the HRA start date. Your HRA start date is ${displayDate}`

      case "disablePast":
        return "Invitation date cannot be in the past"

      default:
        return ""
    }
  }, [dateError, displayDate])

  return (
    <OnboardingStep
      title="Establish date for email invitations"
      description={`Select the date when you'd like Take Command to send invitation emails to your employees. We recommend 30 days before the start of your HRA so employees have ample time to choose a plan. As a reminder, your HRA starts the 1st of ${displayMonth}.`}
      tooltip="You can only select a date up to 2 weeks before your HRA start date. If there is less than 2 weeks, invitations will be sent as soon as employees are added to your roster"
      stepName={INVITE_DATE_SET}
      statusValue={date ? transformDate(date) : undefined}
      disabled={!!dateError || !date}
    >
      {/*
        Note that this is not using Formik, Yup, or our shared component due to difficulties getting the validations to work
        properly. This is largely attributable to using an out-of-date version of MUI X-Date-Pickers which lacks certain features
        which make integrating with Formik and Yup more feasible

        FUTURE: Update MUI X-Date-Pickers
      */}
      <DatePicker
        value={date}
        // FUTURE: This prop is replaced with defaultValue in later versions of MUI X-Date-Pickers
        defaultCalendarMonth={minDate}
        label="Choose a date"
        disablePast
        minDate={minDate}
        onError={err => setDateError(err)}
        // FUTURE: Remove explicit any
        // Updating MUI X-Date-Pickers will also make this MUCH easier
        renderInput={props => (
          <TextField helperText={errorMessage} sx={{ width: { xs: "100%", sm: "31rem" } }} required {...props} />
        )}
        onChange={newDate => setDate(newDate ?? date)}
      />
    </OnboardingStep>
  )
}
