import { useAuth } from "@/features/Auth/useAuth"
import { useNotifications } from "@/services/notificationService"
import { changePasswordValidation } from "@/utils/validations"
import styled from "@emotion/styled"
import CloseIcon from "@mui/icons-material/Close"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Alert as MuiAlert,
  TextField as MuiTextField,
} from "@mui/material"
import { spacing } from "@mui/system"
import { Formik } from "formik"

const Alert = styled(MuiAlert)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

interface ChangePasswordModalProps {
  onClose: () => void
  editPassword: () => void
}

export const ChangePasswordModal = ({ onClose }: ChangePasswordModalProps) => {
  const { notify } = useNotifications("change-password-success")
  const { changeUserPassword } = useAuth()

  return (
    <Dialog
      open
      onClose={(evt, reason) => {
        if (reason === "backdropClick") {
          return
        }
        onClose()
      }}
      scroll="body"
      data-qa="change-password-modal"
      aria-labelledby="change-password-title"
    >
      <DialogTitle id="alert-dialog-title" variant="h2" fontFamily="Tiempos Headline">
        <Grid container sx={{ justifyContent: "space-between", py: 3 }} className="dialog-headline-container">
          Change password
          <CloseIcon className="dialog-close-icon" onClick={onClose} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            retypePassword: "",
            submit: false,
          }}
          validationSchema={changePasswordValidation()}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await changeUserPassword(values.oldPassword, values.newPassword)
              notify(`Password changed successfully.`, "success")
              onClose()
            } catch (error: any) {
              const message = error.message || error || "Something went wrong"

              setStatus({ success: false })
              setErrors({ submit: message })
              setSubmitting(false)
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit} data-qa="change-password-form">
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning" data-qa="error-banner-change-password">
                  {errors.submit}
                </Alert>
              )}
              <TextField
                name="oldPassword"
                label="Current Password"
                value={values.oldPassword}
                error={Boolean(touched.oldPassword && errors.oldPassword)}
                fullWidth
                helperText={touched.oldPassword && errors.oldPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
                data-qa="old-password-input"
              />
              <TextField
                name="newPassword"
                label="New password"
                value={values.newPassword}
                error={Boolean(touched.newPassword && errors.newPassword)}
                fullWidth
                helperText={touched.newPassword && errors.newPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
                data-qa="new-password-input"
              />
              <TextField
                name="retypePassword"
                label="Retype your new password"
                value={values.retypePassword}
                error={Boolean(touched.retypePassword && errors.retypePassword)}
                fullWidth
                helperText={touched.retypePassword && errors.retypePassword}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
                data-qa="retype-password-input"
              />
              <DialogActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  data-qa="change-password-button"
                >
                  Confirm
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
