import { StyledCard } from "@/components/StyledCard"
import { useAuth } from "@/features/Auth/useAuth"
import { useGetCompany } from "@/features/CreateCompany/components/Steps/Setup/setupService"
import { ClassAssignmentModal } from "@/features/Dashboard/Modals/ClassAssignmentModal"
import { useGetHealthBenefit } from "@/features/People/BenefitsService"
import { useGetPersonById } from "@/features/People/peopleService"
import { createDataQa, WithDataQa } from "@/utils/dataQa"
import { getUserDisplayName } from "@/utils/util"
import { CheckCircle, KeyboardArrowRightOutlined, RadioButtonUncheckedOutlined } from "@mui/icons-material"
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { isNil } from "lodash"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AUTOPAY, EMPLOYEES, HRA_DOCUMENTS, INVITATIONS, SUBSCRIPTION_BILLING } from "../employerOnboardingConstants"
import { useOnboardingStatuses } from "../employerOnboardingService"
import { EmployerOnboardingPath } from "../employerOnboardingTypes"

interface FinalCompleted {
  final?: boolean
  completed?: boolean
}

interface SectionButtonProps extends WithDataQa, FinalCompleted {
  path: EmployerOnboardingPath
  disabled?: boolean
}

type SectionButtonLabelProps = FinalCompleted
const SectionButtonLabel = ({ final, completed }: SectionButtonLabelProps) => {
  if (final) return <>Continue</>

  if (completed) return <>Review</>

  return <>Go</>
}

const SectionButton = ({ path, disabled, final, completed }: SectionButtonProps) => (
  <Button
    endIcon={!completed && <KeyboardArrowRightOutlined />}
    variant={completed ? "outlined" : "contained"}
    color={completed ? "inherit" : "primary"}
    sx={{ minWidth: "5.5rem" }}
    component={Link}
    to={path}
    disabled={disabled}
  >
    <SectionButtonLabel final={final} completed={completed} />
  </Button>
)

const baseDataQa = createDataQa("employer-onboarding-checklist")

interface ChecklistIconProps extends WithDataQa {
  loading?: boolean
  completed?: boolean
  final?: boolean
}

const ChecklistIcon = ({ loading, completed, final, "data-qa": dataQa }: ChecklistIconProps) => {
  if (final) return null

  if (loading) return <CircularProgress variant="indeterminate" size="1.5rem" />

  if (completed)
    return (
      <CheckCircle
        color="primary"
        data-qa={createDataQa(dataQa, "complete")}
        sx={{ width: "1.5rem", height: "1.5rem" }}
      />
    )

  return (
    <RadioButtonUncheckedOutlined
      data-qa={createDataQa(dataQa, "incomplete")}
      sx={{ width: "1.5rem", height: "1.5rem" }}
    />
  )
}

type StatusProps =
  | {
      final: boolean
      completed?: never
    }
  | {
      completed?: boolean
      final?: never
    }

type ChecklistSectionProps = StatusProps & {
  title: string
  description: string
  path: EmployerOnboardingPath
  disabled?: boolean
  loading?: boolean
}

const ChecklistSection = ({ title, description, path, disabled, loading, completed, final }: ChecklistSectionProps) => {
  const sectionDataQa = createDataQa(baseDataQa, "section", path)
  const materialTheme = useTheme()
  const isMobile = useMediaQuery(materialTheme.breakpoints.down("sm"))

  return (
    // FUTURE: Remove this use of deprecated property
    // More info: https://mui.com/material-ui/api/list-item/#list-item-prop-disabled
    <>
      <ListItem disabled={disabled || loading} data-qa={sectionDataQa}>
        <ListItemIcon>
          <ChecklistIcon
            final={final}
            completed={completed}
            loading={loading}
            data-qa={createDataQa(sectionDataQa, "icon")}
          />
        </ListItemIcon>
        <ListItemText
          primary={<Typography>{title}</Typography>}
          secondary={
            <Typography variant="body2" color="colors.lightGrayText" mt={2}>
              {description}
            </Typography>
          }
          sx={{ mr: "1rem" }}
        />
        {!isMobile && (
          <SectionButton
            path={path}
            completed={final ? undefined : completed}
            final={final}
            data-qa={createDataQa(sectionDataQa, "button")}
            disabled={disabled || loading}
          />
        )}
      </ListItem>
      {isMobile && (
        <Grid item sx={{ ml: "4.5rem", mb: 4 }}>
          <SectionButton
            path={path}
            completed={final ? undefined : completed}
            final={final}
            data-qa={createDataQa(sectionDataQa, "button")}
            disabled={disabled || loading}
          />
        </Grid>
      )}
    </>
  )
}

interface ChecklistProgressProps {
  completedSteps: number
  totalSteps: number
}

const ChecklistProgress = ({ completedSteps, totalSteps }: ChecklistProgressProps) => (
  <Grid container display="flex" justifyContent="space-before">
    <Grid item ml="auto">
      <Typography variant="caption">
        {completedSteps} of {totalSteps}
      </Typography>
    </Grid>

    <LinearProgress variant="determinate" value={(completedSteps / totalSteps) * 100} sx={{ width: "100%", mt: 2 }} />
  </Grid>
)

export const Checklist = () => {
  const { user } = useAuth()
  const { userName } = getUserDisplayName(user)
  // SAFETY: If we are here, we have a company id
  const currentCompanyId = user?.company?.companyId!
  const { data: currentCompany, isLoading: isCompanyLoading } = useGetCompany(currentCompanyId)
  const hraPlan = user?.companyHRAPlan?.[0]
  const currentPlanId = hraPlan?.id!
  const { data: person, isLoading: isPendingPerson } = useGetPersonById(currentCompanyId, user?.id)
  const employee = person?.employments?.[0]
  const { data: healthBenefitData, isLoading: isPendingHealthBenefit } = useGetHealthBenefit(
    currentCompanyId,
    employee?.id || ""
  )

  const showAutoPay = !!currentCompany?.companyInfo.autoPayAvailableOption
  const { statuses, isPending, isBillingReady } = useOnboardingStatuses()
  const hraStartDate = hraPlan?.hraStartDate?.toString()
  const documentsCompleted = statuses.DOCUMENTS_VIEWED?.isComplete ?? false
  const employeesCompleted = statuses.EMPLOYEE_ROSTER?.isComplete ?? false
  const autopayCompleted = statuses.AUTOPAY_COMPLETED?.isComplete ?? false
  const invitationsCompleted = statuses.INVITE_DATE_SET?.isComplete ?? false
  const adminClassAssignmentCompleted = statuses.ADMIN_CLASS_ASSIGNMENT?.isComplete ?? false

  const completedSteps =
    Number(!!documentsCompleted) +
    Number(!!employeesCompleted) +
    Number(!!autopayCompleted) +
    Number(!!invitationsCompleted)

  const [isClassAssignmentModalOpen, setClassAssignmentModalOpen] = useState(false)
  const isDataLoading = isPendingPerson || isPendingHealthBenefit || isCompanyLoading

  useEffect(() => {
    if (!isPendingPerson && !isPendingHealthBenefit) {
      const hasMissingHealthBenefit = isNil(healthBenefitData)
      const hasMissingClassAssignment = hasMissingHealthBenefit || healthBenefitData?.classId === null
      if (hasMissingClassAssignment && !adminClassAssignmentCompleted && Boolean(isBillingReady)) {
        setClassAssignmentModalOpen(true)
      }
    }
  }, [healthBenefitData, isBillingReady, isPendingPerson, isPendingHealthBenefit, adminClassAssignmentCompleted])

  const handleCloseClassAssignmentModal = () => {
    setClassAssignmentModalOpen(false)
  }

  const totalSteps = showAutoPay ? 5 : 4

  return (
    <>
      <Typography variant="h1" gutterBottom data-qa={createDataQa(baseDataQa, "greeting")}>
        Hello, {userName}
      </Typography>
      <StyledCard data-qa={createDataQa(baseDataQa, "card")}>
        <Typography variant="h5" data-qa={createDataQa(baseDataQa, "heading")}>
          Getting started at Take Command
        </Typography>
        <Typography
          variant="body2"
          data-qa={createDataQa(baseDataQa, "description")}
          color="colors.lightGrayText"
          mt={2}
          mb={4}
          // FUTURE: Style gutterBottom based on typography variant
          /* gutterBottom */
        >
          This {totalSteps} step checklist will allow you to complete the necessary steps for your company's HRA
        </Typography>
        <ChecklistProgress completedSteps={completedSteps} totalSteps={totalSteps} />
        <List dense>
          <ChecklistSection
            title="HRA documents"
            description="View and acknowledge documents provided to you by Take Command"
            path={HRA_DOCUMENTS}
            loading={isPending}
            completed={documentsCompleted}
          />
          <Divider />
          <ChecklistSection
            title="Set-up employees"
            description="Upload your employee roster or add individually"
            path={EMPLOYEES}
            loading={isPending}
            completed={employeesCompleted}
          />
          {showAutoPay && (
            <>
              <Divider />
              <ChecklistSection
                title="AutoPay set-up"
                description="Apply for your AutoPay account and connect your bank account"
                path={AUTOPAY}
                loading={isPending}
                completed={autopayCompleted}
                // FUTURE: Uncomment or remove this as needed
                // disabled={!employeesCompleted}
              />
            </>
          )}
          <Divider />
          <ChecklistSection
            title="Establish date for email invitations"
            description="Send out email invitations for newly invited employees"
            path={INVITATIONS}
            loading={isPending}
            completed={invitationsCompleted}
          />
          <Divider />
          <ChecklistSection
            title="Set-up subscription billing"
            description="Add-on products, view estimates, manage payment method and invoices"
            path={SUBSCRIPTION_BILLING}
            loading={isPending}
            disabled={isDataLoading || !isBillingReady}
            final
          />
        </List>
      </StyledCard>
      <ClassAssignmentModal
        onClose={handleCloseClassAssignmentModal}
        isOpen={isClassAssignmentModalOpen}
        companyId={currentCompanyId}
        planId={currentPlanId}
        employee={employee!}
        hraStartDate={hraStartDate!}
      />
    </>
  )
}
