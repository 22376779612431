import { PasswordTextField } from "@/components/TextFields"
import { UserResetPasswordData } from "@/features/Auth/authTypes"
import { useAuth } from "@/features/Auth/useAuth"
import { useNotifications } from "@/services/notificationService"
import { passwordAndConfirmPasswordValidation } from "@/utils/validations"
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"
import { Button, IconButton, InputAdornment, TextField } from "@mui/material"
import { Formik } from "formik"
import { MouseEvent as ReactMouseEvent, useState } from "react"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { decodeIncomingParams } from "../authUtils"

const validateIncomingParams = (
  searchParams: URLSearchParams
): searchParams is URLSearchParams & UserResetPasswordData => {
  const data = decodeIncomingParams<UserResetPasswordData>(searchParams)

  return Boolean(data?.email && data?.verificationCode)
}

export const ResetPasswordPanel = () => {
  const navigate = useNavigate()
  const { resetPassword } = useAuth()
  const { notify } = useNotifications("reset-password")
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
  const [searchParams] = useSearchParams()

  if (!validateIncomingParams(searchParams)) {
    return <Navigate to="/404" />
  }

  const handleClickShowPasswordConfirmation = () => setShowPasswordConfirmation(show => !show)

  const handleMouseDownPassword = (event: ReactMouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <Formik
      initialValues={{
        newPassword: "",
        confirmPassword: "",
        submit: false,
      }}
      validationSchema={passwordAndConfirmPasswordValidation()}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = decodeIncomingParams<UserResetPasswordData>(searchParams)
          await resetPassword(data?.email!, values.newPassword, data?.verificationCode!)
          notify("Password changed successfully", "success")
          navigate("/sign-in")
        } catch (error: any) {
          const message = error.message || error || "Something went wrong"

          setStatus({ success: false })
          setErrors({ submit: message })
          notify(`${message}`, "error")
          setSubmitting(false)
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit} data-qa="reset-password-form">
          <PasswordTextField
            name="newPassword"
            label="New Password"
            value={values.newPassword}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          />
          <TextField
            type={showPasswordConfirmation ? "text" : "password"}
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordConfirmation}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    data-qa="visibility-icon-confirm-password"
                  >
                    {showPasswordConfirmation ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            data-qa="reset-password-button"
            sx={{ mt: 1 }}
          >
            Update password
          </Button>
        </form>
      )}
    </Formik>
  )
}
