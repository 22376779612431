import { createDataQa } from "@/utils/dataQa"
import { AlertColor } from "@mui/material"
import { useSnackbar } from "notistack"
import { useCallback } from "react"

export const useNotifications = (name: string) => {
  const { enqueueSnackbar } = useSnackbar()

  const notify = useCallback(
    (message: string, status: AlertColor = "info", reason?: string) => {
      enqueueSnackbar(message, {
        variant: "simpleNotification",
        selection: status,
        dataQa: createDataQa(name, reason, status),
      })
    },
    [enqueueSnackbar, name]
  )

  return { notify }
}
