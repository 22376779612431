import { useContext } from "react"
import { ThemeContext } from "./ThemeContext"

export const useTheme = () => {
  const contextValue = useContext(ThemeContext)

  if (!contextValue) {
    throw new Error("useTheme must be used within a ThemeProvider")
  }

  return contextValue
}
