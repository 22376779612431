import { CurrentQuestion, HRAResult } from "@/features/HRAPlanDesign/HRAPlanDesign.data"
import * as Yup from "yup"

interface PreScreenQuestions {
  [key: string]: CurrentQuestion
}
interface PlanResults {
  [key: string]: HRAResult
}

export const radioGroupHraValidation = Yup.object().shape({
  radioGroup: Yup.string().required("Required"),
})

export const StateHraValidation = Yup.object().shape({
  state: Yup.string().required("Required"),
})

export const isQsehra = (result: string) => (result.toUpperCase() === "QSEHRA" ? true : false)
export const isIchraDiy = (result: string) => (result.toUpperCase() === "ICHRA_DIY" ? true : false)
export const isIchraNonDiy = (result: string) => (result.toUpperCase() === "ICHRA_NON_DIY" ? true : false)

export const PLAN_RESULT: PlanResults = {
  QSEHRA: "QSEHRA",
  ICHRA_DIY: "ICHRA_DIY",
  ICHRA_NON_DIY: "ICHRA_NON_DIY",
}

export const PRE_SCREEN_QUESTION: PreScreenQuestions = {
  NUM_FT_EE: "NUM_FT_EE",
  HAVE_GROUP_PLAN: "HAVE_GROUP_PLAN",
  KEEP_GROUP_PLAN: "KEEP_GROUP_PLAN",
  MONTHLY_ALLOWANCE: "MONTHLY_ALLOWANCE",
  NON_INDIVIDUAL_PLAN_REIMBURSEMENT: "NON_INDIVIDUAL_PLAN_REIMBURSEMENT",
  LOCATION: "LOCATION",
  VARIED_ALLOWANCES: "VARIED_ALLOWANCES",
}

export const ICHRA_PLAN_RESULT = {
  NON_DIY: "non-diy",
  DIY: "diy",
}
