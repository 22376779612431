import { AmountTextField } from "@/components/AmountTextField"
import { DatePickerField } from "@/components/DatePickerField"
import { FileUploader, UploadedFile } from "@/components/FileUploader"
import { SelectMultiField } from "@/components/SelectMultiField"
import { StyledCard } from "@/components/StyledCard"
import { EXTERNAL_LINKS, SELF_ENROLL } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { POC } from "@/features/Documents/documentsConstants"
import { useCompanyDocDownloadUrl, useCreateDocument, useFetchFileFromUrl } from "@/features/Documents/documentsService"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { getOnlyDate, yearFirstDay, yearLastDay } from "@/utils/dates"
import { createDateFromText, formatCents, formatDate, formatDollarToCents, transformDate } from "@/utils/formatting"
import { Option, Uuid } from "@/utils/types"
import { getFileBase64, removeBase64Metadata } from "@/utils/util"
import { AddShoppingCart } from "@mui/icons-material"
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Link, Typography } from "@mui/material"
import { Formik } from "formik"
import { isString } from "lodash"
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import * as Yup from "yup"
import {
  EMPLOYEE_PURCHASED,
  FAMILY,
  FINAL_CHECK,
  INDIVIDUAL_HEALTH_INSURANCE,
  MAX_POC_FILES,
  MEDICARE,
  OTHER_PREMIUMS,
  POC_SUBMITTED,
  RECURRING_REIMBURSEMENT,
  SELF_ENROLLED_PLAN,
  SELF_ENROLL_PURCHASE,
} from "../../benefitsElectionConstants"
import {
  useIsCompanyAutoPay,
  useShoppingSession,
  useShoppingUrl,
  useUpdateHealthBenefitsElection,
} from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { HealthBenefitsElection, ProofOfCoverageFile, ShoppingPerson } from "../../benefitsElectionTypes"
import { getDisplayName } from "../../benefitsElectionUtils"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"
import { useEmployeeIsMedicare } from "../../hooks/useValidateMedicareQualification"

const fileNameStart = "poc-file-"

const extractFileName = (url?: string) => {
  const defaultName = "Proof of coverage file"
  const splitUrl = url?.split(fileNameStart)

  if (!url || !splitUrl || splitUrl.length <= 1) {
    return defaultName
  }
  const lastIndex = splitUrl.length - 1
  const queryIndex = splitUrl[lastIndex].indexOf("?")

  if (queryIndex === -1) {
    return defaultName
  }

  const encodedFileName = splitUrl[lastIndex].substring(0, queryIndex)

  return decodeURIComponent(encodedFileName)
}

interface ProofOfCoverageFilesProps {
  loadedFile?: File
  usePreviousFile: boolean
  removePreviousFile?: () => void
  uploadedDocumentUrl?: string
  uploadedFiles: File[]

  setUploadedFiles: Dispatch<SetStateAction<File[]>>
}

const ProofOfCoverageFiles = ({
  loadedFile,
  usePreviousFile,
  uploadedDocumentUrl,
  uploadedFiles,
  setUploadedFiles,
  removePreviousFile,
}: ProofOfCoverageFilesProps) =>
  loadedFile && usePreviousFile ? (
    <UploadedFile
      name={loadedFile.name}
      size={loadedFile.size}
      removeFile={removePreviousFile}
      url={uploadedDocumentUrl}
    />
  ) : (
    <FileUploader
      maxFiles={MAX_POC_FILES}
      fileFormatDescription="JPG, JPEG, PNG or PDF"
      accept={{
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
        "application/pdf": [".pdf"],
      }}
      maxSize={4000000}
      uploadedFiles={uploadedFiles}
      setUploadedFiles={setUploadedFiles}
    />
  )

interface ProofOfCoverageProps {
  companyId: string
  existingPocDocumentId?: string
  setProofOfCoverageFile: Dispatch<SetStateAction<ProofOfCoverageFile>>
  hidePreamble?: boolean
  isMedicare?: boolean
  readOnly?: boolean
}

/** @returns [[planType, opening, linkDisplay, url, closing]] */
const getCopy = (isMedicare: boolean | undefined) =>
  isMedicare
    ? ([
        "Medicare",
        "Since you are enrolling in Medicare",
        "AskChapter.org",
        EXTERNAL_LINKS.MEDICARE_PARTNER,
        "or the official website of your state’s health insurance assistance program",
      ] as const)
    : ([
        "health",
        "As you've chosen a self-enrolled plan",
        "healthcare.gov",
        EXTERNAL_LINKS.PUBLIC_EXCHANGE,
        "your state health insurance website, or the insurance carrier's platform",
      ] as const)

export const ProofOfCoverage = ({
  companyId,
  existingPocDocumentId,
  setProofOfCoverageFile,
  hidePreamble,
  isMedicare,
  readOnly,
}: ProofOfCoverageProps) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
  const [usePreviousFile, setUsePreviousFile] = useState(true)

  const { data: uploadedDocumentUrl, isLoading: isFetchingUrl } = useCompanyDocDownloadUrl(
    companyId,
    existingPocDocumentId,
    false,
    true
  )

  const { data: loadedFile, isLoading: isLoadingDocument } = useFetchFileFromUrl(
    uploadedDocumentUrl,
    extractFileName(uploadedDocumentUrl) ?? "Proof of coverage"
  )

  const isLoading = isFetchingUrl || isLoadingDocument

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      setProofOfCoverageFile({ file: uploadedFiles[0], isPreviousFile: false })
    } else if (uploadedFiles.length === 0) {
      setProofOfCoverageFile({ file: null, isPreviousFile: false })
    }
  }, [uploadedFiles, setProofOfCoverageFile])

  useEffect(() => {
    if (loadedFile) {
      setProofOfCoverageFile({ file: loadedFile, isPreviousFile: true })
    }
  }, [loadedFile, setProofOfCoverageFile])

  const [planType, opening, linkDisplay, url, closing] = getCopy(isMedicare)

  return (
    <>
      {!hidePreamble && (
        <>
          <Typography variant="h5" gutterBottom>
            Upload proof of coverage
          </Typography>
          <Typography>
            {opening}, please visit{" "}
            <Link href={url} target="_blank">
              {linkDisplay}
            </Link>
            , {closing}, to make your selection. Once you've secured your {planType} plan, upload your proof of coverage
            below to qualify for your employer’s HRA. Should you encounter any issues with your plan, please contact the
            carrier directly. We can always help provide guidance on how to do that.{" "}
          </Typography>
          <Box component="ul" sx={{ mt: 6, pl: 6 }}>
            <li>Your first and last name</li>
            <li>The name of your {planType} plan</li>
            <li>Your {new Date().getFullYear()} monthly premium</li>
          </Box>
        </>
      )}
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "10rem" }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <ProofOfCoverageFiles
          loadedFile={loadedFile}
          removePreviousFile={
            readOnly
              ? () => {
                  setUsePreviousFile(false)
                  setProofOfCoverageFile({ file: null, isPreviousFile: false })
                }
              : undefined
          }
          usePreviousFile={usePreviousFile}
          uploadedDocumentUrl={uploadedDocumentUrl}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
        />
      )}
    </>
  )
}

export const MedicareEnrollmentCard = () => (
  <StyledCard>
    <Typography variant="h6" gutterBottom>
      Do you need to shop for Medicare coverage?
    </Typography>
    <Typography variant="body1" pb={4}>
      To shop for medicare coverage, we have a partner that can help you enroll in Medicare or evaluate your current
      coverage.
    </Typography>
    <Button
      startIcon={<AddShoppingCart />}
      variant="outlined"
      color="inherit"
      href={EXTERNAL_LINKS.MEDICARE_PARTNER}
      target="_blank"
    >
      Shop for Medicare
    </Button>
  </StyledCard>
)

export const getRecurringReimbursementSchema = (planYear: number, minDate: Date, isPrimary: boolean) =>
  Yup.object().shape({
    selectedMembers: Yup.array()
      .of(Yup.string<Uuid>().defined())
      .required()
      .min(1, "At least one family member is required"),
    premiumAmount: Yup.string().required("Premium amount is required"),
    startDate: Yup.date()
      .min(
        minDate,
        isPrimary
          ? `Start date must be on or after ${formatDate(minDate)}`
          : `Start date for additional premiums must be on or after the primary plan start date of ${formatDate(minDate)}`
      )
      .max(yearLastDay(planYear), `Start date must be within the year ${planYear}`)
      .typeError("Use MM/DD/YYYY format")
      .required("Start date is required"),
    endDate: Yup.date()
      .nullable()
      .defined()
      .min(Yup.ref("startDate"), "End date must be after start date")
      .max(yearLastDay(planYear), `End date must be within the year ${planYear}`)
      .required("End date is required"),
  })

export const findOptionLabel = (optionId: string, options: Option<string, string>[]) =>
  options.find(option => option.value === optionId)?.label

export interface RecurringReimbursementForm {
  selectedMembers: Uuid[]
  premiumAmount: string
  startDate: Date | null
  endDate: Date | null
}

const getSelectOptions = (employee: ShoppingPerson, familyMembers: ShoppingPerson[]) => {
  const notMedicaidMembers = familyMembers.filter(member => member.personalInformation?.isEnrolledInMedicaid === false)

  // SAFETY: If the user is in this step, we can ensure members will have a shoppingPersonId
  const employeeOption = {
    label: employee.personalInformation ? getDisplayName(employee.personalInformation) : "You",
    value: employee.shoppingPersonId!,
    disabled: true,
  }

  const familyOptions = notMedicaidMembers.map(({ shoppingPersonId, personalInformation }) => ({
    label: personalInformation ? getDisplayName(personalInformation) : "",
    value: shoppingPersonId!,
  }))

  return [employeeOption, ...familyOptions]
}

export const useRenderSelectValue = (f: (id: string, options: Option[]) => string | undefined, options: Option[]) =>
  useCallback((selected: string[]) => selected.map(id => f(id, options)).join(", "), [f, options])

export const RecurringReimbursement = () => {
  const [isAcknowledged, setIsAcknowledged] = useState(false)
  const { user } = useAuth()
  const { notify } = useNotifications("proof-of-coverage")
  const companyId = user?.company?.companyId as Uuid
  const employeeId = user?.company?.employeeId as Uuid
  const shoppingUrl = useShoppingUrl()
  const shoppingSessionId = useShoppingSession()
  const { isAutoPay, isLoading: isLoadingFundingStatus } = useIsCompanyAutoPay(companyId)
  const currentShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const planYear = currentShoppingSession.planYear
  const firstDayOfPlanYear = yearFirstDay(planYear)
  const lastDayOfPlanYear = yearLastDay(planYear)
  const setHealthBenefitElection = useBenefitsElectionStore(state => state.setHealthBenefitElection)
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const employee = useBenefitsElectionStore(state => state.employee)
  const familyMembers = useBenefitsElectionStore(state => state.familyMembers)
  const getEligibleApplicants = useBenefitsElectionStore(state => state.getEligibleApplicants)
  const selectedPlan = useBenefitsElectionStore(state => state.selectedPlan)
  const showFindPlan = useBenefitsElectionStore(state => state.showFindPlan)
  const isMedicare = useEmployeeIsMedicare()
  const currentBenefitElection = currentShoppingSession.healthBenefitElections[0]
  const selectOptions = getSelectOptions(employee, familyMembers)
  const renderSelectValue = useRenderSelectValue(findOptionLabel, selectOptions)
  const { mutateAsync: createDocument } = useCreateDocument(companyId)
  const { mutateAsync: updateHealthBenefitsElection } = useUpdateHealthBenefitsElection(shoppingSessionId)
  const eligibiltyDateString = currentShoppingSession.enrollmentTimePeriod.eligibiltyDate
  const eligibiltyDate = isString(eligibiltyDateString) ? createDateFromText(eligibiltyDateString) : null

  const nextStep = isMedicare ? OTHER_PREMIUMS : FINAL_CHECK
  const next = shoppingUrl + nextStep
  const regularPreviousStep = showFindPlan ? SELF_ENROLL_PURCHASE : FAMILY

  const isSelfEnrolledPlan = !selectedPlan && !isMedicare
  const previousStep = isSelfEnrolledPlan ? SELF_ENROLLED_PLAN : regularPreviousStep
  const previous = shoppingUrl + previousStep

  const [proofOfCoverageFile, setProofOfCoverageFile] = useState<ProofOfCoverageFile>({
    file: null,
    isPreviousFile: false,
  })

  const getFormInitialValues = () => {
    const { shoppingPersons, premiumAmountCents, planEffectiveDate } = currentBenefitElection
    const premiumAmount = formatCents(premiumAmountCents ?? selectedPlan?.premiumAmountCents).replace("$", "")
    let initialMembers = []

    if (shoppingPersons) {
      const storedEmployeeId = employee.shoppingPersonId!

      const selectedMembersIds = shoppingPersons
        .map(benefitsShoppingPerson => benefitsShoppingPerson.id)
        .filter(id => id !== storedEmployeeId)

      initialMembers = [storedEmployeeId, ...selectedMembersIds]
    } else {
      initialMembers = getEligibleApplicants().map(applicant => applicant.shoppingPersonId)
    }
    const formInitialValues: RecurringReimbursementForm = {
      selectedMembers: initialMembers,
      premiumAmount,
      startDate: planEffectiveDate ? createDateFromText(planEffectiveDate) : eligibiltyDate,
      endDate: lastDayOfPlanYear,
    }

    return formInitialValues
  }

  return (
    <Formik
      initialValues={getFormInitialValues()}
      onSubmit={async values => {}}
      validationSchema={getRecurringReimbursementSchema(planYear, eligibiltyDate ?? firstDayOfPlanYear, true)}
      validateOnMount
    >
      {({ errors, touched, values, isValid, setFieldTouched, handleChange, handleBlur }) => (
        <BenefitsElectionStep
          title="Set your recurring premium"
          data-qa={createDataQa("proof-of-coverage")}
          description={
            <Typography>
              Enter your monthly health insurance premium amount and the start and end date of your plan.
            </Typography>
          }
          required
          next={next}
          previous={previous}
          advanceOnSuccess
          disabled={
            !isAcknowledged ||
            ((isMedicare || !isAutoPay) && !proofOfCoverageFile?.file) ||
            !isValid ||
            isLoadingFundingStatus
          }
          errorMessage="Error saving recurring reimbursements information. Please try again later."
          handleContinue={async () => {
            const updatedBenefitsElection: HealthBenefitsElection = {
              id: currentBenefitElection.id,
              employmentId: currentBenefitElection.employmentId,
              planEffectiveDate: transformDate(values.startDate!),
              planEndDate: isMedicare ? undefined : transformDate(values.endDate!),
              shoppingPersons: values.selectedMembers.map(id => ({ id })),

              // To provide premiumAmount, plan id and carrier id must be sent
              healthPlanId: selectedPlan?.id,
              carrierId: selectedPlan?.carrier.id,
              premiumAmountCents: formatDollarToCents(values.premiumAmount),
              enrollmentType: SELF_ENROLL,
              enrollmentStatus: isMedicare ? EMPLOYEE_PURCHASED : POC_SUBMITTED,
            }

            if (isSelfEnrolledPlan) {
              const currentInsuranceType = currentBenefitElection.insuranceType
              updatedBenefitsElection.insuranceType = currentInsuranceType
              if (
                currentInsuranceType === INDIVIDUAL_HEALTH_INSURANCE &&
                currentBenefitElection.carrierId &&
                currentBenefitElection.carrierName
              ) {
                updatedBenefitsElection.carrierId = currentBenefitElection.carrierId
                updatedBenefitsElection.carrierName = currentBenefitElection.carrierName
              } else {
                // FUTURE: uncomment this code when the schema is updated to reset the carrierId
                //updatedBenefitsElection.carrierId = ""
                updatedBenefitsElection.carrierName = " "
              }
            } else {
              updatedBenefitsElection.insuranceType = isMedicare ? MEDICARE : INDIVIDUAL_HEALTH_INSURANCE
            }

            if (proofOfCoverageFile?.file && !proofOfCoverageFile.isPreviousFile) {
              const file = proofOfCoverageFile.file
              const base64 = await getFileBase64(file)
              const document = removeBase64Metadata(base64)
              const documentName = `${fileNameStart}${file.name}`

              const { id: pocDocumentId } = await createDocument({
                document,
                documentName,
                documentType: POC,
                employeeId,
              })

              updatedBenefitsElection.pocDocumentId = pocDocumentId
            }

            const updatedBenefitElection = await updateHealthBenefitsElection(updatedBenefitsElection)

            setHealthBenefitElection(updatedBenefitElection)
            notify("Succesfully saved your recurring reimbursement data", "success")

            if (
              currentStep === RECURRING_REIMBURSEMENT ||
              currentStep === FINAL_CHECK ||
              currentStep === OTHER_PREMIUMS
            ) {
              setCurrentStep(nextStep)
            }
          }}
        >
          <Grid container spacing={4} my={8}>
            <Grid item xs={12}>
              <SelectMultiField
                selectedValues={values.selectedMembers}
                fieldLabel="Who is covered in this plan?"
                data={selectOptions}
                name="selectedMembers"
                onChange={handleChange}
                onBlur={() => setFieldTouched("selectedMembers")}
                error={Boolean(touched.selectedMembers && errors.selectedMembers)}
                helperText={
                  touched.selectedMembers && errors.selectedMembers ? (errors.selectedMembers as string) : undefined
                }
                renderValue={renderSelectValue}
                dataQa="members-select-field"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AmountTextField
                touched={!!touched.premiumAmount}
                errorString={errors.premiumAmount!}
                error={Boolean(touched.premiumAmount && errors.premiumAmount)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="premiumAmount"
                label="Premium Amount"
                value={values.premiumAmount}
                sx={{ mt: 0 }}
                maxAmount={10000}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePickerField
                data-qa="start-date-field"
                name="startDate"
                label="Start Date"
                required
                fullWidth
                variant="outlined"
                type="date"
                value={getOnlyDate(values.startDate ?? eligibiltyDate ?? null)}
                minDate={eligibiltyDate ?? firstDayOfPlanYear}
                maxDate={lastDayOfPlanYear}
                error={Boolean(touched.startDate && errors.startDate)}
                helperText={touched.startDate && errors.startDate}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePickerField
                data-qa="end-date-field"
                name="endDate"
                label="End Date"
                required
                fullWidth
                variant="outlined"
                type="date"
                value={getOnlyDate(values.endDate ?? null)}
                error={Boolean(touched.endDate && errors.endDate)}
                helperText={touched.endDate && errors.endDate}
                disabled
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          {!isLoadingFundingStatus && (!isAutoPay || isMedicare || isSelfEnrolledPlan) && (
            <ProofOfCoverage
              companyId={companyId}
              existingPocDocumentId={currentBenefitElection.pocDocumentId}
              setProofOfCoverageFile={setProofOfCoverageFile}
              isMedicare={isMedicare}
            />
          )}
          {isMedicare && <MedicareEnrollmentCard />}
          <FormControlLabel
            label={
              <>
                I understand I cannot receive tax credits with an ICHRA, and I attest that I’m not receiving tax
                credits.
                <br />
                You can go to the market place and remove the tax credits.
              </>
            }
            control={
              <Checkbox
                checked={isAcknowledged}
                data-qa={createDataQa("tax-credits-ineligible-acknowledgement-checkbox")}
              />
            }
            onChange={(_, checked) => setIsAcknowledged(checked)}
            sx={{ mt: 8 }}
            data-qa={createDataQa("tax-credits-ineligible-acknowledgement")}
          />
        </BenefitsElectionStep>
      )}
    </Formik>
  )
}
