import { CheckBoxGroupCard } from "@/components/CheckBoxGroupCard"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { StyledCard } from "@/components/StyledCard"
import { NO, YES, YES_NO_OPTIONS_BOOLEAN } from "@/constants"
import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import { AboutCard } from "@/features/Profile/pages/Profile"
import { getDisplayPhoneNumber } from "@/utils/formatting"
import { Delete, Mail } from "@mui/icons-material"
import { Button, CircularProgress, Grid } from "@mui/material"
import { noop } from "lodash"
import { Dispatch, SetStateAction, useState } from "react"
import { useSignUpLink } from "../hooks/useSendSignupLink"
import { PersonModel } from "../peopleTypes"

const ROLE_OPTIONS = [
  { title: "Admin", value: "administrator" },
  { title: "Employee", value: "employee" },
  { title: "None", value: "none" },
]

const getRoleValues = (roles: string[] = []) => {
  const lowerCaseRoles = roles.map(role => role.toLowerCase())

  const isAdmin = lowerCaseRoles.includes("administrator") || lowerCaseRoles.includes("system administrator")
  const isEmployee = lowerCaseRoles.includes("employee")

  const roleValues = {
    administrator: isAdmin,
    employee: isEmployee,
  }

  return {
    ...roleValues,
    none: !isAdmin && !isEmployee,
  }
}

export interface PeopleDrawerProfileDetailsProps {
  setIsTerminateOpen: Dispatch<SetStateAction<boolean>>
  person: PersonModel
  company: CompanyModel
}

export const PeopleDrawerProfileDetails = ({
  setIsTerminateOpen,
  person,
  company,
}: PeopleDrawerProfileDetailsProps) => {
  const { handleSendSignUpLink } = useSignUpLink()
  const [isSendingLink, setIsSendingLink] = useState(false)

  // Person info
  const firstName = person?.firstName ?? ""
  const lastName = person?.lastName ?? ""
  const employmentId = person?.employmentId ?? ""
  const fullAddress = person?.location ?? ""
  const emailNew = person?.email ?? ""
  const phoneNumber = getDisplayPhoneNumber(person?.phoneNumber ?? "")
  const roles = person?.roles?.map(role => role.name.toLowerCase()) ?? []
  const roleValues = getRoleValues(roles)
  const benefitsEligible = person?.isBenefitsEligible ?? false

  // Company info
  const companyName = company?.companyInfo.companyName ?? ""

  const handleSendLink = async () => {
    setIsSendingLink(true)
    try {
      await handleSendSignUpLink(company.id, person.id)
    } finally {
      setIsSendingLink(false)
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <AboutCard
          firstName={firstName}
          lastName={lastName}
          employmentId={employmentId}
          companyName={companyName}
          fullAddress={fullAddress}
          email={emailNew}
          phoneNumber={phoneNumber}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledCard>
          <CheckBoxGroupCard
            disabled
            formName="profile-details"
            elements={ROLE_OPTIONS}
            values={roleValues}
            handleChange={noop}
            error={false}
            label="Roles"
          />
        </StyledCard>
      </Grid>
      <Grid item xs={12}>
        <StyledCard>
          <RadioGroupCard
            disabled
            name="benefitsEligible"
            formName="profile-details"
            elements={YES_NO_OPTIONS_BOOLEAN}
            value={benefitsEligible ? YES : NO}
            handleChange={noop}
            label="Benefits Eligible"
          />
        </StyledCard>
      </Grid>
      <Grid item xs={12}>
        <StyledCard>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Button
                variant="outlined"
                data-qa="send-signup-link"
                onClick={handleSendLink}
                disabled={isSendingLink}
                startIcon={isSendingLink ? <CircularProgress size={20} /> : <Mail />}
              >
                Send Sign-up Link
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                data-qa="terminate-employee"
                onClick={() => setIsTerminateOpen(true)}
                startIcon={<Delete />}
              >
                Terminate Employee
              </Button>
            </Grid>
          </Grid>
        </StyledCard>
      </Grid>
    </>
  )
}
