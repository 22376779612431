import { DRAWER_WIDTH } from "@/constants"
import { WrapperProps } from "@/utils/types"
import { Box } from "@mui/material"

export const DrawerWrapper = ({ sx = {}, ...props }: WrapperProps) => (
  <Box
    sx={{
      // FUTURE: Use relative units here instead of px
      width: { md: `${DRAWER_WIDTH}px` },
      flexShrink: { md: 0 },
      ...sx,
    }}
    {...props}
  />
)
