import { getHrisConfig, linkHrisConnection } from "@/features/Hris/hrisEndpoints"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

export const useHrisConfig = (companyId: string) =>
  useQuery({
    queryKey: ["hris-config", companyId],
    queryFn: () => getHrisConfig(companyId),
    enabled: Boolean(companyId),
  })

export const useHrisLink = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: { companyId: string; publicToken: string }) => {
      await linkHrisConnection(payload.companyId, payload.publicToken)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === "hris-config",
      })
    },
  })
}
