import { Property } from "./types"

export const STATE_OPTIONS = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District of Columbia",
    value: "DC",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
] as const

export const STATES = STATE_OPTIONS.map(s => s.value)

export type UsaState = Property<typeof STATES>

export const getStateLabel = (stateCode: string) => {
  const stateName = STATE_OPTIONS.find(state => state.value === stateCode)?.label

  return stateName ?? stateCode
}

type StateToExchange = Record<UsaState, string | null>

export const StatesToExchangeLink: StateToExchange = {
  AL: null,
  AK: null,
  AZ: null,
  AR: null,
  CA: "http://www.coveredca.com/",
  CO: "http://www.connectforhealthco.com/",
  CT: "http://www.accesshealthct.com/",
  DE: null,
  DC: "https://dchealthlink.com/",
  FL: null,
  GA: null,
  HI: null,
  ID: "http://www.yourhealthidaho.org/",
  IL: null,
  IN: null,
  IA: null,
  KS: null,
  KY: "https://kynect.ky.gov/s/?language=en_US",
  LA: null,
  ME: "https://coverme.gov/",
  MD: "http://www.marylandhealthconnection.gov/",
  MA: "https://www.mahealthconnector.org/",
  MI: null,
  MN: "http://mn.gov/hix/",
  MS: null,
  MO: null,
  MT: null,
  NE: null,
  NV: "https://www.nevadahealthlink.com/",
  NH: null,
  NJ: "https://www.getcovered.nj.gov/",
  NM: "http://www.bewellnm.com/",
  NY: "http://nystateofhealth.ny.gov/",
  NC: null,
  ND: null,
  OH: null,
  OK: null,
  OR: null,
  PA: "https://www.pennie.com/",
  RI: "http://www.healthsourceri.com/",
  SC: null,
  SD: null,
  TN: null,
  TX: null,
  UT: null,
  VT: "http://healthconnect.vermont.gov/",
  VA: "https://www.marketplace.virginia.gov/",
  WA: "http://www.wahealthplanfinder.org/",
  WV: null,
  WI: null,
  WY: null,
}
