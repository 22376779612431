import { TypographyOptions } from "@mui/material/styles/createTypography"
import { CSSProperties } from "react"
import { takeCommandPrimary } from "./palette"

type AllNewTypographyVariants =
  | "h140"
  | "h2small"
  | "h2tiempos"
  | "h3tiempos"
  | "h3small"
  | "body1bold"
  | "body2bold"
  | "badges11"
  | "huge"

declare module "@mui/material/styles" {
  type MappedTypographyStyles = {
    [Variant in AllNewTypographyVariants]: CSSProperties
  }
  interface TypographyVariants extends MappedTypographyStyles {}
  interface TypographyVariantsOptions extends Partial<MappedTypographyStyles> {}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  type MappedTypographyStyles = {
    [Variant in AllNewTypographyVariants]: true
  }
  interface TypographyPropsVariantOverrides extends MappedTypographyStyles {}
}

export const typography: TypographyOptions = {
  fontFamily: [
    "Mark Pro",
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h140: {
    fontSize: "27pt",
    lineHeight: "2.4rem",
    fontFamily: "Tiempos Medium",
  },
  h1: {
    fontSize: "24pt",
    lineHeight: "2.4rem",
    fontFamily: "Tiempos Medium",
  },
  h2: {
    fontSize: "21pt",
    fontWeight: 600,
    lineHeight: "2.1rem",
    fontFamily: "Mark Pro",
  },
  h2small: {
    fontSize: "15pt",
    fontWeight: 500,
    lineHeight: "1.32813rem",
    fontFamily: "Mark Pro Medium",
  },
  h2tiempos: {
    fontSize: "21pt",
    lineHeight: "2.1rem",
    fontFamily: "Tiempos Medium",
  },
  h3: {
    fontSize: "19pt",
    lineHeight: "1.8rem",
    fontWeight: 500,
    fontFamily: "Mark Pro Medium",
    "@media (max-width:600px)": {
      fontSize: "15pt",
    },
  },
  h3tiempos: {
    fontSize: "19pt",
    lineHeight: "1.95rem",
    fontFamily: "Tiempos Semibold",
    fontWeight: 400,
  },
  h3small: {
    fontSize: "18pt",
    fontWeight: 400,
    lineHeight: "1.8rem",
    fontFamily: "Mark Pro",
  },
  h4: {
    fontSize: "18pt",
    fontWeight: 400,
    lineHeight: "1.875rem",
    fontFamily: "Mark Pro",
  },
  h5: {
    fontSize: "15pt",
    fontWeight: 500,
    lineHeight: "1.32813rem",
    fontFamily: "Mark Pro Medium",
  },
  h6: {
    fontSize: "13pt",
    fontWeight: 500,
    lineHeight: "1.25rem",
    fontFamily: "Mark Pro Bold",
    "@media (max-width:600px)": {
      fontSize: "1rem",
    },
  },
  body1: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1.40719rem",
    fontFamily: "Mark Pro",
  },
  body1bold: {
    fontSize: "12pt",
    fontWeight: 500,
    lineHeight: "1.40719rem",
    fontFamily: "Mark Pro Medium",
  },
  body2: {
    fontSize: "10pt",
    fontWeight: 400,
    lineHeight: "1.16188rem",
    fontFamily: "Mark Pro",
    color: "colors.lightGrayText",
  },
  body2bold: {
    fontSize: "10pt",
    fontWeight: 500,
    lineHeight: "1.162rem",
    fontFamily: "Mark Pro Bold",
    color: "colors.lightGrayText",
  },
  caption: {
    fontSize: "10pt",
    fontWeight: 400,
    lineHeight: "1.34981rem",
    fontFamily: "Mark Pro",
    color: takeCommandPrimary.captionGray,
  },
  subtitle1: {
    fontSize: "11pt",
    fontWeight: 500,
    lineHeight: "1.45781rem",
    fontFamily: "Mark Pro Medium",
  },
  subtitle2: {
    fontSize: "10pt",
    fontWeight: 500,
    lineHeight: "1.27563rem",
    fontFamily: "Mark Pro Medium",
  },
  overline: {
    fontSize: "8pt",
    fontWeight: 500,
    lineHeight: "1.8525rem",
    fontFamily: "Mark Pro Medium",
    textTransform: "uppercase",
  },
  badges11: {
    fontSize: "8pt",
    fontWeight: 400,
    lineHeight: "1rem",
    fontFamily: "Mark Pro",
  },
  huge: {
    fontSize: "1.5rem",
  },
  button: {
    fontWeight: 400,
    fontSize: "12pt",
    textTransform: "none",
  },
}
