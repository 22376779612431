import { useNotifications } from "@/services/notificationService"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { getActiveCompany } from "../Auth/services/authService"
import { useAuth } from "../Auth/useAuth"
import { getOnboardingStatus } from "../CreateCompany/createCompanyEndpoints"
import { getEmployerOnboardingStatuses, updateEmployerOnboardingStatus } from "./employerOnboardingEndpoints"
import { EmployerOnboardingStatus, EmployerOnboardingStep } from "./employerOnboardingTypes"
import { mapListStatusesToObject } from "./employerOnboardingUtils"

export const useGetEmployerOnboardingStatuses = (companyId: string) =>
  useQuery({
    queryKey: ["onboarding-statuses", companyId],
    queryFn: () => getEmployerOnboardingStatuses(companyId),
  })

export const useUpdateEmployerOnboardingStatus = (companyId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: EmployerOnboardingStatus) => updateEmployerOnboardingStatus(companyId, values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["onboarding-statuses", companyId] })
    },
  })
}

export const useUpdateOnboardingStatus = () => {
  const { notify } = useNotifications("update-onboarding-status")
  const { user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId
  const { mutateAsync: updateCurrentOnboardingStatus, isPending } = useUpdateEmployerOnboardingStatus(companyId)

  const updateStepOnboardingStatus = async (
    stepName: EmployerOnboardingStep,
    isComplete: boolean,
    statusValue?: string
  ) => {
    const updatedStep: EmployerOnboardingStatus = {
      step: stepName,
      isComplete,
      statusValue,
    }

    await updateCurrentOnboardingStatus(updatedStep, {
      onError: errorData => {
        notify("Error updating onboarding status. Please try again later", "error")
        console.error(errorData.message)
      },
    })
  }

  return { isPending, updateStepOnboardingStatus }
}

export const useOnboardingStatuses = () => {
  const { user } = useAuth()
  // FUTURE: Remove this unsafe non-null assertion
  const companyId = user?.company?.companyId!
  const { data, isPending } = useGetEmployerOnboardingStatuses(companyId)
  const statuses = mapListStatusesToObject(data ?? [])

  const isDocumentsComplete = statuses.DOCUMENTS_VIEWED?.isComplete
  const isEmployeesComplete = statuses.EMPLOYEE_ROSTER?.isComplete
  const isAutoPayComplete = statuses.AUTOPAY_COMPLETED?.isComplete
  const isInvitationsComplete = statuses.INVITE_DATE_SET?.isComplete
  const isBillingReady = isDocumentsComplete && isInvitationsComplete
  const isBillingSetupComplete = statuses.BILLING_SETUP?.isComplete
  const isAccountComplete = !!statuses.ACCOUNT?.isComplete
  const isPlanSetupComplete = !!statuses.PLAN_SETUP?.isComplete
  const isPlanStructureComplete = !!statuses.PLAN_STRUCTURE?.isComplete

  const isCreateCompanyComplete = isAccountComplete && isPlanSetupComplete && isPlanStructureComplete
  const isOnboardingComplete = !!statuses.ONBOARDING_COMPLETE?.isComplete
  const isEmployerOnboarding = isCreateCompanyComplete && !isOnboardingComplete
  const accountStatusValue = statuses.ACCOUNT?.statusValue ? JSON.parse(statuses.ACCOUNT.statusValue) : {}
  const isAssisted = accountStatusValue?.isAssisted

  return {
    statuses,
    isPending,

    // Individual Statuses
    isDocumentsComplete,
    isEmployeesComplete,
    isAutoPayComplete,
    isInvitationsComplete,
    isOnboardingComplete,
    isAccountComplete,
    isPlanSetupComplete,
    isPlanStructureComplete,
    isAssisted,
    isBillingSetupComplete,

    // Meta Statuses
    isCreateCompanyComplete,
    isEmployerOnboarding,
    isBillingReady,
  }
}

export const useGetCompanyOnboardingStatus = (companyId: string) =>
  useQuery({
    queryKey: ["onboarding-statuses", companyId],
    queryFn: () => getOnboardingStatus(companyId),
  })
