import { useAuth } from "@/features/Auth/useAuth"
import { getAuthenticationToken } from "@/features/AutoPay/autopayEndpoints"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import * as idb from "idb-keyval"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import {
  getCompanyFundingEventEntries,
  getCompanyFundingEvents,
  getCompanyFundingEventSummary,
} from "../Funding/fundingEndpoints"
import { CompanyFundingEntriesRequest } from "../Funding/fundingTypes"

const getAuthTokenKey = (companyId: string | null, userId: string | null) => ["auth-tokens", companyId, userId]

export const useAuthToken = (companyId: string | null) => {
  const { user } = useAuth()
  const userId = user?.id ?? null
  // -1 means we haven't checked yet and 0 means it should be refreshed instantly
  const [tokenStaleTime, setTokenStaleTime] = useState(-1)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!window?.indexedDB || !companyId || !userId) {
      return
    }
    idb
      .get(getAuthTokenKey(companyId, userId).join("-"))
      .then(result => {
        if (result) {
          setTokenStaleTime(DateTime.fromISO(result.expiredAt).toMillis())
          queryClient.setQueryData(getAuthTokenKey(companyId, userId), result)
          queryClient.invalidateQueries({
            queryKey: getAuthTokenKey(companyId, userId),
          })
        } else {
          setTokenStaleTime(0)
        }
      })
      .catch(error => {
        console.error(error)
        setTokenStaleTime(0)
      })
  }, [queryClient, companyId, userId])

  return useQuery({
    queryKey: getAuthTokenKey(companyId, userId),
    queryFn: async () => {
      const result = await getAuthenticationToken(companyId!)

      if (!window?.indexedDB) {
        return result
      }
      idb.set(getAuthTokenKey(companyId, userId).join("-"), result).catch(console.error)
      setTokenStaleTime(DateTime.fromISO(result.expiredAt).toMillis())

      return result
    },
    enabled: Boolean(companyId && userId) && tokenStaleTime !== -1 && Date.now() > tokenStaleTime,
  })
}

export const useCompanyFundingEvents = (companyId: string) =>
  useQuery({
    queryKey: ["getCompanyFundingEvents", companyId],
    queryFn: async () => {
      const data = await getCompanyFundingEvents(companyId)

      return data
    },
    enabled: !!companyId,
    refetchOnWindowFocus: false,
  })

export const useCompanyFundingEventSummary = (companyId: string, fundingEventId: string | undefined) =>
  useQuery({
    queryKey: ["companies", companyId, "funding-events", fundingEventId],
    queryFn: () => getCompanyFundingEventSummary(companyId, fundingEventId!),
    enabled: !!companyId && !!fundingEventId,
    refetchOnWindowFocus: false,
  })

export const useCompanyFundingEventEntries = (companyFundingEntriesRequest: CompanyFundingEntriesRequest) =>
  useQuery({
    queryKey: [
      "getCompanyFundingEventEntries",
      companyFundingEntriesRequest.month,
      companyFundingEntriesRequest.year,
      companyFundingEntriesRequest.companyId,
    ],
    queryFn: async () => {
      const companyFundingEventEntriesResponse = await getCompanyFundingEventEntries(companyFundingEntriesRequest)
      return companyFundingEventEntriesResponse.fundingEntries
    },
    enabled: !!companyFundingEntriesRequest.companyId,
    refetchOnWindowFocus: false,
  })
