import { AsyncButton, ReturnButton } from "@/components/Buttons"
import { ConfirmationModal, useConfirmationControl } from "@/components/ConfirmationModal"
import { StyledCard } from "@/components/StyledCard"
import { resetUserMfa } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { convertHraClassModelToCustomClassDetails } from "@/features/Benefits/benefitsUtils"
import { SPECIAL_ENROLLMENT } from "@/features/BenefitsElection/benefitsElectionConstants"
import { useGetShoppingSession, useIsCompanyAutoPay } from "@/features/BenefitsElection/benefitsElectionService"
import { useBenefitsElectionStore } from "@/features/BenefitsElection/benefitsElectionStore"
import {
  AffordabilityCheckPayload,
  EligibleApplicant,
  EnrollmentStatus,
  EnrollmentTimePeriod,
  EnrollmentType,
  InsuranceType,
  PlanType,
  ShoppingSession,
} from "@/features/BenefitsElection/benefitsElectionTypes"
import {
  getPlanSearchApplicants,
  mapShoppingPersonPayloadToStore,
} from "@/features/BenefitsElection/benefitsElectionUtils"
import { CarrierLogo, PlanDetailsChips } from "@/features/BenefitsElection/components/PlanComponents"
import { useCheckAffordability } from "@/features/BenefitsElection/healthPlansService"
import { FamilyMemberCard } from "@/features/BenefitsElection/pages/myInfo/Family/Family"
import { useGetHraPlan } from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupService"
import { ComplianceFrequency } from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupTypes"
import { useGetCurrentClasses } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureService"
import { useGetCompany } from "@/features/CreateCompany/components/Steps/Setup/setupService"
import { EMPLOYEE } from "@/features/CreateCompany/createCompanyConstants"
import { useGetEnrollmentTimePeriodsValue, useResetShoppingSession } from "@/features/Dashboard/dashboardService"
import { getActiveEnrollmentTimePeriods } from "@/features/Dashboard/dashboardUtils"
import { ChipPersonStatus } from "@/features/People/components/ChipPersonStatus"
import { classSelector } from "@/features/People/PeoplePage"
import { PeopleStatus } from "@/features/People/peopleTypes"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import {
  DATE_FORMAT_MONTH_DAY_YEAR,
  createDateFromDateTimeText,
  createDateFromText,
  formatBooleanAsYesNo,
  formatCents,
  getDisplayPhoneNumber,
  toTitleCase,
  transformDate,
} from "@/utils/formatting"
import { Cents, Uuid } from "@/utils/types"
import { EditOutlined, LockOpenOutlined } from "@mui/icons-material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { maxBy } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router-dom"
import { useCarriers } from "../Enrollment/tcHubEnrollmentService"
import { useBusinessUnits, useUser } from "../tcHubService"

const dataQa = "tc-hub-person-detail-page"

interface PersonDetailCardProps {
  status?: PeopleStatus
  dateOfBirth?: string
  email?: string
  businessUnitName?: string
  className?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  isAutoPay: boolean
  companyName: string
  complianceFrequency?: ComplianceFrequency
}

const PersonDetailCard = ({
  status,
  dateOfBirth,
  email,
  businessUnitName,
  className,
  firstName,
  lastName,
  phoneNumber,
  isAutoPay,
  companyName,
  complianceFrequency,
}: PersonDetailCardProps) => (
  <StyledCard>
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      data-qa={createDataQa(dataQa, "person-details-card")}
    >
      <Grid item>
        <Typography variant="h5" data-qa="person-detail-title" mb={0}>
          {firstName && lastName ? `${firstName} ${lastName}` : "User information"}
        </Typography>
      </Grid>
      {status && (
        <Grid item>
          <ChipPersonStatus status={status} />
        </Grid>
      )}
    </Grid>
    <Divider sx={{ my: 3 }} />
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1bold" data-qa={createDataQa(dataQa, "person-detail-email")}>
          Email
        </Typography>
        <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
          {email}
        </Typography>
      </Grid>
      {dateOfBirth && (
        <Grid item xs={12} sm={3}>
          <Typography variant="body1bold" data-qa={createDataQa(dataQa, "person-detail-dob")}>
            Date of Birth
          </Typography>
          <Typography variant="body1">{transformDate(dateOfBirth, DATE_FORMAT_MONTH_DAY_YEAR)}</Typography>
        </Grid>
      )}

      {phoneNumber && (
        <Grid item xs={12} sm={3}>
          <Typography variant="body1bold" data-qa={createDataQa(dataQa, "person-detail-phone")}>
            Phone Number
          </Typography>
          <Typography>{getDisplayPhoneNumber(phoneNumber)}</Typography>
        </Grid>
      )}
      {businessUnitName && (
        <Grid item xs={12} sm={3}>
          <Typography variant="body1bold" data-qa={createDataQa(dataQa, "person-detail-business-unit")}>
            Business Unit
          </Typography>
          <Typography variant="body1">{businessUnitName}</Typography>
        </Grid>
      )}

      {className && (
        <Grid item xs={12} sm={3}>
          <Typography variant="body1bold" data-qa={createDataQa(dataQa, "person-detail-address")}>
            Class
          </Typography>
          <Typography variant="body1">{className}</Typography>
        </Grid>
      )}

      <Grid item xs={12} mt={4}>
        <Typography variant="body1bold">Company</Typography>
        <Divider sx={{ mt: 4 }} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Typography variant="body1bold" data-qa={createDataQa(dataQa, "company-name")}>
          Name
        </Typography>
        <Typography variant="body1">{companyName}</Typography>
      </Grid>

      <Grid item xs={12} sm={3}>
        <Typography variant="body1bold" data-qa={createDataQa(dataQa, "company-autopay")}>
          AutoPay Configured
        </Typography>
        <Typography variant="body1">{formatBooleanAsYesNo(isAutoPay)}</Typography>
      </Grid>

      {complianceFrequency && (
        <Grid item xs={12} sm={3}>
          <Typography variant="body1bold" data-qa={createDataQa(dataQa, "company-compliance")}>
            Compliance Frecuency
          </Typography>
          <Typography variant="body1">{toTitleCase(complianceFrequency)}</Typography>
        </Grid>
      )}
    </Grid>
  </StyledCard>
)

interface PersonRecurringPremiumCardProps {
  carrierLogo: string
  carrierName: string
  isLoadingCarrier: boolean
  coverageEndDate?: string
  coverageStartDate?: string
  employeeName: string
  healthPlanName?: string
  healthPlanType?: PlanType
  isAutoPay: boolean
  premiumAmountCents?: Cents
  recurringPremiumId: string
  enrollmentType?: EnrollmentType
  insuranceType?: InsuranceType
  enrollmentStatus?: EnrollmentStatus
  isHsaQualified?: boolean
  fullAddress?: string
  membersCovered?: string
}

const PersonRecurringPremiumCard = ({
  carrierLogo,
  carrierName,
  isLoadingCarrier,
  coverageEndDate,
  coverageStartDate,
  employeeName,
  healthPlanName,
  healthPlanType,
  isAutoPay,
  premiumAmountCents,
  recurringPremiumId,
  enrollmentType,
  insuranceType,
  enrollmentStatus,
  isHsaQualified,
  fullAddress,
  membersCovered,
}: PersonRecurringPremiumCardProps) => (
  <StyledCard sx={{ border: "1px solid", borderColor: "colors.borderGray" }}>
    <Grid container spacing={4}>
      <Grid item xs={12} data-qa={createDataQa(dataQa, "person-recurring-premium-card")}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" gutterBottom>
            Reimbursement
          </Typography>
          <Box />
        </Stack>
      </Grid>

      {carrierName && (
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={4}>
            {isLoadingCarrier ? (
              <CircularProgress size={20} />
            ) : (
              <CarrierLogo
                carrierName={carrierName}
                logoUrl={carrierLogo}
                sx={{ height: "3.125rem", p: "1rem 0.5rem" }}
                data-qa={createDataQa(dataQa, "carrier-logo")}
              />
            )}

            <Typography>{carrierName}</Typography>
          </Stack>
        </Grid>
      )}

      {healthPlanName && (
        <Grid item xs={12}>
          <Typography variant="body1bold">Plan: {healthPlanName}</Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <PlanDetailsChips
          planType={healthPlanType!}
          data-qa={createDataQa(dataQa, "recurring-premium-plan-chips")}
          isAutoPay={isAutoPay}
          enrollmentType={enrollmentType}
          limitHeight={false}
          offExchange={false}
          onExchange
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa={createDataQa(dataQa, "premium-amount")}>
          Premium Amount
        </Typography>
        <Typography variant="body1">{formatCents(premiumAmountCents)}</Typography>
      </Grid>

      {insuranceType && (
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa={createDataQa(dataQa, "insurance-type")}>
            Insurance Type
          </Typography>
          <Typography variant="body1">{toTitleCase(insuranceType)}</Typography>
        </Grid>
      )}

      {enrollmentStatus && (
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa={createDataQa(dataQa, "enrollment-status")}>
            Enrollment Status
          </Typography>
          <Typography variant="body1">{toTitleCase(enrollmentStatus)}</Typography>
        </Grid>
      )}

      <Grid item container xs={12} spacing={4}>
        {coverageStartDate && (
          <Grid item xs={12} sm={4}>
            <Typography variant="body1bold" data-qa={createDataQa(dataQa, "start-date")}>
              Start Date
            </Typography>
            <Typography variant="body1">{transformDate(coverageStartDate, DATE_FORMAT_MONTH_DAY_YEAR)}</Typography>
          </Grid>
        )}
        {coverageEndDate && (
          <Grid item xs={12} sm={8}>
            <Typography variant="body1bold" data-qa={createDataQa(dataQa, "end-date")}>
              End Date
            </Typography>
            <Typography variant="body1">{transformDate(coverageEndDate, DATE_FORMAT_MONTH_DAY_YEAR)}</Typography>
          </Grid>
        )}
      </Grid>
      {isHsaQualified !== undefined && (
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa={createDataQa(dataQa, "hsa-qualified")}>
            Hsa Qualified
          </Typography>
          <Typography variant="body1">{formatBooleanAsYesNo(isHsaQualified)}</Typography>
        </Grid>
      )}
      {fullAddress !== undefined && (
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa={createDataQa(dataQa, "address")}>
            Address
          </Typography>
          <Typography variant="body1">{fullAddress}</Typography>
        </Grid>
      )}
      {membersCovered !== undefined && (
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa={createDataQa(dataQa, "covered-members")}>
            Members covered
          </Typography>
          <Typography variant="body1">{membersCovered}</Typography>
        </Grid>
      )}
    </Grid>
  </StyledCard>
)

interface ResetShoppingButtonProps {
  handleResetShoppingSession: () => void
  isResettingShoppingSession: boolean
}

const ResetShoppingButton = ({ handleResetShoppingSession, isResettingShoppingSession }: ResetShoppingButtonProps) => (
  <AsyncButton
    data-qa="reset-shopping-button"
    variant="contained"
    sx={{
      backgroundColor: "colors.lightBlueShade",
      color: "colors.darkBody",
      "&:hover": {
        backgroundColor: "colors.mediumTealShade",
      },
    }}
    onClick={handleResetShoppingSession}
    isLoading={isResettingShoppingSession}
    fullWidth
  >
    Reset Shopping
  </AsyncButton>
)

interface ForgotPasswordButtonProps {
  email: string
  isResettingPassword: boolean
  handleClick: () => void
}

const ForgotPasswordButton = ({ email, handleClick, isResettingPassword }: ForgotPasswordButtonProps) => (
  <Tooltip title={!email ? "User Email is required to send forgot password." : null} disableHoverListener={!!email}>
    <span>
      <Button
        data-qa="forgot-password-button"
        variant="outlined"
        onClick={handleClick}
        startIcon={<LockOpenOutlined />}
        disabled={!email || isResettingPassword}
        fullWidth
      >
        {isResettingPassword ? "Sending..." : "Send Forgot Password"}
      </Button>
    </span>
  </Tooltip>
)

interface MfaResetButtonProps {
  userId?: string
  isResettingMfa: boolean
  handleClick: () => void
}

const MfaResetButton = ({ userId, isResettingMfa, handleClick }: MfaResetButtonProps) => (
  <Tooltip title={!userId ? "User ID is required to reset MFA." : null} disableHoverListener={!!userId}>
    <span>
      <Button
        data-qa="reset-mfa-button"
        variant="outlined"
        onClick={handleClick}
        startIcon={<LockOpenOutlined />}
        disabled={!userId || isResettingMfa}
        fullWidth
      >
        {isResettingMfa ? "Resetting..." : "Reset MFA"}
      </Button>
    </span>
  </Tooltip>
)

interface ShoppingSessionInformationProps {
  shoppingSession: ShoppingSession
  isAutoPay: boolean
}

const ShoppingSessionInformation = ({ shoppingSession, isAutoPay }: ShoppingSessionInformationProps) => {
  const { data: carriers, isLoading: isLoadingCarrier } = useCarriers()
  const { shoppingStatus, planYear, monthlyAllowanceCents, shoppingPersons, healthBenefitElections } = shoppingSession

  const employee = shoppingPersons?.find(person => person.relationship === EMPLOYEE)
  const employeeName = employee?.firstName + " " + employee?.lastName

  return (
    <>
      <Grid item xs={12} mt={12}>
        <Typography variant="body1bold">Shopping Session</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold">Status</Typography>
        <Typography data-qa={createDataQa("shopping", "status")}>{toTitleCase(shoppingStatus)}</Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold">Plan Year</Typography>
        <Typography data-qa={createDataQa("shopping", "plan-year")}>{planYear}</Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold">Allowance</Typography>
        <Typography data-qa={createDataQa("shopping", "allowance")}>{formatCents(monthlyAllowanceCents)}</Typography>
      </Grid>

      {!!shoppingPersons?.length && (
        <Grid item xs={12} mt={4}>
          <Typography variant="body1bold">Family members</Typography>
        </Grid>
      )}

      {shoppingPersons?.map(shoppingPerson => (
        <Grid item xs={12} md={6} key={shoppingPerson.id}>
          <FamilyMemberCard
            familyMember={mapShoppingPersonPayloadToStore(shoppingPerson)}
            isPending={false}
            isAddressDifferent={false}
            showEmployeeName
          />
        </Grid>
      ))}

      {!!healthBenefitElections?.length && (
        <Grid item xs={12} mt={4}>
          <Typography variant="body1bold">Reimbursements</Typography>
        </Grid>
      )}

      {healthBenefitElections?.map(benefitElection => {
        const {
          id,
          carrierName = "",
          carrierId,
          healthPlanName,
          healthPlanType,
          enrollmentType,
          premiumAmountCents,
          mailingAddress,
          shoppingPersons: shoppingPersonsCovered,
          enrollmentStatus,
          insuranceType,
          planEffectiveDate,
          planEndDate,
          isHsaQualified,
        } = benefitElection
        const carrierLogo = carrierId ? carriers?.find(carrier => carrier.id === carrierId)?.issuers?.[0].logo_url : ""
        const { city = "", state = "", zipCode = "", street1 = "", street2 = "" } = mailingAddress ?? {}
        const fullAddress = `${street1} ${street2} ${city}, ${state} ${zipCode}`
        const membersCovered = shoppingPersonsCovered
          ?.map(({ firstName, lastName }) => `${firstName} ${lastName}`)
          .join(", ")

        return (
          <Grid item xs={12} key={id}>
            <PersonRecurringPremiumCard
              isLoadingCarrier={isLoadingCarrier}
              carrierLogo={carrierLogo ?? ""}
              carrierName={carrierName}
              coverageEndDate={planEndDate}
              coverageStartDate={planEffectiveDate}
              employeeName={employeeName}
              healthPlanName={healthPlanName}
              healthPlanType={healthPlanType}
              isAutoPay={isAutoPay}
              premiumAmountCents={premiumAmountCents}
              recurringPremiumId={id}
              enrollmentType={enrollmentType}
              insuranceType={insuranceType}
              enrollmentStatus={enrollmentStatus}
              isHsaQualified={isHsaQualified}
              fullAddress={fullAddress}
              membersCovered={membersCovered}
            />
          </Grid>
        )
      })}
    </>
  )
}

interface CardLoadingBoxProps {
  text?: string
}

const CardLoadingBox = ({ text }: CardLoadingBoxProps) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      gap: 4,
      justifyContent: "center",
      width: "100%",
      minHeight: "6rem",
    }}
    data-qa="loading-box"
  >
    <Typography mt={4}>{text}</Typography>
    <CircularProgress size={35} />
  </Box>
)

interface ShoppingSessionCardProps {
  shoppingSession: ShoppingSession | undefined
  isLoading: boolean
  isAutoPay: boolean
}

export const ShoppingSessionCard = ({ shoppingSession, isLoading, isAutoPay }: ShoppingSessionCardProps) => (
  <>
    {isLoading && (
      <Grid item xs={12}>
        <Divider />
        <CardLoadingBox text="Loading shopping session" />
      </Grid>
    )}

    {shoppingSession && <ShoppingSessionInformation shoppingSession={shoppingSession} isAutoPay={isAutoPay} />}
  </>
)

interface ShoppingInformationCardProps {
  employmentId: string
  enrollmentTimePeriod: EnrollmentTimePeriod
  isAutoPay: boolean
}

const ShoppingInformationCard = ({ employmentId, enrollmentTimePeriod, isAutoPay }: ShoppingInformationCardProps) => {
  const { data: shoppingSession, isLoading: isLoadingShoppingSession } = useGetShoppingSession(
    enrollmentTimePeriod.shoppingSessionId
  )
  const employee = shoppingSession?.shoppingPersons?.find(person => person.relationship === EMPLOYEE)

  const createAffordabilityPayload = (): AffordabilityCheckPayload | undefined => {
    if (employee) {
      const { zipCode, fipsCode, annualIncomeCents } = employee
      const { monthlyAllowanceCents, planYear, shoppingPersons } = shoppingSession!

      if (zipCode && fipsCode && annualIncomeCents && monthlyAllowanceCents && planYear && shoppingPersons) {
        const applicants: EligibleApplicant[] = shoppingPersons.map(
          shoppingPerson =>
            ({
              shoppingPersonId: shoppingPerson.id,
              personalInformation: {
                dateOfBirth: shoppingPerson.dateOfBirth,
                relationship: shoppingPerson.relationship,
                isTobaccoUser: shoppingPerson.isTobaccoUser,
              },
            }) as EligibleApplicant
        )
        return {
          zipCode,
          fipsCode,
          householdIncome: annualIncomeCents,
          allowanceInCents: monthlyAllowanceCents,
          planYear,
          applicants: getPlanSearchApplicants(applicants, shoppingSession as ShoppingSession),
        }
      }

      console.error("Not enough data to check affordability", {
        employmentId,
        zipCode,
        fipsCode,
        annualIncomeCents,
        monthlyAllowanceCents,
        planYear,
        shoppingPersons,
      })

      return undefined
    }
    return undefined
  }

  const affordabilityPayload = createAffordabilityPayload()
  const { data: affordability, isLoading: isLoadingAffordability } = useCheckAffordability(affordabilityPayload)

  const isLoading = isLoadingShoppingSession || isLoadingAffordability

  return (
    <Grid container spacing={4} m={0} width="100%" pb={8} pr={4}>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold">Start Date</Typography>
        <Typography data-qa={createDataQa("etp", "start-date")}>
          {transformDate(enrollmentTimePeriod.periodStartUtc, DATE_FORMAT_MONTH_DAY_YEAR)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold">End Date</Typography>
        <Typography data-qa={createDataQa("etp", "end-date")}>
          {transformDate(enrollmentTimePeriod.periodEndUtc, DATE_FORMAT_MONTH_DAY_YEAR)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold">Eligibility Date</Typography>
        <Typography data-qa={createDataQa("etp", "eligibility-date")}>
          {transformDate(enrollmentTimePeriod.eligibiltyDate, DATE_FORMAT_MONTH_DAY_YEAR)}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold">Plan Year</Typography>
        <Typography data-qa={createDataQa("etp", "plan-year")}>{enrollmentTimePeriod.planYear}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold">Shopping available</Typography>
        <Typography data-qa={createDataQa("etp", "can-shop")}>
          {formatBooleanAsYesNo(enrollmentTimePeriod.canShop)}
        </Typography>
      </Grid>

      {affordability && (
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa={createDataQa("etp", "affordability")}>
            Yearly Affordable ICHRA
          </Typography>
          <Typography variant="body1">{formatBooleanAsYesNo(affordability.isAffordable)}</Typography>
        </Grid>
      )}

      <ShoppingSessionCard shoppingSession={shoppingSession} isLoading={isLoading} isAutoPay={isAutoPay} />
    </Grid>
  )
}

interface YearPickerProps {
  year: null | number
  yearOptions: number[]
  handleChange: (yearValue: any) => void
}

const YearPicker = ({ year, yearOptions, handleChange }: YearPickerProps) => (
  <Stack direction="row" gap={4} alignItems="center">
    <Typography whiteSpace="nowrap">Select a year:</Typography>
    <FormControl fullWidth>
      <InputLabel id="year-label" sx={{ mt: -2 }}>
        Year
      </InputLabel>
      <Select
        labelId="year-label"
        id="year-select"
        value={year}
        label="Year"
        onChange={event => handleChange(event.target.value)}
        placeholder="pepe"
        size="small"
        data-qa="year-select"
      >
        {yearOptions.map(yearOption => (
          <MenuItem value={yearOption} key={yearOption}>
            {yearOption}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Stack>
)

export const TcHubUserDetailPage = () => {
  const { confirmationModal, setConfirmationModal, setConfirmationSubmitting, clearConfirmationModal } =
    useConfirmationControl()
  const { userId, employmentId, companyId } = useParams<{ userId: string; employmentId: string; companyId: Uuid }>()
  const navigate = useNavigate()
  const { notify } = useNotifications("tchub-user-detail")
  const { forgotPassword } = useAuth()

  const [isResettingMfa, setIsResettingMfa] = useState(false)
  const [isResettingPassword, setIsResettingPassword] = useState(false)
  const [selectedYear, setSelectedYear] = useState<number | null>(null)

  const { isAutoPay, isLoading: isLoadingAutoPay } = useIsCompanyAutoPay(companyId as Uuid)
  const { data: tcHubPersonDetails, isLoading: istcHubPersonDetailsLoading } = useUser(
    companyId ?? ("" as never),
    userId ?? ""
  )
  const { data: businessUnits } = useBusinessUnits(companyId ?? "")
  const { data: enrollmentTimePeriods, isLoading: isLoadingEtp } = useGetEnrollmentTimePeriodsValue(employmentId)
  const { mutateAsync: resetShoppingSession, isPending: isResettingShoppingSession } = useResetShoppingSession(
    employmentId as Uuid
  )
  const { data: company } = useGetCompany(companyId!)
  const companyName = company?.companyInfo.companyName ?? ""

  // HRA plan and classes
  const employeeId = tcHubPersonDetails?.employmentId ?? ""
  const { data: hraPlans, isLoading: isLoadingPlans } = useGetHraPlan(tcHubPersonDetails?.companyId)
  const plansList = hraPlans ?? []
  const currentPlan = plansList[0]
  const { data: classes } = useGetCurrentClasses(tcHubPersonDetails?.companyId!, currentPlan?.id)
  const convertedClasses = classes ? convertHraClassModelToCustomClassDetails(classes) : []
  const className = classSelector(convertedClasses, employeeId)

  // Enrollment time periods
  const filteredEnrollmentTimePeriods = enrollmentTimePeriods?.filter(etp => Number(etp.planYear) === selectedYear)
  const clearBenefitsElectionStore = useBenefitsElectionStore(store => store.clearBenefitsElectionStore)
  const activeEnrollmentTimePeriods = getActiveEnrollmentTimePeriods(enrollmentTimePeriods ?? [])

  const { data: shoppingSession } = useGetShoppingSession(activeEnrollmentTimePeriods[0]?.shoppingSessionId)

  const { healthBenefitElections = [] } = shoppingSession || {}

  const etpYears = useMemo(
    () => [...new Set(enrollmentTimePeriods?.map(etp => Number(etp.planYear)))].sort((a, b) => b - a),
    [enrollmentTimePeriods]
  )

  const { dateOfBirth, email = "", status, employments, firstName, lastName, phoneNumber } = tcHubPersonDetails ?? {}
  const fullName = firstName && lastName ? `${firstName} ${lastName}` : "User"
  const businessUnitId = employments?.[0]?.businessUnitId ?? ""

  const primaryHealthBenefitElection = healthBenefitElections.find(election => election.isPrimary)

  const today = new Date()

  const showResetShoppingButton =
    !!activeEnrollmentTimePeriods?.length &&
    (shoppingSession?.shoppingStatus === "COMPLETED" || shoppingSession?.shoppingStatus === "WAIVED") &&
    today <= createDateFromText(primaryHealthBenefitElection?.planEffectiveDate!)

  const isLoadingData = istcHubPersonDetailsLoading || isLoadingEtp || isLoadingAutoPay || isLoadingPlans

  useEffect(() => {
    if (etpYears.length > 0) {
      setSelectedYear(etpYears[0])
    }
  }, [etpYears])

  const handleResetShoppingSession = async () => {
    try {
      const mostRecentTimePeriod = maxBy(activeEnrollmentTimePeriods, period =>
        createDateFromDateTimeText(period.periodEndUtc)
      )
      if (!mostRecentTimePeriod) {
        throw new Error(
          `Failed to retrieve the most recent time period. The most recent period found was '${mostRecentTimePeriod}', out of a total of ${activeEnrollmentTimePeriods.length} available periods.`
        )
      }
      await resetShoppingSession({ enrollmentTimePeriodId: mostRecentTimePeriod.id })
      clearBenefitsElectionStore()
      notify("The user's shopping session has been successfully reset.", "success")
    } catch (error) {
      console.error(error)
      notify(
        "Error resetting shopping session. Please try again later. If the issue persists, contact support for assistance.",
        "error"
      )
    }
  }

  const handleResetMfa = async () => {
    try {
      setIsResettingMfa(true)
      setConfirmationSubmitting(true)
      if (!userId) {
        notify("User ID is required to reset MFA", "error")
        return
      }

      await resetUserMfa(userId)
      notify("MFA reset successfully", "success")
    } catch (error) {
      console.error(error)
      notify("Failed to reset MFA", "error")
    } finally {
      setIsResettingMfa(false)
      setConfirmationSubmitting(false)
      clearConfirmationModal()
    }
  }

  const handleSendForgotPassword = async () => {
    try {
      setIsResettingPassword(true)
      setConfirmationSubmitting(true)
      if (!email) {
        notify("User email is required to send forgot password", "error")
        return
      }

      await forgotPassword(email)
      notify("password reset email sent", "success")
    } catch (error) {
      console.error(error)
      notify("Failed to send password reset email", "error")
    } finally {
      setIsResettingPassword(false)
      setConfirmationSubmitting(false)
      clearConfirmationModal()
    }
  }

  return (
    <Grid container data-qa={createDataQa(dataQa, "person-details-container")} spacing={4}>
      <Helmet title="TC Hub Site Settings" />
      <Grid item xs={12} mb={8}>
        <ReturnButton
          data-qa={createDataQa("back", "button")}
          text="the complete Users table"
          handleClose={() => navigate(-1)}
        />
      </Grid>
      {!isLoadingData && (
        <>
          <Grid item xs={12} sm={8}>
            <Typography variant="h1" gutterBottom data-qa="person-details">
              {fullName} Profile
            </Typography>
          </Grid>

          {etpYears.length > 0 && (
            <Grid item xs={12} sm={4}>
              <YearPicker
                year={selectedYear}
                handleChange={yearValue => setSelectedYear(yearValue)}
                yearOptions={etpYears}
              />
            </Grid>
          )}

          {showResetShoppingButton && (
            <Grid item xs={12} sm={4} xl={3}>
              <ResetShoppingButton
                handleResetShoppingSession={handleResetShoppingSession}
                isResettingShoppingSession={isResettingShoppingSession}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={5} xl={3}>
            <ForgotPasswordButton
              email={email}
              isResettingPassword={isResettingPassword}
              handleClick={() => {
                setConfirmationModal({
                  title: "Confirm Send Forgot Password",
                  message: "Are you sure you want to send a forgot password email?",
                  actionLabel: "Send Forgot Password",
                  onConfirm: handleSendForgotPassword,
                  isSubmitting: isResettingPassword,
                })
              }}
            />
          </Grid>

          {confirmationModal && (
            <ConfirmationModal
              {...confirmationModal}
              isOpen={!!confirmationModal}
              onClose={clearConfirmationModal}
              isSubmitting={isResettingPassword || isResettingMfa}
            />
          )}

          <Grid item xs={12} sm={3} xl={3}>
            <MfaResetButton
              userId={userId}
              isResettingMfa={isResettingMfa}
              handleClick={() => {
                setConfirmationModal({
                  title: "Confirm MFA Reset",
                  message: "Are you sure you want to reset MFA for this user?",
                  actionLabel: "Reset MFA",
                  onConfirm: handleResetMfa,
                  isSubmitting: isResettingMfa,
                })
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2} xl={3}>
            <Button
              data-qa="save-user-button"
              variant="contained"
              onClick={() => navigate(`edit`)}
              startIcon={<EditOutlined />}
              fullWidth
            >
              Edit
            </Button>
          </Grid>
        </>
      )}
      {isLoadingData ? (
        <Grid item xs={12} mt={8}>
          <CardLoadingBox text="Loading user information" />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <PersonDetailCard
              status={status as PeopleStatus}
              dateOfBirth={dateOfBirth}
              email={email}
              businessUnitName={businessUnits?.find(bu => bu.id === businessUnitId)?.name}
              className={className}
              firstName={firstName}
              lastName={lastName}
              phoneNumber={phoneNumber?.value}
              isAutoPay={isAutoPay}
              companyName={companyName}
              complianceFrequency={currentPlan?.complianceFrequency}
            />
          </Grid>
          <Grid item xs={12}>
            {filteredEnrollmentTimePeriods?.map((etp, index) => {
              const enrollmentTypeLabel =
                etp.enrollmentTimePeriodType === SPECIAL_ENROLLMENT ? "Special Enrollment" : "Open Enrollment"
              const etpPlanYear = etp.planYear
              return (
                <Accordion key={etp.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography variant="h5" px={4}>
                      {`${etpPlanYear} - ${enrollmentTypeLabel}`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ShoppingInformationCard
                      enrollmentTimePeriod={etp}
                      employmentId={employmentId!}
                      isAutoPay={isAutoPay}
                    />
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </Grid>
        </>
      )}
    </Grid>
  )
}
