import { create } from "zustand"
import { persist } from "zustand/middleware"

interface ShoppingCompleteState {
  waivedPlanYear: number
  completedPlanYear: number
}

interface ShoppingCompleteMethods {
  setWaivedPlanYear: (waivedPlanYear: number) => void
  setCompletedPlanYear: (completedPlanYear: number) => void
}

interface ShoppingCompleteStore extends ShoppingCompleteState, ShoppingCompleteMethods {}

export const useShoppingCompleteStore = create<ShoppingCompleteStore>()(
  persist(
    set => ({
      waivedPlanYear: new Date().getFullYear() + 1,
      completedPlanYear: new Date().getFullYear() + 1,

      setWaivedPlanYear: waivedPlanYear => set({ waivedPlanYear }),
      setCompletedPlanYear: completedPlanYear => set({ completedPlanYear }),
    }),
    {
      name: "shopping-success-store",
      version: 0,
    }
  )
)
