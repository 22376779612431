import { DefaultError, QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { useEffect, useState } from "react"

const stringify = (obj: QueryKey) => JSON.stringify(obj)
export const useDebouncedQuery = <
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>({
  queryKey,
  queryFn,
  debounce,
  ...options
}: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
  queryFn: () => Promise<TData>
  debounce: number
}) => {
  const [delayedKey, setDelayedKey] = useState<TQueryKey>(queryKey)

  useEffect(() => {
    if (stringify(queryKey) !== stringify(delayedKey)) {
      const timerId = setTimeout(() => setDelayedKey(queryKey), debounce)

      return () => clearTimeout(timerId)
    }
  }, [debounce, delayedKey, queryKey])

  return useQuery({ queryKey: delayedKey, queryFn, ...options })
}
