import { ConfirmationModal, ConfirmationModalProps } from "@/components/ConfirmationModal"
import { deferPromise } from "@/utils/util"
import { ReactNode } from "react"
import { create } from "zustand"

type ModalProps = Omit<ConfirmationModalProps, "onConfirm" | "isSubmitting" | "isOpen" | "onClose" | "isCancelable">
interface ConfirmationState {
  props: ConfirmationModalProps | null
}

interface ConfirmationMethods {
  confirm: (props: ModalProps) => Promise<void>
}

type ConfirmationStore = ConfirmationState & ConfirmationMethods

const useConfirmationStore = create<ConfirmationStore>()((set, get) => {
  const close = () => set({ props: null })

  return {
    props: null,

    confirm: async props => {
      const deferred = deferPromise()

      set(() => ({
        props: {
          ...props,
          isOpen: true,
          isCancelable: true,

          onConfirm: () => {
            close()
            deferred.resolve?.()
          },

          onClose: () => {
            close()
            deferred.reject?.()
          },
        },
      }))

      await deferred.promise
    },
  }
})

export const useConfirmation = (props: ModalProps) => {
  const confirm = useConfirmationStore(state => state.confirm)

  return {
    confirmAction: async (extraProps: Partial<ModalProps> = {}) => {
      await confirm({ ...props, ...extraProps })
    },
  }
}

interface ConfirmationProviderProps {
  children: ReactNode
}

export const ConfirmationProvider = ({ children }: ConfirmationProviderProps) => {
  const props = useConfirmationStore(state => state.props)

  return (
    <>
      {children}
      {props && <ConfirmationModal {...props} />}
    </>
  )
}
