import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import {
  Actions,
  ActionsMap,
  CreateCompanyDataState,
  createCompanyReducer,
} from "@/features/CreateCompany/createCompanyReducer"
import { noop } from "lodash"
import { createContext, ReactNode, useCallback, useContext, useMemo, useReducer } from "react"
import { ICHRA_HRA_TYPE } from "../Documents/documentsConstants"
import { HraPlanModel } from "./components/Steps/Setup/PlanSetup/planSetupTypes"
import { ANNUALLY } from "./createCompanyConstants"

export type Dispatcher = <Type extends Actions["type"], Payload extends ActionsMap[Type]>(
  type: Type,
  ...payload: Payload extends undefined ? [undefined?] : [Payload]
) => void

type CreateCompanyContextInterface = readonly [CreateCompanyDataState, Dispatcher]

const startingCompany: CompanyModel = {
  id: "",
  companyInfo: {
    companyName: "",
    companyType: "",
    industry: "",
    ein: "",
    isALE: null,
    isAssisted: null,
  },
  companyAddress: {
    streetAddress: "",
    suiteApt: "",
    city: "",
    state: "",
    zip: "",
  },
  benefitsAdministrator: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
} as never

const startingHraPlan: HraPlanModel = {
  id: "",
  companyId: "",
  hraStartDate: null,
  hraEndDate: null,
  statementFrequency: "",
  statementCreationDate: 1,
  dayOfMonth: 1,
  complianceFrequency: ANNUALLY,
  hraType: ICHRA_HRA_TYPE,
  willKeepGroupPlan: false,
  willOfferHRA: false,
  partTimeHourQualification: 1,
  seasonalMonthQualification: 1,
  eligibleEmployees: "",
  participatingEmployees: "",
  isAutoPay: false,
  packageName: "",
} as never

export const CreateCompanyContext = createContext<CreateCompanyContextInterface>([
  { isInitialized: false, company: startingCompany, hraPlan: startingHraPlan },
  noop,
])

export interface CreateCompanyProviderProps {
  children: ReactNode
}
export const CreateCompanyProvider = ({ children }: CreateCompanyProviderProps) => {
  const [state, _dispatch] = useReducer(createCompanyReducer, {
    company: startingCompany,
    hraPlan: startingHraPlan,
    isInitialized: false,
  })

  const dispatch: Dispatcher = useCallback((type, ...payload) => {
    _dispatch({ type, payload: payload[0] } as Actions)
  }, [])

  const createCompanyProviderValue: CreateCompanyContextInterface = useMemo(() => [state, dispatch], [state, dispatch])

  return <CreateCompanyContext.Provider value={createCompanyProviderValue}>{children}</CreateCompanyContext.Provider>
}

export const useCreateCompanyAccount = () => {
  const [{ company }, dispatch] = useContext(CreateCompanyContext)

  const setCompanyAcount = useCallback(
    (companyPayload: CompanyModel) => dispatch("account", companyPayload),
    // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return {
    company,
    setCompanyAcount,
  }
}

export const useCreateCompanyHraPlan = () => {
  const [{ hraPlan }, dispatch] = useContext(CreateCompanyContext)

  const setCompanyHraPlan = useCallback(
    (hraPlanPayload: HraPlanModel) => dispatch("planSetup", hraPlanPayload),
    // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return {
    hraPlan,
    setCompanyHraPlan,
  }
}

export const useInitializeCreateCompany = () => {
  const [{ isInitialized }, dispatch] = useContext(CreateCompanyContext)

  const setInitialCreateCompanyAccount = useCallback(
    (initalize: boolean) => dispatch("initalize", initalize),
    // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return {
    isInitialized,
    setInitialCreateCompanyAccount,
  }
}
