import { FilterOptionsState } from "@mui/material/useAutocomplete"
import Fuse, { IFuseOptions } from "fuse.js"
import { memoize } from "lodash"

export const createFuseFilterOptions = <Value>(
  fuseOptions: IFuseOptions<Value> & { limit?: number }
): ((options: Value[], state: FilterOptionsState<Value>) => Value[]) =>
  memoize((options, { inputValue }) => {
    const fuse = new Fuse(options, fuseOptions)
    const input = inputValue
    const filteredOptions = !input ? options : fuse.search(input).map(({ item }) => item)
    return fuseOptions.limit ? filteredOptions.slice(0, fuseOptions.limit) : filteredOptions
  })
