import { DataQa } from "@/utils/dataQa"
import { KeyboardArrowLeftOutlined } from "@mui/icons-material"
import { Button, ButtonProps, CircularProgress } from "@mui/material"
import { ReactNode } from "react"

interface ReturnButtonProps {
  "data-qa": DataQa
  text: string
  handleClose: () => void
}

export const ReturnButton = ({ "data-qa": dataQa, text, handleClose }: ReturnButtonProps) => (
  <Button
    variant="text"
    startIcon={<KeyboardArrowLeftOutlined />}
    onClick={handleClose}
    sx={{ color: "colors.darkBody" }}
    data-qa={dataQa}
  >
    Back to {text}
  </Button>
)

interface AsyncButtonProps extends ButtonProps {
  isLoading: boolean
  children: ReactNode
}

// FUTURE: Replace other loading buttons with this one
export const AsyncButton = ({ isLoading, children, disabled, ...props }: AsyncButtonProps) => (
  <Button disabled={isLoading || disabled} endIcon={isLoading && <CircularProgress size="1rem" />} {...props}>
    {children}
  </Button>
)
