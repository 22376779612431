import { SearchField, SearchFieldWithChips } from "@/components/SearchFields"
import { getCarriers } from "@/features/BenefitsElection/benefitsElectionEndpoints"
import { useShoppingSession } from "@/features/BenefitsElection/benefitsElectionService"
import { Carrier, MyInfoSearchProps } from "@/features/BenefitsElection/benefitsElectionTypes"
import { BenefitsElectionStep } from "@/features/BenefitsElection/components/BenefitsElectionStep"
import { takeCommandPrimary } from "@/theme/palette"

export type CarriersSearchProps = MyInfoSearchProps<Carrier, string> & { stateCode: string }

export const CarriersSearchFieldWithChips = ({ stateCode, ...props }: CarriersSearchProps) => (
  <SearchFieldWithChips<Carrier, string>
    title="Carriers"
    name="carriers"
    placeholder="Search by carrier"
    handleSearch={searchTerm => getCarriers(searchTerm, stateCode)}
    formatOption={carrier => carrier.name}
    formatSelection={carrier => ({
      key: carrier.id,
      label: carrier.name,
    })}
    minimumInputLength={3}
    {...props}
    sx={{ backgroundColor: takeCommandPrimary[200] }}
  />
)

export const CarriersSearchField = ({ stateCode, ...props }: CarriersSearchProps) => (
  <SearchField
    title="Carriers"
    name="carriers"
    placeholder="Search by carrier"
    handleSearch={searchTerm => getCarriers(searchTerm, stateCode)}
    formatOption={carrier => carrier.name}
    minimumInputLength={3}
    {...props}
  />
)

export const Carriers = () => {
  const shoppingSessionId = useShoppingSession()
  const next = `/benefits-election/${shoppingSessionId}/find-plan/recommended-plans`
  const previous = `/benefits-election/${shoppingSessionId}/find-plan/questions/plan-type`

  return (
    <BenefitsElectionStep title="Carriers" next={next} previous={previous} advanceOnSuccess>
      {/* FUTURE */}
    </BenefitsElectionStep>
  )
}
