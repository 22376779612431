import { Button, Grid } from "@mui/material"

interface QuizBackButtonProps {
  handleBack: () => void
}

export const QuizBackButton = ({ handleBack }: QuizBackButtonProps) => (
  <Grid item>
    <Button data-qa="back-button" onClick={handleBack} variant="outlined" color="primary">
      Back
    </Button>
  </Grid>
)
