import { SearchFieldWithChips } from "@/components/SearchFields"
import { useNotifications } from "@/services/notificationService"
import { takeCommandPrimary } from "@/theme/palette"
import { Grid } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { FAMILY, HOSPITALS, PRESCRIPTIONS } from "../../benefitsElectionConstants"
import { searchDrugs } from "../../benefitsElectionEndpoints"
import { useMyInfo, useShoppingUrl } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { DrugId, DrugPreference, MyInfoSearchProps } from "../../benefitsElectionTypes"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"

export type DrugsSearchProps = MyInfoSearchProps<DrugPreference, DrugId>

export const DrugsSearchField = ({ planYear, zipCode, radius, ...props }: DrugsSearchProps) => (
  <SearchFieldWithChips<DrugPreference, DrugId>
    title="Medication and prescriptions"
    name="drugs"
    placeholder="Search for medication and prescriptions"
    handleSearch={searchTerm => searchDrugs(searchTerm, true)}
    helperText="*You can check any health plan's drug formulary to see which medications are covered in different tiers."
    formatOption={option => option.description}
    formatSelection={({ description, id }) => ({
      key: id,
      label: description,
    })}
    minimumInputLength={3}
    {...props}
    sx={{ backgroundColor: takeCommandPrimary[200] }}
  />
)

export const Prescriptions = () => {
  const { notify } = useNotifications("add-drugs")
  const { prescriptions, addPrescription, removePrescription, planSearchParams } = useMyInfo()
  const shoppingUrl = useShoppingUrl()
  const navigate = useNavigate()
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const hasSelectedPrescriptions = prescriptions.length > 0
  const previous = shoppingUrl + HOSPITALS
  const next = shoppingUrl + FAMILY

  const setNextStep = () => {
    if (currentStep === PRESCRIPTIONS) {
      setCurrentStep(FAMILY)
    }
  }

  return (
    <BenefitsElectionStep
      title="Prescriptions"
      description="If you take ongoing, routine medications you can add them here in order to see their level of coverage in health plans. If there are none, please skip."
      disabled={!hasSelectedPrescriptions}
      previous={previous}
      next={next}
      handleContinue={() => {
        // FUTURE: Show appropriate notification based on API response
        notify(`Successfully added ${prescriptions.length} drugs`, "success")
        setNextStep()
        navigate(next)
      }}
      handleSkip={() => {
        setNextStep()
        navigate(next)
      }}
    >
      <Grid item xs={12} sx={{ mt: 4 }}>
        <DrugsSearchField
          selections={prescriptions}
          handleSelection={addPrescription}
          handleDelete={removePrescription}
          {...planSearchParams}
        />
      </Grid>
    </BenefitsElectionStep>
  )
}
