import { ReactNode } from "react"
import { Navigate } from "react-router-dom"
import { useAuth } from "../useAuth"

interface GuestGuardProps {
  children: ReactNode
}

// For routes that can only be accessed by unauthenticated users
export const GuestGuard = ({ children }: GuestGuardProps) => {
  const { isAuthenticated, isInitialized } = useAuth()

  if (!isInitialized) {
    return null
  }

  if (isInitialized && isAuthenticated) {
    return <Navigate to="/" />
  }

  return children
}
