import { useAuth } from "@/features/Auth/useAuth"
import { PAYROLL_REPORT } from "@/features/Documents/documentsConstants"
import { useState } from "react"
import { Reports } from "../reportsTypes"
import { PayrollReportModal } from "./PayrollReportModal"
import { ReportsCard } from "./ReportsCard"

export const PayrollReportCard = ({ name, description, buttonText, reportType }: Reports) => {
  const [showPayrollReportModal, setShowPayrollReportModal] = useState(false)
  const { user } = useAuth()
  const companyId = user?.company?.companyId ?? ""
  const currentCompanyHraPlanStartDate = user?.companyHRAPlan?.[0]?.hraStartDate?.toString()

  const handleButtonClick = () => {
    setShowPayrollReportModal(true)
  }
  const handleClose = () => {
    setShowPayrollReportModal(false)
  }
  return (
    <>
      <ReportsCard
        name={name}
        description={description}
        buttonText={buttonText}
        handleButtonClick={handleButtonClick}
        isButtonDisabled={!currentCompanyHraPlanStartDate}
      />
      <PayrollReportModal
        onClose={handleClose}
        open={showPayrollReportModal}
        companyId={companyId}
        currentCompanyHraPlanStartDate={currentCompanyHraPlanStartDate!}
        reportType={reportType ?? PAYROLL_REPORT}
      />
    </>
  )
}
