import { ConfirmationModal, ModalConfig } from "@/components/ConfirmationModal"
import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { BaseTable, OrderByItemType } from "@/components/Table/Table"
import { SEARCH_THRESHOLD } from "@/constants"
import { toTitleCase } from "@/utils/formatting"
import styled from "@emotion/styled"
import { Avatar, TableCell } from "@mui/material"
import { Box } from "@mui/system"
import Fuse from "fuse.js"
import { Dispatch, SetStateAction, useState } from "react"
import { useNavigate } from "react-router-dom"
import { STATUSES_TO_HIDE } from "../peopleConstants"
import { Person, PersonModel } from "../peopleTypes"
import { ChipPersonStatus } from "./ChipPersonStatus"
import { PeopleOptionsDropdown } from "./PeopleOptionsDropdown"

const defaultOrder = { headCellId: "name", direction: "asc" } as const

const roleSelector = (person: PersonModel) => person.roles?.map(role => role.name).join(", ")

const Customer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export interface PeopleTableProps {
  searchCriteria: string
  statusCriteria: string[]
  hideSendSignUpLink?: boolean
  hideCopySignUpLink?: boolean
  hideWaive?: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  setPersonProfileId: Dispatch<SetStateAction<string>>
  people: Person[]
  isLoading: boolean
  classField?: (row: PersonModel) => string
  companyField?: (row: PersonModel) => string
  isTchubPage: boolean
}

export const PeopleTable = ({
  searchCriteria,
  statusCriteria,
  hideSendSignUpLink,
  hideCopySignUpLink,
  hideWaive,
  setOpen,
  setPersonProfileId,
  people,
  isLoading,
  classField,
  companyField,
  isTchubPage,
}: PeopleTableProps) => {
  const [page, setPage] = useState(0)
  const initialRowsPerPage = isTchubPage ? 25 : 10
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage)
  const [selected, setSelected] = useState<Person[]>([])
  const [orderBy, setOrderBy] = useState<OrderByItemType[]>([defaultOrder])
  const [modal, setModal] = useState<ModalConfig>(null)
  const [isModalSubmitting, setIsModalSubmitting] = useState(false)
  const navigate = useNavigate()

  const fuseOptions = {
    keys: ["firstName", "lastName", "email"],
    includeMatches: true,
    threshold: SEARCH_THRESHOLD,
  }

  const fuseOptionsStatus = {
    keys: ["status"],
    includeMatches: true,
    threshold: SEARCH_THRESHOLD,
  }

  const fuse = new Fuse(people, fuseOptions)
  let fuseSearchResults: Person[] = []

  if (searchCriteria) {
    fuseSearchResults = fuse.search(searchCriteria).map(result => result.item)
  }

  if (statusCriteria.length > 0) {
    const initialDataForFilter = fuseSearchResults?.length > 0 ? fuseSearchResults : people
    const fuseStatus = new Fuse(initialDataForFilter, fuseOptionsStatus)

    fuseSearchResults = fuseStatus
      .search({
        $or: statusCriteria.map(criteria => ({
          status: criteria,
        })),
      })
      .map(result => result.item)
  }

  let filteredPeople: Person[] = []

  if (searchCriteria.length === 0 && statusCriteria.length === 0) {
    filteredPeople = people
  } else {
    filteredPeople = fuseSearchResults
  }

  return (
    <>
      {isLoading ? (
        <SkeletonTableLoader
          data-qa="skeleton-table-loader-people"
          headerTitles={[
            "Name/Email",
            "Status",
            ...(!!classField ? ["Class"] : []),
            "Role",
            ...(isTchubPage ? ["Company Name"] : []),
            "Actions",
          ]}
          bodyRowsCount={10}
        />
      ) : (
        <BaseTable
          rows={filteredPeople}
          selected={selected}
          orderBy={orderBy}
          onToggleOrderBy={headId =>
            setOrderBy(state => {
              const result = [] as OrderByItemType[]

              if (state && state.length > 0 && state[0].headCellId === headId) {
                result.push({
                  headCellId: headId,
                  direction: state[0].direction === "asc" ? "desc" : "asc",
                })
              } else {
                result.push({ headCellId: headId, direction: "asc" })
              }
              if (defaultOrder.headCellId !== headId) {
                result.push(defaultOrder)
              }

              return result
            })
          }
          onToggleSelect={selection =>
            setSelected(state => {
              if (state.some(r => r.id === selection.id)) {
                return state.filter(r => r.id !== selection.id)
              }

              return [...state, selection]
            })
          }
          onToggleSelectAll={() => {
            setSelected(state => {
              if (state.length > 0) {
                return []
              } else {
                return [...people]
              }
            })
          }}
          uniqueIdSelector={row => row.id.toString()}
          headCells={[
            {
              id: "name",
              label: "Name/Email",
              field: (row: PersonModel) => toTitleCase(row.firstName) + toTitleCase(row.lastName) + row.email,
              sortable: true,
            },
            { id: "status", label: "Status", sortable: true },
            ...(!!classField
              ? [
                  {
                    id: "class",
                    label: "Class",
                    field: classField,
                    sortable: true,
                  },
                ]
              : []),
            {
              id: "role",
              label: "Role",
              field: (row: PersonModel) => row.roles?.map(role => role.name).join(", "),
              sortable: true,
            },
            ...(isTchubPage
              ? [
                  {
                    id: "company",
                    label: "Company Name",
                    field: (row: PersonModel) => row.companyName,
                    sortable: true,
                  },
                ]
              : []),
            { id: "actions", label: "", sortable: false },
          ].filter(Boolean)}
          onPageChange={changedPage => setPage(changedPage)}
          onRowsPerPageChange={rows => setRowsPerPage(rows)}
          page={page}
          rowsPerPage={rowsPerPage}
        >
          {({ row, isSelected, toggleSelect, labelId, rowId }) => {
            const shouldHideWaive = STATUSES_TO_HIDE.includes(row.status)
            return (
              <>
                <TableCell component="th" id={labelId} scope="row" data-qa="person">
                  <Customer
                    onClick={() => {
                      if (!isTchubPage) {
                        setPersonProfileId(String(row.id))
                        setOpen(true)
                      } else {
                        navigate(`${row.id}/${row.employmentId}/${row.companyId}`)
                      }
                    }}
                  >
                    <Avatar>
                      {row.firstName.charAt(0).toUpperCase()}
                      {row.lastName.charAt(0).toUpperCase()}
                    </Avatar>
                    <Box ml={3}>
                      {row.firstName} {row.lastName}
                      <br />
                      {row.email}
                    </Box>
                  </Customer>
                </TableCell>
                <TableCell data-qa="status-chip">
                  <ChipPersonStatus status={row.status} />
                </TableCell>
                {classField && <TableCell data-qa="class">{classField(row)}</TableCell>}
                <TableCell data-qa="role">{roleSelector(row)}</TableCell>
                {isTchubPage && <TableCell data-qa="company">{row.companyName}</TableCell>}
                <TableCell>
                  <PeopleOptionsDropdown
                    personId={String(row.id)}
                    employmentId={row.employmentId}
                    status={row.status}
                    setModal={setModal}
                    setIsModalSubmitting={setIsModalSubmitting}
                    setOpen={setOpen}
                    setPersonProfileId={setPersonProfileId}
                    hideSendSignUpLink={hideSendSignUpLink}
                    hideCopySignUpLink={hideCopySignUpLink}
                    hideWaive={hideWaive || shouldHideWaive}
                    companyId={row.companyId}
                  />
                </TableCell>
              </>
            )
          }}
        </BaseTable>
      )}

      {modal && (
        <ConfirmationModal
          isOpen={!!modal}
          {...modal}
          onClose={() => {
            setModal(null)
          }}
          isSubmitting={isModalSubmitting}
        />
      )}
    </>
  )
}
