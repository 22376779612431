import { useMutation, useQuery } from "@tanstack/react-query"
import {
  acknowledgeDocument,
  createDocument,
  getCompanyDocDownloadUrl,
  getCompanyDocuments,
  getEmployeeDocDownloadUrl,
  getEmployeeDocuments,
} from "./documentsEndpoints"
import { AcknowledgeDocumentRequest, CreateDocumentRequest } from "./documentsTypes"

export const useCompanyDocuments = (companyId: string, isAdmin: boolean, documentType?: string) =>
  useQuery({
    queryKey: ["adminDocuments", companyId, documentType],
    queryFn: async () => {
      const data = await getCompanyDocuments(companyId, documentType)
      return data.documents
    },
    enabled: isAdmin && !!companyId,
  })

export const useEmployeeDocuments = (companyId: string, employeeId: string, isEmployee: boolean) =>
  useQuery({
    queryKey: ["employeeDocuments", companyId, employeeId],
    queryFn: async () => (await getEmployeeDocuments(companyId, employeeId)).documents,
    enabled: isEmployee && !!companyId && !!employeeId,
  })

export const useCompanyDocDownloadUrl = (
  companyId: string,
  documentId: string | undefined,
  isAdmin: boolean,
  isEmployee: boolean
) =>
  useQuery({
    queryKey: ["companyDocumentDownloadUrl", companyId, documentId],
    queryFn: async () => {
      const data = await getCompanyDocDownloadUrl(companyId, documentId)

      return data.url
    },
    enabled: (isAdmin || isEmployee) && !!companyId && !!documentId,
    gcTime: 0,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

export const useEmployeeDocDownloadUrl = (
  companyId: string,
  employeeId: string,
  documentId: string | undefined,
  isEmployee: boolean
) =>
  useQuery({
    queryKey: ["employeeDocumentDownloadUrl", companyId, employeeId, documentId],
    queryFn: async () => {
      const data = await getEmployeeDocDownloadUrl(companyId, employeeId, documentId)

      return data.url
    },
    enabled: isEmployee && !!companyId && !!employeeId && !!documentId,
    gcTime: 0,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

export const useFetchFileFromUrl = (url: string | undefined, name: string | undefined) =>
  useQuery({
    queryKey: ["fetch-file", url],
    queryFn: async () => {
      const response = await fetch(url!)
      const data = await response.blob()

      return new File([data], name!, {
        type: data.type,
      })
    },
    enabled: !!url && !!name,
    refetchOnWindowFocus: false,
  })

export const useCreateDocument = (companyId: string) =>
  useMutation({
    mutationKey: ["createEmployeeDocument", companyId],
    mutationFn: async (document: CreateDocumentRequest) => createDocument(companyId, document),
  })

export const useCreateAndExecuteServiceAgreement = () =>
  useMutation({
    mutationFn: ({ userInfo, documentId }: AcknowledgeDocumentRequest) => acknowledgeDocument(userInfo, documentId),
  })
