import { Uuid } from "@/utils/types"
import { useMutation, useQuery } from "@tanstack/react-query"
import { getEnrollmentTimePeriods, resetShoppingSession } from "../BenefitsElection/benefitsElectionEndpoints"
import { EnrollmentTimePeriodId } from "../BenefitsElection/benefitsElectionTypes"
import {
  checkQleAvailability,
  createQle,
  getAutoPayCutOffDate,
  getDashboardWidgetData,
  getEnrollmentReportCsv,
} from "./dashboardEndpoints"
import { QlePayload } from "./dashboardTypes"

export const useGetDashboardWidgetData = (companyId: string) =>
  useQuery({
    queryKey: ["dashboardWidgetData", companyId],
    queryFn: () => getDashboardWidgetData(companyId),
    refetchIntervalInBackground: true,
    refetchInterval: 300000,
  })

export const useGetEnrollmentReportCsv = (companyId: string) =>
  useQuery({
    queryKey: ["enrollmentReportCsv", companyId],
    queryFn: () => getEnrollmentReportCsv(companyId),
    // ensure the user needs to click the button to get the latest data
    enabled: false,
  })

export const useGetEnrollmentTimePeriodsValue = (employeeId?: string) =>
  useQuery({
    queryKey: ["enrollmentTimePeriods", employeeId],
    queryFn: () => getEnrollmentTimePeriods(employeeId!),
    refetchOnWindowFocus: false,
    enabled: !!employeeId,
  })

export const useCreateQle = () =>
  useMutation({
    mutationFn: (payload: QlePayload) => createQle(payload),
  })

export const useCheckQleAvailability = (employeeId: string) =>
  useQuery({
    queryKey: ["qleAvailability", employeeId],
    queryFn: () => checkQleAvailability(employeeId),
    refetchOnWindowFocus: true,
  })

export const useGetAutoPayCutOffDate = (companyId: string, planId: string) =>
  useQuery({
    queryKey: ["autoPayCutOffDate", companyId, planId],
    queryFn: () => getAutoPayCutOffDate(companyId, planId),
  })

export const useResetShoppingSession = (employmentId: Uuid) =>
  useMutation({
    mutationFn: (values: EnrollmentTimePeriodId) => resetShoppingSession(employmentId, values),
  })
