import { RouteObject } from "react-router-dom"
import { EmployerOnboardingLayout } from "./features/EmployerOnboarding/components/EmployerOnboardingLayout"
import {
  AUTOPAY,
  EMPLOYEES,
  HRA_DOCUMENTS,
  INVITATIONS,
  SUBSCRIPTION_BILLING,
  SUCCESS,
} from "./features/EmployerOnboarding/employerOnboardingConstants"
import { AutoPay } from "./features/EmployerOnboarding/pages/AutoPay"
import { Checklist } from "./features/EmployerOnboarding/pages/Checklist"
import { Employees } from "./features/EmployerOnboarding/pages/Employees"
import { HraDocuments } from "./features/EmployerOnboarding/pages/HRADocuments"
import { Invitations } from "./features/EmployerOnboarding/pages/Invitations"
import { OnboardingSuccess } from "./features/EmployerOnboarding/pages/OnboardingSuccess"
import { SubscriptionBilling } from "./features/EmployerOnboarding/pages/SubscriptionBilling"

export const employerOnboardingRoutes: RouteObject = {
  path: "/employer-onboarding/",
  element: <EmployerOnboardingLayout />,
  children: [
    {
      path: "",
      element: <Checklist />,
    },
    {
      path: HRA_DOCUMENTS,
      element: <HraDocuments />,
    },
    {
      path: EMPLOYEES,
      element: <Employees />,
    },
    {
      path: AUTOPAY,
      element: <AutoPay />,
    },
    {
      path: INVITATIONS,
      element: <Invitations />,
    },
    {
      path: SUBSCRIPTION_BILLING,
      element: <SubscriptionBilling />,
    },
    {
      path: SUCCESS,
      element: <OnboardingSuccess />,
    },
  ],
}
