import { CSVBoxButton } from "@csvbox/react"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import { Box, Button } from "@mui/material"

interface CSVBoxButtonProps {
  title: string
  userId: string
  companyId: string
  "data-qa"?: string
  licenseKey: string
  planId?: string
  token?: string
  onClose: () => void
}

export const UploadCsvFile = ({
  title,
  userId,
  companyId,
  "data-qa": dataQaLocator,
  licenseKey,
  planId,
  token,
  onClose,
}: CSVBoxButtonProps) => (
  <Box>
    <CSVBoxButton
      licenseKey={licenseKey}
      onClose={onClose}
      user={{ user_id: userId, company_id: companyId, plan_id: planId, token }}
      onImport={(result, data) => {
        if (result) {
          console.info("Import success")
        } else {
          console.error("Import failed")
        }
      }}
      render={(launch, isLoading) => {
        const isDisabled = dataQaLocator === "upload-csv" ? isLoading : planId === undefined

        return (
          <Button
            data-qa={dataQaLocator}
            variant="contained"
            onClick={launch}
            startIcon={<FileUploadIcon />}
            disabled={isDisabled}
          >
            {title}
          </Button>
        )
      }}
    />
  </Box>
)
