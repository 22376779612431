import { StyledCard } from "@/components/StyledCard"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { HrisConnectButton } from "@/features/Hris/HrisConnectButton"
import { useHrisConfig, useHrisLink } from "@/features/Hris/hrisService"
import { HrisStatusChip } from "@/features/Hris/HrisStatusChip"
import { HRIS_STATUS_ERROR, HRIS_STATUS_NOT_STARTED } from "@/features/Hris/hrisTypes"
import { useNotifications } from "@/services/notificationService"
import { Grid, Skeleton, Typography } from "@mui/material"

export const CompanyHris = () => {
  const { notify } = useNotifications("hris")
  const { user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId || ""
  const { data: hrisConfig, isLoading: isConfigLoading } = useHrisConfig(companyId)
  const hrisLink = useHrisLink()
  const isLoading = isConfigLoading || hrisLink.isPending
  const isError = hrisConfig?.status === HRIS_STATUS_ERROR

  return (
    <StyledCard>
      <Grid container direction="column" spacing={5}>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h6" data-qa="hris-details-title">
                HRIS
              </Typography>
            </Grid>
            <Grid item>
              <HrisStatusChip dataQa="hris-status-chip" hrisStatus={hrisConfig?.status ?? HRIS_STATUS_NOT_STARTED} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" data-qa="hris-details-description">
            Connecting will allow your to integrate your roster from your HR provider to your company's Take Command
            account.
          </Typography>
          {isError && (
            <Typography variant="body1" data-qa="hris-details-error">
              There is a problem with the connection. Please reconnect.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {hrisConfig ? (
            <HrisConnectButton
              isLoading={isLoading}
              hrisConfig={hrisConfig}
              onPublicToken={async publicToken => {
                await hrisLink.mutateAsync({
                  companyId,
                  publicToken,
                })
                notify(
                  "We're currently importing your data. We will send you a notification and email once it is complete.",
                  "info"
                )
              }}
            />
          ) : (
            <Skeleton variant="rectangular" height={40} width={200} />
          )}
        </Grid>
      </Grid>
    </StyledCard>
  )
}
