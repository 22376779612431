import { Grid, Typography } from "@mui/material"
import { Helmet } from "react-helmet-async"

export const TcHubIssuesPage = () => (
  <Grid container data-qa="tc-hub-issues-page">
    <Helmet title="TC Hub Issues" />
    <Typography variant="h1" gutterBottom display="inline" data-qa="issues">
      Issues
    </Typography>
  </Grid>
)
