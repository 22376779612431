import { SelectField } from "@/components/SelectField"
import { StyledCard } from "@/components/StyledCard"
import { EXTERNAL_LINKS } from "@/constants"
import { DataQa } from "@/utils/dataQa"
import { AddShoppingCart } from "@mui/icons-material"
import { Button, Grid, Link as MuiLink, Typography } from "@mui/material"
import { Formik } from "formik"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import {
  INDIVIDUAL_HEALTH_INSURANCE,
  PRIMARY_INSURANCE_TYPES,
  PRIMARY_INSURANCE_TYPE_OPTIONS,
  RECOMMENDED_PLANS,
  RECURRING_REIMBURSEMENT,
  SELF_ENROLLED_PLAN,
} from "../../benefitsElectionConstants"
import { useMyInfo, useShoppingUrl } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { Carrier, HealthBenefitsElection, PrimaryInsuranceType } from "../../benefitsElectionTypes"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"
import { CarriersSearchField } from "../findMyPlan/questions/Carriers"

interface ShopForPlanCardProps {
  planYear: number
  planPage: string
}

const ShopForPlanCard = ({ planYear, planPage }: ShopForPlanCardProps) => (
  <StyledCard>
    <Typography variant="body1bold">Need to shop for a {planYear} health plan?</Typography>
    <Typography mt={1}>
      Shop for a {planYear} health plan using Take Command and we’ll handle the enrollment and payment process for you!
    </Typography>
    <Button
      variant="outlined"
      sx={{ mt: 4, color: "colors.darkBody" }}
      startIcon={<AddShoppingCart />}
      component={Link}
      to={planPage}
    >
      Shop for a plan
    </Button>
  </StyledCard>
)

interface SelfEnrolledPlanFormValues {
  healthInsuranceType: PrimaryInsuranceType
  carrier: Carrier | null
}

const dataQa = "self-enrolled-plan" as DataQa

export const selfEnrolledPlanValidationSchema = Yup.object().shape({
  healthInsuranceType: Yup.string().oneOf(PRIMARY_INSURANCE_TYPES).required("Health insurance type is required"),
})

export const SelfEnrolledPlan = () => {
  const shoppingUrl = useShoppingUrl()

  const currentShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const setHealthBenefitElection = useBenefitsElectionStore(state => state.setHealthBenefitElection)
  const currentHealthBenefitElection = currentShoppingSession.healthBenefitElections?.[0]
  const planYear = currentShoppingSession.planYear

  const { planSearchParams } = useMyInfo()

  const recommendedPlansPage = shoppingUrl + RECOMMENDED_PLANS
  const previous = shoppingUrl + RECOMMENDED_PLANS
  const next = shoppingUrl + RECURRING_REIMBURSEMENT

  const setNextStep = () => {
    if (currentStep === SELF_ENROLLED_PLAN) {
      setCurrentStep(RECURRING_REIMBURSEMENT)
    }
  }

  const getFormInitialValues = () => {
    const { insuranceType, carrierId, carrierName } = currentHealthBenefitElection
    const hasCarrier = Boolean(carrierId && carrierName)
    const formInitialValues = {
      healthInsuranceType: insuranceType ?? "",
      carrier: hasCarrier ? ({ id: carrierId, name: carrierName } as Carrier) : null,
    }

    return formInitialValues
  }

  return (
    <Formik
      initialValues={getFormInitialValues()}
      validateOnMount
      onSubmit={async values => {}}
      enableReinitialize
      validationSchema={selfEnrolledPlanValidationSchema}
    >
      {({ handleBlur, handleSubmit, values, isValid, touched, setFieldValue, setFieldTouched }) => {
        const doesNotHaveCarrier = values.healthInsuranceType === INDIVIDUAL_HEALTH_INSURANCE && !values.carrier

        return (
          <BenefitsElectionStep
            title="Existing plan purchased elsewhere"
            description={
              <Typography>
                Please share the details of your existing health plan below. This will ensure that you can receive your
                monthly allowance from your employer.
              </Typography>
            }
            disabled={!isValid || doesNotHaveCarrier}
            previous={previous}
            next={next}
            advanceOnSuccess
            data-qa={dataQa}
            required
            errorMessage="Error saving your plan information. Please try again later. If the issue persists, contact support for assistance."
            handleContinue={async () => {
              const castedValues = values as unknown as SelfEnrolledPlanFormValues

              const updatedBenefitElection: HealthBenefitsElection = {
                ...currentHealthBenefitElection,
                insuranceType: castedValues.healthInsuranceType,
              }

              if (castedValues.carrier) {
                updatedBenefitElection.carrierId = castedValues.carrier.id
                updatedBenefitElection.carrierName = castedValues.carrier.name
              }

              setHealthBenefitElection(updatedBenefitElection)
              setNextStep()
            }}
          >
            <form noValidate onSubmit={handleSubmit} data-qa={`${dataQa}-form`} style={{ width: "100%" }}>
              <Grid container spacing={4} width="100%">
                <Grid item xs={12} mt={8}>
                  <Typography variant="h6" data-qa={`${dataQa}-health-insurance-label`}>
                    What is your type of health insurance?
                  </Typography>
                </Grid>

                <Grid item xs={12} md={8}>
                  <SelectField
                    data={PRIMARY_INSURANCE_TYPE_OPTIONS}
                    type="text"
                    name="healthInsuranceType"
                    value={values.healthInsuranceType}
                    placeholder="Select an Option"
                    dataQa={`${dataQa}-health-insurance-type`}
                    onBlur={handleBlur}
                    handleChange={() => setFieldValue("carrier", null)}
                  />
                </Grid>

                {values.healthInsuranceType === INDIVIDUAL_HEALTH_INSURANCE && (
                  <Grid item xs={12} md={8} mt={4}>
                    <CarriersSearchField
                      selections={[]}
                      handleChange={carrier => {
                        setFieldValue("carrier", carrier)
                      }}
                      title="Who is your health insurance carrier?"
                      placeholder="Enter your health insurance carrier"
                      clearValueOnSelection={false}
                      {...planSearchParams}
                      stateCode={planSearchParams.state}
                      error={doesNotHaveCarrier && touched.carrier ? "error" : undefined}
                      helperText={
                        doesNotHaveCarrier && touched.carrier ? "Health insurance carrier is required" : undefined
                      }
                      handleBlur={() => setFieldTouched("carrier", true)}
                      value={values.carrier}
                      noResultsText={
                        <Typography>
                          The carrier you entered has not been found in our database. Please call or chat with our{" "}
                          <MuiLink href={EXTERNAL_LINKS.SUPPORT} target="_blank" rel="noopener noreferrer">
                            Support team
                          </MuiLink>{" "}
                          to resolve this issue. Not all individual plans are eligible for coverage based on the
                          Affordable Care Act.
                        </Typography>
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} mb={4}>
                  <ShopForPlanCard planYear={planYear} planPage={recommendedPlansPage} />
                </Grid>
              </Grid>
            </form>
          </BenefitsElectionStep>
        )
      }}
    </Formik>
  )
}
