import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type CurrentQuestion =
  | "NUM_FT_EE"
  | "HAVE_GROUP_PLAN"
  | "KEEP_GROUP_PLAN"
  | "MONTHLY_ALLOWANCE"
  | "NON_INDIVIDUAL_PLAN_REIMBURSEMENT"
  | "LOCATION"
  | "VARIED_ALLOWANCES"
export type HRAResult = "QSEHRA" | "ICHRA_DIY" | "ICHRA_NON_DIY"

interface PreScreenResponses {
  question: CurrentQuestion
  response: string
  result: HRAResult
}

export interface HRAPlanDesignState {
  preScreenResponses: PreScreenResponses[]
}

const initialState: HRAPlanDesignState = {
  preScreenResponses: [],
}

export const HraPlanDesignSlice = createSlice({
  name: "HRAPlanDesign",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addResponse: (state, action: PayloadAction<PreScreenResponses>) => {
      const checkPreScreenResponse = (response: PreScreenResponses) => response.question === action.payload.question

      if (!state.preScreenResponses.some(checkPreScreenResponse)) {
        state.preScreenResponses.push(action.payload)
      } else {
        state.preScreenResponses = state.preScreenResponses.map(response =>
          response.question === action.payload.question
            ? {
                ...response,
                response: action.payload.response,
                result: action.payload.result,
              }
            : response
        )
      }
    },
  },
})

export const { addResponse } = HraPlanDesignSlice.actions

export const HraPlanDesignReducer = HraPlanDesignSlice.reducer
