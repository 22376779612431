import { AppContentWrapper } from "@/components/AppContentWrapper"
import { Footer } from "@/components/Footer"
import { Navbar } from "@/components/Navbar/Navbar"
import { RootWrapper } from "@/components/RootWrapper"
import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { useMediaQuery } from "@mui/material"
import { Outlet } from "react-router-dom"

export const BenefitsElectionWithoutGuardLayout = () => {
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down("sm"))

  return (
    <AuthGuard>
      <RootWrapper>
        <AppContentWrapper>
          <Navbar hasMark={isSmDown} hasLogo={!isSmDown} hasDrawer={false} hasNotificationDrawer={false} />
          <Outlet />
          <Footer />
        </AppContentWrapper>
      </RootWrapper>
    </AuthGuard>
  )
}
