export const AGE_CURVE_0_TO_14 = [
  {
    age: 0,
    amount: 0.77,
  },
  {
    age: 1,
    amount: 0.77,
  },
  {
    age: 2,
    amount: 0.77,
  },
  {
    age: 3,
    amount: 0.77,
  },
  {
    age: 4,
    amount: 0.77,
  },
  {
    age: 5,
    amount: 0.77,
  },
  {
    age: 6,
    amount: 0.77,
  },
  {
    age: 7,
    amount: 0.77,
  },
  {
    age: 8,
    amount: 0.77,
  },
  {
    age: 9,
    amount: 0.77,
  },
  {
    age: 10,
    amount: 0.77,
  },
  {
    age: 11,
    amount: 0.77,
  },
  {
    age: 12,
    amount: 0.77,
  },
  {
    age: 13,
    amount: 0.77,
  },
  {
    age: 14,
    amount: 0.77,
  },
] as const

export const AGE_CURVE_15_TO_17 = [
  {
    age: 15,
    amount: 0.77,
  },
  {
    age: 16,
    amount: 0.86,
  },
  {
    age: 17,
    amount: 0.89,
  },
] as const

export const AGE_CURVE_18_TO_64 = [
  {
    age: 18,
    amount: 0.91,
  },
  {
    age: 19,
    amount: 0.94,
  },
  {
    age: 20,
    amount: 0.97,
  },
  {
    age: 21,
    amount: 1.0,
  },
  {
    age: 22,
    amount: 1.0,
  },
  {
    age: 23,
    amount: 1.0,
  },
  {
    age: 24,
    amount: 1.0,
  },
  {
    age: 25,
    amount: 1.0,
  },
  {
    age: 26,
    amount: 1.02,
  },
  {
    age: 27,
    amount: 1.05,
  },
  {
    age: 28,
    amount: 1.09,
  },
  {
    age: 29,
    amount: 1.12,
  },
  {
    age: 30,
    amount: 1.14,
  },
  {
    age: 31,
    amount: 1.16,
  },
  {
    age: 32,
    amount: 1.18,
  },
  {
    age: 33,
    amount: 1.2,
  },
  {
    age: 34,
    amount: 1.21,
  },
  {
    age: 35,
    amount: 1.22,
  },
  {
    age: 36,
    amount: 1.23,
  },
  {
    age: 37,
    amount: 1.24,
  },
  {
    age: 38,
    amount: 1.25,
  },
  {
    age: 39,
    amount: 1.26,
  },
  {
    age: 40,
    amount: 1.28,
  },
  {
    age: 41,
    amount: 1.3,
  },
  {
    age: 42,
    amount: 1.33,
  },
  {
    age: 43,
    amount: 1.36,
  },
  {
    age: 44,
    amount: 1.4,
  },
  {
    age: 45,
    amount: 1.44,
  },
  {
    age: 46,
    amount: 1.5,
  },
  {
    age: 47,
    amount: 1.56,
  },
  {
    age: 48,
    amount: 1.64,
  },
  {
    age: 49,
    amount: 1.71,
  },
  {
    age: 50,
    amount: 1.79,
  },
  {
    age: 51,
    amount: 1.87,
  },
  {
    age: 52,
    amount: 1.95,
  },
  {
    age: 53,
    amount: 2.04,
  },
  {
    age: 54,
    amount: 2.14,
  },
  {
    age: 55,
    amount: 2.23,
  },
  {
    age: 56,
    amount: 2.33,
  },
  {
    age: 57,
    amount: 2.44,
  },
  {
    age: 58,
    amount: 2.55,
  },
  {
    age: 59,
    amount: 2.6,
  },
  {
    age: 60,
    amount: 2.71,
  },
  {
    age: 61,
    amount: 2.81,
  },
  {
    age: 62,
    amount: 2.87,
  },
  {
    age: 63,
    amount: 2.95,
  },
  {
    age: 64,
    amount: 3.0,
  },
] as const

export const AGE_CURVE_65_TO_100 = [
  {
    age: 65,
    amount: 3.0,
  },
  {
    age: 66,
    amount: 3.0,
  },
  {
    age: 67,
    amount: 3.0,
  },
  {
    age: 68,
    amount: 3.0,
  },
  {
    age: 69,
    amount: 3.0,
  },
  {
    age: 70,
    amount: 3.0,
  },
  {
    age: 71,
    amount: 3.0,
  },
  {
    age: 72,
    amount: 3.0,
  },
  {
    age: 73,
    amount: 3.0,
  },
  {
    age: 74,
    amount: 3.0,
  },
  {
    age: 75,
    amount: 3.0,
  },
  {
    age: 76,
    amount: 3.0,
  },
  {
    age: 77,
    amount: 3.0,
  },
  {
    age: 78,
    amount: 3.0,
  },
  {
    age: 79,
    amount: 3.0,
  },
  {
    age: 80,
    amount: 3.0,
  },
  {
    age: 81,
    amount: 3.0,
  },
  {
    age: 82,
    amount: 3.0,
  },
  {
    age: 83,
    amount: 3.0,
  },
  {
    age: 84,
    amount: 3.0,
  },
  {
    age: 85,
    amount: 3.0,
  },
  {
    age: 86,
    amount: 3.0,
  },
  {
    age: 87,
    amount: 3.0,
  },
  {
    age: 88,
    amount: 3.0,
  },
  {
    age: 89,
    amount: 3.0,
  },
  {
    age: 90,
    amount: 3.0,
  },
  {
    age: 91,
    amount: 3.0,
  },
  {
    age: 92,
    amount: 3.0,
  },
  {
    age: 93,
    amount: 3.0,
  },
  {
    age: 94,
    amount: 3.0,
  },
  {
    age: 95,
    amount: 3.0,
  },
  {
    age: 96,
    amount: 3.0,
  },
  {
    age: 97,
    amount: 3.0,
  },
  {
    age: 98,
    amount: 3.0,
  },
  {
    age: 99,
    amount: 3.0,
  },
  {
    age: 100,
    amount: 3.0,
  },
] as const

export const AGE_CURVE_DISTINCT = [...AGE_CURVE_15_TO_17, ...AGE_CURVE_18_TO_64] as const

export const AGE_CURVE_COMPLETE = [...AGE_CURVE_0_TO_14, ...AGE_CURVE_15_TO_17, ...AGE_CURVE_65_TO_100] as const

export const MINIMUM_CURVE_CHILD = AGE_CURVE_15_TO_17[0]
export const MINIMUM_CURVE_ADULT = AGE_CURVE_18_TO_64[0]
export const LOWER_AGE_LIMIT = MINIMUM_CURVE_ADULT.age
export const MAXIMUM_CURVE = AGE_CURVE_18_TO_64[AGE_CURVE_18_TO_64.length - 1]
