import {
  AccountBalanceWalletOutlined,
  AddchartOutlined,
  DescriptionOutlined,
  GroupOutlined,
  HomeOutlined,
  SettingsApplicationsOutlined,
  SettingsOutlined,
  SyncOutlined,
} from "@mui/icons-material"
import { useMemo } from "react"
import { SidebarItem } from "./types"

const getDashboardItems = (showAutoPay: boolean, isAdmin: boolean, showHris: boolean): SidebarItem[] => [
  {
    href: "/",
    title: "Dashboard",
    icon: HomeOutlined,
  },
  {
    href: "/people",
    title: "People",
    icon: GroupOutlined,
    hidden: !isAdmin,
  },
  {
    href: "/hris",
    title: "HRIS Import",
    icon: SyncOutlined,
    hidden: !showHris || !isAdmin,
  },
  {
    href: "/documents",
    title: "Documents",
    icon: DescriptionOutlined,
  },
  {
    href: "/autopay?tab=autopay-activity",
    title: "AutoPay",
    icon: AccountBalanceWalletOutlined,
    hidden: !showAutoPay || !isAdmin,
  },
  {
    href: "/benefits",
    title: "Benefits",
    icon: SettingsApplicationsOutlined,
    hidden: !isAdmin,
  },
  {
    href: "/reports",
    title: "Reports",
    icon: AddchartOutlined,
    hidden: !isAdmin,
  },
  {
    href: "/company/settings?tab=company-profile",
    title: "Settings",
    icon: SettingsOutlined,
    hidden: !isAdmin,
  },
]

export const useDashboardItems = (showAutopay: boolean, isAdmin: boolean, showHris: boolean) =>
  useMemo(() => getDashboardItems(showAutopay, isAdmin, showHris), [showAutopay, isAdmin, showHris])
