import { takeCommandPrimary } from "@/theme/palette"
import { createDateFromDateTimeText } from "@/utils/formatting"
import { EnrollmentTimePeriod } from "../BenefitsElection/benefitsElectionTypes"
import { APPROVED, ARCHIVED, PALE_OLIVE_COLOR, PENDING, PINE_GREEN_COLOR, PUMPKIN_COLOR } from "./dashboardConstants"

//FUTURE update chip color once statuses are confirmed
export const getColorByPremiumStatus = (status: string | undefined) => {
  switch (status?.toUpperCase()) {
    case APPROVED:
      return PINE_GREEN_COLOR
    case PENDING:
      return PALE_OLIVE_COLOR
    case ARCHIVED:
      return PUMPKIN_COLOR
    default:
      return takeCommandPrimary.light
  }
}

export const getActiveEnrollmentTimePeriods = (enrollmentTimePeriods: EnrollmentTimePeriod[]) =>
  enrollmentTimePeriods?.filter(period => {
    const fromDate = createDateFromDateTimeText(period.periodStartUtc)
    const toDate = createDateFromDateTimeText(period.periodEndUtc)
    const today = new Date()
    return today >= fromDate && today <= toDate
  })
