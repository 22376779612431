import { DatePickerField } from "@/components/DatePickerField"
import { LoadingContentArea } from "@/components/LoadingContentArea"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import { StyledCard } from "@/components/StyledCard"
import { ClampedTextField, EmailTextField, FirstNameTextField, LastNameTextField } from "@/components/TextFields"
import { ADMINISTRATIVE_ROLES, YES_NO_OPTIONS_BOOLEAN } from "@/constants"
import { convertHraClassModelToCustomClassDetails } from "@/features/Benefits/benefitsUtils"
import { useGetHraPlan } from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupService"
import { useGetCurrentClasses } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureService"
import {
  CustomClassDetails,
  HraClassModelResponse,
} from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureTypes"
import { calculateFirstBenefitDate, calculateHireDate } from "@/features/People/AddPersonModal/addPersonUtils"
import { useUpdateHealthBenefit } from "@/features/People/BenefitsService"
import { PhoneNumberField } from "@/features/People/PeopleSettings/PhoneNumberField"
import { ZipCodeField } from "@/features/People/PeopleSettings/ZipCodeField"
import { Roles } from "@/features/People/peopleTypes"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { STATE_OPTIONS } from "@/utils/States"
import { Uuid } from "@/utils/types"
import { SaveOutlined } from "@mui/icons-material"
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { Formik, FormikProps } from "formik"
import { get, isString } from "lodash"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router-dom"
import { editPersonSchema, editPersonValues } from "../editPersonValidations"
import { useBusinessUnits, useUpdateUser, useUser } from "../tcHubService"
import { BusinessUnit, PersonDetailResponse } from "../tcHubTypes"

const dataQa = "tc-hub-person-detail-page"

function getInitialValues(
  tcHubPersonDetails: PersonDetailResponse | undefined,
  companyClasses: HraClassModelResponse[] | undefined
): editPersonValues {
  const customClassDetails = companyClasses ? convertHraClassModelToCustomClassDetails(companyClasses) : []

  const mapPersonRoles = (roles: Roles[] | undefined) => {
    const hasRole = (newRoles: Roles[] | undefined, roleName: string): boolean => {
      if (!newRoles || !Array.isArray(newRoles)) {
        return false
      }
      return newRoles.some(role => role.name === roleName)
    }

    let administrativeRole: (typeof ADMINISTRATIVE_ROLES)[number] = ADMINISTRATIVE_ROLES.find(
      role => role.value === "none"
    )!
    if (hasRole(roles, "System Administrator")) {
      administrativeRole = ADMINISTRATIVE_ROLES.find(role => role.value === "system_admin")!
    } else if (hasRole(roles, "Administrator")) {
      administrativeRole = ADMINISTRATIVE_ROLES.find(role => role.value === "admin")!
    }

    return {
      administrativeRole: administrativeRole.value,
      employeeRole: hasRole(roles, "Employee"),
      benefitsConsultantRole: hasRole(roles, "Broker"),
    }
  }

  const classIdSelector = (details: CustomClassDetails[], employmentId: string) => {
    const classId =
      details !== undefined
        ? details.find(({ healthBenefits }) => healthBenefits.some(h => h.employmentId === employmentId))?.classId || ""
        : ""

    return classId
  }

  const mappedRoles = mapPersonRoles(tcHubPersonDetails?.roles)

  return {
    id: tcHubPersonDetails?.id ?? "",
    firstName: tcHubPersonDetails?.firstName ?? "",
    lastName: tcHubPersonDetails?.lastName ?? "",
    dateOfBirth: new Date(tcHubPersonDetails?.dateOfBirth ?? ""),
    email: tcHubPersonDetails?.email ?? "",
    phoneNumber: tcHubPersonDetails?.phoneNumber?.value ?? "",
    homeAddress: {
      id: tcHubPersonDetails?.addresses?.[0]?.id ?? "",
      street1: tcHubPersonDetails?.addresses?.[0]?.street1 ?? "",
      street2: tcHubPersonDetails?.addresses?.[0]?.street2 ?? "",
      city: tcHubPersonDetails?.addresses?.[0]?.city ?? "",
      zip: tcHubPersonDetails?.addresses?.[0]?.zip ?? "",
      county: tcHubPersonDetails?.addresses?.[0]?.county ?? "",
      state: tcHubPersonDetails?.addresses?.[0]?.state ?? ("" as never),
    },
    businessUnit: tcHubPersonDetails?.employments?.[0]?.businessUnitId ?? "",
    roles: mappedRoles,
    currentClass: classIdSelector(customClassDetails, tcHubPersonDetails?.employmentId!),
    employmentId: tcHubPersonDetails?.employmentId,
    companyId: tcHubPersonDetails?.companyId,
  }
}

const PersonEditDetailCard = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  businessUnitData,
  classes,
}: FormikProps<editPersonValues> & { businessUnitData: BusinessUnit[] | undefined } & {
  classes: HraClassModelResponse[]
}) => (
  <StyledCard>
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      data-qa={createDataQa(dataQa, "person-details-card")}
    >
      <Grid item>
        <Typography variant="h5" gutterBottom data-qa="person-detail-title">
          About
        </Typography>
      </Grid>
    </Grid>
    <Divider sx={{ my: 3 }} />
    <Grid container spacing={12}>
      <Grid item xs={12} sm={6}>
        <FirstNameTextField disabled />
      </Grid>
      <Grid item xs={12} sm={6}>
        <LastNameTextField disabled />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePickerField
          data-qa="personal-birth-date"
          dataQa="personal-birth-date"
          name="dateOfBirth"
          label="Date of birth"
          required
          fullWidth
          variant="outlined"
          type="date"
          maxDate={new Date()}
          value={values.dateOfBirth ?? null}
          error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
          helperText={touched.dateOfBirth ? `${errors.dateOfBirth ?? ""}` : ""}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <EmailTextField />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PhoneNumberField
          data-qa="phone-number"
          name="phoneNumber"
          label="Phone number"
          fullWidth
          variant="outlined"
          value={values.phoneNumber}
          error={Boolean(touched.phoneNumber && errors.phoneNumber)}
          helperText={touched.phoneNumber && errors.phoneNumber}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Grid>
      {businessUnitData && businessUnitData.length > 0 && (
        <Grid item xs={12} sm={6}>
          <SelectField
            dataQa="business-unit"
            data={[
              { value: "", label: "No Business Unit" },
              ...businessUnitData.map(unit => ({
                value: unit.id,
                label: unit.name,
              })),
            ]}
            type="text"
            name="businessUnit"
            label="Business Unit"
            value={values.businessUnit}
            placeholder="Select Business Unit"
            onChange={handleChange}
            sx={{ width: "100%" }}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <SelectField
          dataQa="class-name"
          data={classes?.map(className => ({ value: className.id, label: className.name || "" })) ?? []}
          type="text"
          name="currentClass"
          label="Class"
          required
          value={values.currentClass}
          placeholder="Select class"
          onChange={handleChange}
          onBlur={handleBlur}
          sx={{ width: "100%" }}
        />
        {touched.currentClass && errors.currentClass && !values.currentClass && (
          <FormHelperText error>{errors.currentClass}</FormHelperText>
        )}
      </Grid>
    </Grid>
  </StyledCard>
)

const PersonEditAddressDetailCard = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}: FormikProps<editPersonValues>) => (
  <StyledCard>
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      data-qa={createDataQa(dataQa, "person-address-card")}
    >
      <Grid item>
        <Typography variant="h5" gutterBottom data-qa="person-detail-title">
          Home Address
        </Typography>
      </Grid>
    </Grid>
    <Divider sx={{ my: 3 }} />
    <Grid container spacing={12}>
      <Grid item xs={12} sm={6}>
        <ClampedTextField
          data-qa="street1"
          name="homeAddress.street1"
          label="Address"
          value={values.homeAddress?.street1}
          fullWidth
          required
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(get(touched, `homeAddress.street1`) && get(errors, `homeAddress.street1`))}
          helperText={get(touched, `homeAddress.street1`) && get(errors, `homeAddress.street1`)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ClampedTextField
          data-qa="street2"
          name="homeAddress.street2"
          label="Apt, suite, etc."
          value={values.homeAddress?.street2}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(get(touched, `homeAddress.street2`) && get(errors, `homeAddress.street2`))}
          helperText={get(touched, `homeAddress.street2`) && get(errors, `homeAddress.street2`)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ClampedTextField
          data-qa="city"
          name="homeAddress.city"
          label="City"
          required
          value={values.homeAddress?.city}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(get(touched, `homeAddress.city`) && get(errors, `homeAddress.city`))}
          helperText={get(touched, `homeAddress.city`) && get(errors, `homeAddress.city`)}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ZipCodeField
          data-qa="zip"
          name="homeAddress.zip"
          label="ZIP Code"
          required
          value={values.homeAddress?.zip}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(get(touched, `homeAddress.zip`) && get(errors, `homeAddress.zip`))}
          helperText={get(touched, `homeAddress.zip`) && get(errors, `homeAddress.zip`)}
          inputProps={{ "data-qa": "zip-code-input" }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ClampedTextField
          data-qa="county"
          name="homeAddress.county"
          label="County"
          required
          value={values.homeAddress?.county}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(get(touched, `homeAddress.county`) && get(errors, `homeAddress.county`))}
          helperText={get(touched, `homeAddress.county`) && get(errors, `homeAddress.county`)}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl
          variant="outlined"
          sx={{ width: "100%" }}
          required
          error={Boolean(get(touched, `homeAddress.state`) && get(errors, `homeAddress.state`))}
        >
          <InputLabel>State</InputLabel>
          <Select
            name="homeAddress.state"
            label="State"
            placeholder="Select State"
            data-qa="state"
            fullWidth
            value={values.homeAddress?.state}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {STATE_OPTIONS.map(state => (
              <MenuItem key={state.value} data-qa={"state-" + state.value} value={state.value}>
                {state.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.homeAddress?.state ? `${errors.homeAddress?.state ?? ""}` : ""}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  </StyledCard>
)

export const TcHubUserEditPage = () => {
  const navigate = useNavigate()
  const { userId, employmentId, companyId } = useParams<{ userId: string; employmentId: string; companyId: Uuid }>()
  const { notify } = useNotifications("edit-person")

  const { data: tcHubPersonDetails, isLoading: istcHubPersonDetailsLoading } = useUser(
    companyId ?? ("" as never),
    userId ?? ""
  )
  const { data: businessUnit, isLoading: isBusinessUnitLoading } = useBusinessUnits(companyId ?? "")

  const { mutateAsync: updatePerson } = useUpdateUser(companyId ?? "", userId ?? "")
  const { mutateAsync: updateHealthBenefit } = useUpdateHealthBenefit()
  const { data: plans } = useGetHraPlan(companyId ?? ("" as never))
  const currentPlan = plans && plans.length > 0 ? plans[0] : { id: "", hraStartDate: "" }
  const { data: classes } = useGetCurrentClasses(companyId ?? "", currentPlan?.id)
  const employee = tcHubPersonDetails?.employments?.[0]
  const handleNavigateBack = () => {
    navigate(`/admin/users/${userId}/${employmentId}/${companyId}`)
  }

  return (
    <Grid container data-qa={createDataQa(dataQa, "person-details-container")}>
      <Helmet title="TC Hub Site Settings" />
      {istcHubPersonDetailsLoading || isBusinessUnitLoading ? (
        <LoadingContentArea data-qa={createDataQa("loading-person-details")} />
      ) : (
        <Formik
          initialValues={getInitialValues(tcHubPersonDetails, classes)}
          validationSchema={editPersonSchema}
          onSubmit={async values => {
            try {
              const currentClassObject = classes?.find(classItem => classItem.id === values.currentClass)
              const currentClassWaitingPeriod = currentClassObject?.waitingPeriod
              const hireDate = calculateHireDate(new Date(employee!.activeFrom), currentClassWaitingPeriod!)
              const firstBenefitDate = calculateFirstBenefitDate(hireDate, new Date(currentPlan.hraStartDate!))
              await updatePerson({ payload: values, employmentId: employmentId ?? "" })
              await updateHealthBenefit({
                employmentId: values.employmentId,
                classId: values.currentClass,
                companyId: values.companyId,
                planId: currentPlan.id,
                eligibilityDateFrom: firstBenefitDate.toISOString().split("T")[0],
              })
              handleNavigateBack()
              notify("Person updated successfully", "success")
            } catch (error: any) {
              const errorMessage =
                error.responseMessage ??
                "Error updating the person. Please try again later. If the issue persists, contact support for assistance."
              notify(errorMessage, "error")
            }
          }}
        >
          {formProps => (
            <form noValidate onSubmit={formProps.handleSubmit} data-qa="edit-user-form">
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h1" gutterBottom display="inline" data-qa="person-details">
                    Editing Profile
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm="auto" spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button data-qa="cancel-user-button" variant="outlined" onClick={handleNavigateBack}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={formProps.isSubmitting}
                      data-qa="update-user-button"
                      startIcon={formProps.isSubmitting ? <CircularProgress size={20} /> : <SaveOutlined />}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <PersonEditDetailCard {...formProps} businessUnitData={businessUnit} classes={classes!} />
              <PersonEditAddressDetailCard {...formProps} />
              <StyledCard>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      User Roles
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Administrative Role
                    </Typography>
                    <RadioGroupCard
                      name="roles.administrativeRole"
                      formName="user-roles"
                      value={formProps.values.roles.administrativeRole}
                      handleChange={(field, value) => {
                        formProps.setFieldValue(field, value)
                        if (value !== "none") {
                          formProps.setFieldValue("roles.benefitsConsultantRole", YES_NO_OPTIONS_BOOLEAN[1].value)
                        }
                      }}
                      elements={ADMINISTRATIVE_ROLES}
                    />
                    {formProps.touched.roles?.administrativeRole && formProps.errors.roles?.administrativeRole && (
                      <FormHelperText error>{String(formProps.errors.roles.administrativeRole)}</FormHelperText>
                    )}
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Employee Role
                    </Typography>
                    <RadioGroupCard
                      name="roles.employeeRole"
                      formName="user-roles"
                      value={formProps.values.roles.employeeRole}
                      handleChange={(field, value) => {
                        formProps.setFieldValue(field, value)
                        if (value) {
                          formProps.setFieldValue("roles.benefitsConsultantRole", YES_NO_OPTIONS_BOOLEAN[1].value)
                        }
                      }}
                      elements={YES_NO_OPTIONS_BOOLEAN}
                    />
                    {formProps.touched.roles?.employeeRole && formProps.errors.roles?.employeeRole && (
                      <FormHelperText error>{String(formProps.errors.roles.employeeRole)}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Benefits Consultant Role</Typography>
                    <RadioGroupCard
                      name="roles.benefitsConsultantRole"
                      formName="user-roles"
                      value={formProps.values.roles.benefitsConsultantRole}
                      handleChange={(field, value) => {
                        formProps.setFieldValue(field, value)
                        if (value === YES_NO_OPTIONS_BOOLEAN[0].value) {
                          formProps.setFieldValue("roles.administrativeRole", ADMINISTRATIVE_ROLES[2].value)
                          formProps.setFieldValue("roles.employeeRole", YES_NO_OPTIONS_BOOLEAN[1].value)
                        }
                      }}
                      elements={YES_NO_OPTIONS_BOOLEAN}
                      disabled={(
                        formProps.values.roles.administrativeRole !== ADMINISTRATIVE_ROLES[2].value || 
                        formProps.values.roles.employeeRole === YES_NO_OPTIONS_BOOLEAN[0].value
                      )}
                    />
                    {formProps.touched.roles?.benefitsConsultantRole && formProps.errors.roles?.benefitsConsultantRole && (
                      <FormHelperText error>{String(formProps.errors.roles.benefitsConsultantRole)}</FormHelperText>
                    )}
                  </Grid> */}

                  {formProps.touched.roles && formProps.errors.roles && isString(formProps.errors.roles) && (
                    <Grid item xs={12}>
                      <FormHelperText error>{formProps.errors.roles}</FormHelperText>
                    </Grid>
                  )}
                </Grid>
              </StyledCard>
            </form>
          )}
        </Formik>
      )}
    </Grid>
  )
}
