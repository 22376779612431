import { AppContentWrapper } from "@/components/AppContentWrapper"
import { DrawerWrapper } from "@/components/DrawerWrapper"
import { Footer } from "@/components/Footer"
import { MainContentWrapper } from "@/components/MainContentWrapper"
import { Navbar } from "@/components/Navbar/Navbar"
import { RootWrapper } from "@/components/RootWrapper"
import { Sidebar } from "@/components/Sidebar/Sidebar"
import { DRAWER_WIDTH } from "@/constants"
import { Box } from "@mui/material"
import { ReactNode, useState } from "react"
import { Outlet } from "react-router-dom"
import { useTcHubMenuItems } from "../tcHubMenuItems"
export interface TcHubLayoutProps {
  children?: ReactNode
}

export const TcHubLayout = ({ children }: TcHubLayoutProps) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const tcHubMenuItems = useTcHubMenuItems()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <RootWrapper>
      <DrawerWrapper>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: DRAWER_WIDTH } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={tcHubMenuItems}
            isViewingTcHub
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar PaperProps={{ style: { width: DRAWER_WIDTH } }} items={tcHubMenuItems} isViewingTcHub />
        </Box>
      </DrawerWrapper>
      <AppContentWrapper>
        <Navbar onDrawerToggle={handleDrawerToggle} isViewingTcHub />
        <MainContentWrapper>
          {children}
          <Outlet />
        </MainContentWrapper>
        <Footer />
      </AppContentWrapper>
    </RootWrapper>
  )
}
