import { Box, Typography } from "@mui/material"

interface CarrierQuestionsErrorContainerProps {
  error: Error
}

export const CarrierQuestionsErrorContainer = ({ error }: CarrierQuestionsErrorContainerProps) => (
  <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%" height="50vh">
    <Typography variant="h4" color="error">
      Error loading carrier questions
    </Typography>
    <Typography variant="body1" color="error">
      Please try again later.
    </Typography>
    <Typography variant="body1" color="error">
      {error.message}
    </Typography>
  </Box>
)
