import { ConfirmationModal } from "@/components/ConfirmationModal"
import { useDeleteCarrierQuestion } from "@/features/BenefitsElection/healthPlansService"
import { useNotifications } from "@/services/notificationService"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined"
import { Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from "@mui/material"
import { SyntheticEvent, useEffect, useState } from "react"
import { CarrierQuestion } from "../tcHubTypes"
import { CarrierQuestionsDrawer } from "./CarrierQuestionsDrawer"

interface CarrierQuestionsActionDropdownMenuProps {
  carrierId: string
  carrierQuestion: CarrierQuestion
}

export const CarrierQuestionsActionDropdownMenu = ({
  carrierId,
  carrierQuestion,
}: CarrierQuestionsActionDropdownMenuProps) => {
  const [showMenu, setShowMenu] = useState<any>(null)
  const { notify } = useNotifications("carrier-questions-actions")
  const [openQuestionDrawer, setOpenQuestionDrawer] = useState(false)
  const [isDeleteClicked, setIsDeleteClicked] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const { mutateAsync: deleteCarrierQuestion, error: isCarrierQuestionDeleteError } =
    useDeleteCarrierQuestion(carrierId)

  const handleActionsClick = (e: SyntheticEvent) => {
    setShowMenu(e.currentTarget)
  }

  const closeMenu = () => {
    setShowMenu(null)
  }

  const handleEdit = () => {
    closeMenu()
    setOpenQuestionDrawer(true)
  }

  const onCloseDrawer = () => {
    setOpenQuestionDrawer(false)
    closeMenu()
  }

  const closeConfirmationModal = () => {
    setIsDeleteClicked(false)
  }

  const handleDelete = async () => {
    setIsDeleteLoading(true)
    if (carrierQuestion.id) await deleteCarrierQuestion({ carrierId, questionId: carrierQuestion.id })
    setIsDeleteLoading(false)
    closeConfirmationModal()
    notify(`Carrier ${carrierQuestion.question} removed successfully`, "success")
  }

  const openConfirmationModal = async () => {
    closeMenu()
    setIsDeleteClicked(true)
  }

  useEffect(
    () => {
      if (Boolean(isCarrierQuestionDeleteError) === true) {
        notify("Error deleting carrier question", "error")
      }
    },
    // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCarrierQuestionDeleteError]
  )

  return (
    <>
      <Tooltip title="Actions">
        <IconButton
          size="large"
          aria-haspopup="true"
          aria-owns="menu-carrier-question-action-options"
          onClick={handleActionsClick}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>

      <Menu open={Boolean(showMenu)} anchorEl={showMenu} onClose={closeMenu}>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <RemoveRedEyeOutlinedIcon />
          </ListItemIcon>
          <Typography variant="body1">Edit</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={openConfirmationModal}>
          <ListItemIcon>
            <DeleteOutlinedIcon color="error" />
          </ListItemIcon>
          <Typography color="error" variant="body1">
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      <CarrierQuestionsDrawer
        onClose={onCloseDrawer}
        visible={openQuestionDrawer}
        currentCarrierId={carrierId}
        carrierQuestion={carrierQuestion}
        action="edit"
        title="Edit carrier question"
      />
      <ConfirmationModal
        isOpen={isDeleteClicked}
        onClose={closeConfirmationModal}
        onConfirm={handleDelete}
        isError
        title="You are about to remove this question"
        message="This action will delete all child questions."
        cancelLabel="Close"
        actionLabel="Delete"
        maxWidth="xs"
        isSubmitting={isDeleteLoading}
      />
    </>
  )
}
