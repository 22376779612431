import { usePermissions } from "@/services/permissionsService"
import { EmotionJSX } from "@emotion/react/types/jsx-namespace"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Button, Grid, Tab, Typography } from "@mui/material"
import { SyntheticEvent } from "react"
import { Helmet } from "react-helmet-async"
import { useSearchParams } from "react-router-dom"
import { AuthGuard } from "../Auth/guards/AuthGuard"
import { PermissionGuard } from "../Auth/guards/PermissionGuard"
import { DashboardLayout } from "../Dashboard/components/DashboardLayout"
import { AutoPayActivity } from "./AutoPayActivity"
import { AutoPayFundingReports } from "./AutoPayFundingReports"

interface TabType {
  dataQa: string
  id: string
  title: string
  content: EmotionJSX.Element
}

const TABS: TabType[] = [
  {
    dataQa: "autopay-activity",
    id: "autopay-activity",
    title: "Activity",
    content: <AutoPayActivity />,
  },
  {
    dataQa: "autopay-funding-reports",
    id: "funding-reports",
    title: "Funding Reports",
    content: <AutoPayFundingReports />,
  },
]

export const AutoPayPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedTab = searchParams.get("tab") ?? "autopay-activity"

  const handleChange = (event: SyntheticEvent, newTab: string) => {
    setSearchParams({ tab: newTab, ...searchParams })
  }

  const hasAutopayPermission = usePermissions("accounts_account_management")

  return (
    <AuthGuard>
      <DashboardLayout>
        <PermissionGuard in="accounts_account_management">
          <Grid container data-qa="autopay-activity">
            <Helmet title="AutoPay Page" />
            <Typography variant="h1" gutterBottom display="inline" data-qa="company-name" sx={{ mb: 4 }}>
              AutoPay
            </Typography>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item sm={10}>
                <Typography variant="body1" gutterBottom>
                  AutoPay shows you all the information related to paying your Employee's monthly premiums directly via
                  payments. Below you can track individual transactions as well as the amount of money that's
                  transferred into your disbursement account.
                </Typography>
              </Grid>
              <Grid item sx={{ mt: 3 }}>
                <Button variant="outlined" href="/company/settings?tab=autopay" startIcon={<SettingsOutlinedIcon />}>
                  Settings
                </Button>
              </Grid>
            </Grid>
            {hasAutopayPermission && (
              <Grid container direction="column" spacing={3} sx={{ pt: 4 }}>
                <TabContext value={selectedTab}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange}>
                      {TABS.map(tab => (
                        <Tab label={tab.title} value={tab.id} key={tab.id} data-qa={tab.dataQa} />
                      ))}
                    </TabList>
                  </Box>
                  {TABS.map(tab => (
                    <TabPanel key={`${tab.id}-panel`} value={tab.id}>
                      {tab.content}
                    </TabPanel>
                  ))}
                </TabContext>
              </Grid>
            )}
          </Grid>
        </PermissionGuard>
      </DashboardLayout>
    </AuthGuard>
  )
}
