import { COMPLETE, INCOMPLETE } from "@/constants"
import { CheckCircle, Warning } from "@mui/icons-material"
import {
  Box,
  Grid,
  LinearProgress,
  Step as MuiStep,
  StepLabel as MuiStepLabel,
  Stepper as MuiStepper,
  StepProps as MuiStepProps,
  StepButton,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { ReactNode } from "react"

const StepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.primary.main,
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: theme.palette.primary.main,
  }),
  "& .CustomStepIcon-completedIcon": {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
  "& .CustomStepIcon-circle": {
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}))

type StepStatus = typeof COMPLETE | typeof INCOMPLETE

const stepIconStyle = {
  width: { xs: "1rem", md: "1.25rem" },
  height: { xs: "1rem", md: "1.25rem" },
}

interface StepIconProps {
  status: StepStatus
  isError?: boolean
  isActive?: boolean
}

const Icon = ({ status, isError }: Omit<StepIconProps, "isActive">) => {
  if (isError) {
    return <Warning data-qa="icon-error" sx={{ color: "red", ...stepIconStyle }} />
  }

  if (status === COMPLETE) {
    return <CheckCircle className="CustomStepIcon-completedIcon" data-qa="icon-complete" sx={stepIconStyle} />
  }

  return null
}

const StepIcon = ({ isActive, ...props }: StepIconProps) => (
  <StepIconRoot ownerState={{ active: isActive }}>
    <Icon {...props} />
  </StepIconRoot>
)

interface StepLabelProps extends StepIconProps {
  children: ReactNode
}

const StepLabel = ({ children, ...props }: StepLabelProps) => (
  <MuiStepLabel icon={<StepIcon {...props} />} data-qa="step-label">
    {children}
  </MuiStepLabel>
)

export interface FormStep {
  label: string
  component: ReactNode
  route: string
}

export interface StepData<T extends Readonly<string>> {
  label: string
  step: T
  status: StepStatus
  url: string
  hidden?: boolean
  isActive?: boolean
  isDiy?: boolean
}

interface StepperProps<T extends Readonly<string>> {
  steps: StepData<T>[]
  activeStepIndex: number
  isError?: boolean
  hideProgressBar?: boolean
}

interface MobileStepperProps<T extends Readonly<string>> extends Pick<StepperProps<T>, "steps" | "activeStepIndex"> {
  currentStepLabel: string
}

type StepProps<T extends Readonly<string>> = Omit<MuiStepProps, "active"> &
  StepIconProps & {
    step: StepData<T>
    label: string
    isCurrent?: boolean
  }

const getStepStyle = (isActiveStep: boolean | undefined, isError: boolean | undefined) =>
  ({
    borderBottom: isError ? "0px solid" : "3px solid",
    borderBottomColor: isActiveStep ? "primary.main" : "transparent",
  }) as const

const Step = <T extends Readonly<string>>({
  label,
  status,
  isCurrent,
  isActive,
  isError,
  step: { url },
  sx = {},
  ...props
}: StepProps<T>) => (
  <MuiStep
    active={isActive && !isError}
    data-qa="stepper-step"
    sx={{
      "& .MuiStepLabel-root .Mui-active": {
        color: isError ? "stepper.warning" : "primary.main",
      },
      "& .MuiStepLabel-root": {
        paddingBottom: 1,
      },
      "& .MuiStepLabel-label": {
        fontSize: { xs: "0.688rem", md: "0.8rem" },
      },
      ...sx,
    }}
    {...props}
  >
    <StepButton
      component="a"
      disabled={!isActive}
      // onClick={isActive && url ? () => navigate(url) : undefined}
      href={isActive && url ? url : undefined}
      icon={
        <Box sx={getStepStyle(isCurrent, isError)}>
          <StepLabel status={status} isActive={isActive && !isError} isError={isActive && isError}>
            {label.toUpperCase()}
          </StepLabel>
        </Box>
      }
    />
  </MuiStep>
)

const MobileStepper = <T extends Readonly<string>>({
  steps,
  activeStepIndex,
  currentStepLabel,
}: MobileStepperProps<T>) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant="caption">
        Step {activeStepIndex + 1} of {steps.length}
      </Typography>
    </Grid>
    <Grid item xs={12} mt={3} mb={4}>
      <Typography variant="h6">{currentStepLabel}</Typography>
    </Grid>
  </Grid>
)

const DesktopStepper = <T extends Readonly<string>>({
  steps,
  isError,
  activeStepIndex,
  hideProgressBar,
}: StepperProps<T>) => {
  const ratio = 1 / steps.length
  const progress = Math.max(2.5, activeStepIndex * ratio)

  return (
    <>
      {!hideProgressBar && (
        <LinearProgress
          variant="determinate"
          data-qa="stepper-linear-progress-bar"
          color="primary"
          value={progress}
          sx={{ borderRadius: 8, marginBottom: "1rem", marginLeft: ".3rem", marginRight: ".3rem" }}
        />
      )}
      <MuiStepper data-qa="stepper" sx={{ width: "100%" }} nonLinear activeStep={activeStepIndex}>
        {steps
          .filter(s => !s.hidden)
          .map((step, i) => (
            <Step
              key={step.step}
              step={step}
              status={step.status}
              isActive={activeStepIndex >= i}
              isCurrent={activeStepIndex === i}
              isError={isError}
              label={step.label}
            />
          ))}
      </MuiStepper>
    </>
  )
}

export const Stepper = <T extends Readonly<string>>({ steps, activeStepIndex, ...props }: StepperProps<T>) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"))

  return isMobile ? (
    <MobileStepper
      steps={steps}
      activeStepIndex={activeStepIndex}
      currentStepLabel={steps[activeStepIndex]?.label.toUpperCase() || ""}
      {...props}
    />
  ) : (
    <DesktopStepper steps={steps} activeStepIndex={activeStepIndex} {...props} />
  )
}
