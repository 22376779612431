import { AsyncButton } from "@/components/Buttons"
import { useNotifications } from "@/services/notificationService"
import { Card, Divider, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { RECOMMENDED_PLANS, SELF_ENROLLED_PLAN } from "../benefitsElectionConstants"
import { useShoppingUrl } from "../benefitsElectionService"
import { useBenefitsElectionStore } from "../benefitsElectionStore"
import { useResetPlanSelection } from "../hooks/useResetPlanSelection"

export const AlreadyHaveCoverage = () => {
  const navigate = useNavigate()
  const shoppingUrl = useShoppingUrl()
  const location = useLocation()
  const { notify } = useNotifications("already-have-coverage")

  const { unselectPlan, isResettingPlanSelection } = useResetPlanSelection()
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)

  const handleClick = async () => {
    try {
      await unselectPlan()
      setCurrentStep(SELF_ENROLLED_PLAN)
      navigate(shoppingUrl + SELF_ENROLLED_PLAN)
    } catch (error) {
      notify("An unexpected error occurred. Please try again later.", "error")
    }
  }

  if (!location.pathname.includes(RECOMMENDED_PLANS)) return null

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "colors.borderGray",
        p: 6,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Already have coverage?
      </Typography>
      <Typography>We've got you covered — just upload your proof of coverage (POC).</Typography>
      <Divider />
      <AsyncButton variant="contained" sx={{ mt: 4 }} onClick={handleClick} isLoading={isResettingPlanSelection}>
        Upload your POC
      </AsyncButton>
    </Card>
  )
}
