import { createDataQa, DataQa } from "@/utils/dataQa"
import { VoidFn } from "@/utils/types"
import { Box, Grid } from "@mui/material"
import { ReactNode, useState } from "react"
import { Helmet } from "react-helmet-async"
import { To, useNavigate } from "react-router-dom"
import { useNotifications } from "../services/notificationService"
import { FlowNavigationButtons, FlowNavigationButtonsProps } from "./FlowNavigationButtons"
import { InfoTooltip } from "./InfoTooltip"
import { Typographify } from "./Text"

type BaseFlowNavigationStepProps = FlowNavigationButtonsProps & {
  name: string
  title: ReactNode
  hideTitle?: boolean
  subtitle?: ReactNode
  description?: ReactNode
  tooltip?: string
  required?: boolean
  disabled?: boolean
  previous?: To
  next?: To
  advanceOnSuccess?: boolean
  handleContinue?: VoidFn
  handleSkip?: () => void
  type?: "submit" | "button"
  successMessage?: string
  errorMessage?: string
  "data-qa"?: DataQa
}

const FlowNavigationStep = ({
  name,
  title,
  hideTitle,
  subtitle,
  description,
  tooltip,
  required = false,
  disabled,
  previous,
  next,
  advanceOnSuccess,
  handleContinue: handleContinueProp,
  handleSkip: handleSkipProp,
  handleBack: handleBackProp,
  isSubmitting,
  type = "button",
  successMessage,
  errorMessage,
  children,
  "data-qa": dataQa,
  sx = {},
  ...props
}: BaseFlowNavigationStepProps) => {
  const [isWaiting, setIsWaiting] = useState(false)
  const navigate = useNavigate()
  const { notify } = useNotifications(name)
  const advance = next ? () => navigate(next) : undefined
  const retreat = previous ? () => navigate(previous) : undefined
  const showBackButton = handleBackProp || previous

  const handleContinue = async () => {
    setIsWaiting(true)

    try {
      await handleContinueProp?.()

      if (successMessage) {
        notify(successMessage, "success")
      }

      if (advanceOnSuccess) {
        advance?.()
      }
    } catch (e) {
      if (errorMessage) {
        notify(errorMessage, "error")
      }

      console.error("An error occurred", e)
    }

    setIsWaiting(false)
  }

  const handleBack = () => {
    handleBackProp?.()
    retreat?.()
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      <Helmet title={title} />
      <Grid container direction="column" mb={8}>
        <Grid item>
          {!hideTitle && (
            <Typographify variant="h1" gutterBottom data-qa={createDataQa(dataQa, "title")}>
              {title}
            </Typographify>
          )}

          {subtitle && (
            <Typographify variant="h5" gutterBottom data-qa={createDataQa(dataQa, "subtitle")}>
              {subtitle}
            </Typographify>
          )}
          {description && (
            <Typographify
              // FUTURE: Style gutterBottom based on typography variant
              /* gutterBottom */
              pb="2rem"
              endAdornment={tooltip && <InfoTooltip title={tooltip} />}
              data-qa={createDataQa(dataQa, "description")}
            >
              {description}
            </Typographify>
          )}
        </Grid>
        <Grid container>{children}</Grid>
      </Grid>

      <FlowNavigationButtons
        isSubmitting={isWaiting || isSubmitting}
        disabled={disabled}
        type={type}
        handleContinue={handleContinue}
        handleBack={showBackButton ? handleBack : undefined}
        handleSkip={
          required
            ? undefined
            : () => {
                if (handleSkipProp) {
                  handleSkipProp()
                } else {
                  advance?.()
                }
              }
        }
        {...props}
      />
    </Box>
  )
}

export type FlowNavigationStepProps = Omit<BaseFlowNavigationStepProps, "name">

/** @param name This should be the same as the URL path segment for the step  */
export const createNavigationStep = (name: string) => (props: FlowNavigationStepProps) => (
  <FlowNavigationStep name={name} {...props} />
)
