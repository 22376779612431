import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"

// Define an interface for the component props
export interface TableLoadingSkeletonProps {
  numRows?: number
  numColumns?: number
}

export const TableBodyLoadingSkeleton = ({
  numRows = 5, // Provide default value if not passed
  numColumns = 3, // Provide default value if not passed
}: TableLoadingSkeletonProps) => (
  <TableContainer component={Paper}>
    <Table aria-label="loading skeleton table">
      <TableBody>
        {[...Array(numRows)].map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {[...Array(numColumns)].map((column, columnIndex) => (
              <TableCell key={columnIndex}>
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)
