import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  createBankAccount,
  getApplicationForm,
  getAutoPayConfig,
  getBankAccountUrl,
  reinitiateApplicationForm,
} from "../Funding/fundingEndpoints"
import { CreateBankAccount } from "./types/settingsTypes"

const REFRESH_TIMEOUT_MS = 1000 * 10

export const useGetBankAccount = (companyId: string | null) =>
  useQuery({
    queryKey: ["bank-account", companyId],
    queryFn: async () => {
      const data = await getBankAccountUrl(companyId!)

      localStorage.setItem("plaid_oauth_linkTokenData", data.linkToken)

      return data
    },
    enabled: Boolean(companyId),
  })

export const useGetApplicationForm = (companyId: string | null) => {
  const { data, isError, error, isFetched } = useQuery({
    queryKey: ["application", companyId],
    queryFn: () => getApplicationForm(companyId!),
    enabled: !!companyId,
    refetchOnWindowFocus: true,
    staleTime: REFRESH_TIMEOUT_MS,
    refetchIntervalInBackground: true,
    refetchInterval: 1000 * 60 * 1,
  })

  const isLoading = !isError && !isFetched

  return { data, isError, error, isFetched, isLoading }
}

export const useAutoPayConfig = (companyId: string | null) => {
  const { data, isError, error, isFetched } = useQuery({
    queryKey: ["funding", "companies", companyId, "config"],
    queryFn: () => getAutoPayConfig(companyId!),
    enabled: !!companyId,
    refetchOnWindowFocus: true,
    staleTime: REFRESH_TIMEOUT_MS,
    refetchIntervalInBackground: true,
    refetchInterval: 1000 * 60 * 1,
  })

  const isLoading = !isError && !isFetched

  return { data, isError, error, isFetched, isLoading }
}

export const useReinitiateApplicationForm = (companyId: string | null) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => reinitiateApplicationForm(companyId!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["application", companyId] })
    },
  })
}

export const useCreateBankAccount = (companyId: string | null) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ["create-bank-account", companyId],
    mutationFn: async (payload: CreateBankAccount) => {
      try {
        return await createBankAccount(companyId!, payload)
      } catch (error) {
        queryClient.invalidateQueries({ queryKey: ["bank-account", companyId] })
        throw error
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bank-account", companyId] })
      queryClient.invalidateQueries({ queryKey: ["application", companyId] })
    },
  })
}
