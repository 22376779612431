import { Button, Grid } from "@mui/material"

interface QuizNextButtonProps {
  onClick: () => void
  disabled?: boolean
}

export const QuizNextQuestionButton = ({ onClick, disabled }: QuizNextButtonProps) => (
  <Grid item>
    <Button
      type="submit"
      onClick={onClick}
      disabled={disabled}
      variant="contained"
      color="primary"
      data-qa="next-question-button"
    >
      Next Question
    </Button>
  </Grid>
)
