import { HraPlanModel } from "./components/Steps/Setup/PlanSetup/planSetupTypes"
import { CompanyModel } from "./createCompanyEndpoints"

export interface CreateCompanyDataState {
  company: CompanyModel
  hraPlan: HraPlanModel
  isInitialized: boolean
}

export interface ActionsMap {
  initalize: boolean
  account: CompanyModel
  planSetup: HraPlanModel
}

export type Actions = {
  [Key in keyof ActionsMap]: {
    type: Key
    payload: ActionsMap[Key]
  }
}[keyof ActionsMap]

export const createCompanyReducer = (
  createCompanyData: CreateCompanyDataState,
  action: Actions
): CreateCompanyDataState => {
  switch (action?.type) {
    case "initalize": {
      return {
        isInitialized: true,
        hraPlan: createCompanyData.hraPlan,
        company: createCompanyData.company,
      }
    }
    case "account": {
      return {
        isInitialized: createCompanyData.isInitialized,
        hraPlan: createCompanyData.hraPlan,
        company: action.payload,
      }
    }
    case "planSetup": {
      return {
        isInitialized: createCompanyData.isInitialized,
        hraPlan: action.payload,
        company: createCompanyData.company,
      }
    }
    default: {
      throw Error("Unknown action: " + action)
    }
  }
}
