import { TakeCommandLogoInline } from "@/components/Branding"
import { EXTERNAL_LINKS } from "@/constants"
import { useLoginRedirect } from "@/services/loginRedirectService"
import { useNotifications } from "@/services/notificationService"
import { colors } from "@/theme/palette"
import { verificationCodeValidationSchema } from "@/utils/validations"
import { Alert, Button, Chip, CircularProgress, Grid, Link, Typography } from "@mui/material"
import { Formik } from "formik"
import { get } from "lodash"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "../../useAuth"
import { mfaEmailCodeSend, mfaEmailVerificationSettle, useCognitoUser } from "./mfaService"
import { VerificationCodeField } from "./VerificationCodeField"

import { DateTime } from "luxon"
import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

interface EmailSendCodeStore {
  lastSentCodeIso: string
  clearLastSentCode: () => void
  markLastSentCode: () => void
}

const ALLOWED_CODE_RESEND_INTERVAL_MS = 3 * 60 * 1000

export const useEmailSendCodeStore = create<EmailSendCodeStore>()(
  persist(
    set => ({
      lastSentCodeIso: "",
      clearLastSentCode: () => {
        set({ lastSentCodeIso: "" })
      },
      markLastSentCode: () => {
        const nextSendCodeIso = DateTime.now().toISO()
        set({ lastSentCodeIso: nextSendCodeIso })
      },
    }),
    {
      name: "email-send-code-store",
      storage: createJSONStorage(() => localStorage),
      version: 0, // version 0 is the default version at September 06, 2024
    }
  )
)

const initialValues = {
  verificationCode: "",
}

const ResendTimerUntil = () => {
  const { lastSentCodeIso, clearLastSentCode } = useEmailSendCodeStore()
  const [seconds, setSeconds] = useState(
    lastSentCodeIso
      ? DateTime.fromISO(lastSentCodeIso)
          .plus({
            milliseconds: ALLOWED_CODE_RESEND_INTERVAL_MS,
          })
          .diffNow()
          .as("seconds")
      : 0
  )
  //rerender every second
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(draft => {
        if (draft <= 1) {
          clearInterval(interval)
          clearLastSentCode()
          return 0
        }
        return draft - 1
      })
    }, 1000)
    return () => clearInterval(interval)
  })
  if (seconds <= 0) {
    return <Typography variant="body1">You can resend the code now</Typography>
  }
  if (seconds > 60) {
    return <Typography variant="body1">You can resend the code in {Math.round(seconds / 60)} minutes</Typography>
  }
  return <Typography variant="body1">You can resend the code in {Math.round(seconds)} seconds</Typography>
}

const ResendEmailCodeButton = () => {
  const { notify } = useNotifications("verify-mfa")
  const location = useLocation()
  const { email: stateEmail, password } = location.state ?? {}
  const { lastSentCodeIso, markLastSentCode } = useEmailSendCodeStore()
  const isResendingCode =
    DateTime.fromISO(lastSentCodeIso).diffNow().as("milliseconds") < ALLOWED_CODE_RESEND_INTERVAL_MS

  return (
    <>
      <Button
        variant="contained"
        sx={{ mt: 6, height: 40, backgroundColor: colors.gumDropGreen, color: colors.darkBody }}
        fullWidth
        disabled={isResendingCode}
        startIcon={isResendingCode && <CircularProgress size={20} />}
        onClick={async () => {
          if (isResendingCode) {
            return
          }
          markLastSentCode()
          try {
            await mfaEmailCodeSend(stateEmail, password)
          } catch (error) {
            const message = get(error, "message", "Something went wrong")
            notify(message, "error")
          }
        }}
      >
        {!isResendingCode ? "Send the code again" : <ResendTimerUntil />}
      </Button>
      <Alert severity="warning">
        Check your inbox for the latest HRA Hub email. If you do not see it, please check your spam folder.
      </Alert>
    </>
  )
}

export const VerifyEmailCodeMfa = () => {
  const { signOut, refresh, user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { email: stateEmail, password, isLoggedIn } = location.state ?? {}
  const email = stateEmail ?? user?.email ?? ""
  const { data: cognitoUser } = useCognitoUser(email, password, isLoggedIn)
  const { redirect } = useLoginRedirect()

  useEffect(() => {
    if (!email) {
      navigate("/")
    }
  }, [email, navigate])

  const handleMfaSelection = () => {
    navigate("/mfa/select", {
      state: {
        ...(location.state ?? {}),
        email,
      },
    })
  }

  const handleSignOut = () => {
    signOut()
    navigate("/")
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ maxWidth: "36.667rem" }}>
      <TakeCommandLogoInline />
      <Chip
        label={email}
        color="default"
        sx={{
          mt: 8,
          border: "1pt solid black",
        }}
      />
      <Typography variant="h1" mt={3}>
        Enter the 6-digit code
      </Typography>
      <Typography variant="body1" mt={10}>
        We sent a 6-digit code to your email address. Please enter the code below.
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={verificationCodeValidationSchema}
        onSubmit={async (values, { setErrors, setStatus }) => {
          try {
            await mfaEmailVerificationSettle(cognitoUser!, values.verificationCode)
            await refresh()
            await redirect()
          } catch (error: any) {
            console.error(error)
            const message = error.message || "Something went wrong"

            setStatus({ success: false })
            setErrors({ verificationCode: message })
          }
        }}
      >
        {({ isSubmitting, handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
          <form style={{ width: "100%" }} onSubmit={handleSubmit} noValidate data-qa="sign-in-form">
            <VerificationCodeField
              sx={{
                mt: 8,
                width: "100%",
              }}
              required
              value={values.verificationCode}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(get(touched, `verificationCode`) && get(errors, `verificationCode`))}
              helperText={get(touched, `verificationCode`) && get(errors, `verificationCode`)}
              inputProps={{ "data-qa": "verification-code-input" }}
              data-qa="verification-code"
              name="verificationCode"
              label="Authentication Code"
            />
            <Button
              sx={{ mt: 6 }}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting}
              startIcon={isSubmitting && <CircularProgress size={20} />}
            >
              Authenticate
            </Button>
            <ResendEmailCodeButton />
          </form>
        )}
      </Formik>
      {isLoggedIn && (
        <Button sx={{ mt: 3 }} variant="text" onClick={handleMfaSelection}>
          Back to selection
        </Button>
      )}
      <Typography variant="body1">
        <Button
          variant="text"
          onClick={() => {
            handleSignOut()
          }}
        >
          Switch account
        </Button>
      </Typography>
      <Grid sx={{ mt: 6 }}>
        <Typography variant="caption">
          <Link href={EXTERNAL_LINKS.PRIVACY}>Privacy Policy</Link> |{" "}
          <Link href={EXTERNAL_LINKS.TERMS_OF_SERVICE}>Terms of Service</Link>
        </Typography>
      </Grid>
    </Grid>
  )
}
