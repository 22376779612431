import { AuthLayout } from "@/features/Auth/pages/AuthLayout"
import { MfaSelection } from "@/features/Auth/pages/Mfa/MfaSelection"
import { SetupMfa } from "@/features/Auth/pages/Mfa/SetupMfa"
import { VerifyEmailCodeMfa } from "@/features/Auth/pages/Mfa/VerifyEmailCodeMfa"
import { VerifyMfa } from "@/features/Auth/pages/Mfa/VerifyMfa"
import { RouteObject } from "react-router-dom"
import { SetupSmsMfa } from "./SetupSmsMfa"
import { VerifySmsMfa } from "./VerifySmsMfa"

export const mfaRoutes: RouteObject = {
  path: "/mfa",
  element: <AuthLayout />,
  children: [
    {
      path: "select",
      element: <MfaSelection />,
    },
    {
      path: "setup",
      element: <SetupMfa />,
    },
    {
      path: "verify",
      element: <VerifyMfa />,
    },
    {
      path: "setup/sms",
      element: <SetupSmsMfa />,
    },
    {
      path: "verify/sms",
      element: <VerifySmsMfa />,
    },
    {
      path: "verify/code",
      element: <VerifyEmailCodeMfa />,
    },
  ],
}
