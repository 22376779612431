import { Grid, Typography } from "@mui/material"
import { Helmet } from "react-helmet-async"

export const TcHubSiteSettingsPage = () => (
  <Grid container data-qa="tc-hub-site-settings">
    <Helmet title="TC Hub Site Settings" />
    <Typography variant="h1" gutterBottom display="inline" data-qa="site-settings">
      Site Settings
    </Typography>
  </Grid>
)
