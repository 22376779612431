import { SidebarItem } from "@/components/Sidebar/types"
import { ReactElement } from "react"
import { matchPath } from "react-router-dom"
import { SidebarNavList } from "./SidebarNavList"
import { SidebarNavListItem } from "./SidebarNavListItem"

interface ReduceChildRoutesProps {
  depth: number
  page: SidebarItem
  items: ReactElement[]
  currentRoute: string
}

const isOpen = (page: SidebarItem, currentRoute: string) =>
  !!page.children &&
  !!page.href &&
  !!matchPath(
    {
      path: page.href,
      end: false,
    },
    currentRoute
  )

export const reduceChildRoutes = ({ items, page, depth, currentRoute }: ReduceChildRoutesProps) => [
  ...items,
  <SidebarNavListItem key={page.title} depth={depth} {...page} open={isOpen(page, currentRoute)}>
    {page.children && <SidebarNavList depth={depth + 1} pages={page.children} />}
  </SidebarNavListItem>,
]
