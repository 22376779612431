import { ConfirmationModal } from "@/components/ConfirmationModal"
import { ClampedTextField } from "@/components/TextFields"
import { useNotifications } from "@/services/notificationService"
import { createBusinessUnitSchema } from "@/utils/validations"
import { Divider, Grid } from "@mui/material"
import { Formik } from "formik"
import { useUpdateBusinessUnit } from "../../tcHubService"
import { BusinessUnit } from "../../tcHubTypes"

interface TcHubEditBusinessUnitModalProps {
  isOpen: boolean
  companyId: string
  businessUnit: BusinessUnit
  onClose: () => void
  onUpdateName: (updatedName: string) => void
}

export const TcHubEditBusinessUnitModal = ({
  isOpen,
  companyId,
  businessUnit,
  onClose,
  onUpdateName,
}: TcHubEditBusinessUnitModalProps) => {
  const { notify } = useNotifications("business-unit-update")
  const { mutateAsync: updateBusinessUnit, isPending } = useUpdateBusinessUnit(companyId)
  const ASSIGN_CLASS_INITIAL_VALUES = {
    businessUnitName: businessUnit.name || "",
  }

  return (
    <Formik
      initialValues={ASSIGN_CLASS_INITIAL_VALUES}
      validationSchema={createBusinessUnitSchema}
      onSubmit={async values => {
        try {
          await updateBusinessUnit({ id: businessUnit.id, name: values.businessUnitName, companyId })
          notify("Business Unit updated successfully", "success")
          onUpdateName(values.businessUnitName)
          onClose()
        } catch (error: any) {
          const errorMessage =
            error.responseMessage ??
            "Error updating Business Unit. Please try again later. If the issue persists, contact support for assistance."
          notify(errorMessage, "error")
        }
      }}
      validateOnMount
    >
      {({ values, touched, errors, isValid, handleChange, handleBlur, setFieldValue, resetForm }) => (
        <ConfirmationModal
          title="Edit Business Unit"
          message="A business unit is a distinct part of a company that operates within a specific area of responsibility, often functioning semi-independently.
Examples include Division, Department, Branch, Sector, and Location"
          actionLabel="Save"
          isCancelable
          onConfirm={async () => {
            try {
              await updateBusinessUnit({ id: businessUnit.id, name: values.businessUnitName, companyId })
              notify("Business Unit updated successfully", "success")
              onUpdateName(values.businessUnitName)
              onClose()
              resetForm()
            } catch (error: any) {
              const errorMessage =
                error.responseMessage ??
                "Error updating Business Unit. Please try again later. If the issue persists, contact support for assistance."
              notify(errorMessage, "error")
              resetForm()
            }
          }}
          isOpen={isOpen}
          onClose={() => {
            resetForm()
            setFieldValue("businessUnitName", businessUnit.name)
            onClose()
          }}
          isSubmitting={isPending}
          disabled={!isValid}
        >
          <Grid container>
            <Grid item xs={12}>
              <ClampedTextField
                data-qa="business-unit-text-field"
                type="text"
                name="businessUnitName"
                label="Business Unit Name"
                required
                error={Boolean(touched.businessUnitName && errors.businessUnitName)}
                fullWidth
                helperText={touched.businessUnitName && errors.businessUnitName}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ my: 3 }}
                value={values.businessUnitName}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ width: "100%" }} />
            </Grid>
          </Grid>
        </ConfirmationModal>
      )}
    </Formik>
  )
}
