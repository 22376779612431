import { Grid, Typography } from "@mui/material"
import { ReactNode } from "react"

interface QuestionTitleProps {
  children: ReactNode
}

export const QuestionTitle = ({ children }: QuestionTitleProps) => (
  <Grid item sx={{ mt: 8 }} data-qa="prescreen-questions-page-wrapper">
    <Typography variant="h1" align="center" sx={{ mb: 4, px: "12%" }}>
      {children}
    </Typography>
  </Grid>
)
