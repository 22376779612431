import { mfaRoutes } from "@/features/Auth/pages/Mfa/mfaRoutes"
import { Page404 } from "@/features/Auth/pages/Page404"
import { ResetPassword } from "@/features/Auth/pages/ResetPassword"
import { SignIn } from "@/features/Auth/pages/SignIn"
import { HraPlanDesignResults } from "@/features/HRAPlanDesign/pages/HRAPlanDesignResults"
import { PreScreenQuestions } from "@/features/HRAPlanDesign/pages/PreScreenQuestions"
import { WelcomeScreen } from "@/features/HRAPlanDesign/pages/WelcomeScreen"
import { PeoplePage } from "@/features/People/PeoplePage"
import { RouteObject } from "react-router-dom"
import { benefitsElectionRoutes } from "./BenefitsElectionRoutes"
import { ComponentLibrary } from "./components/ComponentLibrary"
import { employerOnboardingRoutes } from "./EmployerOnboardingRoutes"
import { ApologiesPendingSetup } from "./features/Auth/pages/ApologiesPendingSetup"
import { ForgotPassword } from "./features/Auth/pages/ForgotPassword"
import { SignUp } from "./features/Auth/pages/SignUp"
import { AutoPayPage } from "./features/AutoPay/AutoPayPage"
import { Benefits } from "./features/Benefits/pages/Benefits"
import { CreateCompany } from "./features/CreateCompany/pages/CreateCompany"
import { Dashboard } from "./features/Dashboard/pages/Dashboard"
import { QlePage } from "./features/Dashboard/pages/QlePage"
import { Documents } from "./features/Documents/pages/Documents"
import { HrisImportPage } from "./features/Hris/HrisImportPage"
import { PeopleSettings } from "./features/People/PeopleSettings/PeopleSettings"
import { Profile } from "./features/Profile/pages/Profile"
import { ReportsPage } from "./features/Reports/ReportsPage"
import { SettingsPage } from "./features/Settings/components/SettingsPage"
import { TcHubCreateCompany } from "./features/TCHub/Companies/Steps/TcHubCompanyCreate"
import { tcHubRoutes } from "./TCHubRoutes"

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/people",
    children: [
      {
        path: "",
        element: <PeoplePage />,
      },
      {
        path: ":personId/settings",
        element: <PeopleSettings />,
      },
    ],
  },
  {
    path: "/hris",
    element: <HrisImportPage />,
  },
  {
    path: "/reports",
    element: <ReportsPage />,
  },
  {
    path: "/qle",
    element: <QlePage />,
  },
  {
    path: "/documents",
    element: <Documents />,
  },
  {
    path: "/autopay",
    element: <AutoPayPage />,
  },
  {
    path: "/benefits",
    element: <Benefits />,
  },
  {
    path: "/company/:settings?",
    element: <SettingsPage />,
  },
  {
    path: "/welcome",
    element: <WelcomeScreen />,
  },
  {
    path: "/create-company/:step?",
    element: <CreateCompany />,
  },
  {
    path: "/admin/create-company/:step?",
    element: <TcHubCreateCompany />,
  },
  {
    path: "/hra-determination/:question?",
    element: <PreScreenQuestions />,
  },
  {
    path: "/hra-determination-results/:plan",
    element: <HraPlanDesignResults />,
  },
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
  {
    path: "/component-library",
    element: <ComponentLibrary />,
  },
  {
    path: "/pending-plan-setup",
    element: <ApologiesPendingSetup />,
  },
  ...benefitsElectionRoutes,
  tcHubRoutes,
  employerOnboardingRoutes,
  mfaRoutes,
]
