import { ClearableSearchInput } from "@/components/ClearableSearchInput/ClearableSearchInput"
import { Redirect } from "@/components/Navigation"
import { useGetPeople } from "@/features/People/peopleService"
import { TcHubAddPersonModal } from "@/features/TCHub/Users/TcHubAddPersonModal/TcHubAddPersonModal"
import { createDataQa } from "@/utils/dataQa"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Button, Grid, Tab, Typography } from "@mui/material"
import { Dispatch, ReactNode, SetStateAction, SyntheticEvent, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useSearchParams } from "react-router-dom"
import { isUserAdministrator } from "../Auth/authUtils"
import { AuthGuard } from "../Auth/guards/AuthGuard"
import { useAuth } from "../Auth/useAuth"
import { convertHraClassModelToCustomClassDetails } from "../Benefits/benefitsUtils"
import { useGetCurrentClasses } from "../CreateCompany/components/Steps/Setup/PlanStructure/planStructureService"
import { CustomClassDetails } from "../CreateCompany/components/Steps/Setup/PlanStructure/planStructureTypes"
import { DashboardLayout } from "../Dashboard/components/DashboardLayout"
import { activeUsersFilter, inactiveUsersFilter } from "../TCHub/tcHubService"
import { AddPersonModal } from "./AddPersonModal/AddPersonModal"
import { PeopleProfileDrawer } from "./components/PeopleProfileDrawer"
import { PeopleTable } from "./components/PeopleTable"
import { PeopleTableFilterDropdown } from "./components/PeopleTableFilterDropdown"
import { ACTIVE_PEOPLE_TAB, INACTIVE_PEOPLE_TAB } from "./peopleConstants"
import { Person, PersonModel } from "./peopleTypes"

export const classSelector = (customClassDetails: CustomClassDetails[], employmentId: string) => {
  const className =
    customClassDetails !== undefined
      ? customClassDetails
          .filter(({ healthBenefits }) => healthBenefits.filter(h => h.employmentId === employmentId).length > 0)
          .map(({ customClassName }) => customClassName)
          .join(", ") || "-"
      : "-"

  return className
}

interface CommonPeopleTableProps {
  people: Person[]
  isLoading: boolean
  classField?: (row: PersonModel) => string
  companyField?: (row: PersonModel) => string
  isTchubPage: boolean
}

interface PeopleTabsProps extends CommonPeopleTableProps {
  searchCriteria: string
  hideSendSignUpLink?: boolean
  hideCopySignUpLink?: boolean
  hideWaive?: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  setPersonProfileId: Dispatch<SetStateAction<string>>
  filterStatusValues: string[]
}

const tabPanels = [
  { label: "Active", value: ACTIVE_PEOPLE_TAB, "data-qa": createDataQa("active-people-tab") },
  { label: "Inactive", value: INACTIVE_PEOPLE_TAB, "data-qa": createDataQa("inactive-people-tab") },
] as const

const PeopleTabs = ({
  searchCriteria,
  hideSendSignUpLink,
  hideCopySignUpLink,
  hideWaive,
  setOpen,
  setPersonProfileId,
  filterStatusValues,
  people,
  isLoading,
  isTchubPage,
  classField,
  companyField,
}: PeopleTabsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get("tab") === INACTIVE_PEOPLE_TAB ? INACTIVE_PEOPLE_TAB : ACTIVE_PEOPLE_TAB

  const handleChange = (event: SyntheticEvent, newTab: string) => {
    setSearchParams({ tab: newTab, ...searchParams })
  }

  return (
    <TabContext value={tab} data-qa="people-tabs">
      <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 2 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <TabList onChange={handleChange} data-qa="people-tabs">
              {tabPanels.map(panel => (
                <Tab key={panel.value} label={panel.label} value={panel.value} data-qa={panel["data-qa"]} />
              ))}
            </TabList>
          </Grid>
        </Grid>
      </Box>
      <TabPanel value={ACTIVE_PEOPLE_TAB} sx={{ padding: "0 0", mt: 2 }}>
        <PeopleTable
          people={activeUsersFilter(people ?? [])}
          searchCriteria={searchCriteria}
          statusCriteria={filterStatusValues}
          setPersonProfileId={setPersonProfileId}
          setOpen={setOpen}
          hideSendSignUpLink={hideSendSignUpLink}
          hideCopySignUpLink={hideCopySignUpLink}
          hideWaive={hideWaive}
          isLoading={Boolean(isLoading)}
          isTchubPage={isTchubPage}
          classField={classField}
          companyField={companyField}
        />
      </TabPanel>
      <TabPanel value={INACTIVE_PEOPLE_TAB} sx={{ padding: "0 0", mt: 2 }}>
        <PeopleTable
          people={inactiveUsersFilter(people ?? [])}
          searchCriteria={searchCriteria}
          statusCriteria={filterStatusValues}
          setPersonProfileId={setPersonProfileId}
          setOpen={setOpen}
          hideSendSignUpLink
          hideCopySignUpLink
          hideWaive
          isLoading={Boolean(isLoading)}
          isTchubPage={isTchubPage}
          classField={classField}
          companyField={companyField}
        />
      </TabPanel>
    </TabContext>
  )
}

interface PeoplePageContentProps {
  peopleTitle?: ReactNode
  hideSendSignUpLink?: boolean
  hideCopySignUpLink?: boolean
  hideWaive?: boolean
}

export const PeoplePageContent = ({
  peopleTitle,
  hideSendSignUpLink = false,
  hideCopySignUpLink = false,
  hideWaive = false,
  people,
  isLoading,
  isTchubPage,
  classField,
  companyField,
}: PeoplePageContentProps & CommonPeopleTableProps) => {
  const [searchInputValue, setSearchInputValue] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  const [personProfileId, setPersonProfileId] = useState<string>("")
  const [isAddingPerson, setIsAddingPerson] = useState<boolean>(false)
  const [filterStatusValues, setFilterStatusValues] = useState<string[]>([])

  const handleStatusValuesChange = (statusValues: string[]) => {
    setFilterStatusValues(statusValues)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} mb={5}>
          {peopleTitle ?? (
            <Typography variant="h1" gutterBottom display="inline" data-qa="page-header">
              {isTchubPage ? "Users" : "People"}
            </Typography>
          )}
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
          <Grid item>
            <Grid container justifyContent="flex-start" spacing={3}>
              <Grid item>
                <ClearableSearchInput
                  onChange={event => setSearchInputValue(event.target.value)}
                  handleClearClick={() => setSearchInputValue("")}
                  data-qa="input-search-people-table"
                />
              </Grid>
              <Grid item>
                <PeopleTableFilterDropdown
                  filterStatusValues={filterStatusValues}
                  handleStatusValuesChange={handleStatusValuesChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              data-qa="add-people-button"
              variant="contained"
              onClick={() => setIsAddingPerson(true)}
              startIcon={<AddOutlinedIcon />}
            >
              Add new
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <PeopleTabs
          searchCriteria={searchInputValue}
          setOpen={setOpen}
          setPersonProfileId={setPersonProfileId}
          hideSendSignUpLink={hideSendSignUpLink}
          hideCopySignUpLink={hideCopySignUpLink}
          hideWaive={hideWaive}
          filterStatusValues={filterStatusValues}
          people={people}
          isLoading={isLoading}
          isTchubPage={isTchubPage}
          classField={classField}
          companyField={companyField}
        />
      </Grid>
      <PeopleProfileDrawer open={open} setOpen={setOpen} personProfileId={personProfileId} />
      {isAddingPerson &&
        (isTchubPage ? (
          <TcHubAddPersonModal onClose={() => setIsAddingPerson(false)} />
        ) : (
          <AddPersonModal onClose={() => setIsAddingPerson(false)} />
        ))}
    </>
  )
}

export const PeoplePageShell = ({
  peopleTitle,
  hideSendSignUpLink = false,
  hideCopySignUpLink = true,
  hideWaive = false,
}: PeoplePageContentProps) => {
  const { user, companyId } = useAuth()

  const getPeopleResults = useGetPeople(companyId!, people => people)
  const people = getPeopleResults.data ?? []
  const currentPlanId = user?.companyHRAPlan?.[0].id!
  const { data } = useGetCurrentClasses(companyId!, currentPlanId)
  const [customClassDetails, setCustomClassDetails] = useState<CustomClassDetails[]>([])

  useEffect(() => {
    if (data) {
      const convertedData = convertHraClassModelToCustomClassDetails(data)
      setCustomClassDetails(convertedData)
    }
  }, [data])

  return (
    <AuthGuard>
      <PeoplePageContent
        people={people}
        isLoading={getPeopleResults.isLoading}
        isTchubPage={false}
        classField={row => classSelector(customClassDetails, row.employmentId)}
        peopleTitle={peopleTitle}
        hideSendSignUpLink={hideSendSignUpLink}
        hideCopySignUpLink={hideCopySignUpLink}
        hideWaive={hideWaive}
      />
    </AuthGuard>
  )
}

export const PeoplePage = () => {
  const { user } = useAuth()
  const company = user?.company
  const roles = company?.roles ?? []
  const isAdmin = isUserAdministrator(roles)

  return (
    <DashboardLayout>
      {!isAdmin ? (
        <Redirect to="/" />
      ) : (
        <AuthGuard>
          <Helmet title="People" />
          <PeoplePageShell />
        </AuthGuard>
      )}
    </DashboardLayout>
  )
}
