import { SidebarItem } from "@/components/Sidebar/types"
import { PermissionId } from "@/features/Auth/permissions"
import { useHasPermissions } from "@/services/permissionsService"
import {
  AccountBalanceWalletOutlined,
  AddchartOutlined,
  BackupTable,
  DescriptionOutlined,
  GraphicEqOutlined,
  GroupOutlined,
  SettingsApplicationsOutlined,
} from "@mui/icons-material"
import { useMemo } from "react"

type HasPermissionCallback = (permissions: PermissionId[]) => boolean

const getTcHubMenuItems = (hasPermissions: HasPermissionCallback): SidebarItem[] => [
  {
    href: "/admin/users",
    title: "Users",
    icon: GroupOutlined,
    hidden: !hasPermissions(["tc_hub_users"]),
  },
  {
    href: "/admin/companies",
    title: "Companies",
    icon: DescriptionOutlined,
    hidden: !hasPermissions(["tc_hub_companies"]),
  },
  {
    href: "/admin/autopay",
    title: "AutoPay",
    icon: AccountBalanceWalletOutlined,
    hidden: !hasPermissions(["tc_hub_autopay"]),
    children: [
      {
        href: "/admin/autopay/transfer",
        title: "Transfer",
      },
      {
        href: "/admin/autopay/accounts",
        title: "Accounts",
      },
      {
        href: "/admin/autopay/registration-trackings",
        title: "Registration Tracking",
      },
      {
        href: "/admin/autopay/funding-events",
        title: "Funding Events",
      },
      {
        href: "/admin/autopay/funding-reports",
        title: "Funding Reports",
      },
      {
        href: "/admin/autopay/issues",
        title: "Issues",
      },
    ],
  },
  {
    href: "/admin/journal-entries",
    title: "Ledger",
    icon: BackupTable,
    hidden: !hasPermissions(["tc_hub_journal_entry_management"]),
    children: [
      {
        href: "/admin/journal-entries/create",
        title: "Create Journal Entry",
      },
      {
        href: "/admin/journal-entries/reverse",
        title: "Create Reversal",
      },
    ],
  },
  {
    href: "/admin/compliance",
    title: "Compliance",
    icon: SettingsApplicationsOutlined,
    hidden: !hasPermissions(["tc_hub_compliance"]),
    children: [
      {
        href: "/admin/compliance/recurring-premiums",
        title: "Insurance Coverage",
      },
    ],
  },
  {
    href: "/admin/enrollments",
    title: "Enrollments",
    icon: GraphicEqOutlined,
    hidden: !hasPermissions(["tc_hub_compliance"]),
  },
  {
    href: "/admin/carriers",
    title: "Carriers",
    icon: AddchartOutlined,
    hidden: !hasPermissions(["tc_hub_carriers"]),
    children: [
      {
        href: "/admin/carriers/metadata",
        title: "Carrier Metadata",
      },
      {
        href: "/admin/carriers/questions",
        title: "Carrier Questions",
      },
    ],
  },
  {
    href: "/admin/site-settings",
    title: "Site Settings",
    hidden: !hasPermissions(["tc_hub_site_settings"]),
    icon: AccountBalanceWalletOutlined,
  },
]

export const useTcHubMenuItems = () => {
  const hasPermissions = useHasPermissions(true)

  return useMemo(() => getTcHubMenuItems(hasPermissions), [hasPermissions])
}
