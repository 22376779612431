import { takeCommandPrimary } from "@/theme/palette"
import { VoidFn } from "@/utils/types"
import { CloseOutlined } from "@mui/icons-material"
import { Button, Card, Grid, IconButton, Stack, Typography, useMediaQuery } from "@mui/material"
import { Countdown, CountdownProps } from "./Countdown"

export type BannerProps = {
  title: string
  until?: Date
  variant?: "contained" | "outlined"
  onClose?: VoidFn
  forceMobile?: boolean
  countdownProps?: Partial<CountdownProps>
} & (
  | {
      buttonUrl: string
      buttonLabel: string
      newTab?: boolean
    }
  | {
      buttonUrl?: never
      buttonLabel?: never
      newTab?: never
    }
)

export const Banner = ({
  title,
  until,
  variant = "contained",
  onClose,
  buttonUrl,
  buttonLabel,
  newTab,
  forceMobile,
  countdownProps = {},
}: BannerProps) => {
  const [backgroundColor, dark, buttonVariant, textColor] =
    variant === "contained"
      ? ([takeCommandPrimary.main, true, "outlined", "white"] as const)
      : ([undefined, undefined, "contained", "black"] as const)

  const isDesktop = useMediaQuery(theme => theme.breakpoints.up("sm")) && !forceMobile

  return (
    <Card
      sx={{
        width: "100%",
        backgroundColor,
        p: ".65rem .875rem",
      }}
    >
      <Grid
        container
        direction={isDesktop ? "row" : undefined}
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body1" display="inline" color={textColor}>
          {title}
        </Typography>

        <Grid item>
          <Stack alignItems="center">
            {until && <Countdown until={until} dark={dark} {...countdownProps} />}
            {buttonUrl && (
              <Button
                variant={buttonVariant}
                href={buttonUrl}
                target={newTab ? "_blank" : undefined}
                sx={{
                  color: dark ? "black" : "white",
                }}
              >
                {buttonLabel}
              </Button>
            )}
          </Stack>
        </Grid>
        {!!onClose && (
          <IconButton
            data-qa="close-icon-button"
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              color: theme => theme.palette.grey[900],
              height: "1rem",
              width: "1rem",
              position: "absolute",
              right: -8,
              top: -8,
            }}
          >
            <CloseOutlined className="close-icon" />
          </IconButton>
        )}
      </Grid>
    </Card>
  )
}
