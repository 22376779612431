/* eslint-disable etc/no-commented-out-code */
import {
  AmountTextField,
  AmountTextFieldWithChips,
  HoursPerWeekAmountTextField,
  SingleEmployeeAmountTextFieldWithTable,
} from "@/components/AmountTextField"
import { CheckBoxGroupCard } from "@/components/CheckBoxGroupCard"
import { FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import { SelectMultiField } from "@/components/SelectMultiField"
import { YES_NO_OPTIONS_BOOLEAN } from "@/constants"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { AmountAgesTable } from "@/features/CreateCompany/components/common/AmountAgesTable"
import { Stepper } from "@/features/CreateCompany/components/Stepper"
import {
  ALL_EMPLOYEES,
  CUSTOM,
  ELIGIBLE_FOR_REIMBURSEMENT,
  PREMIUM_ONLY,
  SEASONALITY_RANGES,
  VARY_BY_AGE,
  VARY_BY_FAMILY,
  VARY_BY_FAMILY_SIZE,
  VARY_BY_FAMILY_SIZE_AND_AGE,
  WAITING_PERIODS,
} from "@/features/CreateCompany/createCompanyConstants"
import { PurchaseHraStepProps } from "@/features/CreateCompany/pages/CreateCompany"
import { useNotifications } from "@/services/notificationService"
import { takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { STATE_OPTIONS } from "@/utils/States"
import { Uuid } from "@/utils/types"
import { UnassistedPlanStructureValidationSchema } from "@/utils/validations"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import HelpIcon from "@mui/icons-material/Help"
import { Alert, Box, Button, Card, Collapse, Divider, Grid, Link, Stack, Typography } from "@mui/material"
import { Formik } from "formik"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useGetHraPlan } from "../PlanSetup/planSetupService"
import { HraPlanModel } from "../PlanSetup/planSetupTypes"
import { useFormValuesWithCustomClasses } from "./classesStore"
import { CustomClasses } from "./CustomClasses"
import { MultiSelectClassSection } from "./MultiSelectClassSection"
import { useCreatePlanStructure, useGetCurrentClasses, useUpdatePlanStructure } from "./planStructureService"
import { CustomClassDataWithoutDeleted, PlanStructureFormValues, PlanStructurePatchRequest } from "./planStructureTypes"
import {
  buildCustomClassesPatchRequest,
  hraReimbursementStructureElements,
  hraVaryByFamilySizeOrAgeElements,
} from "./planStructureUtils"

export interface EligibleForReimbursementRadioCardProps {
  subsection?: string
  value: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const EligibleForReimbursementRadioCard = ({
  subsection,
  value,
  setFieldValue,
}: EligibleForReimbursementRadioCardProps) => (
  <Grid item xs={12}>
    <RadioGroupCard
      formName={`plan-structure${subsection && "-" + subsection}`}
      name="eligibleForReimbursement"
      value={value}
      handleChange={setFieldValue}
      elements={ELIGIBLE_FOR_REIMBURSEMENT}
    />
  </Grid>
)

export const WaitingPeriodRadioCard = ({
  subsection,
  value,
  setFieldValue,
  small,
}: {
  subsection?: string
  value: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  small?: boolean
}) => (
  <Grid item xs={12}>
    <RadioGroupCard
      formName={`plan-structure${subsection && "-" + subsection}`}
      name="waitingPeriod"
      value={value}
      handleChange={setFieldValue}
      elements={WAITING_PERIODS}
    />
  </Grid>
)

interface InclusionCheckBoxCardProps {
  elements: { title: string; value: string }[]
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void
  subsection?: string
  small?: boolean
}

export const InclusionCheckBoxCard = ({ subsection, setFieldValue, small, elements }: InclusionCheckBoxCardProps) => (
  <Grid item xs={12} mt={5} mb={10}>
    <CheckBoxGroupCard
      formName={createDataQa("plan-structure", subsection)}
      handleChange={setFieldValue}
      small={small}
      elements={elements}
      error={false}
    />
  </Grid>
)

export const PLAN_STRUCTURE_INITIAL_VALUES = {
  reimbursementStructure: "",
  needMoreThanOneClass: "no-set",
  isSpecificGeography: false,
  geographyDescription: "",
  isFullTime: false,
  isPartTime: false,
  isSalary: false,
  isNonSalary: false,
  isSeasonal: false,
  isNonSeasonal: false,
  eligibleForReimbursement: PREMIUM_ONLY,
  waitingPeriod: "",
  varyByFamilySizeOrAge: "",
  employeeAmount: "0",
  employeeAndChildrenAmount: "0",
  employeeAndSpouseAmount: "0",
  employeeAndSpouseAndChildrenAmount: "0",
  hoursPerWeekAmountLetMeCreate: "",
  monthsPerYearSelectionLetMeCreate: "",
  submit: "false",
}

const hasErrors = (touched: { [field: string]: boolean }, errors: { [field: string]: string }) =>
  Object.keys(touched).some(field => errors[field])

export const PlanStructure = ({ stepData }: PurchaseHraStepProps) => {
  const navigate = useNavigate()
  const { user } = useAuth()
  // FUTURE: Remove these unsafe type casts
  const companyId = getActiveCompany(user)?.companyId as Uuid
  const { data: currentHraPlans } = useGetHraPlan(companyId)
  const hraPlan = currentHraPlans?.[0] ?? ({ id: "" } as unknown as HraPlanModel)
  const { mutateAsync: createPlanStructure } = useCreatePlanStructure(companyId, hraPlan.id)
  const { mutateAsync: updatePlanStructure } = useUpdatePlanStructure(companyId, hraPlan.id)
  const { data } = useGetCurrentClasses(companyId, hraPlan.id) // Retrieve existing data from API
  const classesStoreKey = `${user!.id}-${companyId}-classes`
  const { notify: notifyCreate } = useNotifications("create-plan-structure-classes")
  const { notify: notifyUpdate } = useNotifications("update-plan-structure-classes")
  const [selectedGeographicValues, setSelectedGeographicValues] = useState([])

  const {
    initialFormValues,
    customClassData,
    clearCustomClassesFromStore,
    addCustomClassToStore,
    deleteCustomClassesFromStore,
    loadedClassesType,
    deletedClasses,
  } = useFormValuesWithCustomClasses({ data, hraPlan, classesStoreKey })

  const [isLoading, setIsLoading] = useState(false)
  const [agesTableOpen, setAgesTableOpen] = useState(false)

  const createNewClasses = async (values: PlanStructureFormValues) => {
    if (values.reimbursementStructure === CUSTOM) {
      await createPlanStructure({
        values,
        customclassValues: {
          seasonalMonthQualification: Number(values.monthsPerYearSelectionLetMeCreate),
          partTimeHourQualification: Number(values.hoursPerWeekAmountLetMeCreate),
          customClassData,
        },
      })
      clearCustomClassesFromStore()
    } else if (values.reimbursementStructure === ALL_EMPLOYEES || values.reimbursementStructure === VARY_BY_FAMILY) {
      await createPlanStructure({
        values,
        customclassValues: null,
      })
    } else {
      throw Error("Invalid Reimbursement Structure")
    }
    notifyCreate("Successfully created Plan Structure classes", "success")
    navigate("/employer-onboarding")
  }

  const showUpdateSuccessMessage = ({
    successMessage = "Successfully updated Plan Structure classes",
  }: {
    successMessage?: string
  }) => {
    notifyUpdate(successMessage, "success")
    navigate("/employer-onboarding")
  }

  const updateCustomClasses = async (patchRequest: PlanStructurePatchRequest) => {
    if (patchRequest) {
      await updatePlanStructure(patchRequest)
      clearCustomClassesFromStore()
      showUpdateSuccessMessage({})
    } else {
      showUpdateSuccessMessage({
        successMessage: "No changes made to Plan Structure Custom Classes",
      })
    }
  }

  /**
   * Updates the plan structure based on four cases:
   * 1. A fixed class was updated.
   * 2. Custom classes were deleted, and a fixed class was created.
   * 3. Custom classes were updated.
   * 4. A fixed class was deleted, and custom classes were created.
   * @param values Form values
   */
  const updateClasses = async (values: PlanStructureFormValues) => {
    if (values.reimbursementStructure === ALL_EMPLOYEES || values.reimbursementStructure === VARY_BY_FAMILY) {
      // Case 1: Fixed class was updated
      if (loadedClassesType === "FIXED") {
        const patchRequest = buildCustomClassesPatchRequest({
          fixedClassData: values,
          fixedClassOperation: "UPDATE",
        })

        await updatePlanStructure(patchRequest!)
        showUpdateSuccessMessage({})
      }
      // Case 2: Custom classes were deleted, and a fixed class was created.
      else if (loadedClassesType === CUSTOM) {
        const patchRequest = buildCustomClassesPatchRequest({
          fixedClassData: values,
          fixedClassOperation: "CREATE",
          loadedData: data,
        })

        await updatePlanStructure(patchRequest!)
        clearCustomClassesFromStore()
        showUpdateSuccessMessage({})
      }
    } else if (values.reimbursementStructure === CUSTOM) {
      // Case 3: Custom classes were updated
      if (loadedClassesType === CUSTOM) {
        const deletedClassesArray = deletedClasses ? (deletedClasses as string[]) : []

        const patchRequest = buildCustomClassesPatchRequest({
          customClassData,
          deletedClasses: deletedClassesArray,
          loadedData: data,
        })

        await updateCustomClasses(patchRequest!)
      }
      // A fixed class was deleted, and custom classes were created.
      else if (loadedClassesType === "FIXED") {
        // Implement the logic for this case
        const patchRequest = buildCustomClassesPatchRequest({
          fixedClassData: values,
          fixedClassOperation: "DELETE",
          customClassData,
        })

        await updatePlanStructure(patchRequest!)
        clearCustomClassesFromStore()
        showUpdateSuccessMessage({})
      }
    } else {
      throw Error("Invalid Reimbursement Structure")
    }
  }

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
      validationSchema={UnassistedPlanStructureValidationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        setIsLoading(true)
        const isUpdate = !!loadedClassesType

        const errorMessage = isUpdate
          ? `Error Updating classes for company ${companyId}`
          : `Error Creating classes for company ${companyId}`

        try {
          if (isUpdate) {
            await updateClasses(values)
          } else {
            await createNewClasses(values)
          }
        } catch (error: any) {
          console.error(error)
          setStatus({ success: false })
          setSubmitting(false)
          notifyCreate(errorMessage, "error")
        } finally {
          setIsLoading(false)
        }
      }}
    >
      {({
        errors,
        handleSubmit,
        isValid,
        dirty,
        values,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        resetForm,
      }) => (
        <>
          <Stepper steps={stepData} activeStepIndex={2} isError={hasErrors(touched, errors)} />
          <form noValidate onSubmit={handleSubmit} data-qa="create-company-setup-form">
            {errors.submit && (
              <Alert severity="warning" data-qa="error-banner-create-company-setup-form">
                {errors.submit}
              </Alert>
            )}
            <Grid container spacing={4} mt={5}>
              <Grid item xs={12}>
                <Typography variant="h1" mb={5}>
                  Plan Structure
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Do you need more than one class?</Typography>
              </Grid>
              <RadioGroupCard
                name="needMoreThanOneClass"
                formName="plan-structure"
                elements={YES_NO_OPTIONS_BOOLEAN}
                value={values.needMoreThanOneClass}
                handleChange={(field: string, value: boolean | string) => {
                  resetForm()
                  setFieldValue(field, value)
                  setFieldValue("reimbursementStructure", value ? "CUSTOM" : "")
                }}
                required
                label=""
                labelVariant="h5"
              />
              <Grid item xs={12}>
                <Card sx={{ maxWidth: "100%", pl: "1.3rem", pr: "1.3rem" }}>
                  <Stack direction="row" sx={{ width: "100%", overflow: "hidden" }}>
                    <HelpIcon sx={{ color: takeCommandPrimary.main, width: "1.5rem", height: "1.5rem", my: 3 }} />
                    <Divider sx={{ width: "100%", mb: 6 }} />
                  </Stack>
                  <Grid container>
                    <Grid item xs={12} sx={{ pl: "0.3rem" }}>
                      <Typography variant="h6">
                        ICHRA classes help employers prioritize their health benefits budget.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ul style={{ display: "flex", alignContent: "space-between" }}>
                        <li style={{ marginRight: "1.75rem", maxWidth: "20%" }}>
                          <Typography variant="body1" pt="1rem">
                            Employee classes{" "}
                            <Typography component="span" variant="body1bold" color="primary">
                              separate employees into groups by legitimate job-based criteria{" "}
                            </Typography>
                            like hours worked or location.
                          </Typography>
                        </li>
                        <li style={{ marginRight: "1.75rem", maxWidth: "20%" }}>
                          <Typography variant="body1" pt="1rem">
                            The nice thing about ICHRA is that{" "}
                            <Typography component="span" variant="body1bold" color="primary">
                              you can combine it with a traditional group health plan.
                            </Typography>
                          </Typography>
                        </li>
                        <li style={{ marginRight: "1.75rem", maxWidth: "20%" }}>
                          <Typography variant="body1" pt="1rem">
                            Employers may offer one class of employees{" "}
                            <Typography component="span" variant="body1bold" color="primary">
                              a group health plan and another class of employees an ICHRA.
                            </Typography>
                          </Typography>
                        </li>
                        <li style={{ marginRight: "1.75rem", maxWidth: "20%" }}>
                          <Typography variant="body1" pt="1rem">
                            The only caveat is that{" "}
                            <Typography component="span" variant="body1bold" color="primary">
                              employers cannot offer employees in the same class
                            </Typography>{" "}
                            (say full-time employees) the choice between a traditional group health plan or ICHRA.
                          </Typography>
                        </li>
                        <li style={{ marginRight: "1.75rem", maxWidth: "20%" }}>
                          <Typography variant="body1" pt="1rem">
                            Each class{" "}
                            <Typography component="span" variant="body1bold" color="primary">
                              needs to have only one benefit offering.
                            </Typography>
                          </Typography>
                        </li>
                      </ul>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 3 }} />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} mt={-4}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ backgroundColor: "colors.lightTealShade", padding: 4 }}
                >
                  <Box>
                    <Typography variant="h6">Do you have questions about the ICHRA class rules?</Typography>
                    <Typography variant="body1" pt="1rem">
                      Let’s look at the ICHRA class rules, which ensure that benefits are offered fairly to all
                      employees.
                    </Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    component={Link}
                    href="https://www.takecommandhealth.com/blog/ichra-class-rules"
                    target="_blank"
                  >
                    Learn more about ICHRA classes
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            <Grid item>
              <Collapse
                in={!values.needMoreThanOneClass && values.needMoreThanOneClass !== "no-set"}
                timeout="auto"
                orientation="vertical"
                translate="yes"
                unmountOnExit
              >
                <Grid item xs={12} sx={{ mt: 5 }}>
                  <Typography variant="h6">How do you want to structure your reimbursements?</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">You will only reimburse what employees actually spend.</Typography>
                </Grid>
                <RadioGroupCard
                  formName="plan-structure"
                  name="reimbursementStructure"
                  value={values.reimbursementStructure}
                  handleChange={(field: string, value: string) => {
                    //resetForm()
                    setFieldValue(field, value)
                  }}
                  elements={hraReimbursementStructureElements.slice(0, -1)}
                />
              </Collapse>
              <Collapse
                in={values.reimbursementStructure === ALL_EMPLOYEES}
                timeout="auto"
                orientation="vertical"
                translate="yes"
                unmountOnExit
              >
                <>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Amount</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <AmountTextField
                        name="employeeAmount"
                        touched={touched.employeeAmount!}
                        errorString={errors.employeeAmount!}
                        error={Boolean(touched.employeeAmount && errors.employeeAmount)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.employeeAmount?.toString()}
                        label="Amount"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Waiting Period</Typography>
                    </Grid>
                    <WaitingPeriodRadioCard
                      subsection={ALL_EMPLOYEES}
                      value={values.waitingPeriod}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </>
              </Collapse>
              <Collapse
                in={values.reimbursementStructure === VARY_BY_FAMILY}
                timeout="auto"
                orientation="vertical"
                translate="yes"
                unmountOnExit
              >
                <>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12}>
                      <Typography variant="caption">Select one.</Typography>
                    </Grid>
                    <RadioGroupCard
                      formName="plan-structure"
                      name="varyByFamilySizeOrAge"
                      value={values.varyByFamilySizeOrAge}
                      handleChange={(field: string, value: string) => {
                        setFieldValue("reimbursementStructure", VARY_BY_FAMILY)
                        setFieldValue(field, value)
                      }}
                      elements={hraVaryByFamilySizeOrAgeElements}
                    />
                  </Grid>
                  <Collapse
                    in={values.varyByFamilySizeOrAge === VARY_BY_AGE}
                    timeout="auto"
                    orientation="vertical"
                    translate="yes"
                    unmountOnExit
                  >
                    <>
                      <Grid container spacing={4} mt={5}>
                        <MultiSelectClassSection
                          title="Please select full-time, part-time, or both"
                          formName="plan-structure"
                          handleChange={setFieldValue}
                          elements={[
                            { title: "Full-Time", value: "isFullTime" },
                            { title: "Part-Time", value: "isPartTime" },
                          ]}
                          dataQa="full-part-time-title"
                        />

                        <MultiSelectClassSection
                          title="Please select salary, non-salary, or both"
                          formName="plan-structure"
                          handleChange={setFieldValue}
                          elements={[
                            { title: "Salary", value: "isSalary" },
                            { title: "Non-Salary", value: "isNonSalary" },
                          ]}
                          dataQa="salary-non-title"
                        />

                        <MultiSelectClassSection
                          title="Please select seasonal, non-seasonal, or both"
                          formName="plan-structure"
                          handleChange={setFieldValue}
                          elements={[
                            { title: "Seasonal", value: "isSeasonal" },
                            { title: "Non-Seasonal", value: "isNonSeasonal" },
                          ]}
                          dataQa="seasonal-non-title"
                        />
                        <Grid item xs={12} mt={3}>
                          <Typography variant="h6" textAlign="left" data-qa="geographic-area-title">
                            Please select specific geographic area or all areas
                          </Typography>
                          <Grid item xs={12} mt={5}>
                            <RadioGroupCard
                              formName="classEditor"
                              name="isSpecificGeography"
                              value={values.isSpecificGeography}
                              handleChange={setFieldValue}
                              elements={[
                                {
                                  title: "Specific Geographic Areas",
                                  value: true,
                                },
                                {
                                  title: "All Geographic Areas",
                                  value: false,
                                },
                              ]}
                            />
                          </Grid>
                        </Grid>
                        {values.isSpecificGeography && (
                          <Grid item xs={6}>
                            <SelectMultiField
                              selectedValues={selectedGeographicValues}
                              fieldLabel="Specific Geographic Area"
                              required
                              data={[...STATE_OPTIONS]}
                              name="Specific Geographic Area"
                              sx={{ my: 0 }}
                              placeholder="Specific Geographic Area"
                              dataQa="geographic-select"
                              onChange={event => {
                                setSelectedGeographicValues(event.target.value)
                                setFieldValue(
                                  "geographyDescription",
                                  (event.target.value as unknown as string[]).join(",")
                                )
                              }}
                              onBlur={handleBlur}
                              helperText={touched.geographyDescription ? errors.geographyDescription : undefined}
                              error={Boolean(touched.geographyDescription && errors.geographyDescription)}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Typography variant="h6">Reimbursement amounts per month</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <SingleEmployeeAmountTextFieldWithTable
                            errorString={errors.employeeAmount!}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Employee - Age 21"
                            agesTableOpen={agesTableOpen}
                            setAgesTableOpen={setAgesTableOpen}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={4} mt={5}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Waiting period</Typography>
                        </Grid>
                        <WaitingPeriodRadioCard
                          subsection={VARY_BY_AGE}
                          value={values.waitingPeriod}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                    </>
                  </Collapse>
                  <Collapse
                    in={values.varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE}
                    timeout="auto"
                    orientation="vertical"
                    translate="yes"
                    unmountOnExit
                  >
                    <>
                      <Grid container spacing={4} mt={5}>
                        <MultiSelectClassSection
                          title="Please select full-time, part-time, or both"
                          formName="plan-structure"
                          handleChange={setFieldValue}
                          elements={[
                            { title: "Full-Time", value: "isFullTime" },
                            { title: "Part-Time", value: "isPartTime" },
                          ]}
                          dataQa="full-part-time-title"
                        />

                        <MultiSelectClassSection
                          title="Please select salary, non-salary, or both"
                          formName="plan-structure"
                          handleChange={setFieldValue}
                          elements={[
                            { title: "Salary", value: "isSalary" },
                            { title: "Non-Salary", value: "isNonSalary" },
                          ]}
                          dataQa="salary-non-title"
                        />

                        <MultiSelectClassSection
                          title="Please select seasonal, non-seasonal, or both"
                          formName="plan-structure"
                          handleChange={setFieldValue}
                          elements={[
                            { title: "Seasonal", value: "isSeasonal" },
                            { title: "Non-Seasonal", value: "isNonSeasonal" },
                          ]}
                          dataQa="seasonal-non-title"
                        />
                        <Grid item xs={12} mt={3}>
                          <Typography variant="h6" textAlign="left" data-qa="geographic-area-title">
                            Please select specific geographic area or all areas
                          </Typography>
                          <Grid item xs={12} mt={5}>
                            <RadioGroupCard
                              formName="classEditor"
                              name="isSpecificGeography"
                              value={values.isSpecificGeography}
                              handleChange={setFieldValue}
                              elements={[
                                {
                                  title: "Specific Geographic Areas",
                                  value: true,
                                },
                                {
                                  title: "All Geographic Areas",
                                  value: false,
                                },
                              ]}
                            />
                          </Grid>
                        </Grid>
                        {values.isSpecificGeography && (
                          <Grid item xs={6}>
                            <SelectMultiField
                              selectedValues={selectedGeographicValues}
                              fieldLabel="Specific Geographic Area"
                              required
                              data={[...STATE_OPTIONS]}
                              name="Specific Geographic Area"
                              sx={{ my: 0 }}
                              placeholder="Specific Geographic Area"
                              dataQa="geographic-select"
                              onChange={event => {
                                setSelectedGeographicValues(event.target.value)
                                setFieldValue(
                                  "geographyDescription",
                                  (event.target.value as unknown as string[]).join(",")
                                )
                              }}
                              onBlur={handleBlur}
                              helperText={touched.geographyDescription ? errors.geographyDescription : undefined}
                              error={Boolean(touched.geographyDescription && errors.geographyDescription)}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Typography variant="h6">Reimbursement amounts per month</Typography>
                          <Typography variant="caption">
                            Amounts entered for each group are total monthly costs for the entire group, not per person.
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <AmountTextField
                            error={Boolean(touched.employeeAmount && errors.employeeAmount)}
                            touched={touched.employeeAmount!}
                            errorString={errors.employeeAmount!}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.employeeAmount?.toString()}
                            label="Employee - Age 21"
                            name="employeeAmount"
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <AmountTextField
                            error={Boolean(touched.employeeAndSpouseAmount && errors.employeeAndSpouseAmount)}
                            touched={touched.employeeAndSpouseAmount!}
                            errorString={errors.employeeAndSpouseAmount!}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.employeeAndSpouseAmount?.toString()}
                            label="Employee + Spouse"
                            name="employeeAndSpouseAmount"
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <AmountTextField
                            error={Boolean(touched.employeeAndChildrenAmount && errors.employeeAndChildrenAmount)}
                            touched={touched.employeeAndChildrenAmount!}
                            errorString={errors.employeeAndChildrenAmount!}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.employeeAndChildrenAmount?.toString()}
                            label="Employee + Children"
                            name="employeeAndChildrenAmount"
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <AmountTextField
                            error={Boolean(
                              touched.employeeAndSpouseAndChildrenAmount && errors.employeeAndSpouseAndChildrenAmount
                            )}
                            touched={touched.employeeAndSpouseAndChildrenAmount!}
                            errorString={errors.employeeAndSpouseAndChildrenAmount!}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.employeeAndSpouseAndChildrenAmount?.toString()}
                            label="Employee + Spouse + Children"
                            name="employeeAndSpouseAndChildrenAmount"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={4} mt={5}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Waiting period</Typography>
                        </Grid>
                        <WaitingPeriodRadioCard
                          subsection={VARY_BY_FAMILY_SIZE}
                          value={values.waitingPeriod}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                    </>
                  </Collapse>
                  <Collapse
                    in={values.varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE_AND_AGE}
                    timeout="auto"
                    orientation="vertical"
                    translate="yes"
                    unmountOnExit
                  >
                    <>
                      <Grid container spacing={4} mt={5}>
                        <MultiSelectClassSection
                          title="Please select full-time, part-time, or both"
                          formName="plan-structure"
                          handleChange={setFieldValue}
                          elements={[
                            { title: "Full-Time", value: "isFullTime" },
                            { title: "Part-Time", value: "isPartTime" },
                          ]}
                          dataQa="full-part-time-title"
                        />

                        <MultiSelectClassSection
                          title="Please select salary, non-salary, or both"
                          formName="plan-structure"
                          handleChange={setFieldValue}
                          elements={[
                            { title: "Salary", value: "isSalary" },
                            { title: "Non-Salary", value: "isNonSalary" },
                          ]}
                          dataQa="salary-non-title"
                        />

                        <MultiSelectClassSection
                          title="Please select seasonal, non-seasonal, or both"
                          formName="plan-structure"
                          handleChange={setFieldValue}
                          elements={[
                            { title: "Seasonal", value: "isSeasonal" },
                            { title: "Non-Seasonal", value: "isNonSeasonal" },
                          ]}
                          dataQa="seasonal-non-title"
                        />
                        <Grid item xs={12} mt={3}>
                          <Typography variant="h6" textAlign="left" data-qa="geographic-area-title">
                            Please select specific geographic area or all areas
                          </Typography>
                          <Grid item xs={12} mt={5}>
                            <RadioGroupCard
                              formName="classEditor"
                              name="isSpecificGeography"
                              value={values.isSpecificGeography}
                              handleChange={setFieldValue}
                              elements={[
                                {
                                  title: "Specific Geographic Areas",
                                  value: true,
                                },
                                {
                                  title: "All Geographic Areas",
                                  value: false,
                                },
                              ]}
                            />
                          </Grid>
                        </Grid>
                        {values.isSpecificGeography && (
                          <Grid item xs={6}>
                            <SelectMultiField
                              selectedValues={selectedGeographicValues}
                              fieldLabel="Specific Geographic Area"
                              required
                              data={[...STATE_OPTIONS]}
                              name="Specific Geographic Area"
                              sx={{ my: 0 }}
                              placeholder="Specific Geographic Area"
                              dataQa="geographic-select"
                              onChange={event => {
                                setSelectedGeographicValues(event.target.value)
                                setFieldValue(
                                  "geographyDescription",
                                  (event.target.value as unknown as string[]).join(",")
                                )
                              }}
                              onBlur={handleBlur}
                              helperText={touched.geographyDescription ? errors.geographyDescription : undefined}
                              error={Boolean(touched.geographyDescription && errors.geographyDescription)}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Typography variant="h6" data-qa="varybyfamilysizeandage-reimbursement-amounts">
                            Reimbursement amounts per month
                          </Typography>
                          <Typography variant="caption">
                            Amounts entered for each group are total monthly costs for the entire group, not per person.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex">
                          <Typography variant="caption" data-qa="varybyfamilysizeandage-reimbursement-description">
                            Here you can see your generated reimbursement roles by age:
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={2}
                            display="flex"
                            ml="auto"
                            alignItems="center"
                            sx={{ cursor: "pointer" }}
                            onClick={() => setAgesTableOpen(!agesTableOpen)}
                          >
                            {agesTableOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            <Link ml="auto" alignItems="center" color="black">
                              <Typography variant="body2" alignItems="center">
                                Expand
                              </Typography>
                            </Link>
                          </Stack>
                        </Grid>
                        <AmountAgesTable agesTableOpen={agesTableOpen} isAgeAndFamily />
                        <AmountTextFieldWithChips
                          touched={touched.employeeAmount!}
                          errorString={errors.employeeAmount!}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.employeeAmount?.toString()}
                          label="Employee - Age 21"
                          name="employeeAmount"
                        />
                        <AmountTextFieldWithChips
                          touched={touched.employeeAndSpouseAmount!}
                          errorString={errors.employeeAndSpouseAmount!}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.employeeAndSpouseAmount?.toString()}
                          label="Employee + Spouse"
                          name="employeeAndSpouseAmount"
                        />
                        <AmountTextFieldWithChips
                          touched={touched.employeeAndChildrenAmount!}
                          errorString={errors.employeeAndChildrenAmount!}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.employeeAndChildrenAmount?.toString()}
                          label="Employee + Children"
                          name="employeeAndChildrenAmount"
                        />
                        <AmountTextFieldWithChips
                          touched={touched.employeeAndSpouseAndChildrenAmount!}
                          errorString={errors.employeeAndSpouseAndChildrenAmount!}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.employeeAndSpouseAndChildrenAmount?.toString()}
                          label="Employee + Spouse + Children"
                          name="employeeAndSpouseAndChildrenAmount"
                        />
                      </Grid>
                      <Grid container spacing={4} mt={5}>
                        <Grid item xs={12}>
                          <Typography variant="h6" data-qa="varybyfamilysizeandage-waiting-period">
                            Waiting period
                          </Typography>
                        </Grid>
                        <WaitingPeriodRadioCard
                          subsection={VARY_BY_FAMILY_SIZE_AND_AGE}
                          value={values.waitingPeriod}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                    </>
                  </Collapse>
                </>
              </Collapse>
              <Collapse
                in={values.needMoreThanOneClass !== "no-set" && Boolean(values.needMoreThanOneClass)}
                timeout="auto"
                orientation="vertical"
                translate="yes"
                unmountOnExit
              >
                <>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12}>
                      <Typography variant="h6" data-qa="plan-structure-custom-help-label">
                        Next, we'll help you design your employee classes and reimbursement structure for each class.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" data-qa="plan-structure-custom-help-description">
                        You will need to design your classes so that each employee can only fall into one class. Don't
                        worry, we'll help you make sure your classes are compliant!
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomClasses
                        customClasses={customClassData as CustomClassDataWithoutDeleted}
                        createCustomClass={addCustomClassToStore}
                        deleteCustomClasses={deleteCustomClassesFromStore}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12}>
                      <Typography variant="h6" data-qa="plan-structure-custom-hours-label">
                        How many hours per week must employees be scheduled to work to be considered full-time?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" data-qa="plan-structure-custom-hours-description">
                        This information helps us distinguish between part-time and full-time employees.
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <HoursPerWeekAmountTextField
                        name="hoursPerWeekAmountLetMeCreate"
                        touched={touched.hoursPerWeekAmountLetMeCreate!}
                        errorString={errors.hoursPerWeekAmountLetMeCreate!}
                        error={Boolean(touched.hoursPerWeekAmountLetMeCreate && errors.hoursPerWeekAmountLetMeCreate)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.hoursPerWeekAmountLetMeCreate}
                        label="Hours per week"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        Please enter the amount of hours considered to be full-time
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12} mt={5}>
                      <Typography variant="h6" data-qa="plan-structure-custom-months-label">
                        How many months per year must employees be scheduled to work to be considered non-seasonal?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" data-qa="plan-structure-custom-months-description">
                        This information helps us distinguish between seasonal and non-seasonal employees.
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <SelectField
                        dataQa="months-per-year-option"
                        data={SEASONALITY_RANGES}
                        type="text"
                        name="monthsPerYearSelectionLetMeCreate"
                        label="Months per year"
                        required
                        value={values.monthsPerYearSelectionLetMeCreate}
                        placeholder="Please select"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        Please select the range of months considered to be non-seasonal
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              </Collapse>
              <FlowNavigationButtons
                handleBack={() => {
                  navigate("/create-company/plan-setup")
                }}
                type="submit"
                disabled={!(isValid && dirty)}
                isSubmitting={isLoading}
              />
            </Grid>
          </form>
        </>
      )}
    </Formik>
  )
}
