import { createDataQa, WithDataQa } from "@/utils/dataQa"
import { Alert, AlertColor, Typography } from "@mui/material"
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack"
import { forwardRef, useCallback } from "react"

export interface SnackbarProps extends WithDataQa<CustomContentProps> {
  selection: AlertColor
}

export const SimpleNotification = forwardRef<HTMLDivElement, SnackbarProps>(
  ({ id, selection, message, "data-qa": dataQa }, ref) => {
    const { closeSnackbar } = useSnackbar()

    const handleDismiss = useCallback(() => {
      closeSnackbar(id)
    }, [id, closeSnackbar])

    return (
      <SnackbarContent ref={ref}>
        <Alert
          onClose={handleDismiss}
          severity={selection}
          sx={{ minWidth: { md: "37rem" } }}
          data-qa={createDataQa(dataQa, "snackbar")}
        >
          <Typography> {message}</Typography>
        </Alert>
      </SnackbarContent>
    )
  }
)

SimpleNotification.displayName = "SimpleNotification"
