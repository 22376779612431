import { AuthUser } from "@/features/Auth/authTypes"
import { getRoleById, PermissionId, roleHasPermissions } from "@/features/Auth/permissions"
import { useAuth } from "@/features/Auth/useAuth"
import { constant, isObject } from "lodash"
import { useMemo } from "react"
import { getActiveCompany, getStarCompany } from "../features/Auth/services/authService"

type PermissionCheckOptions =
  | {
      internalOnly?: boolean
      matchType?: "some" | "every"
    }
  | boolean
  | undefined

const getCompany = (user: AuthUser, internalOnly: boolean) =>
  internalOnly ? getStarCompany(user) : getActiveCompany(user)

export const useHasPermissions = (options: PermissionCheckOptions = false) => {
  // when options is not object, it means internalOnly
  const internalOnly = !!(isObject(options) ? options?.internalOnly : options)
  // when options is not object, it means matchType is every
  const matchType = (isObject(options) ? options?.matchType : null) ?? "every"
  const { user } = useAuth()

  return useMemo(() => {
    if (!user) return constant(false) as (permissionIds: PermissionId[]) => boolean
    const company = getCompany(user, internalOnly)

    return (permissionIds: PermissionId[]) => {
      if (!permissionIds?.length) return false
      const roleList = company?.roles.map(r => getRoleById(r.roleId))

      if (!roleList) return false

      return roleList.some(role => roleHasPermissions(role, permissionIds, matchType))
    }
  }, [user, internalOnly, matchType])
}

export const usePermissions = (
  permissionIds: PermissionId | PermissionId[],
  options: PermissionCheckOptions = false
) => {
  const permissions = Array.isArray(permissionIds) ? permissionIds : [permissionIds]
  const hasPermissions = useHasPermissions(options)

  return hasPermissions(permissions)
}
