import { ShoppingPerson } from "@/features/BenefitsElection/benefitsElectionTypes"
import {
  ageQualifiesForMedicare,
  calculateAge,
  isDateOfBirthValid,
} from "@/features/BenefitsElection/benefitsElectionUtils"
import { createDateFromText, DATE_FORMAT_MONTH_DAY_YEAR, isTextValidDate } from "@/utils/formatting"
import { isDate } from "lodash"
import { useEffect, useState } from "react"
import { useBenefitsElectionStore } from "../benefitsElectionStore"

export const useValidateMedicareQualification = (currentEditingMember?: ShoppingPerson) => {
  const [isMedicareDialogOpen, setIsMedicareDialogOpen] = useState(false)
  const [isMedicareEligible, setIsMedicareEligible] = useState(false)

  // Set the isMedicareEligible value depending on the age of the currentEditingMember
  useEffect(() => {
    if (!currentEditingMember) {
      setIsMedicareEligible(false)
    } else {
      const age = calculateAge(currentEditingMember.personalInformation?.dateOfBirth as Date)

      setIsMedicareEligible(ageQualifiesForMedicare(age))
    }
  }, [currentEditingMember])

  const closeMedicareDialog = () => setIsMedicareDialogOpen(false)

  const handleBirthDateChange = (date: string | Date | null) => {
    if (isDate(date) && isDateOfBirthValid(date)) {
      const age = calculateAge(date)

      if (ageQualifiesForMedicare(age)) {
        setTimeout(() => {
          setIsMedicareEligible(true)
          setIsMedicareDialogOpen(true)
        }, 300)
      } else {
        setIsMedicareEligible(false)
      }
    }
  }

  const handleBirthDateTextChange = (value: string) => {
    if (value && isTextValidDate(value, DATE_FORMAT_MONTH_DAY_YEAR)) {
      handleBirthDateChange(createDateFromText(value, DATE_FORMAT_MONTH_DAY_YEAR))
    }
  }

  return {
    isMedicareDialogOpen,
    isMedicareEligible,
    closeMedicareDialog,
    handleBirthDateChange,
    handleBirthDateTextChange,
  }
}

export const useEmployeeIsMedicare = () => {
  const employee = useBenefitsElectionStore(state => state.employee)
  return useValidateMedicareQualification(employee).isMedicareEligible
}
