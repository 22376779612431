import { DatePickerField } from "@/components/DatePickerField"
import { SelectField } from "@/components/SelectField"
import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import {
  fundingEventStatusOptions,
  fundingEventTimePeriodOptions,
  useFundingEventCreate,
} from "@/features/TCHub/AutoPay/FundingEvents/fundingEventDetailsService"
import { useNotifications } from "@/services/notificationService"
import { getOnlyDate } from "@/utils/dates"
import { fundingEventDetailsSchema } from "@/utils/validations"
import CloseIcon from "@mui/icons-material/Close"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material"
import { Formik, FormikProps } from "formik"
import { DateTime } from "luxon"
import { Helmet } from "react-helmet-async"
import { CompanyAutopaySearchAutoComplete } from "../../components/CompanyAutopaySearchAutoComplete"

const initialValues = {
  company: null as CompanyModel | null,
  periodEndAt: "",
  periodStartAt: "",
  status: "IN_PROGRESS",
  timePeriod: "",
  timePeriodLabel: "",
  submit: false,
} as const

interface NewFundingEventDetailsFormProps extends FormikProps<typeof initialValues> {}

const NewFundingEventDetailsForm = ({
  handleBlur,
  handleChange,
  setFieldValue,
  errors,
  values,
  touched,
}: NewFundingEventDetailsFormProps) => (
  <Grid container spacing={4} my={6}>
    <Grid item xs={12}>
      <CompanyAutopaySearchAutoComplete
        name="company"
        label="Company"
        touched={Boolean(errors.company)}
        error={errors.company?.toString()}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        value={values.company}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectField
        dataQa="time-period-select"
        data={fundingEventTimePeriodOptions}
        type="text"
        name="timePeriod"
        label="Time Period"
        required
        value={values.timePeriod ?? ""}
        placeholder="Select time period"
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        required
        fullWidth
        name="timePeriodLabel"
        label="Time period label"
        value={values.timePeriodLabel}
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(touched.timePeriodLabel && errors.timePeriodLabel)}
        helperText={touched.timePeriodLabel && errors.timePeriodLabel}
        data-qa="time-period-label"
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <DatePickerField
        data-qa="period-start-date-picker"
        name="periodStartAt"
        label="Period Start Date"
        value={getOnlyDate(values.periodStartAt ?? null)}
        onBlur={handleBlur}
        error={Boolean(touched.periodStartAt && errors.periodStartAt)}
        helperText={touched.periodStartAt ? `${errors.periodStartAt ?? ""}` : ""}
        fullWidth
        required
        variant="outlined"
        onChange={e => {
          handleChange(e)
        }}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <DatePickerField
        data-qa="period-end-date-picker"
        name="periodEndAt"
        label="Period End Date"
        value={getOnlyDate(values.periodEndAt ?? null)}
        InputLabelProps={{
          shrink: true,
        }}
        onBlur={handleBlur}
        error={Boolean(touched.periodEndAt && errors.periodEndAt)}
        helperText={touched.periodEndAt ? `${errors.periodEndAt ?? ""}` : ""}
        fullWidth
        required
        variant="outlined"
        onChange={e => {
          handleChange(e)
        }}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectField
        dataQa="event-status-select"
        data={fundingEventStatusOptions}
        type="text"
        name="status"
        label="Status"
        required
        value={values.status ?? ""}
        placeholder="Select status"
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Grid>
  </Grid>
)

interface AddNewFundingEventModalProps {
  onClose: () => void
  showNewFundingEventModal: boolean
}

export const AddNewFundingEventModal = ({ onClose, showNewFundingEventModal }: AddNewFundingEventModalProps) => {
  const createNewFundingEvent = useFundingEventCreate()
  const { notify } = useNotifications("new-funding-event")

  return (
    <TcHubGuard requiredPermissions={["tc_hub_autopay"]}>
      <Helmet title="Add new company auto funding event" />
      <Formik
        validationSchema={fundingEventDetailsSchema}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await createNewFundingEvent.mutateAsync({
              companyId: values.company!.id,
              timePeriod: values.timePeriod,
              timePeriodLabel: values.timePeriodLabel,
              // FUTURE: Remove these unsafe non-null assertions
              periodStartAt: DateTime.fromJSDate(values.periodStartAt as never).toISODate()!,
              periodEndAt: DateTime.fromJSDate(values.periodEndAt as never).toISODate()!,
              status: values.status,
            })
            onClose()
            notify(`Funding event created successfully`, "success")
          } catch (error: any) {
            const message = error.message || "Something went wrong"

            setErrors({ submit: error.message })
            notify(`${message}`, "error")
            setSubmitting(false)
          }
        }}
      >
        {formikProps => (
          <Dialog
            open={showNewFundingEventModal}
            onClose={(evt, reason) => {
              if (reason === "backdropClick") {
                onClose()
              }
            }}
            scroll="body"
            data-qa="company-funding-event-modal"
            aria-labelledby="company-funding-event-modal"
          >
            <DialogTitle>
              <Grid
                container
                sx={{ justifyContent: "space-between", py: 3, alignItems: "center" }}
                className="dialog-headline-container"
              >
                <Typography className="dialog-headline" variant="h3tiempos">
                  Add new funding event
                </Typography>
                <CloseIcon
                  data-qa="company-funding-event-dialog-close-icon-button"
                  className="dialog-close-icon"
                  onClick={onClose}
                />
              </Grid>
            </DialogTitle>
            <DialogContent className="dialog-content-container">
              <form noValidate onSubmit={formikProps.handleSubmit}>
                <Typography variant="body1">Please add the funding event details below.</Typography>
                <NewFundingEventDetailsForm {...formikProps} />
                <DialogActions>
                  <Button variant="text" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="primary" data-qa="submit-company-funding-event">
                    Submit
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        )}
      </Formik>
    </TcHubGuard>
  )
}
