import { addEmployeeToClass, addPerson } from "@/features/People/peopleManagementEndpoints"
import { Uuid } from "@/utils/types"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { PersonModel } from "../peopleTypes"
import { addPersonFormToPayload, handleAddPersonResult, mapAddEmployeeRespToPayload } from "./addPersonUtils"
import { AddPersonValues } from "./addPersonValidations"

export const useAddPerson = (companyId: Uuid | undefined, currentPlanId: Uuid | undefined) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ["add-person"],
    mutationFn: async (values: AddPersonValues) => {
      if (!companyId || !currentPlanId) {
        return
      }
      return handleAddPersonResult(
        companyId,
        await addPerson(companyId, addPersonFormToPayload(values, companyId, currentPlanId)),
        queryClient
      )
    },
  })
}

export const useAddEmployeeClass = (planId: string, classId: string, companyId: string, eligibilityDate: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values: Partial<PersonModel>) =>
      addEmployeeToClass(
        companyId,
        planId,
        classId,
        mapAddEmployeeRespToPayload(values, planId, classId, eligibilityDate)
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getCurrentClasses"],
      })
    },
  })
}

export const useAddEmployeeClassHraHub = (classId: string, eligibilityDate: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      companyId,
      planId,
      ...values
    }: Partial<PersonModel> & { companyId: string; planId: string }) =>
      addEmployeeToClass(
        companyId,
        planId,
        classId,
        mapAddEmployeeRespToPayload(values, planId, classId, eligibilityDate)
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getCurrentClasses"],
      })
    },
  })
}
