import { addResponse } from "@/features/HRAPlanDesign/HRAPlanDesign.data"
import { usePrescreenResult } from "@/features/HRAPlanDesign/HRAPlanDesign.hooks"
import {
  isQsehra,
  PLAN_RESULT,
  PRE_SCREEN_QUESTION,
  radioGroupHraValidation,
} from "@/features/HRAPlanDesign/HRAPlanDesign.utils"
import { useQsehraMax } from "@/features/QSEHRAMax/qsehraMaxEndpoints"
import { createDataQa } from "@/utils/dataQa"
import { formatDollars } from "@/utils/formatting"
import { Box, CircularProgress } from "@mui/material"
import { Formik } from "formik"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { NavigationContainer } from "./NavigationContainer"
import { QuestionBody } from "./QuestionBody"
import { QuestionContainer } from "./QuestionContainer"
import { QuestionTitle } from "./QuestionTitle"
import { QuizBackButton } from "./QuizBackButton"
import { QuizLoader } from "./QuizLoader"
import { QuizNextQuestionButton } from "./QuizNextQuestionButton"
import { QuizProgressBar } from "./QuizProgressBar"
import { QuizRadioGroupQuestion } from "./QuizRadioGroupQuestion"

const formOptions = ["Yes", "No"]

export const MonthlyAllowance = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const dataQa = createDataQa("monthly-allowance")
  const { data: qsehraMax, isLoading: qsehraMaxLoading } = useQsehraMax()
  const haveGroupPlanResult = usePrescreenResult("HAVE_GROUP_PLAN")
  const haveGroupPlanResultPlan = haveGroupPlanResult?.result
  const haveGroupPlanResponse = haveGroupPlanResult?.response
  const keepGroupPlanResult = usePrescreenResult("KEEP_GROUP_PLAN")
  const keepGroupPlanResultPlan = keepGroupPlanResult?.result
  const keepGroupPlanResponse = keepGroupPlanResult?.response
  const monthlyAllowanceResult = usePrescreenResult("MONTHLY_ALLOWANCE")
  const monthlyAllowanceResponse = monthlyAllowanceResult?.response

  const initialValues = {
    radioGroup: monthlyAllowanceResponse || "",
  }

  const handleBack = async () => {
    if (haveGroupPlanResponse === "No" && haveGroupPlanResultPlan && isQsehra(haveGroupPlanResultPlan)) {
      navigate("/hra-determination/have_group_plan")
    } else if (
      haveGroupPlanResponse === "Yes" &&
      keepGroupPlanResponse === "No" &&
      keepGroupPlanResultPlan === PLAN_RESULT.QSEHRA
    ) {
      navigate("/hra-determination/keep_group_plan")
    } else {
      throw Error("Previous questions incomplete")
    }
  }

  const handleFormSubmit = async (values: any, { resetForm, setErrors, setStatus, setSubmitting }: any) => {
    try {
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
      if (formOptions.includes(values.radioGroup)) {
        switch (values.radioGroup) {
          case "Yes":
            await dispatch(
              addResponse({
                question: PRE_SCREEN_QUESTION.MONTHLY_ALLOWANCE,
                response: values.radioGroup,
                result: PLAN_RESULT.ICHRA_DIY,
              })
            )
            navigate("/hra-determination/location")
            break
          case "No":
            await dispatch(
              addResponse({
                question: PRE_SCREEN_QUESTION.MONTHLY_ALLOWANCE,
                response: values.radioGroup,
                result: PLAN_RESULT.QSEHRA,
              })
            )
            navigate("/hra-determination/non_individual_plan_reimbursement")
            break
          default:
            throw Error("Invalid Option Selected")
        }
      }
    } catch (error: any) {
      setStatus({ sent: false })
      setErrors({ submit: error.message })
      setSubmitting(false)
    }
  }

  return (
    <QuestionContainer data-qa={createDataQa(dataQa, "wrapper")}>
      <Formik initialValues={initialValues} validationSchema={radioGroupHraValidation} onSubmit={handleFormSubmit}>
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, isValid, values }) => (
          <>
            <QuizProgressBar value={60} />
            {qsehraMaxLoading ? (
              <Box display="flex" justifyContent="center" my={10}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <QuestionTitle>
                  For a monthly allowance, do you want to offer more than
                  {" " + formatDollars(qsehraMax?.employeeOnlyContributionLimit!) + "?"}
                </QuestionTitle>
                <QuestionBody>Decide the amount for a monthly allowance to offer to employees.</QuestionBody>
                {isSubmitting ? (
                  <QuizLoader />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <QuizRadioGroupQuestion
                      options={formOptions}
                      value={values.radioGroup}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="radioGroup"
                      data-qa={createDataQa("option")}
                    />
                    <NavigationContainer>
                      <QuizBackButton handleBack={handleBack} />
                      <QuizNextQuestionButton
                        onClick={handleSubmit}
                        disabled={!(isValid && values.radioGroup && !isSubmitting && errors)}
                      />
                    </NavigationContainer>
                  </form>
                )}
              </>
            )}
          </>
        )}
      </Formik>
    </QuestionContainer>
  )
}
