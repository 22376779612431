import {
  ADMINISTRATOR_EXTERNAL_ROLE_ID,
  EMPLOYEE_EXTERNAL_ROLE_ID,
  SYSTEM_ADMINISTRATOR_EXTERNAL_ROLE_ID,
} from "../People/peopleConstants"
import { ENCODED_DATA_PARAM_KEY } from "./authConstants"
import { CompanyRole } from "./authTypes"

export const decodeIncomingParams = <Data>(searchParams: URLSearchParams | undefined) => {
  const data = searchParams?.get(ENCODED_DATA_PARAM_KEY)

  if (!data) return null

  try {
    return JSON.parse(window.atob(data)) as Data
  } catch (error) {
    console.error(error)
  }

  return null
}

export const isUserAdministrator = (roles: CompanyRole[]) =>
  roles.some(
    role => role.roleId === ADMINISTRATOR_EXTERNAL_ROLE_ID || role.roleId === SYSTEM_ADMINISTRATOR_EXTERNAL_ROLE_ID
  )

export const isUserEmployee = (roles: CompanyRole[]) => roles.some(role => role.roleId === EMPLOYEE_EXTERNAL_ROLE_ID)
