import { AsyncButton } from "@/components/Buttons"
import { DatePickerField } from "@/components/DatePickerField"
import { LoadingContentArea } from "@/components/LoadingContentArea"
import { SelectField } from "@/components/SelectField"
import { StyledCard } from "@/components/StyledCard"
import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"

import { ConfirmationModal, useConfirmationControl } from "@/components/ConfirmationModal"
import {
  ALL_INSURANCE_TYPE_OPTIONS,
  PLAN_ENROLLMENT_TYPE_OPTIONS,
} from "@/features/BenefitsElection/benefitsElectionConstants"
import {
  CompanySet,
  convertToFormFields,
  recurringPremiumInitialValues,
  useRecurringPremiumByRecurringPremiumId,
  useRecurringPremiumUpdate,
} from "@/features/TCHub/Compliance/tcHubComplianceService"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { formatDateToYyyyMmDd, getOnlyDate } from "@/utils/dates"
import { DATE_FORMAT_MONTH_DAY_YEAR, formatCents, transformDate } from "@/utils/formatting"
import { Cents, Uuid } from "@/utils/types"
import { recurringPremiumDetailsSchema } from "@/utils/validations"
import { KeyboardArrowLeft } from "@mui/icons-material"
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material"
import { Formik, FormikProps } from "formik"
import { get, isNil } from "lodash"
import { SyntheticEvent, useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { NumericFormat } from "react-number-format"
import { Link, useParams } from "react-router-dom"
import { RECURRING_PREMIUM_STATUS_OPTIONS } from "../tcHubConstants"
import { useCompanies, useCreateSubAccount, useFundingEntityDetails, useUser } from "../tcHubService"
import { RecurringPremiumDetailModel, RecurringPremiumUpdateRequest } from "../tcHubTypes"

const dataQa = "tc-hub-recurring-premium-detail-page"

interface EmployeeDetailsProps {
  employeeName: string
  email: string
  companySet: Map<string, string> | undefined
  fullAddress: string
  enrolledAllowanceCents: Cents
  companyName?: string
  familyMembers: any[]
}

/** @deprecated Please use the shared age calculation util instead */
const calculateAge = (dateOfBirth: string) => {
  const birthDate = new Date(dateOfBirth)
  const today = new Date()
  const age = today.getFullYear() - birthDate.getFullYear()
  return age
}

const EmployeeDetails = ({
  employeeName,
  email,
  fullAddress,
  enrolledAllowanceCents,
  companyName,
  familyMembers,
}: EmployeeDetailsProps) => (
  <StyledCard>
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      data-qa={createDataQa(dataQa, "person-details-card")}
    >
      <Grid item>
        <Typography variant="h5" gutterBottom data-qa="person-detail-title">
          Employee Details
        </Typography>
      </Grid>
    </Grid>
    <Divider sx={{ my: 3 }} />
    <Grid container spacing={12}>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" gutterBottom data-qa={createDataQa(dataQa, "person-name")}>
          Employee Name
        </Typography>
        <Typography variant="body1">{employeeName}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa={createDataQa(dataQa, "email")}>
          Email
        </Typography>
        <Typography variant="body1">{email}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa={createDataQa(dataQa, "dob")}>
          Company
        </Typography>
        <Typography variant="body1">{companyName}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa={createDataQa(dataQa, "address")}>
          Address
        </Typography>
        <Typography variant="body1">{fullAddress}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa={createDataQa(dataQa, "allowance")}>
          Allowance Amount
        </Typography>
        <Typography variant="body1">{formatCents(enrolledAllowanceCents)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1bold" data-qa={createDataQa(dataQa, "covered-family-members")}>
          Covered Family Members
        </Typography>
        {familyMembers.length > 0
          ? familyMembers.map((familyMember, memberIndex) => (
              <Grid container key={memberIndex} spacing={2} mt={0.5} alignItems="center" justifyContent="space-between">
                <Grid item xs={4} sm={4}>
                  <Typography variant="caption">Name</Typography>
                  <Typography variant="body1">{`${familyMember.firstName} ${familyMember.lastName}`}</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography variant="caption">Age</Typography>
                  <Typography variant="body1">{calculateAge(familyMember.dateOfBirth)} years</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography variant="caption">Birthday</Typography>
                  <Typography variant="body1">
                    {transformDate(familyMember.dateOfBirth, DATE_FORMAT_MONTH_DAY_YEAR)}
                  </Typography>
                </Grid>
              </Grid>
            ))
          : null}
      </Grid>
    </Grid>
  </StyledCard>
)

const getUniqueFamilyMembers = (recurringPremiums: RecurringPremiumDetailModel["recurringPremiums"]) => {
  const familyMembersMap = new Map<
    string,
    {
      firstName: string
      lastName: string
      dateOfBirth: string
    }
  >()

  recurringPremiums.forEach(premium => {
    premium.familyMembers?.forEach(member => {
      const key = `${member.firstName}-${member.lastName}-${member.dateOfBirth}`
      if (!familyMembersMap.has(key)) {
        familyMembersMap.set(key, member)
      }
    })
  })

  return Array.from(familyMembersMap.values())
}

interface RecurringPremiumDetailFormProps extends FormikProps<typeof recurringPremiumInitialValues> {
  isUpdateRecurringPremium: boolean
  employeeName: string
}

interface RecurringPremiumDetailFormItemProps extends RecurringPremiumDetailFormProps {
  premium: RecurringPremiumDetailFormProps["values"]["recurringPremiums"][0]
  index: number
}

const RecurringPremiumDetailFormItem = ({
  handleBlur,
  handleChange,
  setFieldValue,
  isUpdateRecurringPremium,
  values,
  errors,
  touched,
  premium,
  employeeName,
  index,
}: RecurringPremiumDetailFormItemProps) => {
  const { data: fundingEntityDetails } = useFundingEntityDetails(premium.employeeSubAccountId ?? "")
  const recurringPremiumId = premium.id ?? ""

  const [anchorMenu, setAnchorMenu] = useState<Element | null>(null)

  const toggleMenu = (event: SyntheticEvent) => {
    setAnchorMenu(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorMenu(null)
  }

  const { notify } = useNotifications("recurring-premium")
  const { mutateAsync: createSubAccount } = useCreateSubAccount()

  const { confirmationModal, setConfirmationModal, setConfirmationSubmitting, clearConfirmationModal } =
    useConfirmationControl()

  const handlerAddSubaccount = () => {
    setConfirmationModal({
      title: "Create subaccount",
      message: `Recurring premium with amount ${formatCents(premium.premiumAmountCents ?? 0)} for ${employeeName}`,
      actionLabel: "Create",
      onConfirm: async () => {
        setConfirmationSubmitting(true)
        try {
          await createSubAccount({ recurringPremiumId })
          clearConfirmationModal()
        } catch (error) {
          const errorMessage =
            get(error, "userMessage") ??
            get(error, "developerMessage") ??
            get(error, "message") ??
            "Unknown error occurred. Please try again later."
          console.error("Error creating subaccount:", errorMessage, error)
          notify(`Error creating subaccount: ${errorMessage}`, "error")
          setConfirmationSubmitting(false)
        }
      },
    })
  }

  return (
    <StyledCard key={index}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={6} sm={9} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5" data-qa={createDataQa(dataQa, "name")}>
            Recurring Premium
          </Typography>
          <Box>
            <IconButton onClick={toggleMenu}>
              <MoreHorizOutlinedIcon />
            </IconButton>
            <Menu anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
              {recurringPremiumId && (
                <MenuItem className="tch-menu-item" data-qa={createDataQa(dataQa, "add-sub-account-menu-item")}>
                  <Button startIcon={<AddToPhotosOutlinedIcon />} onClick={handlerAddSubaccount}>
                    Add Subaccount
                  </Button>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
          <SelectField
            data-qa={createDataQa(dataQa, "status")}
            data={[...RECURRING_PREMIUM_STATUS_OPTIONS]}
            type="text"
            name={`recurringPremiums.${index}.status`}
            value={premium.status ?? ""}
            placeholder="Select status"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mb: 9, mt: 4 }} />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            name={`recurringPremiums.${index}.coverageStartDate`}
            label="Start Date"
            value={getOnlyDate(premium.coverageStartDate ?? null)}
            InputLabelProps={{ shrink: true }}
            onBlur={handleBlur}
            error={Boolean(
              touched.recurringPremiums?.[index]?.coverageStartDate &&
                get(errors.recurringPremiums?.[index], "coverageStartDate")
            )}
            helperText={
              touched.recurringPremiums?.[index]?.coverageStartDate
                ? `${get(errors.recurringPremiums?.[index], "coverageStartDate") ?? ""}`
                : ""
            }
            fullWidth
            required
            variant="outlined"
            onChange={handleChange}
            data-qa={createDataQa(dataQa, "coverage-start-date")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            name={`recurringPremiums.${index}.coverageEndDate`}
            label="End Date"
            value={getOnlyDate(premium.coverageEndDate ?? null)}
            InputLabelProps={{
              shrink: true,
            }}
            onBlur={handleBlur}
            error={Boolean(
              touched.recurringPremiums?.[index]?.coverageEndDate &&
                get(errors.recurringPremiums?.[index], "coverageEndDate")
            )}
            helperText={
              touched.recurringPremiums?.[index]?.coverageEndDate
                ? `${get(errors.recurringPremiums?.[index], "coverageEndDate") ?? ""}`
                : ""
            }
            fullWidth
            required
            variant="outlined"
            onChange={handleChange}
            data-qa={createDataQa(dataQa, "coverage-end-date")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            dataQa="insurance-types-select"
            data={[...ALL_INSURANCE_TYPE_OPTIONS]}
            type="text"
            name={`recurringPremiums.${index}.insuranceType`}
            label="Insurance Type"
            required
            value={premium.insuranceType ?? ""}
            placeholder="Select insurance type"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel
              htmlFor="total-amount-label"
              error={Boolean(
                touched.recurringPremiums?.[index]?.premiumAmountCents &&
                  get(errors.recurringPremiums?.[index], "premiumAmountCents")
              )}
            >
              Premium Amount
            </InputLabel>
            <NumericFormat
              id={`total-amount-label-${index}`}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              customInput={OutlinedInput}
              name={`recurringPremiums.${index}.premiumAmountCents`}
              label="Premium Amount"
              onBlur={handleBlur}
              error={Boolean(
                touched.recurringPremiums?.[index]?.premiumAmountCents &&
                  get(errors.recurringPremiums?.[index], "premiumAmountCents")
              )}
              value={isNil(premium.premiumAmountCents) ? "" : formatCents(premium.premiumAmountCents)}
              onChange={e => {
                const value = e.target.value.replace(/[^0-9.]/g, "")
                const valueInCents = parseFloat(value) * 100

                setFieldValue(`recurringPremiums.${index}.premiumAmountCents`, valueInCents)
              }}
              thousandSeparator
              decimalSeparator="."
              decimalScale={2}
              prefix=""
              data-qa={createDataQa(dataQa, "premium-amount")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            dataQa="enrollment-type-select"
            data={[...PLAN_ENROLLMENT_TYPE_OPTIONS]}
            type="text"
            name={`recurringPremiums.${index}.easyEnroll`}
            label="Enrollment Type"
            required
            value={premium.easyEnroll ? "true" : "false"}
            placeholder="Select enrollment type"
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {premium.employeeSubAccountId && (
            <TextField
              required
              fullWidth
              name={`recurringPremiums.${index}.employeeSubAccountId`}
              label="Subaccount Last 4 Digits"
              value={`****${fundingEntityDetails?.accountNumber.slice(-4)}`}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(
                touched.recurringPremiums?.[index]?.employeeSubAccountId &&
                  get(errors.recurringPremiums?.[index], "employeeSubAccountId")
              )}
              helperText={
                touched.recurringPremiums?.[index]?.employeeSubAccountId &&
                get(errors.recurringPremiums?.[index], "employeeSubAccountId")
              }
              data-qa={createDataQa(dataQa, "subaccount")}
              disabled
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} mt={3}>
          <Typography variant="body1bold">Covered Family Members</Typography>
          {premium.familyMembers?.map((familyMember, memberIndex) => (
            <Grid container key={memberIndex} spacing={2} mt={0.5} alignItems="center" justifyContent="space-between">
              <Grid item xs={4} sm={4}>
                <Typography variant="caption">Name</Typography>
                <Typography variant="body1">{`${familyMember?.firstName ?? ""} ${
                  familyMember?.lastName ?? ""
                }`}</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography variant="caption">Age</Typography>
                <Typography variant="body1">
                  {familyMember.dateOfBirth ? calculateAge(familyMember.dateOfBirth) : ""} years
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography variant="caption">Birthday</Typography>
                <Typography variant="body1">
                  {familyMember.dateOfBirth ? transformDate(familyMember.dateOfBirth, DATE_FORMAT_MONTH_DAY_YEAR) : ""}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {confirmationModal && (
        <ConfirmationModal {...confirmationModal} isOpen onClose={() => setConfirmationModal(null)} />
      )}
    </StyledCard>
  )
}

const RecurringPremiumDetailForm = ({
  handleBlur,
  handleChange,
  setFieldValue,
  handleSubmit,
  values,
  errors,
  touched,
  isUpdateRecurringPremium,
  employeeName,
  ...otherProps
}: RecurringPremiumDetailFormProps) => (
  <form data-qa={createDataQa(dataQa, "form")} onSubmit={handleSubmit} noValidate>
    {values.recurringPremiums?.map((premium, index) => (
      <RecurringPremiumDetailFormItem
        key={index}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        handleSubmit={handleSubmit}
        isUpdateRecurringPremium={isUpdateRecurringPremium}
        values={values}
        errors={errors}
        touched={touched}
        premium={premium}
        index={index}
        employeeName={employeeName}
        {...otherProps}
      />
    ))}
    <Grid container mt={5} justifyContent="flex-end">
      <AsyncButton
        type="submit"
        variant="contained"
        color="primary"
        isLoading={isUpdateRecurringPremium}
        data-qa={createDataQa(dataQa, "submit-button")}
      >
        Save
      </AsyncButton>
    </Grid>
  </form>
)

interface Params extends Record<string, string | undefined> {
  employementId: string
  userId: string
  employmentId: string
  companyId: Uuid
}

export const TcHubRecurringPremiumDetailPage = () => {
  const { notify } = useNotifications("edit-recurring-premium")
  const { userId, companyId, employmentId } = useParams<Params>()

  const { data: recurringPremiums, isLoading: isRecurringPremiumLoading } = useRecurringPremiumByRecurringPremiumId(
    employmentId ?? ""
  )
  const { data: companies, isLoading: isCompaniesLoading } = useCompanies()
  const { data: tcHubPersonDetails, isLoading: istcHubPersonDetailsLoading } = useUser(
    companyId ?? ("" as never),
    userId ?? ""
  )
  const { mutateAsync: updateRecurringPremium, isPending: isUpdateRecurringPremium } = useRecurringPremiumUpdate(
    employmentId ?? ""
  )
  const companySet = useMemo(() => CompanySet(companies ?? []), [companies])

  const email = tcHubPersonDetails?.email ?? ""
  const enrolledAllowanceCents = recurringPremiums?.recurringPremiums?.[0].enrolledAllowanceCents ?? 0
  const employeeName = tcHubPersonDetails ? `${tcHubPersonDetails.firstName} ${tcHubPersonDetails.lastName}` : ""
  const companyName = companySet.get(tcHubPersonDetails?.companyId ?? "")
  const city = tcHubPersonDetails?.addresses?.[0].city ?? ""
  const state = tcHubPersonDetails?.addresses?.[0].state ?? ""
  const zipCode = tcHubPersonDetails?.addresses?.[0].zip ?? ""
  const street1 = tcHubPersonDetails?.addresses?.[0].street1 ?? ""
  const street2 = tcHubPersonDetails?.addresses?.[0].street2 ?? ""
  const fullAddress = `${street1} ${street2} ${city}, ${state} ${zipCode}`

  const showLoadingArea = isRecurringPremiumLoading || istcHubPersonDetailsLoading || isCompaniesLoading
  const uniqueFamilyMembers = recurringPremiums ? getUniqueFamilyMembers(recurringPremiums.recurringPremiums) : []

  return (
    <TcHubGuard requiredPermissions={["tc_hub_compliance"]}>
      <Grid container data-qa={createDataQa(dataQa, "recurring-premium-details-container")}>
        <Helmet title="Recurring Premium Detail" />
        <Button
          color="primary"
          component={Link}
          to="/admin/compliance/recurring-premiums"
          startIcon={<KeyboardArrowLeft />}
        >
          Back
        </Button>
        <Grid item xs={12} mt={3}>
          <Typography variant="h1">Insurance Coverage Detail</Typography>
        </Grid>
        {showLoadingArea ? (
          <LoadingContentArea data-qa="loading-recurring-premium-details" />
        ) : (
          <>
            <EmployeeDetails
              employeeName={employeeName}
              email={email}
              enrolledAllowanceCents={enrolledAllowanceCents}
              companySet={companySet}
              fullAddress={fullAddress}
              companyName={companyName}
              familyMembers={uniqueFamilyMembers}
              data-qa={createDataQa(dataQa, "employee-details")}
            />
            <Formik
              validationSchema={recurringPremiumDetailsSchema}
              enableReinitialize
              initialValues={
                recurringPremiums && tcHubPersonDetails
                  ? convertToFormFields(companySet, recurringPremiums, tcHubPersonDetails)
                  : recurringPremiumInitialValues
              }
              onSubmit={async values => {
                const updateRecurringPremiumRequest: RecurringPremiumUpdateRequest = {
                  recurringPremiums: values.recurringPremiums.map(premium => ({
                    id: premium.id!,
                    employmentId: employmentId!,
                    premiumAmountCents: premium.premiumAmountCents!,
                    coverageStartDate: formatDateToYyyyMmDd(premium.coverageStartDate!),
                    coverageEndDate: formatDateToYyyyMmDd(premium.coverageEndDate!),
                    insuranceType: premium.insuranceType!,
                    status: premium.status,
                  })),
                }
                await updateRecurringPremium(updateRecurringPremiumRequest)
                notify(`Recurring premium updated successfully`, "success")
              }}
            >
              {formikProps => (
                <RecurringPremiumDetailForm
                  data-qa={createDataQa(dataQa, "recurring-premium-detail-form")}
                  {...formikProps}
                  isUpdateRecurringPremium={isUpdateRecurringPremium}
                  employeeName={employeeName}
                />
              )}
            </Formik>
          </>
        )}
      </Grid>
    </TcHubGuard>
  )
}
