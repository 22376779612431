import { Cents, IsoDateString, Property } from "@/utils/types"
import { usePlaidLink } from "react-plaid-link"

export const CREDIT_CARD = "CREDIT_CARD"
export const ACH_ACCOUNT = "ACH_ACCOUNT"
export const PAYMENT_METHODS = [CREDIT_CARD, ACH_ACCOUNT] as const
export type PaymentMethod = Property<typeof PAYMENT_METHODS>

export const CHECKING = "CHECKING"
export const SAVINGS = "SAVINGS"
export const BANK_ACCOUNT_TYPES = [CHECKING, SAVINGS] as const

export const BANK_ACCOUNT_TYPE_OPTIONS = [
  {
    value: CHECKING,
    label: "Checking",
  },
  {
    value: SAVINGS,
    label: "Savings",
  },
] as const

export type BankAccountType = Property<typeof BANK_ACCOUNT_TYPES>

export interface AchDetails {
  accountType: BankAccountType
  routingNumber: string
  accountNumber: string
  bankName: string
  hasEnoughFunds?: boolean
}

export type StripeId = `cus_${string}`

export interface PaymentStatusType {
  severity: "success" | "info" | "warning" | "error"
  message: string
}

export interface AchAccountProps {
  institution: string
  accountNumber: string
  accountType: "Checking" | "Savings"
}

export interface CreditCardProps {
  cardIssuer: string
  cardNumber: string
  expirationMonth: string
  expirationYear: string
}

export type PaymentInformation =
  | {
      kind: typeof ACH_ACCOUNT
      props: AchAccountProps
      paymentStatus?: PaymentStatusType
      amountDueCents?: Cents
      billingDate?: IsoDateString
      billingProviderId?: StripeId
    }
  | {
      kind: typeof CREDIT_CARD
      props: CreditCardProps
      paymentStatus?: PaymentStatusType
      amountDueCents?: Cents
      billingDate?: IsoDateString
      billingProviderId?: StripeId
    }
  | {
      kind: null
      amountDueCents?: null
      billingDate?: null
      billingProviderId?: null
    }

export interface PaymentMethodProps {
  isLoading: boolean
  paymentInformation: PaymentInformation
}

export type PlaidOpenFunction = ReturnType<typeof usePlaidLink>["open"]
export type PlaidExitFunction = ReturnType<typeof usePlaidLink>["exit"]
