import { PATCH } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { SIGNATURE } from "@/features/Documents/documentsConstants"
import { useCreateDocument } from "@/features/Documents/documentsService"
import { Uuid } from "@/utils/types"
import { removeBase64Metadata } from "@/utils/util"
import { useManageShoppingPersons } from "../benefitsElectionService"
import { EligibleApplicant } from "../benefitsElectionTypes"

export const useSubmitSignatures = (shoppingSessionId: Uuid, applicants: EligibleApplicant[]) => {
  const { user } = useAuth()
  // FUTURE: Remove these unsafe type casts
  const companyId = user?.company?.companyId as Uuid
  const employeeId = user?.company?.employeeId as Uuid
  const { mutateAsync: createDocument } = useCreateDocument(companyId)
  const { mutateAsync: manageShoppingPersons } = useManageShoppingPersons(shoppingSessionId)

  const submitSignatures = async (signatures: string[]) => {
    const payloads = await Promise.all(
      applicants.map(async ({ personalInformation: { firstName, lastName }, shoppingPersonId }, i) => {
        const document = removeBase64Metadata(signatures[i])
        const documentName = `${firstName}-${lastName}-signature.png`

        const { id: signatureDocumentId } = await createDocument({
          document,
          documentName,
          documentType: SIGNATURE,
          employeeId,
        })

        return {
          method: PATCH,
          id: shoppingPersonId,
          signatureDocumentId,
        } as const
      })
    )

    return manageShoppingPersons(payloads)
  }

  return { submitSignatures }
}
