import { MAX_RETRY_ATTEMPTS } from "@/constants"
import { createSelector } from "@reduxjs/toolkit"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { addWaiveCoverage } from "../BenefitsElection/benefitsElectionEndpoints"
import { WaiveCoveragePayload } from "../BenefitsElection/benefitsElectionTypes"
import {
  ADMINISTRATOR_EXTERNAL_ROLE_ID,
  PEOPLE_ACTIVE_STATUS,
  PEOPLE_INACTIVE_STATUSES,
  SYSTEM_ADMINISTRATOR_EXTERNAL_ROLE_ID,
} from "./peopleConstants"
import {
  archivePerson,
  getAssociatedCompanies,
  getPeople,
  getPersonById,
  reinstatePerson,
  terminatePerson,
  updateWelcomeEmail,
  waivePerson,
} from "./peopleManagementEndpoints"
import { ActivePeopleStatus, InactivePeopleStatus, Person } from "./peopleTypes"

export const activePeopleFilter = (people: Person[]) =>
  people.filter(person => Object.values(PEOPLE_ACTIVE_STATUS).includes(person.status as ActivePeopleStatus))

export const adminPeopleFilter = (people: Person[]) =>
  people.filter(
    person =>
      Object.values(PEOPLE_ACTIVE_STATUS).includes(person.status as ActivePeopleStatus) &&
      person.roles.some(
        role => role.id === ADMINISTRATOR_EXTERNAL_ROLE_ID || role.id === SYSTEM_ADMINISTRATOR_EXTERNAL_ROLE_ID
      )
  )

export const inactivePeopleFilter = (people: Person[]) =>
  people.filter(person => Object.values(PEOPLE_INACTIVE_STATUSES).includes(person.status as InactivePeopleStatus))

export const useGetPeople = (companyId: string, peopleSelector: (people: Person[]) => Person[]) =>
  useQuery({
    queryKey: ["companies", companyId, "people"],
    queryFn: () => getPeople(companyId),
    select: createSelector(peopleSelector, data =>
      data.map(row => ({ ...row, fullName: row.firstName + " " + row.lastName }))
    ),
  })

export const useGetPersonById = (companyId: string, personId: string | undefined) =>
  useQuery({
    queryKey: ["getPerson", companyId, personId],
    queryFn: () => getPersonById(companyId, personId!),
    enabled: Boolean(companyId && personId),
  })

export const useArchivePersonMutation = (companyId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (personIdParam: string) => archivePerson(personIdParam),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companies", companyId, "people"],
      })
    },
    retry: MAX_RETRY_ATTEMPTS,
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    onError: error => {
      console.error("Error archiving person:", error)
    },
  })
}

export const useReinstatePersonMutation = (companyId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (personIdParam: string) => reinstatePerson(companyId, personIdParam),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companies", companyId, "people"],
      })
    },
    retry: MAX_RETRY_ATTEMPTS,
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    onError: error => {
      console.error("Error reinstating person:", error)
    },
  })
}

export const useTerminatePerson = (companyId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (personIdParam: string) => terminatePerson(personIdParam, companyId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["companies", companyId, "people"],
      }),
  })
}

export const useUpdateWelcomeEmail = (companyId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (welcomeEmailDateParam: string) => updateWelcomeEmail(welcomeEmailDateParam, companyId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["companies", companyId, "people"],
      }),
  })
}

export const useWaivePersonMutation = (companyId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (personIdParam: string) => waivePerson(companyId, personIdParam),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["companies", companyId, "people"],
      }),
    retry: MAX_RETRY_ATTEMPTS,
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    onError: error => console.error("Error waiving person:", error),
  })
}

export const useWaivePersonByEmploymentId = (companyId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ shoppingSessionId, ...values }: WaiveCoveragePayload & { shoppingSessionId: string }) =>
      addWaiveCoverage(shoppingSessionId, values),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["companies", companyId, "people"],
      }),
  })
}

export const useGetAssociatedCompanies = () =>
  useQuery({
    queryKey: ["companies", "name"],
    queryFn: () => getAssociatedCompanies(),
  })
