import { TextField } from "@mui/material"
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker"
import { noop } from "lodash"
import { ComponentProps } from "react"

type DatePickerFieldProps = ComponentProps<typeof TextField> & {
  // FUTURE: Refactor this to use the correct naming scheme ("data-qa") and DataQa
  // "data-qa"?: DataQa
  dataQa?: string
  defaultCalendarMonth?: Date | string
  disableTextInput?: boolean
  maxDate?: Date | string
  minDate?: Date | string
  onDateAccept?: (value: string | Date | null) => void
  onInputChange?: (value: string) => void
  readOnly?: boolean
  shouldDisableDate?: (day: string | Date) => boolean
}

export const DatePickerField = ({
  dataQa = "date-picker-field",
  defaultCalendarMonth,
  disableTextInput,
  error,
  label,
  maxDate,
  minDate,
  onChange,
  onDateAccept = noop,
  onInputChange = noop,
  readOnly = false,
  shouldDisableDate,
  value,
  disabled,
  ...textFieldProps
}: DatePickerFieldProps) => (
  <DatePicker
    data-qa={dataQa}
    defaultCalendarMonth={defaultCalendarMonth}
    label={label}
    maxDate={maxDate}
    minDate={minDate}
    onChange={jsDate =>
      onChange?.({
        target: { name: textFieldProps.name, value: jsDate },
      } as never)
    }
    onAccept={onDateAccept}
    readOnly={readOnly}
    shouldDisableDate={shouldDisableDate}
    value={value}
    disabled={disabled}
    renderInput={params =>
      disableTextInput ? (
        <TextField
          onKeyPress={event => event.preventDefault()}
          {...textFieldProps}
          {...params}
          error={error}
          disabled={disabled}
          type="text"
        />
      ) : (
        <TextField
          {...textFieldProps}
          {...params}
          error={error}
          disabled={disabled}
          type="text"
          onChange={event => {
            onInputChange(event.target.value)
          }}
        />
      )
    }
  />
)

interface MonthYearDatePickerFieldProps extends DatePickerFieldProps {
  dateViews?: DatePickerProps<unknown, unknown>["views"]
  dateInputFormat?: DatePickerProps<unknown, unknown>["inputFormat"]
}

// FUTURE: This component is copy-pasted whole-cloth from above
// Aim to consolidate where possible
export const MonthYearDatePickerField = ({
  dataQa = "date-picker-field",
  defaultCalendarMonth,
  disableTextInput,
  error,
  label,
  maxDate,
  minDate,
  onChange,
  onDateAccept = noop,
  onInputChange = noop,
  readOnly = false,
  shouldDisableDate,
  value,
  disabled,
  dateViews,
  dateInputFormat,
  ...textFieldProps
}: MonthYearDatePickerFieldProps) => (
  <DatePicker
    data-qa={dataQa}
    defaultCalendarMonth={defaultCalendarMonth}
    label={label}
    maxDate={maxDate}
    minDate={minDate}
    onChange={jsDate =>
      onChange?.({
        target: { name: textFieldProps.name, value: jsDate },
      } as never)
    }
    onAccept={onDateAccept}
    readOnly={readOnly}
    shouldDisableDate={shouldDisableDate}
    value={value}
    disabled={disabled}
    views={dateViews}
    inputFormat={dateInputFormat}
    renderInput={params =>
      disableTextInput ? (
        <TextField
          onKeyPress={event => event.preventDefault()}
          {...textFieldProps}
          {...params}
          error={error}
          disabled={disabled}
          type="text"
        />
      ) : (
        <TextField
          {...textFieldProps}
          {...params}
          error={error}
          disabled={disabled}
          type="text"
          onChange={event => {
            onInputChange(event.target.value)
          }}
        />
      )
    }
  />
)
