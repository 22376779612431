export const ACTIVE = "ACTIVE"
export const NOT_STARTED = "NOT_STARTED"
export const APPLICATION_PENDING = "APPLICATION_PENDING"
export const APPLICATION_DENIED = "APPLICATION_DENIED"
export const APPLICATION_AWAITING_DOCUMENTS = "APPLICATION_AWAITING_DOCUMENTS"
export const BANK_ACCOUNT_PENDING = "BANK_ACCOUNT_PENDING"
export const REAUTHENTICATION_NEEDED = "REAUTHENTICATION_NEEDED"
export const FUNDING_EVENT_STATUS_SCHEDULED = "SCHEDULED"
export const FROZEN = "FROZEN"
export const CLOSED = "CLOSED"

export const FUNDING_STATUSES = [
  ACTIVE,
  NOT_STARTED,
  APPLICATION_PENDING,
  APPLICATION_DENIED,
  APPLICATION_AWAITING_DOCUMENTS,
  BANK_ACCOUNT_PENDING,
  REAUTHENTICATION_NEEDED,
  FROZEN,
  CLOSED,
] as const

export const FUNDING_EVENT_STATUSES = [FUNDING_EVENT_STATUS_SCHEDULED] as const
