import { EXTERNAL_LINKS } from "@/constants"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Button, Grid, Link, Typography } from "@mui/material"
import { QuizProgressBar } from "./QuizProgressBar"

const qsehraBenefits = [
  "Can vary rates based on employee status (single, couple, family)",
  "Part-time and seasonal workers can be excluded",
  "Simple and flexible design options",
  "Budget control",
]

export const QsehraResult = () => (
  <Grid sx={{ width: { xs: "90%", sm: "50%" }, py: 10 }}>
    <Grid item>
      <QuizProgressBar value={100} />
    </Grid>
    <Grid item sx={{ mt: 8 }}>
      <Typography variant="h1" align="center">
        Your Results:
        <br />
        QSEHRA
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="body1" align="left" sx={{ mt: "12px" }}>
        Based on your responses, a QSEHRA plan may be a good solution for providing health care to your employees.
        <br />
        <br />
        Compared to group plans, QSEHRA come with many benefits for companies:
      </Typography>
    </Grid>
    <Grid item>
      <Grid item>
        <ul>
          {qsehraBenefits.map((benefit, index) => (
            <li key={index}>
              <Typography variant="body1" align="left">
                {benefit}
              </Typography>
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
    <Grid item>
      <Button href={EXTERNAL_LINKS.HRA_QSEHRA_GUIDE} data-qa="qsehra-guide-link" sx={{ color: "colors.darkBody" }}>
        Check out our full QSEHRA guide.
      </Button>
    </Grid>
    <Grid container mt={10} justifyContent="center">
      <Button
        color="primary"
        variant="contained"
        href={EXTERNAL_LINKS.TCH_HOME_PAGE}
        data-qa="qsehra-create-my-account"
      >
        Create my account
      </Button>
    </Grid>
    <Grid container justifyContent="center">
      <Button component={Link} startIcon={<ArrowBackIcon />} href="/welcome" sx={{ mt: 5, color: "colors.darkBody" }}>
        Restart the quiz
      </Button>
    </Grid>
  </Grid>
)
