import {
  ClampedTextField,
  EmailTextField,
  FirstNameTextField,
  LastNameTextField,
  MiddleNameTextField,
  PreferredNameTextField,
} from "@/components/TextFields"
import { PhoneNumberField } from "@/features/People/PeopleSettings/PhoneNumberField"
import { ROLES } from "@/roles_permissions"
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import FormHelperText from "@mui/material/FormHelperText"
import { get } from "lodash"
import { AddPeopleStepProps } from "../addPersonValidations"
import { STEPS } from "../types"
import { AddPersonStep } from "./AddPersonStep"

export const AboutProfileFormStep = ({
  onClose,
  onNextStep,
  onBack,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}: AddPeopleStepProps) => {
  const isOnlyProfile = !values.benefitEligible

  return (
    <AddPersonStep
      title={isOnlyProfile ? "" : "About"}
      continueButtonType={isOnlyProfile ? "submit" : "button"}
      continueButtonLabel={isOnlyProfile ? "Save" : "Continue"}
      onClose={onClose}
      onContinue={() => !isOnlyProfile && onNextStep(STEPS.ADDRESS_PERSON_FORM)}
      onBack={onBack}
    >
      <Grid item xs={12} sm={6}>
        <FirstNameTextField />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MiddleNameTextField />
      </Grid>
      <Grid item xs={12} sm={6}>
        <LastNameTextField />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PreferredNameTextField />
      </Grid>
      <Grid item xs={12} sm={6}>
        <EmailTextField />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PhoneNumberField
          data-qa="phone"
          name="phoneNumber"
          label="Phone Number"
          value={values.phoneNumber}
          fullWidth
          error={Boolean(get(touched, `phoneNumber`) && get(errors, `phoneNumber`))}
          helperText={get(touched, `phoneNumber`) && get(errors, `phoneNumber`)}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>

      {values.benefitEligible && (
        <Grid item xs={12} sm={6}>
          <ClampedTextField
            data-qa="employeeNumber"
            name="employeeNumber"
            label="Employee ID"
            value={values.employeeNumber}
            error={Boolean(get(touched, `employeeNumber`) && get(errors, `employeeNumber`))}
            helperText={get(touched, `employeeNumber`) && get(errors, `employeeNumber`)}
            fullWidth
            type="employeeNumber"
            variant="outlined"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <FormControl
          variant="outlined"
          sx={{ width: "100%" }}
          required
          error={Boolean(get(touched, `selectedRole.roleId`) && get(errors, `selectedRole.roleId`))}
        >
          <InputLabel>Role</InputLabel>
          <Select
            name="selectedRole.roleId"
            label="Role"
            placeholder="Select role"
            data-qa="role"
            fullWidth
            value={values.selectedRole?.roleId}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {ROLES.filter(row => !row.name.toLowerCase().includes("internal")).map(role => (
              <MenuItem key={role.id} id="role" value={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.selectedRole?.roleId ? `${errors.selectedRole?.roleId ?? ""}` : ""}</FormHelperText>
        </FormControl>
      </Grid>
    </AddPersonStep>
  )
}
