import { DatePickerField } from "@/components/DatePickerField"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import {
  FirstNameTextField,
  LastNameTextField,
  PhoneNumberTextField,
  PreferredNameTextField,
} from "@/components/TextFields"
import { ZipCodeCountyAutoComplete } from "@/components/ZipCodeCountyAutoComplete"
import { ADULT_MINIMUM_AGE, GENDER_OPTIONS, GENDER_VALUES, YES_NO_OPTIONS_BOOLEAN } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { ICHRA_EMPLOYEE_NOTICE } from "@/features/Documents/documentsConstants"
import { useEmployeeDocDownloadUrl, useEmployeeDocuments } from "@/features/Documents/documentsService"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { getOnlyDate } from "@/utils/dates"
import { transformDate } from "@/utils/formatting"
import { validateSelectedZipCode } from "@/utils/validations"
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined"
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined"
import { Checkbox, FormControlLabel, Grid, Link, Stack, Typography } from "@mui/material"
import { Formik } from "formik"
import { Helmet } from "react-helmet-async"
import * as Yup from "yup"
import {
  BENEFITS_ELECTION_PATHS,
  DOCTORS,
  FAMILY,
  FAMILY_RELATIONSHIPS,
  PERSONAL_INFO,
  RECURRING_REIMBURSEMENT,
  SELF,
  WAIVE_COVERAGE,
} from "../../benefitsElectionConstants"
import { useManageShoppingPersons, useShoppingSession, useShoppingUrl } from "../../benefitsElectionService"
import { useBenefitsElectionStore, useUpdateSteps } from "../../benefitsElectionStore"
import { PersonalInformation, ShoppingUrl } from "../../benefitsElectionTypes"
import {
  createUpdatedShoppingPersonPayload,
  formatStoredPersonalInformation,
  hasApplicantDataChanged,
  isDateOfBirthValid,
} from "../../benefitsElectionUtils"
import { MedicaidAlert, MedicareAlert } from "../../components/BenefitsElectionAlerts"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"
import { useResetPlanSelection } from "../../hooks/useResetPlanSelection"
import { useValidateMedicareQualification } from "../../hooks/useValidateMedicareQualification"

const PERSONAL_INFO_INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  preferredName: "",
  isTobaccoUser: "",
  isEnrolledInMedicaid: "",
  acknowledgeTerms: false,
  gender: "",
  isMedicareEligible: false,
  relationship: SELF,
  phoneNumber: "",
  zipCode: null,
  // SAFETY: The validation schema enforces this type
} as unknown as PersonalInformation

const minimumAdultDateOfBirth = new Date()

minimumAdultDateOfBirth.setFullYear(minimumAdultDateOfBirth.getFullYear() - ADULT_MINIMUM_AGE)

export const commonPersonalInformationValidationSchema = Yup.object()
  .shape({
    relationship: Yup.string().oneOf(FAMILY_RELATIONSHIPS),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    dateOfBirth: Yup.date()
      .transform((dateValue, originalValue) => {
        if (
          originalValue === null ||
          originalValue === "" ||
          !isDateOfBirthValid(originalValue) ||
          !isDateOfBirthValid(dateValue)
        ) {
          return null
        }

        return dateValue
      })
      .nullable()
      .required("Date of birth is required")
      .max(new Date(), "Date of birth cannot be in the future"),
    gender: Yup.string().oneOf(GENDER_VALUES, "Please select a valid option.").required("Please select a gender"),
    zipCode: validateSelectedZipCode,
    isTobaccoUser: Yup.boolean().required(),
    isEnrolledInMedicaid: Yup.boolean().required(),
  })
  .test("dateOfBirth", "", obj => {
    if (obj.relationship !== SELF || (obj.relationship === SELF && obj.dateOfBirth <= minimumAdultDateOfBirth)) {
      return true
    }

    return new Yup.ValidationError("Employee must be at least 18 years old", null, "dateOfBirth")
  })

const createPersonalInfoValidationSchema = commonPersonalInformationValidationSchema.shape({
  phoneNumber: Yup.string().length(14, "Phone number must be 10 digits").required("Phone number is required"),
  acknowledgeTerms: Yup.boolean()
    .nullable()
    .when("isEnrolledInMedicaid", {
      is: true,
      then: schema => schema.optional(),
      otherwise: schema => schema.isTrue().required(),
    }),
})

interface EmployeeMedicareAndMedicaidAlertsProps {
  shoppingUrl: ShoppingUrl
  isEmployeeMedicareEligible: boolean
  isEmployeeEnrolledInMedicaid: boolean
}

export const EmployeeMedicareAndMedicaidAlerts = ({
  shoppingUrl,
  isEmployeeMedicareEligible,
  isEmployeeEnrolledInMedicaid,
}: EmployeeMedicareAndMedicaidAlertsProps) => {
  const currentStep = useBenefitsElectionStore(state => state.currentStep)

  if (isEmployeeEnrolledInMedicaid) {
    return <MedicaidAlert shoppingUrl={shoppingUrl} />
  }

  if (isEmployeeMedicareEligible) {
    // FUTURE: Please fix this awful hack
    let isAvailable = false

    for (const step of BENEFITS_ELECTION_PATHS) {
      if (step === RECURRING_REIMBURSEMENT) {
        isAvailable = true
      }
      if (step === currentStep) break
    }

    return <MedicareAlert shoppingUrl={shoppingUrl} isAvailable={isAvailable} />
  }

  return null
}

const getNextStep = (isMedicare: boolean, isMedicaid: boolean) => {
  if (isMedicaid) return WAIVE_COVERAGE

  if (isMedicare) return FAMILY

  return DOCTORS
}

export const PersonalInfo = () => {
  const shoppingSessionId = useShoppingSession()
  const shoppingUrl = useShoppingUrl()
  const { notify } = useNotifications("personal-information")
  const { mutateAsync: manageShoppingPersons } = useManageShoppingPersons(shoppingSessionId)
  const personalInformation = useBenefitsElectionStore(state => state.employee.personalInformation)
  const setPersonalInformation = useBenefitsElectionStore(state => state.setEmployeePersonalInformation)
  const employee = useBenefitsElectionStore(state => state.employee)
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const selectedPlan = useBenefitsElectionStore(state => state.selectedPlan)
  const updateSteps = useUpdateSteps()
  const { unselectPlan, unselectComparePlans } = useResetPlanSelection()
  const { user } = useAuth()
  // FUTURE: Remove these unsafe non-null assertions
  const companyId = user?.company?.companyId!
  const employeeId = user?.company?.employeeId!
  const { data: documents } = useEmployeeDocuments(companyId, employeeId, true)
  const employeeNotice = documents?.find(doc => doc.documentType === ICHRA_EMPLOYEE_NOTICE)
  const { data: employeeNoticeUrl } = useEmployeeDocDownloadUrl(companyId, employeeId, employeeNotice?.documentId, true)

  const { isMedicareEligible, handleBirthDateChange, handleBirthDateTextChange } =
    useValidateMedicareQualification(employee)

  const initialValues = personalInformation
    ? ({
        ...PERSONAL_INFO_INITIAL_VALUES,
        ...formatStoredPersonalInformation(personalInformation),
        acknowledgeTerms: false,
        // SAFETY: The validation schema enforces this type
      } as PersonalInformation)
    : PERSONAL_INFO_INITIAL_VALUES

  updateSteps(isMedicareEligible, !!personalInformation?.isEnrolledInMedicaid)

  const resetPlanSelection = async (valuesToStore: PersonalInformation) => {
    if (
      personalInformation &&
      hasApplicantDataChanged(valuesToStore, personalInformation, false) &&
      currentStep !== PERSONAL_INFO
    ) {
      unselectComparePlans()
      if (selectedPlan) {
        await unselectPlan()
      }

      const isPersonEnrolledInMedicaid = !!personalInformation.isEnrolledInMedicaid
      const nextStep = isPersonEnrolledInMedicaid
        ? PERSONAL_INFO
        : getNextStep(isMedicareEligible, isPersonEnrolledInMedicaid)
      setCurrentStep(nextStep)
    }
  }

  return (
    <>
      <Helmet title="Personal Information" />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={createPersonalInfoValidationSchema}
        onSubmit={async values => {}}
        validateOnMount
        validateOnChange
      >
        {({ values, touched, errors, isValid, handleChange, handleBlur, setFieldTouched, setFieldValue }) => {
          const {
            firstName,
            lastName,
            dateOfBirth,
            preferredName,
            isTobaccoUser,
            isEnrolledInMedicaid,
            acknowledgeTerms,
            gender,
            relationship,
            zipCode,
            phoneNumber,
          } = values

          updateSteps(isMedicareEligible, isEnrolledInMedicaid)

          return (
            <BenefitsElectionStep
              title="Personal information"
              description="This information allows us to show you accurate, personalized plan options"
              disabled={!isValid}
              next={shoppingUrl + getNextStep(isMedicareEligible, isEnrolledInMedicaid)}
              advanceOnSuccess
              data-qa={createDataQa("personal-info")}
              required
              continueLabel={isEnrolledInMedicaid ? "Waive" : undefined}
              handleContinue={async () => {
                try {
                  const valuesToStore: PersonalInformation = {
                    firstName,
                    lastName,
                    preferredName,
                    isTobaccoUser,
                    isEnrolledInMedicaid,
                    acknowledgeTerms,
                    gender,
                    isMedicareEligible,
                    zipCode,
                    phoneNumber: phoneNumber?.replace(/\D/g, ""),
                    dateOfBirth: transformDate(dateOfBirth as Date),
                    relationship,
                  }

                  const patchEmployeePayload = createUpdatedShoppingPersonPayload({
                    ...employee,
                    personalInformation: valuesToStore,
                  })

                  const manageResult = await manageShoppingPersons([patchEmployeePayload])

                  if (manageResult.meta) {
                    resetPlanSelection(valuesToStore)
                  }
                  setPersonalInformation(valuesToStore)
                  notify("Succesfully saved personal information", "success")

                  if (isEnrolledInMedicaid) {
                    setCurrentStep(PERSONAL_INFO)
                  } else if (currentStep === PERSONAL_INFO || currentStep === DOCTORS) {
                    setCurrentStep(getNextStep(isMedicareEligible, isEnrolledInMedicaid))
                  }
                } catch (error) {
                  notify(
                    "Error saving personal information. Please try again later. If the issue persists, contact support for assistance.",
                    "error"
                  )
                  console.error(error)
                }
              }}
            >
              <form data-qa="personal-info-form">
                <Grid container spacing={4} rowSpacing={6}>
                  <EmployeeMedicareAndMedicaidAlerts
                    shoppingUrl={shoppingUrl}
                    isEmployeeMedicareEligible={isMedicareEligible}
                    isEmployeeEnrolledInMedicaid={isEnrolledInMedicaid}
                  />
                  <Grid item xs={12} md={4}>
                    <FirstNameTextField />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <LastNameTextField />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <PreferredNameTextField />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PhoneNumberTextField />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ZipCodeCountyAutoComplete
                      name="zipCode"
                      touched={Boolean(touched.zipCode)}
                      error={errors.zipCode}
                      setFieldValue={setFieldValue}
                      value={zipCode}
                      handleBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePickerField
                      data-qa="personal-info-birth-date"
                      dataQa="personal-info-birth-date"
                      name="dateOfBirth"
                      label="Date of birth"
                      required
                      fullWidth
                      variant="outlined"
                      type="date"
                      maxDate={new Date()}
                      value={getOnlyDate(values.dateOfBirth ?? null)}
                      error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                      helperText={touched.dateOfBirth && errors.dateOfBirth}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onDateAccept={date => {
                        handleBirthDateChange(date)
                        setFieldTouched("dateOfBirth")
                        updateSteps(isMedicareEligible, isEnrolledInMedicaid)
                      }}
                      onInputChange={handleBirthDateTextChange}
                    />
                    {isMedicareEligible && (
                      <Stack direction="row" alignItems="center" gap={1} sx={{ ml: 2, mt: 1 }}>
                        <WarningAmberOutlinedIcon
                          sx={{ height: "1.25rem", width: "1.25rem", color: "colors.lightWarning" }}
                        />
                        <Typography variant="body2">You are over the age of 65</Typography>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField
                      dataQa="gender-select"
                      data={GENDER_OPTIONS}
                      type="text"
                      name="gender"
                      label="Gender"
                      required
                      value={gender}
                      placeholder="Select one"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography variant="caption">
                      * Required for the insurance company. These are the only options they offer.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroupCard
                      name="isTobaccoUser"
                      formName="personal-info-form"
                      elements={YES_NO_OPTIONS_BOOLEAN}
                      value={isTobaccoUser}
                      handleChange={setFieldValue}
                      required
                      label="Do you use tobacco?"
                      labelVariant="h5"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroupCard
                      name="isEnrolledInMedicaid"
                      formName="personal-info-form"
                      elements={YES_NO_OPTIONS_BOOLEAN}
                      value={isEnrolledInMedicaid}
                      handleChange={(field, medicaidValue) => {
                        if (medicaidValue) {
                          setFieldValue("acknowledgeTerms", false)
                        }

                        updateSteps(isMedicareEligible, medicaidValue)

                        return setFieldValue(field, medicaidValue)
                      }}
                      required
                      label="Are you currently enrolled in Medicaid?"
                      labelVariant="h5"
                    />
                  </Grid>
                  {isEnrolledInMedicaid === false && (
                    <>
                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <Typography variant="h5" gutterBottom data-qa="personal-info-documents-label">
                          Documents
                        </Typography>
                        <Typography variant="body1" component="span">
                          Review and acknowledge HRA{" "}
                        </Typography>
                        <Link href={employeeNoticeUrl} target="_blank" data-qa="personal-info-documents-link">
                          Employee Notice Agreement
                          <LaunchOutlinedIcon sx={{ width: "1rem", height: "1rem" }} />
                        </Link>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox required checked={!!acknowledgeTerms} />}
                          label="I acknowledge"
                          name="acknowledgeTerms"
                          sx={{ pl: 4, mt: -6 }}
                          value={acknowledgeTerms}
                          onChange={handleChange}
                          data-qa="personal-info-acknowledge-terms"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </BenefitsElectionStep>
          )
        }}
      </Formik>
    </>
  )
}
