import { addResponse } from "@/features/HRAPlanDesign/HRAPlanDesign.data"
import { usePrescreenResult } from "@/features/HRAPlanDesign/HRAPlanDesign.hooks"
import { PLAN_RESULT, PRE_SCREEN_QUESTION, radioGroupHraValidation } from "@/features/HRAPlanDesign/HRAPlanDesign.utils"
import { createDataQa } from "@/utils/dataQa"
import { Formik } from "formik"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { NavigationContainer } from "./NavigationContainer"
import { QuestionBody } from "./QuestionBody"
import { QuestionContainer } from "./QuestionContainer"
import { QuestionTitle } from "./QuestionTitle"
import { QuizBackButton } from "./QuizBackButton"
import { QuizLoader } from "./QuizLoader"
import { QuizNextQuestionButton } from "./QuizNextQuestionButton"
import { QuizProgressBar } from "./QuizProgressBar"
import { QuizRadioGroupQuestion } from "./QuizRadioGroupQuestion"

const formOptions = ["1-49", "50-149", "150+"]

export const NumFulltimeEmployees = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const dataQa = createDataQa("num-fulltime-employees")
  const numFtEeResult = usePrescreenResult("NUM_FT_EE")
  const numFtEeResponse = numFtEeResult?.response

  const initialValues = {
    radioGroup: numFtEeResponse || "",
  }

  const handleBack = async () => {
    navigate("/welcome")
  }

  const handleFormSubmit = async (values: any, { resetForm, setErrors, setStatus, setSubmitting }: any) => {
    try {
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
      if (formOptions.includes(values.radioGroup)) {
        switch (values.radioGroup) {
          case "1-49":
            await dispatch(
              addResponse({
                question: PRE_SCREEN_QUESTION.NUM_FT_EE,
                response: values.radioGroup,
                result: PLAN_RESULT.QSEHRA,
              })
            )
            break
          case "50-149":
            await dispatch(
              addResponse({
                question: PRE_SCREEN_QUESTION.NUM_FT_EE,
                response: values.radioGroup,
                result: PLAN_RESULT.ICHRA_DIY,
              })
            )
            break
          case "150+":
            await dispatch(
              addResponse({
                question: PRE_SCREEN_QUESTION.NUM_FT_EE,
                response: values.radioGroup,
                result: PLAN_RESULT.ICHRA_NON_DIY,
              })
            )
            break
          default:
            throw Error("Invalid Option Selected")
        }
        navigate("/hra-determination/have_group_plan")
      }
    } catch (error: any) {
      setStatus({ sent: false })
      setErrors({ submit: error.message })
      setSubmitting(false)
    }
  }

  return (
    <QuestionContainer data-qa={createDataQa(dataQa, "wrapper")}>
      <Formik initialValues={initialValues} validationSchema={radioGroupHraValidation} onSubmit={handleFormSubmit}>
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, isValid, values }) => (
          <>
            <QuizProgressBar value={20} />
            <QuestionTitle>How many full-time employees does your organization have?</QuestionTitle>
            <QuestionBody>Full-time W-2 employees</QuestionBody>
            {isSubmitting ? (
              <QuizLoader />
            ) : (
              <form onSubmit={handleSubmit}>
                <QuizRadioGroupQuestion
                  options={formOptions}
                  value={values.radioGroup}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="radioGroup"
                  data-qa={createDataQa("option")}
                />
                <NavigationContainer>
                  <QuizBackButton handleBack={handleBack} />
                  <QuizNextQuestionButton
                    onClick={handleSubmit}
                    disabled={!(isValid && values.radioGroup && !isSubmitting && errors)}
                  />
                </NavigationContainer>
              </form>
            )}
          </>
        )}
      </Formik>
    </QuestionContainer>
  )
}
