import { TakeCommandLogoInline } from "@/components/Branding"
import { appConfig } from "@/config"
import {
  getAppName,
  getMfaQrCode,
  GOOGLE_AUTHENTICATOR_ANDROID_APP,
  GOOGLE_AUTHENTICATOR_IOS_APP,
  initialValues,
  mfaVerificationSettle,
  MICROSOFT_AUTHENTICATOR_ANDROID_APP,
  MICROSOFT_AUTHENTICATOR_IOS_APP,
  useCognitoUser,
  useSecretCode,
} from "@/features/Auth/pages/Mfa/mfaService"
import { VerificationCodeField } from "@/features/Auth/pages/Mfa/VerificationCodeField"
import { useAuth } from "@/features/Auth/useAuth"
import { useLoginRedirect } from "@/services/loginRedirectService"
import { useNotifications } from "@/services/notificationService"
import { verificationCodeValidationSchema } from "@/utils/validations"
import { KeyboardArrowRight } from "@mui/icons-material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { Box, Button, Chip, CircularProgress, Grid, Typography } from "@mui/material"
import { Formik } from "formik"
import { get } from "lodash"
import { useEffect } from "react"
import QRCode from "react-qr-code"
import { useLocation, useNavigate } from "react-router-dom"

export const SetupMfa = () => {
  const { refresh, user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { email: stateEmail, password, isLoggedIn } = location.state ?? {}
  const email = stateEmail ?? user?.email ?? ""
  const { notify } = useNotifications("verify-mfa")
  const { data: cognitoUser } = useCognitoUser(email, password, isLoggedIn)
  const { data: secretCode, isFetched } = useSecretCode(email, cognitoUser)
  const { redirect } = useLoginRedirect()

  useEffect(() => {
    if (!email) {
      navigate("/")
    }
  }, [email, navigate])

  const handleMfaSelection = () => {
    navigate("/mfa/select", {
      state: {
        ...(location.state ?? {}),
        email,
      },
    })
  }
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ maxWidth: "36.667rem" }}>
      <TakeCommandLogoInline />
      <Chip
        label={email}
        color="default"
        sx={{
          mt: 8,
          border: "1pt solid black",
        }}
      />
      <Typography variant="h1" sx={{ mt: 3 }}>
        Let’s Secure your account!
      </Typography>

      <Grid
        sx={{
          pl: {
            xs: 0,
            sm: "1rem",
          },
        }}
      >
        <ul
          style={{
            listStyleType: "disc",
            paddingInlineStart: 0,
          }}
        >
          <li>
            <Typography variant="body2" sx={{ mt: 6 }}>
              Get your preferred authentication app from:
            </Typography>
            <Grid
              sx={{
                ml: {
                  xs: 0,
                  sm: "2rem",
                },
                mt: "0.8rem",
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                Google Authenticator app
              </Typography>
              <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <a href={GOOGLE_AUTHENTICATOR_ANDROID_APP}>
                  <img
                    alt="Get it on Google Play"
                    style={{ height: "28pt" }}
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>
                <a href={GOOGLE_AUTHENTICATOR_IOS_APP}>
                  <img
                    alt="Download on the App Store"
                    style={{ height: "19pt" }}
                    src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                  />
                </a>
              </Grid>
            </Grid>
            <Grid
              sx={{
                ml: {
                  xs: 0,
                  sm: "2rem",
                },
                mt: "0.8rem",
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" sx={{ display: "flex", alignItems: "top" }}>
                Microsoft Authenticator app
              </Typography>
              <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <a href={MICROSOFT_AUTHENTICATOR_ANDROID_APP}>
                  <img
                    alt="Get it on Google Play"
                    style={{ height: "28pt" }}
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>{" "}
                <a href={MICROSOFT_AUTHENTICATOR_IOS_APP}>
                  <img
                    alt="Download on the App Store"
                    style={{ height: "19pt" }}
                    src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                  />
                </a>
              </Grid>
            </Grid>
          </li>

          <li style={{ marginTop: "0.8rem" }}>
            <Typography variant="body2">In the authentication app, select 'Set Up Account',</Typography>
          </li>

          <li style={{ marginTop: "0.8rem" }}>
            <Typography variant="body2">
              Scan the QR code below or enter the key manually, then enter the verification code generated by your
              authentication app.
            </Typography>
          </li>
        </ul>
      </Grid>
      {!isFetched ? (
        <Box
          sx={{
            width: 150,
            height: 150,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          data-qa="loading-secret-code"
        >
          <CircularProgress size={80} />
        </Box>
      ) : (
        <Grid sx={{ height: "16.667rem", margin: "0.8rem auto 0", maxWidth: "16.667rem", width: "100%" }}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={secretCode ? getMfaQrCode(secretCode, email, getAppName()) : ""}
            viewBox="0 0 256 256"
          />
        </Grid>
      )}
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
        }}
      >
        <Typography variant="body2" sx={{ width: "100%", overflow: "auto", textAlign: "center" }}>
          {secretCode}
        </Typography>
        <Button
          variant="text"
          startIcon={<ContentCopyIcon />}
          onClick={() => {
            if (secretCode) {
              navigator.clipboard.writeText(secretCode)
              notify("Copied to clipboard", "success")
            }
          }}
        />
      </Grid>
      <Formik
        initialValues={initialValues}
        validationSchema={verificationCodeValidationSchema}
        onSubmit={async (values, { setErrors, setStatus }) => {
          try {
            await mfaVerificationSettle(cognitoUser!, values.verificationCode)
            await refresh()
            await redirect()
          } catch (error: any) {
            console.error(error)
            const message = error.message || "Something went wrong"

            setStatus({ success: false })
            setErrors({ verificationCode: message })
            notify(`${message}`, "error")
          }
        }}
      >
        {({ isSubmitting, handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
          <form style={{ width: "100%" }} onSubmit={handleSubmit} noValidate data-qa="sign-in-form">
            <VerificationCodeField
              sx={{
                mt: 8,
                width: "100%",
              }}
              required
              value={values.verificationCode}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(get(touched, `verificationCode`) && get(errors, `verificationCode`))}
              helperText={get(touched, `verificationCode`) && get(errors, `verificationCode`)}
              inputProps={{ "data-qa": "verification-code-input" }}
              data-qa="verification-code"
              name="verificationCode"
              label="Authentication Code"
              placeholder="Enter the code from your app here"
              InputLabelProps={{ shrink: true }}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 10 }}
              disabled={isSubmitting}
              // FUTURE: Consider using continue navigation button here
              startIcon={isSubmitting ? <CircularProgress size={20} /> : <KeyboardArrowRight />}
              data-qa="verify-button"
            >
              Authenticate
            </Button>
            <Button
              variant="text"
              onClick={handleMfaSelection}
              sx={{
                mt: 3,
                width: "100%",
                textAlign: "center",
              }}
            >
              Back to selection
            </Button>
          </form>
        )}
      </Formik>
      {isLoggedIn && !appConfig.isProduction && (
        <Button onClick={() => redirect()} fullWidth sx={{ mt: 4 }} data-qa="skip-button">
          Skip
        </Button>
      )}
    </Grid>
  )
}
