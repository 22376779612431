import { AmountTextField } from "@/components/AmountTextField"
import { DatePickerField } from "@/components/DatePickerField"
import { DrawerForm, DrawerFormProps } from "@/components/DrawerForm"
import { BackNavigationButton, FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { SelectField } from "@/components/SelectField"
import { SelectMultiField } from "@/components/SelectMultiField"
import { EXTERNAL_LINKS, PLAN_PREMIUM_LABEL, SELF_ENROLL } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { POC } from "@/features/Documents/documentsConstants"
import { useCreateDocument } from "@/features/Documents/documentsService"
import { useConfirmation } from "@/services/Confirmations"
import { useNotifications } from "@/services/notificationService"
import { takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { getOnlyDate, yearFirstDay, yearLastDay } from "@/utils/dates"
import {
  createDateFromText,
  createDateFromTextOrElse,
  formatCents,
  formatDate,
  formatDollarToCents,
  toTitleCase,
  transformDate,
} from "@/utils/formatting"
import { Cents, Uuid } from "@/utils/types"
import { getFileBase64, removeBase64Metadata } from "@/utils/util"
import { AddCircleOutlineOutlined, DeleteOutlined, EditOutlined } from "@mui/icons-material"
import {
  Avatar,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { Box } from "@mui/system"
import { addDays } from "date-fns"
import { Formik } from "formik"
import { sortBy } from "lodash"
import { useState } from "react"
import * as Yup from "yup"
import { create } from "zustand"
import {
  COMPLETE,
  INSURANCE_TYPE_OPTIONS,
  INSURANCE_TYPES,
  MEDICARE,
  PERSONAL_INFO,
  POC_SUBMITTED,
} from "../../benefitsElectionConstants"
import {
  useCheckCoverageForAllMembers,
  useCloseShoppingSession,
  useCreateHealthBenefitsElection,
  useDeleteHealthBenefitsElection,
  useRefreshCurrentShoppingSession,
  useShoppingUrl,
  useUpdateHealthBenefitsElection,
} from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import {
  EligibleApplicant,
  HealthBenefitsElection,
  InsuranceType,
  ProofOfCoverageFile,
  ShoppingSession,
} from "../../benefitsElectionTypes"
import { getDisplayName, getPlanBalance, useGetDisplayName } from "../../benefitsElectionUtils"
import { WarningAlert } from "../../components/BenefitsElectionAlerts"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"
import { PerMonthSpan } from "../../components/PlanComponents"
import {
  findOptionLabel,
  getRecurringReimbursementSchema,
  ProofOfCoverage,
  RecurringReimbursementForm,
  useRenderSelectValue,
} from "./RecurringReimbursement"

interface PriceProps {
  amountCents: Cents
}

const Price = ({ amountCents }: PriceProps) => (
  <>
    <Typography variant="body1bold" component="span" color="primary">
      {formatCents(amountCents)}
    </Typography>
    <PerMonthSpan />
  </>
)

interface ReimbursementsOverviewProps {
  allowanceCents: Cents
  reimbursementsCents: Cents
}

const ReimbursementsOverview = ({ allowanceCents, reimbursementsCents }: ReimbursementsOverviewProps) => {
  const { balanceValue, balanceLabel, balanceLabelColor } = getPlanBalance(allowanceCents, reimbursementsCents)

  return (
    <Stack direction={{ md: "row" }} justifyContent="space-between" width={{ md: "100%" }}>
      <Typography variant="h5" alignSelf={{ md: "center" }}>
        Your Reimbursements
      </Typography>

      <Card sx={{ p: 2 }}>
        <Stack direction="row" spacing={8} width="100%" justifyContent="space-between">
          <Typography variant="body1" component="span">
            <Typography component="span" mr={4}>
              Total Allowance
            </Typography>
            <Price amountCents={allowanceCents} />
          </Typography>
          <Typography component="span">
            <Typography component="span" mr={4}>
              {balanceLabel}
            </Typography>
            <Typography variant="body1bold" color={balanceLabelColor}>
              {balanceValue}
            </Typography>
            <PerMonthSpan />
          </Typography>
        </Stack>
      </Card>
    </Stack>
  )
}

export const renderInsuranceType = (insuranceType: InsuranceType) => toTitleCase(insuranceType?.replace(/_[A-Z_]+/, ""))

interface ReimbursementCardProps {
  members: EligibleApplicant[]
  election: Required<HealthBenefitsElection>
  editElection: () => void
  deleteElection: (electionId: Uuid) => void
}

const ReimbursementCard = ({
  members,
  election: { id, premiumAmountCents, shoppingPersons, planEffectiveDate, planEndDate, insuranceType, isPrimary },
  deleteElection,
  editElection,
}: ReimbursementCardProps) => {
  const startDate = createDateFromText(planEffectiveDate)
  const endDate = createDateFromText(planEndDate || `${startDate.getFullYear()}-12-31`)

  return (
    <Card sx={{ p: 4, height: "100%" }}>
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2bold">Family members covered</Typography>
          <Stack direction="row" height="100%" visibility={isPrimary ? "hidden" : undefined}>
            <IconButton onClick={() => editElection()}>
              <EditOutlined />
            </IconButton>
            <IconButton onClick={() => deleteElection(id)}>
              <DeleteOutlined />
            </IconButton>
          </Stack>
        </Stack>
        <Box>
          {members
            .filter(member => shoppingPersons.map(person => person.id).includes(member.shoppingPersonId))
            .map(({ shoppingPersonId, personalInformation }) => (
              <Chip
                key={shoppingPersonId}
                label={getDisplayName(personalInformation)}
                variant="outlined"
                sx={{
                  m: 1,
                  width: "min-content",
                }}
              />
            ))}
        </Box>
        <Typography variant="body2bold" mt={4}>
          {PLAN_PREMIUM_LABEL}
        </Typography>
        <Box>
          <Price amountCents={premiumAmountCents} />
        </Box>
        <Stack direction="row" mt={6} gap={16}>
          <Box>
            <Typography variant="body2">Insurance type</Typography>
            <Typography variant="body2bold" color="primary">
              {renderInsuranceType(insuranceType)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">Start date</Typography>
            <Typography variant="body2bold" color="primary">
              {formatDate(startDate)}
            </Typography>
          </Box>
          {insuranceType !== MEDICARE && (
            <Box>
              <Typography variant="body2">End date</Typography>
              <Typography variant="body2bold" color="primary">
                {formatDate(endDate)}
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>
    </Card>
  )
}

interface AddReimbursementButtonProps {
  handleClick: () => void
}

const AddReimbursementButton = ({ handleClick }: AddReimbursementButtonProps) => (
  <Button
    onClick={handleClick}
    fullWidth
    sx={{
      minHeight: "16rem",
      height: "100%",
      color: "primary.dark",
      border: "1px dashed",
      borderColor: "colors.borderGray",
      borderRadius: "4px",
      backgroundColor: "colors.regionGray",
    }}
    data-qa="add-reimbursement-button"
  >
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item>
        <Avatar sx={{ bgcolor: takeCommandPrimary[100] }}>
          <AddCircleOutlineOutlined color="primary" />
        </Avatar>
      </Grid>
      <Grid item>
        <Typography variant="caption">Add a new reimbursement</Typography>
      </Grid>
    </Grid>
  </Button>
)

interface ReimbursementCardsContainerProps {
  members: EligibleApplicant[]
  elections: Required<HealthBenefitsElection>[]
  showReimbursementsDrawer: (election?: Required<HealthBenefitsElection>) => void
  deleteElection: (electionId: Uuid) => void
}

const ReimbursementCardsContainer = ({
  members,
  elections,
  showReimbursementsDrawer,
  deleteElection,
}: ReimbursementCardsContainerProps) => {
  // Please don't copy+paste this
  const isMediumOrUp = useMediaQuery(theme => theme.breakpoints.up("md"))
  const cols = isMediumOrUp ? 2 : 1

  return (
    <ImageList cols={cols} sx={{ width: "100%", minHeight: "16rem", rowHeight: "16rem" }}>
      {elections.map(election => (
        <ImageListItem key={election.id} sx={{ p: 2 }}>
          <ReimbursementCard
            members={members}
            election={election}
            deleteElection={deleteElection}
            editElection={() => showReimbursementsDrawer(election)}
          />
        </ImageListItem>
      ))}
      <ImageListItem sx={{ p: 2 }}>
        <AddReimbursementButton handleClick={() => showReimbursementsDrawer()} />
      </ImageListItem>
    </ImageList>
  )
}

const OtherPremiumsDescription = () => (
  <Typography variant="body1" pb="1rem">
    This might include additional Medicare parts or supplements, premiums for family members covered by a different plan
    than you, or a{" "}
    <Link target="_blank" href={EXTERNAL_LINKS.DENTAL_PARTNER}>
      dental
    </Link>{" "}
    or vision plan.
  </Typography>
)

interface OtherPremiumForm extends RecurringReimbursementForm {
  insuranceType: InsuranceType
  pocDocumentId?: string
}

const ADD_REIMBURSEMENT_INITIAL_VALUES = {
  selectedMembers: [],
  premiumAmount: "",
  startDate: null,
  endDate: "",
  insuranceType: "",
  // SAFETY: Formik isValid verifies this for us
} as unknown as OtherPremiumForm

interface State {
  initialValues: OtherPremiumForm
  setInitialValues: (initialValues: OtherPremiumForm) => void
}

const useEditStore = create<State>()(set => ({
  initialValues: ADD_REIMBURSEMENT_INITIAL_VALUES,
  setInitialValues: initialValues => set({ initialValues }),
}))

type ReimbursementsEditorProps = Omit<DrawerFormProps, "paperStyle" | "onClose" | "children"> & {
  currentShoppingSession: ShoppingSession
  applicants: EligibleApplicant[]
  editingElectionId: Uuid | undefined
  minStartDate: Date
  handleClose: () => void
}

const ReimbursementsEditor = ({
  currentShoppingSession,
  applicants,
  handleClose,
  editingElectionId,
  minStartDate,
  ...props
}: ReimbursementsEditorProps) => {
  const minEndDate = addDays(minStartDate, 1)
  const { user } = useAuth()

  const [proofOfCoverageFile, setProofOfCoverageFile] = useState<ProofOfCoverageFile>({
    file: null,
    isPreviousFile: false,
  })

  const companyId = user?.company?.companyId as Uuid
  const employeeId = user?.company?.employeeId
  const { mutateAsync: createDocument } = useCreateDocument(companyId)
  const { mutateAsync: createHealthBenefitsElection } = useCreateHealthBenefitsElection(currentShoppingSession.id)
  const { mutateAsync: updateHealthBenefitsElection } = useUpdateHealthBenefitsElection(currentShoppingSession.id)
  const getDisplayNameFast = useGetDisplayName()
  const initialValues = useEditStore(state => state.initialValues)
  const planYear = currentShoppingSession.planYear
  const selectedPlan = useBenefitsElectionStore(state => state.selectedPlan)
  const selectOptions = applicants.map(({ personalInformation, shoppingPersonId: value }) => ({
    value,
    label: getDisplayNameFast(personalInformation),
  }))

  const renderSelectValue = useRenderSelectValue(findOptionLabel, selectOptions)
  const { notify } = useNotifications("add-other-premium")

  const validationSchema = getRecurringReimbursementSchema(planYear, minStartDate, false).shape({
    insuranceType: Yup.string().oneOf(INSURANCE_TYPES).required("Insurance type is required"),
  })

  const employmentId = currentShoppingSession.healthBenefitElections[0].employmentId
  const refreshCurrentShoppingSession = useRefreshCurrentShoppingSession(employmentId)

  return (
    <DrawerForm
      paperStyle={{
        p: "1.5rem 2rem",
        width: { xs: "90%", md: "80%" },
        maxWidth: "40rem",
      }}
      onClose={() => handleClose()}
      {...props}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async ({ insuranceType, selectedMembers, premiumAmount, startDate, endDate }) => {
          try {
            const electionInfo: Omit<HealthBenefitsElection, "id"> = {
              employmentId,
              planEffectiveDate: transformDate(startDate!),
              shoppingPersons: selectedMembers.map(id => ({ id })),
              premiumAmountCents: formatDollarToCents(premiumAmount),
              insuranceType,
              isRenewal: false,
              enrollmentStatus: POC_SUBMITTED,
              enrollmentType: SELF_ENROLL,
              healthPlanType: selectedPlan?.planType,
            }

            if (endDate) {
              electionInfo.planEndDate = transformDate(endDate)
            }

            if (editingElectionId) {
              await updateHealthBenefitsElection({
                id: editingElectionId,
                ...electionInfo,
              })

              await refreshCurrentShoppingSession()
            } else {
              const file = proofOfCoverageFile.file
              if (!file) {
                throw new Error("File is null")
              }
              const base64 = await getFileBase64(file)
              const document = removeBase64Metadata(base64)
              const documentName = `supplemental-poc-file-${file.name}`

              const result = await createDocument({
                document,
                documentName,
                documentType: POC,
                employeeId,
              })

              const pocDocumentId = result.id
              await createHealthBenefitsElection({ ...electionInfo, pocDocumentId })
              await refreshCurrentShoppingSession()
            }

            handleClose()
          } catch (e) {
            notify("An error occurred submitting your premium", "error")
            console.error(e)
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isValid,
          isSubmitting,
          handleChange,
          handleBlur,
          setFieldTouched,
          handleSubmit,
        }) => (
          <>
            <Grid container spacing={4}>
              <Grid item>
                <BackNavigationButton onClick={() => handleClose()}>Back</BackNavigationButton>
              </Grid>
              <Grid item>
                <Typography variant="h1" gutterBottom>
                  {editingElectionId ? "Edit" : "Add new"} premium reimbursement
                </Typography>
                <OtherPremiumsDescription />
              </Grid>
              <Grid item xs={12}>
                <SelectMultiField
                  selectedValues={values.selectedMembers}
                  fieldLabel="Who is covered in this plan?"
                  data={selectOptions}
                  name="selectedMembers"
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("selectedMembers")}
                  error={Boolean(touched.selectedMembers && errors.selectedMembers)}
                  helperText={
                    touched.selectedMembers && errors.selectedMembers ? (errors.selectedMembers as string) : undefined
                  }
                  renderValue={renderSelectValue}
                  dataQa="members-select-field"
                />
              </Grid>
              <Grid item xs={12}>
                <AmountTextField
                  touched={!!touched.premiumAmount}
                  errorString={errors.premiumAmount!}
                  error={Boolean(touched.premiumAmount && errors.premiumAmount)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="premiumAmount"
                  label="Premium Amount"
                  value={values.premiumAmount}
                  sx={{ mt: 0 }}
                  maxAmount={10000}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <SelectField
                  required
                  name="insuranceType"
                  value={values.insuranceType}
                  label="Insurance type"
                  placeholder="Please Select"
                  data={INSURANCE_TYPE_OPTIONS}
                  onBlur={handleBlur}
                  dataQa={createDataQa("insurance-type-dropdown")}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePickerField
                  data-qa="start-date-field"
                  name="startDate"
                  label="Start Date"
                  required
                  fullWidth
                  variant="outlined"
                  type="date"
                  value={getOnlyDate(values.startDate ?? null)}
                  minDate={minStartDate}
                  defaultCalendarMonth={minStartDate}
                  maxDate={yearLastDay(planYear)}
                  error={Boolean(errors.startDate)}
                  helperText={errors.startDate}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              {values.insuranceType !== MEDICARE && (
                <Grid item xs={12}>
                  <DatePickerField
                    data-qa="end-date-field"
                    name="endDate"
                    label="End Date"
                    required
                    fullWidth
                    variant="outlined"
                    type="date"
                    minDate={minEndDate}
                    defaultCalendarMonth={minEndDate}
                    value={getOnlyDate(values.endDate ?? null)}
                    error={Boolean(touched.endDate && errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Upload proof of coverage for an existing plan
                </Typography>
                <Typography mb="1rem">
                  If you or a family member have an insurance plan purchased outside of Take Command, upload the plan
                  details to confirm the plan qualifies for reimbursement.
                </Typography>
                <Typography>
                  Proof of coverage document needs to show the following:
                  <ul>
                    <li>Enrollee's first and last name</li>
                    <li>Name of the health plan</li>
                    <li>{planYear} monthly premium amount</li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
            <ProofOfCoverage
              companyId={companyId}
              existingPocDocumentId={initialValues.pocDocumentId}
              setProofOfCoverageFile={setProofOfCoverageFile}
              hidePreamble
            />
            <FlowNavigationButtons
              handleSkip={() => handleClose()}
              skipLabel="Close"
              handleContinue={() => handleSubmit()}
              continueLabel={editingElectionId ? "Save changes" : "Add new"}
              isSubmitting={isSubmitting}
              disabled={!dirty || !isValid || (!editingElectionId && !proofOfCoverageFile.file)}
            />
          </>
        )}
      </Formik>
    </DrawerForm>
  )
}

const createOtherPremiumForm = ({
  shoppingPersons,
  premiumAmountCents,
  planEffectiveDate,
  planEndDate,
  insuranceType,
  pocDocumentId,
}: Required<HealthBenefitsElection>) => ({
  selectedMembers: shoppingPersons.map(p => p.id),
  premiumAmount: formatCents(premiumAmountCents),
  startDate: createDateFromText(planEffectiveDate),
  endDate: createDateFromTextOrElse(planEndDate, null),
  insuranceType,
  pocDocumentId,
})

export const OtherPremiums = () => {
  const setInitialValues = useEditStore(state => state.setInitialValues)
  const { confirmAction: confirmDelete } = useConfirmation({
    title: "Are you sure you want to delete this premium?",
    message: "This action will permanently delete this premium",
    actionLabel: "Delete",
    isError: true,
  })

  const shoppingUrl = useShoppingUrl()
  const currentShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const getEligibleFamilyMembers = useBenefitsElectionStore(state => state.getEligibleFamilyMembers)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)

  const planYear = currentShoppingSession.planYear
  const employmentId = currentShoppingSession.employmentId
  const minStartDate = createDateFromTextOrElse(
    currentShoppingSession.healthBenefitElections.find(e => e.isPrimary)?.planEffectiveDate,
    yearFirstDay(planYear)
  )

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [editingElection, setEditingElection] = useState<Uuid>()
  const healthBenefitElections = sortBy(
    currentShoppingSession.healthBenefitElections,
    "createdAt"
  ) as Required<HealthBenefitsElection>[] // SAFETY: The elections have valid data by the time we reach this ste

  const { monthlyAllowanceCents } = currentShoppingSession
  const reimbursementsTotalCents = healthBenefitElections.reduce((acc, e) => e.premiumAmountCents + acc, 0)
  const eligibleApplicants = getEligibleFamilyMembers()
  const next = shoppingUrl + COMPLETE

  const { mutateAsync: closeShoppingSession } = useCloseShoppingSession(
    currentShoppingSession.id,
    currentShoppingSession.planYear
  )
  const { data: checkCoverageForAllResponse } = useCheckCoverageForAllMembers(currentShoppingSession.id)
  const { mutateAsync: deleteHealthBenefitsElection } = useDeleteHealthBenefitsElection(currentShoppingSession.id)

  const refreshCurrentShoppingSession = useRefreshCurrentShoppingSession(employmentId)

  const deleteElection = async (electionId: Uuid) => {
    await confirmDelete()
    await deleteHealthBenefitsElection(electionId)
    await refreshCurrentShoppingSession()
  }

  const showReimbursementsDrawer = (election?: Required<HealthBenefitsElection>) => {
    setEditingElection(election?.id)

    setInitialValues(
      election
        ? createOtherPremiumForm(election)
        : { ...ADD_REIMBURSEMENT_INITIAL_VALUES, endDate: yearLastDay(planYear) }
    )

    setDrawerOpen(true)
  }

  const showCoverageWarning = checkCoverageForAllResponse && !checkCoverageForAllResponse.isAllMembersHaveCoverage

  return (
    <BenefitsElectionStep
      title="Do you have any other premiums you want to claim reimbursement for?"
      description={<OtherPremiumsDescription />}
      required
      advanceOnSuccess
      handleContinue={async () => {
        await closeShoppingSession()
        setCurrentStep(PERSONAL_INFO)
      }}
      next={next}
      disabled={!checkCoverageForAllResponse?.isAllMembersHaveCoverage}
    >
      {showCoverageWarning && (
        <Grid item xs={12}>
          <WarningAlert data-qa="medicaid-alert" sx={{ mt: 0 }}>
            All shopping family members must be enrolled in a medical plan before you can continue.
          </WarningAlert>
        </Grid>
      )}
      <ReimbursementsOverview allowanceCents={monthlyAllowanceCents} reimbursementsCents={reimbursementsTotalCents} />
      <ReimbursementCardsContainer
        members={eligibleApplicants}
        elections={healthBenefitElections}
        showReimbursementsDrawer={showReimbursementsDrawer}
        deleteElection={deleteElection}
      />
      <ReimbursementsEditor
        editingElectionId={editingElection}
        applicants={eligibleApplicants}
        currentShoppingSession={currentShoppingSession}
        open={drawerOpen}
        minStartDate={minStartDate}
        handleClose={() => setDrawerOpen(false)}
      />
    </BenefitsElectionStep>
  )
}
