import { LinearProgress } from "@mui/material"

interface QuizProgressBarProps {
  value: number
}

export const QuizProgressBar = ({ value }: QuizProgressBarProps) => (
  <LinearProgress
    variant="determinate"
    value={value}
    sx={{
      height: 10,
      borderRadius: 8,
      backgroundColor: "colors.lightGreyButton",
    }}
  />
)
