import { HraPlanDesignReducer } from "@/features/HRAPlanDesign/HRAPlanDesign.data"
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import { counterReducer } from "./slices/counter"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    HRAPlanDesign: HraPlanDesignReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
