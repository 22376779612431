import { Typography, TypographyProps } from "@mui/material"
import { isObject } from "lodash"
import { ReactNode } from "react"

// If the children passed to this component are a primitive type like a string or a number
// The component will wrap the content with a Typography component with the supplied props
// However if the children is a more complex ReactNode such as a JSX construct, it will return children directly

export interface TypographifyProps extends TypographyProps {
  startAdornment?: ReactNode
  endAdornment?: ReactNode
}

export const Typographify = ({ children, startAdornment, endAdornment, ...props }: TypographifyProps) =>
  isObject(children) ? (
    <>
      {startAdornment}
      {children}
      {endAdornment}
    </>
  ) : (
    <Typography {...props}>
      {startAdornment}
      {children}
      {endAdornment}
    </Typography>
  )

export const Highlighted = (props: TypographifyProps) => (
  <Typographify variant="body1bold" color="primary" component="span" {...props} />
)
