import { SELF_ENROLL } from "@/constants"
import { valueIsYes } from "@/utils/util"
import { SetStateAction, useEffect, useMemo, useState } from "react"
import { NavigationType, useNavigationType } from "react-router-dom"
import { NONE, PAY_LATER, SORT_VALUES_PROPERTIES, TAX_ADVANTAGED } from "../benefitsElectionConstants"
import { BenefitsElectionStore, useBenefitsElectionStore } from "../benefitsElectionStore"
import { Carrier, FilterPreferences, Plan, PlanSearchCategory, SortOption, SortValue } from "../benefitsElectionTypes"
import { MAX_PLANS_LIMIT, useGetPlans } from "../healthPlansService"

const filterEnrollmentTypes = (
  enrollmentTypes: FilterPreferences["enrollmentTypes"],
  carrier: Carrier,
  onExchange: boolean,
  offExchange: boolean
) => {
  const isTaxAdvantaged = !onExchange || offExchange

  if (enrollmentTypes.includes(PAY_LATER) && carrier.payLater) {
    return true
  }
  if (enrollmentTypes.includes(carrier.enrollment)) {
    return true
  }
  if (enrollmentTypes.includes(TAX_ADVANTAGED) && isTaxAdvantaged) {
    return true
  }

  return false
}

interface UseSearchPlansStateOptions {
  createFilterPreferences: (benefitsElectionState: BenefitsElectionStore) => FilterPreferences
  defaultPlansPerPage: number
  initialPage: number
}

const sortPlans = (plans: Plan[] | undefined, sortValue: SortValue, isAscendingOrder: boolean) => {
  if (plans) {
    if (sortValue !== NONE) {
      const propertyName = SORT_VALUES_PROPERTIES[sortValue]
      return plans.toSorted((planA, planB) =>
        isAscendingOrder ? planA[propertyName] - planB[propertyName] : planB[propertyName] - planA[propertyName]
      )
    } else {
      return plans.toSorted((planA, planB) => {
        const hitRatePlanA =
          new Set(
            (planA.coverages || []).map(coverage => coverage.drugPackageId).filter(drugPackageId => drugPackageId)
          ).size +
          new Set((planA.providers || []).filter(provider => provider.inNetwork).map(provider => provider.addressId))
            .size
        const hitRatePlanB =
          new Set(
            (planB.coverages || []).map(coverage => coverage.drugPackageId).filter(drugPackageId => drugPackageId)
          ).size +
          new Set((planB.providers || []).filter(provider => provider.inNetwork).map(provider => provider.addressId))
            .size

        const isTaxAdvantagedPlanA = !planA.onExchange || planA.offExchange ? 1 : 0
        const isTaxAdvantagedPlanB = !planB.onExchange || planB.offExchange ? 1 : 0

        const isEasyEnrollA = planA.carrier.enrollment === "EASY_ENROLL" ? 1 : 0
        const isEasyEnrollB = planB.carrier.enrollment === "EASY_ENROLL" ? 1 : 0

        return (
          isEasyEnrollB - isEasyEnrollA ||
          hitRatePlanB - hitRatePlanA ||
          isTaxAdvantagedPlanB - isTaxAdvantagedPlanA ||
          planA.premiumAmountCents - planB.premiumAmountCents
        )
      })
    }
  }
  return []
}

export const useSearchPlansState = ({
  createFilterPreferences,
  defaultPlansPerPage,
  initialPage,
}: UseSearchPlansStateOptions) => {
  const navigationType = useNavigationType()
  const benefitsElectionState = useBenefitsElectionStore(state => state)
  const { planGridState, setPlanGridState, setRenewalPlanId, selectedPlan } = benefitsElectionState
  const {
    filterPreferences: storedFilterPreferences,
    currentSortOption: storedCurrentSortOption,
    page: storedPage,
    plansPerPage: storedPlansPerPage,
    restoreState,
  } = planGridState ?? {}
  const loadStoredValue = navigationType === NavigationType.Pop && restoreState

  const [filterPreferences, setFilterPreferences] = useState<FilterPreferences>(() =>
    loadStoredValue && storedFilterPreferences
      ? storedFilterPreferences
      : createFilterPreferences(benefitsElectionState)
  )

  const [currentSortOption, setCurrentSortOption] = useState<SortOption>(
    loadStoredValue && storedCurrentSortOption
      ? storedCurrentSortOption
      : {
          sortValue: NONE,
          isAscendingOrder: true,
        }
  )
  const { sortValue, isAscendingOrder } = currentSortOption

  const [page, setPage] = useState(loadStoredValue && storedPage ? storedPage : initialPage)
  const [plansPerPage, setPlansPerPage] = useState(
    loadStoredValue && storedPlansPerPage ? storedPlansPerPage : defaultPlansPerPage
  )
  const { data, isLoading, isSuccess, isFetching, isError } = useGetPlans(MAX_PLANS_LIMIT, 0, filterPreferences)
  const showLoading = isLoading || isFetching || (!data && !isError)
  const allPlans = data?.plans
  const totalAvailablePlans = data?.meta.total ?? 0
  const renewalPlanId = data?.renewalData?.renewPlanId

  useEffect(() => {
    if (renewalPlanId) {
      setRenewalPlanId(renewalPlanId)
    }
  }, [renewalPlanId, setRenewalPlanId])

  useEffect(() => {
    if (restoreState) {
      setPlanGridState({ restoreState: false })
    }
  }, [restoreState, setPlanGridState])

  const sortedPlans = useMemo(() => {
    const sorted = sortPlans(allPlans, sortValue, isAscendingOrder)

    return selectedPlan ? [selectedPlan, ...sorted.filter(p => p.id !== selectedPlan.id)] : sorted
  }, [allPlans, isAscendingOrder, selectedPlan, sortValue])

  const filteredPlans = useMemo(() => {
    sortedPlans.forEach(plan => {
      if (!plan.carrier.enrollment) {
        plan.carrier.enrollment = SELF_ENROLL
      }
    })

    const { isHsaEligible, preferredPlanTypes, categories, carrierPreferences, enrollmentTypes } = filterPreferences

    return sortedPlans.filter(
      ({ id, hsaEligible, planType, level, carrier, onExchange, offExchange, providers, coverages }) => {
        if (id === selectedPlan?.id) {
          return true
        }

        let satisfiesFilter = true

        if (isHsaEligible) {
          satisfiesFilter = satisfiesFilter && valueIsYes(isHsaEligible) === hsaEligible
        }
        if (preferredPlanTypes.length > 0) {
          satisfiesFilter = satisfiesFilter && preferredPlanTypes.includes(planType)
        }
        if (categories.length > 0) {
          satisfiesFilter = satisfiesFilter && categories.includes(level.toUpperCase() as PlanSearchCategory)
        }
        if (carrierPreferences.length > 0) {
          satisfiesFilter = satisfiesFilter && carrierPreferences.map(c => c.id).includes(carrier?.id ?? "")
        }
        if (enrollmentTypes.length > 0) {
          satisfiesFilter = satisfiesFilter && filterEnrollmentTypes(enrollmentTypes, carrier, onExchange, offExchange)
        }

        return satisfiesFilter
      }
    )
  }, [sortedPlans, filterPreferences, selectedPlan?.id])

  const totalFilteredPlans = filteredPlans?.length ?? 0
  const foundNoPlans = totalFilteredPlans === 0
  const displayPlans = foundNoPlans ? sortedPlans : filteredPlans
  const totalDisplayedPlans = displayPlans.length

  const currentPlans = useMemo(() => {
    const startIndex = page * plansPerPage
    const endIndex = (page + 1) * plansPerPage

    return displayPlans.slice(startIndex, endIndex)
  }, [displayPlans, page, plansPerPage])

  const resetPage = () => {
    // FUTURE: Refactor this to remove the hardcoded element query
    // We need a generalized way to dispatch scroll events from across the app
    // In the meantime, I beg you to PLEASE not copy-paste this hack
    document.querySelector("#find-plan-layout")?.scroll(0, 0)
    setPage(initialPage)
  }

  const handleSort = (sortOption: SortOption) => {
    setCurrentSortOption(sortOption)
    resetPage()
  }

  const persistPlansState = () =>
    setPlanGridState({ filterPreferences, currentSortOption, page, plansPerPage, restoreState: true })

  return {
    currentPlans,
    allPlans: allPlans ?? [],
    totalAvailablePlans,
    totalFilteredPlans,
    isLoading,
    isSuccess,
    showLoading,
    isError,
    filterPreferences,
    currentSortOption,
    page,
    plansPerPage,
    totalDisplayedPlans,
    foundNoPlans,
    setPage,
    setPlansPerPage,
    handleSort,
    persistPlansState,
    setFilterPreferences: (action: SetStateAction<FilterPreferences>) => {
      resetPage()
      return setFilterPreferences(action)
    },
    renewalPlanId,
  }
}
