import { LoadingContentArea } from "@/components/LoadingContentArea"
import { StyledCard } from "@/components/StyledCard"
import { toTitleCase } from "@/utils/formatting"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material"
import { useMemo } from "react"
import { Helmet } from "react-helmet-async"
import { Link, useParams } from "react-router-dom"
import { useAutoPayCompanies, useRegistrationTrackingDetails } from "../../tcHubService"
import { RegistrationTrackingDetailModel } from "../../tcHubTypes"
import { CompanySet } from "../Accounts/TcHubAccountsPage"

interface PaymentRegistrationInformationProps {
  registrationTrackingDetails: RegistrationTrackingDetailModel
  companySet: Map<string, string>
  isCompaniesLoading: boolean
}

const PaymentRegistrationInformation = ({
  registrationTrackingDetails,
  companySet,
  isCompaniesLoading,
}: PaymentRegistrationInformationProps) => (
  <StyledCard>
    <Grid container spacing={6} data-qa="account-details-business-information">
      <Grid item xs={12} mb={3}>
        <Typography variant="h5">Change payment registration tracking</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="companyName"
          label="Company Name"
          value={registrationTrackingDetails?.companyId ? companySet.get(registrationTrackingDetails.companyId) : ""}
          InputProps={{
            readOnly: true,
            endAdornment: isCompaniesLoading && <CircularProgress size={20} />,
          }}
          data-qa="registration-tracking-detail-company-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="registrationStatus"
          label="Registration Status"
          value={toTitleCase(registrationTrackingDetails?.registrationStatus ?? "")}
          InputProps={{
            readOnly: true,
          }}
          data-qa="registration-tracking-detail-registration-status"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="vendorApplicationFormId"
          label="Vendor Application Form ID"
          value={toTitleCase(registrationTrackingDetails?.vendorApplicationFormId ?? "")}
          InputProps={{
            readOnly: true,
          }}
          data-qa="registration-tracking-detail-vendor-application-form-id"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="vendorApplicationId"
          label="Vendor Application ID"
          value={registrationTrackingDetails?.vendorApplicationId ?? ""}
          InputProps={{
            readOnly: true,
          }}
          data-qa="registration-tracking-detail-vendor-application-id"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="vendorCustomerId"
          label="Vendor Customer ID"
          value={registrationTrackingDetails?.vendorCustomerId ?? ""}
          InputProps={{
            readOnly: true,
          }}
          data-qa="registration-tracking-detail-vendor-customer-id"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="microDepositStatus"
          label="Micro Deposit Status"
          value={registrationTrackingDetails?.microDepositStatus ?? ""}
          InputProps={{
            readOnly: true,
          }}
          data-qa="registration-tracking-detail-micro-deposit-status"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="microDepositAchReturnCode"
          label="Micro Deposit ACH Return Code"
          value={registrationTrackingDetails?.microDepositAchReturnCode ?? ""}
          InputProps={{
            readOnly: true,
          }}
          data-qa="registration-tracking-detail-micro-deposit-ach-return-code"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="microDepositAchReturnDescription"
          label="Micro Deposit Return Description"
          value={registrationTrackingDetails?.microDepositReturnDescription ?? ""}
          InputProps={{
            readOnly: true,
          }}
          data-qa="registration-tracking-detail-micro-deposit-return-description"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="reapplyCount"
          label="Reapply Count"
          value={registrationTrackingDetails?.reapplyCount ?? ""}
          InputProps={{
            readOnly: true,
          }}
          data-qa="registration-tracking-detail-reapply-count"
        />
      </Grid>
    </Grid>
  </StyledCard>
)

type CompanySetType = ReturnType<typeof CompanySet>

interface RegistrationDataProps {
  companySet: CompanySetType
  details: RegistrationTrackingDetailModel | undefined
  isPageLoading: boolean
  isCompaniesLoading: boolean
}
const RegistrationData = ({ companySet, details, isPageLoading, isCompaniesLoading }: RegistrationDataProps) => {
  if (isPageLoading) return <LoadingContentArea data-qa="loading-registration-tracking-details" />

  if (details)
    return (
      <PaymentRegistrationInformation
        registrationTrackingDetails={details}
        companySet={companySet}
        isCompaniesLoading={isCompaniesLoading}
      />
    )

  return <Typography variant="body1">No registration tracking details found.</Typography>
}

interface Params extends Record<string, string | undefined> {
  registrationTrackingId: string
}

export const TcHubOnboardingRegistrationTrackingDetailPage = () => {
  const { registrationTrackingId } = useParams<Params>()

  const { data: registrationTrackingDetails, isLoading: isRegistrationTrackingDetailsLoading } =
    useRegistrationTrackingDetails(registrationTrackingId ?? "")

  const { data: companies, isLoading: isCompaniesLoading } = useAutoPayCompanies()
  const companySet = useMemo(() => CompanySet(companies ?? []), [companies])
  const isPageLoading = isRegistrationTrackingDetailsLoading || !registrationTrackingDetails

  if (!registrationTrackingId) {
    return <Typography variant="body1">No registration tracking id</Typography>
  }

  return (
    <Grid container data-qa="tc-hub-registration-tracking-details" spacing={6}>
      <Grid item>
        <Helmet title="TC Hub Account Detail" />
        <Button
          color="primary"
          component={Link}
          to="/admin/autopay/registration-trackings"
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} mt={3}>
            <Typography variant="h1" gutterBottom display="inline" data-qa="registration-tracking-details-title">
              Registration Tracking Detail
            </Typography>
          </Grid>
        </Grid>
        <RegistrationData
          isPageLoading={isPageLoading}
          isCompaniesLoading={isCompaniesLoading}
          companySet={companySet}
          details={registrationTrackingDetails}
        />
      </Grid>
    </Grid>
  )
}
