import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { DashboardLayout } from "@/features/Dashboard/components/DashboardLayout"
import { Typography } from "@mui/material"
import { DocumentsTable } from "./DocumentsTable"

export const Documents = () => (
  <AuthGuard>
    <DashboardLayout>
      <Typography variant="h1" gutterBottom>
        Documents
      </Typography>
      <DocumentsTable isEROnboarding={false} />
    </DashboardLayout>
  </AuthGuard>
)
