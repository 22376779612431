import { appConfig } from "@/config"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { takeCommandPrimary } from "@/theme/palette"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount"
import { Button, Grid, SvgIconProps, SxProps, Theme, Typography } from "@mui/material"
import { ComponentType } from "react"
import { UploadCsvFile } from "../../components/UploadCsvFile"
import { AddPeopleStepProps } from "../addPersonValidations"
import { STEPS } from "../types"

const centeredGridItemStyle = { display: "flex", justifyContent: "center", alignItems: "center" }

interface ActionPickerProps {
  icon: ComponentType<SvgIconProps & { activeclassname?: string }>
  title: string
  description: string
  action: string
  onAction: () => void
  "data-qa"?: string
  sx?: SxProps<Theme>
  isUpdateCSV: boolean
  userId?: string
  companyId?: string
  planId?: string
  token?: string
  onClose: () => void
}

const ActionPicker = ({
  icon: IconComponent,
  title,
  description,
  action,
  onAction,
  "data-qa": dataQaLocator,
  sx,
  isUpdateCSV,
  userId,
  companyId,
  planId,
  token,
  onClose,
}: ActionPickerProps) => (
  <Grid
    container
    item
    xs={12}
    md={6}
    sx={{
      ...sx,
      borderRadius: "6px",
      border: "3px solid",
      borderColor: "grey.200",
      py: 8,
      px: 4,
    }}
  >
    <Grid item xs={12} sx={centeredGridItemStyle}>
      <IconComponent
        sx={{
          fill: takeCommandPrimary.main,
          width: { xs: 60, sm: 80 },
          height: { xs: 60, sm: 80 },
        }}
      />
    </Grid>
    <Grid item xs={12} sx={centeredGridItemStyle} mb={1}>
      <Typography variant="h5" sx={{ minHeight: "1.5rem" }}>
        {title}
      </Typography>
    </Grid>
    <Grid item xs={12} sx={centeredGridItemStyle}>
      <Typography sx={{ mb: 6, minHeight: "4rem", textAlign: "center", maxWidth: "26rem" }}>{description}</Typography>
    </Grid>
    <Grid item xs={12} sx={centeredGridItemStyle}>
      {isUpdateCSV ? (
        <UploadCsvFile
          title="Continue"
          userId={userId ?? ""}
          companyId={companyId ?? ""}
          licenseKey={appConfig.csvBoxLicense}
          data-qa={dataQaLocator}
          planId={planId}
          token={token}
          onClose={onClose}
        />
      ) : (
        <Button
          sx={{ background: takeCommandPrimary.actionTeal }}
          data-qa={dataQaLocator}
          variant="contained"
          onClick={onAction}
        >
          {action}
        </Button>
      )}
    </Grid>
  </Grid>
)

export const ConfirmActionStep = ({ onNextStep, onClose }: AddPeopleStepProps) => {
  const { user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId
  const userId = user!.id
  const hraPlan = user?.companyHRAPlan?.[0]
  const currentPlanId = hraPlan?.id
  const token = user?.signInUserSession?.idToken?.jwtToken

  return (
    <Grid container sx={{ flexWrap: "nowrap", gap: 4, px: { sx: 0, sm: 4 }, pb: 4 }}>
      <ActionPicker
        icon={SupervisorAccountIcon}
        title="Add a person"
        description="From here you’ll be able to add a single user with the data you have available"
        action="Continue"
        onAction={() => onNextStep(STEPS.CONFIRM_BENEFITS)}
        data-qa="add-person"
        isUpdateCSV={false}
        onClose={onClose}
      />
      <ActionPicker
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
        }}
        icon={FileUploadIcon}
        title="Add from a CSV file"
        description="From here you'll be able to add multiple users from a CSV file"
        action="Continue"
        onAction={() => onNextStep(STEPS.WELL_DONE_MESSAGE)}
        data-qa="upload-csv"
        isUpdateCSV
        userId={userId}
        companyId={companyId}
        planId={currentPlanId}
        token={token}
        onClose={onClose}
      />
    </Grid>
  )
}
