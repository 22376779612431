import { TableBodyLoadingSkeleton } from "@/components/Table/TableBodyLoadingSkeleton"
import { createDataQa } from "@/utils/dataQa"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material"
import { noop } from "lodash"
import { ReactNode } from "react"

type TableAlign = "left" | "center" | "right" | "justify" | "inherit"
type TablePadding = "checkbox" | "normal" | "none"
export interface HeadCell {
  id: string
  name: string
  label: ReactNode
  align: TableAlign
  padding: TablePadding
}

export interface SimpleTableProps<T> {
  tableName: string
  hasPagination: boolean
  rowCount?: number
  rowsPerPageOptions?: number[]
  rowsPerPage?: number
  currentPage?: number
  headCells: HeadCell[]
  isSortable: boolean
  sortBy?: string
  sortDirection?: "desc" | "asc"
  rowData: T[]
  rowHover: boolean
  isLoading?: boolean
  onPageChange?: () => void
  onRowsPerPageChange?: () => void
  getUniqueId: (row: T) => string
  handleSortClick?: (id: string) => void
  rowCells: (rowData: any) => ReactNode
}

export const SimpleTable = <T,>({
  tableName,
  headCells,
  hasPagination = false,
  rowCount = 10,
  rowsPerPageOptions = [],
  rowsPerPage = 10,
  currentPage = 0,
  isSortable = false,
  sortBy,
  sortDirection,
  rowData,
  rowHover = false,
  isLoading = false,
  onPageChange = noop,
  onRowsPerPageChange,
  handleSortClick = noop,
  rowCells,
  getUniqueId,
}: SimpleTableProps<T>) => {
  const dataQaBase = createDataQa(tableName, "table")

  return (
    <>
      <TableContainer data-qa={createDataQa(dataQaBase, "container")}>
        <Table>
          <TableHead data-qa={createDataQa(dataQaBase, "head")}>
            <TableRow>
              {headCells.map(headCell => (
                <TableCell
                  key={headCell.name}
                  align={headCell.align}
                  size="small"
                  padding={headCell.padding}
                  sortDirection={isSortable && sortBy === headCell.id ? sortDirection : undefined}
                  data-qa={createDataQa(dataQaBase, `table-head-cell-${headCell.id}`)}
                >
                  {isSortable ? (
                    <TableSortLabel
                      onClick={() => handleSortClick(headCell.id)}
                      active={sortBy === headCell.id}
                      direction={sortBy === headCell.id ? sortDirection : "asc"}
                      data-qa={createDataQa(dataQaBase, `table-head-cell-${headCell.label}-sort-label`)}
                      sx={{ fontWeight: "bold" }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody data-qa={createDataQa(dataQaBase, "body")}>
            {!isLoading &&
              rowData.map(row => (
                <TableRow
                  key={getUniqueId(row)}
                  hover={rowHover}
                  data-qa={createDataQa(dataQaBase, `table-body-row-${row}`)}
                >
                  {rowCells(row)}
                </TableRow>
              ))}
            {isLoading && <TableBodyLoadingSkeleton numColumns={headCells.length} />}
          </TableBody>
        </Table>
      </TableContainer>
      {hasPagination && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          data-qa={createDataQa(dataQaBase, "pagination")}
        />
      )}
    </>
  )
}
