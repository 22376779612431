import dogAndBall from "@/assets/svg/dog-and-ball.svg"
import { Footer } from "@/components/Footer"
import { NavbarUserDropdown } from "@/components/Navbar/NavbarUserDropdown"
import { StyledCard } from "@/components/StyledCard"
import { PHONE_NUMBER } from "@/constants"
import { createDataQa } from "@/utils/dataQa"
import { cleanUpUsPhoneNumber } from "@/utils/formatting"
import { Box, Button, Grid, Link, Typography } from "@mui/material"

export const ApologiesPendingSetup = () => (
  <StyledCard sx={{ minHeight: "100%", margin: "0 auto" }}>
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <NavbarUserDropdown hideProfileItem />
    </Box>
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <Box
          component="img"
          alt="dog and ball"
          data-qa={createDataQa("dog-and-ball-image")}
          src={dogAndBall}
          sx={{ height: "21.25rem" }}
        />
      </Grid>
      <Grid item>
        <Typography variant="h1" align="center" gutterBottom>
          Apologies for the delay!
        </Typography>
      </Grid>
      <Grid item>
        <Typography align="center" sx={{ maxWidth: "35rem", mt: 4 }}>
          Your Take Command representative is still setting up your account.
        </Typography>
        <Typography align="center" sx={{ maxWidth: "37rem" }}>
          You'll get an email as soon as it's ready. If you have any questions or can't log in, reach out to us.
        </Typography>
      </Grid>
      <Button variant="contained" sx={{ mt: 8, minWidth: "10rem" }} data-qa="chat-with-us-button">
        Chat with us
      </Button>
      <Grid item>
        <Typography align="center" sx={{ maxWidth: "35rem", mt: 4 }}>
          Or call us
        </Typography>
      </Grid>
      <Grid item>
        <Link href={`tel:+${cleanUpUsPhoneNumber(PHONE_NUMBER)}`} data-qa="phone-number-button">
          {PHONE_NUMBER}
        </Link>
      </Grid>
    </Grid>
    <Box sx={{ mt: 40 }}>
      <Footer />
    </Box>
  </StyledCard>
)
