import { ConfirmationModal, ModalConfig } from "@/components/ConfirmationModal"
import { useAuth, useCompanyId } from "@/features/Auth/useAuth"
import { AboutProfileFormStep } from "@/features/People/AddPersonModal/steps/AboutProfileFormStep"
import { AddressProfileFormStep } from "@/features/People/AddPersonModal/steps/AddressProfileFormStep"
import { BenefitsProfileFormStep } from "@/features/People/AddPersonModal/steps/BenefitsProfileFormStep"
import { ConfirmActionStep } from "@/features/People/AddPersonModal/steps/ConfirmActionStep"
import { ConfirmBenefitsStep } from "@/features/People/AddPersonModal/steps/ConfirmBenefitsStep"
import { WellDoneMessageStep } from "@/features/People/AddPersonModal/steps/WellDoneMessageStep"
import { useNotifications } from "@/services/notificationService"
import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack } from "@mui/material"
import { Formik } from "formik"
import { useState } from "react"
import { PartialDeep } from "type-fest"
import { useAddPerson } from "./addPersonService"
import { convertToTouched } from "./addPersonUtils"
import { addPersonValidate, AddPersonValues } from "./addPersonValidations"
import { BorderLinearProgress } from "./steps/StyledComponents"
import { Step, STEPS } from "./types"

export const ADD_PERSON_INITIAL_VALUES: PartialDeep<AddPersonValues> = {
  firstName: "",
  lastName: "",
  middleName: "",
  preferredName: "",
  email: "",
  phoneNumber: "",
  benefitEligible: true,
  selectedRole: {
    roleId: "",
    roleName: "",
  },
  homeAddress: {
    street1: "",
    street2: "",
    city: "",
    county: "",
    state: "" as never,
    zip: "",
  },
  className: "",
  employeeNumber: "",
  employmentType: undefined,
  hireDate: undefined,
  dateOfBirth: undefined,
  eligibilityDate: undefined,
  isTcHub: false,
}

const STEP_COMPONENTS = {
  [STEPS.CONFIRM_ACTION.id]: ConfirmActionStep,
  [STEPS.CONFIRM_BENEFITS.id]: ConfirmBenefitsStep,
  [STEPS.ABOUT_PERSON_FORM.id]: AboutProfileFormStep,
  [STEPS.ADDRESS_PERSON_FORM.id]: AddressProfileFormStep,
  [STEPS.EMPLOYMENT_PERSON_FORM.id]: BenefitsProfileFormStep,
  [STEPS.WELL_DONE_MESSAGE.id]: WellDoneMessageStep,
  [STEPS.TC_HUB_ABOUT_PERSON_FORM.id]: BenefitsProfileFormStep,
  [STEPS.CONFIRM_COMPANY.id]: ConfirmActionStep,
}

interface FormStepperProps {
  onClose: () => void
}

const FormStepper = ({ onClose }: FormStepperProps) => {
  const [activeStep, setActiveStep] = useState<Step>(STEPS.CONFIRM_ACTION)
  const [lastSteps, setLastSteps] = useState<Step[] | null>(null)
  const [modal, setModal] = useState<ModalConfig>(null)
  const { user } = useAuth()
  // FUTURE: Remove this unsafe non-null assertion
  const companyId = useCompanyId(false)!
  const { notify } = useNotifications("add-person-modal")
  const hraPlan = user?.companyHRAPlan?.[0]
  // FUTURE: Remove this unsafe non-null assertion
  const currentPlanId = hraPlan?.id!
  const hraStartDate = hraPlan?.hraStartDate!

  const { mutateAsync: addPerson, isPending: isAddingPerson } = useAddPerson(companyId, currentPlanId)
  const StepComponent = STEP_COMPONENTS[activeStep.id]

  return (
    <>
      <Formik
        initialValues={ADD_PERSON_INITIAL_VALUES as AddPersonValues}
        validate={values => addPersonValidate(values, activeStep)}
        onSubmit={async values => {
          setModal({
            title: "Adding a person",
            message: "Are you sure you want to add this person?",
            isError: false,
            onConfirm: async () => {
              try {
                await addPerson(values)
                setLastSteps([STEPS.CONFIRM_ACTION])
                notify(`${values.firstName} ${values.lastName} has been successfully added.`, "success")
                onClose()
              } catch (e: any) {
                console.error(e)
                setModal({
                  title: "Error adding person",
                  message: "An error occurred while adding this person. Please try again later.",
                  isError: true,
                  isCancelable: false,
                  actionLabel: "Close",
                  onConfirm: () => setModal(null),
                })
              }
            },
          })
        }}
      >
        {formProps => {
          const showProgress = activeStep.progress && formProps.values.benefitEligible

          const handleNext = async (next: Step) => {
            if (activeStep.shouldValidate) {
              const errors = await formProps.validateForm()

              if (errors && Object.keys(errors).length > 0) {
                formProps.setTouched(convertToTouched(formProps.touched, errors))

                return
              }
            }
            setLastSteps(prev => [...(prev || []), activeStep])
            setActiveStep(next)
          }

          const handleBack = () => {
            if (lastSteps?.length) {
              setActiveStep(lastSteps[lastSteps.length - 1])
              setLastSteps(lastSteps.slice(0, lastSteps.length - 1))

              return
            }
            onClose()
          }

          return (
            <form onSubmit={formProps.handleSubmit} data-qa="add-person-form" noValidate>
              <Grid
                container
                sx={{
                  gap: 2,
                }}
              >
                {showProgress && (
                  <BorderLinearProgress
                    variant="determinate"
                    sx={{ mt: 2, mx: 4, width: "100%" }}
                    value={activeStep.progress}
                  />
                )}
                <Grid
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <StepComponent
                    onNextStep={handleNext}
                    onClose={onClose}
                    onBack={handleBack}
                    planId={currentPlanId}
                    companyId={companyId}
                    hraStartDate={hraStartDate}
                    {...formProps}
                  />
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
      {modal && <ConfirmationModal {...modal} isOpen onClose={() => setModal(null)} isSubmitting={isAddingPerson} />}
    </>
  )
}

interface AddPersonModalProps {
  onClose: () => void
}

export const AddPersonModal = ({ onClose }: AddPersonModalProps) => (
  <Dialog
    open
    onClose={(evt, reason) => {
      if (reason === "backdropClick") {
        return
      }
      onClose()
    }}
    scroll="body"
    data-qa="add-person-modal"
    aria-labelledby="add-person-title"
    PaperProps={{
      sx: { minWidth: "min(100vw - 80px, 750px)", p: 4 },
    }}
  >
    <Stack justifyContent="space-between" direction="row" sx={{ pr: 2 }}>
      <DialogTitle variant="h3">Add new</DialogTitle>
      <IconButton onClick={onClose} sx={{ mt: 2 }}>
        <CloseIcon className="dialog-close-icon" />
      </IconButton>
    </Stack>

    <DialogContent sx={{ px: 4, pt: 1, pb: 4 }}>
      <FormStepper onClose={onClose} />
    </DialogContent>
  </Dialog>
)
