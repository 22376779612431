import { ConfirmationModal } from "@/components/ConfirmationModal"
import { ClampedTextField } from "@/components/TextFields"
import { useNotifications } from "@/services/notificationService"
import { createBusinessUnitSchema } from "@/utils/validations"
import { Divider, Grid } from "@mui/material"
import { Formik } from "formik"
import { useCreateBusinessUnit } from "../../tcHubService"

export interface TcHubCreateBusinessUnitModalProps {
  isOpen: boolean
  companyId: string
  onClose: () => void
}

export const TcHubCreateBusinessUnitModal = ({ isOpen, companyId, onClose }: TcHubCreateBusinessUnitModalProps) => {
  const { notify } = useNotifications("business-unit-creation")
  const { mutateAsync: createBusinessUnit, isPending } = useCreateBusinessUnit(companyId)
  const ASSIGN_CLASS_INITAL_VALUES = {
    businessUnitName: "",
  }

  return (
    <Formik
      initialValues={ASSIGN_CLASS_INITAL_VALUES}
      validationSchema={createBusinessUnitSchema}
      onSubmit={async values => {}}
      validateOnMount
    >
      {({ values, touched, errors, isValid, handleChange, handleBlur, setFieldTouched, setFieldValue, resetForm }) => (
        <ConfirmationModal
          title="Add new Business Unit"
          message="A business unit is a distinct part of a company that operates within a specific area of responsibility, often functioning semi-independently.
          Examples include Division, Department, Branch, Sector, and Location"
          actionLabel="Confirm"
          isCancelable
          onConfirm={async () => {
            try {
              await createBusinessUnit({ name: values.businessUnitName, companyId })
              notify("Business Unit created sucessfuly", "success")
              onClose()
              resetForm()
            } catch (error: any) {
              const errorMessage =
                error.responseMessage ??
                "Error creating Business Unit. Please try again later. If the issue persists, contact support for assistance."
              notify(errorMessage, "error")
              resetForm()
            }
          }}
          isOpen={isOpen}
          onClose={() => {
            resetForm()
            setFieldValue("businessUnitName", "")
            onClose()
          }}
          isSubmitting={isPending}
          disabled={!isValid}
        >
          <Grid container>
            <Grid item xs={12}>
              <ClampedTextField
                data-qa="business-unit-text-field"
                type="text"
                name="businessUnitName"
                label="Business Unit Name"
                required
                error={Boolean(touched.businessUnitName && errors.businessUnitName)}
                fullWidth
                helperText={touched.businessUnitName && errors.businessUnitName}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ my: 3 }}
                value={values.businessUnitName}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ width: "100%" }} />
            </Grid>
          </Grid>
        </ConfirmationModal>
      )}
    </Formik>
  )
}
