import { BaseTable, OrderByItemType } from "@/components/Table/Table"
import { AutoPayFundingReportsTableHeader } from "@/features/AutoPay/autopayTypes"
import { CompanyFundingEntry } from "@/features/Funding/fundingTypes"
import { DATE_FORMAT_MONTH_DAY_YEAR, DATE_FORMAT_MONTH_YEAR, formatCents, transformDate } from "@/utils/formatting"
import { Checkbox, TableCell, Typography } from "@mui/material"
import { useState } from "react"

const headers: AutoPayFundingReportsTableHeader[] = [
  { id: "select", label: "", sortable: false },
  { id: "name", label: "Name", field: "employeeName", sortable: true },
  { id: "amount", label: "Amount", type: "currencyCents", field: "recurringPremiumAmountCents", sortable: true },
  { id: "date", label: "Date", field: "fundingDate", sortable: true },
]

interface TchubAutoPayFundingReportsTableProps {
  events: CompanyFundingEntry[]
}

export const TchubAutoPayFundingReportsTable = ({ events: fundingEvents }: TchubAutoPayFundingReportsTableProps) => {
  const defaultOrder = { headCellId: "month", direction: "desc" } as const
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [selected, setSelected] = useState<CompanyFundingEntry[]>([])
  const [orderBy, setOrderBy] = useState<OrderByItemType[]>([defaultOrder])

  return (
    <BaseTable
      csvTitle={`Funding Events${fundingEvents.length > 0 ? ` - ${transformDate(fundingEvents[0].fundingDate, DATE_FORMAT_MONTH_YEAR)} - ` : ""}`}
      exportCsv
      rows={fundingEvents}
      selected={selected}
      orderBy={orderBy}
      searchCriteria=""
      noRecordsMessage="No funding reports available for this period."
      onToggleSelectAll={() => {
        setSelected(state => {
          if (state.length > 0) {
            return []
          } else {
            return [...fundingEvents]
          }
        })
      }}
      onToggleSelect={selection =>
        setSelected(state => {
          if (
            state.some(
              r => `${r.employeeName} ${r.fundingDate}` === `${selection.employeeName} ${selection.fundingDate}`
            )
          ) {
            return state.filter(
              r => `${r.employeeName} ${r.fundingDate}` !== `${selection.employeeName} ${selection.fundingDate}`
            )
          }

          return [...state, selection]
        })
      }
      onToggleOrderBy={headId =>
        setOrderBy(state => {
          const result = [] as OrderByItemType[]
          let orderDirection = "asc" as "asc" | "desc"

          if (state && state.length > 0 && state[0].headCellId === headId) {
            orderDirection = state[0].direction === "asc" ? "desc" : "asc"
          }
          result.push({ headCellId: headId, direction: orderDirection })
          if (defaultOrder.headCellId !== headId) {
            result.push(defaultOrder)
          }

          return result
        })
      }
      uniqueIdSelector={row => `${row.employeeName} ${row.fundingDate}`}
      headCells={headers ?? []}
      onPageChange={changedPage => setPage(changedPage)}
      onRowsPerPageChange={rows => setRowsPerPage(rows)}
      page={page}
      rowsPerPage={rowsPerPage}
    >
      {({ row, isSelected, toggleSelect, labelId, rowId }) => (
        <>
          <TableCell padding="checkbox">
            <Checkbox
              checked={isSelected}
              inputProps={{ "aria-labelledby": labelId }}
              onClick={toggleSelect}
              data-qa="funding-event-checkbox"
            />
          </TableCell>
          <TableCell id={labelId} data-qa="funding-entries-employeename">
            <Typography variant="body1" sx={{ padding: ".531rem 1rem" }}>
              {row.employeeName}
            </Typography>
          </TableCell>
          <TableCell data-qa="funding-entries-amount">
            {row.recurringPremiumAmountCents !== undefined && (
              <Typography variant="body1" sx={{ padding: ".531rem 1rem" }}>
                {row.recurringPremiumAmountCents ? formatCents(row.recurringPremiumAmountCents) : "—"}
              </Typography>
            )}
          </TableCell>
          <TableCell data-qa="funding-entries-date">
            {row.fundingDate && (
              <Typography variant="body1" sx={{ padding: ".531rem 1rem" }}>
                {transformDate(row.fundingDate, DATE_FORMAT_MONTH_DAY_YEAR)}
              </Typography>
            )}
          </TableCell>
        </>
      )}
    </BaseTable>
  )
}
