import { RadioGroupElement } from "@/components/RadioGroupCard"
import { EmployeeEnrollmentStatusWidgetData } from "./dashboardTypes"

export const SUCCESS_STATUS = "SUCCESS"
export const UNAUTHORIZED_STATUS = "UNAUTHORIZED"
export const ERROR_STATUS = "ERROR"

export const WIDGET_DATA_RETRIVAL_STATUSES = [SUCCESS_STATUS, UNAUTHORIZED_STATUS, ERROR_STATUS] as const

export const HEALTH_INSURANCE_OVERVIEW = "healthInsuranceOverview"
export const PAYMENT_HISTORY = "paymentHistory"
export const EMPLOYER_CONTRIBUTION = "employerContribution"
export const EMPLOYEE_DASHBOARD_WIDGETS = [HEALTH_INSURANCE_OVERVIEW, PAYMENT_HISTORY, EMPLOYER_CONTRIBUTION] as const

export const EMPLOYEE_ENROLLMENT_STATUS = "employeeEnrollmentStatus"
export const AUTOPAY_UPCOMING_FUNDING = "autopayUpcomingFunding"
export const EMPLOYER_DASHBOARD_WIDGETS = [EMPLOYEE_ENROLLMENT_STATUS, AUTOPAY_UPCOMING_FUNDING] as const
export const EMPLOYER_DASHBOARD_WIDGETS_SMALL_SCREEN = [AUTOPAY_UPCOMING_FUNDING, EMPLOYEE_ENROLLMENT_STATUS] as const

export const APPROVED = "APPROVED"
export const PENDING = "PENDING"
export const ARCHIVED = "ARCHIVED"
export const PINE_GREEN_COLOR = "colors.pineGreen"
export const PALE_OLIVE_COLOR = "colors.PaleOlive"
export const PUMPKIN_COLOR = "colors.pumpkin"

export const EMPTY_ENROLLMENT_STATUS_WIDGET_DATA: EmployeeEnrollmentStatusWidgetData = {
  completed: {
    waived: 0,
    optedOut: 0,
    active: 0,
  },
  inProgress: {
    onBoarding: 0,
  },
  notStarted: {
    pendingInvite: 0,
    invited: 0,
  },
}

export const LOSS_OF_HEALTH_COVERAGE = "LOSS_OF_HEALTH_COVERAGE"
export const CHANGE_IN_MARITAL_STATUS = "CHANGE_IN_MARITAL_STATUS"
export const CHANGE_OF_CITIZENSHIP = "CHANGE_OF_CITIZENSHIP"
export const CHANGE_OF_ADDRESS = "CHANGE_OF_ADDRESS"
export const BIRTH_OR_ADOPTION = "BIRTH_OR_ADOPTION"

export const QLE_VALUES = [
  LOSS_OF_HEALTH_COVERAGE,
  CHANGE_IN_MARITAL_STATUS,
  CHANGE_OF_CITIZENSHIP,
  CHANGE_OF_ADDRESS,
  BIRTH_OR_ADOPTION,
] as const

export const QLE_OPTIONS: readonly RadioGroupElement<string>[] = [
  {
    title: "Loss of health coverage",
    value: LOSS_OF_HEALTH_COVERAGE,
  },
  {
    title: "Change in marital status",
    value: CHANGE_IN_MARITAL_STATUS,
  },
  {
    title: "Change in citizenship",
    value: CHANGE_OF_CITIZENSHIP,
  },
  {
    title: "Change of address",
    value: CHANGE_OF_ADDRESS,
  },
  {
    title: "Birth or adoption",
    value: BIRTH_OR_ADOPTION,
  },
] as const

export const MAX_QLE_FILES = 1

export const MAX_DAYS_FOR_QLE = 60
