import { DatePickerField } from "@/components/DatePickerField"
import { SelectField } from "@/components/SelectField"
import { useGetCurrentClasses } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureService"
import { createDataQa } from "@/utils/dataQa"
import { getOnlyDate } from "@/utils/dates"
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import FormHelperText from "@mui/material/FormHelperText"
import { get, isString } from "lodash"
import { DateTime } from "luxon"
import { useEffect, useMemo, useState } from "react"
import { calculateFirstBenefitDate, calculateHireDate, getAllClassNames } from "../addPersonUtils"
import { AddPeopleStepProps } from "../addPersonValidations"
import { EMPLOYMENT_TYPES } from "../types"
import { AddPersonStep } from "./AddPersonStep"

export interface HraClasses {
  name: string
  id: string
  waitingPeriod: string
}

interface BenefitEligibleDate {
  name: string
  value: string
}

const formatDate = (date: string): string => DateTime.fromISO(date, { zone: "local" }).toFormat("MMMM d, yyyy")

export const BenefitsProfileFormStep = ({
  onClose,
  onNextStep,
  companyId,
  planId,
  onBack,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  hraStartDate,
}: AddPeopleStepProps) => {
  const [benefitEligibleDate, setBenefitEligibleDate] = useState<string | null>("")
  const [selectBenefitEligibleDate, setSelectBenefitEligibleDate] = useState<BenefitEligibleDate[]>([])
  const { data: allClasses } = useGetCurrentClasses(companyId, planId)
  const classNames = useMemo(() => (allClasses ? getAllClassNames(allClasses) : []), [allClasses])

  useEffect(
    () => {
      if (values.className && values.hireDate) {
        const currentClass = classNames.find(({ id }) => id === values.className)
        const currentClassWaitingPeriod = currentClass?.waitingPeriod
        const companyHraStartDate = isString(hraStartDate)
          ? DateTime.fromISO(hraStartDate, { zone: "local" }).toJSDate()
          : hraStartDate

        if (currentClassWaitingPeriod) {
          const hireDate = calculateHireDate(
            DateTime.fromJSDate(values.hireDate, { zone: "local" }).startOf("day").toJSDate(),
            currentClassWaitingPeriod
          )
          const firstBenefitDate = calculateFirstBenefitDate(hireDate, companyHraStartDate)
          const options: BenefitEligibleDate[] = []
          try {
            for (let i = 0; i < 6; i += 1) {
              const date = DateTime.fromJSDate(firstBenefitDate).plus({ months: i }).startOf("month")
              options.push({ name: date.toISO() ?? "", value: date.toISO() ?? "" })
            }
            setSelectBenefitEligibleDate(options)
            setBenefitEligibleDate(options[0].value)
            handleChange({ target: { name: "eligibilityDate", value: options[0].value } })
          } catch (error) {
            console.error("Error calculating benefit eligible date", error)
          }
        }
      }
    },
    // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.className, values.hireDate, classNames]
  )

  const handleBenefitEligibleDateChange = (event: SelectChangeEvent<string | null>) => {
    const selectedDate = event.target.value

    if (selectedDate !== null) {
      setBenefitEligibleDate(selectedDate)
      handleChange({ target: { name: "eligibilityDate", value: selectedDate } })
    }
  }

  const handleEligibilityDateHelperText = () => {
    if (touched.eligibilityDate) {
      return `${errors?.eligibilityDate ?? ""}`
    }

    return ""
  }

  return (
    <AddPersonStep
      title="Benefits"
      continueButtonType="submit"
      continueButtonLabel="Save"
      onClose={onClose}
      onBack={onBack}
    >
      <Grid item xs={12} sm={6}>
        <FormControl
          variant="outlined"
          sx={{ width: "100%" }}
          error={Boolean(get(touched, `employmentType`) && get(errors, `employmentType`))}
          required
        >
          <InputLabel>Employment Type</InputLabel>
          <Select
            name="employmentType"
            label="Employment type"
            placeholder="Select employment type"
            data-qa="employment-type"
            fullWidth
            value={values.employmentType ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue=""
          >
            {EMPLOYMENT_TYPES.map(employmentType => (
              <MenuItem
                key={employmentType.value}
                data-qa={"employment-type-" + employmentType.value}
                value={employmentType.value}
              >
                {employmentType.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.employmentType ? `${errors.employmentType ?? ""}` : ""}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <DatePickerField
          data-qa="hire-date"
          name="hireDate"
          label="Hire Date"
          required
          value={getOnlyDate(values.hireDate ?? null)}
          error={Boolean(get(touched, `hireDate`) && get(errors, `hireDate`))}
          helperText={touched.hireDate ? `${errors?.hireDate ?? ""}` : ""}
          fullWidth
          variant="outlined"
          type="date"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DatePickerField
          data-qa="date-of-birth"
          name="dateOfBirth"
          label="Date of Birth"
          required
          value={getOnlyDate(values.dateOfBirth ?? null)}
          error={Boolean(get(touched, `dateOfBirth`) && get(errors, `dateOfBirth`))}
          helperText={touched.dateOfBirth ? `${errors?.dateOfBirth ?? ""}` : ""}
          fullWidth
          variant="outlined"
          type="date"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <SelectField
          dataQa="class-name"
          data={classNames.map(className => ({ value: className.id, label: className.name }))}
          type="text"
          name="className"
          label="Class"
          required
          value={values.className}
          placeholder="Select class"
          onChange={handleChange}
          onBlur={handleBlur}
          sx={{ width: "100%" }}
        />
      </Grid>
      {values.className && values.hireDate && (
        <Grid item xs={12} sm={6}>
          <FormControl
            variant="outlined"
            sx={{ width: "100%" }}
            required
            error={Boolean(get(touched, `eligibilityDate`) && get(errors, `eligibilityDate`))}
          >
            <InputLabel>Benefits Eligible Date</InputLabel>
            <Select
              name="eligibility-date"
              label="Benefits Eligible Date"
              required
              placeholder="Select Benefits Eligible Date"
              data-qa="eligibility-date"
              fullWidth
              value={benefitEligibleDate}
              onChange={handleBenefitEligibleDateChange}
              onBlur={handleBlur}
            >
              {selectBenefitEligibleDate.map(benefitEligibleDateItem => (
                <MenuItem
                  key={benefitEligibleDateItem.name}
                  id="eligibilityDate"
                  data-qa={createDataQa("eligibility-date", benefitEligibleDateItem.name)}
                  value={benefitEligibleDateItem.value}
                >
                  {formatDate(benefitEligibleDateItem.name)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{handleEligibilityDateHelperText()}</FormHelperText>
          </FormControl>
        </Grid>
      )}
    </AddPersonStep>
  )
}
