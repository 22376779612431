import { DrawerForm } from "@/components/DrawerForm"
import { Footer } from "@/components/Footer"
import { PLAN_PREMIUM_LABEL } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { createDataQa } from "@/utils/dataQa"
import { formatCents } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import CloseIcon from "@mui/icons-material/Close"
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined"
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { noop } from "lodash"
import { MutableRefObject, ReactNode, SyntheticEvent, useMemo, useRef, useState } from "react"
import { BENEFITS_SUMMARY } from "../../benefitsElectionConstants"
import { useIsCompanyAutoPay } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { DoctorPreference, DrugPreference, HospitalPreference, Plan } from "../../benefitsElectionTypes"
import { getPlanBalance } from "../../benefitsElectionUtils"
import { CarrierLogo, ChoosePlanButton, ComparePlanButton, PlanDetailsChips } from "../../components/PlanComponents"
import { getPlanDetailsElementsList, PlanDetailsElement, WithCoverage } from "./PlanDetailsElements"

const dataQa = createDataQa("plan-details")

const customButtonStyle: SxProps = {
  color: "colors.darkBody",
  borderColor: "colors.darkBody",
}

interface DetailsTitleProps {
  title: string
  icon: ReactNode
  description: string
  isMobile: boolean
  isFirstItem?: boolean
  handleBackToTop?: () => void
}

const DetailsTitle = ({
  title,
  icon,
  description,
  isMobile,
  isFirstItem = false,
  handleBackToTop = noop,
}: DetailsTitleProps) => {
  const showBiggerText = isFirstItem || isMobile

  return (
    <Grid item xs={12}>
      <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
        {icon}
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6" data-qa={createDataQa(dataQa, title, "section-title")}>
              {title}
            </Typography>
            {!showBiggerText && (
              <Button
                variant="text"
                sx={{ height: "fit-content" }}
                onClick={handleBackToTop}
                data-qa={createDataQa(dataQa, "section-back-to-top-button")}
              >
                <Typography variant="body2bold" color="primary">
                  Back to top
                </Typography>
              </Button>
            )}
          </Box>
          <Typography
            variant={showBiggerText ? "body1" : "body2"}
            sx={{ color: showBiggerText ? "colors.darkBody" : "colors.lightGrayText" }}
            data-qa={createDataQa(dataQa, "section-description")}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}

const SelectButton = ({
  handlePlanSelect,
  isSelectingPlan,
}: {
  handlePlanSelect: () => void
  isSelectingPlan: boolean
}) => (
  <Grid item xs={12} my={2}>
    <ChoosePlanButton
      dataQa={createDataQa(dataQa, "select-button")}
      isSelectingPlan={isSelectingPlan}
      isSelectingThisPlan={isSelectingPlan}
      handleClick={handlePlanSelect}
    />
  </Grid>
)

export interface ActionButtonsProps {
  isMobile?: boolean
  detailsUrl?: string
  isSelectingPlan?: boolean
  isComparing: boolean
  handlePlanSelect: () => void
  handleComparePlan?: () => void
}

const ActionButtons = ({
  isMobile = false,
  detailsUrl,
  isComparing,
  isSelectingPlan,
  handlePlanSelect,
  handleComparePlan,
}: ActionButtonsProps) => {
  const showCompareButton = !!handleComparePlan
  const plansToCompare = useBenefitsElectionStore(state => state.plansToCompare)
  const disableCompareButton = plansToCompare.length >= 4

  return (
    <Grid container spacing={2} mt={6} mb={isMobile ? 10 : 0}>
      {isMobile && <SelectButton handlePlanSelect={handlePlanSelect} isSelectingPlan={!!isSelectingPlan} />}
      <Grid item xs={showCompareButton ? 6 : 12}>
        <Button
          variant="outlined"
          fullWidth
          sx={customButtonStyle}
          href={detailsUrl ?? "#"}
          disabled={!detailsUrl}
          data-qa={createDataQa(dataQa, "view-pdf-button")}
        >
          View PDF
        </Button>
      </Grid>
      {showCompareButton && (
        <Grid item xs={6}>
          <ComparePlanButton
            dataQa={dataQa}
            isComparing={isComparing}
            handleClick={handleComparePlan ?? noop}
            disabled={disableCompareButton}
          />
        </Grid>
      )}

      {!isMobile && <SelectButton handlePlanSelect={handlePlanSelect} isSelectingPlan={!!isSelectingPlan} />}
    </Grid>
  )
}

interface TabPanelProps {
  children: ReactNode
  index: number
  value: number
  title: string
}

const CustomTabPanel = ({ children, value, index, title }: TabPanelProps) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    data-qa={createDataQa(dataQa, title, "tab-panel")}
    sx={{ width: "100%" }}
  >
    {value === index && (
      <Grid item xs={12}>
        {children}
      </Grid>
    )}
  </Box>
)

const PerMonthSpan = () => (
  <Typography variant="body2" component="span" color="colors.lightGrayText">
    /mo
  </Typography>
)

export interface DetailsBannerProps {
  plan: Plan
  allowanceCents: number
}

export const DetailsBanner = ({
  plan: { logoUrl, displayName, premiumAmountCents, planType, level, carrier, onExchange, offExchange },
  allowanceCents,
}: DetailsBannerProps) => {
  const { balanceLabel, balanceValue, balanceLabelColor } = getPlanBalance(allowanceCents, premiumAmountCents)
  const { user } = useAuth()
  const companyId = user?.company?.companyId as Uuid
  const { isAutoPay } = useIsCompanyAutoPay(companyId)

  return (
    <Grid container padding={2} mb={8} spacing={4} data-qa={createDataQa(dataQa, "plan-banner")}>
      <Grid item xs={12} md={8}>
        <CarrierLogo
          carrierName={carrier.name}
          logoUrl={logoUrl}
          sx={{ height: "1.8rem" }}
          data-qa={createDataQa(dataQa, "carrier-logo")}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="h2small" data-qa={createDataQa(dataQa, "plan-name")}>
          {displayName}
        </Typography>

        <Box mt={4}>
          <PlanDetailsChips
            data-qa={dataQa}
            enrollmentType={carrier.enrollment}
            planType={planType}
            level={level}
            limitHeight={false}
            payLater={carrier.payLater}
            onExchange={onExchange}
            offExchange={offExchange}
            isAutoPay={isAutoPay}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={4} sx={{ textAlign: { xs: "start", md: "end" } }}>
        <Typography variant="overline" gutterBottom>
          {PLAN_PREMIUM_LABEL.toUpperCase()}
        </Typography>

        <Typography variant="h5" color="primary" gutterBottom data-qa={createDataQa(dataQa, "plan-cost")}>
          {formatCents(premiumAmountCents)}
          <PerMonthSpan />
        </Typography>

        <Typography variant="body2" data-qa={createDataQa(dataQa, "allowance-left")}>
          {balanceLabel}
        </Typography>
        <Typography variant="body2bold" color={balanceLabelColor}>
          {balanceValue}/mo
        </Typography>
      </Grid>
    </Grid>
  )
}

interface PlanDetailsHeaderProps {
  handleClose: () => void
}

const PlanDetailsHeader = ({ handleClose }: PlanDetailsHeaderProps) => (
  <Grid item xs={12} data-qa={createDataQa(dataQa, "mobile-header")}>
    <IconButton
      aria-label="close"
      onClick={handleClose}
      sx={{
        color: "colors.darkBody",
        padding: 0,
        position: "absolute",
        top: "1.5rem",
      }}
      data-qa={createDataQa(dataQa, "close-icon-button")}
    >
      <CloseIcon />
    </IconButton>

    <Box sx={{ display: "flex", width: "100%", justifyContent: "center", mb: 4 }}>
      <Typography variant="h3" textAlign="center">
        Plan details
      </Typography>
    </Box>
    <Divider sx={{ mx: -6 }} />
  </Grid>
)

export interface DetailsSidebarProps {
  elementRefs: MutableRefObject<any[]>
  planDetailsElements: PlanDetailsElement[]
  detailsUrl?: string
  isSelectingPlan: boolean
  isComparing: boolean
  handlePlanSelect: () => void
  handleComparePlan?: () => void
}

const DetailsSidebar = ({
  elementRefs,
  planDetailsElements,
  detailsUrl,
  isSelectingPlan,
  isComparing,
  handleComparePlan,
  handlePlanSelect,
}: DetailsSidebarProps) => (
  <Grid item md={4} lg={3}>
    <Box sx={{ position: "sticky", top: "2rem" }}>
      <nav aria-label="plan details list">
        <List data-qa={createDataQa(dataQa, "details-sidebar")}>
          {planDetailsElements.map(({ title, icon }, index) => (
            <ListItem disablePadding key={title} data-qa={createDataQa(dataQa, "details-sidebar", "list-item")}>
              <ListItemButton
                onClick={() => {
                  elementRefs.current[index]?.scrollIntoView({ behavior: "smooth" })
                }}
                data-qa={createDataQa(dataQa, "details-sidebar", "button")}
                sx={{
                  borderRadius: "0",
                  py: 3,
                  ":hover": {
                    bgcolor: "colors.lightBlueShade",
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: "2rem", color: "colors.darkBody" }}>{icon}</ListItemIcon>
                <ListItemText primary={title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </nav>
      <ActionButtons
        detailsUrl={detailsUrl}
        handlePlanSelect={handlePlanSelect}
        isSelectingPlan={isSelectingPlan}
        isComparing={isComparing}
        handleComparePlan={handleComparePlan}
      />
    </Box>
  </Grid>
)

export interface DetailsListedItemsProps {
  elementRefs: MutableRefObject<any[]>
  scrollToTop: () => void
  plan: Plan
  planDetailsElements: PlanDetailsElement[]
  allowanceCents: number
  handlePlanSelect: () => void
  isSelectingPlan: boolean
}

const DetailsListedItems = ({
  elementRefs,
  scrollToTop,
  plan,
  planDetailsElements,
  allowanceCents,
  handlePlanSelect,
  isSelectingPlan,
}: DetailsListedItemsProps) => (
  <Grid item xs={12} md={8} lg={9} mb={8}>
    <DetailsBanner plan={plan} allowanceCents={allowanceCents} />
    {planDetailsElements.map(({ title, contentIcon, component, description, isFirstItem }, index) => (
      <Box
        key={title}
        ref={ref => {
          // FUTURE: Resolve this violation of the Rules of React and remove this eslint-disable directive
          // More info: https://react.dev/reference/rules
          // eslint-disable-next-line react-compiler/react-compiler
          elementRefs.current[index] = ref
        }}
        sx={{ scrollMargin: "2rem" }}
      >
        <DetailsTitle
          title={title}
          icon={contentIcon}
          description={description}
          isMobile={false}
          isFirstItem={isFirstItem}
          handleBackToTop={scrollToTop}
        />
        <Grid item xs={12} key={title} mb={8}>
          {component}
        </Grid>
      </Box>
    ))}
  </Grid>
)

export interface DetailsTabsProps {
  value: number
  handleChange: (event: SyntheticEvent, newValue: number) => void
  plan: Plan
  planDetailsElements: PlanDetailsElement[]
  allowanceCents: number
}

const DetailsTabs = ({ value, handleChange, plan, planDetailsElements, allowanceCents }: DetailsTabsProps) => (
  <Grid item xs={12} mb={6} mt={7}>
    <DetailsBanner plan={plan} allowanceCents={allowanceCents} />
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="items tabs"
      variant="scrollable"
      scrollButtons="auto"
      data-qa={createDataQa(dataQa, "tabs")}
    >
      {planDetailsElements.map(({ title, icon }, index) => (
        <Tab
          key={title}
          icon={icon}
          label={<Typography variant="body2">{title}</Typography>}
          sx={{ color: "colors.darkBody" }}
          data-qa={createDataQa(dataQa, title, "tab")}
        />
      ))}
    </Tabs>
  </Grid>
)

export interface DetailsTabElementsProps {
  value: number
  planDetailsElements: PlanDetailsElement[]
}

const DetailsTabElements = ({ value, planDetailsElements }: DetailsTabElementsProps) => (
  <>
    {planDetailsElements.map(({ title, contentIcon, component, description }, index) => (
      <CustomTabPanel key={title} value={value} index={index} title={title}>
        <DetailsTitle title={title} icon={contentIcon} description={description} isMobile />
        {component}
      </CustomTabPanel>
    ))}
  </>
)

export interface PlanDetailsProps {
  allowanceCents: number
  detailsUrl: string | undefined
  isSelectingPlan: boolean
  plan: Plan
  planElements: PlanDetailsElement[]
  isComparing: boolean
  handleComparePlan?: () => void
  handleClose: () => void
  handlePlanSelect: () => void
}

const PlanDetailsDesktop = ({
  allowanceCents,
  detailsUrl,
  isSelectingPlan,
  plan,
  planElements,
  isComparing,
  handleComparePlan,
  handleClose,
  handlePlanSelect,
}: PlanDetailsProps) => {
  const elementRefs = useRef<any[]>([])
  const topRef = useRef<HTMLDivElement | null>(null)

  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Grid container spacing={8} px={4}>
      <Grid item xs={12} ref={topRef} sx={{ scrollMargin: "2rem" }}>
        <Button
          variant="text"
          startIcon={<KeyboardArrowLeftOutlinedIcon />}
          onClick={handleClose}
          sx={customButtonStyle}
          data-qa={createDataQa(dataQa, "back-top-button")}
        >
          Back
        </Button>
      </Grid>

      <DetailsSidebar
        elementRefs={elementRefs}
        planDetailsElements={planElements}
        detailsUrl={detailsUrl}
        handlePlanSelect={handlePlanSelect}
        isSelectingPlan={isSelectingPlan}
        isComparing={isComparing}
        handleComparePlan={handleComparePlan}
      />
      <DetailsListedItems
        elementRefs={elementRefs}
        scrollToTop={scrollToTop}
        plan={plan}
        planDetailsElements={planElements}
        allowanceCents={allowanceCents}
        handlePlanSelect={handlePlanSelect}
        isSelectingPlan={isSelectingPlan}
      />
    </Grid>
  )
}

const PlanDetailsMobile = ({
  allowanceCents,
  detailsUrl,
  isSelectingPlan,
  plan,
  planElements,
  isComparing,
  handleComparePlan,
  handleClose,
  handlePlanSelect,
}: PlanDetailsProps) => {
  const [value, setValue] = useState(0)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Grid container spacing={0} px={0}>
      <PlanDetailsHeader handleClose={handleClose} />

      <DetailsTabs
        value={value}
        handleChange={handleChange}
        plan={plan}
        planDetailsElements={planElements}
        allowanceCents={allowanceCents}
      />
      <DetailsTabElements value={value} planDetailsElements={planElements} />

      <ActionButtons
        isMobile
        detailsUrl={detailsUrl}
        handlePlanSelect={handlePlanSelect}
        isSelectingPlan={isSelectingPlan}
        isComparing={isComparing}
        handleComparePlan={handleComparePlan}
      />

      <Footer />
    </Grid>
  )
}

interface PlanDetailsDrawerProps {
  open: boolean
  plan: Plan
  isSelectingPlan: boolean
  isComparing: boolean
  uniqueDoctors: WithCoverage<DoctorPreference>[]
  uniqueHospitals: WithCoverage<HospitalPreference>[]
  uniqueDrugs: WithCoverage<DrugPreference>[]
  handleClose: () => void
  handlePlanSelect: () => void
  handleComparePlan?: () => void
}

export const PlanDetailsDrawer = ({
  open,
  plan,
  isSelectingPlan,
  isComparing,
  uniqueDoctors,
  uniqueHospitals,
  uniqueDrugs,
  handleClose,
  handlePlanSelect,
  handleComparePlan,
}: PlanDetailsDrawerProps) => {
  // FUTURE: useTheme should not be needed here, we should be able to pass an inline function to useMediaQuery
  // e.g. const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"))
  // But for some reason this causes PlanGrid.test.tsx to fail
  const materialTheme = useTheme()
  const isDesktop = useMediaQuery(materialTheme.breakpoints.up("md"))
  const DetailsComponent = isDesktop ? PlanDetailsDesktop : PlanDetailsMobile

  const allowance = useBenefitsElectionStore(state => state.allowance)
  const allowanceCents = allowance?.amountCents ?? 0

  const planElements = useMemo(
    () => getPlanDetailsElementsList(plan, allowanceCents, uniqueDoctors, uniqueHospitals, uniqueDrugs),
    [plan, allowanceCents, uniqueDoctors, uniqueHospitals, uniqueDrugs]
  )

  const { planDocuments } = plan
  const detailsUrl = planDocuments.find(doc => doc.type === BENEFITS_SUMMARY)?.url

  return (
    <DrawerForm
      open={open}
      onClose={handleClose}
      paperStyle={{
        p: { xs: 6, md: 8 },
        width: { xs: "100%", md: "90%" },
        maxWidth: "75rem",
      }}
      data-qa={createDataQa(dataQa, "drawer")}
    >
      <DetailsComponent
        allowanceCents={allowanceCents}
        detailsUrl={detailsUrl}
        isSelectingPlan={isSelectingPlan}
        plan={plan}
        planElements={planElements}
        isComparing={isComparing}
        handleComparePlan={handleComparePlan}
        handleClose={handleClose}
        handlePlanSelect={handlePlanSelect}
      />
    </DrawerForm>
  )
}
