import { useCreateHubspotDeal } from "@/features/Messaging/messagingService"
import { Uuid } from "@/utils/types"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { IconButton, Menu, MenuItem, PopoverVirtualElement, Tooltip } from "@mui/material"
import { useState } from "react"

interface EnrollmentOptionsDropDownProps {
  benefitId: Uuid
  companyId: Uuid
}

export const EnrollmentOptionsDropDown = ({ benefitId, companyId }: EnrollmentOptionsDropDownProps) => {
  const [dropdownTarget, setDropdownTarget] = useState<PopoverVirtualElement>()

  const { mutate: createHubspotDeal } = useCreateHubspotDeal()

  const closeMenu = () => {
    setDropdownTarget(undefined)
  }

  return (
    <div data-qa={`icon-more-actions-${benefitId}`}>
      <Tooltip title="Options">
        <IconButton
          data-qa={`dropdown-button-${benefitId}`}
          aria-owns={dropdownTarget && "menu-people-options"}
          aria-haspopup="true"
          size="large"
          onClick={event => {
            event.stopPropagation()
            setDropdownTarget(event.currentTarget as never)
          }}
          sx={{ width: "2.5rem", height: "2.5rem", borderRadius: "50%" }}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        data-qa="menu-people-options"
        id="menu-people-options"
        anchorEl={dropdownTarget}
        open={Boolean(dropdownTarget)}
        onClose={closeMenu}
      >
        <MenuItem
          className="tch-menu-item"
          data-qa="copy-signup-link"
          onClick={event => {
            event.stopPropagation()
            closeMenu()
            createHubspotDeal({ benefitElectionId: benefitId, companyId })
          }}
        >
          Create HubSpot Deal
        </MenuItem>
      </Menu>
    </div>
  )
}
