import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectMultiField } from "@/components/SelectMultiField"
import { STATE_OPTIONS } from "@/utils/States"
import { validateCarrierQuestions } from "@/utils/validations"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import {
  BOOLEAN,
  CARRIER_QUESTION_SCOPE_ARRAY,
  CARRIER_QUESTION_TYPE,
  DATE,
  MULTIPLE_CHOICE,
  TEXT,
} from "../tcHubConstants"
import { CarrierQuestion, emptyCarrierQuestion } from "../tcHubTypes"

export const baseDataQa = "carrier-question"

const ANSWER_CHOICES = "answerChoices"

export interface ValidationErrors {
  path: string
  errors: string[]
}

const defaultValidationErrors = [{ path: "", errors: [] }]

export interface CarrierQuestionCardComponentProps {
  carrierQuestion: CarrierQuestion
  parentQuestion?: CarrierQuestion
  setCarrierQuestion: (carrierQuestion: CarrierQuestion | ((prevValue: CarrierQuestion) => CarrierQuestion)) => void
  setParentQuestion?: (carrierQuestion: CarrierQuestion | ((prevValue: CarrierQuestion) => CarrierQuestion)) => void
  position?: number
  isCancel: boolean
  states?: string[]
  isSave: boolean
  isAChildQuestion: boolean
  setValidForm: (validForm: boolean) => void
  validForm: boolean
  setChildUpdated: (value: boolean) => void
}

const PARENT_ANSWER_FOR_VISIBILITY = "parentAnswerForVisibility"
const WEBMERGE_KEY = "webmergeKey"
const NEW_ID = "new"

export interface MultipleChoiceComponentProps {
  carrierQuestion: CarrierQuestion
  handleOptionChange: (event: any) => void
}

const MultipleChoiceComponent = ({ carrierQuestion, handleOptionChange }: MultipleChoiceComponentProps) => {
  const [inputValues, setInputValues] = useState<{ id: number; value: string }[]>(() =>
    carrierQuestion.answerChoices.map((option, index) => ({ id: index, value: option }))
  )

  const [selectedOptions, setSelectedOptions] = useState<number[]>([])

  const handleInputBlur = useCallback(() => {
    const updatedValues = inputValues.map(input => input.value)

    handleOptionChange({ target: { name: ANSWER_CHOICES, value: updatedValues } })
  }, [inputValues, handleOptionChange])

  const handleInputChange = (id: number, value: string) => {
    setInputValues(prevValues => {
      const updatedValues = prevValues.map(input => ({ ...input }))

      updatedValues[id].value = value

      return updatedValues
    })
  }

  const handleAddOption = () => {
    setInputValues(prevValues => [...prevValues, { id: prevValues.length, value: "" }])
  }

  const handleDeleteOption = (id: number) => {
    const value = inputValues.find(input => input.id === id)?.value
    const newValues = inputValues.filter(input => input.id !== id)

    setInputValues(newValues)

    if (value) {
      const updatedChildQuestions =
        carrierQuestion.childQuestions?.filter(childQuestion => childQuestion.parentAnswerForVisibility !== value) || []

      if (inputValues.length > 0) {
        handleOptionChange({ target: { name: "childQuestions", value: updatedChildQuestions } })
      }
    }

    const updatedValues = newValues.map(input => input.value)

    handleOptionChange({ target: { name: ANSWER_CHOICES, value: updatedValues } })

    setInputValues(prevValues =>
      prevValues.map((input, index) => ({
        id: index,
        value: input.value,
      }))
    )
  }

  const handleOptionSelect = (index: number) => {
    const selectedIndex = selectedOptions.indexOf(index)

    if (selectedIndex === -1) {
      setSelectedOptions([...selectedOptions, index])
    } else {
      const updatedOptions = [...selectedOptions]

      updatedOptions.splice(selectedIndex, 1)
      setSelectedOptions(updatedOptions)
    }
  }

  const isOptionSelected = (index: number) => selectedOptions.includes(index)

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {inputValues.map(input => (
          <Box key={`${carrierQuestion.question}-${input.id}`} sx={{ display: "flex", width: "100%", pb: 2 }}>
            <Radio
              sx={{ marginRight: 1 }}
              checked={isOptionSelected(input.id)}
              onClick={() => handleOptionSelect(input.id)}
            />
            <TextField
              fullWidth
              placeholder={`Option ${input.id + 1}`}
              value={input.value}
              type="text"
              variant="standard"
              onBlur={handleInputBlur}
              onChange={e => handleInputChange(input.id, e.target.value)}
            />
            <IconButton onClick={() => handleDeleteOption(input.id)}>
              <DeleteOutlinedIcon />
            </IconButton>
          </Box>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" onClick={handleAddOption} startIcon={<AddCircleOutlineIcon />}>
          Add new answer
        </Button>
      </Grid>
    </Grid>
  )
}

const renderQuestionChoicesComponent = ({
  carrierQuestion,
  handleChange,
  setCarrierQuestion,
}: RenderQuestionChoicesComponentProps) => {
  if (carrierQuestion.fieldType === MULTIPLE_CHOICE) {
    return <MultipleChoiceComponent carrierQuestion={carrierQuestion} handleOptionChange={handleChange} />
  }
}

export const CarrierQuestionCardComponent = ({
  carrierQuestion,
  parentQuestion,
  setCarrierQuestion,
  setParentQuestion,
  position,
  isCancel,
  states,
  isSave,
  isAChildQuestion,
  setValidForm,
  validForm,
  setChildUpdated,
}: CarrierQuestionCardComponentProps) => {
  const [carrierQuestionModified, setCarrierQuestionModified] = useState<CarrierQuestion>({
    ...carrierQuestion,
    parentQuestionId: parentQuestion?.id,
  })

  const [touchedValues, setTouchedValues] = useState<string[]>([])
  const [validationErrors, setValidationErrors] = useState<ValidationErrors[]>(defaultValidationErrors)
  const [childValidationErrors, setChildValidationErrors] = useState<ValidationErrors[]>(defaultValidationErrors)
  const initialState = states ? [...STATE_OPTIONS.filter(state => states.includes(state.value))] : [...STATE_OPTIONS]
  const [statesOptions, setStatesOptions] = useState(initialState)
  const [isCarrierQuestionModifiedDirty, setIsCarrierQuestionModifiedDirty] = useState(false)
  const [isWebmergeKeyDuplicated, setIsWebmergeKeyDuplicated] = useState(false)

  const validateWebMergeKeyForParent = useCallback(
    (parentQuestionModified: CarrierQuestion, childWebMergeKey?: string) => {
      let webMergeKeyToValidate: string | undefined

      if (childWebMergeKey) {
        webMergeKeyToValidate = childWebMergeKey
      } else if (parentQuestionModified?.childQuestions && parentQuestionModified.childQuestions.length > 0) {
        webMergeKeyToValidate = parentQuestionModified.childQuestions[0].webmergeKey
      }

      if (
        parentQuestionModified.childQuestions?.length === 1 &&
        parentQuestionModified.webmergeKey?.length &&
        webMergeKeyToValidate?.length &&
        webMergeKeyToValidate === parentQuestionModified.webmergeKey
      ) {
        setIsWebmergeKeyDuplicated(true)
        setValidForm(false)
      } else {
        const siblingsKeys = parentQuestionModified?.childQuestions
          ? parentQuestionModified.childQuestions.filter((_, index) => index !== position).map(q => q.webmergeKey)
          : []

        const allWebMergeKeys = [...siblingsKeys, parentQuestionModified.webmergeKey].filter(k => k !== "")
        const uniqueWebMergeKeys = new Set(allWebMergeKeys.filter(k => k !== ""))
        const hasDuplicates = uniqueWebMergeKeys.size !== allWebMergeKeys.length

        if (childWebMergeKey && !allWebMergeKeys.includes(childWebMergeKey)) {
          setIsWebmergeKeyDuplicated(false)
          setValidationErrors([])
        }

        if (allWebMergeKeys && childWebMergeKey && allWebMergeKeys.includes(childWebMergeKey) && hasDuplicates) {
          setIsWebmergeKeyDuplicated(true)
          setValidForm(false)
        } else {
          setIsWebmergeKeyDuplicated(false)
          setValidationErrors([])
        }
      }
    },
    [position, setValidForm]
  )

  const checkForDuplicateWebmergeKeys = (question: CarrierQuestion, currentKey?: string): boolean => {
    const currentChildKeys = question?.childQuestions
      ? question.childQuestions.map(q => q.webmergeKey).filter((key): key is string => !!key)
      : []

    const allKeys = [...currentChildKeys]

    if (currentKey) allKeys.push(currentKey)
    const keySet = new Set<string>()

    for (const key of allKeys) {
      if (keySet.has(key)) {
        return true
      }
      keySet.add(key)
    }

    return false
  }

  const validateCarrierQuestion = useCallback(
    (isChild: boolean, validateChilQuestions: boolean, question: CarrierQuestion, setErrors: boolean) => {
      validateCarrierQuestions(isChild, question.fieldType)
        .validate(question, { abortEarly: false })
        .then(() => {
          if (isChild) {
            setChildValidationErrors(defaultValidationErrors)
          } else {
            setValidationErrors(defaultValidationErrors)
          }

          if (validateChilQuestions && question.childQuestions) {
            for (const childQuestion of question.childQuestions) {
              validateCarrierQuestion(true, false, childQuestion, setErrors)
            }
          }
          setValidForm(true)
          setValidationErrors([])

          if (!question.parentQuestionId) {
            const isKeyDuplicated = checkForDuplicateWebmergeKeys(question, question.webmergeKey)

            if (isKeyDuplicated) {
              setValidForm(false)
              setIsWebmergeKeyDuplicated(true)
            } else {
              setValidForm(true)
              setIsWebmergeKeyDuplicated(false)
              setValidationErrors([])
            }
          } else {
            if (parentQuestion) {
              validateWebMergeKeyForParent(parentQuestion, question.webmergeKey)
            }
          }
        })
        .catch(error => {
          const errors = error.inner.map((e: ValidationErrors) => ({ path: e.path, errors: e.errors }))

          if (setErrors) {
            if (isChild) {
              setChildValidationErrors(errors)
            } else {
              setValidationErrors(errors)
            }
          }
          setValidForm(false)
        })
    },
    [setValidForm, parentQuestion, validateWebMergeKeyForParent]
  )

  useEffect(() => {
    if (states) {
      setStatesOptions([...STATE_OPTIONS.filter(state => states.includes(state.value))])
    }
  }, [states])

  useEffect(() => {
    if (isCancel) {
      setCarrierQuestionModified(carrierQuestion)
    }
  }, [isCancel, carrierQuestion, setCarrierQuestionModified])

  useEffect(() => {
    if (isSave) {
      setCarrierQuestion(carrierQuestionModified)
      setChildUpdated(true)
    }
  }, [carrierQuestionModified, isSave, setCarrierQuestion, setChildUpdated])

  // FUTURE: evaluate if this unused function should be removed
  /*   const validateParentQuestion = useCallback(
    async (questionModified: CarrierQuestion) => {
      await validateCarrierQuestion(false, true, questionModified, false)
      validateWebMergeKeyForParent(questionModified)
    },
    [validateCarrierQuestion, validateWebMergeKeyForParent]
  ) */

  useEffect(() => {
    if (isCarrierQuestionModifiedDirty && setParentQuestion && position !== undefined) {
      setParentQuestion(previous => {
        const updatedChildQuestions = previous.childQuestions ? [...previous.childQuestions] : []

        updatedChildQuestions[position] = carrierQuestionModified
        const questionModified = { ...previous, childQuestions: updatedChildQuestions }

        return questionModified
      })
      setIsCarrierQuestionModifiedDirty(false)
    }
  }, [
    isCarrierQuestionModifiedDirty,
    carrierQuestionModified,
    setParentQuestion,
    position,
    validateCarrierQuestion,
    touchedValues,
  ])

  const handleAddChild = () => {
    setCarrierQuestionModified(previous => {
      const newChildQuestion = {
        ...emptyCarrierQuestion,
        carrierId: carrierQuestion.carrierId,
        scope: carrierQuestionModified.scope,
      }

      const updatedChildQuestions = previous.childQuestions
        ? [...previous.childQuestions, newChildQuestion]
        : [newChildQuestion]

      const updatedCarrierQuestionModified = { ...previous, childQuestions: updatedChildQuestions }

      setCarrierQuestion(updatedCarrierQuestionModified)
      setIsCarrierQuestionModifiedDirty(true)
      validateCarrierQuestion(false, true, updatedCarrierQuestionModified, true)

      return updatedCarrierQuestionModified
    })
  }

  const handleDeleteChild = () => {
    if (position !== undefined && setParentQuestion) {
      setParentQuestion((previous: CarrierQuestion) => {
        const updatedChildQuestions = previous.childQuestions ? [...previous.childQuestions] : []

        updatedChildQuestions.splice(position, 1)
        const updatedCarrierQuestionModified = { ...previous, childQuestions: updatedChildQuestions }

        validateCarrierQuestion(false, true, updatedCarrierQuestionModified, true)

        return updatedCarrierQuestionModified
      })
    }
  }

  const getChildQuestionsWithUpdatedScope = (parentQuestionToValidate: CarrierQuestion) => {
    const parentScope = parentQuestionToValidate.scope
    const childQuestions = parentQuestionToValidate.childQuestions ?? []

    if (!parentScope || parentScope.length === 0) {
      return childQuestions.map(child => ({ ...child, states: [] }))
    } else {
      const updatedChildQuestions = childQuestions.map(child => ({ ...child, scope: parentScope }))

      return updatedChildQuestions
    }
  }

  const getChildQuestionsWithUpdatedStates = (parentQuestionToValidate: CarrierQuestion) => {
    const parentStates = parentQuestionToValidate.states
    const childQuestions = parentQuestionToValidate.childQuestions ?? []

    if (!parentStates || parentStates.length === 0) {
      return childQuestions.map(child => ({ ...child, states: [] }))
    } else {
      const updatedChildQuestions = childQuestions.map(child => {
        const updatedChildStates = child.states.filter(state => parentStates.includes(state))

        return { ...child, states: updatedChildStates }
      })

      return updatedChildQuestions
    }
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target
    let updatedCarrierQuestionModified: CarrierQuestion

    if (name.includes("fieldType")) {
      // FUTURE: Resolve this violation of the Rules of React and remove this eslint-disable directive
      // More info: https://react.dev/reference/rules
      // eslint-disable-next-line react-compiler/react-compiler
      carrierQuestionModified.fieldType = value
      let childQuestions = carrierQuestionModified.childQuestions?.map(question => ({
        ...question,
        parentAnswerForVisibility: undefined,
      }))

      if (value === DATE || value === TEXT) {
        childQuestions = []
      }

      updatedCarrierQuestionModified = {
        ...carrierQuestionModified,
        [name]: value,
        answerChoices: [],
        childQuestions,
      }
    } else {
      updatedCarrierQuestionModified = { ...carrierQuestionModified, [name]: value }
    }

    if (name.includes("states")) {
      carrierQuestionModified.states = value
      updatedCarrierQuestionModified = {
        ...carrierQuestionModified,
        [name]: value,
        childQuestions: getChildQuestionsWithUpdatedStates(carrierQuestionModified),
      }
    }

    if (name.includes("scope")) {
      carrierQuestionModified.scope = value
      updatedCarrierQuestionModified = {
        ...carrierQuestionModified,
        [name]: value,
        childQuestions: getChildQuestionsWithUpdatedScope(carrierQuestionModified),
      }
    }

    const modifyChilParentAnswerVisibility = (carrierQuestionWithNewAnswers: CarrierQuestion) => {
      const previousAnswerChoices = carrierQuestionModified.answerChoices
      const nextAnswerChoices = carrierQuestionWithNewAnswers.answerChoices
      const removedValues = previousAnswerChoices.filter(choice => !nextAnswerChoices.includes(choice))
      const newValue = nextAnswerChoices.find(choice => !previousAnswerChoices.includes(choice))

      const childQuestionsToModify = carrierQuestionModified.childQuestions?.filter(
        childQuestion =>
          childQuestion.parentAnswerForVisibility && removedValues.includes(childQuestion.parentAnswerForVisibility)
      )

      childQuestionsToModify?.forEach(childQuestionToModify => {
        if (newValue) {
          childQuestionToModify.parentAnswerForVisibility = newValue
        }
      })
    }

    modifyChilParentAnswerVisibility(updatedCarrierQuestionModified)

    if (name.includes("answerChoices")) {
      carrierQuestionModified.answerChoices = value
    }

    if (name.includes(WEBMERGE_KEY)) {
    }

    if (isAChildQuestion) {
      updatedCarrierQuestionModified.parentQuestionId = parentQuestion && parentQuestion.id ? parentQuestion.id : NEW_ID
    }

    carrierQuestionModified.childQuestions = updatedCarrierQuestionModified.childQuestions
    carrierQuestionModified.childQuestions = getChildQuestionsWithUpdatedStates(carrierQuestionModified)
    if (updatedCarrierQuestionModified) setCarrierQuestionModified(() => updatedCarrierQuestionModified)

    if (setParentQuestion && position !== undefined) {
      setParentQuestion((previous: CarrierQuestion) => {
        const updatedChildQuestions = previous.childQuestions ? [...previous.childQuestions] : []

        updatedChildQuestions[position] = updatedCarrierQuestionModified

        return { ...previous, childQuestions: updatedChildQuestions }
      })
    }

    validateCarrierQuestion(isAChildQuestion, true, updatedCarrierQuestionModified, true)

    setCarrierQuestion(updatedCarrierQuestionModified)
    setIsCarrierQuestionModifiedDirty(true)
  }

  const parentAnswerForVisibilityOptions = () => {
    if (parentQuestion) {
      if (parentQuestion.fieldType === BOOLEAN) {
        return ["Yes", "No"]
      } else {
        return parentQuestion.answerChoices
      }
    }

    return []
  }

  const handleBlur = (input: string) => {
    setTouchedValues(t => [...new Set([...t, input])])
  }

  const isValidOptionToShowChildQuestions = (fieldType: string) => fieldType !== TEXT && fieldType !== DATE
  const isValidOptionToDisplayAnswers = (fieldType: string) => fieldType === MULTIPLE_CHOICE

  const handleQuestionTypeChange = (field: string, value: string) => {
    handleChange({ target: { name: field, value } })
    if (!isValidOptionToShowChildQuestions(value)) {
      setCarrierQuestionModified(prevState => ({
        ...prevState,
        childQuestions: [],
      }))
      setIsCarrierQuestionModifiedDirty(true)
    }
  }

  const setChildQuestion = (index: number, childQuestion: CarrierQuestion) => {
    setCarrierQuestionModified(prev => {
      const updatedChildQuestions = [...(prev.childQuestions ?? [])]

      updatedChildQuestions[index] = childQuestion

      return { ...prev, childQuestions: updatedChildQuestions }
    })
  }

  const getErrorArray = () => (isAChildQuestion ? childValidationErrors : validationErrors).filter(e => e.path !== "")
  const answerErrors = getErrorArray().filter(e => e.path === "answerChoices")[0]?.errors

  if (isWebmergeKeyDuplicated) {
    setValidForm(false)
  }

  return (
    <Grid container p={2}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={4} sx={{ width: "100%", justifyContent: "space-between" }}>
          <TextField
            fullWidth
            value={carrierQuestionModified.question}
            name="question"
            type="text"
            variant="standard"
            multiline
            InputProps={{
              disableUnderline: true,
              readOnly: true,
            }}
          >
            <Typography variant="h2small" data-qa={`${baseDataQa}-${carrierQuestion.question}-title`} mt={2}>
              {carrierQuestionModified.question}
            </Typography>
          </TextField>

          {isAChildQuestion && (
            <Stack direction="row">
              <IconButton onClick={handleDeleteChild}>
                <DeleteOutlinedIcon data-qa={`${carrierQuestion.question}-delete-child-question-button`} />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Grid>
      <Collapse in sx={{ width: "100%" }}>
        <Grid container sx={{ flexDirection: "column" }} spacing={8} mt={2}>
          {isAChildQuestion && (
            <Grid item>
              <FormControl fullWidth required>
                <InputLabel>Show if</InputLabel>
                <Select
                  data-qa="parent-answer-for-visibility-select"
                  type="text"
                  name={PARENT_ANSWER_FOR_VISIBILITY}
                  sx={{ my: 0 }}
                  label="Show if"
                  value={carrierQuestionModified.parentAnswerForVisibility ?? ""}
                  placeholder="Please select an option"
                  onChange={handleChange}
                  onBlur={() => handleBlur(PARENT_ANSWER_FOR_VISIBILITY)}
                  fullWidth
                  required
                  error={
                    touchedValues.includes(PARENT_ANSWER_FOR_VISIBILITY)
                      ? childValidationErrors.filter(e => e.path === PARENT_ANSWER_FOR_VISIBILITY)[0]?.errors?.length >
                        0
                      : undefined
                  }
                >
                  {parentAnswerForVisibilityOptions().map(choice => (
                    <MenuItem
                      key={`${carrierQuestionModified.question}-${choice}`}
                      id={PARENT_ANSWER_FOR_VISIBILITY}
                      defaultValue={parentAnswerForVisibilityOptions()[0]}
                      value={choice}
                    >
                      {choice}
                    </MenuItem>
                  ))}
                </Select>
                {touchedValues.includes(PARENT_ANSWER_FOR_VISIBILITY)
                  ? childValidationErrors.filter(e => e.path === PARENT_ANSWER_FOR_VISIBILITY)[0]?.errors?.length && (
                      <FormHelperText error>
                        {childValidationErrors.filter(e => e.path === PARENT_ANSWER_FOR_VISIBILITY)[0]?.errors?.[0]}
                      </FormHelperText>
                    )
                  : undefined}
              </FormControl>
            </Grid>
          )}
          <Grid item>
            <TextField
              fullWidth
              required
              name="question"
              label="Question title"
              type="text"
              onChange={event => {
                handleChange(event)
                handleBlur("question")
              }}
              onBlur={() => handleBlur("question")}
              value={carrierQuestionModified.question}
              multiline
              helperText={
                touchedValues.includes("question")
                  ? getErrorArray().filter(e => e.path === "question")[0]?.errors?.[0]
                  : undefined
              }
              error={
                touchedValues.includes("question")
                  ? getErrorArray().filter(e => e.path === "question")[0]?.errors?.length > 0
                  : undefined
              }
            >
              <TextareaAutosize data-qa={`${carrierQuestion.question}-question`} />
            </TextField>
            {!getErrorArray().filter(e => e.path === "question")[0]?.errors?.[0] && (
              <FormHelperText>{carrierQuestionModified.question.length}/1200 characters</FormHelperText>
            )}
          </Grid>
          <Grid item>
            <RadioGroupCard
              name="fieldType"
              label="Question type"
              value={carrierQuestionModified.fieldType}
              formName="fieldType"
              elements={CARRIER_QUESTION_TYPE}
              maxCardsPerRow={2}
              required
              handleChange={(field, value) => {
                handleQuestionTypeChange(field, value)
                handleBlur("fieldType")
              }}
            />
          </Grid>
          {isValidOptionToDisplayAnswers(carrierQuestionModified.fieldType) && (
            <Grid item container>
              <Grid item>
                <Typography variant="body1bold" data-qa={`${baseDataQa}-title`}>
                  Answers
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {renderQuestionChoicesComponent({
                  carrierQuestion: carrierQuestionModified,
                  handleChange,
                  setCarrierQuestion: setCarrierQuestionModified,
                })}

                {answerErrors?.length > 0 && <FormHelperText error>{answerErrors?.[0]}</FormHelperText>}
              </Grid>
            </Grid>
          )}

          <Grid item>
            <SelectMultiField
              selectedValues={carrierQuestionModified.states ? carrierQuestionModified.states : []}
              fieldLabel="Question applies to the following states"
              data={statesOptions}
              name="states"
              sx={{ my: 0 }}
              placeholder="Please select at least one state"
              dataQa="states-multi-select"
              onChange={handleChange}
              required
              onBlur={() => handleBlur("states")}
              helperText={
                touchedValues.includes("states")
                  ? getErrorArray().filter(e => e.path === "states")[0]?.errors?.[0]
                  : undefined
              }
              error={
                touchedValues.includes("states")
                  ? getErrorArray().filter(e => e.path === "states")[0]?.errors?.length > 0
                  : undefined
              }
            />
          </Grid>
          {!isAChildQuestion && (
            <Grid item>
              <FormControl fullWidth required>
                <InputLabel>Who does this question apply to?</InputLabel>
                <Select
                  data-qa="scope-select"
                  type="text"
                  name="scope"
                  sx={{ my: 0 }}
                  required
                  label="Who does this question apply to? *"
                  value={carrierQuestionModified.scope}
                  placeholder="Please select a scope"
                  onChange={handleChange}
                  fullWidth
                  error={getErrorArray().filter(e => e.path === "scope")[0]?.errors?.length > 0}
                >
                  {CARRIER_QUESTION_SCOPE_ARRAY.map(scope => (
                    <MenuItem key={`${carrierQuestionModified.question}-${scope.value}`} id="scope" value={scope.value}>
                      {scope.label}
                    </MenuItem>
                  ))}
                </Select>
                {getErrorArray().filter(e => e.path === "scope")[0]?.errors?.length > 0 && (
                  <FormHelperText>{getErrorArray().filter(e => e.path === "scope")[0]?.errors?.[0]}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

          <Grid item>
            <TextField
              fullWidth
              name="webmergeKey"
              label="Webmerge Key"
              type="text"
              onChange={handleChange}
              value={carrierQuestionModified.webmergeKey}
              multiline
              helperText={isWebmergeKeyDuplicated ? "Cannot use duplicated webmerge key" : undefined}
              error={isWebmergeKeyDuplicated}
            >
              <TextareaAutosize data-qa={`${carrierQuestion.question}-webmergeKey`} />
            </TextField>
          </Grid>

          <Grid item xs={12} mt={4}>
            {!isAChildQuestion && isValidOptionToShowChildQuestions(carrierQuestionModified.fieldType) && (
              <Button
                variant="outlined"
                data-qa="add-child-question-button"
                onClick={handleAddChild}
                startIcon={<AddCircleOutlineIcon />}
              >
                Add a child question
              </Button>
            )}
          </Grid>
          {carrierQuestionModified.childQuestions && carrierQuestionModified.childQuestions.length > 0 && (
            <Grid item>
              {carrierQuestionModified.childQuestions.map((childQuestion, index) => (
                <Card key={childQuestion.id} variant="outlined" sx={{ px: 4, py: 2, mb: 5 }}>
                  <CarrierQuestionCardComponent
                    carrierQuestion={childQuestion}
                    parentQuestion={carrierQuestionModified}
                    setCarrierQuestion={() => setChildQuestion(index, childQuestion)}
                    position={index}
                    setParentQuestion={setCarrierQuestionModified}
                    isCancel={isCancel}
                    isSave={false}
                    states={carrierQuestionModified.states}
                    isAChildQuestion
                    setValidForm={setValidForm}
                    validForm={validForm}
                    setChildUpdated={setChildUpdated}
                  />
                </Card>
              ))}
            </Grid>
          )}
        </Grid>
      </Collapse>
    </Grid>
  )
}

export interface RenderQuestionChoicesComponentProps {
  carrierQuestion: CarrierQuestion
  handleChange: (event: any) => void
  setCarrierQuestion: (carrierQuestion: CarrierQuestion | ((prevValue: CarrierQuestion) => CarrierQuestion)) => void
}
