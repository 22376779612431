import { AsyncButton } from "@/components/Buttons"
import { EXTRA_STEP, SELF_ENROLL } from "@/constants"
import { takeCommandPrimary } from "@/theme/palette"
import { createDataQa, DataQa, WithDataQa } from "@/utils/dataQa"
import { toTitleCase } from "@/utils/formatting"
import { CheckBox, CheckBoxOutlineBlankOutlined } from "@mui/icons-material"
import { Box, Button, Chip, Grid, SxProps, Typography } from "@mui/material"
import {
  BRONZE,
  EXTRA_STEP_CHIP_LABEL,
  PAY_LATER,
  PAY_LATER_COLOR,
  PLAN_ENROLLMENT_TYPES_COLORS,
  TAX_ADVANTAGED,
  TAX_ADVANTAGED_COLOR,
} from "../benefitsElectionConstants"
import { EnrollmentType, PlanLevel, PlanType } from "../benefitsElectionTypes"
import { getColorByMetal } from "../benefitsElectionUtils"

interface PlanLevelChipProps {
  dataQa: string
  level: string
}

export const PlanLevelChip = ({ dataQa, level }: PlanLevelChipProps) => {
  const levelColor = getColorByMetal(level)

  return (
    <Chip
      data-qa={createDataQa(dataQa, "level-chip")}
      sx={{
        color: level.toUpperCase() === BRONZE ? "white" : "black",
        backgroundColor: levelColor,
      }}
      label={<Typography variant="body2">{toTitleCase(level)}</Typography>}
    />
  )
}

interface EnrollmentTypeChipProps {
  dataQa: string
  enrollmentType: EnrollmentType
}

export const EnrollmentTypeChip = ({ dataQa, enrollmentType }: EnrollmentTypeChipProps) => (
  <Chip
    data-qa={createDataQa(dataQa, "enrollment-chip")}
    sx={{
      backgroundColor: PLAN_ENROLLMENT_TYPES_COLORS[enrollmentType],
      color: enrollmentType === SELF_ENROLL ? "white" : "black",
    }}
    label={
      <Typography variant="body2">
        {enrollmentType === EXTRA_STEP ? EXTRA_STEP_CHIP_LABEL : toTitleCase(enrollmentType)}
      </Typography>
    }
  />
)

interface PlanTypeChipProps {
  dataQa: string
  planType: string
}

export const PlanTypeChip = ({ dataQa, planType }: PlanTypeChipProps) => (
  <Chip
    data-qa={createDataQa(dataQa, "plan-type-chip")}
    sx={{ backgroundColor: "colors.pineGreen", color: "white" }}
    label={<Typography variant="body2">{planType}</Typography>}
  />
)

interface PlanInformationChipProps {
  backgroundColor: string
  dataQa: string
  label: string
}

export const PlanInformationChip = ({ dataQa, label, backgroundColor }: PlanInformationChipProps) => (
  <Chip
    data-qa={createDataQa(dataQa, "plan-info-chip")}
    sx={{ backgroundColor }}
    label={<Typography variant="body2">{toTitleCase(label)}</Typography>}
  />
)

export interface PlanDetailsChipsProps extends WithDataQa {
  enrollmentType?: EnrollmentType
  isAutoPay: boolean
  level?: PlanLevel
  limitHeight?: boolean
  offExchange?: boolean
  onExchange?: boolean
  payLater?: boolean
  planType: PlanType
}

export const PlanDetailsChips = ({
  "data-qa": dataQa,
  enrollmentType,
  isAutoPay,
  level,
  limitHeight = true,
  offExchange = false,
  onExchange = false,
  payLater = false,
  planType,
}: PlanDetailsChipsProps) => {
  const isTaxAdvantaged = !onExchange || offExchange

  return (
    <Grid
      item
      sx={{
        height: limitHeight ? "6rem" : "auto",
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        alignContent: "flex-start",
      }}
    >
      {level && <PlanLevelChip dataQa={dataQa} level={level} />}
      {!isAutoPay && payLater && (
        <PlanInformationChip dataQa={dataQa} label={PAY_LATER} backgroundColor={PAY_LATER_COLOR} />
      )}
      {isTaxAdvantaged && enrollmentType !== "SELF_ENROLL" && (
        <PlanInformationChip dataQa={dataQa} label={TAX_ADVANTAGED} backgroundColor={TAX_ADVANTAGED_COLOR} />
      )}
      {planType && <PlanTypeChip dataQa={dataQa} planType={planType} />}
      {enrollmentType && <EnrollmentTypeChip dataQa={dataQa} enrollmentType={enrollmentType} />}
    </Grid>
  )
}

export const PerMonthSpan = () => (
  <Typography variant="body2" component="span">
    /mo
  </Typography>
)

const customButtonStyle: SxProps = {
  color: "colors.darkBody",
  borderColor: "colors.darkBody",
  paddingX: "1rem !important",
  whiteSpace: "nowrap",
  overflow: "hidden",
}

const checkBoxIconStyle: SxProps = {
  height: "1.125rem",
  width: "1.125rem",
}

interface ComparePlanButtonProps {
  dataQa: string
  isComparing: boolean
  disabled: boolean
  handleClick: () => void
}

export const ComparePlanButton = ({ isComparing, dataQa, disabled, handleClick }: ComparePlanButtonProps) => (
  <Button
    data-qa={createDataQa(dataQa, "compare-button")}
    variant="outlined"
    sx={{ ...customButtonStyle, backgroundColor: isComparing ? "colors.lightBlueShade" : "" }}
    fullWidth
    disabled={isComparing ? false : disabled}
    startIcon={
      isComparing ? (
        <CheckBox sx={{ ...checkBoxIconStyle, color: takeCommandPrimary.main }} />
      ) : (
        <CheckBoxOutlineBlankOutlined sx={checkBoxIconStyle} />
      )
    }
    onClick={handleClick}
  >
    Compare
  </Button>
)

interface ChoosePlanButtonProps {
  dataQa: string
  isSelectingPlan: boolean
  isSelectingThisPlan: boolean
  handleClick: () => void
}

export const ChoosePlanButton = ({
  dataQa,
  isSelectingPlan,
  isSelectingThisPlan,
  handleClick,
}: ChoosePlanButtonProps) => (
  <AsyncButton
    variant="contained"
    fullWidth
    data-qa={dataQa}
    isLoading={isSelectingPlan && isSelectingThisPlan}
    onClick={handleClick}
    disabled={isSelectingPlan}
    sx={{ whiteSpace: "nowrap" }}
  >
    Choose plan
  </AsyncButton>
)

interface CarrierLogoProps {
  logoUrl: string
  carrierName: string
  "data-qa": DataQa
  sx?: SxProps
}

export const CarrierLogo = ({ logoUrl, carrierName, "data-qa": dataQa, sx = {} }: CarrierLogoProps) => (
  <Box
    component="img"
    src={logoUrl}
    alt={`${carrierName} logo`}
    sx={{ objectFit: "contain", objectPosition: "0 0", ...sx }}
    data-qa={dataQa}
  />
)
