import { DrawerForm } from "@/components/DrawerForm"
import { LoadingContentArea } from "@/components/LoadingContentArea"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { useGetCompany } from "@/features/CreateCompany/components/Steps/Setup/setupService"
import { Edit as EditIcon, KeyboardArrowLeft } from "@mui/icons-material"
import { Box, Button, Grid, Typography } from "@mui/material"
import { Dispatch, SetStateAction, useState } from "react"
import { useGetPersonById } from "../peopleService"
import { PeopleDrawerProfileDetails } from "./PeopleDrawerProfileDetails"
import { PeopleDrawerTerminate } from "./PeopleDrawerTerminate"

export interface PeopleProfileDrawerProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  personProfileId: string
}
export const PeopleProfileDrawer = ({ open, setOpen, personProfileId }: PeopleProfileDrawerProps) => {
  const { user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId || ""
  const { data: person, isLoading: isLoadingPerson } = useGetPersonById(companyId, personProfileId)
  const { data: company, isLoading: isCompanyLoading } = useGetCompany(companyId)
  const [isTerminateOpen, setIsTerminateOpen] = useState<boolean>(false)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const isEmployeeInformationLoading = isLoadingPerson || isCompanyLoading

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  const handleBackClick = () => {
    if (isTerminateOpen) {
      setIsTerminateOpen(false)
    } else {
      setOpen(false)
    }
  }

  return (
    <DrawerForm
      open={open}
      onClose={toggleDrawer(false)}
      paperStyle={{ padding: "2rem", width: "93%", maxWidth: "37.5rem" }}
      data-qa="drawer-profile"
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Button startIcon={<KeyboardArrowLeft />} color="inherit" onClick={handleBackClick} sx={{ width: "6rem" }}>
          Back
        </Button>
        {!isTerminateOpen && (
          <Button
            startIcon={<EditIcon />}
            variant="outlined"
            disabled={isEmployeeInformationLoading}
            onClick={() => {
              setIsEditMode(!isEditMode)
              // FUTURE: SEG-7515 will extend from here
            }}
            data-qa="edit-profile-button"
            sx={{ borderRadius: "20px" }}
          >
            Edit
          </Button>
        )}
      </Box>
      <Grid container>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Typography variant="h2tiempos" gutterBottom>
            {isTerminateOpen ? "Terminate Employee" : "Profile"}
          </Typography>
        </Grid>
        {isEmployeeInformationLoading && <LoadingContentArea data-qa="loading-employee-informtation" />}
        {!isEmployeeInformationLoading && isTerminateOpen && <PeopleDrawerTerminate />}
        {!isEmployeeInformationLoading && !isTerminateOpen && (
          <PeopleDrawerProfileDetails setIsTerminateOpen={setIsTerminateOpen} person={person!} company={company!} />
        )}
      </Grid>
    </DrawerForm>
  )
}
