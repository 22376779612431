import { ClampedTextField } from "@/components/TextFields"
import { ZipCodeField } from "@/features/People/PeopleSettings/ZipCodeField"
import { STATE_OPTIONS } from "@/utils/States"
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import FormHelperText from "@mui/material/FormHelperText"
import { get } from "lodash"
import { AddPeopleStepProps } from "../addPersonValidations"
import { STEPS } from "../types"
import { AddPersonStep } from "./AddPersonStep"

export const AddressProfileFormStep = ({
  onNextStep,
  onClose,
  onBack,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}: AddPeopleStepProps) => (
  <AddPersonStep
    title="Address"
    onClose={onClose}
    onContinue={() => onNextStep(STEPS.EMPLOYMENT_PERSON_FORM)}
    onBack={onBack}
  >
    <Grid item xs={12} sm={6}>
      <ClampedTextField
        data-qa="street1"
        name="homeAddress.street1"
        label="Address"
        value={values.homeAddress?.street1}
        fullWidth
        required
        variant="outlined"
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(get(touched, `homeAddress.street1`) && get(errors, `homeAddress.street1`))}
        helperText={get(touched, `homeAddress.street1`) && get(errors, `homeAddress.street1`)}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <ClampedTextField
        data-qa="street2"
        name="homeAddress.street2"
        label="Apt, suite, etc."
        value={values.homeAddress?.street2}
        fullWidth
        variant="outlined"
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(get(touched, `homeAddress.street2`) && get(errors, `homeAddress.street2`))}
        helperText={get(touched, `homeAddress.street2`) && get(errors, `homeAddress.street2`)}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <ClampedTextField
        data-qa="city"
        name="homeAddress.city"
        label="City"
        required
        value={values.homeAddress?.city}
        fullWidth
        variant="outlined"
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(get(touched, `homeAddress.city`) && get(errors, `homeAddress.city`))}
        helperText={get(touched, `homeAddress.city`) && get(errors, `homeAddress.city`)}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <ZipCodeField
        data-qa="zip"
        name="homeAddress.zip"
        label="ZIP Code"
        required
        value={values.homeAddress?.zip}
        fullWidth
        variant="outlined"
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(get(touched, `homeAddress.zip`) && get(errors, `homeAddress.zip`))}
        helperText={get(touched, `homeAddress.zip`) && get(errors, `homeAddress.zip`)}
        inputProps={{ "data-qa": "zip-code-input" }}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <ClampedTextField
        data-qa="county"
        name="homeAddress.county"
        label="County"
        required
        value={values.homeAddress?.county}
        fullWidth
        variant="outlined"
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(get(touched, `homeAddress.county`) && get(errors, `homeAddress.county`))}
        helperText={get(touched, `homeAddress.county`) && get(errors, `homeAddress.county`)}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <FormControl
        variant="outlined"
        sx={{ width: "100%" }}
        required
        error={Boolean(get(touched, `homeAddress.state`) && get(errors, `homeAddress.state`))}
      >
        <InputLabel>State</InputLabel>
        <Select
          name="homeAddress.state"
          label="State"
          placeholder="Select State"
          data-qa="state"
          fullWidth
          value={values.homeAddress?.state}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {STATE_OPTIONS.map(state => (
            <MenuItem key={state.value} data-qa={"state-" + state.value} value={state.value}>
              {state.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{touched.homeAddress?.state ? `${errors.homeAddress?.state ?? ""}` : ""}</FormHelperText>
      </FormControl>
    </Grid>
  </AddPersonStep>
)
