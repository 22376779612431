import { FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { Stepper } from "@/features/CreateCompany/components/Stepper"
import { getCheckoutUrl } from "@/features/CreateCompany/createCompanyEndpoints"
import { PurchaseHraStepProps } from "@/features/CreateCompany/pages/CreateCompany"
import { useNotifications } from "@/services/notificationService"
import { Divider, Grid, Typography } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

interface PlanSummaryLineItem {
  title: string
  description?: string
  value: string | number
}

const PLATFORM_USAGE_FEE = [
  {
    title: "Platform fee",
    description: "($40 up to 50 eligible employees, +100 each incremental 50)",
    value: 0,
  },
  {
    title: "PEPM fee",
    description: "($20 per participating employee)",
    value: 0,
  },
  {
    title: "AutoPay buy up",
    description: "($5 per participating employee)",
    value: 0,
  },
] as const

const HRA_COST = [
  {
    title: "Total number of benefit eligible employees",
    value: 100,
  },
  {
    title: "Estimated number participating",
    value: 72,
  },
] as const

const PLAN_SUMMARY = [
  {
    title: "HRA Premium Reimbursement",
    description: "Calculated using average reimbursement amount and estimated participating employees",
    value: 0,
  },
  {
    title: "Take Command platform usage fee",
    value: 0,
  },
  {
    title: "Total",
    description: "Excluding any applicable sales tax",
    value: 0,
  },
] as const

export const PlanSummary = ({ stepData }: PurchaseHraStepProps) => {
  // Authentication
  const { user } = useAuth()
  const company = getActiveCompany(user)
  const companyId = company?.companyId
  const [isLoading, setIsLoading] = useState(false)
  const { notify } = useNotifications("handle-purchase")
  const navigate = useNavigate()

  const handlePurchaseError = (error: any) => {
    const message = error.message || error || "Something went wrong"

    console.warn(message)
    notify("An error occurred when making the purchase. Please try again later.", "error")
  }

  const handlePurchase = async () => {
    try {
      setIsLoading(true)
      const checkoutUrl = await getCheckoutUrl(companyId)

      if (checkoutUrl?.paymentDetailsPageUrl) {
        window.location.replace(checkoutUrl?.paymentDetailsPageUrl)
      }
    } catch (error: any) {
      handlePurchaseError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Stepper steps={stepData} activeStepIndex={5} isError={false} />
      <Grid container spacing={4} mt={5}>
        <Grid item xs={12} mb={5}>
          <Typography variant="h1">HRA Plan Summary for [Provider]</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Benefit Eligible Employees</Typography>
        </Grid>
        <Grid item xs={6} mb={5}>
          <Typography variant="body1">
            Average reimbursement amount &times; estimated <em>participating</em> employees
          </Typography>
        </Grid>
        {HRA_COST.map((lineItem: PlanSummaryLineItem) => (
          <Grid item xs={12} key={lineItem.title}>
            <Grid container justifyContent="space-between" py={1}>
              <Grid item xs={6}>
                <Typography variant="h6" align="left">
                  {lineItem.title}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right">
                  {`${lineItem.value}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} pt={3}>
              <Divider flexItem />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={4} mt={5}>
        <Grid item xs={12}>
          <Typography variant="h6">Cost estimator</Typography>
        </Grid>
        <Grid item xs={12} mb={3}>
          <Typography variant="h6">Take Command platform usage fee</Typography>
        </Grid>
        {PLATFORM_USAGE_FEE.map((lineItem: PlanSummaryLineItem) => (
          <Grid item xs={12} key={lineItem.title}>
            <Grid container justifyContent="space-between" py={1}>
              <Grid item xs={6}>
                <Typography variant="h6" align="left" sx={{ pl: 2 }}>
                  {lineItem.title}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right" sx={{ pl: 2 }}>
                  {`$${lineItem.value}`}
                </Typography>
              </Grid>
              {lineItem.description && (
                <Grid item xs={12}>
                  <Typography variant="caption" align="left" sx={{ color: "colors.lightGrayText", pl: 2 }}>
                    {lineItem.description}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} pt={3}>
                <Divider flexItem />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={4} mt={5}>
        <Grid item xs={12}>
          <Typography variant="h6">Plan summary</Typography>
        </Grid>

        {PLAN_SUMMARY.map((lineItem: PlanSummaryLineItem) => (
          <Grid item xs={12} key={lineItem.title}>
            <Grid container justifyContent="space-between" py={1}>
              <Grid item xs={6}>
                <Typography variant="h6" align="left" sx={{ pl: 2 }}>
                  {lineItem.title}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right" sx={{ pl: 2 }}>
                  {`$${lineItem.value}`}
                </Typography>
              </Grid>
              {lineItem.description && (
                <Grid item xs={12}>
                  <Typography variant="caption" align="left" sx={{ color: "colors.lightGrayText", pl: 2 }}>
                    {lineItem.description}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} pt={3}>
              <Divider flexItem sx={{ backgroundColor: "colors.darkBody" }} />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <FlowNavigationButtons
        handleBack={() => {
          navigate("/create-company/employee-roster")
        }}
        handleContinue={handlePurchase}
        continueLabel="Purchase"
        isSubmitting={isLoading}
      />
    </>
  )
}
