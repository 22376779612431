import { WrapperProps } from "@/utils/types"
import { Component, ErrorInfo } from "react"
import { ErrorPage } from "./ErrorPage"

interface ErrorBoundaryState {
  error: Error | null
}

/**
 * https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 * There is currently no way to write an error boundary as a function component.
 */
export class ErrorBoundary extends Component<WrapperProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    error: null,
  }

  public static getDerivedStateFromError(error: Error) {
    const derivedState: ErrorBoundaryState = { error }

    console.error("ErrorBoundary:", derivedState.error)

    return derivedState
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo)
  }

  public render() {
    const { children } = this.props
    const { error } = this.state

    if (error) {
      return <ErrorPage error={error} />
    }

    return children
  }
}
