export const BUSINESS_STRUCTURES = [
  { value: "nonprofit", label: "Non-Profit" },
  { value: "llc-partnership", label: "Partnership or LLC filing as Partnership" },
  { value: "c-corp", label: "Corporation or LLC filing as C-Corp" },
  { value: "s-corp", label: "S-Corporation or LLC filing as S-Corp" },
  { value: "soleprop", label: "Sole proprietorship" },
  { value: "unknown", label: "I don't know" },
] as const

export const APPLICABLE_LARGE_EMPLOYER = [
  { value: true, title: "Yes" },
  { value: false, title: "No" },
  { value: null, title: "I don't know" },
] as const

export const APPLICABLE_LARGE_EMPLOYER_DROPDOWN = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
  { value: null, label: "I don't know" },
] as const

export const INDUSTRIES = [
  { label: "Agriculture", value: "Agriculture" },
  {
    label: "Natural Resource Extraction and Refining",
    value: "Natural Resource Extraction and Refining",
  },
  { label: "Utilities", value: "Utilities" },
  { label: "Construction", value: "Construction" },
  { label: "Specialty Contractors (e.g., HVAC)", value: "Specialty Contractors (e.g., HVAC)" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Wholesale Trade", value: "Wholesale Trade" },
  { label: "Retail Trade", value: "Retail Trade" },
  {
    label: "Transportation, Shipping, and Warehousing",
    value: "Transportation, Shipping, and Warehousing",
  },
  {
    label: "Movies, TV, Broadcasting, Music, Books, etc.",
    value: "Movies, TV, Broadcasting, Music, Books, etc.",
  },
  { label: "Finance", value: "Finance" },
  { label: "Insurance Brokerage", value: "Insurance Brokerage" },
  { label: "Insurance Carrier", value: "Insurance Carrier" },
  { label: "Real Estate and Rental and Leasing", value: "Real Estate and Rental and Leasing" },
  { label: "Law Practice and Legal Services", value: "Law Practice and Legal Services" },
  { label: "Veterinarian and Pet Services", value: "Veterinarian and Pet Services" },
  {
    label: "Accounting, Bookkeeping, and Payroll Services",
    value: "Accounting, Bookkeeping, and Payroll Services",
  },
  {
    label: "Architectural, Engineering, Design and Related Services",
    value: "Architectural, Engineering, Design and Related Services",
  },
  { label: "Technology Consulting Services", value: "Technology Consulting Services" },
  {
    label: "Technology (Software or Infrastructure)",
    value: "Technology (Software or Infrastructure)",
  },
  {
    label: "Communications, Marketing and Advertising",
    value: "Communications, Marketing and Advertising",
  },
  {
    label: "Other Professional, Scientific and Technical Services",
    value: "Other Professional, Scientific and Technical Services",
  },
  {
    label: "Management of Companies (e.g., Holding Companies)",
    value: "Management of Companies (e.g., Holding Companies)",
  },
  {
    label: "Office/Facility Admin and Support Services",
    value: "Office/Facility Admin and Support Services",
  },
  { label: "Recruiting and Staffing", value: "recruiting-and-staffing" },
  { label: "Human Resources Outsourcing", value: "Human Resources Outsourcing" },
  {
    label: "Travel Arrangement and Reservation Services",
    value: "Travel Arrangement and Reservation Services",
  },
  { label: "Investigation and Security Services", value: "Investigation and Security Services" },
  {
    label: "Waste Management and Remediation Services",
    value: "Waste Management and Remediation Services",
  },
  { label: "Educational Services", value: "Educational Services" },
  {
    label: "Medical/Dental/Vision Private Practice",
    value: "Medical/Dental/Vision Private Practice",
  },
  { label: "Home Healthcare", value: "Home Healthcare" },
  { label: "Hospitals", value: "Hospitals" },
  {
    label: "Nursing and Residential Care Facilities",
    value: "Nursing and Residential Care Facilities",
  },
  {
    label: "Social Assistance (e.g., community needs, childcare)",
    value: "Social Assistance (e.g., community needs, childcare)",
  },
  {
    label: "In-Person Sports (e.g., venues, leagues)",
    value: "In-Person Sports (e.g., venues, leagues)",
  },
  { label: "In-Person Arts and Entertainment", value: "In-Person Arts and Entertainment" },
  { label: "Fitness and Recreation", value: "Fitness and Recreation" },
  { label: "Accommodation", value: "Accommodation" },
  { label: "Restaurants and Food/Drink Services", value: "Restaurants and Food/Drink Services" },
  {
    label: "Repair and Maintenance (e.g., Automotive, Commercial, Home)",
    value: "Repair and Maintenance (e.g., Automotive, Commercial, Home)",
  },
  {
    label: "Personal Care, Beauty and Laundry Services",
    value: "Personal Care, Beauty and Laundry Services",
  },
  { label: "Religious Institutions", value: "Religious Institutions" },
  { label: "Association or Non-Profit", value: "Association or Non-Profit" },
  { label: "Private Household", value: "Private Household" },
  { label: "Public Administration", value: "Public Administration" },
  { label: "Other", value: "Other" },
] as const

export const ALL_EMPLOYEES = "ALL_EMPLOYEES"
export const VARY_BY_FAMILY = "VARY_BY_FAMILY"
export const VARY_BY_AGE = "VARY_BY_AGE"
export const VARY_BY_FAMILY_SIZE = "VARY_BY_FAMILY_SIZE"
export const VARY_BY_FAMILY_SIZE_AND_AGE = "VARY_BY_FAMILY_SIZE_AND_AGE"
export const CUSTOM = "CUSTOM"

export const SIMPLE_REIMBURSEMENT_STRUCTURES = [] as const

export const CUSTOM_CLASS_REIMBURSEMENT_STRUCTURES = [
  {
    title: "All Employees Same Amount",
    value: ALL_EMPLOYEES,
  },
  {
    title: "Vary by Age",
    value: VARY_BY_AGE,
  },
  {
    title: "Vary by Family Size",
    value: VARY_BY_FAMILY_SIZE,
  },
  {
    title: "Vary by Age and Family Size",
    value: VARY_BY_FAMILY_SIZE_AND_AGE,
  },
] as const

export const PREMIUM_ONLY = "PREMIUM_ONLY"
export const PREMIUM_AND_MEDICAL_EXPENSES = "PREMIUM_AND_MEDICAL_EXPENSES"

export const ELIGIBLE_FOR_REIMBURSEMENT = [
  {
    title: "Insurance Premiums Only",
    value: PREMIUM_ONLY,
  },
  {
    title: "Insurance Premiums + Medical Expenses",
    value: PREMIUM_AND_MEDICAL_EXPENSES,
  },
] as const

export const EMPLOYEE = "EMPLOYEE"
export const SPOUSE = "SPOUSE"
export const CHILDREN = "CHILDREN"
export const SPOUSE_AND_CHILDREN = "SPOUSE_AND_CHILDREN"

export const IMMEDIATE = "IMMEDIATE"
export const FIRST_OF_MONTH_AFTER_30_DAYS = "FIRST_OF_MONTH_AFTER_30_DAYS"
export const FIRST_OF_MONTH_AFTER_60_DAYS = "FIRST_OF_MONTH_AFTER_60_DAYS"
export const FIRST_OF_MONTH_AFTER_90_DAYS = "FIRST_OF_MONTH_AFTER_90_DAYS"

export const WAITING_PERIODS = [
  {
    title: "Immediately",
    value: IMMEDIATE,
  },
  {
    title: "30 Days",
    subtitle: "(1st of month after hire date)",
    value: FIRST_OF_MONTH_AFTER_30_DAYS,
  },
  {
    title: "60 Days",
    subtitle: "(1st of month after 30 days)",
    value: FIRST_OF_MONTH_AFTER_60_DAYS,
  },
  {
    title: "90 Days",
    subtitle: "(1st of month after 60 days)",
    value: FIRST_OF_MONTH_AFTER_90_DAYS,
  },
] as const

export const SEASONAL = "SEASONAL"
export const NON_SEASONAL = "NON_SEASONAL"

export const SEASONALITY_STATUSES = [
  {
    title: "Seasonal",
    value: SEASONAL,
  },
  {
    title: "Non-Seasonal",
    value: NON_SEASONAL,
  },
] as const

export const PACKAGE_NAME_OPTIONS = [
  {
    title: "Starter",
    value: "Starter",
  },
  {
    title: "Growth",
    value: "Growth",
  },
  {
    title: "Partner",
    value: "Partner",
  },
  {
    title: "Professional",
    value: "Professional",
  },
]

export const SEASONALITY_RANGES = [
  {
    value: "1",
    label: "1+ Months",
  },
  {
    value: "2",
    label: "2+ Months",
  },
  {
    value: "3",
    label: "3+ Months",
  },
  {
    value: "4",
    label: "4+ Months",
  },
  {
    value: "5",
    label: "5+ Months",
  },
  {
    value: "6",
    label: "6+ Months",
  },
  {
    value: "7",
    label: "7+ Months",
  },
  {
    value: "8",
    label: "8+ Months",
  },
] as const

export const PART_TIME = "PART_TIME"
export const FULL_TIME = "FULL_TIME"

export const TIME_STATUSES = [
  {
    title: "Part-Time",
    value: PART_TIME,
  },
  {
    title: "Full-Time",
    value: FULL_TIME,
  },
] as const

export const SALARIED = "SALARIED"
export const HOURLY = "HOURLY"

export const SALARY_STATUSES = [
  {
    title: "Salary",
    value: SALARIED,
  },
  {
    title: "Non-Salary",
    value: HOURLY,
  },
] as const

export const QUARTERLY = "QUARTERLY"
export const ANNUALLY = "ANNUALLY"

export const COMPLIANCE_FREQUENCIES = [ANNUALLY, QUARTERLY] as const

export const COMPLIANCE_FREQUENCY_OPTIONS = [
  {
    title: "Annually",
    value: ANNUALLY,
  },
  {
    title: "Quarterly",
    value: QUARTERLY,
  },
] as const

export const MONTHLY = "MONTHLY"
export const TWICE_PER_MONTH = "BI_MONTHLY"
export const EVERY_OTHER_WEEK = "BI_WEEKLY"

export const STATEMENT_FREQUENCIES = [
  {
    title: "Monthly",
    value: MONTHLY,
  },
  {
    title: "Semi-Monthly",
    value: TWICE_PER_MONTH,
  },
  {
    title: "Bi-Weekly",
    value: EVERY_OTHER_WEEK,
  },
] as const

export const ACCOUNT = "ACCOUNT"
export const PLAN_SETUP = "PLAN_SETUP"
export const PLAN_STRUCTURE = "PLAN_STRUCTURE"
export const ADD_ON_PRODUCTS = "ADD_ON_PRODUCTS"
export const EMPLOYEE_ROSTER = "EMPLOYEE_ROSTER"
export const PLAN_SUMMARY = "PLAN_SUMMARY"

export const PURCHASE_HRA_STEPS = [
  ACCOUNT,
  PLAN_SETUP,
  PLAN_STRUCTURE,
  ADD_ON_PRODUCTS,
  EMPLOYEE_ROSTER,
  PLAN_SUMMARY,
] as const

export const PART_TIME_HOURS_MINIMUM = 0
export const PART_TIME_HOURS_MAXIMUM = 34

export const MAXIMUM_ELIGIBLE_EMPLOYEES = 50
export const MAXIMUM_PARTICIPATING_EMPLOYEES = 20

export const STARTER_PACKAGE_NAME = "Starter"
export const GROWTH_PACKAGE_NAME = "Growth"
