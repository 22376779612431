import { SearchFieldWithChips } from "@/components/SearchFields"
import { takeCommandPrimary } from "@/theme/palette"
import { Grid } from "@mui/material"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import { DOCTORS, HOSPITALS, PERSONAL_INFO } from "../../benefitsElectionConstants"
import { searchProviders } from "../../benefitsElectionEndpoints"
import { useMyInfo, useShoppingUrl } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { DoctorPreference, MyInfoSearchProps, ProviderPreferenceId } from "../../benefitsElectionTypes"
import { formatProvider } from "../../benefitsElectionUtils"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"

export type DoctorsSearchProps = MyInfoSearchProps<DoctorPreference, ProviderPreferenceId>

export const DoctorsSearchField = ({ planYear, zipCode, radius, ...props }: DoctorsSearchProps) => (
  <SearchFieldWithChips<DoctorPreference, ProviderPreferenceId>
    title="Doctors or specialists"
    name="doctors"
    placeholder="Search for doctors or specialists"
    handleSearch={searchTerm => searchProviders("individual", { searchTerm, planYear, zipCode, radius })}
    helperText="*Providers can change which insurance plans they accept at any time. We highly recommend calling your doctor or specialist to verify they accept a specific plan before enrolling."
    formatOption={doctor => formatProvider(doctor)}
    formatSelection={doctor => ({
      key: doctor.id,
      label: formatProvider(doctor),
    })}
    minimumInputLength={3}
    {...props}
    sx={{ backgroundColor: takeCommandPrimary[200] }}
  />
)

export const Doctors = () => {
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const { doctors, addDoctor, removeDoctor, planSearchParams } = useMyInfo()
  const shoppingUrl = useShoppingUrl()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const hasSelectedDoctors = doctors.length > 0
  const next = shoppingUrl + HOSPITALS
  const previous = shoppingUrl + PERSONAL_INFO

  // FUTURE: Evaluate whether this function can be performed by BenefitsElectionStep instead of being implemented in every step.
  const setNextStep = () => {
    if (currentStep === DOCTORS) {
      setCurrentStep(HOSPITALS)
    }
  }

  return (
    <BenefitsElectionStep
      title="Doctors"
      description="You can add your preferred doctors or specialists that are critical to your plan selection. If there are none, you can skip this step."
      previous={previous}
      next={next}
      disabled={!hasSelectedDoctors}
      handleContinue={() => {
        // FUTURE: Show appropriate notification based on API response
        // FUTURE: Refactor to use the notifications service and remove eslint-disable directive
        // eslint-disable-next-line no-restricted-syntax
        enqueueSnackbar(`Successfully added ${doctors.length} doctors`, {
          variant: "simpleNotification",
          selection: "success",
          dataQa: "add-doctors",
        })
        setNextStep()
        navigate(next)
      }}
      handleSkip={() => {
        setNextStep()
        navigate(next)
      }}
    >
      <Grid item xs={12} mt={4}>
        <DoctorsSearchField
          selections={doctors}
          handleSelection={addDoctor}
          handleDelete={removeDoctor}
          {...planSearchParams}
        />
      </Grid>
    </BenefitsElectionStep>
  )
}
