import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { TaskModel } from "./taskTypes"

export const BASE_TASK_MANAGEMENT_URL = `${appConfig.baseApiUrl}/task-management`

export const getTaskById = async (taskId: string) => {
  if (!taskId || taskId.trim() === "") {
    throw new Error("Invalid taskId: taskId cannot be empty or only whitespace")
  }
  return (await axios.get<TaskModel>(`${BASE_TASK_MANAGEMENT_URL}/v1/tasks/${taskId}`)).data
}
