import { useNotifications } from "@/services/notificationService"
import { sendSignUpLinkPersonId } from "../peopleManagementEndpoints"

const SIGN_UP_LINK_SENT_SUCCESS = "Invite email sent successfully"
const SIGN_UP_LINK_SENT_ERROR = "User has already signed up"

export const useSignUpLink = () => {
  const { notify } = useNotifications("email-information")

  const handleSendSignUpLink = async (companyId: string, personId: string) => {
    try {
      await sendSignUpLinkPersonId(companyId, personId)
      notify(SIGN_UP_LINK_SENT_SUCCESS, "success")
    } catch (error) {
      notify(SIGN_UP_LINK_SENT_ERROR, "error")
    }
  }

  return { handleSendSignUpLink }
}
