import { ClearableSearchInput } from "@/components/ClearableSearchInput/ClearableSearchInput"
import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import { Button, Grid, Typography } from "@mui/material"
import { useState } from "react"
import { Link } from "react-router-dom"
import { TcHubCompaniesTable } from "./TcHubCompaniesTable"

export const TcHubCompaniesPage = () => {
  const [searchInputValue, setSearchInputValue] = useState<string>("")

  return (
    <TcHubGuard requiredPermissions={["tc_hub_companies"]}>
      <Grid container data-qa="tc-hub-companies" justifyContent="space-between" spacing={3}>
        <Grid item xs={12} mb={5}>
          <Typography variant="h1" gutterBottom display="inline" data-qa="companies">
            Companies
          </Typography>
        </Grid>
        <Grid item>
          <Grid container justifyContent="flex-start" spacing={3}>
            <Grid item>
              <ClearableSearchInput
                onChange={event => setSearchInputValue(event.target.value)}
                handleClearClick={() => {
                  setSearchInputValue("")
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            data-qa="hub-button"
            component={Link}
            to="/admin/create-company"
            variant="contained"
            startIcon={<AddOutlinedIcon />}
          >
            Add new
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TcHubCompaniesTable companySelector={companies => companies} searchCriteria={searchInputValue} />
        </Grid>
      </Grid>
    </TcHubGuard>
  )
}
