import { SELF_ENROLL } from "@/constants"
import { useCompanyId } from "@/features/Auth/useAuth"
import { useNotifications } from "@/services/notificationService"
import { EMPLOYEE_PURCHASED, OTHER_PREMIUMS } from "../benefitsElectionConstants"
import { useIsCompanyAutoPay, useShoppingSession, useUpdateHealthBenefitsElection } from "../benefitsElectionService"
import { useBenefitsElectionStore } from "../benefitsElectionStore"
import { HealthBenefitsElection } from "../benefitsElectionTypes"

export const useShowCheckoutStep = () => {
  const companyId = useCompanyId(false)!
  const { isAutoPay } = useIsCompanyAutoPay(companyId)
  const getEnrollmentType = useBenefitsElectionStore(state => state.getEnrollmentType)
  const enrollmentType = getEnrollmentType()

  return !isAutoPay && !!enrollmentType && enrollmentType !== SELF_ENROLL
}

export const usePurchasePlan = (stepName: string) => {
  const { notify } = useNotifications(stepName)
  const shoppingSessionId = useShoppingSession()
  const currentShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const { mutateAsync: updateHealthBenefitsElection, isPending: isPurchasePending } =
    useUpdateHealthBenefitsElection(shoppingSessionId)

  const currentBenefitElection = currentShoppingSession.healthBenefitElections[0]
  const employmentId = currentBenefitElection.employmentId
  const electionId = currentBenefitElection.id

  const purchasePlanBenefitsElection: HealthBenefitsElection = {
    id: electionId,
    employmentId: currentBenefitElection.employmentId,
    enrollmentStatus: EMPLOYEE_PURCHASED,
  }

  const purchasePlan = async () => {
    try {
      await updateHealthBenefitsElection(purchasePlanBenefitsElection)

      setCurrentStep(OTHER_PREMIUMS)
    } catch (error: any) {
      notify(
        "Error purchasing plan. Please try again later. If the issue persists, contact support for assistance.",
        "error"
      )
      console.error(error)
    }
  }

  return { purchasePlan, isPurchasePending, employmentId, electionId }
}
