import { interleave } from "@/utils/util"
import { Stack, Typography, TypographyProps } from "@mui/material"
import { zip } from "lodash"
import { DateTime, Duration } from "luxon"
import { useEffect, useState } from "react"

const getRemaining = (until: Date) => DateTime.fromJSDate(until).diffNow()

const getSegments = (remaining: Duration<true>) => {
  const days = remaining.as("days")

  if (days < 1) {
    return [remaining.toFormat("dd,hh,mm,ss"), ["Days", "Hours", "Minutes", "Seconds"]] as const
  }

  if (days < 2) {
    return [remaining.toFormat("dd,hh,mm"), ["Days", "Hours", "Minutes"]] as const
  }

  if (days < 3) {
    return [remaining.toFormat("dd,hh"), ["Days", "Hours"]] as const
  }

  return [remaining.toFormat("dd"), ["Days"]] as const
}

export interface CountdownProps {
  until: Date
  variant?: TypographyProps["variant"]
  dark?: boolean
}

export const Countdown = ({ until, dark, variant = "huge" }: CountdownProps) => {
  const [remaining, setRemaining] = useState(() => getRemaining(until))

  useEffect(() => {
    const interval = setInterval(() => setRemaining(getRemaining(until)), 1000)

    return () => clearInterval(interval)
  })

  const [timings, labels] = getSegments(remaining)
  const segments = zip(timings.split(","), labels)
  const [mainColor, captionColor] = dark
    ? (["white", "lightgray"] as const)
    : (["black", "colors.captionGray"] as const)

  return (
    <Stack direction="row" color={mainColor}>
      {interleave(
        segments.map(([segment, caption]) => (
          <Stack key={`${caption}: ${segment}`} alignItems="center" width="3.5rem">
            <Typography variant="huge">{segment}</Typography>
            <Typography variant="caption" color={captionColor}>
              {caption}
            </Typography>
          </Stack>
        )),
        <Typography variant={variant}>:</Typography>
      )}
    </Stack>
  )
}
