import { BaseTable } from "@/components/Table/Table"
import { useCompanyDocDownloadUrl } from "@/features/Documents/documentsService"
import { ServiceDocument } from "@/features/Documents/documentsTypes"
import { useNotifications } from "@/services/notificationService"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import { CircularProgress, Grid, IconButton, TableCell, Typography } from "@mui/material"
import { constant, noop } from "lodash"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"

interface ReportModalTableHeaders {
  id: string
  label: string
  alignment?: "left" | "right" | "center"
}

const headers: ReportModalTableHeaders[] = [
  { id: "dateRange", label: "Date Range", alignment: "left" },
  {
    id: "download",
    label: "Download",
    alignment: "center",
  },
]

const extractMonthYear = (report: ServiceDocument, payrollReports: ServiceDocument[]) => {
  const s3Key = report.s3Key ?? ""
  const createdAt = report.createdAt ?? ""
  const [year, month] = [parseInt(s3Key.split("/")[3]), parseInt(s3Key.split("/")[4])]
  const isAmended = payrollReports.some(item => {
    const [reportYear, reportMonth] = item.s3Key
      ? [parseInt(item.s3Key.split("/")[3]), parseInt(item.s3Key.split("/")[4])]
      : [0, 0]
    return reportYear === year && reportMonth === month && new Date(item.createdAt) < new Date(createdAt)
  })

  return `${DateTime.fromObject({ year, month }).toFormat("MMMM")} ${year}${isAmended ? " (AMENDED)" : ""}`
}
interface PayrollReportHistoryTableProps {
  payrollReports: ServiceDocument[]
  companyId: string
  showTitle?: boolean
}
export const PayrollReportHistoryTable = ({ payrollReports, companyId, showTitle }: PayrollReportHistoryTableProps) => {
  const { notify } = useNotifications("payrollReportModal")
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | undefined>(undefined)
  const showTableTitle = showTitle ?? true

  const { data: url, isError: isFetchingUrlError } = useCompanyDocDownloadUrl(
    companyId,
    selectedDocumentId,
    false,
    true
  )

  useEffect(() => {
    if (url) {
      window.open(url, "_blank")
      setSelectedDocumentId(undefined)
    }
  }, [url])

  useEffect(() => {
    if (isFetchingUrlError) {
      notify("Unable to download the CSV report. Please reach out to the system administrator for assistance.", "error")
      setSelectedDocumentId(undefined)
    }
  }, [isFetchingUrlError, notify])

  const handleDownloadPayrollReport = (documentId: string) => {
    setSelectedDocumentId(documentId)
  }

  return (
    <Grid container xs={12} pt={4}>
      {showTableTitle && (
        <Grid item xs={12} py={1.5}>
          <Typography variant="h3">Report History</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <BaseTable
          rows={payrollReports}
          selected={[]}
          searchCriteria=""
          onToggleSelect={noop}
          onToggleOrderBy={noop}
          onToggleSelectAll={noop}
          onPageChange={noop}
          onRowsPerPageChange={noop}
          uniqueIdSelector={constant("")}
          headCells={headers ?? []}
          rowsPerPage={4}
          page={0}
          fullWidth
          orderBy={[{ headCellId: "", direction: "asc" }]}
          hidePagination
        >
          {({ row }) => (
            <>
              <TableCell>
                {row.s3Key ? (
                  <Typography data-qa="payroll report modal">{extractMonthYear(row, payrollReports)}</Typography>
                ) : (
                  <Typography data-qa="payroll report modal">Unknown</Typography>
                )}
              </TableCell>
              <TableCell align="center">
                {selectedDocumentId === row.documentId ? (
                  <CircularProgress size={24} sx={{ my: 2.5 }} />
                ) : (
                  <IconButton
                    data-qa="payroll-report-download"
                    onClick={() => {
                      handleDownloadPayrollReport(row.documentId)
                    }}
                    aria-label="View"
                    size="large"
                  >
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                )}
              </TableCell>
            </>
          )}
        </BaseTable>
      </Grid>
    </Grid>
  )
}
