import { AppContentWrapper } from "@/components/AppContentWrapper"
import { Footer } from "@/components/Footer"
import { Navbar } from "@/components/Navbar/Navbar"
import { RootWrapper } from "@/components/RootWrapper"
import { Sidebar } from "@/components/Sidebar/Sidebar"
import { SidebarNav } from "@/components/Sidebar/SidebarNav"
import { SidebarItem } from "@/components/Sidebar/types"
import { COMPLETE, INCOMPLETE } from "@/constants"
import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { BenefitsElectionGuard } from "@/features/Auth/guards/BenefitsElectionGuard"
import { StepData, Stepper } from "@/features/CreateCompany/components/Stepper"
import { WrapperProps } from "@/utils/types"
import { Box, Drawer, Grid } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useEffect, useState } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import {
  CARRIER_QUESTIONS,
  FIND_PLAN,
  MY_INFO,
  PERSONAL_INFO,
  PURCHASE,
  RECOMMENDED_PLANS,
  STEP_FIND_PLAN,
  STEP_MY_INFO,
  STEP_PURCHASE,
} from "../benefitsElectionConstants"
import { useShoppingUrl } from "../benefitsElectionService"
import { useBenefitsElectionStore } from "../benefitsElectionStore"
import { AlreadyHaveCoverage } from "../components/AlreadyHaveCoverage"
import { EnrollmentBanner } from "../components/EnrollmentBanner"
import { useBenefitsElectionItems } from "../hooks/useBenefitsElectionItems"

type BenefitsElectionStep = typeof MY_INFO | typeof FIND_PLAN | typeof PURCHASE

/**
 * Recursively finds an item within a list of items by its path.
 * @param path The path of the item to find.
 * @param items The list of sidebar items to search within.
 * @returns The sidebar item found by the given path, or undefined if not found.
 */
const findItemByPath = (path: string, items: SidebarItem[]): SidebarItem | undefined => {
  for (const item of items) {
    if (item.href === path) {
      return item
    }
    if (item.children && item.children.length > 0) {
      const foundItem = findItemByPath(path, item.children)

      if (foundItem) {
        return foundItem
      }
    }
  }

  return undefined
}

export const useBenefitsElectionStepper = () => {
  const location = useLocation()
  const currentPathName = location?.pathname
  const showFindPlan = useBenefitsElectionStore(state => state.showFindPlan)
  const benefitElectionMenuItems = useBenefitsElectionItems()
  const shoppingUrl = useShoppingUrl()
  const [activeStep, setActiveStep] = useState(0)
  const [steps, setSteps] = useState<StepData<BenefitsElectionStep>[]>([
    {
      label: "MY INFO",
      step: MY_INFO,
      url: shoppingUrl + PERSONAL_INFO,
      status: INCOMPLETE,
    },
    {
      label: "FIND A PLAN",
      step: FIND_PLAN,
      url: shoppingUrl + RECOMMENDED_PLANS,
      status: INCOMPLETE,
    },
    {
      label: "PURCHASE",
      step: PURCHASE,
      url: shoppingUrl + CARRIER_QUESTIONS,
      status: INCOMPLETE,
    },
  ])

  useEffect(() => {
    setSteps(current =>
      current.map((step, i) => ({
        ...step,
        status: benefitElectionMenuItems[i].complete ? COMPLETE : INCOMPLETE,
        hidden: step.label === "FIND A PLAN" && !showFindPlan,
      }))
    )

    if (currentPathName.includes(MY_INFO)) {
      setActiveStep(STEP_MY_INFO)
    } else if (currentPathName.includes(FIND_PLAN)) {
      setActiveStep(STEP_FIND_PLAN)
    } else if (currentPathName.includes(PURCHASE)) {
      setActiveStep(STEP_PURCHASE)
    }
  }, [benefitElectionMenuItems, currentPathName, showFindPlan])

  const menuItem = findItemByPath(currentPathName, benefitElectionMenuItems)

  return { steps, menuItem, activeStep, benefitElectionMenuItems }
}

export const BenefitsElectionStepper = () => {
  const { steps, activeStep } = useBenefitsElectionStepper()

  return (
    <Box sx={{ width: { xs: "100%", lg: "60%" }, pb: "2.2rem" }}>
      <Stepper steps={steps} activeStepIndex={activeStep} isError={false} hideProgressBar />
    </Box>
  )
}

export const BenefitsElectionLayout = ({ children }: WrapperProps) => {
  const isMediumOrUp = useMediaQuery(theme => theme.breakpoints.up("md"))
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const shoppingUrl = useShoppingUrl()
  const [mobileOpen, setMobileOpen] = useState(false)
  const { menuItem, benefitElectionMenuItems } = useBenefitsElectionStepper()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  if (menuItem?.disabled) {
    return <Navigate to={shoppingUrl + currentStep} />
  }

  if (!currentStep) {
    setCurrentStep(PERSONAL_INFO)

    return <Navigate to={shoppingUrl + PERSONAL_INFO} />
  }

  return (
    <AuthGuard>
      <BenefitsElectionGuard>
        <RootWrapper>
          <AppContentWrapper>
            <Navbar
              onDrawerToggle={handleDrawerToggle}
              hasLogo={isMediumOrUp}
              hasMark={!isMediumOrUp}
              centerAlignMark={!isMediumOrUp}
              toggleDrawerOnMedium
              hasNotificationDrawer={false}
            />
            <Grid
              container
              id="benefits-election-layout"
              data-qa="benefits-election-layout"
              sx={{ bgcolor: "colors.white", height: "100%", overflow: "auto" }}
            >
              <Grid
                item
                container
                sx={{ paddingTop: "1rem", height: "100%", padding: "1rem auto" }}
                justifyContent="center"
              >
                <Grid item display={{ xs: "block", md: "none" }}>
                  <Box sx={{ md: { width: "80%", flexShrink: 0 } }} data-qa="benefits-election-drawer-mobile">
                    <Sidebar
                      PaperProps={{
                        style: { width: "80%", maxWidth: "20rem" },
                      }}
                      variant="temporary"
                      open={mobileOpen}
                      onClose={handleDrawerToggle}
                      items={benefitElectionMenuItems}
                      showMark
                      showFooter={false}
                      showSectionDivider={false}
                    >
                      <AlreadyHaveCoverage />
                    </Sidebar>
                  </Box>
                </Grid>
                <Grid
                  item
                  id="test-container"
                  xs={0}
                  lg={3}
                  xl={2}
                  sx={{ position: "relative" }}
                  display={{ xs: "none", md: "block" }}
                >
                  <Drawer
                    data-qa="benefits-election-drawer"
                    PaperProps={{
                      style: {
                        width: "100%",
                        position: "absolute",
                        paddingTop: "4rem",
                        maxHeight: "90%",
                        border: "none",
                        zIndex: 0,
                        height: "auto",
                      },
                    }}
                    ModalProps={{
                      container: document.getElementById("test-container"),
                      style: { position: "absolute" },
                    }}
                    variant="permanent"
                  >
                    <Box p={2}>
                      <EnrollmentBanner variant="inside-shopping-flow" />
                    </Box>
                    <SidebarNav items={benefitElectionMenuItems} showSectionDivider={false} />
                    <Box p={4}>
                      <AlreadyHaveCoverage />
                    </Box>
                  </Drawer>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={8}
                  xl={7}
                  sx={{ position: "relative", paddingLeft: "2rem", paddingRight: "2rem" }}
                >
                  <BenefitsElectionStepper />
                  {children}
                  <Outlet />
                  <Box sx={{ height: { xs: "2rem", md: "1rem" } }} />
                </Grid>
              </Grid>
            </Grid>

            <Footer />
          </AppContentWrapper>
        </RootWrapper>
      </BenefitsElectionGuard>
    </AuthGuard>
  )
}
