import { ConfirmationModal } from "@/components/ConfirmationModal"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { AboutFormSection } from "@/features/People/PeopleSettings/AboutFormSection"
import styled from "@emotion/styled"
import {
  Archive as ArchiveIcon,
  Delete as DeleteIcon,
  MoreVertSharp,
  Unarchive as ReinstateIcon,
  Send as SendIcon,
} from "@mui/icons-material"
import {
  Grid,
  Hidden,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Tooltip,
  Typography,
} from "@mui/material"
import { spacing } from "@mui/system"
import { useSnackbar } from "notistack"
import { ComponentProps, useState } from "react"
import { Helmet } from "react-helmet-async"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { PEOPLE_INACTIVE_STATUSES } from "../peopleConstants"
import { confirmReinstateModal, confirmTerminationModal, failedModal } from "../peopleModals"
import { useArchivePersonMutation, useGetPersonById, useReinstatePersonMutation } from "../peopleService"

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)
const Divider = styled(MuiDivider)(spacing)

export const PeopleSettings = () => {
  const navigate = useNavigate()
  interface Params extends Record<string, string | undefined> {
    personId: string
  }

  const { personId } = useParams<Params>()
  const { user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId || ""
  const archivePersonMutation = useArchivePersonMutation(companyId)
  const reinstatePersonMutation = useReinstatePersonMutation(companyId)
  const { enqueueSnackbar } = useSnackbar()
  const { data: person } = useGetPersonById(companyId, personId)

  type ModalState = null | ComponentProps<typeof ConfirmationModal>
  const [modal, setModal] = useState<ModalState>(null)

  const clearModal = () => {
    setModal(null)
  }

  const failed = {
    ...failedModal,
    onConfirm: clearModal,
  }

  const confirmTerminate = {
    ...confirmTerminationModal,
    onConfirm: async () => {
      try {
        await archivePersonMutation.mutateAsync(personId!)
        // FUTURE: Refactor to use the notifications service and remove eslint-disable directive
        // eslint-disable-next-line no-restricted-syntax
        enqueueSnackbar("Person archived successfully", {
          variant: "simpleNotification",
          selection: "success",
          dataQa: "archive-person-successful-snackbar",
        })
        clearModal()
        navigate("/people?tab=inactive")
      } catch (e) {
        setModal(failed)
      }
    },
  }

  const confirmReinstate = {
    ...confirmReinstateModal,
    onConfirm: async () => {
      try {
        await reinstatePersonMutation.mutateAsync(personId!)
        // FUTURE: Refactor to use the notifications service and remove eslint-disable directive
        // eslint-disable-next-line no-restricted-syntax
        enqueueSnackbar("Person reinstated successfully", {
          variant: "simpleNotification",
          selection: "success",
          dataQa: "reinstate-person-successful-snackbar",
        })
        clearModal()
        navigate("/people?tab=active")
      } catch (e) {
        setModal(failed)
      }
    },
  }

  if (!person) {
    return null
  }
  const inactive = person?.status in PEOPLE_INACTIVE_STATUSES
  const label = inactive ? "Reinstate" : "Archive"
  const actionModal = inactive ? confirmReinstate : confirmTerminate

  return (
    <>
      <Helmet title="Profile" />
      <Typography variant="h3" gutterBottom display="inline" data-qa="page-header">
        Profile
      </Typography>
      <Grid container>
        <Hidden smDown>
          <Grid item xs={12} md={6} mb={3}>
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link component={NavLink} to="/people">
                People
              </Link>
              <Link component={NavLink} to="/">
                {person.firstName} {person.lastName}
              </Link>
              <Typography>Profile</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item container xs={12} md={6} justifyContent="flex-end" data-qa="people-individiual-settings-container">
            <Tooltip title={label}>
              <IconButton
                aria-label={label}
                size="large"
                color="inherit"
                data-qa={`icon-${label.toLowerCase()}`}
                onClick={() => setModal(actionModal)}
              >
                {inactive ? <ReinstateIcon fontSize="large" /> : <ArchiveIcon fontSize="large" />}
              </IconButton>
            </Tooltip>
            <IconButton aria-label="Delete" size="large" color="inherit" data-qa="icon-delete">
              <DeleteIcon fontSize="large" />
            </IconButton>
            <IconButton aria-label="Send" size="large" color="inherit" data-qa="icon-send">
              <SendIcon fontSize="large" />
            </IconButton>
            <Divider
              orientation="vertical"
              flexItem
              variant="middle"
              color="black"
              sx={{ borderRightWidth: 2, height: 30, mt: 3 }}
            />
            <IconButton aria-label="More" size="large" color="inherit" data-qa="icon-more">
              <MoreVertSharp fontSize="large" />
            </IconButton>
          </Grid>
        </Hidden>
      </Grid>

      <Divider mb={5} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <AboutFormSection person={person} />
        </Grid>
      </Grid>
      {modal && <ConfirmationModal {...modal} onClose={clearModal} />}
    </>
  )
}
