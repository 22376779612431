import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { PermissionGuard } from "@/features/Auth/guards/PermissionGuard"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { getValueLabelsFromYearsToNext, months } from "@/utils/dates"
import { DATE_FORMAT_MONTH_DAY_YEAR, formatCents, transformDate } from "@/utils/formatting"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { useState } from "react"
import { FUNDING_EVENT_STATUS_SCHEDULED } from "../Funding/fundingConstants"
import { useCompanyFundingEventEntries, useCompanyFundingEvents, useCompanyFundingEventSummary } from "./autopayService"
import { AutoPayFundingReportsTable } from "./components/AutoPayFundingReportsTable"

interface AutoPayFudingReportSnapshotProps {
  latestScheduledEvent: any
}

const AutoPayFudingReportSnapshot = ({ latestScheduledEvent }: AutoPayFudingReportSnapshotProps) => (
  <Grid container alignItems="center" sx={{ justifyContent: { xs: "space-between", md: "flex-start" }, py: 2 }}>
    <Grid item xs={3}>
      <Grid container>
        <Grid item>
          <Typography variant="body1">Next Funding Amount</Typography>
        </Grid>
        <Grid item ml={1}>
          <HelpOutlineOutlinedIcon />
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h5">
          {latestScheduledEvent.totalFundingAmountCents
            ? formatCents(latestScheduledEvent.totalFundingAmountCents)
            : "—"}
        </Typography>
      </Grid>
    </Grid>
    <Grid item sm={1} sx={{ px: 4 }}>
      <Typography variant="caption">FOR</Typography>
    </Grid>
    <Grid item xs={2}>
      <Grid container>
        <Grid item>
          <Typography variant="body1"># of Employees</Typography>
        </Grid>
        <Grid item ml={1}>
          <HelpOutlineOutlinedIcon />
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h5">{latestScheduledEvent.numberOfEmployees || "—"}</Typography>
      </Grid>
    </Grid>
    <Grid item xs={1} sx={{ px: 4 }}>
      <Typography variant="caption">ON</Typography>
    </Grid>
    <Grid item xs={2}>
      <Grid container>
        <Grid item>
          <Typography variant="body1">Transfer Date</Typography>
        </Grid>
        <Grid item ml={1}>
          <HelpOutlineOutlinedIcon />
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h5">
          {latestScheduledEvent.periodEndAt
            ? transformDate(latestScheduledEvent.periodEndAt, DATE_FORMAT_MONTH_DAY_YEAR)
            : "—"}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
)

export const AutoPayFundingReports = () => {
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>()
  const [selectedYear, setSelectedYear] = useState<number | undefined>()

  const { user } = useAuth()
  const company = getActiveCompany(user)
  const companyId = company?.companyId
  const { data: companyFundingEvents, isLoading: loadingCompanyFundingEvents } = useCompanyFundingEvents(companyId)
  const { data: companyFundingEventEntries, isLoading: loadingCompanyFundingEventEntries } =
    useCompanyFundingEventEntries({ companyId, month: selectedMonth, year: selectedYear })

  const scheduledEvents =
    companyFundingEvents?.companyFundingEvents.filter(event => event.status === FUNDING_EVENT_STATUS_SCHEDULED) ?? []
  const { data: latestScheduledEvent } = useCompanyFundingEventSummary(companyId, scheduledEvents?.[0]?.id)

  return (
    <PermissionGuard in="accounts_account_management">
      {latestScheduledEvent && <AutoPayFudingReportSnapshot latestScheduledEvent={latestScheduledEvent} />}
      <Grid container direction="row" spacing={4} sx={{ mt: 6 }}>
        <Grid item>
          <FormControl variant="outlined" size="small" sx={{ backgroundColor: "white", minWidth: 150 }}>
            <InputLabel id="month">Month</InputLabel>
            <Select
              labelId="month"
              id="month"
              value={selectedMonth}
              onChange={e => setSelectedMonth(Number(e.target.value))}
              sx={{ height: "2.5rem" }}
              label="Month"
            >
              {months.map(month => (
                <MenuItem key={`month-select-${month.value}`} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl size="small" sx={{ backgroundColor: "white", minWidth: 120 }}>
            <InputLabel id="year">Year</InputLabel>
            <Select
              id="year"
              labelId="year"
              value={selectedYear}
              onChange={e => setSelectedYear(Number(e.target.value))}
              label="Year"
            >
              {getValueLabelsFromYearsToNext(2024).map(year => (
                <MenuItem key={`year-select-${year.value}`} value={year.value}>
                  {year.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 0 }}>
        <Grid item xs={12}>
          {selectedMonth &&
          selectedYear &&
          companyId &&
          (loadingCompanyFundingEvents || loadingCompanyFundingEventEntries) ? (
            <SkeletonTableLoader
              headerTitles={["Name", "Amount", "Date"]}
              bodyRowsCount={7}
              data-qa="skeleton-table-loader-company-funding-events"
            />
          ) : (
            <AutoPayFundingReportsTable events={companyFundingEventEntries ?? []} />
          )}
        </Grid>
      </Grid>
    </PermissionGuard>
  )
}
