import { StyledCard } from "@/components/StyledCard"
import {
  useDownloadSignature,
  useEmployeeDetails,
  useEnrollmentDetails,
} from "@/features/TCHub/Enrollment/tcHubEnrollmentService"
import { colors, takeCommandPrimary } from "@/theme/palette"
import { getOnlyDate } from "@/utils/dates"
import { formatDate, toTitleCase } from "@/utils/formatting"
import CheckCircle from "@mui/icons-material/CheckCircleOutlineOutlined"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import { Button, Card, Checkbox, Chip, Grid, TextField, Typography } from "@mui/material"
import Box from "@mui/system/Box"
import { head } from "lodash"
import { useNavigate, useParams } from "react-router-dom"

const DependentCardContainer = ({
  children,
  xs,
  sm,
  lg,
  hasDependents,
}: {
  children: React.ReactNode
  xs?: number
  sm?: number
  lg?: number
  hasDependents?: boolean
}) => (
  <Grid item xs={xs} sm={sm} lg={lg}>
    <Card
      sx={{
        border: `1px solid ${takeCommandPrimary.captionGray}`,
        backgroundColor: colors.pageBackgroundColor,
        padding: {
          xs: 2,
          lg: 3,
        },
        width: "100%",
        overflow: "hidden",
        ...(hasDependents ? { minHeight: "8.25rem" } : {}),
      }}
    >
      {children}
    </Card>
  </Grid>
)

const DependentCard = ({
  dependentName,
  dependentDateOfBirth,
  dependentGender,
  dependentRelationship,
  dependentSignature,
  xs,
  sm,
  lg,
}: {
  dependentName: string
  dependentDateOfBirth: string
  dependentGender: string
  dependentRelationship: string
  dependentSignature: string | undefined
  xs?: number
  sm?: number
  lg?: number
}) => {
  const downloadSignature = useDownloadSignature(dependentSignature ?? "")

  return (
    <DependentCardContainer lg={lg} sm={sm} xs={xs} hasDependents={!!dependentName}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
          <CheckCircle sx={{ color: takeCommandPrimary.main, mr: 2 }} />
          <Typography variant="body1">{dependentName}</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Chip
            variant="outlined"
            label={dependentDateOfBirth}
            sx={{
              mr: {
                sm: 2,
              },
            }}
          />
          <Chip
            variant="outlined"
            label={dependentGender}
            sx={{
              mr: {
                sm: 2,
              },
            }}
          />
          <Chip variant="outlined" label={dependentRelationship} />
        </Grid>
        {dependentSignature && dependentSignature !== null && (
          <Grid item xs={12} sm={12}>
            <Button
              sx={{ mt: 1 }}
              variant="outlined"
              size="small"
              endIcon={<FileDownloadOutlinedIcon />}
              disabled={downloadSignature.isPending}
              href={downloadSignature.data}
            >
              Download Signature
            </Button>
          </Grid>
        )}
      </Grid>
    </DependentCardContainer>
  )
}

const EnrollmentContainer = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <Grid
    container
    sx={{
      mt: 2,
      width: "100%",
    }}
  >
    <Grid
      item
      xs={12}
      sm={12}
      sx={{
        my: 4,
      }}
    >
      <Typography variant="h6">{title}</Typography>
    </Grid>
    <Grid container spacing={4}>
      {children}
    </Grid>
  </Grid>
)

const EnrollmentReadOnlyCheckbox = ({
  title,
  subtitle,
  isChecked,
  xs,
  sm,
}: {
  title: string
  subtitle: string
  isChecked: boolean
  xs?: number
  sm?: number
}) => (
  <Grid item xs={xs} sm={sm}>
    <Box
      sx={{
        border: "1px solid",
        borderColor: isChecked ? "colors.borderGreen" : "colors.borderGray",
        borderRadius: "5px",
        backgroundColor: isChecked ? "colors.lightTealShade" : "colors.regionGray",
        cursor: "pointer",
        minHeight: "3.125rem",
        pl: 4,
        pr: isChecked ? 2 : 4,
        py: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="body1"> {title}</Typography>
        <Typography variant="caption" sx={{ display: "block", lineHeight: "1.162rem" }}>
          {subtitle}
        </Typography>
      </Box>
      <Checkbox color="primary" checked={isChecked} disabled />
    </Box>
  </Grid>
)

const EnrollmentReadOnlyField = ({
  label,
  value,
  xs,
  sm,
}: {
  label: string
  value: string
  xs?: number
  sm?: number
}) => (
  <Grid item xs={xs} sm={sm}>
    <TextField fullWidth label={label} value={value ?? ""} disabled />
  </Grid>
)

const getPersonName = (nameDescriptor: {
  firstName: string | undefined
  lastName: string | undefined
  preferredName: string | undefined
}) => {
  if (nameDescriptor.preferredName) {
    return nameDescriptor.preferredName
  }
  if (nameDescriptor.firstName || nameDescriptor.lastName) {
    return `${nameDescriptor.firstName ?? ""} ${nameDescriptor.lastName ?? ""}`.trim()
  }
  return "Unknown"
}

const displayDate = (dateOfBirth: string | undefined) => {
  if (!dateOfBirth) {
    return ""
  }
  const date = getOnlyDate(dateOfBirth)
  return date ? formatDate(date) : ""
}

export const TcHubEnrollmentDetailsPage = () => {
  const navigate = useNavigate()
  const { enrollmentId } = useParams()
  const { data: enrollment } = useEnrollmentDetails(enrollmentId)
  const { data: employee } = useEmployeeDetails(enrollment?.employmentId)
  const eeShoppingPerson = enrollment?.shoppingPersons?.find(person => person.relationship === "EMPLOYEE")
  const downloadSignature = useDownloadSignature(eeShoppingPerson?.signatureDocumentId ?? "")
  const nonEmployeeDependents = enrollment?.shoppingPersons?.filter(person => person.relationship !== "EMPLOYEE") ?? []
  const firstNonEmployee = head(nonEmployeeDependents) ?? null
  const employeeName = getPersonName({
    firstName: employee?.firstName ?? eeShoppingPerson?.firstName,
    lastName: employee?.lastName ?? eeShoppingPerson?.lastName,
    preferredName: eeShoppingPerson?.preferredName,
  })

  return (
    <>
      <Button
        data-qa="back-button"
        type="submit"
        color="inherit"
        onClick={() => navigate(-1)}
        startIcon={<KeyboardArrowLeft />}
      >
        Back to Enrollments
      </Button>
      <StyledCard>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" gutterBottom>
              {employeeName} Enrollment
            </Typography>
            <Typography variant="body1">{employee?.email}</Typography>
          </Grid>
          {eeShoppingPerson?.signatureDocumentId && (
            <Grid item>
              <Button
                variant="outlined"
                disabled={downloadSignature.isPending}
                href={downloadSignature.data}
                endIcon={<FileDownloadOutlinedIcon />}
              >
                Download Signature
              </Button>
            </Grid>
          )}
        </Grid>
        <EnrollmentContainer title="Personal information">
          <EnrollmentReadOnlyField xs={12} sm={4} label="Employee name" value={employeeName} />
          <EnrollmentReadOnlyField
            xs={12}
            sm={4}
            label="Gender"
            value={toTitleCase(eeShoppingPerson?.gender as string)}
          />
          <EnrollmentReadOnlyField xs={12} sm={4} label="Health Plan ID" value={enrollment?.healthPlanId ?? ""} />
          <EnrollmentReadOnlyField
            xs={12}
            sm={6}
            label="County zipcode"
            value={[
              eeShoppingPerson?.county ?? firstNonEmployee?.county ?? "",
              eeShoppingPerson?.zipCode ?? firstNonEmployee?.zipCode ?? "",
            ]
              .join(" ")
              .trim()}
          />
          <EnrollmentReadOnlyField
            xs={12}
            sm={6}
            label="Date of birth"
            value={displayDate(eeShoppingPerson?.dateOfBirth)}
          />
        </EnrollmentContainer>
        <EnrollmentContainer title="Family">
          {nonEmployeeDependents.length === 0 && (
            <DependentCardContainer xs={12} sm={6} lg={4}>
              <Typography variant="body1">No dependents</Typography>
            </DependentCardContainer>
          )}
          {nonEmployeeDependents?.map((dependent, index) => (
            <DependentCard
              key={`${dependent.id}-${index}`}
              xs={12}
              sm={6}
              lg={4}
              dependentName={getPersonName({
                firstName: dependent.firstName,
                lastName: dependent.lastName,
                preferredName: dependent.preferredName,
              })}
              dependentDateOfBirth={displayDate(dependent.dateOfBirth)}
              dependentGender={toTitleCase(dependent.gender as string)}
              dependentRelationship={toTitleCase(dependent.relationship as string)}
              dependentSignature={dependent.signatureDocumentId}
            />
          ))}
        </EnrollmentContainer>
        <EnrollmentContainer title="Enrollment">
          <EnrollmentReadOnlyField
            xs={6}
            label="Enrollment status"
            value={toTitleCase(enrollment?.enrollmentStatus ?? "")}
          />
          <EnrollmentReadOnlyField
            xs={6}
            label="Earliest Health Plan Start Date"
            value={displayDate(enrollment?.planEffectiveDate)}
          />
          <EnrollmentReadOnlyCheckbox
            xs={6}
            isChecked={enrollment?.isRenewal ?? false}
            title="Health plan renewed"
            subtitle="Is keeping equivalent health plan?"
          />
        </EnrollmentContainer>
        <Grid container sx={{ mt: 8, justifyContent: "space-between" }}>
          <Grid item />
          <Grid item>
            <Button variant="text" sx={{ mr: 4 }} disabled>
              Cancel
            </Button>
            <Button variant="contained" color="primary" disabled>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </StyledCard>
    </>
  )
}
