import { ObscuredTextField, ObscuredTextFieldProps } from "@/components/TextFields"
import { PATCH } from "@/constants"
import { toTitleCase } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import { validateSsn } from "@/utils/validations"
import { Grid, InputBaseComponentProps, Typography } from "@mui/material"
import { Formik, useFormikContext } from "formik"
import { noop } from "lodash"
import { forwardRef } from "react"
import { IMaskInput } from "react-imask"
import * as Yup from "yup"
import { MAILING_ADDRESS, SIGNATURES, SSN } from "../../benefitsElectionConstants"
import { useManageShoppingPersons, useShoppingSession, useShoppingUrl } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { EligibleApplicant, ManageShoppingPersonPayload } from "../../benefitsElectionTypes"
import { getDisplayName } from "../../benefitsElectionUtils"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"

interface SsnValue {
  id: Uuid
  ssn: string
}

const validationSchema = Yup.array().of(
  Yup.object().shape({
    id: Yup.string(),
    ssn: validateSsn,
  })
)

const createPayloads = (values: SsnValue[]): ManageShoppingPersonPayload[] =>
  values.map(({ id, ssn }) => ({ id, ssn, method: PATCH }))

const ssnIsUnique = (values: SsnValue[], newSsn: string) =>
  values.filter(val => val.ssn !== newSsn).length === values.length - 1

const SsnInput = forwardRef<HTMLInputElement, InputBaseComponentProps>((props, ref) => (
  <IMaskInput inputRef={ref} {...props} />
))

export type SsnFieldProps = Omit<ObscuredTextFieldProps, "name" | "data-qa"> & {
  memberId: Uuid
  memberName: string
}

export const SsnField = ({ memberId, memberName, ...props }: SsnFieldProps) => {
  const { values, setFieldValue, setFieldTouched, handleBlur, handleChange } = useFormikContext<SsnValue[]>()
  const index = values.findIndex(({ id }) => id === memberId)
  const fieldKey = `${index}.ssn`
  const onAccept = (value: string) => setFieldValue(fieldKey, value)
  const familyMemberName = toTitleCase(memberName, " ")

  return (
    <>
      <Grid item>
        <Typography variant="h6" gutterBottom>
          {familyMemberName}
        </Typography>
      </Grid>
      <Grid item sx={{ mb: 4 }}>
        <ObscuredTextField
          name="ssn-field"
          label="Social Security Number (SSN)"
          value={values[index].ssn}
          onBlur={e => {
            setFieldTouched(fieldKey)
            handleBlur(e)
          }}
          onKeyUp={(e: any) => {
            setFieldTouched(fieldKey)
            handleBlur(e)
          }}
          onChange={handleChange}
          {...props}
          inputProps={{
            ...props.inputProps,
            mask: "000-00-0000",
            "aria-label": `${familyMemberName} social security number input`,
            onAccept,
          }}
          InputProps={{
            ...props.InputProps,
            inputComponent: SsnInput,
          }}
          sx={{ maxWidth: "35rem" }}
        />
      </Grid>
    </>
  )
}

const createInitialValues = (applicants: EligibleApplicant[]) =>
  applicants.map(({ shoppingPersonId }): SsnValue => ({ id: shoppingPersonId, ssn: "" }))

export const Ssn = () => {
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const getEligibleApplicants = useBenefitsElectionStore(state => state.getEligibleApplicants)
  const shoppingSessionId = useShoppingSession()
  const shoppingUrl = useShoppingUrl()
  const { mutateAsync: manageShoppingPersons, isPending } = useManageShoppingPersons(shoppingSessionId)
  const applicants = getEligibleApplicants()
  const initialValues = createInitialValues(applicants)
  const previous = shoppingUrl + MAILING_ADDRESS
  const next = shoppingUrl + SIGNATURES

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={noop}>
      {({ isValid, errors, dirty, touched, values, setFieldError }) => {
        const hasRepeatedValues = new Set(values.map(val => val.ssn)).size !== values.length
        const disableContinue = applicants.length === 0 || !dirty || !isValid || hasRepeatedValues

        return (
          <BenefitsElectionStep
            title="Additional Information Required"
            description="We'll need social security numbers for each family member in order to submit your application."
            required
            handleContinue={async () => {
              await manageShoppingPersons(createPayloads(values))
              if (currentStep === SSN) {
                setCurrentStep(SIGNATURES)
              }
            }}
            previous={previous}
            next={next}
            disabled={disableContinue}
            advanceOnSuccess
            successMessage="Successfully saved SSNs"
            errorMessage="Error saving social security numbers information. Please try again later."
            isSubmitting={isPending}
          >
            <Grid container direction="column" rowGap={1}>
              {applicants.map(({ shoppingPersonId, personalInformation }, i) => {
                const memberName = getDisplayName(personalInformation)

                const index = values.findIndex(({ id }) => id === shoppingPersonId)
                const value = values[index].ssn
                const valueIsUnique = ssnIsUnique(values, value)
                const uniqueError = valueIsUnique ? "" : "SSN must be unique"

                const errorMessage = errors[i]?.ssn ? errors[i]?.ssn : uniqueError
                const error = touched[i]?.ssn && !!errorMessage
                return (
                  <SsnField
                    memberId={shoppingPersonId}
                    memberName={memberName}
                    required
                    error={error}
                    helperText={error && errorMessage}
                    key={shoppingPersonId}
                  />
                )
              })}
            </Grid>
          </BenefitsElectionStep>
        )
      }}
    </Formik>
  )
}
