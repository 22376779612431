import { Typography } from "@mui/material"
import { EMPLOYER_CONTRIBUTION, PAYMENT_HISTORY } from "../dashboardConstants"
import { DashboardWidgetResult, WidgetData } from "../dashboardTypes"
import { EmployerContributionWidget } from "./employeeWidgets/EmployerContributionWidget"
import { HealthInsuranceOverviewWidget } from "./employeeWidgets/HealthInsuranceOverviewWidget"
import { PaymentHistoryWidget } from "./employeeWidgets/PaymentHistoryWidget"

interface EmployeeDashboardProps {
  data: DashboardWidgetResult[]
  isLoading: boolean
}

const getEmployeeWidgetComponent = (widgetId: string, data: WidgetData, status: string, isLoading: boolean) => {
  switch (widgetId) {
    case PAYMENT_HISTORY:
      return <PaymentHistoryWidget data={data} isLoading={isLoading} />
    case EMPLOYER_CONTRIBUTION:
      return <EmployerContributionWidget data={data} isLoading={isLoading} />
    default:
      return <HealthInsuranceOverviewWidget data={data} status={status} isLoading={isLoading} />
  }
}

export const EmployeeDashboard = ({ data, isLoading }: EmployeeDashboardProps) =>
  !data || Object.keys(data).length === 0 ? (
    <Typography variant="h3" gutterBottom>
      My benefits
    </Typography>
  ) : (
    data.map(w => getEmployeeWidgetComponent(w.widgetId, w.data, w.status, isLoading))
  )
