import { Grid } from "@mui/material"
import { ReactNode } from "react"

interface QuestionContainerProps {
  children: ReactNode
}

export const QuestionContainer = ({ children }: QuestionContainerProps) => (
  <Grid item sx={{ my: "10%", width: { xs: "90%", sm: "50%" } }} data-qa="prescreen-questions-page-wrapper">
    {children}
  </Grid>
)
