import { Drawer, SxProps } from "@mui/material"
import { ReactEventHandler, ReactNode } from "react"

export interface DrawerFormProps {
  children: ReactNode
  open: boolean
  onClose: ReactEventHandler
  paperStyle: SxProps
}

export const DrawerForm = ({ children, open, onClose, paperStyle, ...props }: DrawerFormProps) => (
  <Drawer
    open={open}
    variant="temporary"
    anchor="right"
    onClose={onClose}
    PaperProps={{ sx: { ...paperStyle, boxShadow: "none" } }}
    {...props}
  >
    {children}
  </Drawer>
)
