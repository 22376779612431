import { VoidFn } from "@/utils/types"
import { useMemo, useRef } from "react"

const DEFAULT_RETRIES = 10
const DEFAULT_DELAY_MS = 250 // Initial delay of 250ms
const DEFAULT_MAX_DELAY_MS = 7000 // Maximum delay of 7 seconds

/**
 * Sleep for a number of milliseconds
 * @param ms
 */
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

/**
 * Enhances a function to be an async retry function with a delay between each attempt
 * Delay is exponential backoff with a maximum delay of 7 seconds
 * @param fn
 * @param retries
 * @param delay
 */
export const retryEnhancer = (fn: VoidFn, retries = DEFAULT_RETRIES, delay = DEFAULT_DELAY_MS) => {
  let attempts = 0
  let lastError: unknown | null = null
  return async () => {
    while (attempts < retries) {
      try {
        attempts = attempts + 1
        return await fn()
      } catch (error) {
        lastError = error
        const backoffDelay = Math.min(delay * Math.pow(2, attempts - 1), DEFAULT_MAX_DELAY_MS)
        await sleep(backoffDelay)
      }
    }
    if (!lastError) {
      lastError = Error("Retry backoff failed")
    }
    throw lastError
  }
}

/**
 * Enhances a function to retry a number of times with a delay between each attempt
 * @param fn
 * @param retries
 * @param delay
 */
export const useAsyncRetry = (fn: VoidFn, retries = DEFAULT_RETRIES, delay = DEFAULT_DELAY_MS) => {
  const fnRef = useRef<VoidFn>()
  fnRef.current = fn
  return useMemo(() => retryEnhancer(() => fnRef.current?.(), retries, delay), [retries, delay])
}
