import { TakeCommandLogo } from "@/components/Branding"
import { EmailTextField, PasswordTextField } from "@/components/TextFields"
import { mfaEmailCodeSend } from "@/features/Auth/pages/Mfa/mfaService"
import { useEmailSendCodeStore } from "@/features/Auth/pages/Mfa/VerifyEmailCodeMfa"
import { useAuth } from "@/features/Auth/useAuth"
import { useNotifications } from "@/services/notificationService"
import { validateEmail } from "@/utils/validations"
import { Button, Checkbox, CircularProgress, FormControlLabel, Link, Typography } from "@mui/material"
import { Formik } from "formik"
import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { NOT_AUTHORIZED, USER_NOT_FOUND } from "../authConstants"
import { GuestGuard } from "../guards/GuestGuard"
import { AuthLayout, AuthWrapper } from "./AuthLayout"

const initialValues = {
  email: "",
  password: "",
  submit: false,
}

const validationSchema = Yup.object().shape({
  email: validateEmail,
})

const SignInPanel = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { signIn } = useAuth()
  const { notify } = useNotifications("sign-in")
  const { markLastSentCode } = useEmailSendCodeStore()
  const [rememberMe, setRememberMe] = useState(false)
  const state = location.state ?? {}
  const toggleRememberMe = () => setRememberMe(remember => !remember)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.email, values.password, rememberMe)
          setStatus({ success: true })
          navigate("/mfa/select", {
            state: {
              ...state,
              isLoggedIn: true,
            },
          })
        } catch (error: any) {
          if (error.code === USER_NOT_FOUND || error.code === NOT_AUTHORIZED) {
            setStatus({ success: false })
            setErrors({ submit: error })
            notify("Incorrect email or password", "error")
            return
          }
          if (error.mfaSetup) {
            navigate("/mfa/select", {
              state: {
                ...state,
                email: values.email,
                password: values.password,
                rememberMe,
              },
            })

            return
          }
          if (error.emailMfaRequired) {
            // trigger it without waiting for the promise to resolve, we don't want to stop the screen transition of the navigation
            mfaEmailCodeSend(values.email, values.password).catch(console.error)
            markLastSentCode()
            navigate("/mfa/verify/code", {
              state: {
                ...state,
                email: values.email,
                password: values.password,
                rememberMe,
              },
            })

            return
          }
          if (error.totpRequired) {
            navigate("/mfa/verify", {
              state: {
                ...state,
                email: values.email,
                password: values.password,
                rememberMe,
              },
            })

            return
          }
          if (error.mfaRequired) {
            navigate("/mfa/verify/sms", {
              state: {
                ...state,
                email: values.email,
                password: values.password,
                rememberMe,
              },
            })

            return
          }
          const message = error.message || "Something went wrong"

          setStatus({ success: false })
          setErrors({ submit: message })
          notify(`${message}`, "error")
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ errors, handleSubmit, isSubmitting }) => (
        <form noValidate onSubmit={handleSubmit} data-qa="sign-in-form">
          <EmailTextField sx={{ marginBottom: 4 }} />
          <PasswordTextField />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" onClick={toggleRememberMe} />}
            label={<Typography variant="caption">Remember me</Typography>}
            data-qa="remember-me-checkbox"
          />
          {/* FUTURE: Consider replacing the following with ContinueNavigationButton */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            data-qa="sign-in-button"
            sx={{ mt: 3 }}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            Sign in
          </Button>
          <Link
            href="/forgot-password"
            variant="caption"
            mt="1rem"
            display="flex"
            justifyContent="center"
            data-qa="forgot-password-link"
          >
            Forgot password
          </Link>
        </form>
      )}
    </Formik>
  )
}

export const SignIn = () => (
  <GuestGuard>
    <AuthLayout>
      <TakeCommandLogo />
      <AuthWrapper>
        <Helmet title="Sign In" />

        <Typography data-qa="form-title" variant="h4" align="center" gutterBottom>
          Welcome
        </Typography>
        <Typography data-qa="form-subtitle" variant="body1" align="center" gutterBottom mb={6}>
          Sign in to your account to continue
        </Typography>
        <SignInPanel />
      </AuthWrapper>
    </AuthLayout>
  </GuestGuard>
)
