import { CheckBoxGroupCard } from "@/components/CheckBoxGroupCard"
import { Grid, Typography } from "@mui/material"

interface MultiSelectClassSectionProps {
  title: string
  formName: string
  handleChange: (field: string, value: any) => void
  elements: { title: string; value: string }[]
  errors?: { [key: string]: string }
  data?: { label: string; value: string }[]
  dataQa: string
}

export const MultiSelectClassSection = ({
  title,
  formName,
  handleChange,
  elements,
  errors = {},
  dataQa,
}: MultiSelectClassSectionProps) => (
  <Grid item xs={12} mt={3}>
    <Typography variant="h6" textAlign="left" data-qa={dataQa}>
      {title}
    </Typography>
    <Grid item xs={12} mt={5}>
      <CheckBoxGroupCard formName={formName} handleChange={handleChange} elements={elements} error={false} />
    </Grid>
  </Grid>
)
