import { PermissionGuard } from "@/features/Auth/guards/PermissionGuard"
import { useAuth } from "@/features/Auth/useAuth"
import { colors, takeCommandPrimary } from "@/theme/palette"
import { createDataQa, WithDataQa } from "@/utils/dataQa"
import CheckIcon from "@mui/icons-material/Check"
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuList,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { SyntheticEvent, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import { ClearableSearchInput } from "../ClearableSearchInput/ClearableSearchInput"
export interface NavbarUserDropdownProps {
  hideProfileItem?: boolean
  associatedCompanies?: any[]
  activeCompanyId?: string
  isLoadingAssociatedCompanies?: boolean
  labelRole?: string
  onCompanySelect?: (id: string) => void
}

interface UserGreetingProps {
  name?: string
}

const UserGreeting = ({ name }: UserGreetingProps) => (
  <Grid item sx={{ paddingLeft: 2 }}>
    <Typography
      variant="body1"
      sx={theme => ({
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      })}
    >
      Hello, {name}
    </Typography>
  </Grid>
)

const NavMenuItem = ({ className = "", ...props }: WithDataQa<MenuItemProps>) => (
  <MenuItem className={`${className} tch-menu-item`.trimStart()} {...props} />
)
interface CompanySwitcherProps {
  associatedCompanies: any[]
  activeCompanyId?: string
  isLoadingAssociatedCompanies?: boolean
  labelRole?: string
  closeMenu: () => void
  onCompanySelect?: (id: string) => void
}
const CompanySwitcher = ({
  associatedCompanies,
  activeCompanyId,
  isLoadingAssociatedCompanies,
  labelRole,
  closeMenu,
  onCompanySelect,
}: CompanySwitcherProps) => {
  const [searchInputValue, setSearchInputValue] = useState<string>("")
  return (
    <MenuList>
      <Divider variant="middle" sx={{ mb: 4 }} />
      <ListItem sx={{ mt: 3 }}>
        <Typography variant="body1bold">Organizations</Typography>
      </ListItem>
      <ListItem>
        <ClearableSearchInput
          onChange={event => setSearchInputValue(event.target.value)}
          handleClearClick={() => setSearchInputValue("")}
          data-qa="account-search-input"
          sx={{ width: "100%" }}
          onKeyDown={e => e.stopPropagation()}
        />
      </ListItem>
      {isLoadingAssociatedCompanies ? (
        <>
          <Skeleton variant="text" sx={{ fontSize: "1rem", m: 4 }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", m: 4 }} />
        </>
      ) : (
        associatedCompanies
          ?.filter(associatedCompany => associatedCompany.name?.toLowerCase().includes(searchInputValue.toLowerCase()))
          .map(associatedCompany => (
            <>
              <NavMenuItem
                key={associatedCompany.id}
                data-qa={createDataQa("avatar-switch-company")}
                onClick={() => {
                  onCompanySelect?.(associatedCompany.id)
                  closeMenu()
                }}
              >
                {activeCompanyId === associatedCompany.id && (
                  <ListItemIcon sx={{ pr: 2 }}>
                    <CheckIcon />
                  </ListItemIcon>
                )}
                <ListItemText>
                  <Typography
                    sx={{
                      color: activeCompanyId === associatedCompany.id ? colors.darkBody : takeCommandPrimary.main,
                      fontWeight: activeCompanyId === associatedCompany.id ? 700 : null,
                    }}
                  >
                    {associatedCompany.name}
                    {labelRole}
                  </Typography>
                </ListItemText>
              </NavMenuItem>
              <Divider variant="middle" />
            </>
          ))
      )}
    </MenuList>
  )
}

export const NavbarUserDropdown = ({
  hideProfileItem,
  onCompanySelect,
  associatedCompanies,
  activeCompanyId,
  labelRole,
}: NavbarUserDropdownProps) => {
  const [anchorMenu, setAnchorMenu] = useState<any>(null)
  const navigate = useNavigate()
  const { isLoggedIn, signOut, displayName, displayInitial } = useAuth()
  const { shutdown } = useIntercom()
  const queryClient = useQueryClient()
  const isAdminPage = location.pathname.startsWith("/admin")

  const toggleMenu = (event: SyntheticEvent) => {
    setAnchorMenu(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorMenu(null)
  }

  const handleSignOut = async () => {
    queryClient.clear()
    shutdown()
    await signOut()
    navigate("/sign-in", { replace: true })
  }

  return (
    <>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="medium"
          sx={theme => ({
            borderRadius: "0.5rem",
            color: theme.sidebar.color,
            "&:hover": {
              backgroundColor: theme.sidebar.hover,
              ".MuiTypography-root": {
                color: theme.sidebar.hoverText,
              },
            },
          })}
        >
          <Avatar
            data-qa="header-avatar"
            sx={theme => ({
              color: theme.palette.colors.white,
              background: theme.palette.primary.main,
            })}
          >
            {displayInitial}
          </Avatar>
          {isLoggedIn && <UserGreeting name={displayName} />}
        </IconButton>
      </Tooltip>
      <Menu id="menu-appbar" anchorEl={anchorMenu} open={!!anchorMenu} onClose={closeMenu} sx={{ width: "100%" }}>
        {isLoggedIn ? (
          <Box sx={{ minWidth: "18.65rem" }}>
            <ListItemText sx={{ pb: 2, pl: 4 }}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ textAlign: "left", paddingRight: 12 }} variant="h5">
                  {displayName}
                </Typography>
                <PermissionGuard
                  internalOnly
                  in={[
                    "tc_hub_users",
                    "tc_hub_companies",
                    "tc_hub_autopay",
                    "tc_hub_compliance",
                    "tc_hub_carriers",
                    "tc_hub_site_settings",
                  ]}
                  matchType="some"
                >
                  {!isAdminPage ? (
                    <Typography style={{ textAlign: "right", padding: "0 1.2rem 0 1.2rem" }} variant="subtitle1">
                      <Link
                        style={{ cursor: "pointer" }}
                        data-qa="tc-hub-link"
                        onClick={() => {
                          closeMenu()
                          navigate("/admin/users")
                        }}
                      >
                        TC Hub
                      </Link>
                    </Typography>
                  ) : (
                    <Typography style={{ textAlign: "right", padding: "0 1.2rem 0 1.2rem" }} variant="subtitle1">
                      <Link
                        style={{ cursor: "pointer" }}
                        data-qa="exit-tc-hub-link"
                        onClick={() => {
                          closeMenu()
                          navigate("/")
                        }}
                      >
                        Exit TC Hub
                      </Link>
                    </Typography>
                  )}
                </PermissionGuard>
              </Box>
              <Typography variant="caption">{labelRole}</Typography>
            </ListItemText>
            <Divider variant="middle" sx={{ mb: 2 }} />
            {!hideProfileItem && (
              <NavMenuItem
                data-qa={createDataQa("avatar-profile")}
                onClick={() => {
                  closeMenu()
                  navigate("/profile")
                }}
                sx={{ color: takeCommandPrimary.main }}
              >
                <ListItemText>Profile</ListItemText>
              </NavMenuItem>
            )}
            {associatedCompanies && associatedCompanies.length > 1 && (
              <CompanySwitcher
                closeMenu={closeMenu}
                onCompanySelect={onCompanySelect}
                associatedCompanies={associatedCompanies}
                activeCompanyId={activeCompanyId}
              />
            )}
            <NavMenuItem
              className="tch-menu-item"
              data-qa={createDataQa("avatar-sign-out")}
              onClick={handleSignOut}
              sx={{ color: takeCommandPrimary.main, mt: 2 }}
            >
              <ListItemText>Sign out</ListItemText>
            </NavMenuItem>
          </Box>
        ) : (
          <NavMenuItem className="tch-menu-item" data-qa={createDataQa("signin")} onClick={() => navigate("/sign-in")}>
            Sign In
          </NavMenuItem>
        )}
      </Menu>
    </>
  )
}
