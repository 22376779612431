import { LOWER_AGE_LIMIT, MAXIMUM_CURVE, MINIMUM_CURVE_ADULT, MINIMUM_CURVE_CHILD } from "@/agecurve"
import { formatDollars } from "@/utils/formatting"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import {
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  OutlinedInputProps,
  Stack,
  Typography,
} from "@mui/material"
import { useFormikContext } from "formik"
import { Dispatch, forwardRef, SetStateAction } from "react"
import { IMaskInput } from "react-imask"
import { AmountAgesTable } from "../features/CreateCompany/components/common/AmountAgesTable"
import { PLAN_STRUCTURE_INITIAL_VALUES } from "../features/CreateCompany/components/Steps/Setup/PlanStructure/PlanStructure"
import { PART_TIME_HOURS_MAXIMUM, PART_TIME_HOURS_MINIMUM } from "../features/CreateCompany/createCompanyConstants"

const AmountMaskInput = forwardRef<
  HTMLElement,
  {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
    max: number
    useMax: boolean
  }
>(({ onChange, max, ...props }, ref) => (
  <IMaskInput
    {...props}
    mask={Number}
    scale={2}
    thousandsSeparator=","
    min={0}
    max={max}
    radix="."
    inputRef={ref as never}
    onAccept={value =>
      onChange({
        target: {
          name: props.name,
          value,
        },
      })
    }
    overwrite
  />
))

type AmountTextFieldProps = OutlinedInputProps & {
  touched: boolean
  errorString: string
  maxAmount?: number
}

export const AmountTextField = ({
  touched,
  value = "",
  endAdornment,
  name,
  label,
  errorString,
  maxAmount = 100000,
  sx = {},
  ...props
}: AmountTextFieldProps) => (
  <FormControl fullWidth sx={{ mt: 3, ...sx }}>
    <InputLabel htmlFor="outlined-adornment-amount">{label}</InputLabel>
    <Stack direction="row" alignItems="center" spacing={5}>
      <OutlinedInput
        fullWidth
        name={name}
        id={`outlined-adornment-amount-${value}`}
        inputComponent={AmountMaskInput as never}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        label={label}
        data-qa={`amount-textField-${name}`}
        inputProps={{ "data-qa": `amount-input-${name}`, name, max: maxAmount }}
        value={value}
        {...props}
      />
      {endAdornment}
    </Stack>
    {touched && errorString && <FormHelperText error>{errorString}</FormHelperText>}
  </FormControl>
)

type AmountTextFieldWithAgesTableProps = OutlinedInputProps & {
  errorString: string
  agesTableOpen: boolean
  setAgesTableOpen: Dispatch<SetStateAction<boolean>>
}

export const SingleEmployeeAmountTextFieldWithTable = ({
  agesTableOpen,
  errorString,
  onChange,
  onBlur,
  setAgesTableOpen,
}: AmountTextFieldWithAgesTableProps) => {
  const { values, touched } = useFormikContext<typeof PLAN_STRUCTURE_INITIAL_VALUES>()

  return (
    <>
      <Grid container display="flex">
        <Grid item xs={12}>
          <Typography variant="body1">Here you can see your generated reimbursement roles by age:</Typography>
        </Grid>
        <Stack
          direction="row"
          spacing={2}
          display="flex"
          ml="auto"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => setAgesTableOpen(!agesTableOpen)}
        >
          {agesTableOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <Link ml="auto" alignItems="center" color="black">
            <Typography variant="body2" alignItems="center">
              Expand
            </Typography>
          </Link>
        </Stack>
      </Grid>
      <Grid container item xs={12} display="flex" direction="row" alignItems="center" mt={3}>
        <AmountTextField
          error={Boolean(touched && errorString)}
          touched={touched.employeeAmount!}
          errorString={errorString}
          onChange={onChange}
          onBlur={onBlur}
          value={values.employeeAmount}
          label="Employee - Age 21"
          name="employeeAmount"
          endAdornment={
            <>
              <Chip
                label={
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2" fontWeight={600}>
                      {`Minimum Age ${LOWER_AGE_LIMIT}:`}
                    </Typography>
                    <Typography variant="body2">
                      {formatDollars(Number(values.employeeAmount.replace(/,/g, "")) * MINIMUM_CURVE_ADULT.amount)}
                    </Typography>
                  </Stack>
                }
                sx={{ borderRadius: "1.25rem" }}
                variant="outlined"
              />
              <Chip
                label={
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2" fontWeight={600}>
                      {`Top Age ${MAXIMUM_CURVE.age}:`}
                    </Typography>
                    <Typography variant="body2">
                      {formatDollars(Number(values.employeeAmount.replace(/,/g, "")) * MAXIMUM_CURVE.amount)}
                    </Typography>
                  </Stack>
                }
                sx={{ borderRadius: "1.25rem" }}
                variant="outlined"
              />
            </>
          }
        />
      </Grid>
      <AmountAgesTable agesTableOpen={agesTableOpen} isAgeAndFamily={false} />
    </>
  )
}

type AmountTextFieldWithChipsProps = Omit<AmountTextFieldWithAgesTableProps, "agesTableOpen" | "setAgesTableOpen"> & {
  value?: string
  dependent?: boolean
  touched: boolean
}

export const AmountTextFieldWithChips = ({ value = "", dependent, ...props }: AmountTextFieldWithChipsProps) => {
  const { handleBlur, handleChange } = useFormikContext<typeof PLAN_STRUCTURE_INITIAL_VALUES>()
  const curve = dependent ? MINIMUM_CURVE_CHILD : MINIMUM_CURVE_ADULT

  return (
    <Grid item xs={12} display="flex" alignItems="center" mt={5}>
      <AmountTextField
        endAdornment={
          <>
            <Chip
              label={
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2" fontWeight={600}>
                    {`Minimum Age ${curve.age}:`}
                  </Typography>
                  <Typography variant="body2">
                    {formatDollars(Number(value.replace(/,/g, "")) * curve.amount)}
                  </Typography>
                </Stack>
              }
              sx={{ borderRadius: "1.25rem" }}
              variant="outlined"
            />
            <Chip
              label={
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2" fontWeight={600}>
                    {`Top Age ${MAXIMUM_CURVE.age}:`}
                  </Typography>
                  <Typography variant="body2">
                    {formatDollars(Number(value.replace(/,/g, "")) * MAXIMUM_CURVE.amount)}
                  </Typography>
                </Stack>
              }
              sx={{ borderRadius: "1.25rem" }}
              variant="outlined"
            />
          </>
        }
        error={Boolean(props.touched && props.errorString)}
        touched={props.touched}
        errorString={props.errorString}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        label={props.label}
        name={props.name}
      />
    </Grid>
  )
}

const HoursAmountMaskInput = forwardRef<
  HTMLElement,
  {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
  }
>(({ onChange, ...props }, ref) => (
  <IMaskInput
    {...props}
    mask="num"
    blocks={{
      num: {
        mask: Number,
        thousandsSeparator: ",",
        min: PART_TIME_HOURS_MINIMUM,
        max: PART_TIME_HOURS_MAXIMUM,
        normalizeZeros: true,
      },
    }}
    scale={0}
    inputRef={ref as never}
    onAccept={value =>
      onChange({
        target: {
          name: props.name,
          value,
        },
      })
    }
    overwrite
  />
))

export const HoursPerWeekAmountTextField = ({
  touched,
  value,
  name,
  label,
  error,
  errorString,
  onChange,
  onBlur,
}: AmountTextFieldProps) => (
  <FormControl fullWidth sx={{ mt: 3 }}>
    <InputLabel htmlFor="outlined-adornment-amount">{label}</InputLabel>
    <OutlinedInput
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={error}
      id={`outlined-adornment-amount-${value}`}
      inputComponent={HoursAmountMaskInput as never}
      label={label}
      data-qa="hours-per-week-amount-textField"
      inputProps={{ "data-qa": `hours-per-week-amount-input-${name}`, name }}
    />
    {touched && errorString && <FormHelperText error>{errorString}</FormHelperText>}
  </FormControl>
)

// FUTURE:
// There is a large amount of duplicated code in this file.
// We can greatly improve this by recomposing these components to reuse more of what is shared
