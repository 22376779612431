import { AuthUser } from "@/features/Auth/authTypes"
import { useCompanyDocDownloadUrl, useCreateAndExecuteServiceAgreement } from "@/features/Documents/documentsService"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material"
import {
  DocumentLoadEvent,
  PageChangeEvent,
  Worker as PdfWorker,
  SpecialZoomLevel,
  Viewer,
} from "@react-pdf-viewer/core"
import "@react-pdf-viewer/core/lib/styles/index.css"
import { useState } from "react"

interface ServicesAgreementModalProps {
  isOpen: boolean
  showClose?: boolean
  user?: AuthUser
  documentId?: string
  companyId: string
  isAdmin: boolean
  isEmployee: boolean
  documentName?: string
  onSuccessfulAcknowledgement: () => void
  onClose: () => void
}

const dataQa = "service-agreement-modal"

const serviceAgreementModalLabel = `
  I acknowledge that I have read and agree with the Services Agreement or any applicable Order Forms. 
  I understand that this Services Agreement or applicable Order Form replaces any prior agreements 
  regarding the same subject matter entered into between the Parties.
`
export const ServicesAgreementModal = ({
  isOpen,
  showClose,
  user,
  documentId,
  companyId,
  isAdmin,
  isEmployee,
  documentName,
  onSuccessfulAcknowledgement,
  onClose,
}: ServicesAgreementModalProps) => {
  const { notify } = useNotifications("acknowledge-company-doc")
  const [isServicesAgreementAcknowledged, setIsServicesAgreementAcknowledged] = useState(false)
  const [isCompleteDocumentRead, setIsCompleteDocumentRead] = useState(false)
  const [totalPageCount, setTotalPageCount] = useState(0)
  const { mutateAsync: createAndExecuteServiceAgreement, isPending } = useCreateAndExecuteServiceAgreement()

  const {
    data: url,
    isLoading: isLoadingDocument,
    isError,
  } = useCompanyDocDownloadUrl(companyId, documentId, isAdmin, isEmployee)

  const handlePageChange = (e: PageChangeEvent) => {
    if (totalPageCount > 0 && e.currentPage === totalPageCount - 1) {
      setIsCompleteDocumentRead(true)
    }
  }

  const handleDocumentLoad = (e: DocumentLoadEvent) => {
    setTotalPageCount(e.doc.numPages)
  }

  const onAgreeButtonClicked = async () => {
    try {
      await createAndExecuteServiceAgreement({ userInfo: user!, documentId })
      onSuccessfulAcknowledgement()
      notify("Document acknowledged successfully", "success")
      onClose()
    } catch (exception) {
      console.error(exception)
      notify(
        "An error occurred when recording acknowledgement of the Services Agreement. Please try again later.",
        "error"
      )
    }
  }

  const showTooltip = !!url && !isCompleteDocumentRead

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="service-agreement-dialog-title" sx={{ mb: 3 }} variant="h3tiempos">
        {documentName}
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ height: "60vh" }} data-qa={createDataQa(dataQa, "pdf-viewer")}>
          {isLoadingDocument ||
            (isError && (
              <Box
                sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}
              >
                {isLoadingDocument ? (
                  <CircularProgress size={64} />
                ) : (
                  <Typography>
                    We're having trouble retrieving the document right now. Please try again later.
                  </Typography>
                )}
              </Box>
            ))}

          {url && (
            <PdfWorker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <Viewer
                fileUrl={url}
                defaultScale={SpecialZoomLevel.PageWidth}
                onPageChange={handlePageChange}
                onDocumentLoad={handleDocumentLoad}
              />
            </PdfWorker>
          )}
        </Box>
        <Grid container mt={8}>
          <Grid item xs={12} mx={4}>
            <Tooltip
              title={showTooltip ? <Typography>Scroll to the bottom to acknowledge</Typography> : ""}
              placement="top"
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isServicesAgreementAcknowledged}
                      data-qa={createDataQa(dataQa, "checkbox")}
                      disabled={!isCompleteDocumentRead}
                      onChange={() => setIsServicesAgreementAcknowledged(!isServicesAgreementAcknowledged)}
                    />
                  }
                  label={serviceAgreementModalLabel}
                />
              </FormGroup>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mt: 4, gap: 4 }}>
            <Button
              variant="contained"
              disabled={!isServicesAgreementAcknowledged || isPending}
              sx={{ px: 8 }}
              data-qa={createDataQa(dataQa, "acknowledge-button")}
              onClick={onAgreeButtonClicked}
              startIcon={isPending && <CircularProgress size={20} />}
            >
              I agree
            </Button>

            {showClose && (
              <Button
                variant="outlined"
                sx={{ px: 8 }}
                data-qa={createDataQa(dataQa, "close-button")}
                onClick={onClose}
              >
                <span>Close</span>
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
