import {
  EnrollmentTimePeriod,
  HealthBenefitsElection,
  ShoppingSession,
} from "@/features/BenefitsElection/benefitsElectionTypes"
import { UsaState } from "@/utils/States"
import { Cents, Property, Role, Uuid } from "@/utils/types"
import { ComplianceFrequency } from "../CreateCompany/components/Steps/Setup/PlanSetup/planSetupTypes"
import { CompanyModel } from "../CreateCompany/createCompanyEndpoints"
import { BusinessStructure, Industry } from "../CreateCompany/createCompanyTypes"
import { FundingStatus } from "../Funding/fundingTypes"
import { EmploymentModel, PeopleStatus, PersonModel } from "../People/peopleTypes"
import {
  BOOLEAN,
  CARRIER_METADATA_ENROLLMENT_VALUES,
  CARRIER_QUESTION_FIELD_TYPE,
  CARRIER_QUESTION_SCOPE,
  ENTITY_TYPES,
  EXCHANGE_STATUSES,
  FUNDING_EVENT_STATUSES,
  GROUP,
  PAYMENT_OPTIONS_VALUES,
  RECURRING_PREMIUM_STATUS,
} from "./tcHubConstants"

export type EntityType = Property<typeof ENTITY_TYPES>

export type FundingEventStatus = Property<typeof FUNDING_EVENT_STATUSES>

export type RecurringPremiumStatus = Property<typeof RECURRING_PREMIUM_STATUS>

export type CarrierQuestionFieldType = Property<typeof CARRIER_QUESTION_FIELD_TYPE>
export type CarrierQuestionScope = Property<typeof CARRIER_QUESTION_SCOPE>
export type CarrierMetadataEnrollmentOptions = Property<typeof CARRIER_METADATA_ENROLLMENT_VALUES>
export type PaymentOptionsType = Property<typeof PAYMENT_OPTIONS_VALUES>

export interface JournalEntryRequest {
  activityCategory: string
  fromAccountCategory: string
  toAccountCategory: string
  companyId: Uuid
  companyFundingEventId: Uuid
  vendorTransactionId: string
  vendorWebhookTransactionId: string
  vendorWebhookTransactionTimestampUtc: string
  entryTimestampUtc: string
  recurringPremiumId: Uuid
  activityComments: string
  amountCents: Cents
  premiumMonth: number
  premiumYear: number
}

export interface JournalEntryResponse {
  id: Uuid
}

export interface FundsTransferRequest {
  fromEntityId: string
  toEntityId: string
  description: string
  amountCents?: number
  fundingEventPremiumAmounts?: {
    amountCents: number
    recurringPremiumId: string
    premiumMonth: number
    premiumYear: number
  }[]
}

export interface FundsTransferResponse {
  fundingTransferId: string
}

export interface AutoPayAccountTableHeader {
  id: string
  label: string
  sortable?: boolean
  alignment?: "center" | "left" | "right"
  field?: keyof FundingEntityModel | ((row: FundingEntityModel) => string)
}

export interface AccountTokenTableHeader {
  id: string
  label: string
  alignment?: "center" | "left" | "right"
  field: keyof AccountTokenRow | ((row: AccountTokenRow) => string)
}

export interface AccountTokenRow {
  status: string
  type: string
  vendorToken: string
}

export interface RegistrationTrackingTableHeader {
  id: string
  label: string
  sortable?: boolean
  alignment?: "center" | "left" | "right"
  field?: keyof RegistrationTrackingModel | ((row: RegistrationTrackingModel) => string)
}

export interface RegistrationTrackingModel {
  id: string
  companyId: string
  companyName: string | undefined
  name: string
  registrationStatus: string
  microDepositStatus: string
}

export interface RegistrationTrackingDetailModel {
  companyId: string
  registrationStatus: string
  microDepositStatus: string
  vendorApplicationFormId: string
  vendorApplicationId: string
  vendorCustomerId: string
  microDepositAchReturnCode: string
  microDepositReturnDescription: string
  microDepositTimestamp: string
  reapplyCount: number
}

export interface FundingEventsTableHeader {
  id: string
  label: string
  sortable?: boolean
  alignment?: "center" | "left" | "right"
  field?: keyof FundingEventModel | ((row: FundingEventModel) => string)
}

export interface FundingEventModel {
  id: string
  companyId: string
  companyName: string | undefined
  name: string
  periodEndAt: string
  periodStartAt: string
  status: FundingEventStatus
  timePeriod: string
  timePeriodLabel: string
  reserveReplenishmentCents: Cents
  totalFundedAmountCents: Cents
  totalPremiumsCents: Cents
}

export interface FundingEventSummaryModel {
  id: string
  status: FundingEventStatus
  numberOfEmployees: number
  replenishReserveAmountCents: number
  totalFundingAmountCents: number
  premiumAmounts: {
    premiumMonth: number
    premiumYear: number
    amountCents: number
    recurringPremiumId: string
  }[]
  feesAmountCents: number
  balanceSnapshotCents: number
  companyId: string
  timePeriod: string
  timePeriodLabel: string
  periodStartAt: string
  periodEndAt: string
  expectedFundingAt: string
}

export interface FundingEventUpdate {
  timePeriod: string
  timePeriodLabel: string
  periodStartAt: string
  periodEndAt: string
  status: FundingEventStatus
  expectedFundingAt: string
}

export interface FundingEventsResponse {
  companyFundingEvents: FundingEventModel[]
}

export interface CreateFundingEventRequest {
  companyId: string
  timePeriod: string
  timePeriodLabel: string
  periodStartAt: string
  periodEndAt: string
  status: FundingEventStatus
}

export interface CreateFundingEventResponse {
  fundingEventId: string
}

export interface FundingEntityModel {
  fundingEntityId: string
  companyId: string
  name: string
  status: FundingStatus
  entityType: EntityType
  companyName: string | undefined
  accountLast4Digits: string
}

export interface FundingAccount {
  fundingEntityId: string
  name: string
  accountLast4Digits: string
  entityType: EntityType
}

export interface RecurringPremiumRequest {
  month: number
  year: number
  limit: number
  offset: number
}

export interface FundingEventPremiumTableHeader {
  id: string
  label: string
  sortable?: boolean
  alignment?: "center" | "left" | "right"
  field?: keyof FundingEventPremium | ((row: FundingEventPremium) => string)
}

export interface FundingEventPremium {
  premiumMonth: any
  premiumYear: any
  id: string
  companyId: string
  name: string
  premiumAmountCents: number
  fundingEntityName: string
}

export interface FundingEntityDetailModel {
  fundingEntityId: string
  vendorCustomerId: string
  vendorFundingSourceId: string
  routingNumber: string
  accountNumber: string
  fundingTokens: [
    {
      status: string
      type: string
      vendorToken: string
    },
  ]
  bankName: string
  tags: {
    companyId: string
    source: string
    entityType: string
  }
  statusModifiedReason: string
  companyId: string
  name: string
  status: FundingStatus
  entityType: EntityType
}

export interface AdminPersonModel extends PersonModel {
  serviceContact?: string
  billingContact?: string
  phoneNumber?: string
}

export interface PeopleReponse {
  people: PersonModel[]
  count: number
}

export interface PersonDetailModel {
  id: string
  email: string
  companyId: Uuid
  employmentId: string
  dateOfBirth: string
  address: string
}

export interface PersonDetailResponse {
  id: string
  email: string
  companyId: Uuid
  employmentId: string
  dateOfBirth: string
  firstName: string
  lastName: string
  middleName: string
  phoneNumber: {
    value: string
  }
  employments?: EmploymentModel[]
  preferredName: string
  addresses: Address[]
  companyAdministrators: CompanyAdministrator[]
  roles: Role[]
  status: PeopleStatus
  planId: string
}

interface Address {
  id: string
  type: string
  street1: string
  street2: string
  city: string
  state: UsaState
  zip: string
  county: string
}

interface CompanyAdministrator {
  roleId: string
  roleName: string
}

export interface UserRpDetailResponse {
  recurringPremiums: RecurringPremium[]
}

export interface UserShoppingSessionDetailsResponse {
  shoppingSessionDetails: ShoppingSession[]
}

export interface EnrollmentModel extends HealthBenefitsElection {
  createdAt: string
  updatedAt: string
  shoppingSession: {
    id: string
    shoppingStatus: string
    employmentId: string
    planYear: number
    monthlyAllowanceCents: number
    enrollmentTimePeriod: EnrollmentTimePeriod
  }
  sourceHealthBenefitElectionId: string
  employee?: {
    firstName: string
    lastName: string
    companyId: string
  } | null
  companyName?: string
}

export interface EnrollmentsTableHeader {
  id: string
  label: string
  sortable?: boolean
  alignment?: "center" | "left" | "right"
  field?: keyof EnrollmentModel | ((row: EnrollmentModel) => string)
}

export interface UsersTableHeader {
  id: string
  label: string
  sortable?: boolean
  alignment?: "center" | "left" | "right"
  field?: keyof PersonModel | ((row: PersonModel) => string)
}

export interface CompaniesTableHeader {
  id: string
  label: string
  sortable?: boolean
  alignment?: "center" | "left" | "right"
  field?: keyof CompanyModel | ((row: CompanyModel) => string)
}

export interface BusinessUnitTableHeader {
  id: string
  label: string
  sortable?: boolean
  alignment?: "center" | "left" | "right"
  field?: keyof BusinessUnit | ((row: BusinessUnit) => string)
}

// Carriers
export interface CarrierUpdatesTableHeader {
  id: string
  label: string
  sortable?: boolean
  alignment?: "center" | "left" | "right"
  field?: keyof CarrierUpdate | ((row: CarrierUpdate) => string)
}

export interface CarrierIntegrationTableHeader {
  id: string
  label: string
  sortable?: boolean
  alignment?: "center" | "left" | "right"
  field?: keyof CarrierIntegration | ((row: CarrierIntegration) => string)
}

export interface ExchangeTableHeader {
  id: string
  label: string
  sortable?: boolean
  alignment?: "center" | "left" | "right"
}

export interface CarrierUpdate {
  id: string
  name: string
  state: string
  logoUrl: string
  status: string
  description: string
}

export interface CarrierToFilter {
  id: string
  name: string
  state: string
}

export type ExchangeStatus = Property<typeof EXCHANGE_STATUSES>

export interface CarrierIntegration {
  id: string
  name: string
  state: string
  bothExchangesLink?: string
  logoUrl: string
  bothExchangesIsAutoPay?: boolean | string
  onExchange?: boolean
  offExchange?: boolean
  bothExchanges?: boolean
  bothExchangesEnrollment?: string
  bothExchangesExtraStepDescription?: string
  overdraftFeeCents?: number
  payLater?: boolean | string
  crossStateCare?: boolean | string
  bothExchangesPremiumPullDate?: number
  bothExchangesCutOffDate?: number
  bothExchangesPaymentOption: PaymentOptionsType
  updatedAt?: Date
  offExchangeEnrollment?: string
  offExchangeExtraStepDescription?: string
  offExchangeLink?: string
  offExchangeIsAutoPay?: boolean | string
  offExchangePremiumPullDate?: number
  offExchangeCutOffDate?: number
  offExchangePaymentOption: PaymentOptionsType
  onExchangeEnrollment?: string
  onExchangeExtraStepDescription?: string
  onExchangeLink?: string
  onExchangeIsAutoPay?: boolean | string
  onExchangePremiumPullDate?: number
  onExchangeCutOffDate?: number
  onExchangePaymentOption: PaymentOptionsType
}

export interface EnrollmentContainerFormikValues {
  name: string
  value?: string | number | readonly string[] | boolean
  error?: string
  touched?: boolean
}

export interface EnrollmentContainerSelectFieldValues {
  name: string
  value?: string | number | readonly string[]
  error?: string
  touched?: boolean
}

export interface EnrollmentContainerValues {
  enrollment: EnrollmentContainerSelectFieldValues
  extraStepDescription: EnrollmentContainerSelectFieldValues
  link: EnrollmentContainerFormikValues
  cutOffDate: EnrollmentContainerFormikValues
  premiumPullDate: EnrollmentContainerFormikValues
  isAutoPay: EnrollmentContainerFormikValues
  paymentOption: EnrollmentContainerFormikValues
}

export interface CarrierFilter {
  names: string[]
  states: string[]
  onExchange?: boolean
  offExchange?: boolean
  bothExchanges?: boolean
  payLater?: boolean
  crossStateCare?: boolean
  premiumPullDate?: number
  cutOffDate?: number
  enrollment?: CarrierMetadataEnrollmentOptions
}

export interface CarrierPreSignUrlResponse {
  url: string
}

export interface CarrierQuestion {
  id?: string
  carrierId: string
  question: string
  fieldType: CarrierQuestionFieldType
  scope: CarrierQuestionScope
  webmergeKey?: string
  states: string[]
  parentAnswerForVisibility?: string
  parentQuestionId?: string
  answerChoices: string[]
  lastUpdated?: string
  childQuestions?: CarrierQuestion[]
}

interface CarrierQuestionIdentifiers {
  id: string
  carrierId: string
}

export type CreateCarrierQuestionRequest = Omit<CarrierQuestion, keyof CarrierQuestionIdentifiers>

export const emptyCarrierQuestion: CarrierQuestion = {
  carrierId: "",
  question: "",
  id: undefined,
  fieldType: BOOLEAN,
  scope: GROUP,
  states: [],
  answerChoices: [],
}

export const emptyCarrierToFilter: CarrierToFilter = {
  id: "",
  name: "",
  state: "",
}

export interface CarrierQuestionsTableHeader {
  id: string
  label: string
  alignment?: "center" | "left" | "right"
}

export interface ApplicationResponse {
  taskId: string
  successfulApplications: string[]
  totalApplications: string[]
}

export interface AdminUsersTableHeader {
  id: string
  label: string
  sortable?: boolean
  alignment?: "center" | "left" | "right"
  field?: keyof AdminPersonModel | ((row: AdminPersonModel) => string)
}

export interface CompanyDetailsModel {
  companyName: string
  businessStructure: BusinessStructure
  companyType: string
  industry: Industry
  ein: string
  stripeId: string
  address: string
  suiteApt: string
  city: string
  zip: string
  state: UsaState
  county: string
}

export interface HraPlanModelDetails {
  companyId: Uuid
  complianceFrequency: ComplianceFrequency
  dayOfMonth: number
  hraEndDate: Date | string | null
  hraStartDate: Date | string | null
  hraType: string
  id: Uuid
  partTimeHourQualification: number
  seasonalMonthQualification: number
  statementCreationDate: Date | string | number
  statementFrequency: string
  willKeepGroupPlan: boolean
  willOfferHRA: boolean
  eligibleEmployees: number
  participatingEmployees: number
  isAutoPay: boolean
  packageName: string
  billingDate: Date | string | number
  reportDate: Date | string | number
  reportFrequency: Date | string | number
  mohtlyAmount: Cents
}

export interface RecurringPremiumsTableHeader {
  id: string
  label: string
  sortable?: boolean
  alignment?: "center" | "left" | "right"
  field?: keyof RecurringPremium | ((row: RecurringPremium) => string)
}

export interface RecurringPremium {
  id: string
  healthBenefitElectionId?: string
  companyId: string
  companyName: string | undefined
  employmentId: string
  personId: string
  employeeSubAccountId?: string
  enrolledAllowanceCents: Cents
  premiumAmountCents: number
  coverageStartDate: string
  coverageEndDate: string
  insuranceType: string
  firstName: string
  lastName: string
  status: RecurringPremiumStatus
  enrollmentType: string
  email: string
  carrierId?: string
  createdAt: string
}

export interface RecurringPremiumFilter {
  offset: number
  limit: number
  createdBefore?: string
  employeeName?: string
  employeeEmail?: string
  companyName?: string
  planYear?: string
  olderThan10Days?: boolean
  insuranceType?: string
}

export interface RecurringPremiumsResponse {
  complianceRecurringPremiums: RecurringPremium[]
  meta: Meta
}

export interface Meta {
  total: number
  hasNext: boolean
  cursorValue: string
}

export interface RecurringPremiumDetailModel {
  recurringPremiums: [
    {
      id: string
      companyId: string
      employmentId: string
      enrolledAllowanceCents: Cents
      premiumAmountCents: number
      coverageStartDate: Date
      coverageEndDate: Date
      insuranceType: string
      firstName: string
      lastName: string
      status: RecurringPremiumStatus
      easyEnroll: boolean
      email: string
      employeeSubAccountId: string
      coverages: [
        {
          id: string
          validFrom: string
          validTo: string
          documentId: string
          status: string
          primary: boolean
          createdAt: string
          createdBy: string
          updatedAt: string
          updatedBy: string
        },
      ]
      familyMembers: [
        {
          firstName: string
          lastName: string
          relation: string
          dateOfBirth: string
        },
      ]
    },
  ]
}

export interface RecurringPremiumUpdateRequest {
  recurringPremiums: RecurringPremiumUpdate[]
}

export interface RecurringPremiumUpdate {
  status: string | null
  coverageStartDate: string
  coverageEndDate: string
  insuranceType: string
  premiumAmountCents: number
  employmentId: string
}

export interface BusinessUnit {
  id: Uuid
  name: string
  assignedAmount?: string
  companyId?: string
}

export interface MigratedCompanySetDetails {
  companyName: string
  isMigrated: boolean
}
