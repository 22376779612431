import { useShoppingSession } from "@/features/BenefitsElection/benefitsElectionService"
import { BenefitsElectionStep } from "@/features/BenefitsElection/components/BenefitsElectionStep"

export const HealthNeeds = () => {
  const shoppingSessionId = useShoppingSession()
  const next = `/benefits-election/${shoppingSessionId}/find-plan/questions/plan-type`
  const previous = `/benefits-election/${shoppingSessionId}/my-info/household-income`

  return (
    <BenefitsElectionStep title="HealthNeeds" next={next} previous={previous} advanceOnSuccess>
      {/* FUTURE */}
    </BenefitsElectionStep>
  )
}
