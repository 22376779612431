import { takeCommandPrimary } from "@/theme/palette"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Box, Button, Typography } from "@mui/material"
import { AddPeopleStepProps } from "../addPersonValidations"

export const WellDoneMessageStep = ({ onClose }: AddPeopleStepProps) => (
  <Box
    sx={{
      p: 4,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 4,
    }}
  >
    <CheckCircleIcon sx={{ width: "2rem", height: "2rem", color: takeCommandPrimary.dark, mt: 4 }} />
    <Typography variant="h1" align="center">
      Well done!
    </Typography>
    <Typography align="center">Your new user has been added to your list.</Typography>
    <Button data-qa="close" variant="contained" onClick={() => onClose()} sx={{ mt: 4 }}>
      Close
    </Button>
  </Box>
)
