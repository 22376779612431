const maxLoadingTimeSeconds = import.meta.env.MAX_LOADING_TIME_SECONDS ?? 30
const maxLoadingTimeMilliseconds = maxLoadingTimeSeconds * 1000

export const appConfig = {
  appName: import.meta.env.VITE_APP_NAME || "HRA Hub",
  userPoolId:
    import.meta.env.VITE_COGNITO_USER_POOL_ID_PARAM_STORE?.trimStart() || import.meta.env.VITE_COGNITO_USER_POOL_ID,
  clientId: import.meta.env.VITE_COGNITO_CLIENT_ID_PARAM_STORE?.trimStart() || import.meta.env.VITE_COGNITO_CLIENT_ID,
  baseApiUrl: import.meta.env.VITE_BASE_API_URL,

  unitThemeUrl: import.meta.env.VITE_UNIT_THEME_URL,
  unitScriptUrl: import.meta.env.VITE_UNIT_SCRIPT_URL,
  unitBaseUrl: import.meta.env.VITE_UNIT_BASE_URL,

  maxLoadingTimeSeconds,
  maxLoadingTimeMilliseconds,

  isProduction: import.meta.env.VITE_TCH_ENV === "production",

  hrisClientId: import.meta.env.VITE_HRIS_CLIENT_ID,
  csvBoxLicense: import.meta.env.VITE_CSV_BOX_LICENSE,

  intercomAppId: import.meta.env.VITE_INTERCOM_APP_ID,
  tchEnv: import.meta.env.VITE_TCH_ENV,

  datadogApplicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  datadogClientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,

  configCatKey: import.meta.env.VITE_CONFIG_CAT_KEY,
  internalAdminCompanyId: import.meta.env.VITE_INTERNAL_ADMIN_COMPANY_ID,
} as const
