import { useNotifications } from "@/services/notificationService"
import { useMutation } from "@tanstack/react-query"
import { createHubSpotDeal, HubspotRequest } from "./messagingServiceEndPoints"

export const useCreateHubspotDeal = () => {
  const { notify } = useNotifications("deal-information")

  return useMutation({
    mutationFn: (payload: HubspotRequest) => createHubSpotDeal(payload),
    onSuccess: () => {
      notify("HubSpot Deal created successfully", "success")
    },
    onError: error => {
      notify("There was an error while creating HubSpot Deal", "error")
    },
  })
}
