import { ErrorBoundary } from "@/components/ErrorBoundary/ErrorBoundary"
import { ScreenViewport } from "@/components/ScreenViewport"
import { GlobalStyle } from "@/theme/GlobalStyle"
import { CacheProvider } from "@emotion/react"
import { CssBaseline } from "@mui/material"
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { ConfigCatProvider, createConsoleLogger, LogLevel, PollingMode } from "configcat-react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { Provider } from "react-redux"
import { useRoutes } from "react-router-dom"
import { IntercomProvider } from "react-use-intercom"
import { FloatingHelpButton } from "./components/FloatingHelpButton"
import { NotificationProvider } from "./components/Notifications/NotificationProvider"
import { appConfig } from "./config"
import { AuthProvider } from "./features/Auth/components/CognitoContext"
import "./i18n"
import { routes } from "./Routes"
import { ConfirmationProvider } from "./services/Confirmations"
import { store } from "./store"
import { createTheme } from "./theme"
import { createEmotionCache } from "./theme/createEmotionCache"
import { useTheme } from "./theme/useTheme"

const clientSideEmotionCache = createEmotionCache()

export const App = () => {
  const content = useRoutes(routes)
  const { theme } = useTheme()
  const intercomAppId = appConfig.intercomAppId
  const configCatKey = appConfig.configCatKey
  const logger = createConsoleLogger(LogLevel.Info)

  return (
    <CacheProvider value={clientSideEmotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Take Command Health"
          defaultTitle="Take Command | QSEHRA + ICHRA Administration Software"
        />
        <ConfigCatProvider sdkKey={configCatKey} pollingMode={PollingMode.AutoPoll} options={{ logger }}>
          <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MuiThemeProvider theme={createTheme(theme)}>
                <CssBaseline />
                <GlobalStyle />
                <ErrorBoundary>
                  <AuthProvider>
                    <ConfirmationProvider>
                      <NotificationProvider>
                        <IntercomProvider appId={intercomAppId}>
                          <ScreenViewport>
                            {content}
                            <FloatingHelpButton />
                          </ScreenViewport>
                        </IntercomProvider>
                      </NotificationProvider>
                    </ConfirmationProvider>
                  </AuthProvider>
                </ErrorBoundary>
              </MuiThemeProvider>
            </LocalizationProvider>
          </Provider>
        </ConfigCatProvider>
      </HelmetProvider>
    </CacheProvider>
  )
}
