import { THEMES } from "@/constants"
import { noop } from "lodash"
import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"

declare global {
  interface Window {
    _experimental_toggleTheme: () => void
  }
}

const initialState = {
  theme: THEMES.DEFAULT,
  toggleTheme: () => noop(),
  isDarkMode: false,
}

const ThemeContext = createContext(initialState)

interface ThemeProviderProps {
  children: ReactNode
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [storedTheme, _setStoredTheme] = useState<string>(initialState.theme)
  const location = useLocation()
  const isAdminPage = location.pathname.startsWith("/admin")

  useEffect(() => {
    const theme = localStorage.getItem("theme")

    if (theme) {
      _setStoredTheme(JSON.parse(theme))
    }
  }, [])

  const toggleTheme = useCallback(() => {
    const toggledTheme = storedTheme === THEMES.DEFAULT ? THEMES.DARK : THEMES.DEFAULT

    localStorage.setItem("theme", JSON.stringify(toggledTheme))
    _setStoredTheme(toggledTheme)
  }, [storedTheme])

  const contextValue = useMemo(
    () => ({
      theme: isAdminPage ? storedTheme : THEMES.DEFAULT,
      toggleTheme,
      isDarkMode: storedTheme === THEMES.DARK,
    }),
    [storedTheme, toggleTheme, isAdminPage]
  )

  // Expose toggleTheme globally
  // eslint-disable-next-line react-compiler/react-compiler
  window._experimental_toggleTheme = toggleTheme

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>
}

export { ThemeContext, ThemeProvider }
