import { StyledCard } from "@/components/StyledCard"
import { Highlighted, Typographify } from "@/components/Text"
import { ObscuredAccountNumberField } from "@/components/TextFields"
import { useAuth } from "@/features/Auth/useAuth"
import { AchDetails } from "@/features/Settings/types/paymentTypes"
import { useNotifications } from "@/services/notificationService"
import { Cents, Uuid } from "@/utils/types"
import { KeyboardArrowRight } from "@mui/icons-material"
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material"
import { isEmpty } from "lodash"
import { ReactNode, useEffect, useState } from "react"
import { RECURRING_REIMBURSEMENT, SELF_ENROLL_PURCHASE } from "../../benefitsElectionConstants"
import { useGetAchDetails, useIsCompanyAutoPay, useShoppingUrl } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { Plan } from "../../benefitsElectionTypes"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"
import { DetailsBanner } from "../findMyPlan/PlanDetailsDrawer"

interface SelfEnrollDescriptionProps {
  carrierName: string
}

export const SelfEnrollDescription = ({ carrierName }: SelfEnrollDescriptionProps) => (
  <>
    <Typography gutterBottom>
      This plan is sold by <Highlighted>{carrierName}</Highlighted>. You'll need to complete your purchase for this plan
      on their website. You
      <Typography variant="body1bold"> should not </Typography>receive tax credits for a self-enrolled health plan.
    </Typography>
    <Typography>
      Want something simpler? Select a plan marked
      <Highlighted> easy enroll </Highlighted>from our shopping page to avoid this step. With
      <Highlighted> easy enroll </Highlighted>plans, we'll handle the enrollment and payment process for you!
    </Typography>
  </>
)

interface IndexCarrierNameProps {
  index: number
  carrierName: string
}

interface SelfEnrollSectionProps {
  index: number
  title: ReactNode
  description?: ReactNode
  children?: ReactNode
}

interface IndexProps {
  index: number
}

const Index = ({ index }: IndexProps) => (
  <Avatar
    sx={theme => ({
      bgcolor: theme.palette.primary.main,
      mr: 2,
      width: "2rem",
      height: "2rem",
    })}
  >
    {index}
  </Avatar>
)

const SelfEnrollSection = ({ index, title, description, children }: SelfEnrollSectionProps) => (
  <Box py={4} width="100%">
    <Stack direction="row">
      <Index index={index} />
      <Box>
        <Typographify variant="h5" gutterBottom>
          {title}
        </Typographify>
        {description && <Typographify>{description}</Typographify>}
        {children}
      </Box>
    </Stack>
  </Box>
)

interface PayForPlanProps extends IndexCarrierNameProps {
  plan: Plan
  allowanceCents: Cents
  exchangeLink: string
}

interface PlanReviewCardProps {
  plan: Plan
  allowanceCents: Cents
}

const PlanReviewCard = ({ plan, allowanceCents }: PlanReviewCardProps) => (
  <StyledCard elevation={4} sx={{ pt: 2, pb: 0 }}>
    <DetailsBanner plan={plan} allowanceCents={allowanceCents} />
  </StyledCard>
)

const PayForPlan = ({ index, plan, carrierName, allowanceCents, exchangeLink }: PayForPlanProps) => (
  <SelfEnrollSection
    index={index}
    title="Sign up for your plan on the carrier's website"
    description={
      <>
        Please sign up for your plan purchase on <Highlighted>{carrierName}</Highlighted> website. Here are the details
        of your plan.
      </>
    }
  >
    <PlanReviewCard plan={plan} allowanceCents={allowanceCents} />
    <Button
      target="_blank"
      href={exchangeLink}
      variant="outlined"
      endIcon={<KeyboardArrowRight />}
      color="inherit"
      sx={{ mt: 8 }}
    >
      Go to plan website
    </Button>
  </SelfEnrollSection>
)

interface SetupAutoPayProps {
  index: number
  carrierName: string
  achDetails: AchDetails | undefined
  hasDetails: boolean
  isPending: boolean
  insufficientFunds: boolean
  areAchDetailsAllowed: boolean
}

const hasAchDetails = (achDetails: AchDetails | undefined) =>
  !isEmpty(achDetails?.accountNumber) && !isEmpty(achDetails?.routingNumber)

const SetupAutoPay = ({
  index,
  carrierName,
  achDetails,
  isPending,
  hasDetails,
  insufficientFunds,
  areAchDetailsAllowed,
}: SetupAutoPayProps) => (
  <SelfEnrollSection
    index={index}
    title="Set up recurring payments with AutoPay"
    description={
      <Stack>
        <Grid item xs={12}>
          <Typography sx={{ marginBottom: 6 }}>
            Once you've selected your self enroll plan on <Highlighted>{carrierName}</Highlighted>, input your employer
            provided payment information to set up recurring automatic payments. Employer Payment Information can be
            found below.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Card
            sx={{
              width: "30rem",
              padding: 4,
              minHeight: "14rem",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: areAchDetailsAllowed ? "flex-start" : "center",
              alignItems: areAchDetailsAllowed ? "flex-start" : "center",
            }}
          >
            {/* FUTURE: Fix this nested ternary problem */}
            {/* eslint-disable-next-line no-nested-ternary */}
            {isPending ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="primary" size="1.5rem" />
                <Typography textAlign="center">
                  Waiting on payment information to load. This may take up to 2 minutes.
                </Typography>
              </Box>
            ) : areAchDetailsAllowed ? (
              <>
                <Card sx={{ padding: 4, border: "1px solid", width: "100%", borderColor: "colors.lightBlack" }}>
                  <Typography variant="h6" textAlign="center">
                    Payment Information
                  </Typography>
                </Card>
                <Stack>
                  <Typography variant="body2" color="colors.lightGrayText" mt={2}>
                    TransPecos Bank
                  </Typography>
                </Stack>
                <ObscuredAccountNumberField
                  name="AccountNumber"
                  label="Account Number:"
                  value={achDetails?.accountNumber}
                />
                <ObscuredAccountNumberField
                  name="RoutingNumber"
                  label="Routing Number:"
                  value={achDetails?.routingNumber}
                />
              </>
            ) : (
              <Alert severity="error" sx={{ mb: 4, mt: 4 }}>
                {insufficientFunds
                  ? "ACH details are not available due to insufficient funds. Please contact Take Command for further assistance."
                  : "Unable to load AutoPay account information. Please contact Customer Service for assistance."}
              </Alert>
            )}
          </Card>
          {(isPending || hasDetails) && (
            <Box sx={{ width: "30rem", padding: 4, marginTop: -2 }}>
              <Typography variant="body2" textAlign="center" sx={{ fontWeight: 600, color: "colors.tailLights" }}>
                Do not share your payment information
              </Typography>
            </Box>
          )}
        </Grid>
      </Stack>
    }
  />
)

interface FinishSetupProps {
  index: number
  carrierName: string
}

const FinishSetup = ({ index, carrierName }: FinishSetupProps) => (
  <SelfEnrollSection
    index={index}
    title="After you purchase your plan, come back here. We'll verify your premium amount and make sure you're good to go"
    description={`After you purchase your plan on the ${carrierName} website, come back here. We'll finish setting up
  your automatic premium payments for your plan.`}
  />
)

export const SelfEnroll = () => {
  const { user } = useAuth()
  const companyId = user?.company?.companyId as Uuid
  const currentShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const currentBenefitElection = currentShoppingSession.healthBenefitElections?.[0]
  const electionId = currentBenefitElection?.id
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const planView = useBenefitsElectionStore(state => state.planView)
  // FUTURE: Remove these unsafe non-null assertions
  const selectedPlan = useBenefitsElectionStore(state => state.selectedPlan)!
  const allowance = useBenefitsElectionStore(state => state.allowance)!
  const shoppingUrl = useShoppingUrl()
  const [isAcknowledged, setIsAcknowledged] = useState(false)
  const exchangeLink = selectedPlan.exchangeLink
  const carrierName = selectedPlan.carrier.name
  const { isAutoPay } = useIsCompanyAutoPay(companyId)
  const { data: achDetails, isPending } = useGetAchDetails(electionId, isAutoPay, selectedPlan?.premiumAmountCents)
  const previous = shoppingUrl + planView
  const next = shoppingUrl + RECURRING_REIMBURSEMENT
  const payForPlanIndex = 1
  const setupAutoPayIndex = 2
  const finishSetupIndex = isAutoPay ? 3 : 2
  const { notify } = useNotifications("self-enroll-autopay")

  const hasDetails = hasAchDetails(achDetails)
  const insufficientFunds = hasDetails && !achDetails?.hasEnoughFunds
  const areAchDetailsAllowed = hasDetails && !!achDetails?.hasEnoughFunds

  useEffect(() => {
    if (isAutoPay && !isPending && !hasAchDetails(achDetails)) {
      notify("Unable to load AutoPay account information. Please contact Customer Service for assistance.", "error")
    }
  }, [isAutoPay, isPending, achDetails, notify])

  return (
    <BenefitsElectionStep
      title="Almost done! Complete your purchase on the health insurer's website"
      description={<SelfEnrollDescription carrierName={carrierName} />}
      previous={previous}
      next={next}
      disabled={(isAutoPay && (!hasAchDetails(achDetails) || !areAchDetailsAllowed)) || !isAcknowledged}
      handleContinue={async () => {
        if (currentStep === SELF_ENROLL_PURCHASE) {
          setCurrentStep(RECURRING_REIMBURSEMENT)
        }
      }}
      required
      advanceOnSuccess
    >
      <PayForPlan
        index={payForPlanIndex}
        plan={selectedPlan}
        carrierName={carrierName}
        allowanceCents={allowance.amountCents}
        exchangeLink={exchangeLink}
      />
      {isAutoPay && (
        <SetupAutoPay
          index={setupAutoPayIndex}
          carrierName={carrierName}
          achDetails={achDetails}
          isPending={isPending}
          hasDetails={hasDetails}
          insufficientFunds={insufficientFunds}
          areAchDetailsAllowed={areAchDetailsAllowed}
        />
      )}
      <FinishSetup index={finishSetupIndex} carrierName={carrierName} />

      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox checked={!!isAcknowledged} />}
          label="I acknowledge I purchased my self-enroll plan."
          name="acknowledgePurchase"
          sx={{ pl: 2 }}
          value={isAcknowledged}
          onChange={(_, checked) => setIsAcknowledged(checked)}
          data-qa="personal-info-acknowledge-terms"
        />
      </Grid>
    </BenefitsElectionStep>
  )
}
