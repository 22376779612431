import { TakeCommandLogo } from "@/components/Branding"
import { Typography } from "@mui/material"
import { Helmet } from "react-helmet-async"
import { ResetPasswordPanel } from "../components/ResetPasswordPanel"
import { AuthLayout, AuthWrapper } from "./AuthLayout"

export const ResetPassword = () => (
  <AuthLayout>
    <TakeCommandLogo />
    <AuthWrapper>
      <Helmet title="Reset Password" />
      <Typography data-qa="form-title" variant="h4" align="center" gutterBottom>
        Reset Password
      </Typography>
      <ResetPasswordPanel />
    </AuthWrapper>
  </AuthLayout>
)
