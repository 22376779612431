import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { HealthBenefitModel } from "./BenefitsTypes"

const baseUrl = appConfig.baseApiUrl
export const benefitsBaseUrl = `${baseUrl}/benefits`

export const getHealthBenefit = async (companyId: string, employmentId: string) => {
  const healthBenefitData = (
    await axios.get(`${benefitsBaseUrl}/v1/companies/${companyId}/employment/${employmentId}/health-benefit`)
  ).data

  return healthBenefitData as HealthBenefitModel
}

export const updateHealthBenefit = async (healthBenefit: Partial<HealthBenefitModel>): Promise<HealthBenefitModel> =>
  (await axios.patch(`${benefitsBaseUrl}/v1/health-benefit`, healthBenefit)).data
