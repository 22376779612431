import { isDateOfBirthValid } from "@/features/BenefitsElection/benefitsElectionUtils"
import DateFnsUtils from "@date-io/date-fns"
import { addMonths, format, isValid, parse, startOfMonth } from "date-fns"
import { isDate, isString, range } from "lodash"
import { DateTime } from "luxon"
import { IsoDateString, Option } from "./types"

interface BackendDateObject {
  year: number
  month: number
  dayOfMonth: number
  hourOfDay: number
  minute: number
  second: number
}

const TARGET_DATE_FORMAT = "yyyy-MM-dd"

// all years from year to current year + 1
export const getValueLabelsFromYearsToNext = (inputYear: number) => {
  const currentYear = new Date().getFullYear()
  return Array.from({ length: currentYear - inputYear + 2 }, (_, i) => {
    const year = inputYear + i
    return { value: year, label: year.toString() }
  })
}

export const months = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
]

export const getDateFromBackendDateObject = (date: BackendDateObject) =>
  DateTime.utc(date.year, date.month + 1, date.dayOfMonth, date.hourOfDay, date.minute, date.second).toJSDate()

const coll = [0, 0, 0, 0] as const

export const getAvailableHraStartDates = () => {
  // FUTURE SEG-2120: This can be improved
  const d = new Date()

  d.setUTCMonth(d.getUTCMonth() + 1)

  return coll.map(x => {
    d.setUTCMonth(d.getUTCMonth() + 1, 1)
    d.setUTCHours(0)

    return new Date(d)
  })
}

export const getNextSixMonthsStartDates = (currentValue?: string): Option<string, string>[] => {
  const today = new Date()

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const hraStartDates: Option<string, string>[] = []

  for (let i = 0; i < 6; i += 1) {
    const date = new Date(today.getFullYear(), today.getMonth() + i + 1, 1)
    const year = date.getFullYear()
    const month = date.getMonth()
    const monthName = monthNames[month]
    const value = `${year}-${(month + 1).toString().padStart(2, "0")}-01`

    hraStartDates.push({ label: `${monthName} ${year}`, value })
  }

  //Verify current value to be added
  if (currentValue && !hraStartDates.some(option => option.value === currentValue)) {
    const [year, month] = currentValue.split("-").map(Number)
    const monthName = monthNames[month - 1]
    const label = `${monthName} ${year}`

    hraStartDates.push({ label, value: currentValue })
  }

  return hraStartDates
}

export const getDaysWithSuffix = () => {
  const days = Array.from({ length: 28 }, (_, i) => i + 1)

  const getDaySuffix = (day: number) => {
    if (day % 10 === 1 && day !== 11) return "st"
    if (day % 10 === 2 && day !== 12) return "nd"
    if (day % 10 === 3 && day !== 13) return "rd"
    return "th"
  }

  return days.map(day => ({ value: `${day}`, label: `${day}${getDaySuffix(day)} of the month` }))
}

const convertDateString = (dateStr: IsoDateString) => DateTime.fromISO(dateStr.substring(0, 10)).toJSDate()

export const getOnlyDate = (date: Date | IsoDateString | null) => {
  if (!date) {
    return null
  }

  if (isString(date)) {
    return convertDateString(date)
  }

  if (!isDateOfBirthValid(date)) {
    return null
  }

  return convertDateString(date.toISOString())
}

export const getClosestMonthStartDate = (dateString: string): string => {
  const dateFns = new DateFnsUtils()
  const parsedDate = dateFns.date(dateString)
  if (!isValid(parsedDate) || !isDate(parsedDate)) {
    throw new Error("Invalid date string")
  }

  let targetDate: Date
  if (parsedDate.getDate() < 15) {
    targetDate = startOfMonth(parsedDate)
  } else {
    targetDate = startOfMonth(addMonths(parsedDate, 1))
  }

  return format(targetDate, TARGET_DATE_FORMAT)
}

export const getLastDayOfMonth = (dateString: string): string => {
  const parsedDate = parse(dateString, "yyyy-MM-dd", new Date())

  if (!isValid(parsedDate) || !isDate(parsedDate)) {
    throw new Error("Invalid date string")
  }

  const lastDay = new Date(parsedDate.getFullYear(), parsedDate.getMonth() + 1, 0)
  return format(lastDay, TARGET_DATE_FORMAT)
}

export const formatDateToHumanReadable = (dateString: string): string => {
  let date = parse(dateString, "yyyy-MM-dd", new Date())
  if (!isValid(date)) {
    date = new Date(dateString)
  }

  if (!isValid(date)) {
    throw new Error("Invalid date string")
  }

  return format(date, "MMMM do, yyyy")
}

export const formatDateToMmDdYyyy = (unformattedDate: Date | string): string => {
  let date: DateTime | null = null
  if (isDate(unformattedDate)) {
    date = DateTime.fromJSDate(unformattedDate)
  }
  if (isString(unformattedDate)) {
    date = DateTime.fromISO(unformattedDate)
  }
  if (!date || !date.isValid) {
    throw new Error("Invalid date")
  }
  return date.toFormat("MM/dd/yyyy")
}
export const formatDateToYyyyMmDd = (unformattedDate: Date | string): string => {
  let date: DateTime | null = null
  if (isDate(unformattedDate)) {
    date = DateTime.fromJSDate(unformattedDate)
  }
  if (isString(unformattedDate)) {
    date = DateTime.fromISO(unformattedDate)
  }
  if (!date || !date.isValid) {
    throw new Error("Invalid date")
  }
  return date.toFormat("yyyy-MM-dd")
}

export const yearFirstDay = (year: number) => new Date(year, 0, 1)
export const yearLastDay = (year: number) => new Date(year, 11, 31)

export const getCurrentYear = () => new Date().getFullYear()

const generateYearOptions = () => {
  const currentYear = getCurrentYear()
  return range(currentYear - 1, currentYear + 2).map(year => ({
    value: year.toString(),
    label: year.toString(),
  }))
}

export const autoPayYearOptions = generateYearOptions()
