import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { Cents } from "@/utils/types"

interface ProfileDetails {
  status: string
  avatarUrl: string
  allowanceInCents: Cents
  lastLoginAt: string
  email: string
  firstName: string
  lastName: string
  SSN: string
  preferredName: string
  phone: string
  mobilePhoneNumber: string
  dateOfBirth: string
  id: string
  addresses: Address[]
  employment: Employment
  companyAdministrator: CompanyAdministrator[]
  supportPerson: SupportPerson[]
}

interface Address {
  type: string
  street1: string
  street2: string
  city: string
  state: string
  zip: string
  county: string
}

interface Employment {
  activeFrom: string
}

interface CompanyAdministrator {
  roleId: number
  roleName: string
  activeFrom: string
}

interface SupportPerson {
  roleId: number
  roleName: string
  activeFrom: string
}

export const fetchProfileDetails = async (companyId: string, userId: string) => {
  try {
    const url = `${appConfig.baseApiUrl}/companies/${companyId}/people/${userId}`

    return (await axios.get<ProfileDetails>(url)).data
  } catch (e) {
    console.warn("An API Error Occurred", e)
  }
}
