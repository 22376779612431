import { useNotifications } from "@/services/notificationService"
import { useState } from "react"
import { getBillingPortalUrl } from "../settingsEndpoints"

export const useGetBillingPortalUrl = ({ companyId }: { companyId: string }) => {
  const [loadingCheckoutUrl, setLoadingCheckoutUrl] = useState(false)
  const { notify } = useNotifications("stripe-portal")

  const handlePortalError = (error: any) => {
    const message = error.message || "Something went wrong"

    console.warn(message)

    notify("An error obtaining the Stripe URL occurred. Please try again later.", "error")
  }

  const redirectToPaymentDetails = (url: string) => {
    window.location.replace(url)
  }

  const handlePortal = async () => {
    setLoadingCheckoutUrl(true)
    try {
      const checkoutUrl = await getBillingPortalUrl(companyId)
      const paymentDetailsPageUrl = checkoutUrl?.paymentDetailsPageUrl

      if (paymentDetailsPageUrl) {
        redirectToPaymentDetails(paymentDetailsPageUrl)
      }
    } catch (error: any) {
      handlePortalError(error)
    } finally {
      setLoadingCheckoutUrl(false)
    }
  }

  return { loadingCheckoutUrl, handlePortal }
}
