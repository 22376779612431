import { ReturnButton } from "@/components/Buttons"
import { DrawerForm } from "@/components/DrawerForm"
import { FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { TAX_CREDITS_OPTIONS } from "@/features/BenefitsElection/benefitsElectionConstants"
import { createDataQa } from "@/utils/dataQa"
import { formatCents } from "@/utils/formatting"
import { Box, Card, CardContent, Grid, Typography } from "@mui/material"
import { useState } from "react"

const dataQa = "tax-credits-drawer"

const AcceptedCreditsText = () => (
  <Typography data-qa={createDataQa(dataQa, "accepted-credits-text")}>
    You have selected to <Typography variant="body1bold">accept</Typography> your tax credits. This means that you are{" "}
    <Typography variant="body1bold">opting</Typography> out of the HRA offer from your employer.{" "}
    <Typography variant="body1bold">
      This is a once per year election and you will not have another opportunity to participate in the benefit until the
      next plan year.
    </Typography>{" "}
    We will redirect you to Health Sherpa or your state's exchange for your health plan enrollment.
  </Typography>
)

const DeclinedTaxCreditsText = () => (
  <Typography data-qa={createDataQa(dataQa, "declined-credits-text")}>
    Don't worry, you're not alone in this. Our dedicated <Typography variant="body1bold">support team</Typography> is
    here to lend a helping hand and guide you through any challenges you may be facing.
  </Typography>
)

export interface TaxCreditsDrawerProps {
  open: boolean
  allowance: number
  taxCredits: number
  isPending: boolean
  handleClose: () => void
  handleAcceptTaxCredits: () => void
  handleTakeHraOffer: () => void
}

export const TaxCreditsDrawer = ({
  open,
  allowance,
  taxCredits,
  isPending,
  handleClose,
  handleAcceptTaxCredits,
  handleTakeHraOffer,
}: TaxCreditsDrawerProps) => {
  const [acceptedTaxCredits, setAcceptedTaxCredits] = useState(false)

  const continueShopping = () => {
    if (acceptedTaxCredits) {
      handleAcceptTaxCredits()
    } else {
      handleTakeHraOffer()
    }
  }

  return (
    <DrawerForm
      open={open}
      onClose={handleClose}
      paperStyle={{
        padding: 6,
        width: { xs: "90%", md: "80%" },
        maxWidth: "37.5rem",
      }}
      data-qa={dataQa}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={4} mb={8}>
          <Grid item xs={12}>
            <ReturnButton
              data-qa={createDataQa(dataQa, "back-top-button")}
              text="Household Income"
              handleClose={handleClose}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2" data-qa={createDataQa(dataQa, "title")}>
              Your HRA is unaffordable
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography data-qa={createDataQa(dataQa, "description")}>
              Affordability depends on two factors: the employer's contribution and the employee's household income.
              Based on your provided information and your employer's contribution, we've determined you may be able to
              utilize your tax credits.
            </Typography>
          </Grid>

          <Grid item xs={12} mt={4}>
            <Typography variant="h6" gutterBottom>
              Based on your annual income you are eligible for Tax Credits
            </Typography>
            Please note: If you choose to accept tax credits, you will no longer be eligible for your company's HRA and
            will need to select a plan outside of the HRA Hub platform.
            <Grid container spacing={4} p={4}>
              <Grid item xs={12}>
                <Typography data-qa={createDataQa(dataQa, "details")}>
                  Your employer is offering <Typography variant="body1bold">{formatCents(allowance)}/month</Typography>{" "}
                  and your tax credits are <Typography variant="body1bold">{formatCents(taxCredits)}</Typography>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <RadioGroupCard<boolean>
                  name="accepted-tax-credits"
                  formName={dataQa}
                  elements={TAX_CREDITS_OPTIONS}
                  value={acceptedTaxCredits}
                  handleChange={(_, value) => setAcceptedTaxCredits(value)}
                  alwaysShowSubtitles={false}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card sx={{ backgroundColor: "colors.honeyDew" }}>
              <CardContent>{acceptedTaxCredits ? <AcceptedCreditsText /> : <DeclinedTaxCreditsText />}</CardContent>
            </Card>
          </Grid>
        </Grid>

        <FlowNavigationButtons
          continueLabel={acceptedTaxCredits ? "Continue" : "Show plans"}
          handleBack={handleClose}
          handleContinue={continueShopping}
          isSubmitting={isPending}
        />
      </Box>
    </DrawerForm>
  )
}
