import { appConfig } from "@/config"
import {
  getUserAttributes,
  getUserInChallenge,
  setUserInChallenge,
  UserPool,
} from "@/features/Auth/services/authService"
import { useQuery } from "@tanstack/react-query"
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js"

export const GOOGLE_AUTHENTICATOR_ANDROID_APP =
  "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
export const GOOGLE_AUTHENTICATOR_IOS_APP = "https://apps.apple.com/us/app/google-authenticator/id388497605"
export const MICROSOFT_AUTHENTICATOR_ANDROID_APP =
  "https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en&gl=US"
export const MICROSOFT_AUTHENTICATOR_IOS_APP = "https://apps.apple.com/us/app/microsoft-authenticator/id983156458"

export const initialValues = {
  verificationCode: "",
}
//As seen at https://github.com/google/google-authenticator/wiki/Key-Uri-Format#label
//We going to use both label and issuer to display the name of the app in the authenticator app
//e.g otpauth://totp/TakeCommand:alice@google.com?secret=JBSWY3DPEHPK3PXP&issuer=TakeCommand
export const getMfaQrCode = (secretCode: string, email: string, appName: string) =>
  `otpauth://totp/${encodeURIComponent(appName)}:${encodeURIComponent(email)}?secret=${encodeURIComponent(
    secretCode
  )}&issuer=${encodeURIComponent(appName)}`

export const getNonAuthenticatedUser = (username: string, password: string) =>
  new Promise<CognitoUser>((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: UserPool,
    })

    cognitoUser.authenticateUser(
      new AuthenticationDetails({
        Username: username,
        Password: password,
      }),
      {
        onSuccess(result) {
          resolve(cognitoUser)
        },
        onFailure: reject,
        mfaRequired(challengeName, challengeParameters) {
          setUserInChallenge(cognitoUser, challengeName)
          if (challengeName === "SOFTWARE_TOKEN_MFA" || challengeName === "MFA_SETUP") {
            resolve(cognitoUser)

            return
          }
          reject(new Error("Not supported challenge name: " + challengeName))
        },
        mfaSetup(challengeName, challengeParameters) {
          setUserInChallenge(cognitoUser, challengeName)
          if (challengeName === "SOFTWARE_TOKEN_MFA" || challengeName === "MFA_SETUP") {
            resolve(cognitoUser)

            return
          }
          reject(new Error("Not supported challenge name: " + challengeName))
        },
      }
    )
  })

export const getAuthenticatedUser = () =>
  new Promise<CognitoUser>((resolve, reject) => {
    const cognitoUser = UserPool.getCurrentUser()

    if (!cognitoUser) {
      reject(new Error("No authenticated user found"))

      return
    }
    cognitoUser.getSession((err: any) => {
      if (err) {
        reject(err)

        return
      }
      resolve(cognitoUser)
    })
  })

export const getSecretCodeFromUser = (cognitoUser: CognitoUser) =>
  new Promise<string>((resolve, reject) => {
    cognitoUser.associateSoftwareToken({
      associateSecretCode: resolve,
      onFailure: reject,
    })
  })

export const getAppName = () => {
  if (appConfig.isProduction) {
    return appConfig.appName
  }
  const url = new URL(appConfig.baseApiUrl)
  const secondSubdomain = url.hostname.substring(url.hostname.indexOf(".") + 1)
  const apiEnv = secondSubdomain.substring(0, secondSubdomain.indexOf("."))

  return `${appConfig.appName}[${apiEnv.toUpperCase()}]`
}

export const mfaSmsCodeSend = (cognitoUser: CognitoUser, phoneNumber: string) =>
  new Promise(async (resolve, reject) => {
    const currentAttrs = await getUserAttributes(cognitoUser)
    const currentPhoneNumber = currentAttrs?.phone_number
    if (currentPhoneNumber && currentPhoneNumber === phoneNumber) {
      cognitoUser.getAttributeVerificationCode("phone_number", {
        onSuccess: () => {
          setUserInChallenge(cognitoUser)
          resolve(cognitoUser)
        },
        onFailure: error => {
          reject(error)
        },
      })
      return
    }
    cognitoUser.updateAttributes(
      [
        new CognitoUserAttribute({
          Name: "phone_number",
          Value: phoneNumber,
        }),
      ],
      (err, result) => {
        setUserInChallenge(cognitoUser)
        if (err) {
          reject(err)
          return
        }
        resolve(cognitoUser)
      }
    )
  })

export const mfaEmailCodeSend = (email: string, password: string) =>
  new Promise(async (resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    })
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    })

    cognitoUser.initiateAuth(authenticationDetails, {
      onSuccess: result => {
        resolve(result)
      },
      onFailure: error => {
        reject(error)
      },
      customChallenge: challengeParameters => {
        setUserInChallenge(cognitoUser)
        resolve(challengeParameters)
      },
    })
  })

export const mfaEmailVerificationSettle = (cognitoUser: CognitoUser, verificationCode: string) =>
  new Promise(async (resolve, reject) => {
    const cognitoUserInChallenge = (await getUserInChallenge()) || cognitoUser
    cognitoUserInChallenge.sendCustomChallengeAnswer(verificationCode, {
      onSuccess: result => {
        resolve(result)
      },
      onFailure: error => {
        reject({
          message: "Invalid verification code",
          error,
        })
      },
      customChallenge: challengeParameters => {
        reject({
          message: "Invalid verification code",
          challengeParameters,
        })
      },
    })
  })

export const mfaVerificationSettle = (cognitoUser: CognitoUser, verificationCode: string) =>
  new Promise((resolve, reject) => {
    cognitoUser.getSession(() => {
      cognitoUser.verifySoftwareToken(verificationCode, "WebBrowser", {
        onFailure: reject,
        onSuccess: session => {
          cognitoUser.setUserMfaPreference(null, { PreferredMfa: true, Enabled: true }, () => {
            resolve(session)
          })
        },
      })
    })
  })

export const mfaSmsVerificationSettle = (cognitoUser: CognitoUser, verificationCode: string) =>
  new Promise((resolve, reject) => {
    cognitoUser.getSession(() => {
      cognitoUser.verifyAttribute("phone_number", verificationCode, {
        onFailure: reject,
        onSuccess: session => {
          cognitoUser.setUserMfaPreference({ PreferredMfa: true, Enabled: true }, null, () => {
            resolve(session)
          })
        },
      })
    })
  })

export const useCognitoUser = (
  email: string | undefined,
  password: string | undefined,
  isLoggedIn: boolean | undefined
) =>
  useQuery({
    queryKey: ["cognito-user", email],
    queryFn: async () =>
      (await getUserInChallenge()) ||
      (!isLoggedIn && email && password ? getNonAuthenticatedUser(email, password) : getAuthenticatedUser()),
    refetchOnMount: "always",
  })

export const useSecretCode = (email: string | undefined, cognitoUser: CognitoUser | null | undefined) =>
  useQuery({
    queryKey: ["secret-code", email],
    queryFn: () => getSecretCodeFromUser(cognitoUser!),
    enabled: !!cognitoUser,
  })
