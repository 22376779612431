import "@mui/lab/themeAugmentation"
import { createTheme as createMuiTheme } from "@mui/material/styles"
import { components } from "./components"
import { typography } from "./typography"
import { variants } from "./variants"

export const createTheme = (name: string) => {
  let themeConfig = variants.find(variant => variant.name === name)

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`))
    themeConfig = variants[0]
  }

  return createMuiTheme(
    {
      spacing: 4,
      components,
      typography,
      palette: themeConfig.palette,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    }
  )
}
