import { TakeCommandLogo } from "@/components/Branding"
import { EmailTextField } from "@/components/TextFields"
import { useNotifications } from "@/services/notificationService"
import { validateEmail } from "@/utils/validations"
import { Button, Grid, Link, Typography } from "@mui/material"
import { Formik } from "formik"
import { useState } from "react"
import { Helmet } from "react-helmet-async"
import * as Yup from "yup"
import { useAuth } from "../useAuth"
import { AuthLayout, AuthWrapper } from "./AuthLayout"

interface ForgotPasswordConfirmationProps {
  email: string
}

export const ForgotPasswordConfirmation = ({ email }: ForgotPasswordConfirmationProps) => (
  <>
    <Typography data-qa="confirmation-title" variant="h4" align="center" gutterBottom>
      Instructions sent!
    </Typography>
    <Typography data-qa="confirmation-body" variant="body1" align="center" gutterBottom sx={{ marginBotton: 15 }}>
      If an account exists for the email &nbsp;
      <Typography
        data-qa="confirmation-email"
        component="span"
        display="inline"
        fontWeight="fontWeightBold"
        variant="body1"
        color="black"
        gutterBottom
      >
        {email}
      </Typography>
      , instructions for resetting your password will be sent to it. You'll receive this email shortly. <br /> Be sure
      to check your spam folder, too.
    </Typography>
  </>
)

interface ForgotPasswordPanelProps {
  setEmail: (email: string) => void
  setShowConfirmationMessage: (show: boolean) => void
}

const initialValues = {
  email: "",
  submit: false,
}

const validationSchema = Yup.object().shape({
  email: validateEmail,
})

export const ForgotPasswordPanel = ({ setEmail, setShowConfirmationMessage }: ForgotPasswordPanelProps) => {
  const { forgotPassword } = useAuth()
  const { notify } = useNotifications("forgot-password")

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          forgotPassword(values.email)
          setShowConfirmationMessage(true)
          setEmail(values.email)
        } catch (error: any) {
          const message = error.message || "Something went wrong"

          setStatus({ success: false })
          setErrors({ submit: message })
          notify(`${message}`, "error")
          setSubmitting(false)
        }
      }}
    >
      {({ errors, handleSubmit, isSubmitting }) => (
        <form noValidate onSubmit={handleSubmit} data-qa="forgot-password-form">
          <EmailTextField sx={{ mt: 6 }} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            data-qa="reset-password-button"
            sx={{ mt: 6 }}
          >
            Send instructions
          </Button>
        </form>
      )}
    </Formik>
  )
}

export const ForgotPassword = () => {
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false)
  const [email, setEmail] = useState("")

  return (
    <AuthLayout>
      <TakeCommandLogo />
      <AuthWrapper>
        <Helmet title="Forgot Password" />
        {!showConfirmationMessage ? (
          <>
            <Typography data-qa="form-title" variant="h4" align="center" gutterBottom>
              Forgot Password
            </Typography>
            <Typography data-qa="form-subtitle" variant="body1" align="center" gutterBottom>
              Enter your email to reset your password
            </Typography>
            <ForgotPasswordPanel setEmail={setEmail} setShowConfirmationMessage={setShowConfirmationMessage} />
          </>
        ) : (
          <ForgotPasswordConfirmation email={email} />
        )}

        <Grid container alignItems="center" justifyContent="center" mt={10}>
          <Typography variant="caption">
            <Link underline="always" href="/sign-in" data-qa="forgot-password-log-in-link">
              Log in
            </Link>
          </Typography>
        </Grid>
      </AuthWrapper>
    </AuthLayout>
  )
}
