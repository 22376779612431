import { WetSignaturePad } from "@/components/WetSignaturePad/WetSignaturePad"
import { Grid } from "@mui/material"
import { constant, times } from "lodash"
import { useState } from "react"
import { SIGNATURES, SSN, SUMMARY } from "../../benefitsElectionConstants"
import { useShoppingSession, useShoppingUrl } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { getDisplayName, shopperIsAdult } from "../../benefitsElectionUtils"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"
import { useSubmitSignatures } from "../../hooks/useSubmitSignatures"

export const Signatures = () => {
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const getEligibleApplicants = useBenefitsElectionStore(state => state.getEligibleApplicants)
  const adultApplicants = getEligibleApplicants().filter(shopperIsAdult)
  const shoppingSessionId = useShoppingSession()
  const shoppingUrl = useShoppingUrl()
  const { submitSignatures } = useSubmitSignatures(shoppingSessionId, adultApplicants)
  const [dataUrls, setDataUrls] = useState(times(adultApplicants.length, constant("")))

  const setUrl = (index: number, url = "") => {
    const newUrls = [...dataUrls]

    newUrls[index] = url
    setDataUrls(newUrls)
  }

  const disabled = !dataUrls.every(e => !!e)
  const previous = shoppingUrl + SSN
  // FUTURE: This will need to be changed dynamically
  const next = shoppingUrl + SUMMARY

  return (
    <BenefitsElectionStep
      title="Applicant Signatures"
      previous={previous}
      next={next}
      disabled={disabled}
      handleContinue={async () => {
        await submitSignatures(dataUrls)

        if (currentStep === SIGNATURES) {
          setCurrentStep(SUMMARY)
        }
      }}
      required
      advanceOnSuccess
    >
      <Grid container direction="column" mt={4}>
        {adultApplicants.filter(shopperIsAdult).map(({ shoppingPersonId, personalInformation }, i) => (
          <WetSignaturePad
            title={getDisplayName(personalInformation)}
            onClear={() => setUrl(i)}
            onSave={(url: string) => setUrl(i, url)}
            key={shoppingPersonId}
          />
        ))}
      </Grid>
    </BenefitsElectionStep>
  )
}
