import { createDataQa } from "@/utils/dataQa"
import styled from "@emotion/styled"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Card, FormControlLabel, Stack, Switch, Tooltip, Typography } from "@mui/material"
import { tooltipClasses, TooltipProps } from "@mui/material/Tooltip"

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "26rem",
    padding: "0.75rem",
  },
})

const dataQa = "quarterly-compliance"

interface QuarterlyComplianceProps {
  isQuarterlyComplianceChecked: boolean
  handleChange: () => void
}

export const QuarterlyCompliance = ({ isQuarterlyComplianceChecked, handleChange }: QuarterlyComplianceProps) => (
  <Card sx={{ p: 6, mt: 0 }} data-qa={createDataQa(dataQa, "card")}>
    <Typography variant="h5" data-qa={createDataQa(dataQa, "title")}>
      Quarterly compliance
    </Typography>
    <Stack direction="row" alignItems="center">
      <FormControlLabel
        sx={{ my: 6, mr: 3 }}
        control={
          <Switch
            checked={isQuarterlyComplianceChecked}
            onChange={handleChange}
            data-qa={createDataQa(dataQa, "toggle")}
          />
        }
        label={isQuarterlyComplianceChecked ? "Active" : "Inactive"}
      />
      <CustomWidthTooltip
        title={
          <>
            <Typography variant="body1bold">More info</Typography>
            <Typography variant="body2">
              During the compliance review period, Take Command will reach out to participating employees to request
              updated health plan documentation to verify that they have current, compliant medical coverage. Employees
              that do not provide approved documentation before the deadline will not appear on reimbursement or funding
              reports until their coverage is validated.
            </Typography>
          </>
        }
        placement="right"
        data-qa={createDataQa(dataQa, "tooltip")}
      >
        <InfoOutlinedIcon />
      </CustomWidthTooltip>
    </Stack>

    <Typography gutterBottom data-qa={createDataQa(dataQa, "explanation")}>
      Annual compliance is recommended for most companies. If you require more frequent validation of your employees,
      you can add on quarterly compliance checks that are completed in March, June & September for a fee of $2 per
      employee per month.
    </Typography>
    <Typography fontStyle="italic" data-qa={createDataQa(dataQa, "note")}>
      Note: there is <Typography variant="body1bold">no fee</Typography> for any employees with plan premiums paid by
      AutoPay accounts.
    </Typography>
  </Card>
)
