import {
  ADMINISTRATOR_EXTERNAL,
  BROKER_EXTERNAL,
  EMPLOYEE_EXTERNAL,
  SYSTEM_ADMINISTRATOR_EXTERNAL,
} from "@/roles_permissions"
import { chipVariants } from "./components/ChipPersonStatus"
import { PeopleStatus } from "./peopleTypes"

export const ACTIVE_PEOPLE_TAB = "active"
export const INACTIVE_PEOPLE_TAB = "inactive"

export const FIVE = 5
export const TEN = 10
export const TWENTY_FIVE = 25

export const PEOPLE_ACTIVE_STATUS = {
  ACTIVE: "ACTIVE",
  ENROLLED: "ENROLLED",
  INELIGIBLE: "INELIGIBLE",
  INVITED: "INVITED",
  ONBOARDING: "ONBOARDING",
  IN_WAITING_PERIOD: "IN_WAITING_PERIOD",
  OPTED_OUT: "OPTED_OUT",
  WAIVED: "WAIVED",
  PENDING_INVITE: "PENDING_INVITE",
} as const

export const PEOPLE_INACTIVE_STATUSES = {
  INACTIVE: "INACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
} as const

export const STATUSES_TO_HIDE = [
  PEOPLE_ACTIVE_STATUS.PENDING_INVITE,
  PEOPLE_ACTIVE_STATUS.INVITED,
  PEOPLE_ACTIVE_STATUS.WAIVED,
] as PeopleStatus[]

export const PEOPLE_STATUS = {
  ...PEOPLE_ACTIVE_STATUS,
  ...PEOPLE_INACTIVE_STATUSES,
} as const

export const PEOPLE_STATUS_TO_VARIANT = {
  [PEOPLE_STATUS.IN_WAITING_PERIOD]: {
    variant: chipVariants.PaleOlive,
    label: "In Waiting Period",
  },
  [PEOPLE_STATUS.ACTIVE]: {
    variant: chipVariants.Forest,
    label: "Active",
  },
  [PEOPLE_STATUS.ENROLLED]: {
    variant: chipVariants.Forest,
    label: "Enrolled",
  },
  [PEOPLE_STATUS.WAIVED]: {
    variant: chipVariants.WaterBlue,
    label: "Waived",
  },
  [PEOPLE_STATUS.INELIGIBLE]: {
    variant: chipVariants.SeaPink,
    label: "Ineligible",
  },
  [PEOPLE_STATUS.INVITED]: {
    variant: chipVariants.SurfCrest,
    label: "Invited",
  },
  [PEOPLE_STATUS.ONBOARDING]: {
    variant: chipVariants.SurfCrest,
    label: "Onboarding",
  },
  [PEOPLE_STATUS.OPTED_OUT]: {
    variant: chipVariants.Periwinkle,
    label: "Opted Out",
  },
  [PEOPLE_STATUS.SUSPENDED]: {
    variant: chipVariants.SilverSand,
    label: "Suspended",
  },
  [PEOPLE_STATUS.INACTIVE]: {
    variant: chipVariants.Pumpkin,
    label: "Inactive",
  },
  [PEOPLE_STATUS.TERMINATED]: {
    variant: chipVariants.FireBrick,
    label: "Terminated",
  },
  [PEOPLE_STATUS.PENDING_INVITE]: {
    variant: chipVariants.PaleOlive,
    label: "Pending Invite",
  },
} as const

export const EMPLOYEE_EXTERNAL_ROLE_ID = EMPLOYEE_EXTERNAL.id
export const SYSTEM_ADMINISTRATOR_EXTERNAL_ROLE_ID = SYSTEM_ADMINISTRATOR_EXTERNAL.id
export const ADMINISTRATOR_EXTERNAL_ROLE_ID = ADMINISTRATOR_EXTERNAL.id
export const BENEFITS_CONSULTANT_EXTERNAL_ROLE_ID = BROKER_EXTERNAL.id

export const ROLE_IDS_TO_NAMES = {
  [EMPLOYEE_EXTERNAL_ROLE_ID]: "Employee",
  [SYSTEM_ADMINISTRATOR_EXTERNAL_ROLE_ID]: "System Administrator",
  [ADMINISTRATOR_EXTERNAL_ROLE_ID]: "Administrator",
  [BENEFITS_CONSULTANT_EXTERNAL_ROLE_ID]: "Benefits Consultant",
}

export const ROLE_NAMES_TO_IDS = {
  Employee: EMPLOYEE_EXTERNAL_ROLE_ID,
  "System Administrator": SYSTEM_ADMINISTRATOR_EXTERNAL_ROLE_ID,
  Administrator: ADMINISTRATOR_EXTERNAL_ROLE_ID,
  "Benefits Consultant": BENEFITS_CONSULTANT_EXTERNAL_ROLE_ID,
}
