import { ConfirmationModal } from "@/components/ConfirmationModal"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { Stepper } from "@/features/CreateCompany/components/Stepper"
import { PurchaseHraStepProps } from "@/features/CreateCompany/pages/CreateCompany"
import { APPLICATION_AWAITING_DOCUMENTS, APPLICATION_DENIED } from "@/features/Funding/fundingConstants"
import { FundingRegistrationButton } from "@/features/Funding/FundingRegistrationButton"
import { MAX_APPLICATION_RETRY_COUNT } from "@/features/Settings/components/Tabs/CompanyAutopay"
import { useAutoPayConfig, useReinitiateApplicationForm } from "@/features/Settings/settingsService"
import styled from "@emotion/styled"
import { KeyboardArrowLeft } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import LaunchIcon from "@mui/icons-material/Launch"
import {
  AlertColor,
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Alert as MuiAlert,
  Switch,
  Typography,
} from "@mui/material"
import { spacing } from "@mui/system"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const Alert = styled(MuiAlert)(spacing)

interface BannerAlertProps {
  dataQaLabel: string
  severity: AlertColor
  onClose?: () => void
  message: string
}

const BannerAlert = ({ dataQaLabel, severity, onClose, message }: BannerAlertProps) => (
  <Alert
    mb={6}
    data-qa={dataQaLabel}
    severity={severity}
    action={
      onClose && (
        <IconButton size="small" onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )
    }
  >
    {message}
  </Alert>
)

export const AddOnProducts = ({ stepData }: PurchaseHraStepProps) => {
  const [showAutopayDeactivatedBanner, setShowAutopayDeactivatedBanner] = useState(false)
  const [showApplicationErrorBanner, setShowApplicationErrorBanner] = useState(false)
  const [autopayOn, setAutopayOn] = useState(true)
  const [showDeactivateAutopayModal, setShowDeactivateAutopayModal] = useState(false)
  const [showSkipAutopayModal, setShowSkipAutopayModal] = useState(false)
  const navigate = useNavigate()
  const { user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId || ""
  const { data: autoPayConfig } = useAutoPayConfig(companyId)
  const reinitiateApplicationFormMutation = useReinitiateApplicationForm(companyId)
  const isApplicationDenied = autoPayConfig?.fundingStatus === APPLICATION_DENIED
  const isApplicationAwaitingDocuments = autoPayConfig?.fundingStatus === APPLICATION_AWAITING_DOCUMENTS

  const handleAutopayToggle = () => {
    if (autopayOn) {
      setShowDeactivateAutopayModal(true)
    } else {
      setAutopayOn(true)
      setShowAutopayDeactivatedBanner(false)
    }
  }

  const confirmDeactivation = () => {
    setShowDeactivateAutopayModal(false)
    setAutopayOn(false)
    setShowAutopayDeactivatedBanner(true)
    setShowApplicationErrorBanner(false)
  }

  const cancelDeactivation = () => {
    setAutopayOn(true)
    setShowDeactivateAutopayModal(false)
  }

  const navigateToNextStep = () => {
    navigate("/create-company/employee-roster")
  }

  const handleSkipButtonClick = () => {
    setShowSkipAutopayModal(true)
  }

  const handleApplicationError = (error: any) => {
    console.warn(error)
    setShowApplicationErrorBanner(true)
  }

  return (
    <>
      <Stepper steps={stepData} activeStepIndex={3} isError={false} />
      <Grid container direction="column" sx={{ mt: 6, ml: 0 }}>
        <Typography variant="h1" data-qa="add-on-products-title">
          Add-on Products
        </Typography>

        <Typography variant="body1" sx={{ mb: 11.5 }}>
          Description of the page
        </Typography>

        <Typography variant="h3" data-qa="add-on-products-autopay" sx={{ mb: showAutopayDeactivatedBanner ? 4 : 8 }}>
          AutoPay
        </Typography>

        {showAutopayDeactivatedBanner && (
          <BannerAlert
            dataQaLabel="autopay-deactivated-banner"
            severity="success"
            onClose={() => setShowAutopayDeactivatedBanner(false)}
            message="You have successfully deactivated AutoPay"
          />
        )}

        {showApplicationErrorBanner && (
          <BannerAlert
            dataQaLabel="autopay-application-error"
            severity="error"
            message="An error with the AutoPay occurred. Please try again later."
          />
        )}

        <Typography variant="h4" sx={{ mb: 6 }}>
          Do you want to add-on AutoPay?
        </Typography>

        <FormControlLabel
          sx={{ mb: 8 }}
          control={<Switch checked={autopayOn} onChange={handleAutopayToggle} data-qa="autopay-toggle" />}
          label={autopayOn ? "AutoPay on" : "AutoPay off"}
        />

        <Typography variant="body1" sx={{ maxWidth: "64ch" }}>
          AutoPay payment solution simulates the same cashflow experience as a traditional group plan - employers pay
          premiums, then withhold employee’s portion via payroll as needed. AutoPay enables the convenience of a group
          plan with the personalization and choice of the individual market. Available to employers with 30+
          participating lives on our platform.
        </Typography>

        {isApplicationDenied &&
          autoPayConfig &&
          (autoPayConfig.reapplyCount < MAX_APPLICATION_RETRY_COUNT ? (
            <Alert severity="error" data-qa="autopay-application-denied-alert" sx={{ mt: "1rem" }}>
              <Typography variant="body1" sx={{ maxWidth: "64ch" }}>
                Your AutoPay application has been <b>denied</b> by our banking partner. If you feel you’ve received this
                message in error, or to correct a mistake, you may try again by clicking <b>Reapply</b>.
              </Typography>
            </Alert>
          ) : (
            <Alert severity="error" data-qa="autopay-application-max-attempts-alert" sx={{ mt: "1rem" }}>
              <Typography variant="body1" sx={{ maxWidth: "64ch" }}>
                You have reached the maximum number of application attempts. Please contact support for assistance.
              </Typography>
            </Alert>
          ))}

        {isApplicationAwaitingDocuments && (
          <Alert severity="warning" data-qa="vendor-application-documents-alert" sx={{ mt: "1rem" }}>
            <Typography variant="body1" sx={{ maxWidth: "64ch" }}>
              Upload required documents to complete your application.
            </Typography>
          </Alert>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            marginTop: 25,
            marginLeft: autopayOn ? 0 : "auto",
          }}
        >
          <Button
            data-qa="back-button"
            type="submit"
            color="inherit"
            onClick={() => navigate("/create-company/plan-structure")}
          >
            <KeyboardArrowLeft />
            Back
          </Button>

          {autopayOn && (
            <Button
              data-qa="skip-button"
              variant="outlined"
              onClick={handleSkipButtonClick}
              sx={{ marginLeft: "auto" }}
            >
              Skip
            </Button>
          )}

          {autopayOn ? (
            <>
              <FundingRegistrationButton
                companyId={companyId}
                onButtonClick={navigateToNextStep}
                onApplicationError={handleApplicationError}
              />
              {isApplicationDenied && (
                <Button
                  variant="contained"
                  color="primary"
                  data-qa="reapply-button"
                  disabled={autoPayConfig?.reapplyCount >= MAX_APPLICATION_RETRY_COUNT}
                  onClick={async () => {
                    const response = await reinitiateApplicationFormMutation.mutateAsync()

                    window.open(response.applicationUrl)
                  }}
                  endIcon={<LaunchIcon />}
                >
                  Reapply
                </Button>
              )}
            </>
          ) : (
            //
            <Button
              data-qa="continue-button"
              type="submit"
              variant="contained"
              color="primary"
              onClick={navigateToNextStep}
            >
              Continue
            </Button>
          )}
        </Box>
      </Grid>

      <ConfirmationModal
        isOpen={showDeactivateAutopayModal}
        onClose={cancelDeactivation}
        onConfirm={confirmDeactivation}
        isError={false}
        title="Deactivate AutoPay?"
        message="Are you sure you want to deactivate AutoPay?"
        cancelLabel="No"
        actionLabel="Yes"
      />

      <ConfirmationModal
        isOpen={showSkipAutopayModal}
        onClose={() => {
          setShowSkipAutopayModal(false)
        }}
        onConfirm={navigateToNextStep}
        isError={false}
        title="Skip AutoPay?"
        message="Your AutoPay is already on. Are you sure you want to skip AutoPay?"
        actionLabel="I want to skip and continue"
      />
    </>
  )
}
