import markUrl from "@/assets/svg/tch-mark.svg"
import { Link } from "@mui/material"

const markStyle = {
  height: "2.8rem",
  width: "auto",
  paddingTop: "0.3rem",
  marginLeft: "0.5rem",
}

export const TakeCommandMarkHeader = ({ href = "/" }: { href?: string }) => (
  <Link data-qa="take-command-mark-header" href={href}>
    <img src={markUrl} alt="take command health mark" style={markStyle} />
  </Link>
)
