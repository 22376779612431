import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material"
import { ChangeEventHandler, FocusEventHandler } from "react"

interface QuizRadioGroupQuestionProps {
  options: string[]
  value: string
  name: string
  onChange: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}

export const QuizRadioGroupQuestion = ({ options, value, name, onChange, onBlur }: QuizRadioGroupQuestionProps) => (
  <Grid item>
    <RadioGroup id="radioGroup" value={value} onChange={onChange} onBlur={onBlur} name={name}>
      {options.map(option => (
        <FormControlLabel
          key={option}
          name={name}
          classes={{
            root: `${value === option ? "isChecked" : ""}`,
            label: `${value === option ? "isChecked" : ""}`,
          }}
          value={option}
          control={<Radio />}
          label={option}
          sx={{
            backgroundColor: "hraPlanDesign.background",
            "&.isChecked": {
              backgroundColor: "hraPlanDesign.backgroundChecked",
            },
            mb: 1,
            ml: 0.5,
            mx: "12%",
          }}
        />
      ))}
    </RadioGroup>
  </Grid>
)
