import { StyledCard } from "@/components/StyledCard"
import { PLAN_PREMIUM_LABEL } from "@/constants"
import { takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { formatCents, toTitleCase } from "@/utils/formatting"
import styled from "@emotion/styled"
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined"
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined"
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined"
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined"
import PregnantWomanOutlinedIcon from "@mui/icons-material/PregnantWomanOutlined"
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined"
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined"
import TollOutlinedIcon from "@mui/icons-material/TollOutlined"
import {
  Grid,
  Link,
  TableCell as MuiTableCell,
  Stack,
  SvgIconTypeMap,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyProps,
} from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import { capitalize } from "lodash"
import { ReactElement, ReactNode } from "react"
import { BENEFITS_SUMMARY } from "../../benefitsElectionConstants"
import { DoctorPreference, DrugPreference, HospitalPreference, Plan } from "../../benefitsElectionTypes"
import { getInAndOutOfNetworkValues, getPlanBalance } from "../../benefitsElectionUtils"

const dataQa = "plan-details"

const TableCell = styled(MuiTableCell)`
  padding: 0.75rem 0 0.5rem 0;
`

const TableIcon = ({ icon: IconComponent }: { icon: OverridableComponent<SvgIconTypeMap> }) => (
  <IconComponent sx={{ color: takeCommandPrimary.main }} />
)

const ContentIcon = ({ icon: IconComponent }: { icon: OverridableComponent<SvgIconTypeMap> }) => (
  <IconComponent sx={{ width: "2.5rem", height: "2.5rem", color: takeCommandPrimary.main }} />
)

const TableRowWithIcon = ({ icon, name, value }: { icon: ReactElement; name: string; value: ReactNode }) => (
  <TableRow key={name} data-qa={createDataQa(dataQa, "table-icon-row")}>
    <TableCell component="th" scope="row" sx={{ width: { xs: "50%", md: "60%" } }}>
      <Stack alignItems="center" direction="row" gap={2}>
        {icon}
        <Typography variant="body2">{name}</Typography>
      </Stack>
    </TableCell>
    <TableCell align="right" sx={{ maxWidth: "9rem" }}>
      {value}
    </TableCell>
  </TableRow>
)

const Body2Span = ({ text }: { text: string }) => <Typography variant="body2bold">{text}</Typography>
const planOverviewDataQa = "plan-overview"

const PlanOverview = ({ plan }: { plan: Plan }) => {
  const {
    planType,
    level,
    individualMedicalDeductibleAmountCents,
    familyMedicalDeductibleAmountCents,
    hsaEligible,
    planDocuments,
    individualMedicalMaxOutOfPocketAmountCents,
    familyMedicalMaxOutOfPocketAmountCents,
  } = plan

  const benefitsSummaryUrl = planDocuments.find(doc => doc.type === BENEFITS_SUMMARY)?.url
  const hasBenefitsSummaryUrl = !!benefitsSummaryUrl

  return (
    <TableContainer>
      <Table aria-label="plan overview table" data-qa={createDataQa(dataQa, "plan-overview-table")}>
        <TableBody>
          <TableRowWithIcon
            name="Plan Type"
            icon={<TableIcon icon={PersonOutlinedIcon} />}
            value={
              <Typography variant="body2bold" data-qa={createDataQa(planOverviewDataQa, "plan-type")}>
                {planType}
              </Typography>
            }
          />
          <TableRowWithIcon
            name="Metal Tier"
            icon={<TableIcon icon={PersonOutlinedIcon} />}
            value={
              <Typography variant="body2bold" data-qa={createDataQa(planOverviewDataQa, "metal-tier")}>
                {toTitleCase(level)}
              </Typography>
            }
          />
          <TableRowWithIcon
            name="Individual deductible"
            icon={<TableIcon icon={PersonOutlinedIcon} />}
            value={
              <Typography variant="body2bold" data-qa={createDataQa(planOverviewDataQa, "individual-deductible")}>
                {formatCents(individualMedicalDeductibleAmountCents)}
              </Typography>
            }
          />
          <TableRowWithIcon
            name="Family deductible"
            icon={<TableIcon icon={PeopleOutlinedIcon} />}
            value={
              <Typography variant="body2bold" data-qa={createDataQa(planOverviewDataQa, "family-deductible")}>
                {formatCents(familyMedicalDeductibleAmountCents)}
              </Typography>
            }
          />
          <TableRowWithIcon
            name="Individual max out of pocket"
            icon={<TableIcon icon={PersonOutlinedIcon} />}
            value={
              <Typography
                variant="body2bold"
                data-qa={createDataQa(planOverviewDataQa, "individual-max-out-of-pocket")}
              >
                {formatCents(individualMedicalMaxOutOfPocketAmountCents)}
              </Typography>
            }
          />
          <TableRowWithIcon
            name="Family max out of pocket"
            icon={<TableIcon icon={PeopleOutlinedIcon} />}
            value={
              <Typography variant="body2bold" data-qa={createDataQa(planOverviewDataQa, "family-max-out-of-pocket")}>
                {formatCents(familyMedicalMaxOutOfPocketAmountCents)}
              </Typography>
            }
          />
          <TableRowWithIcon
            name="Health savings account eligibility (HSA)"
            icon={<TableIcon icon={CheckCircleOutlinedIcon} />}
            value={
              <Typography variant="body2" data-qa={createDataQa(planOverviewDataQa, "hsa-eligible")} textAlign="left">
                This plan is <Body2Span text={`${hsaEligible ? "" : "not"} eligible`} /> with a health savings account.
                Not to be confused with the HRA you're setting up now. All plans are HRA eligible.
              </Typography>
            }
          />

          {hasBenefitsSummaryUrl && (
            <TableRowWithIcon
              name="Summary of benefits"
              icon={<TableIcon icon={StickyNote2OutlinedIcon} />}
              value={
                <Link href={benefitsSummaryUrl} data-qa={createDataQa(dataQa, "table-icon-value-link")}>
                  View document
                </Link>
              }
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export interface CostBreakdownProps {
  allowanceCents: number
  premiumAmountCents: number
}

const CostBreakdown = ({ allowanceCents, premiumAmountCents }: CostBreakdownProps) => {
  const { balanceLabel, balanceValue, balanceLabelColor } = getPlanBalance(allowanceCents, premiumAmountCents)

  return (
    <TableContainer>
      <Table aria-label="cost breakdown table" data-qa={createDataQa(dataQa, "cost-breakdown-table")}>
        <TableBody>
          <TableRow data-qa={createDataQa(dataQa, "plan-overview-allowance")}>
            <TableCell component="th" scope="row">
              <Typography variant="body2bold">Your allowance</Typography>
            </TableCell>
            <TableCell align="right">{formatCents(allowanceCents)}/mo</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" data-qa={createDataQa(dataQa, "cost-breakdown-premium")}>
              <Typography variant="body2bold">Your {PLAN_PREMIUM_LABEL.toLowerCase()}</Typography>
            </TableCell>
            <TableCell align="right">{formatCents(premiumAmountCents)}/mo</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" data-qa={createDataQa(dataQa, "plan-cost")}>
              <Typography variant="body2bold"> {balanceLabel}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2bold" color={balanceLabelColor}>
                {balanceValue}/mo
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface PlanCost {
  name: string
  rawValue: string
}

interface PlanCostsTableProps {
  tableName: string
  rowsData: PlanCost[]
}

const PlanCostsTable = ({ tableName, rowsData }: PlanCostsTableProps) => (
  <TableContainer>
    <Table aria-label={`${tableName} table`} data-qa={createDataQa(dataQa, tableName, "table")}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "33%" }} />
          <TableCell align="left" sx={{ width: "33%" }}>
            <Typography variant="body2bold">In Network</Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: "33%" }}>
            <Typography variant="body2bold">Out of Network</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rowsData.map(({ name, rawValue }) => {
          const { inNetworkValue, outOfNetworkValue } = getInAndOutOfNetworkValues(rawValue)

          return (
            <TableRow key={name} data-qa={createDataQa(dataQa, tableName, "row")}>
              <TableCell component="th" scope="row">
                <Typography variant="body2bold"> {name}</Typography>
              </TableCell>
              <TableCell align="left">{capitalize(inNetworkValue)}</TableCell>
              <TableCell align="left">{capitalize(outOfNetworkValue)}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </TableContainer>
)

interface MedicalCoverage {
  preferenceName: string
  covered: boolean
}

interface CoverageItemProps {
  name: string
  color: TypographyProps["color"]
  show: boolean
}

const CoverageItem = ({ name, color, show }: CoverageItemProps) =>
  show ? (
    <Typography variant="body2bold" color={color}>
      {name}
    </Typography>
  ) : (
    <Typography color="black">-</Typography>
  )

interface MedicalCoverageTableProps {
  label: string
  tableName: string
  rowsData: MedicalCoverage[]
  coveredLabel?: string
  notCoveredLabel?: string
}

const MedicalCoverageTable = ({
  label,
  tableName,
  rowsData,
  coveredLabel = "In Network",
  notCoveredLabel = "Out of Network",
}: MedicalCoverageTableProps) => (
  <StyledCard
    elevation={0}
    sx={{
      backgroundColor: takeCommandPrimary[100],
      m: 0,
      mb: "1rem",
      pt: 0,
      px: 2,
      pb: 2,
      borderRadius: "0.5rem",
    }}
  >
    <Grid container direction="row">
      <Grid item xs={12} md={4} p={3}>
        <Typography variant="body2bold">{label}</Typography>
      </Grid>
      <Grid item xs={12} md={8} p={{ xs: 3, md: 0 }}>
        <TableContainer>
          <Table aria-label={`${tableName} table`} data-qa={createDataQa(dataQa, tableName, "table")}>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ width: "33%" }}>
                  <Typography variant="body2bold">{coveredLabel}</Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: "33%" }}>
                  <Typography variant="body2bold">{notCoveredLabel}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsData.map(({ preferenceName, covered }) => (
                <TableRow key={preferenceName} data-qa={createDataQa(dataQa, tableName, "row")}>
                  <TableCell align="left">
                    <CoverageItem name={preferenceName} color="primary" show={covered} />
                  </TableCell>
                  <TableCell align="left">
                    <CoverageItem name={preferenceName} color="error" show={!covered} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </StyledCard>
)

export interface MedicalDetailsProps {
  label: string
  tableName: string
  costs: PlanCost[]
  coverages: MedicalCoverage[]
  coveredLabel?: string
  notCoveredLabel?: string
}

const MedicalDetails = ({ label, tableName, costs, coverages, coveredLabel, notCoveredLabel }: MedicalDetailsProps) => (
  <>
    {coverages.length > 0 && (
      <MedicalCoverageTable
        label={label}
        tableName={`your-${tableName}`}
        rowsData={coverages}
        coveredLabel={coveredLabel}
        notCoveredLabel={notCoveredLabel}
      />
    )}
    <PlanCostsTable tableName={tableName} rowsData={costs} />
  </>
)

export interface MentalHealthDetailsProps {
  mentalHealthCosts: PlanCost[]
}

const MentalHealthDetails = ({ mentalHealthCosts }: MentalHealthDetailsProps) => (
  <PlanCostsTable tableName="mental health" rowsData={mentalHealthCosts} />
)

export interface PregnancyAndMaternityDetailsProps {
  pregnancyCosts: PlanCost[]
}

const PregnancyAndMaternityDetails = ({ pregnancyCosts }: PregnancyAndMaternityDetailsProps) => (
  <PlanCostsTable tableName="pregnancy and maternity" rowsData={pregnancyCosts} />
)

export interface EmergencyCareDetailsProps {
  emergencyCosts: PlanCost[]
}

const EmergencyCareDetails = ({ emergencyCosts }: EmergencyCareDetailsProps) => (
  <PlanCostsTable tableName="emergency care" rowsData={emergencyCosts} />
)

export interface PlanDetailsElement {
  title: string
  description: string
  icon: ReactElement
  contentIcon: ReactElement
  component: ReactNode
  isFirstItem?: boolean
}

export type WithCoverage<T extends object> = T & { covered: boolean }

export const getPlanDetailsElementsList = (
  plan: Plan,
  allowanceCents: number,
  uniqueDoctors: WithCoverage<DoctorPreference>[],
  uniqueHospitals: WithCoverage<HospitalPreference>[],
  uniqueDrugs: WithCoverage<DrugPreference>[]
): PlanDetailsElement[] => {
  const { premiumAmountCents, rawData } = plan

  const {
    emergencyRoom,
    genericDrugs,
    imagingCenter,
    inpatientBirth,
    inpatientFacility,
    inpatientMentalHealth,
    labTest,
    nonPreferredBrandDrugs,
    outpatientFacility,
    outpatientMentalHealth,
    postnatalCare,
    prenatalCare,
    preventativeCare,
    preferredBrandDrugs,
    primaryCarePhysician,
    specialist,
    specialtyDrugs,
    urgentCare,
  } = rawData

  const doctorCosts: PlanCost[] = [
    { name: "Preventative Care", rawValue: preventativeCare },
    { name: "Primary", rawValue: primaryCarePhysician },
    { name: "Specialist", rawValue: specialist },
  ]

  const prescriptionCosts: PlanCost[] = [
    { name: "Generic", rawValue: genericDrugs },
    { name: "Preferred brand", rawValue: preferredBrandDrugs },
    {
      name: "Non preferred brand",
      rawValue: nonPreferredBrandDrugs,
    },
    { name: "Specialty", rawValue: specialtyDrugs },
  ]

  const hospitalCosts: PlanCost[] = [
    { name: "Inpatient", rawValue: inpatientFacility },
    { name: "Outpatient", rawValue: outpatientFacility },
    { name: "Imaging", rawValue: imagingCenter },
    { name: "Labs & tests", rawValue: labTest },
  ]

  const mentalHealthCosts: PlanCost[] = [
    { name: "Inpatient", rawValue: inpatientMentalHealth },
    { name: "Outpatient", rawValue: outpatientMentalHealth },
  ]

  const pregnancyCosts: PlanCost[] = [
    { name: "Prenatal care", rawValue: prenatalCare },
    { name: "Postnatal care", rawValue: postnatalCare },
    { name: "Inpatient birth", rawValue: inpatientBirth },
  ]

  const emergencyCosts: PlanCost[] = [
    { name: "Emergency room", rawValue: emergencyRoom },
    { name: "Urgent care", rawValue: urgentCare },
  ]

  return [
    {
      title: "Plan overview",
      description: "Summary of health coverage benefits and plan details",
      icon: <DescriptionOutlinedIcon />,
      contentIcon: <ContentIcon icon={DescriptionOutlinedIcon} />,
      component: <PlanOverview plan={plan} />,
      isFirstItem: true,
    },
    {
      title: "Cost breakdown",
      description:
        "Here is a breakdown of your total HRA allowance, and the total monthly premium on the plan you've selected, before your allowance is applied. The total due today reflects the amount you owe up front.",
      icon: <PaidOutlinedIcon />,
      contentIcon: <ContentIcon icon={PaidOutlinedIcon} />,
      component: <CostBreakdown allowanceCents={allowanceCents} premiumAmountCents={premiumAmountCents} />,
    },
    {
      title: "Doctors",
      description: "If you need to see a doctor, here’s what you’ll pay if they’re in-network:",
      icon: <PermContactCalendarOutlinedIcon />,
      contentIcon: <ContentIcon icon={PermContactCalendarOutlinedIcon} />,
      component: (
        <MedicalDetails
          label="Your doctors:"
          tableName="doctors"
          costs={doctorCosts}
          coverages={uniqueDoctors.map(({ name: preferenceName, covered }) => ({ preferenceName, covered }))}
        />
      ),
    },
    {
      title: "Prescriptions",
      description: "If you have a prescription that's in-network, here's what you'll pay:",
      icon: <AssignmentOutlinedIcon />,
      contentIcon: <ContentIcon icon={AssignmentOutlinedIcon} />,
      component: (
        <MedicalDetails
          label="Your prescriptions:"
          tableName="prescriptions"
          costs={prescriptionCosts}
          coverages={uniqueDrugs.map(({ description: preferenceName, covered }) => ({
            preferenceName,
            covered,
          }))}
          coveredLabel="Covered"
          notCoveredLabel="Not Covered"
        />
      ),
    },
    {
      title: "Hospitals",
      description: "If you need medical attention or tests, here's what you'll pay if you use an in-network provider:",
      icon: <DomainOutlinedIcon />,
      contentIcon: <ContentIcon icon={DomainOutlinedIcon} />,
      component: (
        <MedicalDetails
          label="Your hospitals"
          tableName="hospitals"
          costs={hospitalCosts}
          coverages={uniqueHospitals.map(({ name: preferenceName, covered }) => ({ preferenceName, covered }))}
        />
      ),
    },
    {
      title: "Mental Health",
      description:
        "Mental and behavioral health inpatient service, here’s what you’ll pay if you use an in-network provider:",
      icon: <PsychologyOutlinedIcon />,
      contentIcon: <ContentIcon icon={PsychologyOutlinedIcon} />,
      component: <MentalHealthDetails mentalHealthCosts={mentalHealthCosts} />,
    },
    {
      title: "Pregnancy & maternity",
      description: "If you are having a baby, here's what you'll pay if you use an in-network provider:",
      icon: <PregnantWomanOutlinedIcon />,
      contentIcon: <ContentIcon icon={PregnantWomanOutlinedIcon} />,
      component: <PregnancyAndMaternityDetails pregnancyCosts={pregnancyCosts} />,
    },
    {
      title: "Emergency care",
      description: "If you need medical attention or tests, here's what you'll pay if you use an in-network provider:",
      icon: <TollOutlinedIcon />,
      contentIcon: <ContentIcon icon={TollOutlinedIcon} />,
      component: <EmergencyCareDetails emergencyCosts={emergencyCosts} />,
    },
  ]
}
