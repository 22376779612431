import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { getCheckoutUrl } from "@/features/CreateCompany/createCompanyEndpoints"
import { useNotifications } from "@/services/notificationService"
import { useState } from "react"
import { OnboardingStep } from "../components/OnboardingStep"
import { QuarterlyCompliance } from "../components/QuarterlyCompliance"
import { BILLING_SETUP, QUARTERLY_COMPLIANCE } from "../employerOnboardingConstants"
import { useOnboardingStatuses, useUpdateOnboardingStatus } from "../employerOnboardingService"

export const SubscriptionBilling = () => {
  const { updateStepOnboardingStatus } = useUpdateOnboardingStatus()
  const { notify } = useNotifications("subscription-billing")
  const { statuses } = useOnboardingStatuses()
  const isQuarterlyComplianceComplete = statuses.QUARTERLY_COMPLIANCE?.isComplete
  const [isQuarterlyComplianceChecked, setIsQuarterlyComplianceChecked] = useState(!!isQuarterlyComplianceComplete)
  const { user } = useAuth()
  const company = getActiveCompany(user)
  const companyId = company?.companyId
  const [isLoading, setIsLoading] = useState(false)

  const handleCheckout = async () => {
    const checkoutUrl = await getCheckoutUrl(companyId)

    if (checkoutUrl?.paymentDetailsPageUrl) {
      window.location.replace(checkoutUrl?.paymentDetailsPageUrl)
    }
  }

  // Function to handle checkout error
  const handleCheckoutError = (error: any) => {
    const message = error.message || error || "Something went wrong"

    console.error(message)
    notify("An error occurred when checkout. Please try again later.", "error")
  }

  const handleContinue = async () => {
    try {
      setIsLoading(true)
      await updateStepOnboardingStatus(QUARTERLY_COMPLIANCE, isQuarterlyComplianceChecked)
      await handleCheckout()
    } catch (error: any) {
      handleCheckoutError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <OnboardingStep
      title="Add-on Products"
      description="Take Command will review health plan documents for employees participating in your company’s HRA each year as part of our compliance service to confirm they have current, valid coverage. Employees with approved documentation will appear on your funding and reimbursement reports."
      stepName={BILLING_SETUP}
      handleContinue={handleContinue}
      advanceOnSuccess={false}
      isSubmitting={isLoading}
    >
      <QuarterlyCompliance
        isQuarterlyComplianceChecked={isQuarterlyComplianceChecked}
        handleChange={() => setIsQuarterlyComplianceChecked(previousValue => !previousValue)}
      />
    </OnboardingStep>
  )
}
