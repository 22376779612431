import { noop } from "lodash"

export const confirmTerminationModal = {
  title: "Terminate Person",
  message: "Are you sure you want to terminate this person?",
  isOpen: true,
  isError: false,
  isCancelable: true,
  onClose: noop,
}

export const confirmReinstateModal = {
  title: "Reinstate Person",
  message: "Are you sure you want to reinstate this person?",
  isOpen: true,
  isError: false,
  isCancelable: true,
  onClose: noop,
}

export const confirmWaiveModal = {
  title: "Waive Person",
  message: "Are you sure you want to waive this person?",
  isOpen: true,
  isError: false,
  isCancelable: true,
  onClose: noop,
}

export const failedModal = {
  title: "Error",
  message: "Something went wrong, please try again",
  isOpen: true,
  isError: true,
  isCancelable: false,
  actionLabel: "Ok",
  onClose: noop,
}
