import {
  HRIS_STATUS_CONNECTED,
  HRIS_STATUS_ERROR,
  HRIS_STATUS_NOT_STARTED,
  HRIS_STATUS_PROCESSING,
  HrisStatus,
} from "@/features/Hris/hrisTypes"
import { colors, takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import TripOriginIcon from "@mui/icons-material/TripOrigin"
import WarningIcon from "@mui/icons-material/Warning"
import { Chip, SxProps, Typography } from "@mui/material"
import { defaultMemoize as memoize } from "reselect"

interface HrisStatusChipProps {
  dataQa: string
  hrisStatus: HrisStatus
  sx?: SxProps
}

export const getVariantConfigByStatus = memoize((hrisStatus: string) => {
  switch (hrisStatus.toUpperCase()) {
    case HRIS_STATUS_PROCESSING:
      return {
        label: "Processing",
        icon: <TripOriginIcon sx={{ fill: colors.waterBlue }} />,
        backgroundColor: colors.calgarBlue,
        color: colors.waterBlue,
      } as const
    case HRIS_STATUS_CONNECTED:
      return {
        label: "Connected",
        backgroundColor: takeCommandPrimary.main,
        color: "white",
      } as const
    case HRIS_STATUS_ERROR:
      return {
        label: "Error",
        icon: <WarningIcon sx={{ fill: colors.lightErrorDark }} />,
        backgroundColor: colors.fieryGarnet,
        color: colors.lightErrorDark,
      } as const
    case HRIS_STATUS_NOT_STARTED:
    default:
      return {
        label: "Pending",
        icon: <WarningIcon sx={{ width: "1rem", fill: colors.tailLights }} />,
        backgroundColor: colors.creamyCloudDreams,
        color: colors.tailLights,
      } as const
  }
})

export const HrisStatusChip = ({ dataQa, hrisStatus, sx }: HrisStatusChipProps) => {
  const variantConfig = getVariantConfigByStatus(hrisStatus)

  return (
    <Chip
      data-qa={createDataQa(dataQa, "hris-status-chip")}
      sx={{
        backgroundColor: variantConfig.backgroundColor,
        color: variantConfig.color,
        px: 2,
        ...sx,
      }}
      icon={variantConfig.icon}
      label={<Typography variant="body2">{variantConfig.label}</Typography>}
    />
  )
}
