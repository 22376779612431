import { DatePickerField } from "@/components/DatePickerField"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { ClampedTextField } from "@/components/TextFields"
import { YES_NO_OPTIONS } from "@/constants"
import { BOOLEAN, DATE, MULTIPLE_CHOICE, TEXT } from "@/features/TCHub/tcHubConstants"
import { createDataQa, DataQa } from "@/utils/dataQa"
import { Grid, SxProps, Typography } from "@mui/material"
import { useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { CarrierEnrollmentQuestion } from "../benefitsElectionTypes"
import { buildEnrollmentQuestionFormKey } from "../utils/carrierQuestions"

const formName = "carrier-questions"
const baseDataQa = createDataQa(formName)

interface QuestionTitleProps {
  title: string
  sx?: SxProps
}

const QuestionTitle = ({ title, sx }: QuestionTitleProps) => (
  <Grid item xs={12} mt={4}>
    <Typography variant="h5" sx={sx}>
      {title}
    </Typography>
  </Grid>
)

interface BaseQuestionProps {
  questionKey: string
  label: string
  "data-qa": DataQa
}

// FUTURE: We should be able to add a little more detail to this type
// One idea would be to use the Uuid type for the index signature
type Values = Record<string, string | undefined>
const BooleanQuestion = ({ questionKey, label, "data-qa": dataQa }: BaseQuestionProps) => {
  const { setFieldValue, values } = useFormikContext<Values>()

  return (
    <RadioGroupCard
      name={questionKey}
      formName={formName}
      elements={YES_NO_OPTIONS}
      value={values[questionKey] || ""}
      handleChange={setFieldValue}
      label={label}
      labelVariant="h5"
      // Take the base data qa and disambiguate with the question type
      data-qa={createDataQa(dataQa, BOOLEAN)}
    />
  )
}

const TextQuestion = ({ questionKey, label, "data-qa": dataQa }: BaseQuestionProps) => {
  const { errors, handleChange, handleBlur, touched, values } = useFormikContext<Values>()

  return (
    <>
      <QuestionTitle title={label} />
      <ClampedTextField
        name={questionKey}
        placeholder="Type your answer here..."
        variant="outlined"
        value={values[questionKey] || ""}
        error={Boolean(touched[questionKey] && errors[questionKey])}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        sx={{ mb: 0, mt: 4 }}
        // Take the base data qa and disambiguate with the question type
        data-qa={createDataQa(dataQa, TEXT)}
      />
    </>
  )
}

const DateQuestion = ({ questionKey, label, "data-qa": dataQa }: BaseQuestionProps) => {
  const { errors, handleChange, handleBlur, touched, values } = useFormikContext<Values>()

  return (
    <>
      <QuestionTitle title={label} />
      <DatePickerField
        name={questionKey}
        value={values[questionKey] || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(touched[questionKey] && errors[questionKey])}
        fullWidth
        sx={{ mb: 0, mt: 4 }}
        // Take the base data qa and disambiguate with the question type
        data-qa={createDataQa(dataQa, DATE)}
      />
    </>
  )
}

interface MultipleChoiceQuestionProps extends BaseQuestionProps {
  options: string[]
}

const MultipleChoiceQuestion = ({ questionKey, label, options, "data-qa": dataQa }: MultipleChoiceQuestionProps) => {
  const { setFieldValue, values } = useFormikContext<Values>()

  return (
    <>
      <QuestionTitle title={label} sx={{ mb: 4 }} />
      <RadioGroupCard
        name={questionKey}
        formName={formName}
        elements={options.map(option => ({
          title: option,
          value: option,
        }))}
        value={values[questionKey] || ""}
        handleChange={setFieldValue}
        required
        labelVariant="h5"
        // Take the base data qa and disambiguate with the question type
        data-qa={createDataQa(dataQa, MULTIPLE_CHOICE)}
      />
    </>
  )
}

interface QuestionProps {
  question: CarrierEnrollmentQuestion
  questionKey: string
}

const Question = ({ question, questionKey }: QuestionProps) => {
  const sharedProps = { questionKey, label: question.question, "data-qa": baseDataQa }

  switch (question.fieldType) {
    case BOOLEAN:
      return <BooleanQuestion {...sharedProps} />

    case TEXT:
      return <TextQuestion {...sharedProps} />

    case DATE:
      return <DateQuestion {...sharedProps} />

    case MULTIPLE_CHOICE:
      return <MultipleChoiceQuestion {...sharedProps} options={question.answerChoices || []} />

    default:
      return null
  }
}

export interface CarrierQuestionProps {
  question: CarrierEnrollmentQuestion
  personId?: string
}

export const CarrierQuestion = ({ question, personId }: CarrierQuestionProps) => {
  const { values, touched, setFieldTouched } = useFormikContext<Values>()
  const [hasChildQuestions, setHasChildQuestions] = useState<boolean>(false)
  const [valueKey, setValueKey] = useState<string>("")

  useEffect(() => {
    setHasChildQuestions(question.childQuestions && question.childQuestions.length > 0)
    setValueKey(buildEnrollmentQuestionFormKey(question, personId))
  }, [question, personId])

  useEffect(() => {
    if (question.childQuestions) {
      question.childQuestions.forEach(childQuestion => {
        const parentQuestionKey = buildEnrollmentQuestionFormKey(childQuestion, personId, true)
        const parentQuestionAnswer = values[parentQuestionKey]?.toLowerCase()
        const parentAnswerForVisibility = childQuestion.parentAnswerForVisibility?.toLowerCase()

        if (parentQuestionAnswer && parentQuestionAnswer === parentAnswerForVisibility && !touched[valueKey]) {
          setFieldTouched(valueKey, true, false)
        }
      })
    }
  }, [question.childQuestions, touched, values, valueKey, personId, setFieldTouched])

  return (
    <>
      <Question question={question} questionKey={valueKey} />
      {hasChildQuestions &&
        question.childQuestions.map((childQuestion: CarrierEnrollmentQuestion) => {
          const parentQuestionAnswer = values[valueKey]

          if (
            parentQuestionAnswer === undefined ||
            parentQuestionAnswer.toLowerCase() !== childQuestion.parentAnswerForVisibility?.toLowerCase()
          ) {
            return null
          }

          return <CarrierQuestion key={childQuestion.id} question={childQuestion} personId={personId} />
        })}
    </>
  )
}
