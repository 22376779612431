import { WaitingPeriod } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureTypes"

export const STEPS = {
  CONFIRM_COMPANY: {
    progress: 20,
    shouldValidate: true,
    id: "tc-hub-confirm-company",
  },
  TC_HUB_ABOUT_PERSON_FORM: {
    progress: 40,
    shouldValidate: true,
    id: "tc-hub-about-profile-form",
  },
  TC_HUB_ADDRESS_PERSON_FORM: {
    progress: 60,
    shouldValidate: true,
    id: "address-profile-form",
  },
  TC_HUB_EMPLOYMENT_PERSON_FORM: {
    progress: 80,
    shouldValidate: true,
    id: "employment-profile-form",
  },
  CONFIRM_ACTION: {
    progress: false,
    shouldValidate: false,
    id: "confirm-action",
  },
  CONFIRM_BENEFITS: {
    progress: false,
    shouldValidate: false,
    id: "confirm-benefits",
  },
  ABOUT_PERSON_FORM: {
    progress: 30,
    shouldValidate: true,
    id: "about-profile-form",
  },
  ADDRESS_PERSON_FORM: {
    progress: 65,
    shouldValidate: true,
    id: "address-profile-form",
  },
  EMPLOYMENT_PERSON_FORM: {
    progress: 95,
    shouldValidate: true,
    id: "employment-profile-form",
  },
  WELL_DONE_MESSAGE: {
    progress: 100,
    shouldValidate: false,
    id: "well-done-message",
  },
} as const

export type Step = (typeof STEPS)[keyof typeof STEPS]

/*
 * Employment types are used in the dropdown for the employment type field.
 */
export const EMPLOYMENT_TYPES = [
  { name: "Full-Time", value: "full_time" },
  { name: "Part-Time", value: "part_time" },
  { name: "Seasonal", value: "seasonal" },
] as const

export const PAYLOAD_ADDRESS_TYPE = "HOME"

export interface BenefitClass {
  name: string
  id: string
  waitingPeriod: WaitingPeriod
}
