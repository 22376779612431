import { useOnboardingStatuses } from "@/features/EmployerOnboarding/employerOnboardingService"
import { ACCOUNTS_ACCESS_HRAS } from "@/roles_permissions"
import { usePermissions } from "@/services/permissionsService"
import { WrapperProps } from "@/utils/types"
import { Navigate, useLocation } from "react-router-dom"
import { useAuth } from "../useAuth"

// For routes that can only be accessed by authenticated users
export const AuthGuard = ({ children }: WrapperProps) => {
  const location = useLocation()
  const { isAuthenticated, isInitialized } = useAuth()

  const isEmployer = usePermissions(ACCOUNTS_ACCESS_HRAS.id)
  const { isBillingSetupComplete, isEmployerOnboarding } = useOnboardingStatuses()
  const isInOnboardingPage = location.pathname.includes("/employer-onboarding")
  const shouldRedirectToOnboarding =
    isEmployer && !isInOnboardingPage && isEmployerOnboarding && !isBillingSetupComplete

  if (!isInitialized) return null

  if (!isAuthenticated) {
    return (
      <Navigate
        to="/sign-in"
        replace
        state={{
          from: location.pathname,
        }}
      />
    )
  }

  if (shouldRedirectToOnboarding) {
    return (
      <Navigate
        to="/employer-onboarding"
        replace
        state={{
          from: location.pathname,
        }}
      />
    )
  }

  return children
}
