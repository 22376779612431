import { useNotifications } from "@/services/notificationService"
import { usePermissions } from "@/services/permissionsService"
import { ReactNode } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { PermissionId } from "../permissions"

interface TcHubGuardProps {
  children: ReactNode
  requiredPermissions: PermissionId | PermissionId[]
  matchType?: "some" | "every"
}

export const TcHubGuard = ({ children, matchType, requiredPermissions }: TcHubGuardProps) => {
  const location = useLocation()
  const { notify } = useNotifications("tc-hub")
  // Ensure that requiredPermissions is an array of strings
  const permissionsArray = Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions]

  const hasRequiredPermissions = usePermissions(permissionsArray, {
    internalOnly: true,
    matchType,
  })

  if (!hasRequiredPermissions) {
    notify("You don't have permission to access this page", "error", "permission-denied")

    return <Navigate to="/404" replace state={{ from: location }} />
  }

  return children
}
