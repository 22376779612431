import axios from "axios"
import { get, set } from "lodash"

export const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(
  response => response,
  axiosError => {
    const generalError = axiosError.response?.data
    const userMessage = get(axiosError.response?.data, "userMessage")
    const developerMessage = get(axiosError.response?.data, "developerMessage")
    const errorMessage =
      userMessage ?? axiosError?.message ?? developerMessage ?? `Something went wrong: Response: ${axiosError}`
    const isCancel = axios.isCancel(axiosError)
    const error = new Error(errorMessage)
    set(error, "userMessage", userMessage)
    set(error, "developerMessage", developerMessage)
    set(error, "responseMessage", generalError)
    set(error, "isCancel", isCancel)
    return Promise.reject(error)
  }
)
