import { usePermissions } from "@/services/permissionsService"
import { ReactNode } from "react"
import { PermissionId } from "../permissions"

export interface PermissionGuardProps {
  in: PermissionId | PermissionId[]
  children?: ReactNode
  internalOnly?: boolean
  matchType?: "some" | "every"
}

export const PermissionGuard = ({ internalOnly, matchType, in: permissionIds, children }: PermissionGuardProps) => {
  const hasPermission = usePermissions(permissionIds, { internalOnly, matchType })

  if (hasPermission) {
    return children
  }

  return null
}
