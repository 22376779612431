import { LoadingContentArea } from "@/components/LoadingContentArea"
import { StyledCard } from "@/components/StyledCard"
import { PermissionGuard } from "@/features/Auth/guards/PermissionGuard"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { Launch } from "@mui/icons-material"
import { Alert, Button, CircularProgress, Grid, Tooltip, Typography } from "@mui/material"
import { useGetBillingPortalUrl } from "../../hooks/useGetBillingPortalUrl"
import { useGetPaymentDetails } from "../../hooks/useGetPaymentDetails"
import {
  ACH_ACCOUNT,
  AchAccountProps,
  CREDIT_CARD,
  CreditCardProps,
  PaymentMethodProps,
} from "../../types/paymentTypes"

// Functional Components
const AchAccount = ({ institution, accountNumber, accountType }: AchAccountProps) => (
  <Grid item>
    <Grid container direction="column" spacing={4}>
      <Grid item container direction="row">
        <Typography data-qa="company-billing-details-account-label" width="8rem">
          Account:
        </Typography>
        <Typography data-qa="company-billing-details-account-value">{`${institution} ${accountNumber}`}</Typography>
      </Grid>
      <Grid item container direction="row">
        <Typography data-qa="company-billing-details-account-type-label" width="8rem">
          Account Type:
        </Typography>
        <Typography data-qa="company-billing-details-account-type-value">{accountType}</Typography>
      </Grid>
    </Grid>
  </Grid>
)

const CreditCard = ({ cardIssuer, cardNumber, expirationMonth, expirationYear }: CreditCardProps) => (
  <Grid item>
    <Grid container direction="column" spacing={4}>
      <Grid item container direction="row">
        <Typography data-qa="company-billing-details-card-number-label" width="10rem">
          Card number:
        </Typography>
        <Typography data-qa="company-billing-details-card-number-value">
          {cardIssuer} {cardNumber}
        </Typography>
      </Grid>
      <Grid item container direction="row">
        <Typography data-qa="company-billing-details-card-expiration-label" width="10rem">
          Expiration Date:
        </Typography>
        <Typography data-qa="company-billing-details-card-expiration-value">{`${expirationMonth}/${expirationYear}`}</Typography>
      </Grid>
    </Grid>
  </Grid>
)

const NoConfiguredPaymentMethod = () => (
  <Grid item>
    <Typography variant="body1">Your payment information is not yet configured.</Typography>
  </Grid>
)

const PaymentMethod = ({ isLoading, paymentInformation }: PaymentMethodProps) => {
  if (isLoading) {
    return <LoadingContentArea data-qa="loading-billing-details" />
  }

  if (paymentInformation.kind === ACH_ACCOUNT) {
    return <AchAccount {...paymentInformation.props} />
  } else if (paymentInformation.kind === CREDIT_CARD) {
    return <CreditCard {...paymentInformation.props} />
  } else {
    return <NoConfiguredPaymentMethod />
  }
}

export const CompanyBillingDetails = () => {
  // Authentication
  const { user } = useAuth()
  const company = getActiveCompany(user)
  const companyId = company?.companyId

  // Billing portal URL
  const { loadingCheckoutUrl, handlePortal } = useGetBillingPortalUrl({
    companyId,
  })

  const { loadingPaymentDetails, paymentDetails } = useGetPaymentDetails({ companyId })

  return (
    <StyledCard>
      {(paymentDetails.kind === ACH_ACCOUNT || paymentDetails.kind === CREDIT_CARD) && paymentDetails.paymentStatus && (
        <Alert severity={paymentDetails.paymentStatus.severity} data-qa="billing-details-error">
          {paymentDetails.paymentStatus.message}
        </Alert>
      )}
      <Grid container justifyContent="space-between" alignItems="center" mb={5}>
        <Grid item>
          <Typography variant="h5" data-qa="billing-details-label">
            Billing
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" data-qa="billing-details-description">
          Our portal allows you to self-manage your payment details, invoices, and subscriptions all in one place.
        </Typography>
      </Grid>
      <Grid container mt={5} spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" data-qa="billing-details-subtitle" mb={4} mt={5}>
            Payment Details
          </Typography>
        </Grid>
        <PaymentMethod isLoading={loadingPaymentDetails} paymentInformation={paymentDetails} />
        <Grid item xs={12} mt={5}>
          <PermissionGuard in="billing_payment_edit_payment_information">
            <Tooltip title="You will be redirected to the Stripe website">
              <Button
                data-qa="update-payment-method-button"
                variant="contained"
                color="primary"
                onClick={handlePortal}
                startIcon={loadingCheckoutUrl ? <CircularProgress color="primary" size={20} /> : <Launch />}
                disabled={loadingCheckoutUrl}
              >
                Update payment information
              </Button>
            </Tooltip>
          </PermissionGuard>
        </Grid>
      </Grid>
    </StyledCard>
  )
}
