import { addResponse } from "@/features/HRAPlanDesign/HRAPlanDesign.data"
import { usePrescreenResult } from "@/features/HRAPlanDesign/HRAPlanDesign.hooks"
import {
  isIchraDiy,
  isIchraNonDiy,
  PRE_SCREEN_QUESTION,
  radioGroupHraValidation,
} from "@/features/HRAPlanDesign/HRAPlanDesign.utils"
import { createDataQa } from "@/utils/dataQa"
import { Formik } from "formik"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { NavigationContainer } from "./NavigationContainer"
import { QuestionBody } from "./QuestionBody"
import { QuestionContainer } from "./QuestionContainer"
import { QuestionTitle } from "./QuestionTitle"
import { QuizBackButton } from "./QuizBackButton"
import { QuizLoader } from "./QuizLoader"
import { QuizNextQuestionButton } from "./QuizNextQuestionButton"
import { QuizProgressBar } from "./QuizProgressBar"
import { QuizRadioGroupQuestion } from "./QuizRadioGroupQuestion"

const formOptions = ["Yes", "No"]

export const VariedAllowances = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const dataQa = createDataQa("varied-allowance")
  const locationResult = usePrescreenResult("LOCATION")
  const locationResultPlan = locationResult?.result
  const variedAllowancesResult = usePrescreenResult("VARIED_ALLOWANCES")
  const variedAllowancesResponse = variedAllowancesResult?.response

  const initialValues = {
    radioGroup: variedAllowancesResponse || "",
  }

  const handleBack = async () => {
    navigate("/hra-determination/location")
  }

  const handleFormSubmit = async (values: any, { resetForm, setErrors, setStatus, setSubmitting }: any) => {
    try {
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
      if (
        formOptions.includes(values.radioGroup) &&
        !!locationResultPlan &&
        (isIchraDiy(locationResultPlan) || isIchraNonDiy(locationResultPlan))
      ) {
        await dispatch(
          addResponse({
            question: PRE_SCREEN_QUESTION.VARIED_ALLOWANCES,
            response: values.radioGroup,
            result: locationResultPlan,
          })
        )
        if (isIchraNonDiy(locationResultPlan)) {
          navigate("/hra-determination-results/ichra-non-diy")
        } else if (isIchraDiy(locationResultPlan)) {
          navigate("/hra-determination-results/ichra-diy")
        } else {
          throw Error("Invalid Location Result")
        }
      }
    } catch (error: any) {
      setStatus({ sent: false })
      setErrors({ submit: error.message })
      setSubmitting(false)
    }
  }

  return (
    <QuestionContainer data-qa={createDataQa(dataQa, "wrapper")}>
      <Formik initialValues={initialValues} validationSchema={radioGroupHraValidation} onSubmit={handleFormSubmit}>
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, isValid, values }) => (
          <>
            <QuizProgressBar value={80} />
            <QuestionTitle>
              Do you want to offer a different reimbursement allowance based on employee type?
            </QuestionTitle>
            <QuestionBody>
              Some HRAs allow for multiple employee classes with different reimbursement structures based on things like
              Full-Time, Part-Time, Salaried, Non-Salaried, Seasonal, Non-Seasonal, etc.
            </QuestionBody>

            {isSubmitting ? (
              <QuizLoader />
            ) : (
              <form onSubmit={handleSubmit}>
                <QuizRadioGroupQuestion
                  options={formOptions}
                  value={values.radioGroup}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="radioGroup"
                  data-qa={createDataQa("option")}
                />
                <NavigationContainer>
                  <QuizBackButton handleBack={handleBack} />
                  <QuizNextQuestionButton
                    onClick={handleSubmit}
                    disabled={!(isValid && values.radioGroup && !isSubmitting && errors)}
                  />
                </NavigationContainer>
              </form>
            )}
          </>
        )}
      </Formik>
    </QuestionContainer>
  )
}
