import balls from "@/assets/img/balls.png"
import puppy from "@/assets/img/puppy.png"
import { StyledCard } from "@/components/StyledCard"
import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { DashboardLayout } from "@/features/Dashboard/components/DashboardLayout"
import { createDataQa } from "@/utils/dataQa"
import { Box, Grid, Typography } from "@mui/material"
import { getActiveCompany } from "../Auth/services/authService"
import { useAuth } from "../Auth/useAuth"
import { useHrisConfig } from "./hrisService"

export const HrisImportPage = () => {
  const { user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId || ""
  const { data: hrisConfig } = useHrisConfig(companyId)
  let providerName = hrisConfig?.providerType?.split(":")[1]

  providerName = providerName ? providerName.charAt(0).toUpperCase() + providerName.slice(1) : ""

  return (
    <AuthGuard>
      <DashboardLayout>
        <Typography variant="h1" gutterBottom>
          Import from {providerName}
        </Typography>
        <StyledCard>
          <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ p: 6 }}>
            <Grid item>
              <Typography variant="h1" align="center" gutterBottom>
                Import is in progress
              </Typography>
            </Grid>
            <Grid item sx={{ height: "21.25rem", display: "flex", flexDirection: "column", alignContent: "center" }}>
              <Box
                component="img"
                alt="balls"
                data-qa={createDataQa("balls")}
                src={balls}
                sx={{
                  "@keyframes rotating": {
                    "0%": {
                      transform: "rotate(0deg)",
                    },
                    "100%": {
                      transform: "rotate(360deg)",
                    },
                  },
                  "@keyframes bouncing": {
                    "0%": {
                      marginTop: "0rem",
                      marginBottom: "2rem",
                    },
                    "100%": {
                      marginTop: "2rem",
                      marginBottom: "0rem",
                    },
                  },
                  animation: "bouncing 1s alternate-reverse infinite, rotating 35s linear infinite",
                  height: "6.25rem",
                  width: "6.25rem",
                  marginRight: "auto",
                  marginLeft: "auto",
                  objectFit: "contain",
                }}
              />
              <Box
                component="img"
                alt="puppy"
                data-qa={createDataQa("dog-and-ball-image")}
                src={puppy}
                sx={{ height: "13rem", marginLeft: "-5rem", objectFit: "contain" }}
              />
            </Grid>
            <Grid item>
              <Typography align="center" sx={{ maxWidth: "28rem", mt: 4 }}>
                This will take some time, depending on the amount of data. You can either stay here or return later to
                review the status. We will notify you via email when it's ready.
              </Typography>
            </Grid>
          </Grid>
        </StyledCard>
      </DashboardLayout>
    </AuthGuard>
  )
}
