import { DataQa } from "@/utils/dataQa"
import { Card, Skeleton, SxProps } from "@mui/material"
import { random } from "lodash"
import { ReactNode } from "react"

interface WidgetLayoutProps {
  isLoading?: boolean
  children: ReactNode
  sx?: SxProps
  "data-qa"?: DataQa
}

export const WidgetLayout = ({ isLoading, children, sx = {}, "data-qa": dataQa }: WidgetLayoutProps) =>
  isLoading ? (
    <Skeleton height={`${random(10, 20)}rem`} variant="rounded" />
  ) : (
    <Card sx={{ p: 6, ...sx }} data-qa={dataQa ?? "widget"}>
      {children}
    </Card>
  )
