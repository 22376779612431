import { Grid, Typography } from "@mui/material"
import { Helmet } from "react-helmet-async"

export const TcHubDashboardPage = () => (
  <Grid container data-qa="tc-hub-dashboard">
    <Helmet title="TC Hub Dashboard" />
    <Typography variant="h1" gutterBottom display="inline" data-qa="dashboard">
      Dashboard
    </Typography>
  </Grid>
)
