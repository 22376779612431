import { NO_PLAN_SELECTED } from "@/features/BenefitsElection/benefitsElectionConstants"
import {
  useShoppingSession,
  useUpdateHealthBenefitsElection,
} from "@/features/BenefitsElection/benefitsElectionService"
import { useBenefitsElectionStore } from "@/features/BenefitsElection/benefitsElectionStore"
import { HealthBenefitsElection } from "@/features/BenefitsElection/benefitsElectionTypes"

export const useResetPlanSelection = () => {
  const shoppingSessionId = useShoppingSession()
  const currentShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const setHealthBenefitElection = useBenefitsElectionStore(state => state.setHealthBenefitElection)
  const setSelectedPlan = useBenefitsElectionStore(state => state.setSelectedPlan)
  const setPlansToCompare = useBenefitsElectionStore(state => state.setPlansToCompare)

  const { mutateAsync: updateHealthBenefitsElection, isPending: isResettingPlanSelection } =
    useUpdateHealthBenefitsElection(shoppingSessionId)

  const unselectComparePlans = () => {
    setPlansToCompare([])
  }

  const unselectPlan = async () => {
    const currentBenefitElection = currentShoppingSession.healthBenefitElections[0]

    const unselectedPlanBenefitElection: HealthBenefitsElection = {
      id: currentBenefitElection.id,
      employmentId: currentBenefitElection.employmentId,
      enrollmentStatus: NO_PLAN_SELECTED,
      shoppingPersons: [],
      isRenewal: false,
    }

    const updatedBenefitElection = await updateHealthBenefitsElection(unselectedPlanBenefitElection)

    setHealthBenefitElection(updatedBenefitElection)
    setSelectedPlan(undefined)
    unselectComparePlans()
  }

  return { isResettingPlanSelection, unselectPlan, unselectComparePlans }
}
