import helpIcon from "@/assets/svg/icon-megaphone.svg"
import { takeCommandSecondary } from "@/theme/palette"
import { Avatar, Card, CardContent, Grid } from "@mui/material"
import { Box } from "@mui/system"
import { ReactNode } from "react"
import { Typographify } from "../Text"

export interface CalloutProps {
  img?: string
  mainText?: ReactNode
  children?: ReactNode
}

const avatarSize = { xs: 40, sm: 60 } as const

export const Callout = ({ img = helpIcon, mainText, children }: CalloutProps) => (
  <Grid container>
    <Grid item xs={12} md={8}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          gap: 4,
          my: {
            xs: 4,
            md: 6,
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            src={img}
            sx={{
              bgcolor: takeCommandSecondary.light,
              width: avatarSize,
              height: avatarSize,
              position: { xs: "absolute", sm: "static" },
              top: { xs: "-1.25rem", sm: "auto" },
              left: { xs: "-.25rem", sm: "auto" },
            }}
            alt="help icon"
          />
        </Box>
        <Card sx={{ border: "1px solid", borderColor: "colors.borderGray" }}>
          <CardContent>
            <Typographify>{mainText}</Typographify>
            {children}
          </CardContent>
        </Card>
      </Box>
    </Grid>
  </Grid>
)
