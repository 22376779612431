import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { getHealthBenefit, updateHealthBenefit } from "./BenefitsEndpoints"
import { HealthBenefitModel } from "./BenefitsTypes"

export const useGetHealthBenefit = (companyId: string, employmentId: string) =>
  useQuery({
    queryKey: ["getHealthBenefit", companyId, employmentId],
    queryFn: () => getHealthBenefit(companyId, employmentId),
    enabled: !!companyId,
    refetchOnWindowFocus: false,
  })

export const useUpdateHealthBenefit = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (payload: Partial<HealthBenefitModel>) => updateHealthBenefit(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["getCurrentClasses"],
      }),
  })
}
