import { addResponse } from "@/features/HRAPlanDesign/HRAPlanDesign.data"
import { usePrescreenResult } from "@/features/HRAPlanDesign/HRAPlanDesign.hooks"
import {
  isIchraDiy,
  isIchraNonDiy,
  isQsehra,
  PLAN_RESULT,
  PRE_SCREEN_QUESTION,
  radioGroupHraValidation,
} from "@/features/HRAPlanDesign/HRAPlanDesign.utils"
import { createDataQa } from "@/utils/dataQa"
import { Formik } from "formik"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { NavigationContainer } from "./NavigationContainer"
import { QuestionBody } from "./QuestionBody"
import { QuestionContainer } from "./QuestionContainer"
import { QuestionTitle } from "./QuestionTitle"
import { QuizBackButton } from "./QuizBackButton"
import { QuizLoader } from "./QuizLoader"
import { QuizNextQuestionButton } from "./QuizNextQuestionButton"
import { QuizProgressBar } from "./QuizProgressBar"
import { QuizRadioGroupQuestion } from "./QuizRadioGroupQuestion"

const formOptions = ["Yes", "No"]

export const KeepGroupPlan = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const dataQa = createDataQa("keep-group-plan")
  const haveGroupPlanResult = usePrescreenResult("HAVE_GROUP_PLAN")
  const haveGroupPlanResultPlan = haveGroupPlanResult?.result
  const keepGroupPlanResult = usePrescreenResult("KEEP_GROUP_PLAN")
  const keepGroupPlanResponse = keepGroupPlanResult?.response

  const initialValues = {
    radioGroup: keepGroupPlanResponse || "",
  }

  const handleBack = async () => {
    navigate("/hra-determination/have_group_plan")
  }

  const handleFormSubmit = async (values: any, { resetForm, setErrors, setStatus, setSubmitting }: any) => {
    try {
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
      if (formOptions.includes(values.radioGroup)) {
        if (haveGroupPlanResultPlan && isQsehra(haveGroupPlanResultPlan)) {
          switch (values.radioGroup) {
            case "Yes":
              await dispatch(
                addResponse({
                  question: PRE_SCREEN_QUESTION.KEEP_GROUP_PLAN,
                  response: values.radioGroup,
                  result: PLAN_RESULT.ICHRA_DIY,
                })
              )
              navigate("/hra-determination/location")
              break
            case "No":
              await dispatch(
                addResponse({
                  question: PRE_SCREEN_QUESTION.KEEP_GROUP_PLAN,
                  response: values.radioGroup,
                  result: PLAN_RESULT.QSEHRA,
                })
              )
              navigate("/hra-determination/monthly_allowance")
              break
            default:
              throw Error("Invalid Option Selected")
          }
        } else if (
          haveGroupPlanResultPlan &&
          (isIchraDiy(haveGroupPlanResultPlan) || isIchraNonDiy(haveGroupPlanResultPlan))
        ) {
          await dispatch(
            addResponse({
              question: PRE_SCREEN_QUESTION.KEEP_GROUP_PLAN,
              response: values.radioGroup,
              result: haveGroupPlanResultPlan,
            })
          )
          navigate("/hra-determination/location")
        }
      }
    } catch (error: any) {
      setStatus({ sent: false })
      setErrors({ submit: error.message })
      setSubmitting(false)
    }
  }

  return (
    <QuestionContainer data-qa={createDataQa(dataQa, "wrapper")}>
      <Formik initialValues={initialValues} validationSchema={radioGroupHraValidation} onSubmit={handleFormSubmit}>
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, isValid, values }) => (
          <>
            <QuizProgressBar value={50} />
            <QuestionTitle>Do you want to keep a group plan?</QuestionTitle>
            <QuestionBody>
              You cannot offer a group health plan alongside a QSEHRA. ICHRA does permit you to keep a group health plan
              for one class of employees, however minimum class sizes could apply.
            </QuestionBody>

            {isSubmitting ? (
              <QuizLoader />
            ) : (
              <form onSubmit={handleSubmit}>
                <QuizRadioGroupQuestion
                  options={formOptions}
                  value={values.radioGroup}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="radioGroup"
                  data-qa={createDataQa("option")}
                />
                <NavigationContainer>
                  <QuizBackButton handleBack={handleBack} />
                  <QuizNextQuestionButton
                    onClick={handleSubmit}
                    disabled={!(isValid && values.radioGroup && !isSubmitting && errors)}
                  />
                </NavigationContainer>
              </form>
            )}
          </>
        )}
      </Formik>
    </QuestionContainer>
  )
}
