import { PermissionGuard } from "@/features/Auth/guards/PermissionGuard"
import styled from "@emotion/styled"
import { AdminPanelSettingsOutlined, ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material"
import { BackdropProps, Grid, ListItemButton, ModalProps, Drawer as MuiDrawer, PaperProps } from "@mui/material"
import { ReactNode } from "react"
import { useLocation } from "react-router-dom"
import { TakeCommandLogoHeader, TakeCommandMarkHeader } from "../Branding"
import { SidebarHeader } from "./SidebarHeader"
import { SidebarNav } from "./SidebarNav"
import { SidebarItem } from "./types"

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`

const Brand = styled(ListItemButton)<{
  component?: ReactNode
  to?: string
}>`
  font-size: 1.25rem;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.palette.colors.darkBody};
  font-family: "Tiempos Headline";
  min-height: 56px;
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${props => props.theme.sidebar.header.background};
  }
`

export interface SidebarProps {
  PaperProps: Partial<PaperProps>
  items: SidebarItem[]
  variant?: "permanent" | "persistent" | "temporary"
  open?: boolean
  onClose?: () => void
  showFooter?: boolean
  BackdropProps?: Partial<BackdropProps>
  ModalProps?: Partial<ModalProps>
  showMark?: boolean
  showHeader?: boolean
  showSectionDivider?: boolean
  isViewingTcHub?: boolean
  children?: ReactNode
}

export const Sidebar = ({
  items,
  showFooter = true,
  showMark = false,
  showHeader = true,
  showSectionDivider = false,
  isViewingTcHub,
  children,
  ...props
}: SidebarProps) => {
  const location = useLocation()
  const isAdminPage = location.pathname.startsWith("/admin")

  return (
    <Drawer variant="permanent" {...props} data-qa="sidebar">
      <Grid container alignItems="center" sx={{ backgroundColor: isViewingTcHub ? "colors.boneChilling" : null }}>
        {showHeader && (
          <Brand data-qa="sidebar-header">
            {showMark ? <TakeCommandMarkHeader /> : <TakeCommandLogoHeader isViewingTcHub={isViewingTcHub} />}
          </Brand>
        )}
      </Grid>
      <SidebarNav items={items} showSectionDivider={showSectionDivider} />
      {children}
      {/* Duplicated SidebarHeader for the A/B test. We will be removing on of them. */}
      {showFooter && (
        <PermissionGuard
          internalOnly
          in={[
            "tc_hub_users",
            "tc_hub_companies",
            "tc_hub_autopay",
            "tc_hub_compliance",
            "tc_hub_carriers",
            "tc_hub_site_settings",
          ]}
          matchType="some"
        >
          {!isAdminPage ? (
            <SidebarHeader
              icon={AdminPanelSettingsOutlined}
              title="TC Hub"
              noActiveClass
              href="/admin/"
              endIcon={ChevronRightOutlined}
              data-qa="tc-hub-bottom"
            />
          ) : (
            <SidebarHeader
              icon={AdminPanelSettingsOutlined}
              title="Exit TC Hub"
              noActiveClass
              href="/"
              endIcon={ChevronLeftOutlined}
            />
          )}
        </PermissionGuard>
      )}
    </Drawer>
  )
}
