import ichraBasicPlanDocumentUrl2023 from "@/assets/documents/IchraBasicPlanDocument2023.pdf"
import ichraPlanSummaryDescription2023 from "@/assets/documents/IchraPlanSummaryDescription2023.pdf"
import qsehraBasicPlanDocumentUrl2023 from "@/assets/documents/QsehraBasicPlanDocument2023.pdf"
import qsehraPlanSummaryDescription2023 from "@/assets/documents/QsehraPlanSummaryDescription2023.pdf"
import serviceAgreementUrl2023 from "@/assets/documents/ServicesAgreement2023.pdf"

export const ICHRA_HRA_TYPE = "ICHRA"
export const QSEHRA_HRA_TYPE = "QSEHRA"

export const SERVICES_AGREEMENT = "services_agreement"
export const SERVICES_AGREEMENT_DOCUMENT_NAME = "Services Agreement"
export const ICHRA_BASIC_PLAN_DOCUMENT = "ichra_basic_plan_document"
export const QSEHRA_BASIC_PLAN_DOCUMENT = "qsehra_basic_plan_document"
export const ICHRA_PLAN_SUMMARY_DESCRIPTION = "ichra_plan_summary_description"
export const QSEHRA_PLAN_SUMMARY_DESCRIPTION = "qsehra_plan_summary_description"
export const ICHRA_ADOPTION_AGREEMENT = "ichra_adoption_agreement"
export const QSEHRA_ADOPTION_AGREEMENT = "qsehra_adoption_agreement"
export const ICHRA_SUMMARY_BENEFITS_COVERAGE = "ichra_summary_benefits_and_coverage"
export const ICHRA_SUMMARY_BENEFITS_COVERAGE_FOR_EMPLOYEE = "ichra_employee_summary_benefits_and_coverage"
export const ICHRA_EMPLOYEE_NOTICE = "ichra_employee_notice"
export const QSEHRA_EMPLOYEE_NOTICE = "qsehra_employee_notice"
export const ICHRA_EMPLOYEE_NOTICE_REFERENCE = "ichra_employee_notice_reference"
export const PAYROLL_REPORT = "payroll_report"
export const RECONCILIATION_PAYROLL_REPORT = "reconciliation_payroll_report"
export const APPLICATION_PDF = "application_pdf"

export const ISSUED_DOCUMENT_TYPES = [
  SERVICES_AGREEMENT,
  ICHRA_BASIC_PLAN_DOCUMENT,
  QSEHRA_BASIC_PLAN_DOCUMENT,
  ICHRA_PLAN_SUMMARY_DESCRIPTION,
  QSEHRA_PLAN_SUMMARY_DESCRIPTION,
  ICHRA_ADOPTION_AGREEMENT,
  QSEHRA_ADOPTION_AGREEMENT,
  ICHRA_SUMMARY_BENEFITS_COVERAGE,
  ICHRA_SUMMARY_BENEFITS_COVERAGE_FOR_EMPLOYEE,
  ICHRA_EMPLOYEE_NOTICE,
  QSEHRA_EMPLOYEE_NOTICE,
  ICHRA_EMPLOYEE_NOTICE_REFERENCE,
  PAYROLL_REPORT,
  RECONCILIATION_PAYROLL_REPORT,
  APPLICATION_PDF,
] as const

export const POC = "POC"
export const EXPENSE = "Expense"
export const SIGNATURE = "SIGNATURE"
export const QUALIFYING_EVENT_SUPPORT_DOCUMENT = "Qualifying Event Support Document"

export const UPLOADED_DOCUMENT_TYPES = [POC, EXPENSE, SIGNATURE, QUALIFYING_EVENT_SUPPORT_DOCUMENT] as const

export const DOCUMENT_TYPES = [...ISSUED_DOCUMENT_TYPES, ...UPLOADED_DOCUMENT_TYPES] as const

export const EMPLOYEE_DOCUMENT_TYPES = [
  ICHRA_EMPLOYEE_NOTICE,
  QSEHRA_EMPLOYEE_NOTICE,
  ICHRA_SUMMARY_BENEFITS_COVERAGE,
  ICHRA_SUMMARY_BENEFITS_COVERAGE_FOR_EMPLOYEE,
  ICHRA_PLAN_SUMMARY_DESCRIPTION,
  QSEHRA_PLAN_SUMMARY_DESCRIPTION,
] as const

export const STATIC_DOCUMENTS = [
  {
    url: "IchraPlanSummaryDescription2023.pdf",
    file: ichraPlanSummaryDescription2023,
  },
  {
    url: "QsehraPlanSummaryDescription2023.pdf",
    file: qsehraPlanSummaryDescription2023,
  },
  {
    url: "IchraBasicPlanDocument2023.pdf",
    file: ichraBasicPlanDocumentUrl2023,
  },
  {
    url: "QsehraBasicPlanDocument2023.pdf",
    file: qsehraBasicPlanDocumentUrl2023,
  },
  {
    url: "ServicesAgreement2023.pdf",
    file: serviceAgreementUrl2023,
  },
] as const
