import { HIDE_FLOATING_HELP_PATHS, PHONE_NUMBER } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { getUserHashForIntercom } from "@/features/People/peopleManagementEndpoints"
import { takeCommandPrimary } from "@/theme/palette"
import { cleanUpUsPhoneNumber } from "@/utils/formatting"
import { HideFloatingHelpPath } from "@/utils/types"
import { ChatOutlined, Close, ContactSupportOutlined, HeadsetMic } from "@mui/icons-material"
import { IconButton, Popover, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { useIntercom } from "react-use-intercom"

const shouldHideHelpButton = (path: HideFloatingHelpPath, pathName: string) => pathName.startsWith(path)

const useShouldHideHelpButton = () => {
  const location = useLocation()
  const pathName = location.pathname

  return useMemo(() => HIDE_FLOATING_HELP_PATHS.some(path => shouldHideHelpButton(path, pathName)), [pathName])
}

export const FloatingHelpButton = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const speedDialRef = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false)
  const { boot, show, hide } = useIntercom()
  const [isVisibleIntercom, setIsVisibleIntercom] = useState(false)
  const { user, isAuthenticated } = useAuth()
  const hideHelpButton = useShouldHideHelpButton()

  const handleClickSpeedDial = () => {
    setOpen(!open)
    if (isVisibleIntercom) {
      hide()
      setIsVisibleIntercom(false)
    }
  }

  const handleClosePopOver = useCallback(() => setAnchorEl(null), [])

  useEffect(() => {
    setAnchorEl(speedDialRef.current)
    const timer = setTimeout(() => {
      handleClosePopOver()
    }, 3000)

    return () => clearTimeout(timer)
  }, [handleClosePopOver])

  if (hideHelpButton) {
    return null
  }

  const showPopover = !!anchorEl

  const handleChatClick = async () => {
    if (isAuthenticated) {
      const { userHash } = await getUserHashForIntercom(user?.username)

      boot({
        hideDefaultLauncher: true,
        userHash,
        verticalPadding: 140,
        horizontalPadding: 100,
        email: user?.email,
        name: user?.displayName,
        userId: user?.username,
        phone: user?.phone_number,
        company: {
          companyId: user?.companyInfo?.id,
          name: user?.companyInfo?.companyInfo?.companyName,
        },
        customAttributes: { application: "HRA Hub" },
        actionColor: takeCommandPrimary.main,
        backgroundColor: takeCommandPrimary.main,
      })
    } else {
      boot({
        hideDefaultLauncher: true,
        verticalPadding: 140,
        horizontalPadding: 100,
        actionColor: takeCommandPrimary.main,
        backgroundColor: takeCommandPrimary.main,
        customAttributes: { application: "HRA Hub" },
      })
    }
    show()
    setIsVisibleIntercom(true)
  }

  return (
    <>
      <SpeedDial
        ariaLabel="Help"
        data-qa="help-icon-main-button"
        sx={{
          position: "fixed",
          zIndex: "1",
          right: { xs: "1.5rem", sm: "2rem" },
          bottom: { xs: "1.5rem", sm: "4rem" },
        }}
        icon={
          <SpeedDialIcon
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            icon={
              <ContactSupportOutlined
                sx={{
                  width: { xs: "1.25rem", md: "2rem" },
                  height: { xs: "1.25rem", md: "2rem" },
                }}
              />
            }
            openIcon={
              <Close
                sx={{
                  width: { xs: "1.25rem", md: "2rem" },
                  height: { xs: "1.25rem", md: "2rem" },
                }}
              />
            }
          />
        }
        direction="left"
        ref={speedDialRef}
        aria-owns={showPopover ? "help-message-popover" : undefined}
        onClick={handleClickSpeedDial}
        open={open}
      >
        <SpeedDialAction
          key="Call"
          icon={<HeadsetMic data-qa="help-icon-call-button" />}
          tooltipTitle="Call"
          FabProps={{
            href: `tel:+${cleanUpUsPhoneNumber(PHONE_NUMBER)}`,
          }}
        />
        <SpeedDialAction
          key="Chat"
          icon={<ChatOutlined data-qa="help-icon-chat-button" />}
          tooltipTitle="Chat"
          sx={{ mr: -20, mt: -12 }}
          onClick={handleChatClick}
        />
      </SpeedDial>
      <Popover
        id="help-message-popover"
        open={showPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopOver}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          ".MuiPopover-paper": {
            ml: 10,
          },
        }}
      >
        <Box
          sx={{
            p: 4,
            maxWidth: "17rem",
            display: "flex",
            gap: 2,
          }}
        >
          <Typography data-qa="help-icon-help-text">
            Hi 👋! You’re not alone in this! Our dedicated support team is here to lend a helping hand!
          </Typography>
          <Box>
            <IconButton size="small" onClick={handleClosePopOver} sx={{ padding: 0 }}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </Popover>
    </>
  )
}
