import { Falsey } from "lodash"

export type DataQa = Lowercase<string> & { readonly __tag: unique symbol }

interface BaseProps<Qa extends DataQa = DataQa> {
  "data-qa": Qa
}

export type WithDataQa<Props extends object = object, Qa extends DataQa = DataQa> = Props & BaseProps<Qa>

export const createDataQa = (...args: (string | number | Falsey)[]) =>
  args
    .filter(e => !!e)
    .join("-")
    .replace(/[\s_]/g, "")
    .toLowerCase() as DataQa
