import { Uuid } from "@/utils/types"
import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"
import { PERSONAL_INFO, RECOMMENDED_PLANS } from "./benefitsElectionConstants"
import {
  Allowance,
  BenefitsElectionPath,
  DoctorPreference,
  DrugPreference,
  EligibleApplicant,
  EnrollmentType,
  HealthBenefitsElection,
  HospitalPreference,
  PersonalInformation,
  Plan,
  PlanGridState,
  PlanView,
  ShoppingPerson,
  ShoppingSession,
} from "./benefitsElectionTypes"
import { validateApplicants, validateFamilyMembers } from "./benefitsElectionUtils"

interface BenefitsElectionState {
  currentShoppingSession: ShoppingSession
  employee: ShoppingPerson
  familyMembers: ShoppingPerson[]
  householdIncome: number
  allowance?: Allowance
  employeeOnlyAllowance?: Allowance
  // FUTURE: Remove explicit anys
  recommendationAnswers?: any
  radius: number
  selectedPlan?: Plan
  plansToCompare: Plan[]
  planView: PlanView
  currentStep: BenefitsElectionPath
  planGridState?: PlanGridState

  showPreferences: boolean
  showFamily: boolean
  showFindPlan: boolean
  showPurchase: boolean
  showMedicarePurchaseFlow: boolean

  renewalPlanId?: string
}

interface BenefitsElectionMethods {
  getEligibleApplicants: () => EligibleApplicant[]
  getEnrollmentType: () => EnrollmentType | undefined
  getEligibleFamilyMembers: () => EligibleApplicant[]

  // FUTURE: Use useQuery to treat the API as the single source of truth for shopping session
  setCurrentShoppingSession: (shoppingSession: ShoppingSession) => void
  setHealthBenefitElection: (healthBenefitElection: HealthBenefitsElection) => void
  setEmployee: (employee: ShoppingPerson) => void
  setEmployeePersonalInformation: (personalInformation: PersonalInformation) => void
  setEmployeeId: (employeeId: Uuid) => void
  setEmployeeDoctors: (doctors: DoctorPreference[]) => void
  setEmployeeHospitals: (hospitals: HospitalPreference[]) => void
  setEmployeePrescriptions: (prescriptions: DrugPreference[]) => void
  setRecommendationAnswers: (recommendationAnswers: any[]) => void
  setRadius: (radius: number) => void
  setFamilyMembers: (familyMembers: ShoppingPerson[]) => void
  setHouseholdIncome: (householdIncome: number) => void
  setAllowance: (allowance: Allowance) => void
  setEmployeeOnlyAllowance: (employeeOnlyAllowance: Allowance) => void
  setSelectedPlan: (selectedPlan: Plan | undefined) => void
  setCurrentStep: (currentStep: BenefitsElectionPath) => void
  setPlansToCompare: (plansToCompare: Plan[]) => void
  setPlanView: (planView: PlanView) => void
  setPlanGridState: (planGridState: PlanGridState) => void

  setShowPreferences: (showPreferences: boolean) => void
  setShowFamily: (showFamily: boolean) => void
  setShowFindPlan: (showFindPlan: boolean) => void
  setShowPurchase: (showPurchase: boolean) => void
  setShowMedicarePurchaseFlow: (showMedicarePurchaseFlow: boolean) => void

  clearBenefitsElectionStore: () => void
  setRenewalPlanId: (renewalPlanId: string) => void
}

export interface BenefitsElectionStore extends BenefitsElectionState, BenefitsElectionMethods {}

const INITIAL_STATE: BenefitsElectionState = {
  // FUTURE: Use useQuery to treat the API as the single source of truth for shopping session
  currentShoppingSession: {
    planYear: new Date().getFullYear() + 1,
    // FUTURE: We are unsafely lying to the TypeScript compiler here
    // If this is indeed safe to do, then we should have a SAFETY comment explaining why this won't cause problems
  } as ShoppingSession,
  employee: { doctors: [], hospitals: [], prescriptions: [] },
  familyMembers: [],
  householdIncome: 0,
  allowance: undefined,
  employeeOnlyAllowance: undefined,
  recommendationAnswers: undefined,
  radius: 50,
  selectedPlan: undefined,
  currentStep: PERSONAL_INFO,
  plansToCompare: [],
  planView: RECOMMENDED_PLANS,

  showPreferences: false,
  showFamily: false,
  showFindPlan: false,
  showPurchase: false,
  showMedicarePurchaseFlow: false,
  renewalPlanId: undefined,
}

export const useBenefitsElectionStore = create<BenefitsElectionStore>()(
  persist(
    (set, get) => ({
      ...INITIAL_STATE,

      getEligibleApplicants: () => {
        const employee = get().employee
        const familyMembers = get().familyMembers

        return validateApplicants([employee, ...familyMembers], employee.personalInformation?.zipCode?.fipsCode!)
      },

      getEligibleFamilyMembers: () => {
        const employee = get().employee
        const familyMembers = get().familyMembers

        return validateFamilyMembers([employee, ...familyMembers])
      },

      getEnrollmentType: () => get().selectedPlan?.carrier.enrollment,

      setCurrentShoppingSession: shoppingSession => set(() => ({ currentShoppingSession: shoppingSession })),
      // FUTURE: Update this function to support working with multiple health benefit elections
      setHealthBenefitElection: healthBenefitElection =>
        set(state => {
          const updatedShoppingPersons = state.currentShoppingSession.shoppingPersons?.map(shoppingPerson => ({
            ...shoppingPerson,
            healthBenefitElections: [healthBenefitElection.id],
          }))

          return {
            currentShoppingSession: {
              ...state.currentShoppingSession,
              healthBenefitElections: [healthBenefitElection],
              shoppingPersons: updatedShoppingPersons,
            },
          }
        }),
      setEmployee: employee => set(() => ({ employee })),
      setEmployeePersonalInformation: personalInformation =>
        set(state => ({ employee: { ...state.employee, personalInformation } })),
      setEmployeeId: employeeId => set(state => ({ employee: { ...state.employee, shoppingPersonId: employeeId } })),
      setEmployeeDoctors: doctors => set(state => ({ employee: { ...state.employee, doctors } })),
      setEmployeeHospitals: hospitals => set(state => ({ employee: { ...state.employee, hospitals } })),
      setEmployeePrescriptions: prescriptions => set(state => ({ employee: { ...state.employee, prescriptions } })),
      setRecommendationAnswers: recommendationAnswers => set(() => ({ recommendationAnswers })),
      setRadius: radius => set(() => ({ radius })),
      setFamilyMembers: familyMembers => set(() => ({ familyMembers })),
      setHouseholdIncome: householdIncome => set(() => ({ householdIncome })),
      setSelectedPlan: selectedPlan => set(() => ({ selectedPlan })),
      setAllowance: allowance => set(() => ({ allowance })),
      setEmployeeOnlyAllowance: employeeOnlyAllowance => set(() => ({ employeeOnlyAllowance })),
      setCurrentStep: currentStep => set(() => ({ currentStep })),
      setPlansToCompare: plansToCompare => set(() => ({ plansToCompare })),
      setPlanView: planView => set(() => ({ planView })),
      setPlanGridState: planGridState => set(() => ({ planGridState })),

      setShowPreferences: showPreferences => set(() => ({ showPreferences })),
      setShowFamily: showFamily => set(() => ({ showFamily })),
      setShowFindPlan: showFindPlan => set(() => ({ showFindPlan })),
      setShowPurchase: showPurchase => set(() => ({ showPurchase })),
      setShowMedicarePurchaseFlow: showMedicarePurchaseFlow => set(() => ({ showMedicarePurchaseFlow })),

      setRenewalPlanId: renewalPlanId => set(() => ({ renewalPlanId })),

      clearBenefitsElectionStore: () => set(INITIAL_STATE),
    }),
    {
      name: "benefits-election-store",
      storage: createJSONStorage(() => localStorage),
      version: 0,
    }
  )
)

export const useUpdateSteps = () => {
  const setShowPreferences = useBenefitsElectionStore(state => state.setShowPreferences)
  const setShowFamily = useBenefitsElectionStore(state => state.setShowFamily)
  const setShowFindPlan = useBenefitsElectionStore(state => state.setShowFindPlan)
  const setShowPurchase = useBenefitsElectionStore(state => state.setShowPurchase)
  const setShowMedicarePurchaseFlow = useBenefitsElectionStore(state => state.setShowMedicarePurchaseFlow)

  return (isMedicare: boolean, isMedicaid: boolean) => {
    const showPreferences = !isMedicare && !isMedicaid
    const showFamily = !isMedicaid
    const showFindPlan = !isMedicare && !isMedicaid
    const showPurchase = !isMedicaid
    const showMedicarePurchaseFlow = isMedicare

    setShowPreferences(showPreferences)
    setShowFamily(showFamily)
    setShowFindPlan(showFindPlan)
    setShowPurchase(showPurchase)
    setShowMedicarePurchaseFlow(showMedicarePurchaseFlow)

    return { showPreferences, showFamily, showFindPlan, showPurchase }
  }
}
