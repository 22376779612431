import { EXTERNAL_LINKS } from "@/constants"
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined"
import { Alert, AlertProps, Grid, Link, Typography } from "@mui/material"
import { NavLink } from "react-router-dom"
import { URL_CALL_SPECIALIST, WAIVE_COVERAGE } from "../benefitsElectionConstants"
import { ShoppingPerson, ShoppingUrl } from "../benefitsElectionTypes"
import { getDisplayName } from "../benefitsElectionUtils"

export const WarningAlert = ({ sx, children, ...props }: AlertProps) => (
  <Alert
    sx={{ backgroundColor: "colors.amber", ...sx }}
    icon={<WarningAmberOutlinedIcon sx={{ height: "1.5rem", width: "1.5rem", color: "colors.lightWarning" }} />}
    {...props}
  >
    <Typography>{children}</Typography>
  </Alert>
)

// FUTURE: Remove this optional chain in a template literal
const joinMembersNames = (members: ShoppingPerson[]) =>
  members.map(member => getDisplayName(member.personalInformation!)).join(", ")

interface FamilyAlertProps {
  members: ShoppingPerson[]
}

interface FamilyAlertWithOptionalProps {
  members?: ShoppingPerson[]
}

export const FamilyMedicareAlert = ({ members }: FamilyAlertProps) => {
  const membersNames = joinMembersNames(members)
  const membersAmount = members.length
  const isOrAreString = membersAmount > 1 ? "are" : "is"
  const qualifyString = membersAmount > 1 ? "qualify" : "qualifies"

  return (
    <Grid item xs={12}>
      <WarningAlert data-qa="medicare-member-alert">
        <Typography variant="body1bold">{membersNames}</Typography>
        {` ${isOrAreString} aged above 65 and ${qualifyString} for Medicare.
      As a result, they will not be included in the full plan package selection. Please follow the `}
        <Link href={EXTERNAL_LINKS.MEDICARE_PARTNER} underline="always" target="_blank" rel="noopener noreferrer">
          link
        </Link>{" "}
        to continue signing up with our partner.
      </WarningAlert>
    </Grid>
  )
}

export const FamilyMedicaidAlert = ({ members }: FamilyAlertWithOptionalProps) => {
  const memberNamesString = members ? joinMembersNames(members) : "This individual"

  return (
    <Grid item xs={12}>
      <WarningAlert data-qa="medicaid-member-alert">
        <Typography variant="body1bold">{memberNamesString}</Typography>
        {` cannot be included in the comprehensive plan package selection due to their
      ineligibility to enroll. They are required to opt out of coverage by waiving it.`}
      </WarningAlert>
    </Grid>
  )
}

export const DifferentAddressAlert = ({ members }: FamilyAlertWithOptionalProps) => {
  const memberNamesString = members ? joinMembersNames(members) : "This individual"

  return (
    <Grid item xs={12}>
      <WarningAlert data-qa="different-address-member-alert">
        <Typography variant="body1bold">{memberNamesString}</Typography>
        {` will not be included in your
        shopping plan if they reside in a different county than yours. Please follow the `}
        <Link href={URL_CALL_SPECIALIST} underline="always" target="_blank" rel="noopener noreferrer">
          link
        </Link>{" "}
        to request a call with one of our enrollment specialists for more assistance.
      </WarningAlert>
    </Grid>
  )
}

interface MedicareAlertProps {
  shoppingUrl: ShoppingUrl
  isAvailable?: boolean
  addresseeStatement?: string
}

export const MedicareAlert = ({ shoppingUrl, isAvailable, addresseeStatement }: MedicareAlertProps) => (
  <Grid item xs={12}>
    <WarningAlert data-qa="medicare-alert" sx={{ mt: 0 }}>
      {addresseeStatement || "You are"} over the age of 65, we suggest you{" "}
      <Link href={EXTERNAL_LINKS.MEDICARE_PARTNER} target="_blank" sx={{ color: "colors.neonBlue" }}>
        visit
      </Link>{" "}
      our partner to continue your process. If you have already shopped and obtained a medicare card, continue to upload
      your proof of coverage.
    </WarningAlert>
  </Grid>
)

interface MedicaidAlertProps {
  shoppingUrl: ShoppingUrl
}

export const MedicaidAlert = ({ shoppingUrl }: MedicaidAlertProps) => (
  <Grid item xs={12}>
    <WarningAlert data-qa="medicaid-alert" sx={{ mt: 0 }}>
      You are Medicaid eligible. Sorry, Medicaid does not qualify as a participating plan for the ICHRA benefit. We are
      going to save your information here.{" "}
      <Link component={NavLink} to={shoppingUrl + WAIVE_COVERAGE} sx={{ color: "colors.waterBlue" }}>
        Waive here
      </Link>
    </WarningAlert>
  </Grid>
)
