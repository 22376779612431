import { grey, teal } from "@mui/material/colors"

export interface CustomColors {
  lightGreyButton: string
  darkBody: string
  pageBackgroundColor: string
  avatarBackground: string
  white: string
  lightGrayText: string
  lightBlueShade: string
  teal: string
  lightTealShade: string
  gumDropGreen: string
  mediumTealShade: string
  borderGray: string
  borderGreen: string
  regionGray: string
  lightErrorDark: string
  grey: string
  amber: string
  lightWarning: string
  boneChilling: string
  mintGreen: string

  // People status chip colors
  pumpkin: string
  forest: string
  seaPink: string
  surfCrest: string
  paleOlive: string
  silverSand: string
  waterBlue: string
  periwinkle: string

  // Shopping plans colors
  bronze: string
  silver: string
  gold: string
  platinum: string
  expandedBronze: string
  pineGreen: string
  darkCyan: string
  aquamarine: string
  oldLace: string
  honeyDew: string
  neonBlue: string
  lightBlack: string
  lightGray: string
}

declare module "@mui/material/styles" {
  interface Palette {
    colors: CustomColors
  }
  interface PaletteOptions {
    colors: CustomColors
  }
}

export const takeCommandPrimary = {
  main: "#00796B",
  dark: "#174235",
  light: "#6DD6BB",
  100: "#31A58210",
  200: "#31A58220",
  500: "#31A58250",
  600: "#31A58260",
  700: "#31A58270",
  800: "#31A58280",
  captionGray: grey[500],
  actionTeal: teal[700],
} as const

export const takeCommandSecondary = {
  main: "#D7B58D",
  dark: "#D27D56",
  light: "#E8D2B8",
  500: "#D7B58D50",
  600: "#D7B58D60",
  700: "#D7B58D70",
  800: "#D7B58D80",
} as const

export const colors = {
  lightGreyButton: "#BDC1C0",
  darkBody: "#27322F",
  pageBackgroundColor: "#F7F9FC",
  avatarBackground: "#D9D9D9",
  white: "#FFF",
  lightGrayText: "#626A68",
  lightBlueShade: "rgba(49, 165, 130, 0.12)",
  teal: "#31A582",
  lightTealShade: "rgba(49, 165, 130, 0.08)",
  gumDropGreen: "rgba(49, 165, 130, 0.12)",
  mediumTealShade: "rgba(49, 165, 130, 0.22)",
  borderGray: "rgba(0, 0, 0, 0.12)",
  borderGreen: "#0e7a6c",
  regionGray: "#F8F7F5",
  lightErrorDark: "#B71C1C",
  glazedRinglet: "rgba(183, 28, 28, 0.1)",
  calgarBlue: "rgb(2, 136, 209, .10)",
  fieryGarnet: "rgb(183, 28, 28, .10)",
  tailLights: "rgba(216, 67, 21, 1)",
  creamyCloudDreams: "rgba(255, 243, 224, 1)",
  grey: "#E0E0E0",
  amber: "#FFF8E1",
  lightWarning: "#FFA000",
  boneChilling: "#E0F2F1",
  mintGreen: "#CAE5E3",

  // People status chip colors
  pumpkin: "#F57F17",
  forest: "#2E7D32",
  seaPink: "#EF9A9A",
  surfCrest: "#C8E6C9",
  paleOlive: "#aed581",
  silverSand: "#bdc1c0",
  waterBlue: "rgba(2, 136, 209)",
  waterBlueLight: "rgba(2, 136, 209, .1)",
  periwinkle: "#C5CAE9",

  // Shopping plans colors
  bronze: "#D27D56",
  silver: "#BDC1C0",
  gold: "#FFB400",
  platinum: "#CFD8DC",
  expandedBronze: "#E8D2B8",
  pineGreen: "#00695C",
  darkCyan: "#00897B",
  aquamarine: "#B2DFDB",
  oldLace: "#FFF3E0",
  honeyDew: "#E8F5E9",
  neonBlue: "#2962FF",
  lightBlack: "rgba(0, 0, 0, 0.04)",
  lightGray: "#8C9196",
  vermilion: "#E53935",
  lightCyan: "#B2EBF2",
  mustard: "#FFD54F",

  // Dashboard colors
  bluePie: "#457B9D",
  orangePie: "#F4A261",
  greenPie: "#2A9D8F",
  greyToolTipPie: "#6D6D6D",
  lightGreyBorder: "#ccc",
} as const
