import { useNotifications } from "@/services/notificationService"
import { useEffect, useState } from "react"
import { getCompanyBillingInformation } from "../settingsEndpoints"
import { PaymentInformation } from "../types/paymentTypes"

export const useGetPaymentDetails = ({ companyId }: { companyId?: string }) => {
  const [loadingPaymentDetails, setLoadingPaymentDetails] = useState(true)
  const { notify } = useNotifications("get-payment-details")
  const [paymentDetails, setPaymentDetails] = useState<PaymentInformation>({ kind: null })

  const handleError = (error: any) => {
    const message = error.message || "Something went wrong"

    console.warn(message)

    notify("An error occurred when retrieving your Payment Details. Please try again later.", "error")
  }

  const getBillingInformation = async () => {
    try {
      // Get the Stripe checkout URL
      const billingInformation = await getCompanyBillingInformation(companyId!)

      setPaymentDetails(billingInformation)
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoadingPaymentDetails(false)
    }
  }

  useEffect(
    () => {
      getBillingInformation()
    },
    // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId]
  )

  return { loadingPaymentDetails, paymentDetails }
}
