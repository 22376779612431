import { takeCommandPrimary } from "@/theme/palette"
import { createDataQa, DataQa } from "@/utils/dataQa"
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import Clear from "@mui/icons-material/Clear"
import { Chip, ChipProps, Grid } from "@mui/material"
import { Key, ReactElement } from "react"

export const StandardChip = (props: ChipProps) => <Chip variant="outlined" {...props} />

export const DeletableChip = ({ "data-qa": dataQa, ...props }: ChipProps & { "data-qa": DataQa }) => (
  <StandardChip
    deleteIcon={<Clear data-qa={createDataQa(dataQa ?? "deletable-chip", "delete-button")} />}
    data-qa={dataQa}
    {...props}
  />
)

interface ChipsListProps<Option> {
  selections: Option[]
  formatSelection: (option: Option) => { key: Key; label: string }
}

export const ChipsList = <Option,>({ selections, formatSelection }: ChipsListProps<Option>) => (
  <Grid container sx={{ gap: 2 }}>
    {selections.map(selection => {
      const { key, label } = formatSelection(selection)
      return (
        <StandardChip
          key={key}
          label={label}
          data-qa={createDataQa(label, "selection-chip")}
          sx={{ backgroundColor: takeCommandPrimary[200] }}
        />
      )
    })}
  </Grid>
)

export type SelectableChipProps = ChipProps & {
  selected: boolean
  "data-qa": DataQa
  selectedBackgroundColor: string
  selectedTextColor?: string
  selectedIcon?: ReactElement
}

export const SelectableChip = ({
  selected,
  "data-qa": dataQa,
  selectedBackgroundColor,
  selectedIcon = (
    <CheckOutlinedIcon sx={{ width: "1rem", height: "1rem" }} data-qa={createDataQa(dataQa, "selected-icon")} />
  ),
  sx = {},
  ...props
}: SelectableChipProps) =>
  selected ? (
    <StandardChip
      variant="outlined"
      data-qa={dataQa}
      icon={selectedIcon}
      sx={{
        backgroundColor: selectedBackgroundColor,
        pl: 1,
        ...sx,
      }}
      {...props}
    />
  ) : (
    <StandardChip variant="outlined" data-qa={dataQa} sx={sx} {...props} />
  )

export type SelectableAndDeletableChipProps = ChipProps & {
  selected: boolean
  "data-qa": DataQa
  selectedBackgroundColor: string
  selectedTextColor?: string
  onUnselect: () => void
  onSelect: () => void
}

export const SelectableAndDeletableChip = ({
  selected,
  "data-qa": dataQa,
  selectedBackgroundColor,
  selectedTextColor = "white",
  onUnselect,
  onSelect,
  ...props
}: SelectableAndDeletableChipProps) =>
  selected ? (
    <StandardChip
      data-qa={dataQa}
      sx={{ backgroundColor: selectedBackgroundColor, color: selectedTextColor }}
      deleteIcon={
        <Clear
          data-qa={createDataQa(dataQa ?? "selectable-chip", "delete-button")}
          sx={{
            color: `${selectedTextColor} !important`,
            paddingRight: 1,
          }}
        />
      }
      onDelete={onUnselect}
      {...props}
    />
  ) : (
    <StandardChip data-qa={dataQa} sx={{ borderColor: selectedBackgroundColor }} onClick={onSelect} {...props} />
  )
