import background from "@/assets/img/background.png"
import { DeletableChip, SelectableChip, StandardChip } from "@/components/Chips"
import { HraQuizNavbar } from "@/features/HRAPlanDesign/components/HraQuizNavbar"
import { ChipPersonStatus } from "@/features/People/components/ChipPersonStatus"
import { PEOPLE_STATUS } from "@/features/People/peopleConstants"
import { createDataQa } from "@/utils/dataQa"
import { toTitleCase } from "@/utils/formatting"
import { EmotionJSX } from "@emotion/react/types/jsx-namespace"
import AccessibilityIcon from "@mui/icons-material/Accessibility"
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined"
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined"
import CloseIcon from "@mui/icons-material/Close"
import LaunchIcon from "@mui/icons-material/Launch"
import MailIcon from "@mui/icons-material/Mail"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Link,
  Switch,
  Tab,
  TextField,
  Typography,
} from "@mui/material"
import Chip from "@mui/material/Chip"
import { DatePicker } from "@mui/x-date-pickers"
import { Formik } from "formik"
import { noop } from "lodash"
import { ReactNode, SyntheticEvent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useSearchParams } from "react-router-dom"
import { Banner } from "./Banners"
import { ClearableSearchInput } from "./ClearableSearchInput/ClearableSearchInput"
import { ConfirmationModal } from "./ConfirmationModal"
import { DatePickerField } from "./DatePickerField"
import { FileUploader } from "./FileUploader"
import { SelectField } from "./SelectField"
import { SkeletonTableLoader } from "./SkeletonTableLoader"
import {
  CityTextField,
  EmailTextField,
  FirstNameTextField,
  LastNameTextField,
  MiddleNameTextField,
  PasswordTextField,
  PreferredNameTextField,
  Street1TextField,
  Street2TextField,
} from "./TextFields"

export interface SubSectionDividerProps {
  children: ReactNode
}

export const SubSectionDivider = ({ children }: SubSectionDividerProps) => (
  <Grid item xs={12} sx={{ pb: 3, pt: 4, mt: 4 }}>
    <Typography>{children}</Typography>
  </Grid>
)

export interface SubSectionContainerProps {
  children: ReactNode
}

export const SubSectionContainer = ({ children }: SubSectionContainerProps) => (
  <Grid container sx={{ mt: 10, p: 8 }}>
    {children}
  </Grid>
)

export interface SubSectionHeaderProps {
  children: ReactNode
}

export const SubSectionHeader = ({ children }: SubSectionHeaderProps) => (
  <Grid item sx={{ mb: 3, borderBottom: "1px solid black" }}>
    <Typography variant="h3tiempos" mb={5}>
      {children}
    </Typography>
  </Grid>
)

const buttonStyle = { mr: 4, mb: 3 }

export const ButtonVariants = () => (
  <SubSectionContainer>
    <SubSectionHeader>Buttons</SubSectionHeader>
    <Grid container>
      <SubSectionDivider>Start Icon</SubSectionDivider>
      <Button variant="contained" color="primary" startIcon={<AccessibilityIcon />} sx={buttonStyle}>
        Primary Contained
      </Button>
      <Button variant="outlined" color="primary" startIcon={<AccessibilityIcon />} sx={buttonStyle}>
        Primary Outlined
      </Button>
      <Button variant="contained" disabled startIcon={<AccessibilityIcon />} sx={buttonStyle}>
        Disabled Contained
      </Button>
      <Button variant="outlined" disabled sx={{ mr: 4 }}>
        Disabled Outlined
      </Button>
      <Button variant="contained" color="error" startIcon={<AccessibilityIcon />} sx={{ mr: 4 }}>
        Error Contained
      </Button>
      <Button variant="outlined" color="error" startIcon={<AccessibilityIcon />}>
        Error Contained
      </Button>
    </Grid>
    <Grid container sx={{ my: 4 }}>
      <SubSectionDivider>No Icon</SubSectionDivider>
      <Button variant="contained" color="primary" sx={buttonStyle}>
        Primary Filled
      </Button>
      <Button variant="outlined" color="primary" sx={buttonStyle}>
        Primary Outlined
      </Button>
      <Button variant="contained" disabled sx={buttonStyle}>
        Disabled Contained
      </Button>
      <Button variant="outlined" disabled sx={buttonStyle}>
        Disabled Outlined
      </Button>
      <Button variant="contained" color="error" sx={{ mr: 4 }}>
        Error Outlined
      </Button>
      <Button variant="outlined" color="error">
        Error Outlined
      </Button>
    </Grid>
    <Grid container>
      <SubSectionDivider>End Icon</SubSectionDivider>
      <Button variant="contained" color="primary" endIcon={<AccessibilityIcon />} sx={buttonStyle}>
        Primary Filled
      </Button>
      <Button variant="outlined" color="primary" endIcon={<AccessibilityIcon />} sx={buttonStyle}>
        Primary Outlined
      </Button>
      <Button variant="contained" disabled endIcon={<AccessibilityIcon />} sx={buttonStyle}>
        Disabled Contained
      </Button>
      <Button variant="outlined" disabled endIcon={<AccessibilityIcon />} sx={buttonStyle}>
        Disabled Outlined
      </Button>
      <Button variant="contained" color="error" endIcon={<AccessibilityIcon />} sx={{ mr: 4 }}>
        Error Outlined
      </Button>
      <Button variant="outlined" color="error" endIcon={<AccessibilityIcon />}>
        Error Outlined
      </Button>
    </Grid>
  </SubSectionContainer>
)

export const TypographyVariants = () => (
  <SubSectionContainer>
    <SubSectionHeader>Typography</SubSectionHeader>
    <Grid container direction="column" sx={{ pt: 4 }}>
      <Typography variant="h140">h1 40</Typography>
      <Typography variant="h1">h1</Typography>
      <Typography variant="h2">h2</Typography>
      <Typography variant="h2small">h2 small</Typography>
      <Typography variant="h2tiempos">h2 tiempos</Typography>
      <Typography variant="h3">h3</Typography>
      <Typography variant="h3tiempos">h3 tiempos</Typography>
      <Typography variant="h3small">h3 small</Typography>
      <Typography variant="h4">h4</Typography>
      <Typography variant="h5">h5</Typography>
      <Typography variant="h6">h6</Typography>
      <Typography variant="body1">body 1</Typography>
      <Typography variant="body1bold">body 1 bold</Typography>
      <Typography variant="body2">body 2</Typography>
      <Typography variant="body2bold">body 2 bold</Typography>
      <Typography variant="caption">caption</Typography>
      <Typography variant="subtitle1">subtitle 1</Typography>
      <Typography variant="subtitle2">subtitle 2</Typography>
      <Typography variant="overline">overline</Typography>
      <Typography variant="badges11">badges 11</Typography>
    </Grid>
  </SubSectionContainer>
)

export const BadgeVariants = () => (
  <SubSectionContainer>
    <SubSectionHeader>Badges</SubSectionHeader>
    <Grid container sx={{ pt: 4 }}>
      <Badge color="secondary" badgeContent={0} sx={{ mr: 4 }}>
        <MailIcon />
      </Badge>
      <Badge color="secondary" badgeContent={4} showZero>
        <MailIcon />
      </Badge>
      <Badge color="secondary" badgeContent={4}>
        <Button variant="outlined" color="primary" sx={{ ml: 4 }}>
          Button
        </Button>
      </Badge>
    </Grid>
  </SubSectionContainer>
)

const alertStyle = { mb: 4 }

export const AlertVariants = () => (
  <SubSectionContainer>
    <SubSectionHeader>Alerts</SubSectionHeader>
    <Grid container direction="column" sx={{ mt: 4 }}>
      <Alert severity="error" sx={{ mb: 4, mt: 4 }}>
        This is an error alert — check it out!
      </Alert>
      <Alert severity="warning" sx={alertStyle}>
        This is a warning alert — check it out!
      </Alert>
      <Alert severity="info" sx={alertStyle}>
        This is an info alert — check it out!
      </Alert>
      <Alert severity="success" sx={alertStyle}>
        This is a success alert — check it out!
      </Alert>
      <Alert variant="outlined" severity="success" sx={alertStyle}>
        This is an outlined success Alert.
      </Alert>
      <Alert variant="outlined" severity="info" sx={alertStyle}>
        This is an outlined info Alert.
      </Alert>
      <Alert variant="outlined" severity="warning" sx={alertStyle}>
        This is an outlined warning Alert.
      </Alert>
      <Alert variant="outlined" severity="error" sx={alertStyle}>
        This is an outlined error Alert.
      </Alert>
      <Alert severity="warning" onClose={noop} sx={alertStyle}>
        This Alert displays the default close icon.
      </Alert>
      <Alert
        severity="success"
        action={
          <Button color="inherit" size="small">
            UNDO
          </Button>
        }
        sx={alertStyle}
      >
        This Alert uses a Button component for its action.
      </Alert>
    </Grid>
  </SubSectionContainer>
)

export const LinkExample = () => (
  <SubSectionContainer>
    <SubSectionHeader>Link</SubSectionHeader>
    <Grid item xs={12}>
      <Link href="#">Link here</Link>
    </Grid>
  </SubSectionContainer>
)

export const Loaders = () => (
  <SubSectionContainer>
    <SubSectionHeader>Loaders</SubSectionHeader>
    <SubSectionDivider>Circular</SubSectionDivider>
    <CircularProgress size={25} />
    <SubSectionDivider>Linear Bar</SubSectionDivider>
    <Grid item sx={{ width: "100% " }}>
      <LinearProgress color="primary" />
    </Grid>
    <SubSectionDivider>Table Loader</SubSectionDivider>
    <Grid container>
      <SkeletonTableLoader
        headerTitles={["Column Name 1", "Column Name 2", "Column Name 3", "Column Name 4"]}
        bodyRowsCount={5}
        data-qa="placeholder"
      />
    </Grid>
  </SubSectionContainer>
)

export const ClearableSearchInputExample = () => (
  <SubSectionContainer>
    <SubSectionHeader>Clearable Search Input</SubSectionHeader>
    <Grid container direction="column">
      <Grid item>
        <ClearableSearchInput value="Search" onChange={noop} data-qa="search-input" placeholder="Search" />
      </Grid>
    </Grid>
  </SubSectionContainer>
)

interface ChipExampleProps {
  "data-qa"?: string
}

export const ChipExamples = ({ "data-qa": dataQa }: ChipExampleProps) => (
  <SubSectionContainer>
    <SubSectionHeader>Chips</SubSectionHeader>
    <Grid container sx={{ mt: 4 }}>
      <Grid item sx={{ mr: 4, mb: 3 }}>
        <ChipPersonStatus status={PEOPLE_STATUS.TERMINATED} />
      </Grid>
      <Grid item sx={{ mr: 4, mb: 3 }}>
        <DeletableChip label="Deletable Chip" onDelete={noop} data-qa={createDataQa("selection-chip")} />
      </Grid>
      <Grid item sx={{ mr: 4, mb: 3 }}>
        <Chip label="Chip" />
      </Grid>
      <Grid item sx={{ mr: 4, mb: 3 }}>
        <StandardChip
          label={
            <Typography sx={{ textTransform: "capitalize" }} variant="body1">
              Standard Chip
            </Typography>
          }
        />
      </Grid>
      <Box>
        <SelectableChip
          label={<Typography variant="body2">Low to High</Typography>}
          onClick={noop}
          disabled={false}
          selected
          icon={<ArrowUpwardOutlinedIcon sx={{ ml: "0.5rem !important" }} />}
          data-qa={createDataQa(dataQa, "example-chip")}
          selectedBackgroundColor="colors.lightTealShade"
          sx={{
            borderRadius: "32px 0px 0px 32px",
          }}
        />
        <SelectableChip
          label={<Typography variant="body2">High to Low</Typography>}
          onClick={noop}
          disabled={false}
          selected={false}
          icon={<ArrowDownwardOutlinedIcon />}
          data-qa={createDataQa(dataQa, "example-chip")}
          selectedBackgroundColor="colors.lightTealShade"
          sx={{
            borderRadius: " 0px 32px 32px 0px",
            borderLeft: "none",
          }}
        />
      </Box>
    </Grid>
  </SubSectionContainer>
)

export const Icons = () => (
  <SubSectionContainer>
    <SubSectionHeader>Icon Library</SubSectionHeader>
    <Grid item xs={12}>
      <Link href="https://mui.com/material-ui/material-icons/" rel="noopener noreferrer" target="_blank">
        MUI Icons <LaunchIcon />
      </Link>
    </Grid>
  </SubSectionContainer>
)

export const FormDrawerExample = () => (
  <SubSectionContainer>
    <SubSectionHeader>Form Drawer</SubSectionHeader>
  </SubSectionContainer>
)

export const DialogExample = () => {
  const [isSimpleDialogOpen, setIsSimpleDialogOpen] = useState<boolean>(false)
  const [isMoreComplexDialogOpen, setIsMoreComplexDialogOpen] = useState<boolean>(false)

  const openSimpleDialog = () => {
    setIsSimpleDialogOpen(true)
  }

  const closeSimpleDialog = () => {
    setIsSimpleDialogOpen(false)
  }

  const openMoreComplexDialog = () => {
    setIsMoreComplexDialogOpen(true)
  }

  const closeMoreComplexDialog = () => {
    setIsMoreComplexDialogOpen(false)
  }

  return (
    <SubSectionContainer>
      <SubSectionHeader>Dialogs</SubSectionHeader>
      <Formik initialValues={{ option1: "", option2: "" }} onSubmit={noop}>
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <Button onClick={openSimpleDialog} variant="outlined" color="primary">
              Simple Dialog
            </Button>
          </Grid>
          <ConfirmationModal
            isOpen={isSimpleDialogOpen}
            onClose={closeSimpleDialog}
            onConfirm={noop}
            isError={false}
            title="Example Dialog"
            message="We have a partner that can help you enroll in Medicare or evaluate your current coverage."
            cancelLabel="Close"
            actionLabel="Button"
            maxWidth="xs"
          />
          <Grid item>
            <Button onClick={openMoreComplexDialog} variant="outlined" color="primary">
              Complex Dialog
            </Button>
          </Grid>
          <Dialog
            open={isMoreComplexDialogOpen}
            onClose={(evt, reason) => {
              if (reason === "backdropClick") {
                closeMoreComplexDialog()
              }
            }}
            scroll="body"
            data-qa="download-banking-activity-modal"
            aria-labelledby="download-banking-activity-modal"
          >
            <DialogTitle>
              <Grid
                container
                sx={{ justifyContent: "space-between", py: 3, alignItems: "center" }}
                className="dialog-headline-container"
              >
                <Typography className="dialog-headline" variant="h3tiempos">
                  Download Activity
                </Typography>
                <CloseIcon
                  data-qa="activity-download-dialog-close-icon-button"
                  className="dialog-close-icon"
                  onClick={closeMoreComplexDialog}
                />
              </Grid>
            </DialogTitle>
            <DialogContent className="dialog-content-container" sx={{ pl: "2rem", pr: "2rem", pb: "1rem", pt: 0 }}>
              <Typography variant="body1" gutterBottom>
                Choose from the list below to download your bank statement.
              </Typography>
              <Grid container spacing={6} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <SelectField
                      required
                      name="option1"
                      label="Select Field 1"
                      placeholder="Please Select"
                      data={[
                        { value: "Option 1", label: "Option 1" },
                        { value: "Option 2", label: "Option 2" },
                      ]}
                      onBlur={noop}
                      dataQa="option-one-dropdown"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <SelectField
                      required
                      name="option2"
                      label="Select Field 2"
                      placeholder="Please Select"
                      data={[
                        { value: "Option 1", label: "Option 1" },
                        { value: "Option 2", label: "Option 2" },
                      ]}
                      onBlur={noop}
                      dataQa="option-two-dropdown"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <DialogActions sx={{ mt: 4, mb: 4, ml: 4, justifyContent: "flex-end" }}>
                <Button variant="text" onClick={closeMoreComplexDialog}>
                  Cancel
                </Button>
                <Button variant="contained" data-qa="statement-download-button" onClick={noop}>
                  Button
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Grid>
      </Formik>
    </SubSectionContainer>
  )
}

export const Tabs = () => {
  interface TabType {
    dataQa: string
    id: string
    title: string
    content: EmotionJSX.Element
  }
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedTab = searchParams.get("tab") ?? "autopay-activity"

  const handleChange = (event: SyntheticEvent, newTab: string) => {
    setSearchParams({ tab: newTab, ...searchParams })
  }

  const ExampleTabOne = () => <Typography variant="body1">Welcome to tab one!</Typography>
  const ExampleTabTwo = () => <Typography variant="body1">Welcome to tab two!</Typography>
  const ExampleTabThree = () => <Typography variant="body1">Welcome to tab three!</Typography>

  const TABS: TabType[] = [
    {
      dataQa: "example-tab-one",
      id: "tab-one",
      title: "Example Tab One",
      content: <ExampleTabOne />,
    },
    {
      dataQa: "example-tab-two",
      id: "tab-two",
      title: "Example Tab Two",
      content: <ExampleTabTwo />,
    },
    {
      dataQa: "example-tab-three",
      id: "tab-three",
      title: "Example Tab Three",
      content: <ExampleTabThree />,
    },
  ]

  return (
    <SubSectionContainer>
      <SubSectionHeader>Tabs</SubSectionHeader>
      <Grid container direction="column">
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              {TABS.map(tab => (
                <Tab label={tab.title} value={tab.id} key={tab.id} data-qa={tab.dataQa} />
              ))}
            </TabList>
          </Box>
          {TABS.map(tab => (
            <TabPanel key={`${tab.id}-panel`} value={tab.id}>
              {tab.content}
            </TabPanel>
          ))}
        </TabContext>
      </Grid>
    </SubSectionContainer>
  )
}

export const AvatarVariants = () => (
  <SubSectionContainer>
    <SubSectionHeader>Avatars</SubSectionHeader>
    <Grid container sx={{ mt: 4 }}>
      <Avatar sx={{ bgcolor: "primary.main", mr: 2 }}>WW</Avatar>
      <Avatar sx={{ bgcolor: "primary.main", mr: 2 }}>W</Avatar>
      <Avatar sx={{ bgcolor: "primary.main", mr: 2 }}>
        <AccessibilityIcon />
      </Avatar>
      <Avatar sx={{ mr: 2 }}>WW</Avatar>
      <Avatar sx={{ mr: 2 }}>W</Avatar>
      <Avatar sx={{ mr: 2 }}>
        <AccessibilityIcon />
      </Avatar>
    </Grid>
  </SubSectionContainer>
)

export const FileUploadDropzone = () => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])

  return (
    <SubSectionContainer>
      <SubSectionHeader>File Upload Dropzone</SubSectionHeader>
      <FileUploader
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        fileFormatDescription="JPG, JPEG, or PNG"
        accept={{
          "image/jpeg": [".jpeg", ".jpg"],
          "image/png": [".png"],
        }}
        maxFiles={5}
      />
    </SubSectionContainer>
  )
}

export const FormFields = () => (
  <SubSectionContainer>
    <SubSectionHeader>Form Fields</SubSectionHeader>
    <SubSectionDivider>Input Fields</SubSectionDivider>
    <Formik initialValues={{}} onSubmit={noop}>
      <Grid container spacing={3} py={3}>
        <Grid item xs={12} sm={6}>
          <FirstNameTextField />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MiddleNameTextField />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LastNameTextField />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PreferredNameTextField />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EmailTextField />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PasswordTextField />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Street1TextField />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Street2TextField />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CityTextField />
        </Grid>
        <SubSectionDivider>Date Picker Field</SubSectionDivider>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            data-qa="hireDate"
            name="hireDate"
            label="Hire Date"
            required
            value={null}
            error={false}
            helperText=""
            fullWidth
            variant="outlined"
            type="date"
            onChange={noop}
            onBlur={noop}
            sx={{ my: 2 }}
          />
        </Grid>
      </Grid>
    </Formik>
  </SubSectionContainer>
)

export const Header = () => (
  <Grid
    container
    alignItems="center"
    sx={{
      width: "100%",
      height: "300px",
      backgroundColor: "primary.main",
      backgroundImage: `url(${background})`,
    }}
  >
    <Typography variant="h1" gutterBottom display="inline" color="colors.white" sx={{ pt: 25, paddingLeft: 8 }}>
      Component Library
    </Typography>
  </Grid>
)

const today = new Date()
const CountdownBanners = () => {
  const [contained, setContained] = useState(true)
  const [date, setDate] = useState(new Date(today.getFullYear(), today.getMonth(), 31))
  const variant = contained ? "contained" : "outlined"

  return (
    <SubSectionContainer>
      <SubSectionHeader>Banners</SubSectionHeader>
      <Grid container sx={{ mt: 4 }}>
        <DatePicker
          value={date}
          onChange={value => setDate(current => value ?? current)}
          renderInput={(params: any) => <TextField {...params} />}
        />
        <FormControlLabel
          label={toTitleCase(variant)}
          onChange={() => setContained(!contained)}
          control={<Switch checked={contained} />}
        />
        <Banner
          title="Lorem ipsum dolor sit amet"
          onClose={noop}
          until={date}
          buttonUrl="https://www.google.com/"
          buttonLabel="Click here"
          variant={variant}
        />
      </Grid>
    </SubSectionContainer>
  )
}

export const ComponentLibrary = () => (
  <>
    <HraQuizNavbar />

    <Grid container data-qa="tc-component-library" pb={30}>
      <Header />
      <Helmet title="Component Library" />
      <ButtonVariants />
      <TypographyVariants />
      <FormFields />
      <BadgeVariants />
      <AvatarVariants />
      <Icons />
      <Tabs />
      <DialogExample />
      <Loaders />
      <ClearableSearchInputExample />
      <LinkExample />
      <ChipExamples />
      <FormDrawerExample />
      <AlertVariants />
      <FileUploadDropzone />
      <CountdownBanners />
    </Grid>
  </>
)
