import { StyledCard } from "@/components/StyledCard"
import { AssignmentOutlined } from "@mui/icons-material"
import { Box, Button, Grid, Typography } from "@mui/material"
import { Reports } from "../reportsTypes"

interface ReportsCardProps extends Reports {
  isButtonDisabled?: boolean
  handleButtonClick?: () => void
}

export const ReportsCard = ({
  name,
  description,
  buttonText,
  imageUrl,
  imageAltText,
  handleButtonClick,
  isButtonDisabled = false,
}: ReportsCardProps) => (
  <StyledCard>
    <Grid container xs={12} justifyContent="center" alignItems="center" py={4}>
      <Grid item>
        <AssignmentOutlined
          preserveAspectRatio="none"
          color="primary"
          style={{
            width: "auto",
            height: "4rem",
          }}
        />
      </Grid>
    </Grid>
    <Box>
      <Typography variant="h5" pb={3}>
        {name}
      </Typography>
      <Typography variant="body1" pb={6}>
        {description}
      </Typography>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button variant="outlined" color="inherit" onClick={handleButtonClick} disabled={isButtonDisabled}>
        {buttonText}
      </Button>
    </Box>
  </StyledCard>
)
