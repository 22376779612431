import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import { SelectMultiField } from "@/components/SelectMultiField"
import { StyledCard } from "@/components/StyledCard"
import { ClampedTextField } from "@/components/TextFields"
import { EXTRA_STEP, NO, SELF_ENROLL, YES, YES_NO_OPTIONS } from "@/constants"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { getStateLabel } from "@/utils/States"
import { validateCarrier } from "@/utils/validations"
import { EditOutlined } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Typography,
} from "@mui/material"
import Badge from "@mui/material/Badge"
import { Formik } from "formik"
import { noop } from "lodash"
import { ChangeEvent, useState } from "react"
import {
  BOTH_EXCHANGE,
  ENNROLLMENT_OPTIONS,
  EXCHANGE_OPTIONS,
  EXTRA_STEP_OPTIONS,
  OFF_EXCHANGE,
  ON_EXCHANGE,
  PAYMENT_OPTIONS,
} from "../tcHubConstants"
import {
  useCreateCarrierIntegration,
  useGetSignedUrl,
  useUpdateCarrierIntegration,
  useUploadCarrierFile,
} from "../tcHubService"
import { CarrierIntegration, CarrierPreSignUrlResponse, EnrollmentContainerValues } from "../tcHubTypes"

const buildFileName = (carrierIntegration: CarrierIntegration) => {
  const today = new Date()
  const milliseconds = today.getTime()

  return `${carrierIntegration.id}-${carrierIntegration.state}-${milliseconds}`
}

const STATUS_OK = 200

interface EnrollmentContainerProps extends EnrollmentContainerValues {
  enrollmentType: string
  disabled: boolean
  handleChange: (e: string | ChangeEvent<any>) => void
  handleBlur: (e: any) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

const EnrollmentContainer = ({
  enrollment,
  extraStepDescription,
  link,
  cutOffDate,
  premiumPullDate,
  isAutoPay,
  enrollmentType,
  paymentOption,
  disabled,
  handleChange,
  handleBlur,
  setFieldValue,
}: EnrollmentContainerProps) => (
  <StyledCard>
    <Grid container item mt={3} ml={3}>
      <Typography variant="h6" mb={4}>
        {enrollmentType}
      </Typography>
      <Grid container item xs={12} py={4} pr={4} spacing={4}>
        <Grid item xs={12}>
          <SelectField
            data-qa="enrollment-select"
            data={[...ENNROLLMENT_OPTIONS]}
            type="text"
            name={enrollment.name}
            label="Enrollment"
            value={enrollment.value}
            placeholder="Select"
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
            required
          />
          {enrollment.error && enrollment.touched && !enrollment.value}
        </Grid>
        {enrollment.value === EXTRA_STEP && (
          <Grid item xs={12}>
            <SelectField
              data-qa="extra-step-select"
              data={[...EXTRA_STEP_OPTIONS]}
              type="text"
              required
              name={extraStepDescription.name}
              label="Extra step (Description)"
              value={extraStepDescription.value}
              placeholder="Select"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
            />
            {extraStepDescription.error && extraStepDescription.touched && !extraStepDescription.value}
          </Grid>
        )}
        <Grid item xs={12}>
          <ClampedTextField
            id="link"
            label="Link"
            type="text"
            data-qa="link"
            value={link.value}
            name={link.name}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required={enrollment.value === SELF_ENROLL}
            disabled={disabled}
            error={!!link.error && link.touched}
            helperText={link.error && link.touched ? link.error : ""}
          />
        </Grid>
        <Grid item xs={6}>
          <ClampedTextField
            id="cut-off-date"
            data-qa="cut-off-date"
            label="Cut-off date"
            type="number"
            value={cutOffDate.value}
            name={cutOffDate.name}
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{ min: 1, max: 31 }}
            disabled={disabled}
            error={!!cutOffDate.error}
            helperText={cutOffDate.error && cutOffDate.touched ? cutOffDate.error : ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <ClampedTextField
            id="premium-pull-date"
            data-qa="premium-pull-date"
            label="Premium pull day"
            type="number"
            value={premiumPullDate.value}
            name={premiumPullDate.name}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
            inputProps={{ min: 1, max: 31 }}
            error={!!premiumPullDate.error && premiumPullDate.touched}
            helperText={premiumPullDate.error && premiumPullDate.touched ? premiumPullDate.error : ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RadioGroupCard
            name={isAutoPay.name}
            label="AutoPay"
            value={isAutoPay.value}
            formName="autopay"
            elements={YES_NO_OPTIONS}
            handleChange={!disabled ? setFieldValue : () => noop}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <RadioGroupCard
            name={paymentOption.name}
            label="Payment Information"
            value={paymentOption.value}
            formName="paymentOption"
            elements={PAYMENT_OPTIONS}
            handleChange={!disabled ? setFieldValue : () => noop}
            disabled={disabled}
            required
          />
        </Grid>
      </Grid>
    </Grid>
  </StyledCard>
)

const getSelectedExchanges = (carrierIntegration: CarrierIntegration) => {
  const initialExchange = []

  if (carrierIntegration.offExchange) {
    initialExchange.push(OFF_EXCHANGE)
  }
  if (carrierIntegration.bothExchanges) {
    initialExchange.push(BOTH_EXCHANGE)
  }
  if (carrierIntegration.onExchange || initialExchange.length === 0) {
    initialExchange.push(ON_EXCHANGE)
  }

  return initialExchange
}

interface CarrierModalProps {
  isOpen: boolean
  onClose: () => void
  carrierIntegration: CarrierIntegration
  isEditing?: boolean
}

const getYesOrNoStringValue = (value: boolean | undefined | string) => {
  if (value === false) {
    return NO
  } else if (value) {
    return YES
  } else {
    return ""
  }
}

export const CarrierModal = ({ isOpen, onClose, carrierIntegration, isEditing = false }: CarrierModalProps) => {
  const { mutateAsync: getSignedUrl } = useGetSignedUrl()
  const { mutateAsync: uploadCarrierFile } = useUploadCarrierFile()
  const { mutateAsync: updateCarrierIntegration } = useUpdateCarrierIntegration()
  const { mutateAsync: createCarrierIntegration } = useCreateCarrierIntegration()
  const [selectedExchanges, setSelectedExchanges] = useState<string[]>(() => getSelectedExchanges(carrierIntegration))
  const [touchedExchange, setTouchedExchange] = useState(false)

  if (!isEditing) {
    // FUTURE: Resolve this violation of the Rules of React and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules
    // eslint-disable-next-line react-compiler/react-compiler
    carrierIntegration.crossStateCare = ""
    carrierIntegration.payLater = ""
    carrierIntegration.onExchange = true
    carrierIntegration.offExchange = false
    carrierIntegration.bothExchanges = false
    carrierIntegration.offExchangeIsAutoPay = ""
    carrierIntegration.onExchangeIsAutoPay = ""
    carrierIntegration.bothExchangesIsAutoPay = ""
  } else {
    carrierIntegration.crossStateCare = getYesOrNoStringValue(carrierIntegration.crossStateCare)
    carrierIntegration.payLater = getYesOrNoStringValue(carrierIntegration.payLater)
    carrierIntegration.offExchangeIsAutoPay = getYesOrNoStringValue(carrierIntegration.offExchangeIsAutoPay)
    carrierIntegration.onExchangeIsAutoPay = getYesOrNoStringValue(carrierIntegration.onExchangeIsAutoPay)
    carrierIntegration.bothExchangesIsAutoPay = getYesOrNoStringValue(carrierIntegration.bothExchangesIsAutoPay)
    carrierIntegration.onExchangeExtraStepDescription = carrierIntegration?.onExchangeExtraStepDescription ?? ""
    carrierIntegration.offExchangeExtraStepDescription = carrierIntegration?.offExchangeExtraStepDescription ?? ""
    carrierIntegration.bothExchangesExtraStepDescription = carrierIntegration?.bothExchangesExtraStepDescription ?? ""
  }

  const { notify } = useNotifications("carrier-updated-success")
  // FUTURE: This is needed when we uncomment the apply functionality const [isSelectedApplyAllCarriers, setIsSelectedApplyAllCarriers] = useState(false)
  const [logoUrl, setLogoUrl] = useState(carrierIntegration.logoUrl)
  const [selectedLogo, setSelectedLogo] = useState("")

  const handleLogoChange = async (event: any) => {
    const file = event.target.files[0]

    setSelectedLogo(URL.createObjectURL(file))

    const fileName = buildFileName(carrierIntegration)
    const signedUrl: CarrierPreSignUrlResponse = await getSignedUrl({ fileName })

    const response = await uploadCarrierFile({
      file,
      signedUrl: signedUrl.url,
    })

    if (response.status === STATUS_OK) {
      setLogoUrl(signedUrl.url.split("?")[0])
    }
  }

  const handleButtonClick = () => {
    const inputElement = document.getElementById("upload-logo-input")

    if (inputElement) {
      inputElement.click()
    }
  }

  const handleExchangeChange = (event: any) => {
    setSelectedExchanges(event.target.value)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-carrier-data"
      aria-describedby=""
      data-qa="modal-carrier-data"
      maxWidth="md"
      PaperProps={{
        style: {
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item px={8} py={6}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <IconButton onClick={handleButtonClick}>
                      <Fab
                        sx={{
                          background: "white",
                          height: "2.25rem",
                          width: "2.25rem",
                        }}
                        aria-label="edit"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleLogoChange}
                          style={{ display: "none", backgroundColor: "blue" }}
                          id="upload-logo-input"
                        />
                        <EditOutlined />
                      </Fab>
                    </IconButton>
                  }
                >
                  <img
                    src={selectedLogo || logoUrl}
                    alt={`${carrierIntegration.name} logo`}
                    style={{
                      objectFit: "contain",
                      minHeight: "3.5rem",
                      maxHeight: "3.5rem",
                      width: "5.7rem",
                      height: "3.5rem",
                      borderRadius: "0.5rem",
                    }}
                    data-qa={createDataQa("modal-carrier-data", "carrier-logo")}
                  />
                </Badge>
              </Grid>
              <Grid item>
                <Typography variant="h3tiempos">
                  {carrierIntegration.name} - {getStateLabel(carrierIntegration.state)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <IconButton data-qa="close-carrier-modal" aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" pt={4} pb={4} pr={8} pl={8}>
          <Formik
            initialValues={carrierIntegration}
            validationSchema={validateCarrier}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              if (values?.bothExchanges) {
                values.bothExchangesEnrollment = values.offExchangeEnrollment
                values.bothExchangesExtraStepDescription = values.offExchangeExtraStepDescription
                values.bothExchangesLink = values.offExchangeLink
                values.bothExchangesCutOffDate = values.offExchangeCutOffDate
                values.bothExchangesPremiumPullDate = values.offExchangePremiumPullDate
                values.bothExchangesIsAutoPay = values.offExchangeIsAutoPay
                values.bothExchangesPaymentOption = values.offExchangePaymentOption
              }
              if (!values?.offExchange) {
                values.offExchangeEnrollment = undefined
                values.offExchangeExtraStepDescription = undefined
                values.offExchangeLink = undefined
                values.offExchangeCutOffDate = undefined
                values.offExchangePremiumPullDate = undefined
                values.offExchangeIsAutoPay = undefined
              }
              if (!values?.onExchange) {
                values.onExchangeEnrollment = undefined
                values.onExchangeExtraStepDescription = undefined
                values.onExchangeLink = undefined
                values.onExchangeCutOffDate = undefined
                values.onExchangePremiumPullDate = undefined
                values.onExchangeIsAutoPay = undefined
              }
              const carrier: CarrierIntegration = {
                ...values,
                logoUrl,
                crossStateCare: values?.crossStateCare === YES,
                payLater: values?.payLater === YES,
                onExchangeIsAutoPay: values?.onExchangeIsAutoPay === YES,
                offExchangeIsAutoPay: values?.offExchangeIsAutoPay === YES,
                bothExchangesIsAutoPay: values?.bothExchangesIsAutoPay === YES,
              }

              if (isEditing) {
                const carriersUpdated = await updateCarrierIntegration({
                  carrierIntegration: carrier,
                  applyAllStates: false, // FUTURE: This value should be isSelectedApplyAllCarriers,
                })

                notify(`${carriersUpdated?.length} Carriers updated successfully`, "success")
              } else {
                const carrierCreated = await createCarrierIntegration({ carrierIntegration: carrier })

                notify(`Carrier ${carrierCreated.name} created successfully`, "success")
              }
              setSubmitting(false)
              resetForm()
              onClose()
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              isValid,
              dirty,
            }) => {
              const isOnExchangeEnrollment = !!selectedExchanges?.includes(ON_EXCHANGE)
              const isOffExchangeEnrollment = !!selectedExchanges?.includes(OFF_EXCHANGE)
              const isBothExchangeEnrollment = !!selectedExchanges?.includes(BOTH_EXCHANGE)

              const handleFieldChange = (field: string, value: any, shouldValidate?: boolean | undefined) => {
                setFieldValue(field, value)
                setFieldTouched(field, true)
              }

              values.onExchange = isOnExchangeEnrollment
              values.offExchange = isOffExchangeEnrollment
              values.bothExchanges = isBothExchangeEnrollment
              const onExchangeFormikValues: EnrollmentContainerValues = {
                enrollment: {
                  name: "onExchangeEnrollment",
                  value: values.onExchangeEnrollment,
                  error: errors.onExchangeEnrollment,
                  touched: touched.onExchangeEnrollment,
                },
                extraStepDescription: {
                  name: "onExchangeExtraStepDescription",
                  value: values.onExchangeExtraStepDescription,
                  error: errors.onExchangeExtraStepDescription,
                  touched: touched.onExchangeExtraStepDescription,
                },
                link: {
                  name: "onExchangeLink",
                  value: values.onExchangeLink,
                  error: errors.onExchangeLink,
                  touched: touched.onExchangeLink,
                },
                cutOffDate: {
                  name: "onExchangeCutOffDate",
                  value: values.onExchangeCutOffDate,
                  error: errors.onExchangeCutOffDate,
                  touched: touched.onExchangeCutOffDate,
                },
                premiumPullDate: {
                  name: "onExchangePremiumPullDate",
                  value: values.onExchangePremiumPullDate,
                  error: errors.onExchangePremiumPullDate,
                  touched: touched.onExchangePremiumPullDate,
                },
                isAutoPay: {
                  name: "onExchangeIsAutoPay",
                  value: values.onExchangeIsAutoPay,
                  error: errors.onExchangeIsAutoPay,
                  touched: touched.onExchangeIsAutoPay,
                },
                paymentOption: {
                  name: "onExchangePaymentOption",
                  value: values.onExchangePaymentOption,
                  error: errors.onExchangePaymentOption,
                  touched: touched.onExchangePaymentOption,
                },
              }

              const offExchangeFormikValues: EnrollmentContainerValues = {
                enrollment: {
                  name: "offExchangeEnrollment",
                  value: values.offExchangeEnrollment,
                  error: errors.offExchangeEnrollment,
                  touched: touched.offExchangeEnrollment,
                },
                extraStepDescription: {
                  name: "offExchangeExtraStepDescription",
                  value: values.offExchangeExtraStepDescription,
                  error: errors.offExchangeExtraStepDescription,
                  touched: touched.offExchangeExtraStepDescription,
                },
                link: {
                  name: "offExchangeLink",
                  value: values.offExchangeLink,
                  error: errors.offExchangeLink,
                  touched: touched.offExchangeLink,
                },
                cutOffDate: {
                  name: "offExchangeCutOffDate",
                  value: values.offExchangeCutOffDate,
                  error: errors.offExchangeCutOffDate,
                  touched: touched.offExchangeCutOffDate,
                },
                premiumPullDate: {
                  name: "offExchangePremiumPullDate",
                  value: values.offExchangePremiumPullDate,
                  error: errors.offExchangePremiumPullDate,
                  touched: touched.offExchangePremiumPullDate,
                },
                isAutoPay: {
                  name: "offExchangeIsAutoPay",
                  value: values.offExchangeIsAutoPay,
                  error: errors.offExchangeIsAutoPay,
                  touched: touched.offExchangeIsAutoPay,
                },
                paymentOption: {
                  name: "offExchangePaymentOption",
                  value: values.offExchangePaymentOption,
                  error: errors.offExchangePaymentOption,
                  touched: touched.offExchangePaymentOption,
                },
              }

              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <SelectMultiField
                        placeholder="Please select exchange types"
                        selectedValues={selectedExchanges}
                        data-qa="exchange-options"
                        fieldLabel="Exchange"
                        data={EXCHANGE_OPTIONS}
                        name="exchangeOption"
                        onChange={handleExchangeChange}
                        required
                        error={touchedExchange && selectedExchanges.length === 0}
                        helperText={touchedExchange && selectedExchanges.length === 0 ? "Exchange is required" : ""}
                        onBlur={() => setTouchedExchange(true)}
                      />
                    </Grid>

                    <Grid item xs={6.5}>
                      <ClampedTextField
                        id="overdraft-fee"
                        data-qa="overdraft-fee"
                        label="Overdraft fee"
                        type="number"
                        value={values.overdraftFeeCents}
                        name="overdraftFeeCents"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          startAdornment: <Typography sx={{ marginRight: 2 }}>$</Typography>,
                        }}
                        fullWidth
                        error={!!errors.overdraftFeeCents}
                        helperText={errors.overdraftFeeCents}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <RadioGroupCard
                        name="crossStateCare"
                        label="Cross state care"
                        value={values.crossStateCare}
                        formName="crossStateCare"
                        elements={YES_NO_OPTIONS}
                        handleChange={setFieldValue}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <RadioGroupCard
                        name="payLater"
                        label="Pay later"
                        value={values.payLater}
                        formName="payLater"
                        elements={YES_NO_OPTIONS}
                        handleChange={setFieldValue}
                        required
                      />
                    </Grid>
                    <EnrollmentContainer
                      enrollment={onExchangeFormikValues.enrollment}
                      extraStepDescription={onExchangeFormikValues.extraStepDescription}
                      link={onExchangeFormikValues.link}
                      cutOffDate={onExchangeFormikValues.cutOffDate}
                      premiumPullDate={onExchangeFormikValues.premiumPullDate}
                      isAutoPay={onExchangeFormikValues.isAutoPay}
                      paymentOption={onExchangeFormikValues.paymentOption}
                      enrollmentType={ON_EXCHANGE}
                      disabled={!isOnExchangeEnrollment}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={handleFieldChange}
                    />

                    <EnrollmentContainer
                      enrollment={offExchangeFormikValues.enrollment}
                      extraStepDescription={offExchangeFormikValues.extraStepDescription}
                      link={offExchangeFormikValues.link}
                      cutOffDate={offExchangeFormikValues.cutOffDate}
                      premiumPullDate={offExchangeFormikValues.premiumPullDate}
                      isAutoPay={offExchangeFormikValues.isAutoPay}
                      enrollmentType={`${OFF_EXCHANGE}/${BOTH_EXCHANGE}`}
                      paymentOption={offExchangeFormikValues.paymentOption}
                      disabled={!(isOffExchangeEnrollment || isBothExchangeEnrollment)}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={handleFieldChange}
                    />

                    <Grid container spacing={4} sx={{ paddingTop: 8 }}>
                      <Grid item xs={6}>
                        {/* FUTURE: <FormControlLabel
                            control={
                              <Checkbox
                                checked={isSelectedApplyAllCarriers}
                                onChange={event => setIsSelectedApplyAllCarriers(event.target.checked)}
                                onBlur={handleBlur}
                                name="apply-to-all-carriers"
                              />
                            }
                            label="Apply to all states of this carrier"
                          /> */}
                      </Grid>
                      <Grid item xs={6}>
                        <DialogActions sx={{ pr: 0, pt: 6 }}>
                          <Button onClick={onClose} color="inherit" data-qa="cancel-button">
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            autoFocus
                            disabled={isSubmitting || !isValid || !dirty}
                            data-qa="confirm-button"
                          >
                            Save changes
                          </Button>
                        </DialogActions>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          </Formik>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
