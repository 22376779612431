import { Uuid } from "@/utils/types"
import {
  benefitsEligibleDateValidation,
  dateOfBirthValidation,
  hireDateValidation,
  validateCity,
  validateCounty,
  validateEmail,
  validateEmployeeId,
  validateFirstName,
  validateLastName,
  validateMiddleName,
  validatePhoneNumber,
  validatePreferredName,
  validateState,
  validateStreet1,
  validateStreet2,
  validateZip,
} from "@/utils/validations"
import { FormikProps, validateYupSchema, yupToFormErrors } from "formik"
import * as Yup from "yup"
import { Step, STEPS } from "./types"

/*
 * This schema is used when the user is not eligible for benefits.
 */
const addPersonSchemaWithoutBenefits = Yup.object({
  firstName: validateFirstName,
  lastName: validateLastName,
  middleName: validateMiddleName,
  preferredName: validatePreferredName,
  email: validateEmail,
  phoneNumber: validatePhoneNumber,
  selectedRole: Yup.object({
    roleId: Yup.string().uuid("Must be a valid role").required("Role is required"),
    roleName: Yup.string(),
  }),
  benefitEligible: Yup.boolean(),
})

/*
 * This schema is used when the user is eligible for benefits.
 */
const addPersonSchemaWithBenefitsAboutStep = addPersonSchemaWithoutBenefits.shape({
  employeeNumber: validateEmployeeId,
})

const addPersonSchemaWithBenefitsAddressStep = addPersonSchemaWithBenefitsAboutStep.shape({
  homeAddress: Yup.object({
    street1: validateStreet1,
    street2: validateStreet2,
    zip: validateZip,
    city: validateCity,
    county: validateCounty,
    state: validateState,
  }),
})

const addPersonSchemaCompanyStep = Yup.object({
  company: Yup.object().required("Company is required"),
  planId: Yup.string().required(),
})

const companyInfoSchema = Yup.object().shape({
  companyName: Yup.string(),
  companyType: Yup.string(),
  industry: Yup.string(),
  ein: Yup.string(),
  isALE: Yup.boolean().nullable(),
})

const companyAddressSchema = Yup.object().shape({
  streetAddress: Yup.string(),
  suiteApt: Yup.string().nullable(),
  city: Yup.string(),
  state: Yup.string(),
  zip: Yup.string(),
})

const benefitsAdministratorSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string(),
  phoneNumber: Yup.string(),
})

const companySchema = Yup.object()
  .shape({
    id: Yup.string<Uuid>(),
    companyInfo: companyInfoSchema,
    companyAddress: companyAddressSchema,
    benefitsAdministrator: benefitsAdministratorSchema,
  })
  .nonNullable()

const addPersonSchemaWithBenefitsEmploymentStep = addPersonSchemaWithBenefitsAddressStep.shape({
  className: Yup.string().required("Class is required"),
  employmentType: Yup.string()
    .oneOf(["full_time", "part_time", "seasonal"])
    .nullable()
    .required("Employment type is required"),
  dateOfBirth: dateOfBirthValidation,
  hireDate: hireDateValidation(),
  eligibilityDate: benefitsEligibleDateValidation,
  companyName: Yup.string(),
  companyId: Yup.string<Uuid>(),
  planId: Yup.string<Uuid>(),
  isAdmin: Yup.boolean(),
  company: companySchema,
  hraStartDate: Yup.date(),
  isTcHub: Yup.boolean(),
})

/*
 * This schema is used when the user is eligible for benefits.
 */
export type AddPersonValues = Yup.InferType<typeof addPersonSchemaWithBenefitsEmploymentStep>

export interface AddPeopleStepProps extends FormikProps<AddPersonValues> {
  onClose: () => void
  onBack: () => void
  onNextStep: (step: Step) => void
  companyId: Uuid
  planId: Uuid
  isAdmin?: boolean
  hraStartDate: string | Date
}

/*
 * This function is used to validate the form values.
 */
export const addPersonValidate = async (values: AddPersonValues, step: Step) => {
  let schema = null

  if (step.id === STEPS.CONFIRM_COMPANY.id) {
    schema = addPersonSchemaCompanyStep
  } else {
    schema = addPersonSchemaWithoutBenefits
  }

  if (values.benefitEligible) {
    if (step.id === STEPS.ABOUT_PERSON_FORM.id) {
      schema = addPersonSchemaWithBenefitsAboutStep
    } else if (step.id === STEPS.ADDRESS_PERSON_FORM.id) {
      schema = addPersonSchemaWithBenefitsAddressStep
    } else if (step.id === STEPS.EMPLOYMENT_PERSON_FORM.id) {
      schema = addPersonSchemaWithBenefitsEmploymentStep
      schema = schema.shape({
        hireDate: hireDateValidation(),
      })
    }
  }
  try {
    await validateYupSchema(values, schema, true)
  } catch (error) {
    return yupToFormErrors(error)
  }
}
