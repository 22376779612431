import { useAuth } from "@/features/Auth/useAuth"
import { useCompanyDocuments } from "@/features/Documents/documentsService"
import { DocumentsTable } from "@/features/Documents/pages/DocumentsTable"
import { Grid } from "@mui/material"
import { OnboardingStep } from "../components/OnboardingStep"
import { DOCUMENTS_VIEWED } from "../employerOnboardingConstants"

export const HraDocuments = () => {
  const { user } = useAuth()
  const company = user?.company
  const companyId = company?.companyId
  const { data: companyDocuments } = useCompanyDocuments(companyId ?? "", true)

  const documentsAreAcnowledged = companyDocuments
    ?.filter(doc => doc.isAcknowledgementRequired)
    .every(doc => !!doc.acknowledgedAt)

  return (
    <OnboardingStep title="Documents" stepName={DOCUMENTS_VIEWED} disabled={!documentsAreAcnowledged}>
      <Grid item xs={12} mb={8}>
        <DocumentsTable isEROnboarding />
      </Grid>
    </OnboardingStep>
  )
}
