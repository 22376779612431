import { useShoppingSession } from "@/features/BenefitsElection/benefitsElectionService"
import { BenefitsElectionStep } from "@/features/BenefitsElection/components/BenefitsElectionStep"

export const PlanType = () => {
  const shoppingSessionId = useShoppingSession()
  const next = `/benefits-election/${shoppingSessionId}/find-plan/questions/carriers`
  const previous = `/benefits-election/${shoppingSessionId}/find-plan/questions/health-needs`

  return (
    <BenefitsElectionStep title="PlanType" next={next} previous={previous} advanceOnSuccess>
      {/* FUTURE */}
    </BenefitsElectionStep>
  )
}
