import carImage from "@/assets/svg/car-driving-away.svg"
import { wrapperBoxStyle } from "@/features/BenefitsElection/pages/welcome/EmployeeWelcome"
import { createDataQa } from "@/utils/dataQa"
import { Box, Button, Card, CardContent, Typography } from "@mui/material"

const maxWidth = "40rem"
const dataQa = createDataQa("onboarding-complete")

export const OnboardingSuccess = () => (
  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 4 }}>
    <Box
      sx={{
        minHeight: "fit-content",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box component="img" alt="car driving away" src={carImage} sx={{ pb: 6, maxHeight: "16rem" }} />
      <Typography
        variant="h1"
        align="center"
        sx={{ fontSize: { sm: "2rem", xs: "1rem" }, lineHeight: "100%", maxWidth, marginBottom: 3 }}
        data-qa={createDataQa(dataQa, "congratulations")}
      >
        Great work! You finished onboarding and are ready to go.
      </Typography>
    </Box>

    <Box sx={wrapperBoxStyle}>
      <Card
        variant="outlined"
        sx={{ justifyContent: "space-around", maxWidth: "48rem" }}
        data-qa={createDataQa(dataQa, "card")}
      >
        <CardContent>
          <Typography>
            Congratulations on completing your employer onboarding setup! Here's what you've accomplished:
            <ul>
              <li>Acknowledged Documents</li>
              <li>Setup Employees</li>
              <li>AutoPay Account Application</li>
              <li>Email Invitation Date Set</li>
              <li>HRA Subscription Purchased</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            What's Next?
          </Typography>
          <Typography>
            Explore your dashboard! Discover more features to streamline company management and maximize your benefits!
          </Typography>
        </CardContent>
      </Card>
    </Box>
    <Button
      href="/"
      variant="contained"
      color="primary"
      sx={{ alignSelf: "center", width: { xs: "100%", sm: "fit-content" }, marginY: 4 }}
      data-qa={createDataQa(dataQa, "start-button")}
    >
      Go to Dashboard
    </Button>
  </Box>
)
