import styled from "@emotion/styled"
import { default as ChipMui } from "@mui/material/Chip"
import { PEOPLE_STATUS_TO_VARIANT } from "../peopleConstants"
import { PeopleStatus } from "../peopleTypes"
export const chipVariants = {
  Pumpkin: styled(ChipMui)`
    background: ${props => props.theme.palette.colors.pumpkin};
    color: ${props => props.theme.palette.colors.white};
  `,
  Forest: styled(ChipMui)`
    background: ${props => props.theme.palette.colors.forest};
    color: ${props => props.theme.palette.colors.white};
  `,
  SeaPink: styled(ChipMui)`
    background: ${props => props.theme.palette.colors.seaPink};
    color: ${props => props.theme.palette.colors.darkBody};
  `,
  SurfCrest: styled(ChipMui)`
    background: ${props => props.theme.palette.colors.surfCrest};
    color: color: ${props => props.theme.palette.colors.darkBody};
  `,
  PaleOlive: styled(ChipMui)`
    background: ${props => props.theme.palette.colors.paleOlive};
    color: color: ${props => props.theme.palette.colors.darkBody};
  `,
  SilverSand: styled(ChipMui)`
    background: ${props => props.theme.palette.colors.silverSand};
    color: color: ${props => props.theme.palette.colors.darkBody};
  `,
  WaterBlue: styled(ChipMui)`
    background: ${props => props.theme.palette.colors.waterBlue};
    color: ${props => props.theme.palette.colors.white};
  `,
  FireBrick: styled(ChipMui)`
    background: ${props => props.theme.palette.colors.lightErrorDark};
    color: ${props => props.theme.palette.colors.white};
  `,
  Periwinkle: styled(ChipMui)`
    background: ${props => props.theme.palette.colors.periwinkle};
    color: ${props => props.theme.palette.colors.darkBody};
  `,
}

export interface ChipPersonStatusProps {
  status: PeopleStatus
}

export const ChipPersonStatus = ({ status }: ChipPersonStatusProps) => {
  if (!status) return null
  const ChipComponent = PEOPLE_STATUS_TO_VARIANT[status] ?? chipVariants.SilverSand
  const Variant = ChipComponent.variant

  return <Variant label={ChipComponent.label} />
}
