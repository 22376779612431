import { createDataQa } from "@/utils/dataQa"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { Button, Grid } from "@mui/material"
import { ReactNode, useEffect, useRef } from "react"
import SignatureCanvas from "react-signature-canvas"
import { Typographify } from "../Text"
import "./signature-canvas.css"

export interface WetSignaturePadProps {
  title?: ReactNode
  description?: ReactNode
  onClear: () => void
  onSave: (dataUrl: string) => void
}

export const WetSignaturePad = ({
  title = "",
  description = "Use your pointer here to draw your signature",
  onClear,
  onSave,
}: WetSignaturePadProps) => {
  const ref = useRef<SignatureCanvas>(null)

  const clear = () => {
    onClear?.()
    ref.current?.clear()
  }

  const save = () => {
    const url = ref.current?.getTrimmedCanvas().toDataURL("image/png") ?? ""

    onSave?.(url)
  }

  const dataQa = createDataQa("wet-signature-pad")

  useEffect(() => {
    const handleResize = () => {
      const temp = ref.current?.toData()

      if (temp) {
        ref.current?.fromData(temp)
      }
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  })

  return (
    <Grid container direction="column" display="flex">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          {title && (
            <Typographify variant="h6" mb={2} data-qa={createDataQa("wet-signature-pad", "title")}>
              {title}
            </Typographify>
          )}
          <Typographify variant="body2" data-qa={createDataQa("wet-signature-pad", "description")}>
            {description}
          </Typographify>
        </Grid>
        <Grid item>
          <Button
            color="inherit"
            startIcon={<DeleteOutlineIcon />}
            onClick={() => clear()}
            data-qa={createDataQa("wet-signature-pad", "clear-button")}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
      {/* FUTURE: Use theming here instead of manually specifying this color */}
      <Grid item mt={3} boxShadow={2} sx={{ borderRadius: ".5rem" }}>
        <SignatureCanvas
          minWidth={1.5}
          maxWidth={2}
          onEnd={() => save()}
          canvasProps={{ className: "wetSignature" }}
          // clearOnResize={false}
          ref={ref}
          data-qa={dataQa}
        />
      </Grid>
    </Grid>
  )
}
