import { EXTERNAL_LINKS } from "@/constants"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Button, Grid, Link, Typography } from "@mui/material"
import { QuizProgressBar } from "./QuizProgressBar"

type ICHRA_PLAN_TYPE = "diy" | "non-diy"
interface Props {
  plan: ICHRA_PLAN_TYPE
}

const ichraBenefits = [
  "Personalized plan choice",
  "Greater tax efficiency",
  "Simple and flexible design options",
  "Budget control",
]

export const IchraResult = ({ plan }: Props) => (
  <Grid sx={{ width: { xs: "90%", sm: "50%" }, py: 10 }}>
    <Grid item>
      <QuizProgressBar value={100} />
    </Grid>
    <Grid item sx={{ mt: 8 }}>
      <Typography variant="h1" align="center">
        Your Results:
        <br />
        ICHRA
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="body1" sx={{ mt: 3 }}>
        Based on your responses, an ICHRA plan may be a good solution for providing health care to your employees.{" "}
        <br />
        <br />
        Compared to group plans, ICHRAs come with many benefits for companies:
      </Typography>
    </Grid>
    <Grid item>
      <Grid item>
        <ul>
          {ichraBenefits.map((benefit, index) => (
            <li key={index}>
              <Typography variant="body1" align="left">
                {benefit}
              </Typography>
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>

    {plan === "non-diy" && (
      <Grid item>
        <Typography variant="body1" sx={{ my: 4 }}>
          Ready to get started? Contact our team of HRA expert specialists, who can help set up your organization's HRA.
        </Typography>
      </Grid>
    )}
    <Grid item>
      <Button
        component={Link}
        href={EXTERNAL_LINKS.HRA_ICHRA_GUIDE}
        underline="none"
        data-qa="ichra-guide-link"
        sx={{ color: "colors.darkBody" }}
      >
        Check out our full ICHRA guide.
      </Button>
    </Grid>
    <Grid container mt={10} justifyContent="center">
      {plan === "non-diy" ? (
        <Button
          color="primary"
          href={EXTERNAL_LINKS.HRA_DESIGN_REQUEST}
          variant="contained"
          data-qa="ichra-submit-design-request"
        >
          Submit a design request
        </Button>
      ) : (
        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              href={EXTERNAL_LINKS.TCH_HOME_PAGE}
              data-qa="ichra-create-my-account"
            >
              Create my account
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              href={EXTERNAL_LINKS.SCHEDULE_APPT}
              data-qa="ichra-schedule-an-appointment"
            >
              Schedule an appointment
            </Button>
          </Grid>
        </Grid>
      )}
      <Button component={Link} startIcon={<ArrowBackIcon />} href="/welcome" sx={{ mt: 5, color: "colors.darkBody" }}>
        Restart the quiz
      </Button>
    </Grid>
  </Grid>
)
