import { Banner } from "@/components/Banners"
import { createDateFromDateTimeText } from "@/utils/formatting"
import { useBenefitsElectionStore } from "../benefitsElectionStore"
import { planYearSuffix } from "../benefitsElectionUtils"

type EnrollmentBannerVariant = "inside-shopping-flow" | "outside-shopping-flow"

export interface EnrollmentBannerProps {
  variant: EnrollmentBannerVariant
  planYear?: string
}

export const EnrollmentBanner = ({ variant, planYear }: EnrollmentBannerProps) => {
  const shoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const periodEndUtc = shoppingSession?.enrollmentTimePeriod?.periodEndUtc

  if (!periodEndUtc) return null

  const until = createDateFromDateTimeText(periodEndUtc)

  if (until < new Date()) return null

  const suffix = planYearSuffix(planYear)
  const extraProps = variant === "outside-shopping-flow" ? {} : { forceMobile: true }

  return <Banner title={`Complete plan shopping${suffix} in`} until={until} {...extraProps} />
}
