import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { useQuery } from "@tanstack/react-query"

export interface QsehraMax {
  id: string
  year: number
  employeeOnlyContributionLimit: number
  householdContributionLimit: number
  isArchived: boolean
}

export const baseApiUrl = appConfig.baseApiUrl

const getCurrentQsehraMax = async () =>
  (await axios.get(`${baseApiUrl}/benefits/v1/qsehramax/current`)).data as QsehraMax

export const useQsehraMax = () => useQuery({ queryKey: ["getQsehraMax"], queryFn: () => getCurrentQsehraMax() })
