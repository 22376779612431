import styled from "@emotion/styled"
import { Box } from "@mui/material"
import { ReactNode } from "react"

const Root = styled(Box)`
  display: flex;
  min-height: 100vh;
`

interface RootWrapperProps {
  children?: ReactNode
}

export const RootWrapper = ({ children }: RootWrapperProps) => <Root>{children}</Root>
