import { TakeCommandLogoInline } from "@/components/Branding"
import { VerificationCodeField } from "@/features/Auth/pages/Mfa/VerificationCodeField"
import { useAuth } from "@/features/Auth/useAuth"
import { useLoginRedirect } from "@/services/loginRedirectService"
import { useNotifications } from "@/services/notificationService"
import { verificationCodeValidationSchema } from "@/utils/validations"
import { KeyboardArrowRight } from "@mui/icons-material"
import { Button, Chip, CircularProgress, Grid, Link, Typography } from "@mui/material"
import { Formik } from "formik"
import { get } from "lodash"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const initialValues = {
  verificationCode: "",
}

export const VerifyMfa = () => {
  const { signInWithMfa } = useAuth()
  const { notify } = useNotifications("verify-mfa")
  const navigate = useNavigate()
  const location = useLocation()
  const { email, password, rememberMe } = location.state ?? {}
  const { redirect } = useLoginRedirect()

  useEffect(() => {
    if (!email) {
      navigate("/")
    }
  }, [email, navigate])

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ maxWidth: "33.667rem" }}>
      <TakeCommandLogoInline />
      <Chip
        label={email}
        color="default"
        sx={{
          mt: 8,
          border: "1pt solid black",
        }}
      />
      <Typography variant="h1" sx={{ mt: 6 }}>
        Enter a one-time code
      </Typography>
      <Typography variant="body1" sx={{ mt: 10 }}>
        Use the code from your mobile authentication app for Take Command Health.
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={verificationCodeValidationSchema}
        onSubmit={async (values, { setErrors, setStatus }) => {
          try {
            await signInWithMfa({
              email,
              password,
              verificationCode: values.verificationCode,
              rememberMe,
              mfaType: "SOFTWARE_TOKEN_MFA",
            })
            await redirect()
          } catch (error: any) {
            console.error(error)
            const message = error.message || "Something went wrong"

            setStatus({ success: false })
            setErrors({ verificationCode: message })
            notify(`${message}`, "error")
          }
        }}
      >
        {({ isSubmitting, handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
          <form style={{ width: "100%" }} onSubmit={handleSubmit} noValidate data-qa="sign-in-form">
            <VerificationCodeField
              sx={{
                mt: 8,
                width: "100%",
              }}
              required
              value={values.verificationCode}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(get(touched, `verificationCode`) && get(errors, `verificationCode`))}
              helperText={get(touched, `verificationCode`) && get(errors, `verificationCode`)}
              inputProps={{ "data-qa": "verification-code-input" }}
              data-qa="verification-code"
              name="verificationCode"
              label="Authentication Code"
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 10 }}
              // FUTURE: Consider using continue navigation button here
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : <KeyboardArrowRight />}
            >
              Authenticate
            </Button>
            <Link
              sx={{
                display: "block",
                textAlign: "center",
                mt: 4,
              }}
              href="/sign-in"
            >
              Try another email
            </Link>
          </form>
        )}
      </Formik>
    </Grid>
  )
}
