import { ALL_COMPANY_ACCESS_ID } from "@/constants"
import { Uuid } from "@/utils/types"
import { useContext } from "react"
import { AuthContext } from "./components/CognitoContext"

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) throw new Error("AuthContext must be placed within AuthProvider")

  const { isAuthenticated, isInitialized } = context
  const isLoggedIn = isAuthenticated && isInitialized

  return { ...context, isLoggedIn }
}

export const useCompanyId = <
  T extends boolean,
  R extends T extends true ? Uuid | typeof ALL_COMPANY_ACCESS_ID | undefined : Uuid | undefined,
>(
  includeStar: T
) => {
  const { user } = useAuth()
  const companyId = user?.company?.companyId

  if (companyId === ALL_COMPANY_ACCESS_ID && !includeStar) {
    return undefined
  }

  return companyId as R
}
