import { SidebarItem } from "@/components/Sidebar/types"
import { Divider } from "@mui/material"
import { SidebarNavList } from "./SidebarNavList"

interface SidebarNavSectionProps {
  pages: SidebarItem[]
  title: string
  showDivider?: boolean
}

export const SidebarNavSection = ({ pages, showDivider }: SidebarNavSectionProps) => (
  <>
    {showDivider && <Divider />}
    <SidebarNavList pages={pages} depth={0} />
  </>
)
