import { convertUserModelToPerson, useGetPeopleSearch } from "@/features/TCHub/tcHubService"
import { colors } from "@/theme/palette"
import { Box, Button, Grid, Paper, TextField } from "@mui/material"
import { useMemo } from "react"
import { create } from "zustand"

const usePeopleSearchState = create<{
  searchInputValues: { name: string; email: string; company: string }
  setSearchInputValues: (field: string, value: string) => void
}>(set => ({
  searchInputValues: { name: "", email: "", company: "" },
  setSearchInputValues: (field, value) =>
    set(state => ({
      searchInputValues: { ...state.searchInputValues, [field]: value },
    })),
}))

export const usePeopleSearch = () => {
  const { searchInputValues, setSearchInputValues } = usePeopleSearchState()
  const { data, isLoading, refetch, isRefetching } = useGetPeopleSearch(searchInputValues)

  const handleFieldChange = (field: string) => (event: { target: { value: string } }) => {
    setSearchInputValues(field, event.target.value)
  }

  const handleSearch = () => refetch()

  const peopleData = useMemo(() => {
    if (data?.people) {
      return data.people.map(convertUserModelToPerson)
    }
    return []
  }, [data?.people])

  return {
    peopleData,
    setSearchInputValues,
    handleFieldChange,
    handleSearch,
    searchInputValues,
    isLoading,
    isRefetching,
  }
}

export const UsersSearch = () => {
  const { handleFieldChange, handleSearch } = usePeopleSearch()

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} width="100%" data-qa="users-search">
      <Paper elevation={3} sx={{ p: 3, mb: 3, width: "100%" }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={5}>
            <TextField
              data-qa="search-name"
              fullWidth
              label="Search by Name"
              onChange={handleFieldChange("name")}
              onKeyUp={e => e.key === "Enter" && handleSearch()}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              data-qa="search-email"
              fullWidth
              label="Search by Email"
              onChange={handleFieldChange("email")}
              onKeyUp={e => e.key === "Enter" && handleSearch()}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              disableElevation
              data-qa="search-button"
              variant="contained"
              onClick={handleSearch}
              sx={{
                width: "100%",
                backgroundColor: colors.boneChilling,
                "&:hover": { backgroundColor: "colors.mintGreen" },
                color: theme => theme.palette.primary.main,
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center" mt={1}>
          <Grid item xs={5}>
            <TextField
              data-qa="search-company"
              fullWidth
              label="Search by Company"
              onChange={handleFieldChange("company")}
              onKeyUp={e => e.key === "Enter" && handleSearch()}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}
